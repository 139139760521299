<div class="ezp-layout__page">
  <div class="ezp-layout__content">
    <div class="mat-headline" id="apps-downloads-text">
      {{ 'PRINTERS.MY_PRINTERS.WELCOME.DOWNLOADS.TITLE' | translate }}
    </div>
    <div class="ezp-layout__part">
      <div class="ezp-download" *ngFor="let download of downloads">
        <div class="ezp-download__head">
          <img class="ezp-download__icon" id="download-icon" [src]="download.icon" />
          <div class="ezp-download__title mat-title" [id]="download.title">
            {{ download.title }}
          </div>
          <p class="ezp-download__description mat-subheading-2" [id]="download.description">
            {{ download.description }}
          </p>
        </div>
        <div class="ezp-download__collections">
          <ng-container *ngFor="let collection of download.collections">
            <div
              class="ezp-download__collection"
              *ngIf="
                !collection.requirements.administrator ||
                (collection.requirements.administrator && isAdmin)
              "
            >
              <div class="ezp-download__collection__title mat-title" [id]="collection.title">
                {{ collection.title }}
              </div>
              <p
                class="ezp-download__collection__description ezp-text ezp-text--medium-emphasis mat-body-1"
                [id]="collection.description"
              >
                {{ collection.description }}
              </p>
              <div class="ezp-download__items">
                <div class="ezp-download__item" *ngFor="let item of collection.items">
                  <div
                    class="ezp-download__item__title ezp-text mat-body-1"
                    [id]="item.operating_system"
                    [ngClass]="item.status !== 'available' ? 'ezp-text--disabled' : ''"
                  >
                    {{ item.operating_system }}
                  </div>
                  <a
                    class="ezp-download__item__link"
                    id="file-download-link"
                    *ngIf="item.status === 'available'"
                    [href]="item.link"
                    target="_blank"
                    matRipple
                    id="file-download"
                    ><mat-icon>file_download</mat-icon></a
                  >
                  <div
                    *ngIf="item.status !== 'available'"
                    class="ezp-download__item__hint ezp-text ezp-text--disabled mat-body-1"
                    id="coming-soon-text"
                  >
                    Coming soon
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
