<h2 mat-dialog-title id="resend-invitation-text">{{ 'USERSGROUPS.USERS.INVITE_USER.RESEND_INVITATION' | translate }}</h2>
<div mat-dialog-content>
  <p><span id="click-reinvite-text">{{ 'USERSGROUPS.USERS.INVITE_USER.CLICK_REINVITE_TEXT' | translate }} <strong>{{ user.user_invitations!= null && user.user_invitations[0].email ? user.user_invitations[0].email : user.display_name }}</strong>.</span></p> 
</div>
<div mat-dialog-actions>
  <button mat-button id="cancel-reinvite-user" (click)="close()" color="primary" class="ezp-button">
    {{ 'CANCEL' | translate }}
  </button>
  <button mat-button id="reinvite-user" (click)="resendInvitation()" color="primary" class="ezp-button" [disabled]="isLoading">
    <mat-icon [ngClass]="{ 'fa-spin': isLoading }" *ngIf="isLoading">cached</mat-icon>
    {{ 'USERSGROUPS.USERS.INVITE_USER.REINVITE' | translate }}
  </button>
</div>
