import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { DIALOG_RESPONSE } from '../classes/dialog-result'
import { EZEEP_PRINTING_API } from '../../../app.config'

@Injectable({
  providedIn: 'root',
})
export class CapabilitiesService {
  private apiUrl = EZEEP_PRINTING_API
  printerPropertiesWatcher = new BehaviorSubject<any>({
    changed: false,
    status: DIALOG_RESPONSE.CANCEL,
  })

  constructor(private http: HttpClient) {}

  getPrinterCapabilities(printerId: string): Observable<any> {
    const url = `${this.apiUrl}/v1/printers/${printerId}/capabilities/`
    return this.http.get<any>(url)
  }

  updatePrinterCapabilities(printerId: string, capability: any): Observable<any> {
    const url = `${this.apiUrl}/v1/printers/${printerId}/capabilities/`
    return this.http.patch<any>(url, capability, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    })
  }

  getPrinterDefaultCapabilities(printerId: string): Observable<any> {
    const url = `${this.apiUrl}/v1/printers/${printerId}/default_capabilities/`
    return this.http.get<any>(url)
  }

  public changePrinterPropertiesWatcherStatus(changed: boolean, status: DIALOG_RESPONSE) {
    this.printerPropertiesWatcher.next({ changed: changed, status: status })
  }
}
