import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core'
import { NetworkPrintersPrintersService } from '../../../modules/shared/services/network-printers-printers.service'
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '../../../app.config'
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator'
import { MatSort } from '@angular/material/sort'
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { Subscription } from 'rxjs'
import { DIALOG_RESPONSE } from '../../../modules/shared/classes/dialog-result'
import { PrinterEditorDialogComponent } from './printer-editor-dialog/printer-editor-dialog.component'
import {
  NetworkPrintersPrintersFiltersService,
  FILTER_CATEGORIES,
} from '../../../modules/shared/services/network-printers-printers-filters.service'
import { NetworkPrintersDriversService } from '../../../modules/shared/services/network-printers-drivers.service'
import { FormControl } from '@angular/forms'
import { pairwise, startWith } from 'rxjs/operators'
import { StorageUtils } from '../../../modules/storage/utils/storage.utils'
import { PrinterEditorDetailsComponent } from './printer-editor-details/printer-editor-details.component'
import { CapabilitiesService } from '../../../modules/shared/services/capabilities.service'
import posthog from 'posthog-js'

@Component({
  selector: 'ezd-networknetworkprinters-printers',
  templateUrl: './networkprinters.component.html',
  styleUrls: ['./networkprinters.component.scss'],
})
export class NetworkprintersComponent implements OnInit {
  @ViewChild(PrinterEditorDialogComponent)
  printerEditorDialogComponent: PrinterEditorDialogComponent
  @ViewChild(PrinterEditorDetailsComponent) detailsComponent: PrinterEditorDetailsComponent
  showDialogAlert: boolean = false
  columnDefs = ['select', 'name', 'model', 'connector', 'edit']
  networkPrintersPrintersTableDataSource = new MatTableDataSource([])
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  selectedNetworkPrintersPrintersCount = 0
  maxPageLength = 100
  defaultPageSize = DEFAULT_PAGE_SIZE
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS
  pageIndex: number
  pageSize: number = DEFAULT_PAGE_SIZE
  isLoading = false
  showMessageNoPrinters = false
  showMessageNotFound = false
  sorting: string
  printersPropertiesHasChanges: boolean = false
  @Output() public showDialog: EventEmitter<boolean> = new EventEmitter<boolean>(false)
  selectedPrinters: any[] = []
  printersServiceSubscription: Subscription
  watchPrinterPropertiesChangesSubscription: Subscription

  networkPrintersPrintersFilters: { field_name: string; values: string[] }[] = []
  selectedPrintersFilters = []
  public location: FormControl = new FormControl()
  public model_name: FormControl = new FormControl()
  getFiltersSubscription: Subscription
  getSelectecdFiltersSubscription: Subscription
  constructor(
    private networkPrintersPrintersService: NetworkPrintersPrintersService,
    private dialog: MatDialog,
    private networkPrintersPrintersFiltersService: NetworkPrintersPrintersFiltersService,
    private networkPrintersDriversService: NetworkPrintersDriversService,
    private capabilitiesService: CapabilitiesService
  ) {}

  ngOnInit() {
    this.networkPrintersPrintersTableDataSource.sort = this.sort

    this.networkPrintersPrintersService.setPage(0, this.defaultPageSize, true)
    // this.networkPrintersPrintersService.applyFilters(undefined)
    this.printersServiceSubscription = this.networkPrintersPrintersService
      .getFilteredNetworkPrintersPrinters()
      .subscribe((result) => {
        this.isLoading = result.isLoading
        if (this.isLoading) return
        this.networkPrintersPrintersTableDataSource.data = result.printers
        this.showMessageNoPrinters = result.noFilters && result.count == 0
        this.showMessageNotFound = !result.noFilters && result.count == 0
        this.pageSize = result.pageSize
        this.pageIndex = result.pageIndex

        this.selectedNetworkPrintersPrintersCount =
          this.networkPrintersPrintersService.getSelectedNetworkPrintersCount()
        this.maxPageLength = result.count
        if (result.printers.length > 0) {
          this.processConnectorsName(result.printers)
          this.processConnectorsOSType(result.printers)
        }
      })
    this.watchPrinterPropertiesChangesSubscription = this.networkPrintersPrintersService
      .watchPrinterPropertiesChanges()
      .subscribe((result) => {
        this.printersPropertiesHasChanges = result.changed
        if (
          (result.status == DIALOG_RESPONSE.CANCEL || result.status == DIALOG_RESPONSE.NONE) &&
          this.printersPropertiesHasChanges
        ) {
          const selectedItems = this.networkPrintersPrintersTableDataSource.data.filter(
            (x) => x.isSelected
          )
          if (selectedItems.length > 0 && this.selectedPrinters.length == 0) {
            this.selectedPrinters = selectedItems
          } else {
            this.networkPrintersPrintersTableDataSource.data.forEach((item) => {
              item.isSelected =
                this.selectedPrinters.filter((x) => x.id == item.id).length > 0 ? true : false
            })
          }
        } else {
          this.selectedPrinters = []
        }
      })

    this.getFiltersSubscription = this.networkPrintersPrintersFiltersService
      .getFilters()
      .subscribe((result) => {
        this.networkPrintersPrintersFilters = result
        this.networkPrintersPrintersFilters.push({
          field_name: 'connector',
          values: [],
        })
      })
    this.getSelectecdFiltersSubscription = this.networkPrintersPrintersFiltersService
      .getSelectedFilters()
      .subscribe((result) => {
        this.selectedPrintersFilters = result
        this.patchValuesToControls()
      })

    this.location.valueChanges
      .pipe(startWith([]), pairwise())
      .subscribe(([prevValue, selectedValue]) => {
        this.handleFilterChange('location', prevValue, selectedValue)
      })

    this.model_name.valueChanges
      .pipe(startWith([]), pairwise())
      .subscribe(([prevValue, selectedValue]) => {
        this.handleFilterChange('model_name', prevValue, selectedValue)
      })
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.printersServiceSubscription.unsubscribe()
    this.watchPrinterPropertiesChangesSubscription.unsubscribe()
    this.getFiltersSubscription.unsubscribe()
    this.getSelectecdFiltersSubscription.unsubscribe()
  }

  private processConnectorsName(printers: [any]) {
    printers.map((p) => {
      p.connectorName = p.connector.readable_name
    })
  }

  private processConnectorsOSType(printers: [any]) {
    printers.map((p) => {
      p.connector_os_type = p.connector.os_type
    })
  }

  patchValuesToControls() {
    const locations = this.selectedPrintersFilters
      .filter((p) => p.field_name === 'location')
      .map((x) => x.name)
    this.location.patchValue(locations, { emitEvent: locations.length === 0, onlySelf: true })
    const modelNames = this.selectedPrintersFilters
      .filter((p) => p.field_name === 'model_name')
      .map((x) => x.name)
    this.model_name.patchValue(modelNames, { emitEvent: modelNames.length === 0, onlySelf: true })
  }

  handleFilterChange(filterName: string, prevValue: any[], selectedValue: any[]) {
    const addedValues = selectedValue.filter((item) => !prevValue.includes(item))
    const removedValues = prevValue.filter((item) => !selectedValue.includes(item))

    if (addedValues.length) {
      this.selectFilter(true, { field_name: filterName }, addedValues[0])
    }

    if (removedValues.length) {
      this.selectFilter(false, { field_name: filterName }, removedValues[0])
    }
  }

  selectAllVisiblePrinters(event) {
    if (this.printersPropertiesHasChanges) {
      this.showDialog.emit(true)
      return
    }
    if (event.checked) {
      this.networkPrintersPrintersService.selectPrinters(
        this.networkPrintersPrintersTableDataSource.data
      )
    } else {
      this.networkPrintersPrintersService.unselectPrinters(
        this.networkPrintersPrintersTableDataSource.data
      )
    }
  }

  selectPrinter(event, printer) {
    if (this.printersPropertiesHasChanges) {
      this.showDialog.emit(true)
      return
    }
    if (event.checked) {
      this.networkPrintersPrintersService.selectPrinters([printer])
    } else {
      this.networkPrintersPrintersService.unselectPrinters([printer])
    }
  }

  public onPaginationChange(e) {
    if (this.printersPropertiesHasChanges) {
      this.showDialog.emit(true)
      return
    }
    this.pageIndex = e.pageIndex
    this.pageSize = e.pageSize
    this.networkPrintersPrintersService.setPage(this.pageIndex, this.pageSize)
  }

  sortChange(e) {
    if (this.printersPropertiesHasChanges) {
      this.showDialog.emit(true)
      return
    }
    let colName = ''
    if (e.active === 'model') {
      colName = 'model_name'
    } else {
      colName = e.active
    }

    const direction = e.direction === 'desc' ? '-' : e.direction === 'asc' ? '' : undefined
    if (direction === undefined) {
      this.sorting = colName
    } else {
      this.sorting = direction + colName
    }

    this.networkPrintersPrintersService.setSorting(this.sorting)
  }

  selectedPrinter: any = null

  openPrinterEditorDialog(printer) {
    if (this.printersPropertiesHasChanges) {
      this.showDialog.emit(true)
      return
    }
    this.onModalOpen('printer_editor')
    this.openEditor(printer)
  }

  private openEditor(printer) {
    StorageUtils.set(localStorage, 'printerUnsaved', null)
    StorageUtils.set(localStorage, 'printerCapabilities', null)
    StorageUtils.set(localStorage, 'printerAssignments', null)
    const dialogConfig = new MatDialogConfig()
    dialogConfig.autoFocus = false
    dialogConfig.width = '800px'
    dialogConfig.height = '600px'
    dialogConfig.panelClass = 'ezp-dialog'

    if (printer) {
      this.networkPrintersPrintersService.selectPrinters([printer])
      dialogConfig.data = [printer]
    } else {
      dialogConfig.data = this.networkPrintersPrintersTableDataSource.data.filter(
        (item) => item.isSelected === true
      )
    }

    const dialogRef = this.dialog.open(PrinterEditorDialogComponent, dialogConfig)

    dialogRef.afterClosed().subscribe((result) => {
      if (
        StorageUtils.get(localStorage, 'printerUnsaved') !== null ||
        StorageUtils.get(localStorage, 'printerCapabilities') !== null ||
        StorageUtils.get(localStorage, 'printerAssignments') !== null
      ) {
        this.showDialogAlert = true
      } else {
        this.processDialogResult(result)
      }
    })

    this.printerEditorDialogComponent = dialogRef.componentInstance
  }

  async processDialogResult(result: DIALOG_RESPONSE) {
    try {
      switch (result) {
        case DIALOG_RESPONSE.SAVE:
          console.log('save button', result)
          if (this.printerEditorDialogComponent) {
            await this.printerEditorDialogComponent.saveAllData()
          } else {
            console.error('printerEditorDialogComponent is not initialized')
          }
          break
        case DIALOG_RESPONSE.DISCARD:
          console.log('discard', result)
          if (this.printerEditorDialogComponent) {
            this.printerEditorDialogComponent.onDiscardChanges()
          } else {
            console.error('printerEditorDialogComponent is not initialized')
          }
          break
        case DIALOG_RESPONSE.CANCEL:
          console.log('cancel', result)
          break
        default:
          console.warn('Unknown dialog result:', result)
      }
    } catch (error) {
      console.error('Error processing dialog result:', error)
    } finally {
      this.showDialogAlert = false
      this.resetSelection()
      this.networkPrintersPrintersService.changePrinterPropertiesWatcherStatus(false, result)
      this.capabilitiesService.changePrinterPropertiesWatcherStatus(false, result)
      // If there was a selected printer (from openPrinterEditorDialog), open the editor now
      if (this.selectedPrinter) {
        // Add a small delay before reopening the dialog
        setTimeout(() => {
          this.openEditor(this.selectedPrinter)
          this.selectedPrinter = null
        }, 100)
      }
    }
  }

  resetSelection() {
    this.networkPrintersPrintersTableDataSource.data.forEach((item) => (item.isSelected = false))
    this.selectedNetworkPrintersPrintersCount = 0
    StorageUtils.set(localStorage, 'printerUnsaved', null)
    StorageUtils.set(localStorage, 'printerCapabilities', null)
    StorageUtils.set(localStorage, 'printerAssignments', null)
  }

  selectFilter(isAdd, filter, name) {
    if (isAdd) {
      if (filter.field_name === FILTER_CATEGORIES.DRIVER) {
        this.networkPrintersDriversService
          .getDrivers(undefined, undefined, name, undefined, undefined, undefined)
          .subscribe((response) => {
            const driver = response ? response[0] : undefined
            if (driver) {
              this.networkPrintersPrintersFiltersService.addFilter(
                filter.field_name,
                name,
                driver.id
              )
            }
          })
      } else {
        this.networkPrintersPrintersFiltersService.addFilter(filter.field_name, name, undefined)
      }
    } else {
      if (this.selectedPrintersFilters.find((x) => x.name === name)) {
        this.networkPrintersPrintersFiltersService.removeFilter(filter.field_name, name)
      }
    }
  }

  getSelectedFilterCountForFilterCategory(fieldName: string) {
    return this.selectedPrintersFilters.filter((filter) => filter.field_name === fieldName).length
  }

  isFilterSelected(filter: any, filterName: string) {
    return this.selectedPrintersFilters.find(
      (selectedFilter) =>
        selectedFilter.field_name === filter.field_name && selectedFilter.name === filterName
    )
  }

  clearFilters() {
    this.networkPrintersPrintersFiltersService.removeAllFilters()
  }

  getFormControl(filter: any): FormControl {
    return filter.field_name === 'location' ? this.location : this.model_name
  }

  // Track modal interactions
  onModalOpen(modalName: string) {
    posthog.capture('modal_opened', {
      modal_name: modalName,
    })
  }
}
