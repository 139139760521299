import { STORAGE_PREFIX } from "../constants/storage.constants";

export class StorageUtils {

  static get(storageType: Storage, key: string): any {
    const value: any = storageType.getItem(StorageUtils.prepareKey(key));

    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  static set(storageType: Storage, key: string, value: any): void {
    value = value === 'string' ? value : JSON.stringify(value);

    storageType.setItem(StorageUtils.prepareKey(key), value);
  }

  static remove(storageType: Storage, key): void {
    storageType.removeItem(StorageUtils.prepareKey(key));
  }

  static prepareKey(key: string): string {
    return `${STORAGE_PREFIX}-${key}`;
  }

}
