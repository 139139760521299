import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'ezd-session-placeholder',
  templateUrl: './session-placeholder.component.html',
  styleUrls: ['./session-placeholder.component.scss'],
})
export class SessionPlaceholderComponent implements OnInit {
  @Input() type: 'smartphone' | 'tablet' | 'laptop' = 'smartphone'

  constructor() {}

  ngOnInit(): void {}
}
