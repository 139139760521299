import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'
import { AuthenticationService } from './services/authentication.service'

@Component({
  selector: 'ezd-authentication',
  template: '',
})
export class AuthenticationComponent implements OnDestroy, OnInit {
  private routeSubscription: Subscription

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.subscribToQueryParams()
  }

  // -------------------------------------------
  // Lifecycle hooks
  // -------------------------------------------

  ngOnInit() {}

  ngOnDestroy() {
    this.routeSubscription.unsubscribe()
  }

  // -------------------------------------------
  // Private methods
  // -------------------------------------------

  private subscribToQueryParams(): void {
    this.routeSubscription = this.route.queryParams.subscribe((params) => {
      this.authenticationService.isEzeepUser().subscribe((response) => {
        if (response) {
          this.redirectToStartPage()
        }
      })
    })
  }

  private redirectToStartPage(): void {
    this.router.navigate(['/'])
  }
}
