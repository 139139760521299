<h2 mat-dialog-title>{{ 'USERSGROUPS.USERS.INVITE_USER.QUICKSTART_INVITE_USERS_DIALOG.HEADER' | translate }}</h2>
<div mat-dialog-content>
  <p>{{ 'USERSGROUPS.USERS.INVITE_USER.QUICKSTART_INVITE_USERS_DIALOG.BODY' | translate }}
</p>
</div>
<div mat-dialog-actions>
  
  <button mat-button (click)="close()" color="primary" class="ezp-button">
    {{ 'USERSGROUPS.USERS.INVITE_USER.QUICKSTART_INVITE_USERS_DIALOG.ACTION_CLOSE' | translate }}
  </button>
</div>