import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom, from as observableFrom, of } from 'rxjs';
import { GroupsService, ProfileService } from './printing-api.service';
import { AdconGroupsService, AdconTenantGroups, AdconGroup } from '../services/adcon-api.service';
import { mergeMap, map, last, toArray, concatMap } from 'rxjs/operators';
import { DEFAULT_PAGE_SIZE } from '../../../app.config';
import { NetworkPrintersGroupsService } from './network-printers-groups.service';
import { GeneralUtil } from '../utils/general-util';
import { AuthUserApiService, AuthOrganizationApiService } from './auth-api.service';

@Injectable()
export class LocalPrinterSupportService {
  printersServiceGroups = [];
  printersServiceGroupsCount = 0;
  printersServiceGroupsSubject = new BehaviorSubject<any>([]);
  localPrinterSupportGroupsSubject = new BehaviorSubject<any>([]);
  adconServiceGroups = [];
  adconServiceTenantId: string;
  adconServiceGroupsCount = 0;
  adconServiceGroupsSubject = new BehaviorSubject<any>([]);
  printersServiceGroupsSearchText = undefined;
  adconServiceGroupsSearchText = undefined;
  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  isLocalPrintingEnabled = true;
  sorting: string;
  ezeepGroupSearchText: string;

  constructor(
    private profileService: ProfileService,
    private groupsService: GroupsService,
    private adconGroupsService: AdconGroupsService,
    private authUserService: AuthUserApiService,
    private authOrganizationService: AuthOrganizationApiService) {
    this.setPrintersServiceGroups();
  }

  public toggleLocalPrinterSupport(): Observable<boolean> {
    return this.profileService.read().pipe(mergeMap(response => {
      let profile = response.result;
      profile.local_printing_enabled = !profile.local_printing_enabled;
      return this.profileService.update(profile).pipe(map(responseUpdate => {
        this.isLocalPrintingEnabled = responseUpdate.result.local_printing_enabled;
        return responseUpdate.result.local_printing_enabled;
      }));
    }));
  }

  public getCurrentLocalPrintingEnableStatus(): Observable<boolean> {
    return this.profileService.read().pipe(map(response => {
      let profile = response.result;
      this.isLocalPrintingEnabled = profile.local_printing_enabled;
      return profile.local_printing_enabled;
    }));
  }

  public getPrintersServiceGroups(): Observable<any> {
    return this.printersServiceGroupsSubject.asObservable();
  }

  public getAdconServiceGroups(): Observable<any> {
    this.setAdconServiceGroups();
    return this.adconServiceGroupsSubject.asObservable();
  }

  public removePrintersServiceGroup(selectedGroup) {
    selectedGroup.local_printing_enabled = false;
    this.groupsService.update(selectedGroup, selectedGroup.id)
      .subscribe(() => {
        this.setPrintersServiceGroups();
        this.setAllLocalSupportGroups()
      });
  }

  public addPrintersServiceGroups(selectedGroups: any): Observable<any> {
    const currentGroups: AdconTenantGroups = new AdconTenantGroups({
      value: [],
      tenantId: this.adconServiceTenantId
    });
    selectedGroups.forEach( group => {
      if (group.source == 'EZEEP') return

      let adconGroup = new AdconGroup({id: group.id, displayName: group.name})
      currentGroups.value.push(adconGroup);
    });
    
    if (currentGroups.value.length > 0) {
      this.adconGroupsService.post(currentGroups)
        .subscribe(async response => {
          if (response.status === 201) {
            if (response.result.value.length > 0) {
              await GeneralUtil.sleep(600);
              const azureIds = response.result.value.map(v => v.id);
              this.updateLocalPrintingSupportProperty(azureIds, selectedGroups);
            }
          }
        });
    }
    else {
      this.updateLocalPrintingSupportProperty([], selectedGroups);
    }
    return this.getPrintersServiceGroups();
  }

  private async updateLocalPrintingSupportProperty(azureIds: string[], selectedGroups: any) {
    let ezeepGroups = selectedGroups.filter(g => g.source == "EZEEP").map(g => {
      g.local_printing_enabled = true
      return g;
    });
    
    if (azureIds && azureIds.length > 0) {
      let foreign_ids = GeneralUtil.chunck(azureIds, 80);
      observableFrom(foreign_ids)
        .pipe(concatMap(async (ids: any) => {
          let responseGroup = await lastValueFrom(this.getGroupsByForeignId(ids)) // // .toPromise();
          return responseGroup
            .filter(g => selectedGroups.filter(s => s.id == g.origin.foreign_id).length > 0)
            .map(g => { g.local_printing_enabled = true; return g; });
        }))
        .pipe(toArray())
        .subscribe((result: any[]) => {
          let groups = [...[].concat(...result), ...ezeepGroups];

          if (!groups || groups.length == 0) {
            this.setPrintersServiceGroups();
          }
          else {
            observableFrom(groups)
              .pipe(mergeMap(async (g: any) => {
                await lastValueFrom(this.groupsService.update(g, g.id)) // // .toPromise();
              }))
              .pipe(last())
              .subscribe(async () => {
                await GeneralUtil.sleep(1000);
                this.setPrintersServiceGroups();
              });
          }
        });
    } else {
      if (!ezeepGroups || ezeepGroups.length == 0) {
        this.setPrintersServiceGroups();
      }
      else {
        observableFrom(ezeepGroups)
          .pipe(mergeMap(async (g: any) => {
            await lastValueFrom(this.groupsService.update(g, g.id)) // // .toPromise();
          }))
          .pipe(last())
          .subscribe(async () => {
            await GeneralUtil.sleep(1000);
            this.setPrintersServiceGroups();
          });
      }
    }
  }

  setPrintersServiceGroups() {
    this.printersServiceGroupsSubject.next({isLoading: true, groups: []});
    this.groupsService.list(undefined, 'true', undefined, undefined, this.printersServiceGroupsSearchText, undefined, this.pageSize, this.pageIndex * this.pageSize)
      .subscribe({
        next: response => {
          this.printersServiceGroups = [...response.result.results];
          this.printersServiceGroupsCount = response.result.count;
          this.printersServiceGroupsSubject.next({ isLoading: false, count: this.printersServiceGroupsCount, groups: [...this.printersServiceGroups] });
        }, error: error => {
          this.printersServiceGroupsSubject.next({ isLoading: false, count: this.printersServiceGroupsCount, groups: [...this.printersServiceGroups] });
        }
      });
  }

  public getAllLocalSupportGroupsSubject(): Observable<any> {
    return this.localPrinterSupportGroupsSubject.asObservable();
  }

  setAllLocalSupportGroups() {
    this.getAllLocalSupportGroups().subscribe(response => {
      response.then(x => this.localPrinterSupportGroupsSubject.next(x))
    })
  }

  getAllLocalSupportGroups(page_size:number = 100, page_index:number = 0) : Observable<Promise<any>> {
    let offset = page_index * page_size
    return this.groupsService.list(undefined, 'true', undefined, undefined, this.printersServiceGroupsSearchText, undefined, page_size, offset)
      .pipe(map(async response => {
        let groups = []
        if(response.result.next) {
          let nextIndex = page_index + 1
          groups = await lastValueFrom(this.getAllLocalSupportGroups(page_size, nextIndex)) // // .toPromise()
        }
        return [...response.result.results, ...groups];
      }));
  }

  setAdconServiceGroups() {
    this.adconServiceGroupsSubject.next({ isLoading: true, groups: [] });
    this.authUserService.me().subscribe(async me => {
      let orgResult = await lastValueFrom(this.authOrganizationService.get(me.result.organization_id)) // // .toPromise()
      if (orgResult.result.azure_profile) {
        this.adconGroupsService.get()
          .subscribe({
            next: async response => {
              this.adconServiceGroups = [...response.result.value];
              this.adconServiceTenantId = response.result.tenantId;
              if (response.result.next) {
                let allGroups = await lastValueFrom(this.getAllAzureGroups(response.result.next)) // // .toPromise()
                this.adconServiceGroups = [...this.adconServiceGroups, ...allGroups]
              }
              this.filterAdconServiceGroups()
                .subscribe(async () => {
                  this.adconServiceGroupsSubject.next({ isLoading: false, count: this.adconServiceGroups.length, groups: [...this.adconServiceGroups] });
                });
            }, error: error => {
              this.adconServiceGroupsSubject.next({ isLoading: false, count: this.adconServiceGroups.length, groups: [...this.adconServiceGroups] });
            }
          });
      } else {
        this.adconServiceGroupsSubject.next({ isLoading: false, count: this.adconServiceGroups.length, groups: [...this.adconServiceGroups] });
      }
    });
  }

  getAllAzureGroups(next:string) : Observable<Promise<any>> {
    return this.adconGroupsService.get()
      .pipe(map(async response => {
        let groups = []
        if(response.result.next) {
          groups = await lastValueFrom(this.getAllAzureGroups(response.result.next)) // // .toPromise()
        }
        return [...response.result.value, ...groups];
      }));
  }

  filterAdconServiceGroups() {
    if(this.adconServiceGroups.length == 0) return of(null)
    let foreign_ids = GeneralUtil.chunck(this.adconServiceGroups.map(x => x.id), 80)
    return observableFrom(foreign_ids)
      .pipe(mergeMap(async (ids: any) => {
        let response = await lastValueFrom(this.getCurrentLocalSupportEnabledGroups(ids)) // // .toPromise();
        if (response.length > 0) {
          response.forEach(group => {
            this.adconServiceGroups = this.adconServiceGroups.filter(adconServiceGroup => adconServiceGroup.id !== group.origin.foreign_id);
          });
        }
        this.adconServiceGroups = this.adconServiceGroups.filter(adconServiceGroup => this.adconServiceGroupsSearchText == undefined || adconServiceGroup.displayName.toLowerCase().includes(this.adconServiceGroupsSearchText.toLowerCase()));
        this.adconServiceGroupsSearchText = null;
        this.adconServiceGroupsCount = this.adconServiceGroups.length;
      }))
      .pipe(last());
  }

  getCurrentLocalSupportEnabledGroups(foreign_ids:string[]) : Observable<any> {
    return this.groupsService.list(undefined, 'true', undefined, foreign_ids.join('|'), undefined, undefined, foreign_ids.length, 0)
      .pipe(map(response => {
        return response.result.results;
      }));
  }
  
  public getAllEzeepGroups(page_size:number, page_index:number) : Observable<any> {
    let offset = page_index * page_size
    return this.groupsService.list(undefined, undefined, undefined, undefined, this.ezeepGroupSearchText, undefined, page_size, offset)
      .pipe(map(async response => {
        let groups = []
        if(response.result.next) {
          let nextIndex = page_index + 1
          groups = await lastValueFrom(this.getAllEzeepGroups(page_size, nextIndex)) // // .toPromise()
        }
        this.ezeepGroupSearchText = undefined
        return [...response.result.results, ...groups];
      }));
  }

  setSearchText(searchText: string) {
    this.adconServiceGroupsSearchText = searchText.toLocaleLowerCase();
    this.ezeepGroupSearchText = searchText.toLocaleLowerCase();
    this.setAdconServiceGroups();
  }

  setPage(pageIndex: number, pageSize: number, isInitial = false) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    if (!isInitial) {
      this.setPrintersServiceGroups();
    }
  }

  getGroupsByForeignId(foreign_id:string[]) : Observable<any> {
    let foreign_ids = GeneralUtil.chunck(foreign_id, 80);
    return observableFrom(foreign_ids)
      .pipe(concatMap(async (ids: any) => {
        let result = []
        let tries = 0
        do {
          result = await lastValueFrom(this.groupsService.list(undefined, 'false', undefined, ids.join('|'), undefined, undefined, foreign_id.length, 0)
          .pipe(map(response => {
            return response.result.results;
          }))) // // .toPromise();
          tries++
        } while(result.length == 0 && tries < 3)

        return result;
      }))
      .pipe(toArray())
      .pipe(map(r => [].concat(...r)))
  }
}
