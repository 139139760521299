import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
  Input,
} from '@angular/core'
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator'
import { MatSort } from '@angular/material/sort'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, env } from '../../../app.config'
import { Subject, Subscription, lastValueFrom } from 'rxjs'
import { UsersgroupsGroupsService } from '../../../modules/shared/services/usersgroups-groups.service'
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog'
import { GroupDetailsDialogComponent } from './group-details-dialog/group-details-dialog.component'
import { ImportAzureAdGroupDialogComponent } from './import-azure-ad-group-dialog/import-azure-ad-group-dialog.component'
import {
  AuthOrganizationApiService,
  AuthUserApiService,
} from '../../../modules/shared/services/auth-api.service'
import { debounceTime } from 'rxjs/operators'
import { SubscriptionService } from '../../../modules/shared/services/subscription.service'

@Component({
  selector: 'ezd-usersgroups-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit {
  columnDefs = ['name', 'description', 'source', 'edit'] //['select', 'name', 'description', 'source', 'edit']
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  @ViewChild('searchGroupsList') search: ElementRef
  maxPageLength = 100
  defaultPageSize = DEFAULT_PAGE_SIZE
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS
  pageIndex: number
  pageSize: number = DEFAULT_PAGE_SIZE
  isLoading = true
  sorting: string
  groupsServiceSubscription: Subscription
  selectedGroupsCount: number = 0
  disableRemoveGroup = this.selectedGroupsCount == 0
  disableAddUsers = true
  disableRemoveUsers = true
  disableRenameGroup = true
  showDialog: boolean
  operationType: string
  @Output() showGroupDialog: EventEmitter<any> = new EventEmitter<any>()
  groupsDataSource = new MatTableDataSource([])
  env: string
  isAzureProfile: boolean
  isEzeepOrg: any
  searchTextChanged = new Subject<string>()
  // @Input() groupsEnabled: boolean;
  enableGroups: boolean

  constructor(
    private groupsService: UsersgroupsGroupsService,
    private authUserService: AuthUserApiService,
    private dialog: MatDialog,
    private subscriptionService: SubscriptionService,
    private authOrganizationService: AuthOrganizationApiService
  ) {
    this.env = env
  }

  ngOnInit() {
    this.subscriptionService.getSubscription().subscribe((result) => {
      this.enableGroups =
        result.features && result.features.groups_printer_assignments == true ? true : false
    })
    this.groupsDataSource.sort = this.sort
    this.groupsService.setPage(0, this.defaultPageSize, true)
    this.groupsServiceSubscription = this.groupsService.getFilteredGroups().subscribe((result) => {
      if (!result.groups) return

      this.isLoading = result.isLoading
      result.groups.map((g) => {
        g.source = g.origin && g.origin.provider ? g.origin.provider : 'Ezeep'
        g.members_count = 'XX'
        g.printers_count = 'XX'
      })
      this.groupsDataSource.data = result.groups

      this.selectedGroupsCount = this.groupsService.getSelectedGroupsCount()
      this.disableRemoveGroup = this.selectedGroupsCount == 0
      this.disableAddUsers = this.selectedGroupsCount == 0
      this.disableRemoveUsers = this.selectedGroupsCount == 0
      this.disableRenameGroup = this.selectedGroupsCount != 1
      this.pageIndex = result.pageIndex
      this.pageSize = this.defaultPageSize
      this.maxPageLength = result.count

      setTimeout(() => {
        if (this.search) {
          this.search.nativeElement.focus()
        }
      }, 500)
    })
    this.groupsService.applyFilters()
    this.authUserService.me().subscribe(async (me) => {
      let orgResult = await lastValueFrom(
        this.authOrganizationService.get(me.result.organization_id)
      ) // .toPromise()
      this.isEzeepOrg = orgResult.result.azure_profile == null
      if (orgResult.result.azure_profile) {
        this.isAzureProfile = true
      } else {
        this.isAzureProfile = false
      }
    })

    this.groupsService.setSearchText(null)
    this.searchTextChanged.pipe(debounceTime(400)).subscribe((result) => {
      this.groupsService.setSearchText(result)
    })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.groupsServiceSubscription) this.groupsServiceSubscription.unsubscribe()
  }

  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue)
  }

  selectAllVisibleGroups(event) {
    if (event.checked) {
      this.groupsService.selectGroups(this.groupsDataSource.data)
    } else {
      this.groupsService.unselectGroups(this.groupsDataSource.data)
    }
  }

  selectGroup(event, groups) {
    if (event.checked) {
      this.groupsService.selectGroups([groups])
    } else {
      this.groupsService.unselectGroups([groups])
    }
  }

  openImportAzureAdGroupDialog() {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = false
    dialogConfig.width = '600px'
    dialogConfig.panelClass = 'ezp-dialog'

    this.dialog.open(ImportAzureAdGroupDialogComponent, dialogConfig)
  }

  openGroupDetailsDialog(id: string | undefined, create: boolean) {
    this.groupsService.cancelGroupToEdit()
    if (!create) this.groupsService.editGroup(id)
    else this.groupsService.createGroup()

    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = false
    dialogConfig.width = '600px'
    dialogConfig.panelClass = 'ezp-dialog'
    dialogConfig.data = create

    this.dialog.open(GroupDetailsDialogComponent, dialogConfig)
  }

  public onPaginationChange(e) {
    this.pageIndex = e.pageIndex
    this.pageSize = e.pageSize
    this.groupsService.setPage(this.pageIndex, this.pageSize)
  }

  sortChange(e) {
    let colName = ''
    colName = e.active

    if (colName == 'source') colName = 'provider'

    const direction = e.direction === 'desc' ? '-' : e.direction === 'asc' ? '' : undefined
    if (direction === undefined) {
      this.sorting = colName
    } else {
      this.sorting = direction + colName
    }

    this.groupsService.setSorting(this.sorting)
  }

  removeGroup() {
    this.groupsService.removeGroups()
  }

  renameGroup() {
    this.showGroupDialog.emit({
      show: true,
      operationType: 'Rename',
      groupName: this.groupsService.getSelectedGroups()[0].name,
    })
  }
}
