import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ezd-loading-icon',
  templateUrl: './loading-icon.component.html',
  styleUrls: ['./loading-icon.component.scss']
})
export class LoadingIconComponent implements OnInit {

  @Input() isLoading:boolean = false;
  constructor() { }

  ngOnInit() {
  }

}
