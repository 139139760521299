<section
  class="expansion-panel"
  [ngClass]="{ 'expansion-panel--expanded': isExpanded }"
>
  <header
    class="expansion-panel__head"
    (click)="onSelect()"
    matRipple
    matRippleColor="rgba(46, 53, 56, 0.1)"
  >
    <span class="mat-body-2 expansion-panel__title efa-text efa-text--he" [id]="title.toLowerCase() + '-filter-label'">{{
      title
    }}</span>
    <span class="mat-body-2 expansion-panel__subtitle efa-text efa-text--me">{{
      subtitle
    }}</span>
    <mat-icon class="efa-icon efa-icon--me expansion-panel__indicator" [id]="title.toLowerCase() + '-filter-expand-icon'"
      >expand_more</mat-icon
    >
  </header>
  <section class="expansion-panel__body">
    <ng-content></ng-content>
  </section>
</section>
