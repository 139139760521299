import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators'
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs'
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core'
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http'

/* APIs URLs*/
export const printerApiUrl = new InjectionToken<string>('printerApiUrl')
export const adconApiUrl = new InjectionToken<string>('adconApiUrl')
export const subscriptionApiUrl = new InjectionToken<string>('subscriptionApiUrl')
export const authApiUrl = new InjectionToken<string>('authApiUrl')
export const ezpChargebeeApiUrl = new InjectionToken<string>('ezpChargebeeApiUrl')
export const reportingApiUrl = new InjectionToken<string>('reportingApiUrl')

@Injectable({
  providedIn: 'root',
})
export class RequesterApiService {
  protected http: HttpClient
  protected baseUrl: string
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    this.http = http
    this.baseUrl = baseUrl ? baseUrl : '/printing'
  }

  protected processRequest<T extends ApiResult>(
    httpVerb: string,
    url: string,
    options: any,
    typeResponse: new () => T
  ): Observable<ApiResponse<T>> {
    return this.http
      .request(httpVerb, url, options)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.process<T>(response_, typeResponse)
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.process<T>(<any>response_, typeResponse)
            } catch (e) {
              return <Observable<ApiResponse<T>>>(<any>_observableThrow(e))
            }
          } else return <Observable<ApiResponse<T>>>(<any>_observableThrow(response_))
        })
      )
  }

  private process<T extends ApiResult>(
    response: HttpResponseBase,
    typeResponse: new () => T
  ): Observable<ApiResponse<T>> {
    const status = response.status
    const responseBlob =
      response instanceof HttpResponse
        ? response.body
        : (<any>response).error instanceof Blob
        ? (<any>response).error
        : undefined

    let _headers: any = {}
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key)
      }
    }
    if (status === 200 || status === 204 || status == 201 || status == 202) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          let result200: any = null
          let resultData200 =
            _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver)
          if (typeResponse == null)
            return _observableOf<ApiResponse<void>>(new ApiResponse(status, _headers, <any>null))

          result200 = new typeResponse().fromJS(resultData200)
          return _observableOf(new ApiResponse(status, _headers, result200))
        })
      )
    } else if (status !== 200 && status !== 204 && status !== 201) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          return throwException(
            'An unexpected server error occurred.',
            status,
            _responseText,
            _headers
          )
        })
      )
    }
    return _observableOf<ApiResponse<T>>(new ApiResponse(status, _headers, null))
  }

  protected processListRequest<T extends ApiResult>(
    httpVerb: string,
    url: string,
    options: any,
    typeResponse: new () => T
  ): Observable<ApiResponse<ListResult<T>>> {
    return this.http
      .request(httpVerb, url, options)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processList(response_, typeResponse)
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processList(<any>response_, typeResponse)
            } catch (e) {
              return <Observable<ApiResponse<ListResult<T>>>>(<any>_observableThrow(e))
            }
          } else return <Observable<ApiResponse<ListResult<T>>>>(<any>_observableThrow(response_))
        })
      )
  }

  private processList<T extends ApiResult>(
    response: HttpResponseBase,
    typeResponse: new () => T
  ): Observable<ApiResponse<ListResult<T>>> {
    const status = response.status
    const responseBlob =
      response instanceof HttpResponse
        ? response.body
        : (<any>response).error instanceof Blob
        ? (<any>response).error
        : undefined

    let _headers: any = {}
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key)
      }
    }
    if (status === 200 || status === 204) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          let result200: any = null
          let resultData200 =
            _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver)
          if (typeResponse == null)
            return _observableOf<ApiResponse<void>>(new ApiResponse(status, _headers, <any>null))
          //process and processList era equal, but this line; I was expecting too much from generics in typescript
          result200 = new ListResult<T>(typeResponse).listFromJS(typeResponse, resultData200)
          return _observableOf(new ApiResponse(status, _headers, result200))
        })
      )
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          return throwException(
            'An unexpected server error occurred.',
            status,
            _responseText,
            _headers
          )
        })
      )
    }
    //process and processList era equal, but this line; I was expecting too much from generics in typescript
    return _observableOf<ApiResponse<ListResult<T>>>(new ApiResponse(status, _headers, null))
  }
}

export class ApiResult {
  fromJS(resultData200: any): any {
    throw new Error('Method not implemented.')
  }
  listFromJS<T extends ApiResult>(typeT: new () => T, resultData200: any): any {
    throw new Error('Method not implemented.')
  }
  protected init(data: any) {
    throw new Error('Method not implemented.')
  }
}

export class ListResult<T extends ApiResult> extends ApiResult implements IListResult<T> {
  count!: number
  next?: string | undefined
  previous?: string | undefined
  results!: T[]
  searchText: string //to save last search text

  constructor(private typeT?: new () => T, data?: IListResult<T>) {
    super()
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property]
      }
    }
    if (!data) {
      this.results = []
    }
  }

  init(data?: any) {
    if (data) {
      this.count = data['count']
      this.next = data['next']
      this.previous = data['previous']
      if (Array.isArray(data['results'])) {
        this.results = [] as any
        for (let item of data['results']) {
          this.results!.push(new this.typeT().fromJS(item))
        }
      }

      if (Array.isArray(data)) {
        this.results = [] as any
        for (let item of data) {
          this.results!.push(new this.typeT().fromJS(item))
        }
      }
    }
  }

  listFromJS<T extends ApiResult>(typeT: new () => T, data: any): ListResult<T> {
    data = typeof data === 'object' ? data : {}
    let result = new ListResult<T>(typeT)
    result.init(data)
    return result
  }
}

export interface IListResult<T extends ApiResult> {
  count: number
  next?: string | undefined
  previous?: string | undefined
  results: T[]
}

export class ApiResponse<TResult> {
  status: number
  headers: { [key: string]: any }
  result: TResult

  constructor(status: number, headers: { [key: string]: any }, result: TResult) {
    this.status = status
    this.headers = headers
    this.result = result
  }
}

export class ApiException extends Error {
  message: string
  status: number
  response: string
  headers: { [key: string]: any }
  result: any

  constructor(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result: any
  ) {
    super()

    this.message = message
    this.status = status
    this.response = response
    this.headers = headers
    this.result = result
  }

  protected isApiException = true

  static isApiException(obj: any): obj is ApiException {
    return obj.isApiException === true
  }
}

function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): Observable<any> {
  if (result !== null && result !== undefined) return _observableThrow(result)
  else return _observableThrow(new ApiException(message, status, response, headers, null))
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next('')
      observer.complete()
    } else {
      let reader = new FileReader()
      reader.onload = (event) => {
        observer.next((<any>event.target).result)
        observer.complete()
      }
      reader.readAsText(blob)
    }
  })
}
