<mat-icon class="icon">timer</mat-icon>
<div class="text">
  <div class="mat-subheading-2 title" *ngIf="subscriptionStatus && subscriptionStatus.status && subscriptionStatus.status == 'trial'">
    {{ 'SUBSCRIPTION.TRIAL_ACTIVE.STATUS' | translate: {value: subscriptionStatus.daysLeft} }}
  </div>
  <div class="mat-body ezp-has-text-link subtitle">
    {{ 'SUBSCRIPTION.TRIAL_ACTIVE.CONTINUE' | translate }}
  </div>
</div>
<button
  class="ezp-button"
  mat-flat-button
  color="primary"
  (click)="checkoutExistingSubscription()"
  disabled
>
  {{ 'SUBSCRIPTION.ACTIONS.MANAGE_SUBSCRIPTION' | translate }}
</button>
