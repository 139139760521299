<ezd-table-placeholder
  *ngIf="!enableGroups"
  [columns]="['Name', 'Description', 'Members', 'Printer Assignments','Source']" 
  [rows]="8"
  mainIcon="supervisor_account" 
  [heading]="'USERSGROUPS.GROUPS.GROUPS_NOT_AVAILABLE.NOT_AVAILABLE' | translate"
  [action]="{title: 'USERSGROUPS.GROUPS.GROUPS_NOT_AVAILABLE.BUTTON_TEXT' | translate, icon : '' ,path: 'subscription'}"
  [description]="'USERSGROUPS.GROUPS.GROUPS_NOT_AVAILABLE.DESCRIPTION' | translate">
</ezd-table-placeholder>

<div *ngIf="enableGroups">
  <div class="ezp-layout__head ezp-layout__head--split" *ngIf="!isEzeepOrg">
    <ezd-search-groups></ezd-search-groups>
    <div class="ezp-layout__head__actions">
      <button mat-raised-button id="add-group" color="primary"
        class="ezp-button ezp-button--icon ezp-button--icon--leading" [matMenuTriggerFor]="addGroupsMenu">
        <mat-icon>add</mat-icon>{{ 'USERSGROUPS.GROUPS.ADD_GROUP_TEXT' | translate }}
      </button>
      <mat-menu #addGroupsMenu="matMenu" xPosition="before">
        <button mat-menu-item id="add-ezeep-group" (click)="openGroupDetailsDialog(undefined, true)">Add ezeep
          Group</button>
        <button mat-menu-item id="import-azuread-group" (click)="openImportAzureAdGroupDialog()"
          *ngIf="isAzureProfile">Import Azure AD Group</button>
      </mat-menu>
    </div>
  </div>
  <div class="ezp-layout__head ezp-layout__head--split" *ngIf="isEzeepOrg">
    <ezd-search-groups></ezd-search-groups>
    <button mat-raised-button id="open-group-details" color="primary"
      class="ezp-button ezp-button--icon ezp-button--icon--leading" (click)="openGroupDetailsDialog(undefined, true)">
      <mat-icon>add</mat-icon>{{ 'USERSGROUPS.GROUPS.ADD_GROUP_TEXT' | translate }}
    </button>
  </div>
  <div class="ezp-layout__part">
    <div class="ezp-table">
      <table mat-table [dataSource]="groupsDataSource" matSort (matSortChange)="sortChange($event)"
        matSortDisableClear="true" class="ezp-table__data">
   
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="ezp-table__cell ezp-table__cell--text"
            id="groups-name-text">
            {{ 'USERSGROUPS.GROUPS.GROUPS_LIST.NAME' | translate }}
          </th>
          <td mat-cell *matCellDef="let group" class="ezp-table__cell ezp-table__cell--text" [id]="group.id + '-name'">
            {{ group.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="ezp-table__cell ezp-table__cell--text"
            id="groups-desc-text">
            {{ 'USERSGROUPS.GROUPS.GROUPS_LIST.DESCRIPTION' | translate }}
          </th>
          <td mat-cell *matCellDef="let group" class="ezp-table__cell ezp-table__cell--text"
            [id]="group.id + '-description'">
            {{ group.description }}
          </td>
        </ng-container>
        <ng-container matColumnDef="source">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="ezp-table__cell ezp-table__cell--text"
            id="groups-source-text">
            {{ 'USERSGROUPS.GROUPS.GROUPS_LIST.SOURCE' | translate }}
          </th>
          <td mat-cell *matCellDef="let group" class="ezp-table__cell ezp-table__cell--text"
            [id]="group.id + '-source'">
            {{ group.source === "Ezeep" ? "ezeep" : group.source }}
          </td>
        </ng-container>
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon">
            <mat-menu #groupsMenu="matMenu" xPosition="before">
              <button mat-menu-item id="group-manage-printers">{{ 'USERSGROUPS.GROUPS.EDIT_GROUP.MANAGE_PRINTERS' |
                translate }}</button>
              <mat-divider></mat-divider>
              <button mat-menu-item id="group-manage-members">{{ 'USERSGROUPS.GROUPS.EDIT_GROUP.MANAGE_MEMBERS' |
                translate }}</button>
              <button mat-menu-item id="group-edit-description">{{ 'USERSGROUPS.GROUPS.EDIT_GROUP.EDIT_DESCRIPTION' |
                translate }}</button>
              <button mat-menu-item id="group-rename">{{ 'USERSGROUPS.GROUPS.EDIT_GROUP.RENAME_GROUP' | translate
                }}</button>
              <mat-divider></mat-divider>
              <button mat-menu-item id="remove-groups" class="ezp-menu__item ezp-menu__item--destructive">
                {{ 'USERSGROUPS.GROUPS.EDIT_GROUP.REMOVE_GROUPS' | translate }}
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let group" class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon"
            [id]="group.id + '-edit'">
            <button mat-icon-button id="open-group-details" (click)="openGroupDetailsDialog(group.id, false)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnDefs"></tr>
        <tr mat-row *matRowDef="let group; columns: columnDefs"></tr>
      </table>
      <mat-spinner *ngIf="isLoading" class="ezp-spinner ezp-spinner--large ezp-spinner--table"></mat-spinner>
      <mat-paginator class="efa-paginator" id="groups-paginator" #paginator [length]="maxPageLength"
        [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
        (page)="onPaginationChange($event)">
      </mat-paginator>
    </div>
  </div>
</div>

