import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { WebhookEditorDialogueComponent } from '../webhook-editor-dialogue.component';
import { triggers } from '../../constants';
import { WebhooksService } from '../../../../../modules/shared/services/webhooks.service';
import { WebhookTestResponse } from '../../../../../modules/shared/services/webhooks-api.service';
import { ErrorService } from '../../../../../modules/shared/services/error.service';

@Component({
  selector: 'ezd-test-run-dialogue',
  templateUrl: './test-run-dialogue.component.html',
  styleUrls: ['./test-run-dialogue.component.scss']
})
export class TestRunDialogueComponent implements OnInit {

  triggerOptions: string[] = triggers;
  topic: string = triggers[0];
  isLoading = false
  constructor(public dialogRef: MatDialogRef<WebhookEditorDialogueComponent>, private webbhookService: WebhooksService,private errorService: ErrorService) { }
  result: WebhookTestResponse
  ngOnInit(): void {

  }

  close() {
    this.dialogRef.close();
  }

  testURL() {
    this.isLoading = true
    this.webbhookService.testURL(this.topic).subscribe({
      next: rsp => {
        this.isLoading = false
        this.result = rsp
      }, error: err => {
        this.errorService.open(err.message)
        this.isLoading = false
      }
    })
  }

  countNewlines(str) {
    const newlineCount = (str.match(/\n/g) || []).length;
    return newlineCount;
}

}
