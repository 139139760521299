<h2 mat-dialog-title id="add-users-text">
  {{ 'USERSGROUPS.USERS.INVITE_USER.ADD_USERS' | translate }}
</h2>
<div mat-dialog-content>
  <form [formGroup]="invitationsForm">
    <div class="ezp-grid ezp-grid--gutter ezp-grid--spacing-vertical" data-grid-cols="1">
      <mat-form-field class="ezp-form-field ezp-form-field--full ezp-form-field--no-helper" appearance="outline">
        <mat-label id="email-addresses-text">{{
          'USERSGROUPS.USERS.INVITE_USER.EMAIL_ADDRESSES' | translate
          }}</mat-label>
        <mat-chip-list #invitationsList formArrayName="emails">
          <mat-chip *ngFor="let invitation of invitations" [selectable]="selectable" [removable]="removable"
            (removed)="removeInvitation(invitation)" [id]="invitation.email" [color]="invitation.invalid ? 'warn' : ''"
            selected>
            {{ invitation.email }}
            <mat-icon matChipRemove id="cancel-email">cancel</mat-icon>
          </mat-chip>
          <input type="email" id="emails-list" placeholder="user@example.com" [matChipInputFor]="invitationsList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="addInvitation($event)" (paste)="paste($event)" />
        </mat-chip-list>
        <mat-icon matSuffix>mail_outline</mat-icon>
      </mat-form-field>
    </div>

  <!-- Limit paid plan -->
  <div class="limit" *ngIf="status != 'trial' && !maxUsers && planQuantity && (usersCount >= planQuantity) && sourceType == 'chargebee' && ezeepLink == 'Named User'">
    <mat-icon class="limit__info">info</mat-icon>
    <div class="limit__message mat-body"
      [innerHTML]="'USERSGROUPS.USERS.INVITE_USER.LIMIT_REACHED_UPGRADE' | translate"></div>
  </div>
  <!-- Limit free plan -->
  <div class="limit" *ngIf="maxUsers && (usersCount >= maxUsers)">
    <mat-icon class="limit__warning">warning</mat-icon>
    <div class="limit__message mat-body" [innerHTML]="'USERSGROUPS.USERS.INVITE_USER.LIMIT_REACHED_BLOCK' | translate">
    </div>
  </div>
  <mat-error *ngIf="invitationsForm.get('emails').hasError('incorrectEmail')">{{
    'USERSGROUPS.USERS.INVITE_USER.INVALID_EMAIL_NOTIFICATION' | translate }}</mat-error>


    <div class="options">
      <mat-form-field appearance="fill" class="ezp-form-field ezp-form-field--no-helper">
        <mat-label>{{ 'USERSGROUPS.USERS.INVITE_USER.INVITATION_EXPIRY' | translate }}</mat-label>
        <input
          (dateChange)="expirationDateChange($event)"
          [min]="minDate"
          [matDatepicker]="expirationDatePicker"
          type="hidden"
        />
        <input
          [value]="expirationDateLabel + ' ' + ('USERSGROUPS.USERS.INVITE_USER.DAYS' | translate)"
          matInput
          readonly
        />
        <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
        <mat-datepicker
          #expirationDatePicker
          startView="month"
          [startAt]="startDate"
          color="primary"
        ></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
        <mat-label id="user-role-text">{{ 'USERSGROUPS.USERS.INVITE_USER.INVITATION_LANGUAGE' | translate }}</mat-label>
        <mat-select id="user-role" [value]="preferredLanguage" (selectionChange)="onLanguageChange($event)">
          <mat-option value="en">{{ 'EN' | translate }}</mat-option>
          <mat-option value="de">{{ 'DE' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
</form>
</div>
<div mat-dialog-actions>
  <button mat-button id="cancel-invite-user" (click)="close()" color="primary" class="ezp-button"
    [disabled]="isLoading">
    {{ 'CANCEL' | translate }}
  </button>
  <button mat-button id="invite-user" (click)="invite()" color="primary"
    [disabled]="isLoading || invitations.length == 0 || (maxUsers && usersCount >= maxUsers)">
    <mat-icon [ngClass]="{ 'fa-spin': isLoading }" *ngIf="isLoading">cached</mat-icon>
    {{ 'USERSGROUPS.USERS.INVITE_USER.INVITE' | translate }}
  </button>
</div>