<div class="ezp-layout__page ezp-layout__page--contain">
  <div class="ezp-layout__content ezp-layout__content--contain" style="height: fit-content">
    <div class="mat-headline" id="policies-headline">Policies</div>
    <div class="ezp-layout__part ezp-layout__part--contain">
      <div class="ezp-panels">
      <ezd-policy-panel id="user-selection-in-portal-panel" [title]="'POLICIES.PRINTER_SELECTION.TITLE' | translate"
        [description]="'POLICIES.PRINTER_SELECTION.DESCRIPTION' | translate" [disabled]="printerSelection.disabled"
        [disabledTitle]="'POLICIES.PRINTER_SELECTION.DISABLE' | translate" [groups]="printerSelection.groups" [groupsEnabled]="enableGroups"
        [type]="'MYPRINTERS'"></ezd-policy-panel>
      <ezd-policy-panel id="local-printer-support-panel" [title]="'POLICIES.LOCAL_PRINTER_SUPPORT.TITLE' | translate"
        [description]="'POLICIES.LOCAL_PRINTER_SUPPORT.DESCRIPTION' | translate"
        [disabled]="localPrinterSupport.disabled" [disabledTitle]="'POLICIES.LOCAL_PRINTER_SUPPORT.DISABLE' | translate"
        [groups]="localPrinterSupport.groups" [type]="'LOCALPRINTERSUPPORT'" [groupsEnabled]="enableGroups"></ezd-policy-panel>
      <ezd-policy-panel id="pull-printing-panel" [title]="'POLICIES.PULL_PRINTING.TITLE' | translate"
        [description]="'POLICIES.PULL_PRINTING.DESCRIPTION' | translate"
        [disabledTitle]="'POLICIES.PULL_PRINTING.DISABLE' | translate" [groups]="securePullPrinting.groups"
        [disabledGroups]="true" [type]="'PULLPRINTING'" [disabled]="false"
        [disabled]="securePullPrinting.disabled || (isValidSubscription == false || featurePullPrinting == false)"
        [grayOut]="isValidSubscription == false || featurePullPrinting == false" [groupsEnabled]="enableGroups"></ezd-policy-panel>
      <ezd-policy-panel id="print-later-panel" [title]="'POLICIES.PRINT_LATER.TITLE' | translate"
        [description]="'POLICIES.PRINT_LATER.DESCRIPTION' | translate"
        [disabled]="printLater.disabled || (isValidSubscription == false || featurePrintLater == false)"
        [disabledTitle]="'POLICIES.PRINT_LATER.DISABLE' | translate" [type]="'PRINTLATER'" [disabledGroups]="true"
        [grayOut]="isValidSubscription == false || featurePrintLater == false" [groupsEnabled]="enableGroups"></ezd-policy-panel>
      </div>
    </div>
  </div>
</div>