import { mergeMap as _observableMergeMap, catchError as _observableCatch, map, switchMap, catchError } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf, combineLatest, of } from 'rxjs';
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequesterApiService, ApiResponse, ApiResult, ListResult, reportingApiUrl } from './requester-api.service';
import { EZEEP_REPORTING_API } from '../../../app.config';

export interface IWebhooksApiService {
  list(order: string | null | undefined, limit: number | null | undefined, offset: number | null | undefined): Observable<ApiResponse<ListResult<Webhook>>>;
  read(id: string): Observable<ApiResponse<Webhook>>;
  post(data: Webhook): Observable<ApiResponse<Webhook>>;
  delete(id: string): Observable<ApiResponse<void>>;
  update(data: Webhook, id: string): Observable<ApiResponse<Webhook>>;
}

export interface IWebhooksSettingsApiService {
  read(): Observable<ApiResponse<WebhooksSettings>>;
  update(data: WebhooksSettings): Observable<ApiResponse<WebhooksSettings>>;
}

@Injectable({
  providedIn: 'root'
})

export class WebhooksApiService extends RequesterApiService implements IWebhooksApiService {
  httpClient: any;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(reportingApiUrl) baseUrl?: string) {
    super(http, baseUrl);
  }

  list(order: string | null | undefined, limit: number | null | undefined, offset: number | null | undefined): Observable<ApiResponse<ListResult<Webhook>>> {
    let url_ = EZEEP_REPORTING_API + "/webhooks/endpoints?";

    if (order !== undefined)
      url_ += "order=" + encodeURIComponent("" + order) + "&";
    if (limit !== undefined)
      url_ += "limit=" + encodeURIComponent("" + limit) + "&";
    if (offset !== undefined)
      url_ += "offset=" + encodeURIComponent("" + offset) + "&";
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };

    return this.processListRequest("get", url_, options_, Webhook)
  }

  read(id: string): Observable<ApiResponse<Webhook>> {
    let url_ = EZEEP_REPORTING_API + "/webhooks/endpoints/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };

    return this.processRequest("get", url_, options_, Webhook);
  }

  post(data: Webhook): Observable<ApiResponse<Webhook>> {
    let url_ = EZEEP_REPORTING_API + "/webhooks/endpoints";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(data);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.processRequest("post", url_, options_, Webhook);
  }

  update(data: Webhook, id: string): Observable<ApiResponse<Webhook>> {
    let url_ = EZEEP_REPORTING_API + "/webhooks/endpoints/{id}/";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(data);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/merge-patch+json",
        "Accept": "application/json",
        "Cache-Control": "no-cache"
      })
    };

    return this.processRequest("patch", url_, options_, Webhook);
  }

  delete(id: string): Observable<ApiResponse<void>> {
    let url_ = EZEEP_REPORTING_API + "/webhooks/endpoints/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
      })
    };

    return this.processRequest<null>("delete", url_, options_, null);
  }

  test(data:any){
    let url_ = EZEEP_REPORTING_API + "/webhooks/endpoints/test";

    const content_ = JSON.stringify(data);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Cache-Control": "no-cache"
      })
    };

    return this.processRequest("put", url_, options_, WebhookTestResponse);
  }
}

@Injectable({
  providedIn: 'root'
})

export class WebhooksSettingsApiService extends RequesterApiService implements IWebhooksSettingsApiService {
  httpClient: any;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(reportingApiUrl) baseUrl?: string) {
    super(http, baseUrl);
  }

  read(): Observable<ApiResponse<WebhooksSettings>> {
    let url_ = EZEEP_REPORTING_API + "/webhooks/settings";
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };

    return this.processRequest("get", url_, options_, WebhooksSettings);
  }

  update(data: any): Observable<ApiResponse<WebhooksSettings>> {
    let url_ = EZEEP_REPORTING_API + "/webhooks/settings";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(data);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/merge-patch+json",
        "Accept": "application/json",
        "Cache-Control": "no-cache"
      })
    };

    return this.processRequest("patch", url_, options_, WebhooksSettings);
  }
}

export class Webhook extends ApiResult implements IWebhook {
  authentication_scheme?: string | undefined;
  basic_password?: string | undefined;
  basic_username?: string | undefined;
  disabled?: boolean | undefined;
  endpoint_id?: string | undefined;
  last_success?: boolean | undefined;
  name?: string | undefined;
  topics?: string[] | undefined;
  url?: string | undefined;
  id?: string | undefined;
  has_failed_events? : boolean |undefined

  constructor(data?: IWebhook) {
    super();
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      (<any>this).authentication_scheme = data["authentication_scheme"];
      (<any>this).basic_password = data["basic_password"];
      (<any>this).basic_username = data["basic_username"];
      (<any>this).disabled = data["disabled"];
      (<any>this).endpoint_id = data["endpoint_id"];
      (<any>this).last_success = data["last_success"];
      (<any>this).name = data["name"];
      (<any>this).topics = data["topics"];
      (<any>this).has_failed_events = data["has_failed_events"];
      (<any>this).url = data["url"];
      (<any>this).id = data["id"];
    
    }
  }

  fromJS(data: any): Webhook {
    data = typeof data === 'object' ? data : {};
    let result = new Webhook();
    result.init(data);
    return result;
  }
}

export interface IWebhook {
  authentication_scheme?: string | undefined;
  basic_password?: string | undefined;
  basic_username?: string | undefined;
  disabled?: boolean | undefined;
  endpoint_id?: string | undefined;
  last_success?: boolean | undefined;
  name?: string | undefined;
  topics?: string[] | undefined;
  url?: string | undefined;
  id?: string | undefined;
  has_failed_events? : boolean |undefined
}

export class WebhooksSettings extends ApiResult implements IWebhooksSettings {
    file_name_obfuscated?: boolean | undefined;
    user_email_disabled?: boolean | undefined;
    user_name_disabled?: boolean | undefined;

  constructor(data?: IWebhooksSettings) {
    super();
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.file_name_obfuscated = data["file_name_obfuscated"];
      this.user_email_disabled = data["user_email_disabled"];
      this.user_name_disabled = data["user_name_disabled"];
    }
  }

  fromJS(data: any): WebhooksSettings {
    data = typeof data === 'object' ? data : {};
    let result = new WebhooksSettings();
    result.init(data);
    return result;
  }
}

export interface IWebhooksSettings {
    file_name_obfuscated?: boolean | undefined;
    user_email_disabled?: boolean | undefined;
    user_name_disabled?: boolean | undefined;
}

export class WebhookTestResponse extends ApiResult implements IWebhookTestResponse {
  request: {
    body: string,
    headers: string;
    start_line: string;
  };
  response: {
    body: string;
    headers: string;
    start_line: string;
  };
  status: string;
  error?: string;

  constructor(data?: WebhookTestResponse) {
    super();
    if (data) {
      this.request = data.request;
      this.response = data.response;
      this.status = data.status;
      this.error = data.error ? data.error : null;
    }
  }

  init(data?: any) {
    if (data) {
      this.request = data.request;
      this.response = data.response;
      this.status = data.status;
      this.error = data.error ? data.error : null;
    }
  }

  fromJS(data: any): WebhookTestResponse {
    data = typeof data === 'object' ? data : {};
    let result = new WebhookTestResponse();
    result.init(data);
    return result;
  }
}

export interface IWebhookTestResponse {
  request: {
    body: string,
    headers: string;
    start_line: string;
  };
  response: {
    body: string;
    headers: string;
    start_line: string;
  };
  status: string;
  error?: string
}