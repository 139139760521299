<ezd-table-placeholder
*ngIf="!isAvailable"
[columns]="['', '']"
[rows]="8"
[heading]="'USERSGROUPS.DOMAINS.DOMAIN_NOT_AVAILABLE.NOT_AVAILABLE' | translate"
[action]="{title: 'USERSGROUPS.DOMAINS.DOMAIN_NOT_AVAILABLE.BUTTON_TEXT' | translate, path: 'subscription' }"
[description]="'USERSGROUPS.DOMAINS.DOMAIN_NOT_AVAILABLE.DESCRIPTION' | translate"
></ezd-table-placeholder>

<div *ngIf="isAvailable">
    <div class="ezp-layout__head ezp-layout__head--split">
      <ezd-search-domain></ezd-search-domain>
      <div class="ezp-layout__head__actions">
        <button
          mat-raised-button
          color="primary"
          class="ezp-button ezp-button--icon ezp-button--icon--leading"
          id="add-domain"
          (click)="addDomainDialog(undefined, true)"
        >
          <mat-icon>add</mat-icon>{{ 'USERSGROUPS.DOMAINS.ADD_DOMAIN_TEXT' | translate }}
        </button>
      </div>
    </div>

    <div class="ezp-layout__part">
      <div class="ezp-table">
        <table
          mat-table
          [dataSource]="domainsDataSource"
          matSort
          (matSortChange)="sortChange($event)"
          matSortDisableClear="true"
          class="ezp-table__data"
        >
          <ng-container matColumnDef="domains">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="ezp-table__cell ezp-table__cell--text"
              id="domains-header-text"
            >
            {{ 'USERSGROUPS.DOMAINS.DOMAINS_LIST.DOMAINS' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let domain"
              class="ezp-table__cell ezp-table__cell--text"
              [id]="domain.id + '-domains'"
            >
              {{ domain.domain }}
            </td>
          </ng-container>
          <ng-container matColumnDef="groups">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="ezp-table__cell ezp-table__cell--text"
              id="domains-group-header-text"
            >
            {{ 'USERSGROUPS.DOMAINS.DOMAINS_LIST.GROUP' | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let domain"
              class="ezp-table__cell ezp-table__cell--text"
              [id]="domain.id + '-assigned-groups'"
            >
              {{ domain.groupsNames }}
            </td>
          </ng-container>
          <ng-container matColumnDef="edit">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon"
              id="edit-domain-icon"
            >
            </th>
            <td
              mat-cell
              *matCellDef="let domain"
              class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon"
              [id]="domain.id + '-edit'"
            >
              <button mat-icon-button (click)="openDomainDetailsDialog(domain.id, false)">
                <mat-icon>edit</mat-icon>
              </button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnDefs"></tr>
          <tr mat-row *matRowDef="let domain; columns: columnDefs"></tr>
        </table>
        <mat-spinner
          *ngIf="isLoading"
          class="ezp-spinner ezp-spinner--large ezp-spinner--table"
        ></mat-spinner>
        <mat-paginator
          class="efa-paginator"
          #paginator
          [length]="maxPageLength"
          [pageSize]="pageSize"
          [pageIndex]="pageIndex"
          [pageSizeOptions]="pageSizeOptions"
          (page)="onPaginationChange($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>

