<h2 mat-dialog-title>{{ 'RENAME_ORGANIZATION.TEXT' | translate }}</h2>
<div mat-dialog-content>
  <div class="ezp-grid ezp-grid--gutter-vertical ezp-grid--spacing-vertical" data-grid-cols="1">
    <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
      <mat-label>{{ 'RENAME_ORGANIZATION.LABEL' | translate }}</mat-label>
      <input matInput [(ngModel)]="currentOrgName" required [customValidator]="nameValidator" #nameCtrl="ngModel"/>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()" color="primary" class="ezp-button" [disabled]="isLoading">
    {{ 'CANCEL' | translate }} 
  </button>
  <button mat-button (click)="renameOrganization()" color="primary" class="ezp-button" [disabled]="isLoading || isDisabled">
    <mat-icon [ngClass]="{ 'fa-spin': isLoading }" *ngIf="isLoading">cached</mat-icon>
    {{ 'DONE' | translate }} 
  </button>
</div> 