import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ConnectorService } from '../../../modules/shared/services/connector.service';
import { Subscription, Subject } from 'rxjs';
import { StorageUtils } from '../../../modules/storage/utils/storage.utils';
import { debounceTime } from 'rxjs/operators';
import { DIALOG_RESPONSE } from '../../../modules/shared/classes/dialog-result';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { NewpasswordDialogComponent } from './newpassword-dialog/newpassword-dialog.component';
import { env } from '../../../app.config';

@Component({
  selector: 'ezd-connectors-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})
export class ConnectorsPropertiesComponent implements OnInit {
  selectedConnector = null;
  showConnectorDetail = false;
  @Output() public forceShowFilterView: EventEmitter<void> = new EventEmitter<void>();
  @Output() public showPrinters: EventEmitter<string> = new EventEmitter<string>();
  @Output() public showDeleteConnectorMessage: EventEmitter<string> = new EventEmitter<string>();
  connectosSubscription: Subscription;
  connectorPropertiesWatcher: Subscription;
  propertiesChanged = new Subject<any>();
  env = env;

  constructor(
    private dialog: MatDialog,private networkPrintersConnectorsService: ConnectorService) {}

  ngOnInit() {
    this.connectorPropertiesWatcher = this.networkPrintersConnectorsService.watchConnectorPropertiesChanges().
      subscribe(result => {
        if(result.status == DIALOG_RESPONSE.SAVE) {
          this.saveChanges();
        }

        if(result.status == DIALOG_RESPONSE.DISCARD) {
          this.discardChanges();
        }
      });

    this.propertiesChanged
      .pipe(
        debounceTime(300))
      .subscribe(result => {
        let hasChanges = false;
        
        if (result.description != undefined && result.description != this.selectedConnector.description) {
          hasChanges = true;
          this.selectedConnector.description = result.description;
        }
        if (result.readable_name != undefined && result.readable_name != this.selectedConnector.readable_name) {
          hasChanges = true;
          this.selectedConnector.readable_name = result.readable_name;
        }

        if (hasChanges) {
          StorageUtils.set(localStorage, "connectorUnsaved", this.selectedConnector);
          this.networkPrintersConnectorsService.changeConnectorPropertiesWatcherStatus(true, DIALOG_RESPONSE.NONE);
        }
      });

    this.connectosSubscription = this.networkPrintersConnectorsService.getFilteredConnectors()
      .subscribe( result => {
        let selectedConnectors = this.networkPrintersConnectorsService.getSelectedConnectors();
        
        if (StorageUtils.get(localStorage, "connectorUnsaved") == null) {
          if (selectedConnectors && selectedConnectors[0]){
            this.selectedConnector = Object.assign({}, selectedConnectors[0]);
            this.showConnectorDetail = true;
          }
        } else {
          this.selectedConnector = StorageUtils.get(localStorage, "connectorUnsaved");
          this.showConnectorDetail = true;
        }
      });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.connectosSubscription.unsubscribe();
    this.connectorPropertiesWatcher.unsubscribe();
  }

  onNameChange(readable_name: string) {
    this.propertiesChanged.next({readable_name: readable_name});
  }

  onDescriptionChange(description: string) {
    this.propertiesChanged.next({description: description});
  }

  showFilterView() {
    this.forceShowFilterView.emit();
  }
  
  discardChanges() {
    this.selectedConnector = Object.assign({}, this.networkPrintersConnectorsService.getSelectedConnectors()[0]);
    StorageUtils.set(localStorage, "connectorUnsaved", null);
    this.networkPrintersConnectorsService.changeConnectorPropertiesWatcherStatus(false, DIALOG_RESPONSE.CANCEL);
    this.forceShowFilterView.emit();
  }

  saveChanges() {
    this.networkPrintersConnectorsService.saveConnector(this.selectedConnector);
    StorageUtils.set(localStorage, "connectorUnsaved", null);
    this.networkPrintersConnectorsService.changeConnectorPropertiesWatcherStatus(false, DIALOG_RESPONSE.CANCEL);
    this.forceShowFilterView.emit();
  }

  deleteConnector() {
    this.showDeleteConnectorMessage.emit(this.selectedConnector.id);
  }

  getPassword() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.width = '600px';
    dialogConfig.panelClass = 'ezp-dialog';
    dialogConfig.data = {id:this.selectedConnector.id};

    const dialogRef = this.dialog.open(NewpasswordDialogComponent, dialogConfig);
  }
}
