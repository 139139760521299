import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, observable, from, of } from 'rxjs';
import {  DefaultGroupAssignmentsService, DefaultGroupAssignment } from './printing-api.service';
import { mergeMap, last, map, concatMap, toArray } from 'rxjs/operators';
import { ApiResponse } from './requester-api.service';
import { DEFAULT_PAGE_SIZE } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class UsersgroupsDomainsService {
  domainsSubject = new BehaviorSubject<any>({ isLoading: true, count: 0, domains: []});
  pageIndex: number = 0;
  pageSize: number = DEFAULT_PAGE_SIZE;
  sorting: any;
  domainsCount: any = 0;
  domains: any = [];
  domainEdited: any;
  domainCreated: any;
  searchText: string;
  
  constructor(private defaultGroupAssignmentsService: DefaultGroupAssignmentsService) { 
  }

  getFilteredDomains() : Observable<any> {
    return this.domainsSubject.asObservable();
  }

  applyFilters(showLoading: boolean = true) {
    const searchText = this.searchText
    this.domainsSubject.next({ isLoading: showLoading, count: this.domainsCount, domains: [], pageSize: this.pageSize, pageIndex: this.pageIndex });
    this.defaultGroupAssignmentsService.list(searchText, this.sorting, this.pageSize, this.pageIndex * this.pageSize).subscribe({
      next: response => {
        if (searchText !== response.result.searchText) return
        this.domains = response.result.results;
        this.domainsCount = response.result.count;
        this.domainsSubject.next({
          isLoading: false,
          count: response.result.count,
          domains: response.result.results,
          pageSize: this.pageSize,
          pageIndex: this.pageIndex
        });
      }, error: error => {
        this.domainsSubject.next({ isLoading: false, count: this.domainsCount, domains: this.domains, pageSize: this.pageSize, pageIndex: this.pageIndex });
      }
    });
  }
  
  setPage(pageIndex: number, pageSize: number, isInitial = false) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    if (!isInitial) {
      this.applyFilters(true);
    }
  }

  setSorting(sorting) {
    this.sorting = sorting;
    this.applyFilters(false);
  }

  draftDomainDetails(domain: any, newDomain: boolean) {
    if (newDomain)
      this.domainCreated = domain;
    else
      this.domainEdited = domain;
  }

  editDomain(id: string) {
    if (id)
      this.domains.map(item => {
        item.toEdit = item.id == id ? true : false
        if(!this.domainEdited && item.toEdit)
          this.domainEdited = item;
      });
    else
      this.domains.map(item => {
        item.toEdit = item.isSelected ? true : false
        if(!this.domainEdited && item.toEdit)
          this.domainEdited = item;
      });

    this.domainsSubject.next({isLoading: false, count: this.domainsCount, domains: this.domains});
  }

  public getDomainToEdit(): any {
    if(!this.domains) return null;

    let domain = this.domains.filter(domain => domain.toEdit)[0];

    return domain;
  }
  
  createDomain() {
    this.domainCreated = {id: "NEWDOMAIN", expression: "", enabled: true, domain: "", toCreate: true, groups: []};
    this.domainsSubject.next({isLoading: false, count: this.domainsCount, groups: [...this.domains]});
  }

  public getDomainToCreate(): any {
    if(!this.domains) return null;

    return this.domainCreated;
  }

  cancelDomainToEdit() {
    this.domains.map(item => {
      item.toEdit = false
      item.toCreate = false
    });
    this.domainEdited = undefined
    this.domainCreated = undefined
  }

  saveDomain(domain:any): Observable<any> {
    if (this.domainEdited) {
      return this.updateDomain(domain)
        .pipe(map(response => {
          return response.result;
        }));
    }

    if (this.domainCreated) {
      return this.defaultGroupAssignmentsService.create(domain)
        .pipe(map(response => {
          return response.result;
        }));
    }
  }

  removeDomain(id:string) : Observable<any> {
    return this.defaultGroupAssignmentsService.delete(id);
  }

  updateDomain(domain:any) : Observable<ApiResponse<DefaultGroupAssignment>> {
    delete domain.expression
    delete domain.enabled
    delete domain.toCreate
    delete domain.toEdit
    delete domain.groupsNames
    return this.defaultGroupAssignmentsService.update(domain, domain.id);
  }

  setSearchText(result: string) {
    this.searchText = result
    this.pageIndex = 0
  }
}
