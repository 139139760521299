import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ActivatedRoute } from '@angular/router';
import { LangaugeService } from '../../../../modules/shared/services/langauge.service';
import { UsersgroupsDomainsService } from '../../../../modules/shared/services/usersgroups-domains.service';

@Component({
  selector: 'ezd-domain-group-dialog',
  templateUrl: './remove-domain-dialog.component.html',
  styleUrls: ['./remove-domain-dialog.component.scss']
})
export class RemoveDomainDialogComponent implements OnInit {
  isLoading = false;
  domainId: any;
  domainName: any;
  lang: any;
  preferredLanguage: any;

  constructor(
    private domainsService: UsersgroupsDomainsService,
    public dialogRef: MatDialogRef<RemoveDomainDialogComponent>,
    private activatedRoute: ActivatedRoute,
    private languageService: LangaugeService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.isLoading = false
      if (data && data.domainDetails) {
        this.domainId = data.domainDetails.id;
        this.domainName = data.domainDetails.domain;
      }
      this.activatedRoute.queryParams.subscribe(params => {
        this.lang = params["lang"]
      })
  }

  ngOnInit(): void {
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value;
    })
  }

  removeDomain() {
    this.isLoading = true
    this.domainsService.removeDomain(this.domainId).subscribe(
      async () => {
        await this.domainsService.applyFilters(true)
        this.dialogRef.close()
      });
  }

  close() {
    this.dialogRef.close()
  }
}
