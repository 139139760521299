<div class="header">
  <div class="mat-headline quick-start__title" id="quickstart-title-text">{{ 'QUICK_START.TITLE' | translate }}</div>
</div>

<div class="setup">
  <!-- Guides -->
  <div class="guides">
    <div class="guides__header">
      <div class="mat-title guides__title" [id]="translations.quick_start.guides_title">{{ translations.quick_start.guides_title }}</div>
      <div class="mat-subheading-2 guides__subtitle" [id]="translations.quick_start.guides_subtitle">
        {{ translations.quick_start.guides_subtitle }}
      </div>
    </div>
    <div class="guides__box" #guidesBox [attr.data-current-guide-index]="currentGuideIndex + 1">
      <div
        class="guide"
        *ngFor="let guide of guides; let i = index"
        (click)="handleCurrentGuideIndex(i)"
        [ngClass]="currentGuideIndex === i ? 'guide--current' : ''"
      >
        <div class="guide__preview" [id]="i + 1">{{ i + 1 }}</div>
        <div class="guide__content">
          <div class="mat-subheading-2 guide__step" [id]="i + 1">{{ i + 1 }}</div>
          <div class="mat-subheading-2 guide__title" [innerHtml]="guide.title" [id]="guide.title"></div>
          <div class="mat-body guide__description" [innerHtml]="guide.description" [id]="guide.description"></div>
          <div class="guide__actions">
            <ng-container *ngFor="let action of guide.actions">
              <a
                *ngIf="action.type === 'url'"
                class="ezp-button"
                mat-button
                [ngClass]="action.primary ? 'mat-flat-button' : 'mat-stroked-button'"
                color="primary"
                [id]="action.id"
                [attr.href]="action.url"
                >{{ action.title }}</a
              >
              <button
                *ngIf="action.type !== 'url'"
                class="ezp-button"
                mat-button
                [ngClass]="action.primary ? 'mat-flat-button' : 'mat-stroked-button'"
                color="primary"
                [id]="action.id"
                (click)="
                  action.type === 'path'
                    ? handleNavigation(action.path)
                    : handleAction(action.id)
                "
              >
                {{ action.title }}
              </button>
            </ng-container>
          </div>
        </div>
        <div class="guide__figure">
          <ctd-animation
            class="guide__animation"
            [data]='guide.figure.animation'
            height="400px"
          ></ctd-animation>
          <div
            *ngIf="guide.figure.type === 'animation'"
            class="guide__animation"
            #guideAnimations
            [attr.data-animation]="guide.figure.animation"
          ></div>
          <img
            *ngIf="guide.figure.type === 'image'"
            [src]="guide.figure.image"
            class="guide__image"
          />
        </div>
      </div>
    </div>
    <div class="guides__nav">
      <button
        class="ezp-button ezp-button--icon ezp-button--icon--leading"
        id="guides-back-button"
        color="primary"
        mat-button
        [ngClass]="currentGuideIndex !== 0 ? 'mat-stroked-button' : ''"
        [disabled]="currentGuideIndex === 0"
        (click)="handleCurrentGuideIndex(currentGuideIndex - 1)"
      >
        <mat-icon>arrow_back</mat-icon>{{ 'BACK' | translate }}
      </button>
      <button
        class="ezp-button ezp-button--icon ezp-button--icon--trailing"
        id="guides-forward-button"
        color="primary"
        mat-button
        [ngClass]="currentGuideIndex + 1 !== guidesCount ? 'mat-stroked-button' : ''"
        [disabled]="currentGuideIndex + 1 === guidesCount"
        (click)="handleCurrentGuideIndex(currentGuideIndex + 1)"
      >
        {{ 'NEXT' | translate }}<mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
  </div>

  <!-- Scenarios -->
  <div class="scenarios">
    <div class="scenarios__header">
      <div class="mat-title scenarios__title" [id]="translations.quick_start.scenarios_title">{{ translations.quick_start.scenarios_title }}</div>
    </div>
    <div class="scenarios__box">
      <div class="scenario" *ngFor="let scenario of scenarios">
        <div class="scenario__figure">
          <img [src]="scenario.figure" class="scenario__image" />
        </div>
        <div class="mat-subheading-2 scenario__title" [innerHtml]="scenario.title" [id]="scenario.title"></div>
        <div class="mat-body scenario__description" [innerHtml]="scenario.description" [id]="scenario.description"></div>
        <a
          class="ezp-button"
          [id]="scenario.link.title"
          mat-stroked-button
          color="primary"
          [href]="scenario.link.url"
          target="_blank"
          rel="noopener"
          >{{ scenario.link.title }}</a
        >
      </div>
    </div>
  </div>
</div>

<div class="feed">
  <div class="feed__header">
    <div class="mat-subheading-2 feed__title" [id]="translations.quick_start.feed_title">{{ translations.quick_start.feed_title }}</div>
  </div>
  <div class="news" *ngFor="let news of newsItems">
    <div class="news__header">
      <div class="mat-caption news__date" [id]="news.date">{{ news.date }}</div>
      <a [href]="news.link" class="mat-body news__title" [id]="news.title" target="_blank" rel="noopener">{{
        news.title
      }}</a>
    </div>
    <a [href]="news.category.archive" class="news__category" target="_blank" rel="noopener">
      <mat-icon class="news__category__icon">{{ news.category.icon }}</mat-icon>
      <span class="mat-caption news__category__title" [id]="news.category.title">{{ news.category.title }}</span>
    </a>
    <div class="news__content">
      <div class="mat-body news__description" [id]="news.description">{{ news.description }}</div>
      <a class="mat-body news__link" [id]="translations.global.learn_more_label" [href]="news.link" target="_blank" rel="noopener">{{
        translations.global.learn_more_label
      }}</a>
    </div>
  </div>
</div>
