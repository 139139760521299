import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  HostListener,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core'
import { NetworkPrintersPrintersService } from '../../../../modules/shared/services/network-printers-printers.service'
import { NetworkPrintersDriversService } from '../../../../modules/shared/services/network-printers-drivers.service'
import { NetworkPrintersGroupsService } from '../../../../modules/shared/services/network-printers-groups.service'
import { ErrorService } from '../../../../modules/shared/services/error.service'
import { NetworkPrintersAssignmentsService } from '../../../../modules/shared/services/network-printers-assignments.service'
import { from as observableFrom, Subscription, Subject, forkJoin, lastValueFrom } from 'rxjs'
import { last, mergeMap, debounceTime, map } from 'rxjs/operators'
import { Clipboard } from '@angular/cdk/clipboard'
import { StorageUtils } from '../../../../modules/storage/utils/storage.utils'
import { DIALOG_RESPONSE } from '../../../../modules/shared/classes/dialog-result'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { GenerateQRCodeService } from '../../../../modules/shared/services/generate-qrcode.service'
import {
  ProfileService,
  UserAssignmentsService,
} from '../../../../modules/shared/services/printing-api.service'
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog'
// import { PrinterQrcodeDialogComponent } from './printer-qrcode-dialog/printer-qrcode-dialog.component';
import { AuthUserApiService } from '../../../../modules/shared/services/auth-api.service'
import { ANYPRINTER_BY_EZEEP } from '../../../../modules/core/constants/core.constants'
import { NetworkPrintersUsersService } from '../../../../modules/shared/services/network-printers-users.service'
import { MyprintersService } from '../../../../modules/shared/services/myprinters.service'
import { PrinterEditorDriversComponent } from './printer-editor-drivers/printer-editor-drivers.component'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { PrinterEditorDialogComponent } from '../printer-editor-dialog/printer-editor-dialog.component'
import { Router } from '@angular/router'
import { PrinterEditorCapabilitiesComponent } from '../printer-editor-capabilities/printer-editor-capabilities.component'
import { CapabilitiesService } from '../../../../modules/shared/services/capabilities.service'
import { LocationUpdateService } from '../../../../modules/shared/services/location-update.service'

@Component({
  selector: 'ezd-printer-editor-details',
  templateUrl: './printer-editor-details.component.html',
  styleUrls: ['./printer-editor-details.component.scss'],
})
export class PrinterEditorDetailsComponent {
  selectedPrinter = null
  showPrinterDetail = false
  drivers = []
  renderings = []
  startIPRange = ''
  endIPRange = ''
  restrictedIpRanges = []
  groups = []
  users = []
  assignedUsers: any
  MODEL_ORDER = 'name'
  format = 'pdf'
  printerIds: string[]
  usersList: any[]
  userName: any
  displayName: any
  userDisplayNames: any[] = []
  printerName = true
  defaultPageSize = 'A4'
  defaultCodeSize = '5cm'
  ANYPRINTER_BY_EZEEP = ANYPRINTER_BY_EZEEP
  static readonly ipv4Regex =
    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  @Output() public forceShowFilterView: EventEmitter<void> = new EventEmitter<void>()
  @Output() public showDrivers: EventEmitter<void> = new EventEmitter<void>()
  @Output() public showUsersGroups: EventEmitter<void> = new EventEmitter<void>()
  @Output() capabilitiesLoaded = new EventEmitter<any>()
  // @Output() public showConnector: EventEmitter<string> = new EventEmitter<string>();
  @Input() groupsUpdated = (value: boolean) => {
    if (value) this.ngOnInit()
  }

  @Input() selectedPrinters: any[] = []
  isLoading = true
  isLoadingGroups = true
  isLoadingUsers = true
  printersSubscription: Subscription
  printerPropertiesWatcher: any
  pull_printing_enabled: boolean

  propertiesChanged = new Subject<any>()
  loadingSubscription = false
  removeGroupsSubscription: Subscription
  removeUsersSubscription: Subscription
  userServiceUsersSubscription: Subscription
  public isMobile = false
  public isDesktop = false
  private _dataLoaded = false

  @ViewChild(PrinterEditorCapabilitiesComponent)
  capabilitiesComponent: PrinterEditorCapabilitiesComponent
  capabilities: any
  networkPrintersService: any

  constructor(
    public myprintersService: MyprintersService,
    public networkPrintersPrintersService: NetworkPrintersPrintersService,
    public networkPrintersDriversService: NetworkPrintersDriversService,
    public networkPrintersAssignmentsService: NetworkPrintersAssignmentsService,
    public networkPrintersGroupsService: NetworkPrintersGroupsService,
    public networkPrintersUsersService: NetworkPrintersUsersService,
    public userAssignmentsService: UserAssignmentsService,
    public locationUpdateService: LocationUpdateService,
    public generateQRCodeService: GenerateQRCodeService,
    public authUserService: AuthUserApiService,
    public profileService: ProfileService,
    public clipboard: Clipboard,
    public snackbar: MatSnackBar,
    public errorService: ErrorService,
    public dialog: MatDialog,
    public router: Router,
    public dialogRef: MatDialogRef<PrinterEditorDialogComponent>,
    private cdr: ChangeDetectorRef,
    private capabilitiesService: CapabilitiesService
  ) {}

  ngOnInit() {
    this.checkDeviceSize()
    this.loadData()
  }

  get dataLoaded(): boolean {
    return this._dataLoaded
  }

  ngAfterViewInit() {
    this.cdr.detectChanges()
  }

  loadData() {
    if (!this._dataLoaded) {
      this.isLoading = true
      this.printerPropertiesWatcher = this.networkPrintersPrintersService
        .watchPrinterPropertiesChanges()
        .subscribe((result) => {
          if (result.status == DIALOG_RESPONSE.SAVE) {
            this.saveChanges()
          }

          if (result.status == DIALOG_RESPONSE.DISCARD) {
            this.discardChanges()
          }

          if (result.status == DIALOG_RESPONSE.NONE && result.changed) {
            this.selectedPrinter = StorageUtils.get(localStorage, 'printerUnsaved')

            if (this.showPrinterDetail) {
              this.networkPrintersDriversService
                .getDriver(this.selectedPrinter.driver_id)
                .subscribe((result) => {
                  if (result) {
                    this.drivers = [result]
                    this.networkPrintersDriversService
                      .getDrivers(result.vendor, undefined, undefined, this.MODEL_ORDER, 10, 0)
                      .subscribe((response) => {
                        this.drivers = [result, ...response]
                        this.isLoading = false
                        this.loadCapabilities()
                      })
                  } else {
                    this.networkPrintersDriversService
                      .getDrivers(
                        undefined,
                        undefined,
                        this.selectedPrinter.name.split(' ').join('|').split('-').join('|'),
                        this.MODEL_ORDER,
                        10,
                        0
                      )
                      .subscribe((response) => {
                        this.drivers = response
                        this.isLoading = false
                      })
                  }
                })
            }
          }
        })

      this.propertiesChanged.pipe(debounceTime(300)).subscribe((result) => {
        let hasChanges = false
        if (result.driver_id && result.driver_id != this.selectedPrinter.driver_id) {
          hasChanges = true
          this.selectedPrinter.driver_id = result.driver_id
        }
        if (result.location != undefined && result.location != this.selectedPrinter.location) {
          hasChanges = true
          this.selectedPrinter.location = result.location
        }
        if (result.name != undefined && result.name != this.selectedPrinter.name) {
          hasChanges = true
          this.selectedPrinter.name = result.name
        }

        if (hasChanges) {
          StorageUtils.set(localStorage, 'printerUnsaved', this.selectedPrinter)
          this.networkPrintersPrintersService.changePrinterPropertiesWatcherStatus(
            true,
            DIALOG_RESPONSE.NONE
          )
        }
      })

      this.printersSubscription = this.networkPrintersPrintersService
        .getFilteredNetworkPrintersPrinters()
        .subscribe(async (result) => {
          if (this.networkPrintersPrintersService.getSelectedNetworkPrintersCount() == 0) {
            return
          }
          // initial values
          this.isLoading = result.isLoading
          this.isLoadingGroups = true
          this.isLoadingUsers = true
          this.groups = []
          this.users = []
          this.restrictedIpRanges = []

          this.showPrinterDetail =
            this.networkPrintersPrintersService.getSelectedNetworkPrintersCount() == 1
          this.selectedPrinters = this.networkPrintersPrintersService.getSelectedNetworkPrinters()
          if (StorageUtils.get(localStorage, 'printerUnsaved') == null) {
            this.selectedPrinter = Object.assign({}, this.selectedPrinters[0])
          } else {
            this.selectedPrinter = StorageUtils.get(localStorage, 'printerUnsaved')
          }
          if (this.networkPrintersPrintersService.getSelectedNetworkPrintersCount() > 1) {
            this.SetSelectedPrintersCommonProperties()
          }

          // Assignments
          this.SetSelectedPrinterAssignment()

          // User Assignments
          this.SetSelectedPrinterUserAssignment()
          // Drivers
          if (this.showPrinterDetail) {
            this.networkPrintersDriversService
              .getDriver(this.selectedPrinter.driver_id)
              .subscribe((result) => {
                if (result) {
                  this.drivers = [result]
                  this.networkPrintersDriversService
                    .getDrivers(result.vendor, undefined, undefined, this.MODEL_ORDER, 10, 0)
                    .subscribe((response) => {
                      this.drivers = [result, ...response]
                      this.isLoading = false
                    })
                } else {
                  this.networkPrintersDriversService
                    .getDrivers(
                      undefined,
                      undefined,
                      this.selectedPrinter.name.split(' ').join('|').split('-').join('|'),
                      this.MODEL_ORDER,
                      10,
                      0
                    )
                    .subscribe((response) => {
                      this.drivers = response
                      this.isLoading = false
                    })
                }
              })
          } else {
            this.networkPrintersDriversService
              .getDrivers(
                undefined,
                undefined,
                this.selectedPrinter.name.split(' ').join('|').split('-').join('|'),
                this.MODEL_ORDER,
                10,
                0
              )
              .subscribe((response) => {
                this.drivers = response
                this.isLoading = false
              })
          }
        })

      this.profileService.read().subscribe((response) => {
        this.pull_printing_enabled = response.result.pull_printing_enabled
      })
    }
    this._dataLoaded = true

    // Future release
    // this.renderings = this.networkPrintersRenderingsService.getRenderings();
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.printersSubscription.unsubscribe()
    this.printerPropertiesWatcher.unsubscribe()
    if (this.removeGroupsSubscription) {
      this.removeGroupsSubscription.unsubscribe()
    }
    if (this.removeUsersSubscription) {
      this.removeUsersSubscription.unsubscribe()
    }
  }

  onNameChange(name: string) {
    this.propertiesChanged.next({ name: name })
  }

  onLocationChange(location: string) {
    this.propertiesChanged.next({ location: location })
    this.locationUpdateService.setLocationUpdateValue(true)
  }

  onDriverChange(driver_id: string) {
    this.propertiesChanged.next({ driver_id: driver_id })
  }

  copyPrinterId(id: string) {
    this.clipboard.copy(id)
    this.snackbar.open('Printer Id copied to your clipboard.', '', { duration: 2000 })
  }

  private SetSelectedPrintersCommonProperties() {
    if (StorageUtils.get(localStorage, 'printerUnsaved') != null) {
      return
    }

    if (
      this.selectedPrinters.filter((p) => p.model_name == this.selectedPrinters[0].model_name)
        .length == this.selectedPrinters.length
    ) {
      this.selectedPrinter.model_name = this.selectedPrinters[0].model_name
      this.selectedPrinter.vendor_name = this.selectedPrinters[0].vendor_name
    } else {
      this.selectedPrinter.vendor_name = this.selectedPrinter.model_name = undefined
    }
    if (
      this.selectedPrinters.filter((p) => p.location == this.selectedPrinters[0].location).length ==
      this.selectedPrinters.length
    ) {
      this.selectedPrinter.location = this.selectedPrinters[0].location
    } else {
      this.selectedPrinter.location = undefined
    }
    if (
      this.selectedPrinters.filter(
        (p) => p.driver_id == this.selectedPrinters[0].driver_id && p.connector_os_type != 'Windows'
      ).length == this.selectedPrinters.length
    ) {
      this.selectedPrinter.driver_id = this.selectedPrinters[0].driver_id
    } else {
      this.selectedPrinter.driver_id = undefined
    }
    if (
      this.selectedPrinters.filter((p) => p.connector_id == this.selectedPrinters[0].connector_id)
        .length == this.selectedPrinters.length
    ) {
      this.selectedPrinter.connectorName = this.selectedPrinters[0].connectorName
      this.selectedPrinter.connector_os_type = this.selectedPrinters[0].connector_os_type
    } else {
      this.selectedPrinter.connectorName = undefined
      this.selectedPrinter.connector_os_type = undefined
    }
  }

  private SetSelectedPrinterAssignment() {
    this.networkPrintersAssignmentsService
      .getAssignments(this.selectedPrinter.id)
      .subscribe((result) => {
        // initial values;avoid many requests accumulate groups and ip ranges
        this.isLoadingGroups = true
        this.groups = []
        this.restrictedIpRanges = []

        if (this.showPrinterDetail) {
          this.groups = result.groups && result.groups.length > 0 ? result.groups : []
          this.restrictedIpRanges =
            result.ip_ranges && result.ip_ranges.length > 0 ? result.ip_ranges : []
          if (this.removeGroupsSubscription) {
            this.removeGroupsSubscription.unsubscribe()
          }
          this.selectedPrinter.rendering = ''
          this.isLoadingGroups = false
          return
        }
        this.getPrintersAssignments(result)
      })
  }

  private SetSelectedPrinterUserAssignment() {
    this.networkPrintersAssignmentsService
      .getUserAssignments(this.selectedPrinter.id)
      .subscribe(async (result) => {
        this.isLoadingUsers = true
        this.users = []

        if (this.showPrinterDetail) {
          this.users = result.users && result.users.length > 0 ? result.users : []
          const userDetails = []
          for (let x = 0; x < this.users.length; x++) {
            userDetails.push(this.authUserService.read(this.users[x].user_id))
          }
          forkJoin(userDetails).subscribe((res) => {
            this.userDisplayNames = res
          })

          if (this.removeUsersSubscription) {
            this.removeUsersSubscription.unsubscribe()
          }
          this.selectedPrinter.rendering = ''
          this.isLoadingUsers = false
          return
        }
        this.getPrintersUsersAssignments(result)
      })
  }

  private getPrintersAssignments(result: any) {
    const tempGroups = result.groups && result.groups.length > 0 ? result.groups : []
    const tempRestrictedIpRanges =
      result.ip_ranges && result.ip_ranges.length > 0 ? result.ip_ranges : []

    observableFrom(this.selectedPrinters)
      .pipe(
        mergeMap(async (p) => {
          if (p.id != this.selectedPrinter.id) {
            const result = await lastValueFrom(
              this.networkPrintersAssignmentsService.getAssignments(p.id)
            ) // .toPromise();
            const groupsToRemove = []

            tempGroups.forEach((g) => {
              if (
                result.groups == undefined ||
                result.groups.length == 0 ||
                result.groups.filter((x) => x.group_id == g.group_id).length == 0
              ) {
                groupsToRemove.push(g)
              }
            })

            groupsToRemove.forEach((g) => {
              const index = tempGroups.indexOf(g)

              if (index >= 0) {
                tempGroups.splice(index, 1)
              }
            })

            // EFA-1018
            // tempRestrictedIpRanges.forEach(r => {
            //   if (result.ip_ranges == undefined || result.ip_ranges.length == 0 || result.ip_ranges.filter(x => x.label == r.label).length == 0) {
            //     restrictedIpRangesToRemove.push(r);
            //   }
            // });

            // restrictedIpRangesToRemove.forEach(r => {
            //   let index = tempRestrictedIpRanges.indexOf(r);

            //   if (index >= 0) {
            //     tempRestrictedIpRanges.splice(index, 1);
            //   }
            // });
          }
        })
      )
      .pipe(last())
      .subscribe(() => {
        this.groups = tempGroups
        this.restrictedIpRanges = tempRestrictedIpRanges
        this.isLoadingGroups = false
      })

    if (this.removeGroupsSubscription) {
      this.removeGroupsSubscription.unsubscribe()
    }
  }

  private getPrintersUsersAssignments(result: any) {
    const tempUsers = result.users && result.users.length > 0 ? result.users : []

    observableFrom(this.selectedPrinters)
      .pipe(
        mergeMap(async (p) => {
          if (p.id != this.selectedPrinter.id) {
            const result = await lastValueFrom(
              this.networkPrintersAssignmentsService.getUserAssignments(p.id)
            ) // .toPromise();
            const usersToRemove = []

            tempUsers.forEach((u) => {
              if (
                result.users == undefined ||
                result.users.length == 0 ||
                result.users.filter((x) => x.user_id == u.user_id).length == 0
              ) {
                usersToRemove.push(u)
              }
            })

            usersToRemove.forEach((u) => {
              const index = tempUsers.indexOf(u)

              if (index >= 0) {
                tempUsers.splice(index, 1)
              }
            })
          }
        })
      )
      .pipe(last())
      .subscribe(() => {
        this.users = tempUsers
        this.isLoadingUsers = false
      })

    if (this.removeUsersSubscription) {
      this.removeUsersSubscription.unsubscribe()
    }
  }

  discardChanges() {
    this.selectedPrinter = Object.assign(
      {},
      this.networkPrintersPrintersService.getSelectedNetworkPrinters()[0]
    )
    StorageUtils.set(localStorage, 'printerUnsaved', null)
    this.networkPrintersPrintersService.changePrinterPropertiesWatcherStatus(
      false,
      DIALOG_RESPONSE.CANCEL
    )
    this.forceShowFilterView.emit()
  }

  saveChanges(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.showPrinterDetail) {
        this.networkPrintersPrintersService.savePrinter(this.selectedPrinter).subscribe(() => {
          this.networkPrintersPrintersService.setPage(0, undefined, true)
          this.networkPrintersPrintersService.applyFilters(undefined)
          this.myprintersService.applyFilters()
          StorageUtils.set(localStorage, 'printerUnsaved', null)
          this.networkPrintersPrintersService.changePrinterPropertiesWatcherStatus(
            false,
            DIALOG_RESPONSE.CANCEL
          )
          this.forceShowFilterView.emit()
          this.close()
          resolve()
        }, reject)
      } else {
        observableFrom(this.selectedPrinters)
          .pipe(
            mergeMap(async (p) => {
              if (
                this.selectedPrinter.location != undefined &&
                this.selectedPrinter.location != null &&
                this.selectedPrinter.location != ''
              ) {
                p.location = this.selectedPrinter.location
              }
              if (
                this.selectedPrinter.driver_id != undefined &&
                this.selectedPrinter.driver_id != null &&
                this.selectedPrinter.driver_id != ''
              ) {
                p.driver_id = this.selectedPrinter.driver_id
              }
              return await lastValueFrom(this.networkPrintersPrintersService.savePrinter(p))
            })
          )
          .pipe(last())
          .subscribe(() => {
            this.networkPrintersPrintersService.setPage(0, undefined, true)
            this.networkPrintersPrintersService.applyFilters(undefined)
            this.myprintersService.applyFilters()
            StorageUtils.set(localStorage, 'printerUnsaved', null)
            this.networkPrintersPrintersService.changePrinterPropertiesWatcherStatus(
              false,
              DIALOG_RESPONSE.CANCEL
            )
            this.forceShowFilterView.emit()
            this.close()
            resolve()
          }, reject)
      }
    })
  }

  addIPRange() {
    if (!this.validateIpRange()) {
      return
    }

    const range = {
      start_ip: this.startIPRange,
      stop_ip: this.endIPRange,
      label: this.startIPRange + ' - ' + this.endIPRange,
    }

    this.isLoadingGroups = true
    observableFrom(this.selectedPrinters)
      .pipe(
        mergeMap(async (p) => {
          if (this.restrictedIpRanges.filter((r) => r.label == range.label).length > 0) {
            if (this.showPrinterDetail) {
              this.errorService.openTranslate(
                'Invalid IPv4 - IP range already add',
                'PRINTERS.NETWORK_PRINTERS.PRINTERS_PROPERTIES.DETAILS.DUPLICATE_RANGE'
              )
            }
            return
          }
          // save ip range; refresh ip ranges list
          const assigment = await lastValueFrom(
            this.networkPrintersAssignmentsService.getAssignments(p.id)
          ) // .toPromise();
          return await lastValueFrom(
            this.networkPrintersAssignmentsService.update(
              [...this.restrictedIpRanges, range, ...assigment.ip_ranges],
              assigment.groups,
              p.id,
              undefined
            )
          ) // .toPromise();
        })
      )
      .pipe(last())
      .subscribe(() => {
        this.restrictedIpRanges.push(range)
        this.isLoadingGroups = false
      })
  }

  removeGroup(group: any) {
    this.removeGroupsSubscription = this.networkPrintersGroupsService
      .removeGroup(this.selectedPrinters, group)
      .subscribe((result) => {
        if (result) {
          this.SetSelectedPrinterAssignment()
        } else {
          this.groups = []
          this.isLoadingGroups = true
        }
      })
  }

  removeUser(user: any) {
    this.removeUsersSubscription = this.networkPrintersUsersService
      .removeUser(this.selectedPrinters, user)
      .subscribe((result) => {
        if (result) {
          this.SetSelectedPrinterUserAssignment()
        } else {
          this.users = []
          this.isLoadingUsers = true
        }
      })
  }

  removeIPRange(range: any): void {
    this.isLoadingGroups = true
    observableFrom(this.selectedPrinters)
      .pipe(
        mergeMap(async (p) => {
          const assigment = await lastValueFrom(
            this.networkPrintersAssignmentsService.getAssignments(p.id)
          ) // .toPromise();
          assigment.ip_ranges = assigment.ip_ranges.filter(
            (ip) => ip.start_ip != range.start_ip && ip.stop_ip != range.stop_ip
          )
          return await lastValueFrom(
            this.networkPrintersAssignmentsService.update(
              assigment.ip_ranges,
              assigment.groups,
              p.id,
              p.name
            )
          ) // .toPromise();
        })
      )
      .pipe(last())
      .subscribe(() => {
        this.restrictedIpRanges.map(() => {
          const index = this.restrictedIpRanges.indexOf(range)

          if (index >= 0) {
            this.restrictedIpRanges.splice(index, 1)
          }
        })
        this.isLoadingGroups = false
      })
  }

  private isValidIPv4(value: string): boolean {
    return /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      value
    )
  }

  private validateIpRange(): boolean {
    if (!this.isValidIPv4(this.startIPRange)) {
      this.errorService.openTranslate(
        'Invalid IPv4 - start IP Range',
        'PRINTERS.NETWORK_PRINTERS.PRINTERS_PROPERTIES.DETAILS.INVALID_IPV4_START'
      )
      return false
    }

    if (!this.isValidIPv4(this.endIPRange)) {
      this.errorService.openTranslate(
        'Invalid IPv4 - end IP Range',
        'PRINTERS.NETWORK_PRINTERS.PRINTERS_PROPERTIES.DETAILS.INVALID_IPV4_END'
      )
      return false
    }

    if (this.startIPRange == this.endIPRange) {
      this.errorService.openTranslate(
        'Invalid IPv4 - same IP',
        'PRINTERS.NETWORK_PRINTERS.PRINTERS_PROPERTIES.DETAILS.INVALID_IPV4_EQUALS'
      )
      return false
    }

    return true
  }

  showConnector(connectorId) {
    this.dialogRef.close()
    this.router.navigate(['connectors'], {
      queryParams: { connectorId: connectorId },
      skipLocationChange: true,
    })
  }

  openPrinterEditorDriversDialog() {
    this.showDrivers.emit()

    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = false
    dialogConfig.width = '600px'
    dialogConfig.height = '570px'
    dialogConfig.panelClass = 'ezp-dialog'

    this.dialog.open(PrinterEditorDriversComponent, dialogConfig)

    this.dialogRef.afterClosed().subscribe((selectedDriver) => {
      if (selectedDriver) {
        this.onDriverSelected(selectedDriver)
      }
    })
  }

  private onDriverSelected(driver: any) {
    // Update the printer with the new driver
    this.networkPrintersService.updatePrinterDriver(this.selectedPrinter, driver).subscribe(
      (updatedPrinter) => {
        this.selectedPrinter = updatedPrinter
        this.loadCapabilities()
      },
      (error) => {
        console.error('Error updating printer driver:', error)
      }
    )
  }

  defaultCapabilities: any
  private loadCapabilities() {
    const printerId = this.selectedPrinter.id
    const driverId = this.selectedPrinter.driver_id

    if (printerId && driverId) {
      this.capabilitiesService.getPrinterCapabilities(printerId).subscribe(
        (capabilities) => {
          this.capabilities = capabilities
          this.updateCapabilitiesComponent(capabilities)
        },
        (error) => {
          console.error('Error loading capabilities:', error)
        }
      )
      this.capabilitiesService.getPrinterDefaultCapabilities(printerId).subscribe(
        (capabilities) => {
          this.defaultCapabilities = capabilities
          StorageUtils.set(localStorage, 'printerCapabilities', capabilities)
          this.capabilitiesLoaded.emit({
            capabilities: this.capabilities,
            defaultCapabilities: this.defaultCapabilities,
          })
        },
        (error) => {
          console.error('Error loading default capabilities:', error)
        }
      )
    } else {
      console.log('No printer or driver selected')
    }
  }

  private updateCapabilitiesComponent(capabilities: any) {
    if (this.capabilitiesComponent) {
      this.capabilitiesComponent.updateCapabilities(capabilities)
    } else {
      console.log('Capabilities component not available')
    }
  }

  close() {
    this.dialogRef.close()
  }

  saveDetails() {
    this.saveChanges()
    this.close()
    return Promise.resolve()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkDeviceSize()
  }

  checkDeviceSize() {
    const width = window.innerWidth
    this.isMobile = width <= 768
    this.isDesktop = width > 768
  }
}
