import {
  Component,
  EventEmitter,
  Inject,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA,
} from '@angular/material/legacy-dialog'
import { NetworkPrintersPrintersService } from '../../../../modules/shared/services/network-printers-printers.service'
import { StorageUtils } from '../../../../modules/storage/utils/storage.utils'
import { DIALOG_RESPONSE } from '../../../../modules/shared/classes/dialog-result'
import { PrinterEditorDetailsComponent } from '../printer-editor-details/printer-editor-details.component'
import { PrinterEditorAssignmentsComponent } from '../printer-editor-assignments/printer-editor-assignments.component'
import { MyprintersService } from '../../../../modules/shared/services/myprinters.service'
import { NetworkPrintersGroupsService } from '../../../../modules/shared/services/network-printers-groups.service'
import { NetworkPrintersUsersService } from '../../../../modules/shared/services/network-printers-users.service'
import { PrinterEditorCapabilities } from '../../../../modules/shared/services/printer-editor-capabilities-api.service'
import { PrinterEditorCapabilitiesComponent } from '../printer-editor-capabilities/printer-editor-capabilities.component'
import { PrinterEditorPullprintingComponent } from '../printer-editor-pullprinting/printer-editor-pullprinting.component'
import { LocationUpdateService } from '../../../../modules/shared/services/location-update.service'

@Component({
  selector: 'ezd-printer-editor-dialog',
  templateUrl: './printer-editor-dialog.component.html',
  styleUrls: ['./printer-editor-dialog.component.scss'],
})
export class PrinterEditorDialogComponent {
  @ViewChild(PrinterEditorDetailsComponent) detailsComponent: PrinterEditorDetailsComponent
  @ViewChild(PrinterEditorAssignmentsComponent)
  assignmentsComponent: PrinterEditorAssignmentsComponent
  @ViewChild(PrinterEditorCapabilitiesComponent)
  capabilitiesComponent: PrinterEditorCapabilitiesComponent
  @ViewChild(PrinterEditorPullprintingComponent)
  pullprintingComponent: PrinterEditorPullprintingComponent

  @Output() public forceShowFilterView = new EventEmitter<void>()
  @Output() groupsSelected = new EventEmitter<void>()
  @Output() assignmentsChanged = new EventEmitter<void>()
  @Output() capabilitiesChanged = new EventEmitter<void>()
  @Output() usersSelected = new EventEmitter<void>()
  @Output() capabilitiesSelected = new EventEmitter<void>()

  selectedPrinters = []
  selectedPrinter: any = null
  tabsLoaded: boolean[] = [false, false, false, false]

  canAddGroups = false
  isProcessingGroups = false
  assignedUsers: any[] = []
  assignedGroups: any[] = []
  addedUsers: any[] = []
  addedGroups: any[] = []
  removedUsers: any[] = []
  removedGroups: any[] = []
  profilesByPrinter: any[] = []

  isLoading = true
  isLoadingGroups = true
  endOfList = false
  endOfGroupsList = false
  isProcessingUsers = false
  enableGroups = false
  canAddUsers = false
  showLoadingIndicator = false
  showPrinterDetail = false
  hasCapabilitiesErrors = false
  hasDuplicateName: boolean = false
  hasEmptyName: boolean = false

  preferredLanguage: string
  selectedOption = 'groups'
  currentPageIndex = 0
  currentPageIndexGroups = 0
  searchText: string
  searchTextGroup: string

  printersSelected: any

  selectedTabIndex: number = 0
  capabilities: PrinterEditorCapabilities
  showResetModal: boolean = false
  isResetLoading: boolean = false
  isSaveLoadingIndicator: boolean = false
  locationUpdated: boolean

  constructor(
    public dialogRef: MatDialogRef<PrinterEditorDialogComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: any,
    private networkPrintersPrintersService: NetworkPrintersPrintersService,
    public myprintersService: MyprintersService,
    private networkPrinterGroupsService: NetworkPrintersGroupsService,
    private usersPrinterService: NetworkPrintersUsersService,
    private locationUpdateService: LocationUpdateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.isResetLoading = false
    this.isSaveLoadingIndicator = false
    if (this.data && this.data.length > 0) {
      this.selectedPrinters = this.data
    } else {
      this.selectedPrinters = this.networkPrintersPrintersService.getSelectedNetworkPrinters()
    }
    this.selectedPrinter = this.selectedPrinters[0]
    this.locationUpdateService.visibility$.subscribe((value) => {
      this.locationUpdated = value;
  })
  }

  ngAfterViewInit() {
    if (this.detailsComponent) {
      this.detailsComponent.capabilitiesLoaded.subscribe((capabilities) => {
        if (this.capabilitiesComponent) {
          this.capabilitiesComponent.updateCapabilities(capabilities)
          this.capabilitiesComponent.resetCapabilities()
        }
      })
    }
  }

  close() {
    StorageUtils.set(localStorage, 'printerUnsaved', null)
    StorageUtils.set(localStorage, 'printerCapabilities', null)
    StorageUtils.set(localStorage, 'printerAssignments', null)
    this.dialogRef.close()
  }

  saveAllData() {
    if (this.hasEmptyName || this.hasDuplicateName || this.hasCapabilitiesErrors) {
      return
    }

    this.isSaveLoadingIndicator = true
    const saveDetails = () => this.detailsComponent.saveDetails()
    const saveAssignments = () => this.assignmentsComponent.saveAssignments()
    const saveCapabilities = () => this.capabilitiesComponent.saveCapabilities()
    const savePrinter = (printer) => {
      if (this.selectedPrinter.location) {
        printer.location = this.selectedPrinter.location
      }
      if (this.selectedPrinter.driver_id) {
        printer.driver_id = this.selectedPrinter.driver_id
      }
      return this.networkPrintersPrintersService.savePrinter(printer)
    }

    const finalizeSave = () => {
      this.networkPrintersPrintersService.setPage(0, undefined, true)
      this.networkPrintersPrintersService.applyFilters(undefined)
      this.myprintersService.applyFilters()
      this.networkPrintersPrintersService.changePrinterPropertiesWatcherStatus(
        false,
        DIALOG_RESPONSE.CANCEL
      )
      this.forceShowFilterView.emit()
      this.close()
    }

    const processGroups = () => {
      if (this.canAddGroups) {
        this.isProcessingGroups = true
        return this.networkPrinterGroupsService
          .addRemoveGroups(this.addedGroups, this.removedGroups, this.printersSelected)
          .toPromise()
          .then((result) => {
            if (result) {
              this.canAddGroups = false
              this.networkPrintersPrintersService.refreshSelectedPrintersProperties()
              this.groupsSelected.emit(null)
            }
          })
      }
      StorageUtils.set(localStorage, 'printerUnsaved', null)
      StorageUtils.set(localStorage, 'printerCapabilities', null)
      return Promise.resolve()
    }

    const processUsers = () => {
      if (this.canAddUsers) {
        this.isProcessingUsers = true
        return this.usersPrinterService
          .addremoveUsers(this.addedUsers, this.removedUsers, this.printersSelected)
          .toPromise()
          .then((result) => {
            if (result) {
              this.canAddUsers = false
              this.networkPrintersPrintersService.refreshSelectedPrintersProperties()
              this.usersSelected.emit(null)
            }
          })
      }
      return Promise.resolve()
    }

    Promise.resolve([
      processGroups(),
      processUsers(),
      saveDetails(),
      saveAssignments(),
      saveCapabilities(),
    ]).then(() => {
      this.dialogRef.close()
      setTimeout(() => {
        if (this.locationUpdated == true) {
          window.location.reload()
        }
    }, 1500)
  })}

  resetCapabilities() {
    if (this.capabilitiesComponent) {
      this.capabilitiesComponent
        .resetCapabilities()
        .then((resetData) => {
          console.log('Capabilities reset successfully:', resetData)
          // The UI should already be updated, no need to call loadData()
        })
        .catch((error) => {
          console.error('Error resetting capabilities:', error)
        })
    }
  }

  openResetModal() {
    if (this.selectedPrinters && this.selectedPrinters.length > 0) {
      this.selectedPrinter = this.selectedPrinters[0]
    }
    this.showResetModal = true
    this.cdr.detectChanges() // Forces change detection to ensure UI updates
  }

  closeResetModal() {
    this.showResetModal = false
    this.cdr.detectChanges() // Forces change detection to ensure UI updates
  }

  confirmReset() {
    this.isResetLoading = false
    this.showResetModal = false
    this.resetCapabilities()
  }

  onCapabilitiesErrors(hasErrors: boolean): void {
    this.hasCapabilitiesErrors = hasErrors
  }

  onDuplicateName(hasDuplicate: boolean): void {
    this.hasDuplicateName = hasDuplicate
  }

  onEmptyName(isEmpty: boolean): void {
    this.hasEmptyName = isEmpty
  }

  onCapabilitiesChanged(updatedCapabilities: PrinterEditorCapabilities): void {
    this.capabilities = updatedCapabilities
  }

  onDiscardChanges() {
    if (this.detailsComponent) {
      this.detailsComponent.discardChanges()
    }
    this.dialogRef.close(DIALOG_RESPONSE.DISCARD)
  }
}
