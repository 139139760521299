import { Component, OnInit, Optional, Inject } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog'
import { UsersgroupsUsersService } from '../../../../modules/shared/services/usersgroups-users.service'
import { UsersgroupsUsersGroupsService } from '../../../../modules/shared/services/usersgroups-users-groups.service';
import { GeneralUtil } from '../../../../modules/shared/utils/general-util';
import { AuthUser } from '../../../../modules/shared/services/auth-api.service';
import { Subscription, EMPTY, lastValueFrom } from 'rxjs';
import { RemoveUserDialogComponent } from '../remove-user-dialog/remove-user-dialog.component';
import { UsersgroupsUsersPrintersService } from '../../../../modules/shared/services/usersgroups-users-printers.service';

@Component({
  selector: 'ezd-user-details-dialog',
  templateUrl: './user-details-dialog.component.html',
  styleUrls: ['./user-details-dialog.component.scss'],
})
export class UserDetailsDialogComponent implements OnInit {
  userId: any;
  user: AuthUser;
  userEmail: any;
  isAdmin: any;
  authUserSubscription: Subscription;
  isLoading = false
  groupsEnabled :boolean = false

  constructor(public dialogRef: MatDialogRef<UserDetailsDialogComponent>,
    private userService: UsersgroupsUsersService,
    private groupsService: UsersgroupsUsersGroupsService,
    private userPrinterService: UsersgroupsUsersPrintersService,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.groupsEnabled = data.enableGroups
      // this.isLoading = false
      // this.userId = this.data.userId
    }

  ngOnInit() {
    this.authUserSubscription = this.userService.getFilteredUsers()
      .subscribe(async result => {
        this.user = Object.assign({}, this.userService.getUserToEdit());
        this.userEmail = this.user.email;
        if (!this.user)
          return;

        let currentUser = await lastValueFrom(this.userService.getAuthMe()) // .toPromise();
        this.isAdmin = currentUser.user_id == this.user.id;
      })
  }

  ngOnDestroy() {
    this.authUserSubscription.unsubscribe();
  }

  openRemoveUserDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.width = '325px';
    dialogConfig.panelClass = 'ezp-dialog';
    dialogConfig.data = { userDetails: this.user }
    
    this.dialogRef.close();
    this.dialog.open(RemoveUserDialogComponent, dialogConfig);
  }

  removeUser(id: any) {
    this.isLoading = true;
    this.userService.removeUser(id).subscribe(
      async () => {
        await this.userService.applyFilters(true)
        this.dialogRef.close()
      });
  }

  close() {
    this.groupsService.clearDraftedAssignedGroups();
    this.userPrinterService.clearDraftedAssignedPrinters()
    this.dialogRef.close()
  }

  done() {
    this.isLoading = true
    this.userService.saveUserDetails()
      .subscribe(async () => {
        await this.groupsService.saveDraftedAssignedGroups();
        if (this.userPrinterService.hasDraftedAssignedPrinters) {
           await this.userPrinterService.saveDraftedAssignedPrinters()
        }
        this.groupsService.clearDraftedAssignedGroups();
        this.userPrinterService.clearDraftedAssignedPrinters()
        this.userService.applyFilters(true);
        this.dialogRef.close();
      });
  }
}
