import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { UAParser } from 'ua-parser-js'

type PlatformType = { title: string; url: string; os: string }
type InstallationType = {
  title: string
  action: string
  description: { any: string; ios: string; android: string }
}

@Component({
  selector: 'ezd-printjob-instructions',
  templateUrl: './printjob-instructions.component.html',
  styleUrls: ['./printjob-instructions.component.scss'],
})
export class PrintjobInstructionsComponent implements OnInit {
  baseUrl = 'https://assets.ezeep.com/wp-json'
  route = 'ezp/v1'
  endpoint = 'static/print-app'
  language = 'en'
  platforms: PlatformType[] = [{ title: '', url: '', os: '' }]
  matched: PlatformType
  installation: InstallationType = {
    title: '',
    action: '',
    description: { any: '', ios: '', android: '' },
  }
  os: string

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    const ua = new UAParser()
    const uaResult = ua.getResult()

    this.os = uaResult.os.name.toLowerCase()
    this.getPrintApps()
  }

  /**
   * Gets the print apps.
   */
  getPrintApps(): void {
    this.http
      .get(`${this.baseUrl}/${this.route}/${this.endpoint}/${this.language}`)
      .subscribe((data: any) => {
        let platforms = []

        Object.entries(data.platforms).map((platform) => {
          let details: Object = platform[1]

          platforms.push({ ...details, os: platform[0] })
        })

        this.platforms = platforms
        this.installation = data.installation
        this.matched = platforms.find((platform) => platform.os === this.os)
      })
  }
}
