import { Injectable, ErrorHandler, Injector } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { ErrorService } from '../../shared/services/error.service'
import * as Sentry from '@sentry/angular'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  // An Injector is used here because cyclic references in app.module.ts make it impossible
  // to instantiate this directly with ErrorService.
  constructor(private injector: Injector) {}

  handleError(error) {
    const errorService = this.injector.get(ErrorService)
    //errorService.openTranslate(error, 'ERRORS.GENERIC');
    // Forward to Sentry
    Sentry.captureException(error)
  }
}
