<div class="header">
  <div class="mat-headline title">{{ 'REPORTING.HEADER' | translate }}</div>
</div>

 <!-- Range when there is no dashboard and no download report present -->
<div class="range" *ngIf="!isDashboardPresent && !isDownloadReportPresent">
  <div class="range__item range__select">
    <mat-form-field appearance="fill" class="ezp-form-field ezp-form-field--full ezp-form-field--no-helper">
      <mat-label>{{ 'REPORTING.REPORTING_RANGE' | translate }}</mat-label>
      <mat-select placeholder="{{'REPORTING.REPORTING_RANGE' | translate}}" disabled>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="range__item range__start">
    <mat-form-field appearance="fill" (click)="startDatePicker.open()"
      class="ezp-form-field ezp-form-field--full ezp-form-field--no-helper">
      <mat-icon matPrefix>date_range</mat-icon>
      <mat-label>{{ 'REPORTING.START_DATE' | translate }}</mat-label>
      <input matInput placeholder="{{ 'REPORTING.START_DATE' | translate }}" disabled />
    </mat-form-field>
    <mat-datepicker #startDatePicker startView="month" color="primary"></mat-datepicker>
  </div>
  <div class="range__item range__end">
    <mat-form-field appearance="fill" (click)="endDatePicker.open()"
      class="ezp-form-field ezp-form-field--full ezp-form-field--no-helper">
      <mat-icon matPrefix>date_range</mat-icon>
      <mat-label>{{ 'REPORTING.END_DATE' | translate }}</mat-label>
      <input matInput placeholder="{{ 'REPORTING.END_DATE' | translate }}" disabled />
    </mat-form-field>
    <mat-datepicker #endDatePicker startView="month" color="primary"></mat-datepicker>
  </div>
  <button class="ezp-button range__action" mat-raised-button color="primary" disabled>
    {{ 'REPORTING.GENERATE_REPORT' | translate }}
  </button>
</div>

 <!-- Range when there is dashboard and no download report present -->
<div class="range" *ngIf="isDashboardPresent || isDownloadReportPresent">
  <div class="range__item range__select">
    <mat-form-field appearance="fill" class="ezp-form-field ezp-form-field--full ezp-form-field--no-helper">
      <mat-label>{{ 'REPORTING.REPORTING_RANGE' | translate }}</mat-label>
      <mat-select [formControl]="reportingRange" class="ezp-select" (selectionChange)="setDateRange()">
        <mat-option class="ezp-select__option" value="lastWeek">{{ 'REPORTING.LAST_WEEK' | translate }}</mat-option>
        <mat-option class="ezp-select__option" value="lastMonth">{{ 'REPORTING.LAST_MONTH' | translate }}</mat-option>
        <mat-option class="ezp-select__option" value="lastQuarter">{{ 'REPORTING.LAST_QUARTER' | translate }}
        </mat-option>
        <mat-option class="ezp-select__option" value="lastYear">{{ 'REPORTING.LAST_YEAR' | translate }}</mat-option>
        <mat-option class="ezp-select__option" value="custom">{{ 'REPORTING.CUSTOM' | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="range__item range__start">
    <mat-form-field appearance="fill" (click)="startDatePicker.open()"
      class="ezp-form-field ezp-form-field--full ezp-form-field--no-helper">
      <mat-icon matPrefix>date_range</mat-icon>
      <mat-label>{{ 'REPORTING.START_DATE' | translate }}</mat-label>
      <input matInput (dateChange)="startDateChange($event)" [matDatepicker]="startDatePicker" [min]="minStartDate" [max]="maxDate"
        placeholder="Start date" [formControl]="startDate" />
    </mat-form-field>
    <mat-datepicker #startDatePicker startView="month" color="primary"></mat-datepicker>
  </div>
  <div class="range__item range__end">
    <mat-form-field appearance="fill" (click)="endDatePicker.open()"
      class="ezp-form-field ezp-form-field--full ezp-form-field--no-helper">
      <mat-icon matPrefix>date_range</mat-icon>
      <mat-label>{{ 'REPORTING.END_DATE' | translate }}</mat-label>
      <input matInput (dateChange)="endDateChange($event)" [matDatepicker]="endDatePicker" [max]="maxEndDate"
        [min]="minEndDate" placeholder="End date" [formControl]="endDate" />
    </mat-form-field>
    <mat-datepicker #endDatePicker startView="month" color="primary"></mat-datepicker>
  </div>
  <button class="ezp-button range__action" mat-raised-button color="primary" (click)="generateReport()" [disabled]="!showGenerateReport">
    {{ 'REPORTING.GENERATE_REPORT' | translate }}
  </button>
</div>

 <!-- Graph shown when there is dashboard feature -->
<div class="chart">
  <div #printoutsChart></div>
</div>

 <!-- Placeholder when there is neither dashboard nor download report feature -->
<ezd-table-placeholder
    *ngIf = "!isDashboardPresent && !isDownloadReportPresent"
    [columns]="['', '', '']"
    [rows]="6"
    [heading]="'REPORTING.REPORTING_NOT_AVAILABLE.NOT_AVAILABLE' | translate"
    [action]="{title: 'REPORTING.REPORTING_NOT_AVAILABLE.BUTTON_TEXT' | translate, path: 'subscription'}"
    [description]="'REPORTING.REPORTING_NOT_AVAILABLE.DESCRIPTION' | translate"
  ></ezd-table-placeholder>

  <!-- Placeholder when there is neither dashboard nor download report feature -->
  <ezd-table-placeholder
  *ngIf = "!isDashboardPresent && !isDownloadReportPresent"
  [columns]="['', '', '']"
  [rows]="6"
  [action]=""
  [description]=""
  [heading]=""
></ezd-table-placeholder>

 <!-- Reports is displayed when there is download report feature -->
<div class="reports" *ngIf = "isDownloadReportPresent">
  <div class="mat-title title">{{ 'REPORTING.GENERATED_REPORTS' | translate }}</div>
  <div class="ezp-table">
    <table mat-table [dataSource]="generatedReports" matSort (matSortChange)="sortChange($event)"
      matSortDisableClear="true" class="ezp-table__data">
      <ng-container matColumnDef="range">
        <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--text">
          {{ 'REPORTING.RANGE' | translate }}
        </th>
        <td mat-cell *matCellDef="let report" class="ezp-table__cell ezp-table__cell--text">
          <span *ngIf="defaultPreferredLanguage == 'de' && report.status != 'failed'" >{{ report.start | localeDate: 'de' }} - {{ (report.end).substring(0, 10) | localeDate: 'de' }}</span>
          <span *ngIf="defaultPreferredLanguage == 'en' && report.status != 'failed'">{{ report.start | localeDate: 'en' }} - {{ (report.end).substring(0, 10) | localeDate: 'en' }}</span>

          <span class="range-failed-report" *ngIf="defaultPreferredLanguage == 'de' && report.status == 'failed'" >{{ report.start | localeDate: 'de' }} - {{ (report.end).substring(0, 10) | localeDate: 'de' }}</span>
          <span class="range-failed-report" *ngIf="defaultPreferredLanguage == 'en' && report.status == 'failed'">{{ report.start | localeDate: 'en' }} - {{ (report.end).substring(0, 10) | localeDate: 'en' }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="generated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ezp-table__cell ezp-table__cell--text">
          {{ 'REPORTING.GENERATED' | translate }}
        </th>
        <td mat-cell *matCellDef="let report" class="ezp-table__cell ezp-table__cell--text">
          <span *ngIf="defaultPreferredLanguage == 'de' && report.status != 'failed'">{{ report.created | intlDate: 'de' }}</span>
          <span *ngIf="defaultPreferredLanguage == 'en' && report.status != 'failed'">{{ report.created | intlDate: 'en' }}</span>

          <span class="range-failed-report" *ngIf="report.status == 'failed'">{{ 'REPORTING.FAILED_REPORT' | translate }}</span>
        </td>

      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef
          class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon ezp-table__cell--icon--double">
        </th>
        <td mat-cell *matCellDef="let report"
          class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon ezp-table__cell--icon--double">
          <div class="ezp-table__cell__actions">
            <button mat-icon-button *ngIf="report.status != 'failed'" [disabled]="report.status != 'succeeded'"
              (click)="downloadReport(report.report_id)">
              <mat-icon>file_download</mat-icon>
            </button>
            <button mat-icon-button *ngIf="report.status == 'failed'" (click)="regenerateReport(report.report_id)">
              <mat-icon>refresh</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteReport(report.report_id)">
              <mat-icon>delete_forever</mat-icon>
            </button>
            <div class="ezp-table__processing" *ngIf="report.status != 'succeeded' && report.status != 'failed'">
              <mat-spinner class="ezp-table__spinner"></mat-spinner>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="reportsColumns"></tr>
      <tr mat-row *matRowDef="let report; columns: reportsColumns" [ngClass]="{'ezp-table__row ezp-table__row--loading': report.status != 'succeeded'}"></tr>
    </table>
    <mat-spinner *ngIf="isLoading" class="ezp-spinner ezp-spinner--large ezp-spinner--table"></mat-spinner>
    <mat-paginator class="efa-paginator" #paginator [length]="maxPageLength" [pageSize]="pageSize"
      [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" (page)="onPaginationChange($event)">
    </mat-paginator>
  </div>
</div>

<!-- Placeholder when there is dashboard but no download report feature -->
<ezd-table-placeholder
    *ngIf="isDashboardPresent && !isDownloadReportPresent"
    [columns]="['Range', 'Generated', '']"
    [rows]="8"
    [heading]="'REPORTING.DOWNLOAD_REPORT_NOT_AVAILABLE.NOT_AVAILABLE' | translate"
    [action]="{title: 'REPORTING.DOWNLOAD_REPORT_NOT_AVAILABLE.BUTTON_TEXT' | translate, path: 'subscription'}"
    [description]="'REPORTING.DOWNLOAD_REPORT_NOT_AVAILABLE.DESCRIPTION' | translate"
  ></ezd-table-placeholder>

<ezd-banner-ad *ngIf="showBannerAd" imageSrc="assets/images/banner-ad-carbon-neutral-printing.svg">
  <div header class="mat-title" [innerHTML]="'BANNER_ADS.CARBON_FREE_PRINTING.TITLE' | translate"></div>
  <div body class="mat-subheading-1 ezp-has-text-link" [innerHTML]="'BANNER_ADS.CARBON_FREE_PRINTING.DESCRIPTION' | translate"></div>
  <a footer mat-flat-button color="primary" [href]="'BANNER_ADS.CARBON_FREE_PRINTING.ACTION_LINK' | translate" target="_blank" rel="noopener">{{ 'BANNER_ADS.CARBON_FREE_PRINTING.ACTION_TITLE' | translate }}</a>
  <button footer mat-stroked-button color="primary" (click)="dismissBanner()">{{ 'BANNER_ADS.DISMISS' | translate }}</button>
</ezd-banner-ad>
