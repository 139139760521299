import {
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
  delay,
  startWith,
  switchMap,
  map,
} from 'rxjs/operators'
import { Observable, throwError as _observableThrow, of as _observableOf, of } from 'rxjs'
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core'
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http'
import {
  RequesterApiService,
  ApiResponse,
  printerApiUrl,
  ApiResult,
  ListResult,
} from './requester-api.service'

export interface IMappingsService {
  read(organization_id: string): Observable<ApiResponse<any>>
}

@Injectable({
  providedIn: 'root',
})
export class MappingsService extends RequesterApiService implements IMappingsService {
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  read(organization_id: string): Observable<ApiResponse<Mappings>> {
    let url_ = this.baseUrl + '/__internal/tpac/mappings/{organization_id}/'
    if (organization_id === undefined || organization_id === null) {
      throw new Error("The parameter 'organization_id' must be defined.")
    }
    url_ = url_.replace('{organization_id}', encodeURIComponent('' + organization_id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('get', url_, options_, Mappings)
  }
}

export interface IAssignmentsService {
  /**
   * @param printer_id (optional)
   * @param group (optional) group id
   * @param user (optional) user id
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    printer_id: string | null | undefined,
    group: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<PrinterAssignment>>>
  create(data: PrinterAssignment): Observable<ApiResponse<PrinterAssignment>>
  read(id: string): Observable<ApiResponse<PrinterAssignment>>
  update(data: PrinterAssignment, id: string): Observable<ApiResponse<PrinterAssignment>>
  partialupdate(data: PrinterAssignment, id: string): Observable<ApiResponse<PrinterAssignment>>
  delete(id: string): Observable<ApiResponse<void>>
}

@Injectable({
  providedIn: 'root',
})
export class AssignmentsService extends RequesterApiService implements IAssignmentsService {
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  /**
   * @param printer_id (optional)
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    printer_id: string | null | undefined,
    group: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<PrinterAssignment>>> {
    let url_ = this.baseUrl + '/v1/assignments/?'
    if (printer_id !== undefined) {
      url_ += 'printer_id=' + encodeURIComponent('' + printer_id) + '&'
    }
    if (group !== undefined) {
      url_ += 'group=' + encodeURIComponent('' + group) + '&'
    }
    if (q !== undefined) {
      url_ += 'q=' + encodeURIComponent('' + q) + '&'
    }
    if (o !== undefined) {
      url_ += 'o=' + encodeURIComponent('' + o) + '&'
    }
    if (limit !== undefined) {
      url_ += 'limit=' + encodeURIComponent('' + limit) + '&'
    }
    if (offset !== undefined) {
      url_ += 'offset=' + encodeURIComponent('' + offset) + '&'
    }
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processListRequest('get', url_, options_, PrinterAssignment).pipe(
      map((r) => {
        r.result.searchText = q
        return r
      })
    )
  }

  create(data: PrinterAssignment): Observable<ApiResponse<PrinterAssignment>> {
    let url_ = this.baseUrl + '/v1/assignments/'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('post', url_, options_, PrinterAssignment)
  }

  read(id: string): Observable<ApiResponse<PrinterAssignment>> {
    let url_ = this.baseUrl + '/v1/assignments/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('get', url_, options_, PrinterAssignment)
  }

  update(data: PrinterAssignment, id: string): Observable<ApiResponse<PrinterAssignment>> {
    let url_ = this.baseUrl + '/v1/assignments/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('put', url_, options_, PrinterAssignment)
  }

  partialupdate(data: PrinterAssignment, id: string): Observable<ApiResponse<PrinterAssignment>> {
    let url_ = this.baseUrl + '/v1/assignments/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('patch', url_, options_, PrinterAssignment)
  }

  delete(id: string): Observable<ApiResponse<void>> {
    let url_ = this.baseUrl + '/v1/assignments/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest<null>('delete', url_, options_, null)
  }
}

export interface IUserAssignmentsService {
  /**
   * @param printer_id (optional)
   * @param group (optional) group id
   * @param user (optional) user id
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    printer_id: string | null | undefined,
    user: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<PrinterUserAssignment>>>
  create(data: PrinterUserAssignment): Observable<ApiResponse<PrinterUserAssignment>>
  read(id: string): Observable<ApiResponse<PrinterUserAssignment>>
  update(data: PrinterUserAssignment, id: string): Observable<ApiResponse<PrinterUserAssignment>>
  partialupdate(
    data: PrinterUserAssignment,
    id: string
  ): Observable<ApiResponse<PrinterUserAssignment>>
  delete(id: string): Observable<ApiResponse<void>>
}

@Injectable({
  providedIn: 'root',
})
export class UserAssignmentsService extends RequesterApiService implements IUserAssignmentsService {
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  /**
   * @param printer_id (optional)
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    printer_id: string | null | undefined,
    user: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<PrinterUserAssignment>>> {
    let url_ = this.baseUrl + '/v1/user_assignments/?'
    if (printer_id !== undefined) {
      url_ += 'printer_id=' + encodeURIComponent('' + printer_id) + '&'
    }
    if (user !== undefined) {
      url_ += 'user=' + encodeURIComponent('' + user) + '&'
    }
    if (q !== undefined) {
      url_ += 'q=' + encodeURIComponent('' + q) + '&'
    }
    if (o !== undefined) {
      url_ += 'o=' + encodeURIComponent('' + o) + '&'
    }
    if (limit !== undefined) {
      url_ += 'limit=' + encodeURIComponent('' + limit) + '&'
    }
    if (offset !== undefined) {
      url_ += 'offset=' + encodeURIComponent('' + offset) + '&'
    }
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processListRequest('get', url_, options_, PrinterUserAssignment).pipe(
      map((r) => {
        r.result.searchText = q
        return r
      })
    )
  }

  create(data: PrinterUserAssignment): Observable<ApiResponse<PrinterUserAssignment>> {
    let url_ = this.baseUrl + '/v1/user_assignments/'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('post', url_, options_, PrinterUserAssignment)
  }

  read(id: string): Observable<ApiResponse<PrinterUserAssignment>> {
    let url_ = this.baseUrl + '/v1/user_assignments/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('get', url_, options_, PrinterUserAssignment)
  }

  update(data: PrinterUserAssignment, id: string): Observable<ApiResponse<PrinterUserAssignment>> {
    let url_ = this.baseUrl + '/v1/user_assignments/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('put', url_, options_, PrinterUserAssignment)
  }

  partialupdate(
    data: PrinterUserAssignment,
    id: string
  ): Observable<ApiResponse<PrinterUserAssignment>> {
    let url_ = this.baseUrl + '/v1/User_assignments/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('patch', url_, options_, PrinterUserAssignment)
  }

  delete(id: string): Observable<ApiResponse<void>> {
    let url_ = this.baseUrl + '/v1/user_assignments/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest<null>('delete', url_, options_, null)
  }
}

export interface IConnectorsService {
  /**
   * @param status (optional)
   * @param readable_name (optional)
   * @param type (optional)
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    status: string | null | undefined,
    readable_name: string | null | undefined,
    type: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<Connector>>>
  create(data: Connector): Observable<ApiResponse<Connector>>
  read(id: string): Observable<ApiResponse<Connector>>
  update(data: Connector, id: string): Observable<ApiResponse<Connector>>
  partialupdate(data: Connector, id: string): Observable<ApiResponse<Connector>>
  delete(id: string): Observable<ApiResponse<void>>
  getpassword(id: string): Observable<ApiResponse<any>>
}

@Injectable({
  providedIn: 'root',
})
export class ConnectorsService extends RequesterApiService implements IConnectorsService {
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  /**
   * @param status (optional)
   * @param readable_name (optional)
   * @param type (optional)
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    status: string | null | undefined,
    readable_name: string | null | undefined,
    type: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<Connector>>> {
    let url_ = this.baseUrl + '/v1/connectors/?'
    if (status !== undefined) {
      url_ += 'status=' + encodeURIComponent('' + status) + '&'
    }
    if (readable_name !== undefined) {
      url_ += 'readable_name=' + encodeURIComponent('' + readable_name) + '&'
    }
    if (type !== undefined) {
      url_ += 'type=' + encodeURIComponent('' + type) + '&'
    }
    if (q !== undefined) {
      url_ += 'q=' + encodeURIComponent('' + q) + '&'
    }
    if (o !== undefined) {
      url_ += 'o=' + encodeURIComponent('' + o) + '&'
    }
    if (limit !== undefined) {
      url_ += 'limit=' + encodeURIComponent('' + limit) + '&'
    }
    if (offset !== undefined) {
      url_ += 'offset=' + encodeURIComponent('' + offset) + '&'
    }
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processListRequest('get', url_, options_, Connector).pipe(
      map((r) => {
        r.result.searchText = q
        return r
      })
    )
  }

  create(data: Connector): Observable<ApiResponse<Connector>> {
    let url_ = this.baseUrl + '/v1/connectors/'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('post', url_, options_, Connector)
  }

  read(id: string): Observable<ApiResponse<Connector>> {
    let url_ = this.baseUrl + '/v1/connectors/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('get', url_, options_, Connector)
  }

  update(data: Connector, id: string): Observable<ApiResponse<Connector>> {
    let url_ = this.baseUrl + '/v1/connectors/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('put', url_, options_, Connector)
  }

  partialupdate(data: Connector, id: string): Observable<ApiResponse<Connector>> {
    let url_ = this.baseUrl + '/v1/connectors/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('patch', url_, options_, Connector)
  }

  delete(id: string): Observable<ApiResponse<void>> {
    let url_ = this.baseUrl + '/v1/connectors/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest<null>('delete', url_, options_, null)
  }

  getpassword(id: string): Observable<ApiResponse<HubPassword>> {
    let url_ = this.baseUrl + '/v1/connectors/{id}/pull_printing_password/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify('')

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('post', url_, options_, HubPassword)
  }

  claimHubConnect(
    serialString: string,
    hubPassword: string
  ): Observable<ApiResponse<HubClaimConnect>> {
    let url_ = this.baseUrl + '/v1/iot_claims/'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify({
      serial_string: serialString,
      password: hubPassword,
    })

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('post', url_, options_, HubClaimConnect)
  }
}

export interface IDriversService {
  /**
   * @param vendor (optional)
   * @param name_like (optional)
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    vendor: string | null | undefined,
    name_like: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<PrinterDriver>>>
  create(data: PrinterDriver): Observable<ApiResponse<PrinterDriver>>
  /**
   * @param vendor (optional)
   * @param name_like (optional)
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  vendors(
    vendor: string | null | undefined,
    name_like: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<Vendors>>
  read(id: string): Observable<ApiResponse<PrinterDriver>>
  update(data: PrinterDriver, id: string): Observable<ApiResponse<PrinterDriver>>
  partialupdate(data: PrinterDriver, id: string): Observable<ApiResponse<PrinterDriver>>
  delete(id: string): Observable<ApiResponse<void>>
}

@Injectable({
  providedIn: 'root',
})
export class DriversService extends RequesterApiService implements IDriversService {
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  /**
   * @param vendor (optional)
   * @param name_like (optional)
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    vendor: string | null | undefined,
    name_like: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<PrinterDriver>>> {
    let url_ = this.baseUrl + '/v1/drivers/?'
    if (vendor !== undefined) {
      url_ += 'vendor=' + encodeURIComponent('' + vendor) + '&'
    }
    if (name_like !== undefined) {
      url_ += 'name_like=' + encodeURIComponent('' + name_like) + '&'
    }
    if (q !== undefined) {
      url_ += 'q=' + encodeURIComponent('' + q) + '&'
    }
    if (o !== undefined) {
      url_ += 'o=' + encodeURIComponent('' + o) + '&'
    }
    if (limit !== undefined) {
      url_ += 'limit=' + encodeURIComponent('' + limit) + '&'
    }
    if (offset !== undefined) {
      url_ += 'offset=' + encodeURIComponent('' + offset) + '&'
    }
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processListRequest('get', url_, options_, PrinterDriver).pipe(
      map((r) => {
        r.result.searchText = q
        return r
      })
    )
  }

  create(data: PrinterDriver): Observable<ApiResponse<PrinterDriver>> {
    let url_ = this.baseUrl + '/v1/drivers/'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('post', url_, options_, PrinterDriver)
  }

  /**
   * @param vendor (optional)
   * @param name_like (optional)
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  vendors(
    vendor: string | null | undefined,
    name_like: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<Vendors>> {
    let url_ = this.baseUrl + '/v1/drivers/vendors/?'
    if (vendor !== undefined) {
      url_ += 'vendor=' + encodeURIComponent('' + vendor) + '&'
    }
    if (name_like !== undefined) {
      url_ += 'name_like=' + encodeURIComponent('' + name_like) + '&'
    }
    if (q !== undefined) {
      url_ += 'q=' + encodeURIComponent('' + q) + '&'
    }
    if (o !== undefined) {
      url_ += 'o=' + encodeURIComponent('' + o) + '&'
    }
    if (limit !== undefined) {
      url_ += 'limit=' + encodeURIComponent('' + limit) + '&'
    }
    if (offset !== undefined) {
      url_ += 'offset=' + encodeURIComponent('' + offset) + '&'
    }
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }
    return this.processRequest('get', url_, options_, Vendors)
  }

  read(id: string): Observable<ApiResponse<PrinterDriver>> {
    let url_ = this.baseUrl + '/v1/drivers/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('get', url_, options_, PrinterDriver)
  }

  update(data: PrinterDriver, id: string): Observable<ApiResponse<PrinterDriver>> {
    let url_ = this.baseUrl + '/v1/drivers/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('put', url_, options_, PrinterDriver)
  }

  partialupdate(data: PrinterDriver, id: string): Observable<ApiResponse<PrinterDriver>> {
    let url_ = this.baseUrl + '/v1/drivers/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('patch', url_, options_, PrinterDriver)
  }

  delete(id: string): Observable<ApiResponse<void>> {
    let url_ = this.baseUrl + '/v1/drivers/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest<null>('delete', url_, options_, null)
  }
}

export interface IFiltersService {
  list(resource_name: string): Observable<ApiResponse<Filters>>
}

@Injectable({
  providedIn: 'root',
})
export class FiltersService extends RequesterApiService implements IFiltersService {
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  list(resource_name: string): Observable<ApiResponse<Filters>> {
    let url_ = this.baseUrl + '/v1/filter_values/{resource_name}/'
    if (resource_name === undefined || resource_name === null) {
      throw new Error("The parameter 'resource_name' must be defined.")
    }
    url_ = url_.replace('{resource_name}', encodeURIComponent('' + resource_name))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('get', url_, options_, Filters)
  }
}

export interface IGroupsService {
  /**
   * @param name (optional)
   * @param local_printing_enabled (optional)
   * @param is_federated (optional) false to return internal groups
   * @param foreign_id (optional)
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    name: string | null | undefined,
    local_printing_enabled: string | null | undefined,
    is_federated: string | null | undefined,
    foreign_id: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<Group>>>
  create(data: Group): Observable<ApiResponse<Group>>
  azure(data: Group): Observable<ApiResponse<Group>>
  read(id: string): Observable<ApiResponse<Group>>
  update(data: Group, id: string): Observable<ApiResponse<Group>>
  partialupdate(data: Group, id: string): Observable<ApiResponse<Group>>
  delete(id: string): Observable<ApiResponse<void>>
}

@Injectable({
  providedIn: 'root',
})
export class GroupsService extends RequesterApiService implements IGroupsService {
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  /**
   * @param name (optional)
   * @param local_printing_enabled (optional)
   * @param is_federated (optional) false to return internal groups
   * @param foreign_id (optional)
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    name: string | null | undefined,
    local_printing_enabled: string | null | undefined,
    is_federated: string | null | undefined,
    foreign_id: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<Group>>> {
    let url_ = this.baseUrl + '/v1/groups/?'
    if (name !== undefined) {
      url_ += 'name=' + encodeURIComponent('' + name) + '&'
    }
    if (is_federated !== undefined) {
      url_ += 'is_federated=' + encodeURIComponent('' + is_federated) + '&'
    }
    if (local_printing_enabled !== undefined) {
      url_ += 'local_printing_enabled=' + encodeURIComponent('' + local_printing_enabled) + '&'
    }
    if (foreign_id !== undefined) {
      url_ += 'foreign_id=' + encodeURIComponent('' + foreign_id) + '&'
    }
    if (q !== undefined) {
      url_ += 'q=' + encodeURIComponent('' + q) + '&'
    }
    if (o !== undefined) {
      url_ += 'o=' + encodeURIComponent('' + o) + '&'
    } else {
      url_ += 'o=' + encodeURIComponent('name') + '&'
    }
    if (limit !== undefined) {
      url_ += 'limit=' + encodeURIComponent('' + limit) + '&'
    }
    if (offset !== undefined) {
      url_ += 'offset=' + encodeURIComponent('' + offset) + '&'
    }
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processListRequest('get', url_, options_, Group).pipe(
      map((r) => {
        r.result.searchText = q
        return r
      })
    )
  }

  listMany(group_ids: string[]): Observable<ApiResponse<ListResult<Group>>> {
    let url_ = this.baseUrl + '/v1/groups/search/'
    if (group_ids === null || group_ids.length == 0) {
      throw new Error("The parameter 'group_ids' must be defined.")
    }
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify({ group_ids })

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    }

    return this.processListRequest('post', url_, options_, Group)
  }

  create(data: Group): Observable<ApiResponse<Group>> {
    let url_ = this.baseUrl + '/v1/groups/'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('post', url_, options_, Group)
  }

  azure(data: Group): Observable<ApiResponse<Group>> {
    let url_ = this.baseUrl + '/v1/groups/azure/'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('post', url_, options_, Group)
  }

  read(id: string): Observable<ApiResponse<Group>> {
    let url_ = this.baseUrl + '/v1/groups/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('get', url_, options_, Group)
  }

  update(data: Group, id: string): Observable<ApiResponse<Group>> {
    let url_ = this.baseUrl + '/v1/groups/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('put', url_, options_, Group)
  }

  partialupdate(data: Group, id: string): Observable<ApiResponse<Group>> {
    let url_ = this.baseUrl + '/v1/groups/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('patch', url_, options_, Group)
  }

  delete(id: string): Observable<ApiResponse<void>> {
    let url_ = this.baseUrl + '/v1/groups/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest<null>('delete', url_, options_, null)
  }
}

export interface IDefaultGroupAssignmentsService {
  /**
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */

  list(
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<DefaultGroupAssignment>>>
  create(data: DefaultGroupAssignment): Observable<ApiResponse<DefaultGroupAssignment>>
  read(id: string): Observable<ApiResponse<DefaultGroupAssignment>>
  update(data: DefaultGroupAssignment, id: string): Observable<ApiResponse<DefaultGroupAssignment>>
  delete(id: string): Observable<ApiResponse<void>>
}

@Injectable({
  providedIn: 'root',
})
export class DefaultGroupAssignmentsService
  extends RequesterApiService
  implements IDefaultGroupAssignmentsService
{
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  list(
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<DefaultGroupAssignment>>> {
    let url_ = this.baseUrl + '/v1/default_group_assignments/?'

    if (q !== undefined) {
      url_ += 'q=' + encodeURIComponent('' + q) + '&'
    }
    if (o !== undefined) {
      url_ += 'o=' + encodeURIComponent('' + o) + '&'
    }
    if (limit !== undefined) {
      url_ += 'limit=' + encodeURIComponent('' + limit) + '&'
    }
    if (offset !== undefined) {
      url_ += 'offset=' + encodeURIComponent('' + offset) + '&'
    }
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processListRequest('get', url_, options_, DefaultGroupAssignment).pipe(
      map((r) => {
        r.result.searchText = q
        return r
      })
    )
  }

  create(data: DefaultGroupAssignment): Observable<ApiResponse<DefaultGroupAssignment>> {
    let url_ = this.baseUrl + '/v1/default_group_assignments/'
    url_ = url_.replace(/[?&]$/, '')

    delete data.id
    delete data.enabled
    if (!data.expression) delete data.expression
    if (!data.groups) data.groups = []

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('post', url_, options_, DefaultGroupAssignment)
  }

  read(id: string): Observable<ApiResponse<DefaultGroupAssignment>> {
    let url_ = this.baseUrl + '/v1/default_group_assignments/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('get', url_, options_, DefaultGroupAssignment)
  }

  update(
    data: DefaultGroupAssignment,
    id: string
  ): Observable<ApiResponse<DefaultGroupAssignment>> {
    let url_ = this.baseUrl + '/v1/default_group_assignments/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('patch', url_, options_, DefaultGroupAssignment)
  }

  delete(id: string): Observable<ApiResponse<void>> {
    let url_ = this.baseUrl + '/v1/default_group_assignments/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest<null>('delete', url_, options_, null)
  }
}

export interface IUserPrintingService {
  /**
   * @param expanded (optional) true to return groups names
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param is_assigned_group (optional) Which field to use when ordering the results by assigned users (is_assigned ordering).
   * @param group_id (optional) Search the users by group id.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    expanded: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    is_assigned_group: string | null | undefined,
    group_id: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<UserPrinting>>>
  update(data: UserPrinting, id: string): Observable<ApiResponse<UserPrinting>>
  read(id: string): Observable<ApiResponse<UserPrinting>>
  getSettings(id: string): Observable<ApiResponse<UserSettings>>
  updateSettings(data: UserSettings, id: string): Observable<ApiResponse<UserSettings>>
}

@Injectable({
  providedIn: 'root',
})
export class UserPrintingService extends RequesterApiService implements IUserPrintingService {
  list(
    expanded: string,
    q: string,
    o: string,
    is_assigned_group: any,
    group_id: string,
    limit: number,
    offset: number
  ): Observable<ApiResponse<ListResult<UserPrinting>>> {
    let url_ = this.baseUrl + '/v1/users/?'
    if (expanded !== undefined) {
      url_ += 'expand=' + encodeURIComponent('' + expanded) + '&'
    }
    // if (group !== undefined)
    //   url_ += "group=" + encodeURIComponent("" + group) + "&";
    if (q !== undefined) {
      url_ += 'q=' + encodeURIComponent('' + q) + '&'
    }
    if (o !== undefined) {
      url_ += 'o=' + encodeURIComponent('' + o) + '&'
    }
    if (is_assigned_group !== undefined) {
      url_ += 'is_assigned_group=' + encodeURIComponent('' + is_assigned_group) + '&'
    }
    if (group_id !== undefined) {
      url_ += 'group=' + encodeURIComponent('' + group_id) + '&'
    }
    if (limit !== undefined) {
      url_ += 'limit=' + encodeURIComponent('' + limit) + '&'
    }
    if (offset !== undefined) {
      url_ += 'offset=' + encodeURIComponent('' + offset) + '&'
    }
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processListRequest('get', url_, options_, UserPrinting).pipe(
      map((r) => {
        r.result.searchText = q
        return r
      })
    )
  }

  update(data: UserPrinting, id: string): Observable<ApiResponse<UserPrinting>> {
    let url_ = this.baseUrl + '/v1/users/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const groups =
      data.groups && data.groups.length > 0
        ? data.groups.map((x) => ({ group_id: x.id ? x.id : x.group_id }))
        : []
    const content_ = JSON.stringify({
      groups: groups,
    })

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('put', url_, options_, UserPrinting)
  }

  read(id: string): Observable<ApiResponse<UserPrinting>> {
    let url_ = this.baseUrl + '/v1/users/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('get', url_, options_, UserPrinting)
  }

  getSettings(id: string): Observable<ApiResponse<UserSettings>> {
    let url_ = this.baseUrl + '/v1/users/{id}/settings/'
    if (id === undefined || id === null) {
      url_ = this.baseUrl + '/v1/users/me/settings/'
    } else {
      url_ = url_.replace('{id}', encodeURIComponent('' + id))
      url_ = url_.replace(/[?&]$/, '')
    }

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('get', url_, options_, UserSettings)
  }

  updateSettings(data: UserSettings, id: string): Observable<ApiResponse<UserSettings>> {
    let url_ = this.baseUrl + '/v1/users/{id}/'
    if (id === undefined || id === null) {
      url_ = this.baseUrl + '/v1/users/me/settings/'
    } else {
      url_ = url_.replace('{id}', encodeURIComponent('' + id))
      url_ = url_.replace(/[?&]$/, '')
    }

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('put', url_, options_, UserSettings)
  }

  getUsersInGroup(group_id: string): Observable<any> {
    const url = this.baseUrl + `/v1/groups/${group_id}/users/`
    return this.http.get(url, { observe: 'body' })
  }
}

export interface IPrintersService {
  /**
   * @param status (optional)
   * @param vendor_name (optional)
   * @param model_name (optional)
   * @param location (optional)
   * @param driver_id (optional)
   * @param user_id (optional)
   * @param driver_name (optional)
   * @param connector_id (optional)
   * @param expand (optional)
   * @param favorite_only (optional)
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    status: string | null | undefined,
    vendor_name: string | null | undefined,
    model_name: string | null | undefined,
    location: string | null | undefined,
    driver_id: string | null | undefined,
    user_id: string | null | undefined,
    driver_name: string | null | undefined,
    connector_id: string | null | undefined,
    expand: string | null | undefined,
    favorite_only: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<Printer>>>
  create(data: Printer): Observable<ApiResponse<Printer>>
  read(id: string): Observable<ApiResponse<Printer>>
  update(data: Printer, id: string, favorite_only?: boolean): Observable<ApiResponse<Printer>>
  partialupdate(data: Printer, id: string): Observable<ApiResponse<Printer>>
  delete(id: string): Observable<ApiResponse<void>>
}

@Injectable({
  providedIn: 'root',
})
export class PrintersService extends RequesterApiService implements IPrintersService {
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  /**
   * @param status (optional)
   * @param vendor_name (optional)
   * @param model_name (optional)
   * @param location (optional)
   * @param driver_id (optional)
   * @param user_id (optional)
   * @param driver_name (optional)
   * @param connector_id (optional)
   * @param expand (optional)
   * @param favorite_only (optional, default: true)
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    status: string | null | undefined,
    vendor_name: string | null | undefined,
    model_name: string | null | undefined,
    location: string | null | undefined,
    driver_id: string | null | undefined,
    user_id: string | null | undefined,
    driver_name: string | null | undefined,
    connector_id: string | null | undefined,
    expand: string | null | undefined,
    favorite_only: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<Printer>>> {
    let url_ = this.baseUrl + '/v1/printers/?'
    if (status !== undefined) {
      url_ += 'status=' + encodeURIComponent('' + status) + '&'
    }
    if (vendor_name !== undefined) {
      url_ += 'vendor_name=' + encodeURIComponent('' + vendor_name) + '&'
    }
    if (model_name !== undefined) {
      url_ += 'model_name=' + encodeURIComponent('' + model_name) + '&'
    }
    if (location !== undefined) {
      url_ += 'location=' + encodeURIComponent('' + location) + '&'
    }
    if (driver_id !== undefined) {
      url_ += 'driver_id=' + encodeURIComponent('' + driver_id) + '&'
    }
    if (user_id !== undefined) {
      url_ += 'user_id=' + encodeURIComponent('' + user_id) + '&'
    }
    if (driver_name !== undefined) {
      url_ += 'driver_name=' + encodeURIComponent('' + driver_name) + '&'
    }
    if (connector_id !== undefined) {
      url_ += 'connector_id=' + encodeURIComponent('' + connector_id) + '&'
    }
    if (expand !== undefined) {
      url_ += 'expand=' + encodeURIComponent('' + expand) + '&'
    }
    if (favorite_only !== undefined) {
      url_ += 'favorite_only=' + encodeURIComponent('' + favorite_only) + '&'
    }
    if (q !== undefined) {
      url_ += 'q=' + encodeURIComponent('' + q) + '&'
    }
    if (o !== undefined) {
      url_ += 'o=' + encodeURIComponent('' + o) + '&'
    }
    if (limit !== undefined) {
      url_ += 'limit=' + encodeURIComponent('' + limit) + '&'
    }
    if (offset !== undefined) {
      url_ += 'offset=' + encodeURIComponent('' + offset) + '&'
    }
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processListRequest('get', url_, options_, Printer).pipe(
      map((r) => {
        r.result.searchText = q
        return r
      })
    )
  }

  create(data: Printer): Observable<ApiResponse<Printer>> {
    let url_ = this.baseUrl + '/v1/printers/'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('post', url_, options_, Printer)
  }

  read(id: string): Observable<ApiResponse<Printer>> {
    let url_ = this.baseUrl + '/v1/printers/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('get', url_, options_, Printer)
  }

  update(data: Printer, id: string, favorite_only?: boolean): Observable<ApiResponse<Printer>> {
    let url_ = this.baseUrl + '/v1/printers/{id}/?'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    if (favorite_only !== undefined) {
      url_ += 'favorite_only=' + encodeURIComponent('' + favorite_only) + '&'
    }
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('put', url_, options_, Printer)
  }

  partialupdate(data: Printer, id: string): Observable<ApiResponse<Printer>> {
    let url_ = this.baseUrl + '/v1/printers/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('patch', url_, options_, Printer)
  }

  delete(id: string): Observable<ApiResponse<void>> {
    let url_ = this.baseUrl + '/v1/printers/{id}/'
    if (id === undefined || id === null) {
      throw new Error("The parameter 'id' must be defined.")
    }
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest<null>('delete', url_, options_, null)
  }

  assignDriver(printerId: string, driverId: string) {
    let url_ = this.baseUrl + '/v1/printers/{printerId}/'
    if (printerId === undefined || printerId === null) {
      throw new Error("The parameter 'printerId' must be defined.")
    }
    url_ = url_.replace('{printerId}', encodeURIComponent('' + printerId))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify({ driver_id: driverId })

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('patch', url_, options_, Printer)
  }
}

export interface IProfileService {
  read(): Observable<ApiResponse<OrganizationProfile>>
  create(data: OrganizationProfile): Observable<ApiResponse<OrganizationProfile>>
  update(data: OrganizationProfile): Observable<ApiResponse<OrganizationProfile>>
  partialupdate(data: OrganizationProfile): Observable<ApiResponse<OrganizationProfile>>
}

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends RequesterApiService implements IProfileService {
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  read(): Observable<ApiResponse<OrganizationProfile>> {
    let url_ = this.baseUrl + '/v1/profile/'
    url_ = url_.replace(/[?&]$/, '')

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('get', url_, options_, OrganizationProfile)
  }

  create(data: OrganizationProfile): Observable<ApiResponse<OrganizationProfile>> {
    let url_ = this.baseUrl + '/v1/profile/'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('post', url_, options_, OrganizationProfile)
  }

  update(data: OrganizationProfile): Observable<ApiResponse<OrganizationProfile>> {
    let url_ = this.baseUrl + '/v1/profile/'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('put', url_, options_, OrganizationProfile)
  }

  partialupdate(data: OrganizationProfile): Observable<ApiResponse<OrganizationProfile>> {
    let url_ = this.baseUrl + '/v1/profile/'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('patch', url_, options_, OrganizationProfile)
  }
}

@Injectable({
  providedIn: 'root',
})
export class HubService extends RequesterApiService {
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  claimHub(serialString: string): Observable<ApiResponse<Hub>> {
    let url_ = this.baseUrl + '/v1/iot_claims/'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify({ serial_string: serialString })

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('post', url_, options_, Hub)
  }
}

// --------------------------------------------------------------------------------------
// --------------------------------------Entities----------------------------------------

export class Mappings extends ApiResult implements IMappings {
  Name?: string
  ModelName?: string
  IpAddress?: string
  Location?: string
  Color?: string
  Duplex?: string
  DriverBundleId?: string
  PrinterProfileId?: string
  IpAddressRanges: any[]
  Users: any[]
  Groups: any[]

  constructor(data?: IMappings) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
    if (!data) {
      this.IpAddressRanges = []
      this.Users = []
      this.Groups = []
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).Name = data['Name']
      ;(<any>this).ModelName = data['ModelName']
      ;(<any>this).IpAddress = data['IpAddress']
      ;(<any>this).Location = data['Location']
      ;(<any>this).Color = data['Color']
      ;(<any>this).Duplex = data['Duplex']
      ;(<any>this).DriverBundleId = data['DriverBundleId']
      ;(<any>this).PrinterProfileId = data['PrinterProfileId']
      ;(<any>this).IpAddressRanges = data['IpAddressRanges']
      if (Array.isArray(data['IpAddressRanges'])) {
        this.IpAddressRanges = [] as any
        for (const item of data['IpAddressRanges']) {
          this.IpAddressRanges!.push(item)
        }
      }
      ;(<any>this).Users = data['Users']
      if (Array.isArray(data['Users'])) {
        this.Users = [] as any
        for (const item of data['Users']) {
          this.Users!.push(item)
        }
      }
      ;(<any>this).Groups = data['Groups']
      if (Array.isArray(data['Groups'])) {
        this.Groups = [] as any
        for (const item of data['Groups']) {
          this.Groups!.push(item)
        }
      }
    }
  }

  fromJS(data: any): Mappings {
    data = typeof data === 'object' ? data : {}
    const result = new Mappings()
    result.init(data)
    return result
  }
}

export interface IMappings {
  Name?: string | undefined
  ModelName?: string | undefined
  IpAddress?: string | undefined
  Location?: string | undefined
  Color?: string | undefined
  Duplex?: string | undefined
  DriverBundleId?: string | undefined
  PrinterProfileId?: string | undefined
  IpAddressRanges: any[]
  Users: any[]
  Groups: any[]
}

export class PrinterAssignment extends ApiResult implements IPrinterAssignment {
  readonly printer_id?: string | undefined
  readonly printer_name?: string | undefined
  readonly assignments?: any | undefined
  assignment!: any[]

  constructor(data?: IPrinterAssignment) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
    if (!data) {
      this.assignment = []
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).printer_id = data['printer_id']
      ;(<any>this).printer_name = data['printer_name']
      ;(<any>this).assignments = data['assignments']
      if (Array.isArray(data['assignment'])) {
        this.assignment = [] as any
        for (const item of data['assignment']) {
          this.assignment!.push(item)
        }
      }
    }
  }

  fromJS(data: any): PrinterAssignment {
    data = typeof data === 'object' ? data : {}
    const result = new PrinterAssignment()
    result.init(data)
    return result
  }
}

export interface IPrinterAssignment {
  printer_id?: string | undefined
  printer_name?: string | undefined
  assignments?: any | undefined
  assignment: any[]
}

export class PrinterUserAssignment extends ApiResult implements IPrinterUserAssignment {
  readonly printer_id?: string | undefined
  readonly printer_name?: string | undefined
  readonly assignments?: any | undefined
  assignment!: any[]

  constructor(data?: IPrinterUserAssignment) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
    if (!data) {
      this.assignment = []
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).printer_id = data['printer_id']
      ;(<any>this).printer_name = data['printer_name']
      ;(<any>this).assignments = data['assignments']
      if (Array.isArray(data['assignment'])) {
        this.assignment = [] as any
        for (const item of data['assignment']) {
          this.assignment!.push(item)
        }
      }
    }
  }

  fromJS(data: any): PrinterUserAssignment {
    data = typeof data === 'object' ? data : {}
    const result = new PrinterUserAssignment()
    result.init(data)
    return result
  }
}

export interface IPrinterUserAssignment {
  printer_id?: string | undefined
  printer_name?: string | undefined
  assignments?: any | undefined
  assignment: any[]
}

export class Connector extends ApiResult implements IConnector {
  readonly id?: string | undefined
  readable_name!: string
  description?: string | undefined
  type!: string
  tpuid!: number
  status!: string
  location?: string | undefined
  management_url?: string | undefined
  hostname?: string | undefined
  internal_ip?: string | undefined
  external_ip?: string | undefined
  os_type?: string | undefined
  sw_version?: string | undefined
  last_seen_online?: Date | undefined
  readonly last_printer_update?: string | undefined
  organization_id!: string

  constructor(data?: IConnector) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).id = data['id']
      this.readable_name = data['readable_name']
      this.description = data['description']
      this.type = data['type']
      this.tpuid = data['tpuid']
      this.status = data['status']
      this.location = data['location']
      this.management_url = data['management_url']
      this.hostname = data['hostname']
      this.internal_ip = data['internal_ip']
      this.external_ip = data['external_ip']
      this.os_type = data['os_type']
      this.sw_version = data['sw_version']
      this.last_seen_online = data['last_seen_online']
        ? new Date(data['last_seen_online'].toString())
        : <any>undefined
      ;(<any>this).last_printer_update = data['last_printer_update']
      this.organization_id = data['organization_id']
    }
  }

  fromJS(data: any): Connector {
    data = typeof data === 'object' ? data : {}
    const result = new Connector()
    result.init(data)
    return result
  }
}

export interface IConnector {
  id?: string | undefined
  readable_name: string
  description?: string | undefined
  type: string
  tpuid: number
  status: string
  location?: string | undefined
  management_url?: string | undefined
  hostname?: string | undefined
  internal_ip?: string | undefined
  external_ip?: string | undefined
  os_type?: string | undefined
  sw_version?: string | undefined
  last_seen_online?: Date | undefined
  last_printer_update?: string | undefined
  organization_id: string
}

export class PrinterDriver extends ApiResult implements IPrinterDriver {
  readonly id?: string | undefined
  name!: string
  readable_name?: string | undefined
  vendor!: string
  os?: string | undefined
  arch?: string | undefined

  constructor(data?: IPrinterDriver) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).id = data['id']
      this.name = data['name']
      this.readable_name = data['readable_name']
      this.vendor = data['vendor']
      this.os = data['os']
      this.arch = data['arch']
    }
  }

  fromJS(data: any): PrinterDriver {
    data = typeof data === 'object' ? data : {}
    const result = new PrinterDriver()
    result.init(data)
    return result
  }
}

export interface IPrinterDriver {
  id?: string | undefined
  name: string
  readable_name?: string | undefined
  vendor: string
  os?: string | undefined
  arch?: string | undefined
}

export class Group extends ApiResult implements IGroup {
  readonly id?: string | undefined
  name!: string
  description: string | undefined
  local_printing_enabled?: boolean | undefined
  readonly origin?: { provider: string } | undefined
  readonly organization_id?: string | undefined
  myprinters_enabled: boolean | undefined

  constructor(data?: IGroup) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).id = data['id']
      this.name = data['name']
      this.description = data['description']
      this.local_printing_enabled = data['local_printing_enabled']
      this.myprinters_enabled = data['myprinters_enabled']
      ;(<any>this).origin = data['origin']
      ;(<any>this).organization_id = data['organization_id']
    }
  }

  fromJS(data: any): Group {
    data = typeof data === 'object' ? data : {}
    const result = new Group()
    result.init(data)
    return result
  }
}

export interface IGroup {
  id?: string | undefined
  name: string
  description: string | undefined
  local_printing_enabled?: boolean | undefined
  origin?: { provider: string } | undefined
  organization_id?: string | undefined
  myprinters_enabled: boolean | undefined
}

export class DefaultGroupAssignment extends ApiResult implements DefaultGroupAssignment {
  id?: string | undefined
  expression: string | undefined
  groups: any[]
  enabled: boolean
  organization_id: string | undefined
  domain: string | undefined

  constructor(data?: IDefaultGroupAssignment) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
    if (!data) {
      this.groups = []
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).id = data['id']
      this.expression = data['expression']
      this.enabled = data['enabled']
      this.organization_id = data['organization_id']
      this.domain = data['domain']
      ;(<any>this).groups = data['groups']
      if (Array.isArray(data['groups'])) {
        this.groups = [] as any
        for (const item of data['groups']) {
          this.groups!.push(item)
        }
      }
    }
  }

  fromJS(data: any): DefaultGroupAssignment {
    data = typeof data === 'object' ? data : {}
    const result = new DefaultGroupAssignment()
    result.init(data)
    return result
  }
}

export interface IDefaultGroupAssignment {
  id?: string | undefined
  expression: string | undefined
  groups: any[]
  enabled: boolean
  organization_id: string | undefined
  domain: string | undefined
}

export class UserPrinting extends ApiResult implements IUserPrinting {
  readonly id?: string | undefined
  groups: any[]

  constructor(data?: IGroup) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).id = data['id']
      if (Array.isArray(data['groups'])) {
        this.groups = [] as any
        for (const item of data['groups']) {
          this.groups!.push(item)
        }
      }
    }
  }

  fromJS(data: any): UserPrinting {
    data = typeof data === 'object' ? data : {}
    const result = new UserPrinting()
    result.init(data)
    return result
  }
}

export interface IUserPrinting {
  id?: string | undefined
  groups: any[]
}

export class UserSettings extends ApiResult implements IUserSettings {
  readonly id?: string | undefined
  show_welcome_overlay: any
  show_quickstart_dialog: any

  constructor(data?: IUserSettings) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).id = data['id']
      ;(<any>this).show_welcome_overlay = data['show_welcome_overlay']
      ;(<any>this).show_quickstart_dialog = data['show_quickstart_dialog']
    }
  }

  fromJS(data: any): UserSettings {
    data = typeof data === 'object' ? data : {}
    const result = new UserSettings()
    result.init(data)
    return result
  }
}

export interface IUserSettings {
  id?: string | undefined
  show_welcome_overlay: any
  show_quickstart_dialog: any
}

export class Printer extends ApiResult implements IPrinter {
  readonly id?: string | undefined
  name!: string
  tp_id?: number | undefined
  status!: string
  vendor_name?: string | undefined
  model_name?: string | undefined
  location?: string | undefined
  connector_id!: string
  driver_id?: string | undefined
  is_favorite?: string | undefined
  is_queue?: string | undefined
  can_modify_favorite?: boolean | undefined
  connector?: {
    readable_name?: string | undefined
  }

  constructor(data?: IPrinter) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).id = data['id']
      this.name = data['name']
      this.tp_id = data['tp_id']
      this.status = data['status']
      this.vendor_name = data['vendor_name']
      this.model_name = data['model_name']
      this.location = data['location']
      this.connector_id = data['connector_id']
      this.driver_id = data['driver_id']
      this.connector = data['connector']
      this.is_favorite = data['is_favorite']
      this.is_queue = data['is_queue']
      this.can_modify_favorite = data['can_modify_favorite']
    }
  }

  fromJS(data: any): Printer {
    data = typeof data === 'object' ? data : {}
    const result = new Printer()
    result.init(data)
    return result
  }
}

export interface IPrinter {
  id?: string | undefined
  name: string
  tp_id?: number | undefined
  status: string
  vendor_name?: string | undefined
  model_name?: string | undefined
  location?: string | undefined
  connector_id: string
  driver_id?: string | undefined
  is_favorite?: string | undefined
  is_queue?: string | undefined
  can_modify_favorite?: boolean | undefined
  connector?: {
    readable_name?: string | undefined
  }
}

export class OrganizationProfile extends ApiResult implements IOrganizationProfile {
  self_service_enabled?: boolean | undefined
  local_printing_enabled?: boolean | undefined
  organization_id!: string
  myprinters_enabled?: boolean | undefined
  pull_printing_enabled?: boolean
  print_later_enabled?: boolean
  subscription?:
    | {
        is_valid: boolean | undefined
        readonly feature_pull_printing: boolean | undefined
        readonly feature_print_later: boolean | undefined
        readonly feature_my_printers: boolean | undefined
      }
    | undefined

  constructor(data?: IOrganizationProfile) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.self_service_enabled = data['self_service_enabled']
      this.local_printing_enabled = data['local_printing_enabled']
      this.organization_id = data['organization_id']
      this.myprinters_enabled = data['myprinters_enabled']
      this.pull_printing_enabled = data['pull_printing_enabled']
      this.print_later_enabled = data['print_later_enabled']
      this.subscription = {
        is_valid: data['subscription'] ? data['subscription']['is_valid'] : undefined,
        feature_pull_printing: data['subscription']
          ? data['subscription']['feature_pull_printing']
          : undefined,
        feature_print_later: data['subscription']
          ? data['subscription']['feature_print_later']
          : undefined,
        feature_my_printers: data['subscription']
          ? data['subscription']['feature_my_printers']
          : undefined,
      }
    }
  }

  fromJS(data: any): OrganizationProfile {
    data = typeof data === 'object' ? data : {}
    const result = new OrganizationProfile()
    result.init(data)
    return result
  }
}

export interface IOrganizationProfile {
  self_service_enabled?: boolean | undefined
  local_printing_enabled?: boolean | undefined
  organization_id: string
  myprinters_enabled?: boolean | undefined
  pull_printing_enabled?: boolean | undefined
  print_later_enabled?: boolean | undefined
  subscription?:
    | {
        is_valid: boolean | undefined
        readonly feature_pull_printing: boolean | undefined
        readonly feature_print_later: boolean | undefined
        readonly feature_my_printers: boolean | undefined
      }
    | undefined
}

export class Vendors extends ApiResult implements IVendors {
  results!: string[]

  constructor(data?: IVendors) {
    super()
    if (data) {
      if (Array.isArray(data)) {
        this.results = [] as any
        for (const item of data) {
          this.results!.push(item)
        }
      }
    }
    if (!data) {
      this.results = []
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data)) {
        this.results = [] as any
        for (const item of data) {
          this.results!.push(item)
        }
      }
    }
  }

  fromJS(data: any): Vendors {
    data = typeof data === 'object' ? data : {}
    const result = new Vendors()
    result.init(data)
    return result
  }
}

export interface IVendors {
  results: any[]
}

export class Filters extends ApiResult implements IFilters {
  result: { field_name: string; values: string[] }[]

  constructor(data?: IFilters) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    } else {
      this.result = []
    }
  }

  init(data?: any) {
    if (data) {
      this.result.push({ field_name: data['field_name'], values: data['values'] })
    }
  }

  fromJS(data: any): Filters {
    data = typeof data === 'object' ? data : {}
    const filters = new Filters()
    data.forEach((d) => {
      const filter = filters.init(d)
    })
    return filters
  }
}

export interface IFilters {
  result: { field_name: string; values: string[] }[]
}

export interface IHub {
  id: string
  connector_id: string
}

export class Hub extends ApiResult implements IHub {
  id: string
  connector_id: string

  constructor(data?: IHub) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data['id']
      this.connector_id = data['connector_id']
    }
  }

  fromJS(data: any): Hub {
    data = typeof data === 'object' ? data : {}
    const result = new Hub()
    result.init(data)
    return result
  }
}

export interface IHubPassword {
  password?: string | undefined
}

export class HubPassword extends ApiResult implements IHubPassword {
  password?: string | undefined

  constructor(data?: IHubPassword) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).password = data['password']
    }
  }

  fromJS(data: any): HubPassword {
    data = typeof data === 'object' ? data : {}
    const result = new HubPassword()
    result.init(data)
    return result
  }
}

export interface IHubClaimConnect {
  auth_url: string
}

export class HubClaimConnect extends ApiResult implements IHubClaimConnect {
  auth_url: string

  constructor(data?: IHubClaimConnect) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.auth_url = data['auth_url']
    }
  }

  fromJS(data: any): HubClaimConnect {
    data = typeof data === 'object' ? data : {}
    const result = new HubClaimConnect()
    result.init(data)
    return result
  }
}

export interface IQRCode {
  printers: string[]
}

export class QRCode extends ApiResult implements IQRCode {
  printers: string[]

  constructor(data?: IQRCode) {
    super()
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          ;(<any>this)[property] = (<any>data)[property]
        }
      }
    }
    // if (!data) {
    //   this.printers = [];
    // }
  }

  init(data?: any) {
    if (data) {
      // this.printerIds = data['printers'];
      ;(<any>this).printers = data['printers']
    }
  }

  fromJS(data: any): QRCode {
    data = typeof data === 'object' ? data : {}
    const result = new QRCode()
    result.init(data)
    return result
  }
}
