import { Component, OnInit } from '@angular/core';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import { DIALOG_RESPONSE } from '../../modules/shared/classes/dialog-result';
import { UsersgroupsDomainsService } from '../../modules/shared/services/usersgroups-domains.service';
import { UsersgroupsGroupsService } from '../../modules/shared/services/usersgroups-groups.service';
import { UsersgroupsUsersService } from '../../modules/shared/services/usersgroups-users.service';
import { SubscriptionService } from '../../modules/shared/services/subscription.service';

@Component({
  selector: 'ezd-usersgroups',
  templateUrl: './usersgroups.component.html',
  styleUrls: ['./usersgroups.component.scss']
})
export class UsersgroupsComponent implements OnInit {
  currentTab: string = 'users';
  currentTabIndex: number;
  showDialog: boolean = false;
  operationType: string;
  enableGroups : boolean = false

  constructor(private groupsService: UsersgroupsGroupsService, 
    private usersService: UsersgroupsUsersService,
    private domainsService: UsersgroupsDomainsService,
    private subscriptionService: SubscriptionService) { }

  ngOnInit() {
   this.subscriptionService.getSubscription().subscribe((result) => {
      this.enableGroups = result.features && result.features.groups_printer_assignments ? true : false
    })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
  
  setCurrentTab(feature: string) {
    if (this.currentTab == feature) { return; }
    this.currentTab = feature;
    if(this.currentTab == 'groups') this.groupsService.applyFilters(true);
    if(this.currentTab == 'users') this.usersService.applyFilters(true);
    if(this.currentTab == 'domains') this.domainsService.applyFilters(true)
  }

  tabClick(tabChangeEvent: MatTabChangeEvent) {
    this.setCurrentTab(tabChangeEvent.tab.textLabel);
  }
  
  processDialogResult(result: DIALOG_RESPONSE) {
    this.showDialog = false;
  }

  showGroupDialog(result: any) {
    this.showDialog = result.show;
    this.operationType = result.operationType;
  }
}
