import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { ApiResult, ApiResponse, RequesterApiService, adconApiUrl } from './requester-api.service';

export interface IAdconGroupsService {
  get(): Observable<ApiResponse<AdconTenantGroups>>;
  post(msGraphTenantGroups: AdconTenantGroups | null | undefined): Observable<ApiResponse<AdconTenantGroups>>;
}

@Injectable({
  providedIn: 'root'
})
export class AdconGroupsService extends RequesterApiService implements IAdconGroupsService {

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(adconApiUrl) baseUrl?: string) {
    super(http, baseUrl ? baseUrl : "/federated");
  }

  get(): Observable<ApiResponse<AdconTenantGroups>> {
    let url_ = this.baseUrl + "/azure/api/Groups";
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Cache-Control": "no-cache"
      })
    };

    return this.processRequest("get", url_, options_, AdconTenantGroups);
  }

  get_v2(search:string|undefined, top:number|undefined, next:string|undefined): Observable<ApiResponse<AdconTenantGroups>> {
    let url_ = this.baseUrl + "/azure/api/v2/Groups/?";
    if (search !== undefined)
      url_ += "search=" + encodeURIComponent("" + search) + "&";
    if (top !== undefined)
      url_ += "top=" + encodeURIComponent("" + top) + "&";
    if (next !== undefined)
      url_ += "next=" + encodeURIComponent("" + next) + "&";
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json",
        "Cache-Control": "no-cache"
      })
    };

    return this.processRequest("get", url_, options_, AdconTenantGroups);
  }

  /**
   * @param msGraphTenantGroups (optional) 
   * @return Success
   */
  post(msGraphTenantGroups: AdconTenantGroups | null | undefined): Observable<ApiResponse<AdconTenantGroups>> {
    let url_ = this.baseUrl + "/azure/api/Groups";
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(msGraphTenantGroups);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Cache-Control": "no-cache"
      })
    };

    return this.processRequest("post", url_, options_, AdconTenantGroups);
  }
}

export class AdconTenantGroups extends ApiResult implements IAdconTenantGroups {
  value?: AdconGroup[] | undefined;
  tenantId?: string | undefined;
  next?: string | undefined;

  constructor(data?: IAdconTenantGroups) {
    super();
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      if (Array.isArray(data["value"])) {
        this.value = [] as any;
        for (let item of data["value"])
          this.value!.push(new AdconGroup().fromJS(item));
      }
      this.tenantId = data["tenantId"];
      this.next = data["next"];
    }
  }

  fromJS(data: any): AdconTenantGroups {
    data = typeof data === 'object' ? data : {};
    let result = new AdconTenantGroups();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    if (Array.isArray(this.value)) {
      data["value"] = [];
      for (let item of this.value)
        data["value"].push(item.toJSON());
    }
    data["tenantId"] = this.tenantId;
    data["next"] = this.next;
    return data;
  }
}

export interface IAdconTenantGroups {
  value?: AdconGroup[] | undefined;
  tenantId?: string | undefined;
  next?: string | undefined;
}

export class AdconGroup extends ApiResult implements IAdconGroup {
  id?: string | undefined;
  displayName?: string | undefined;

  constructor(data?: IAdconGroup) {
    super();
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.id = data["id"];
      this.displayName = data["displayName"];
    }
  }

  fromJS(data: any): AdconGroup {
    data = typeof data === 'object' ? data : {};
    let result = new AdconGroup();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["id"] = this.id;
    data["displayName"] = this.displayName;
    return data;
  }
}

export interface IAdconGroup {
  id?: string | undefined;
  displayName?: string | undefined;
}