import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { map } from 'rxjs/operators';
import { FiltersService } from './printing-api.service';

export enum FILTER_CATEGORIES {
  NAME = 'Name',
  MODEL = 'model_name',
  LOCATION = 'location',
  DRIVER = 'driver',
  STATUS = 'status',
  CONNECTOR = "Connector"
}

export const FILTERS_QUERY_PARAMETER_NAME = 'printerFilters';

@Injectable()
export class NetworkPrintersPrintersFiltersService {
  selectedFilters = [];
  selectedFilterSubject = new BehaviorSubject<any[]>([]);
  route: ActivatedRoute;
  searchText: string;
  routeParamsSubscription: Subscription;
  settingRoute: boolean;


  constructor(
    private httpClient: HttpClient, 
    private PrintingApiFiltersService: FiltersService,
    private router: Router) {
  }

  public getFilters(): Observable<{field_name:string,values:string[]}[]> {
    return this.PrintingApiFiltersService.list('printer').pipe(map(response => {
      let result = response.result.result.filter(r => {
        /***
        if (r.field_name == "status") {
          r.values = r.values.map(v => {
            if (v == "ON") { return "Online"; }
            if (v == "OFF") { return "Offline"; }
            else { return v; }
          });
        }
        EFA-1108 removed status filter temporary
        ***/
        if (r.field_name != "status" && r.field_name != 'driver')
          return r;
      });
      return result;
    }));
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.routeParamsSubscription)
      this.routeParamsSubscription.unsubscribe()
  }

  public getSelectedFilters(): Observable<any> {
    return this.selectedFilterSubject.asObservable();
  }

  public getSearchText(): string {
    return this.searchText;
  }

  public setSearchText(searchText: string) {
    this.searchText = searchText != null ? searchText.toLowerCase() : '';
    this.selectedFilterSubject.next(this.selectedFilters);
  }

  addFilter(fieldName, filterName, filterValue, setQueryParams = true) {
    const filterToAdd = {field_name: fieldName, name: filterName, value: filterValue};
    const sameCategoryAndNameFilters = this.selectedFilters.filter(filter => filter.field_name === filterToAdd.field_name && filter.name === filterToAdd.name);
    if (!sameCategoryAndNameFilters.length) {
      this.selectedFilters.push(filterToAdd);
    }
    if(setQueryParams)
    this.selectedFilterSubject.next(this.selectedFilters);
  }

  removeFilter(fieldName, filterName) {
    this.selectedFilters = this.selectedFilters.filter(filter => !(filter.field_name === fieldName && filter.name === filterName));
    this.selectedFilterSubject.next(this.selectedFilters);
  }

  removeAllFilters(setQueryParams = true) {
    this.selectedFilters = [];
    if(setQueryParams){
      this.selectedFilterSubject.next(this.selectedFilters);
    }
  }
}
