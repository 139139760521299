import { Component, OnInit, Input, Optional } from '@angular/core'
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog'
import { Router } from '@angular/router'
import { LocalPrinterSupportService } from '../../../modules/shared/services/local-printer-support.service'
import { PoliciesMyprintersService } from '../../../modules/shared/services/policies-myprinters.service'
import { PoliciesPullprintingService } from '../../../modules/shared/services/policies-pullprinting.service'
import { PoliciesPrintlaterService } from '../../../modules/shared/services/policies-printlater.service'
import { AddGroupDialogComponent } from './../add-group-dialog/add-group-dialog.component'
import { ProfileService } from '../../../modules/shared/services/printing-api.service'
import { SubscriptionService } from '../../../modules/shared/services/subscription.service'
import { lastValueFrom } from 'rxjs'

@Component({
  selector: 'ezd-policy-panel',
  templateUrl: './policy-panel.component.html',
  styleUrls: ['./policy-panel.component.scss'],
})
export class PolicyPanelComponent implements OnInit {
  @Input() title
  @Input() id
  @Input() description
  @Input() disabled
  @Input() disabledTitle
  @Input() groups
  @Input() type
  @Input() disabledGroups
  @Input() @Optional() grayOut;
  @Input() groupsEnabled :boolean;
  isPrintLaterPresent: boolean;
  isPullPrintingPresent: boolean;
  activeSubscription: boolean;

  constructor(
    private dialog: MatDialog, 
    private router: Router,
    private localPrinterSupportService: LocalPrinterSupportService,
    private policiesMyPrintersService: PoliciesMyprintersService,
    private policiesPullPrintingService: PoliciesPullprintingService,
    private policiesPrintLaterService: PoliciesPrintlaterService,
    private profileService: ProfileService,
    private subscriptionService: SubscriptionService
    ) {}

  ngOnInit(): void {
    this.profileService.read().subscribe(profileResponse => {
      this.isPullPrintingPresent = (profileResponse.result.subscription && profileResponse.result.subscription.feature_pull_printing && profileResponse.result.subscription.feature_pull_printing == true) ? true : false;
      this.isPrintLaterPresent = (profileResponse.result.subscription && profileResponse.result.subscription.feature_print_later && profileResponse.result.subscription.feature_print_later == true) ? true : false;
    })

    if (this.type === 'PULLPRINTING') {
      this.profileService.read().subscribe(profileResponse => {
        if (profileResponse.result.subscription.feature_pull_printing == false) {
          this.disabled = profileResponse.result.subscription.feature_pull_printing
        }
      })
    }
    if (this.type === 'PRINTLATER') {
      this.profileService.read().subscribe(profileResponse => {
        if (profileResponse.result.subscription.feature_print_later == false) {
          this.disabled = profileResponse.result.subscription.feature_print_later
        }
      })
    }
    if (this.type === 'PRINTLATER' || this.type === 'PULLPRINTING') {
      this.profileService.read().subscribe(profileResponse => {
        if (profileResponse.result.subscription.is_valid == false) {
          this.disabled = profileResponse.result.subscription.is_valid
        }
      })
    }

    this.subscriptionService.getSubscription().subscribe(async (result) => {
      this.isPrintLaterPresent = result.features && result.features.print_later ? true : false
      this.isPullPrintingPresent = result.features && result.features.pull_printing ? true : false
    })
  }

  onChangeDisabled(checked: boolean) {
    //this.disabled = checked
    if (this.type === 'LOCALPRINTERSUPPORT') {
      lastValueFrom(this.localPrinterSupportService.toggleLocalPrinterSupport())
         // .toPromise()
         .then(result => {
          this.disabled = result;
        });
    }
    if (this.type === 'MYPRINTERS') {
      lastValueFrom(this.policiesMyPrintersService.toggleMyPrinters())
         // .toPromise()
         .then(result => {
          this.disabled = result;
        });
    }
    if (this.type === 'PULLPRINTING') {
      lastValueFrom(this.policiesPullPrintingService.togglePullPrinting())
         // .toPromise()
         .then(result => {
          this.disabled = result;
        });
    }
    if (this.type === 'PRINTLATER') {
      lastValueFrom(this.policiesPrintLaterService.togglePrintLater())
         // .toPromise()
         .then(result => {
          this.disabled = result;
        });
    }
  }

  removeGroup(item: any) {
    this.groups = [];
    if (this.type === 'LOCALPRINTERSUPPORT') {
      this.localPrinterSupportService.removePrintersServiceGroup(item);
    }
    if (this.type === 'MYPRINTERS') {
      this.policiesMyPrintersService.removePrintersServiceGroup(item);
    }
  }

  openAddGroupDialog() {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = false
    dialogConfig.width = '600px'
    dialogConfig.panelClass = 'ezp-dialog'
    dialogConfig.data = {
      type: this.type
    }

    this.dialog.open(AddGroupDialogComponent, dialogConfig)
  }

  openNetworkPrinters() {
    this.router.navigate(['printers'], { queryParams: { tab: 'networkprinters' } })
  }

  openSubscriptionPage() {
    this.router.navigate(['subscription'])
  }
  
  transformText(text) {
    let textTranform = text.toLowerCase;
    return textTranform;
  }
}
