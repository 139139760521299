<div class="ezp-grid ezp-grid--gutter-vertical ezp-grid--spacing-vertical" data-grid-cols="1">
  <mat-form-field *ngIf="createGroup" class="ezp-form-field ezp-form-field--no-helper" appearance="fill" id="group-info-name">
    <mat-label id="group-info-name-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_INFO.NAME' | translate }}</mat-label>
    <input matInput id="group-info-name-input" #nameFocus [ngModel]="group.name"
    value="Group 1" (input)="onNameChange($event.target.value)"/>
  </mat-form-field>
  <mat-form-field *ngIf="!createGroup" class="ezp-form-field ezp-form-field--no-helper" appearance="fill" id="group-info-name">
    <mat-label id="group-info-name-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_INFO.NAME' | translate }}</mat-label>
    <input matInput id="group-info-name-input" #nameFocus [ngModel]="group.name" [disabled]="!isEzeepGroup"
    value="Group 1" (input)="onNameChange($event.target.value)"/>
  </mat-form-field>
  <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill" id="group-info-desc">
    <mat-label id="group-info-desc-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_INFO.DESCRIPTION' | translate }}</mat-label>
    <textarea matInput id="group-info-desc-textarea" #description [ngModel]="group.description" maxlength="255" cdkTextareaAutosize cdkAutosizeMinRows="4"
      cdkAutosizeMaxRows="8" value="Your fancy group description"
      (input)="onDescriptionChange($event.target.value)"></textarea>
    <mat-hint align="end" id="group-info-desc-hint">{{description.value?.length || 0}}/255</mat-hint>
  </mat-form-field>
</div>
<mat-divider></mat-divider>
<div
  class="ezp-grid ezp-grid--gutter-horizontal ezp-grid--spacing-top"
  data-grid-cols="2"
>
  <div class="group-id">
    <div class="group-id__item">
      <span class="ezp-text ezp-text--medium-emphasis mat-caption" id="group-info-ezeepid-text"
        ><strong>{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_INFO.EZEEP_ID' | translate }}</strong></span
      >
      <span class="ezp-text ezp-text--medium-emphasis mat-caption" [id]="group.id"
        >{{ group.id }}</span
      >
      <span class="group-id__copy ezp-text ezp-text--primary mat-caption" id="copy-group-ezeepid" (click)="copyEzeepId()"
        >{{ 'COPY' | translate }}</span
      >
    </div>
    <div class="group-id__item" *ngIf="group.origin?.provider == 'Azure'">
      <span class="ezp-text ezp-text--medium-emphasis mat-caption" id="group-info-azureid-text"
        ><strong>{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_INFO.AZURE_ID' | translate }}</strong></span
      >
      <span class="ezp-text ezp-text--medium-emphasis mat-caption" [id]="group.origin.foreign_id"
        >{{ group.origin.foreign_id }}</span
      >
      <span class="group-id__copy ezp-text ezp-text--primary mat-caption" id="copy-group-azureid" (click)="copyAzureId()"
        >{{ 'COPY' | translate }}</span
      >
    </div>
  </div>
</div>
