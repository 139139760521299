import { GROUPS } from "../../../pages/pages.constants";

export class GeneralUtil {
  static sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  static chunck(array: any[], size: number) {
    var index = 0;
    let result = [];

    while (index < array.length) {
      if (index % size === 0) {
        result.push([]);
      }
      result[result.length - 1].push(array[index++]);
    }

    return result;
  }

  static removeDuplicates(arr: any[]) {
    return arr.reduce((acc, obj) => {
      const existingObj = acc.find(item => item.id === obj.id);
      if (!existingObj) {
        return acc.concat(obj);
      }
      return acc;
    }, []);
  }
  static searchArray<T extends Record<string, any>>(
    arrayToSearch: T[],
    searchText: string,
    keyToSearch: string,
    secondKeyToSearch?: string
  ): T[] {
    const sortedArr: T[] = arrayToSearch.slice().sort((a: T, b: T) => {
      const nameA: string = a[keyToSearch] ? a[keyToSearch]?.toString().toLowerCase() : a[secondKeyToSearch]?.toString().toLowerCase();
      const nameB: string = b[keyToSearch] ? b[keyToSearch]?.toString().toLowerCase() : b[secondKeyToSearch]?.toString().toLowerCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });

    if (!searchText) {
      return sortedArr;
    }

    const searchLower: string = searchText.toLowerCase();
    const searchLetters: string[] = searchLower.split("");
    const filteredArr: T[] = sortedArr.filter((arr: T) => {
      const loweredName: string = arr[keyToSearch] ? arr[keyToSearch]?.toString().toLowerCase() : arr[secondKeyToSearch]?.toString().toLowerCase();

      let index: number = 0;

      for (const searchLetter of searchLetters) {
        const matchIndex: number = loweredName?.indexOf(searchLetter, index);
        if (matchIndex === -1) {
          return false;
        }
        index = matchIndex + 1;
      }
      return true;
    });

    return filteredArr;
  }

  static findAndAssignPrinterPresets(arrayA, arrayB, type) {
    let key = type === GROUPS ? 'group_id' : 'user_id';
    return arrayA.map(itemA => {
      let matchingItemB = arrayB.find(itemB => itemB[key] === itemA.id);
      if (matchingItemB) {
        itemA.printer_presets = [matchingItemB.printer_profile.id];
        itemA.profile_name = [matchingItemB.printer_profile.name];
      } else {
        itemA.printer_presets = [];
      }
      return itemA;
    });
  }

  static formatDateHoursAndMinutes(value: Date, localization: any) {
    return new Date(value).toLocaleDateString(localization, { hour: 'numeric', minute: 'numeric' })
  }

  static addselectDisabledToArray(targetArray, disable) {
    return targetArray.map(obj => ({ ...obj, selectDisabled: disable }));
  }

  static concatenateObjectPropertyAndSubstring(array: any[], property: string, limit: number = 27) {
    return GeneralUtil.substringIfLongerThan(array.map(obj => obj[property]).join(', '), limit);
  }

  static concatenateObjectProperty(array: any[], property: string) {
    return array.map(obj => obj[property]).join(', ');
  }

  static substringIfLongerThan(string: string, limit: number = 27) {
    if (string.length > 30) {
      return string.substring(0, limit) + '...';
    }
    return string;
  }

  static cleanObject(obj) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key) && obj[key] === '') {
        delete obj[key];
      }
    }
    return obj;
  }
}