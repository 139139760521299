<form [formGroup]="form">
    <div class="ezp-grid ezp-grid--gutter ezp-grid--spacing-vertical" data-grid-cols="2">
        <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
            <mat-label id="properties-name-text">{{ 'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.NAME' | translate
                }}</mat-label>
            <span>
                <input formControlName="name" matInput id="properties-name" />
            </span>
        </mat-form-field>
        <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
            <mat-label id="properties-triggers-text">{{ 'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.TRIGGERS' | translate
                }}</mat-label>
            <mat-select formControlName="topics" id="uproperties-trigger" multiple>
                <mat-option id="properties-trigger-value" [value]="triggerOptions[0]">{{
                    'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.TOPICS.PRINTJOB_SUCCEEDED' |
                    translate }}</mat-option>
                <mat-option id="properties-trigger-value" [value]="triggerOptions[1]">{{
                    'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.TOPICS.PRINTJOB_FAILED' | translate
                    }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="ezp-grid ezp-grid--gutter" data-grid-cols="1">
        <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
            <mat-label id="properties-webhook-url-text">{{ 'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.WEBHOOK_URL' | translate
                }}</mat-label>
            <span>
                <input formControlName="url" matInput id="properties-webhook-url" />
            </span>
            <mat-error>
               {{ 'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.WEBHOOK_URL_ERROR' | translate }}
            </mat-error>
        </mat-form-field>
        <div class="toggle">
            <div class="toggle__control">
                <mat-slide-toggle formControlName="authentication_scheme" id="properties-toggle" color="primary"
                    class="toggle__slide-toggle"></mat-slide-toggle>
            </div>
            <div class="toggle__body">
                <div id="properties-toggle-text" class="toggle__title mat-subheading-2">
                    {{ 'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.TOGGLE' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div class="ezp-grid ezp-grid--gutter ezp-grid--spacing-vertical" data-grid-cols="2">
        <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
            <mat-label id="properties-user-name-text">{{ 'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.USER_NAME' | translate
                }}</mat-label>
            <input formControlName="basic_username" matInput id="properties-user-name">
        </mat-form-field>
        <mat-form-field class="ezp-form-field" appearance="fill">
            <mat-label id="properties-password-text">{{ 'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.PASSWORD' | translate
                }}</mat-label>
            <input formControlName="basic_password" type="password" autocomplete="on" matInput
                id="properties-password" />
        </mat-form-field>
    </div>
    <mat-divider *ngIf="dialogueData.webhook"></mat-divider>
    <div class="ezp-grid ezp-grid--gutter-horizontal ezp-grid--spacing-top" data-grid-cols="2"
        *ngIf="dialogueData.webhook">
        <div class="webhook-id">
            <div class="webhook-id__item">
                <span class="ezp-text ezp-text--medium-emphasis mat-caption" id="user-webhook-id-text"><strong>{{
                        'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.WEBHOOK_ID' | translate }} </strong></span>
                <span class="ezp-text ezp-text--medium-emphasis mat-caption" [id]="dialogueData.webhook.endpoint_id">
                    {{dialogueData.webhook.endpoint_id}}</span>
                <span class="webhook-id__copy ezp-text ezp-text--primary mat-caption" id="copy-webhookid"
                    (click)="copyWebhookId()"> {{ 'COPY' | translate }}</span>
            </div>
        </div>
    </div>
</form>