import { NgModule }           					from '@angular/core';
import { AddSvgComponent }              from './add-svg.component';
import { ArrowSvgComponent }            from './arrow-svg.component';
import { CrossSvgComponent }            from './cross-svg.component';
import { EditSvgComponent }             from './edit-svg.component';
import { FileSvgComponent }             from './file-svg.component';
import { FolderSvgComponent }           from './folder-svg.component';
import { LogoffSvgComponent }            from './logoff-svg.component';
import { LinuxSvgComponent }            from './linux-svg.component';
import { MagnifierSvgComponent }        from './magnifier-svg.component';
import { MoreSvgComponent }             from './more-svg.component';
import { PrinterSvgComponent }          from './printer-svg.component';
import { SettingsSvgComponent }         from './settings-svg.component';
import { ThrobberSvgComponent }         from './throbber-svg.component';
import { UserSvgComponent }             from './user-svg.component';
import { GroupsSvgComponent }            from './groups-svg.component';
import { WindowsSvgComponent }          from './windows-svg.component';
import { MacSvgComponent }              from './mac-svg.component';
import { WinUploaderSvgComponent }      from './win-uploader.component';
import { ZipUploadSvgComponent }        from './zip-upload.component';
import { ExeSvgComponent }              from './exe-svg.component';
import { MsiSvgComponent }              from './msi-svg.component';
import { MyCloudSvgComponent }          from './mycloud-svg.component';
import { CloudSvgComponent }            from './cloud-svg.component';
import { DriverLocalSvgComponent }      from './driver-local-svg.component';
import { DriversProfilesSvgComponent }  from './drivers-profiles-svg.component';
import { IpRangesSvgComponent }         from './ip-ranges-svg.component';
import { ImportCsvSvgComponent }        from './import-csv-svg.component';
import { EzeepLogoSvgComponent }        from './ezeep-logo-svg.component';

@NgModule({
  declarations: [
    AddSvgComponent,
    ArrowSvgComponent,
    CrossSvgComponent,
    EditSvgComponent,
    FileSvgComponent,
    FolderSvgComponent,
    LinuxSvgComponent,
    LogoffSvgComponent,
    MacSvgComponent,
    MagnifierSvgComponent,
    MoreSvgComponent,
    PrinterSvgComponent,
    SettingsSvgComponent,
    ThrobberSvgComponent,
    UserSvgComponent,
    GroupsSvgComponent,
    WindowsSvgComponent,
    WinUploaderSvgComponent,
    ZipUploadSvgComponent,
    ExeSvgComponent,
    MsiSvgComponent,
    MyCloudSvgComponent,
    CloudSvgComponent,
    DriverLocalSvgComponent,
    DriversProfilesSvgComponent,
    IpRangesSvgComponent,
    ImportCsvSvgComponent,
    EzeepLogoSvgComponent
  ],
  exports: [
    AddSvgComponent,
    ArrowSvgComponent,
    CrossSvgComponent,
    EditSvgComponent,
    FileSvgComponent,
    FolderSvgComponent,
    LinuxSvgComponent,
    LogoffSvgComponent,
    MacSvgComponent,
    MagnifierSvgComponent,
    MoreSvgComponent,
    PrinterSvgComponent,
    SettingsSvgComponent,
    ThrobberSvgComponent,
    UserSvgComponent,
    GroupsSvgComponent,
    WindowsSvgComponent,
    WinUploaderSvgComponent,
    ZipUploadSvgComponent,
    ExeSvgComponent,
    MsiSvgComponent,
    MyCloudSvgComponent,
    CloudSvgComponent,
    DriverLocalSvgComponent,
    DriversProfilesSvgComponent,
    IpRangesSvgComponent,
    ImportCsvSvgComponent,
    EzeepLogoSvgComponent
  ]
})
export class SvgModule {}
