<h2 mat-dialog-title id="webhook-editor-text">{{ 'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.WEBHOOK_EDITOR' | translate }}</h2>

<div mat-dialog-content>
    <mat-tab-group class="ezp-tabs" animationDuration="0ms">
        <mat-tab label="Properties">
            <ng-template mat-tab-label>
                <span id="properties-tab-text">{{ 'SETTINGS_PAGE.WEBHOOKS.TABS.PROPERTIES' | translate }}</span>
            </ng-template>
            <div class="tab-content">
                <ezd-properties (isFormValidEvent)="propertiesFormValid($event)"></ezd-properties>
            </div>
        </mat-tab>
        <!-- Disbaled until next release -->
        <mat-tab label="FailedEvents">
            <ng-template mat-tab-label>
                <span [matBadgeHidden]="eventCount == 0"  [matBadge]="eventCount" matBadgeOverlap="false" matBadgeColor="accent" id="failed-events-tab-text">{{ 'SETTINGS_PAGE.WEBHOOKS.TABS.FAILED_EVENTS' | translate }}</span>
            </ng-template>
            <div class="tab-content">
                <ezd-failed-events (eventCount)="eventCountEvent($event)"></ezd-failed-events>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
<div mat-dialog-actions>
    <button mat-button id="test-run-webhook-dialogue-details" color="primary"
        class="ezp-button mat-dialog-actions__action_left" [disabled]="!doneDisabled" (click)="openTestRunDialogue()">
        <mat-icon>play_circle_filled</mat-icon> {{ 'SETTINGS_PAGE.WEBHOOKS.TEST_RUN.TEST_RUN_BUTTON' | translate }}
    </button>
    <button mat-button id="cancel-webhook-dialogue-details" color="primary" class="ezp-button" (click)="close()">
        {{ 'CANCEL' | translate }}
    </button>
    <button mat-button id="done-webhook-dialogue-details" color="primary" class="ezp-button" (click)="done()"
        [disabled]="!doneDisabled || isLoading">
        <mat-icon [ngClass]="{ 'fa-spin': isLoading }" *ngIf="isLoading">cached</mat-icon>
        {{ 'DONE' | translate }}
    </button>

</div>