export class Util {

  public static removeEmptyKeys(obj: any): Object {
    Object.keys(obj).forEach(value => {
      if (obj[value] === '' || obj[value] === null) {
        delete obj[value];
      }
    });
    return obj;
  }

  // Compares v1 to v2: returns -1 if v2 < v1, 0 if v1 == v2 and 1 if v2 > v1.
  // Both strings must contains dot-separated numbers. The number of numbers can be variable.
  public static compareVersions(v1: string, v2: string): number {
    const toIntArray = (version: string): number[] => {
      const arr: number[] = [];
      version.split('.').forEach(s => arr.push(parseInt(s)));
      return arr;
    };
    const compare = (a: number[], b: number[], i = 0): number => {
      if (i >= a.length) return 1;
      if (i >= b.length) return -1;
      if (a[i] === b[i]) return compare(a, b, i + 1);
      return a[i] > b[i] ? -1 : 1;
    };
    return compare(toIntArray(v1), toIntArray(v2));
  }

}
