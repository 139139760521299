import { Injectable } from '@angular/core';
import { PrintersService, AssignmentsService, Printer, UserPrintingService, UserAssignmentsService, PrinterUserAssignment, GroupsService } from './printing-api.service';
import { Observable, from, BehaviorSubject, lastValueFrom } from 'rxjs';
import { map, mergeMap, last } from 'rxjs/operators';
import { UsersgroupsUsersService } from './usersgroups-users.service';

@Injectable({
  providedIn: 'root'
})
export class UsersgroupsUsersPrintersService {
  allPrintersSubject = new BehaviorSubject<any>([]);
  currentUserId: any;
  allPrinters: any[] = [];
  PAGE_SIZE: number = 100;
  PAGE_INDEX: any = 0;
  draftedAssignedPrinters: any[];
  hasDraftedAssignedPrinters: boolean = false;

  constructor(private printerService: PrintersService, 
    private assignmentService: UserAssignmentsService,
    private groupAssignmentService: AssignmentsService,
    private userPrintingService: UserPrintingService,
    private usersService: UsersgroupsUsersService,
    private groupsService: GroupsService) { }

  public getAllPrinters(searchText:string, userId:string): Observable<any> {
    this.setAllPrinters(searchText, userId, this.PAGE_SIZE, this.PAGE_INDEX);
    return this.allPrintersSubject.asObservable();
  }

  async setAllPrinters(searchText:string, userId:string, page_size: number, offset: number) {
    if(!this.currentUserId) {
      this.currentUserId = userId;
    }
    if (this.currentUserId != userId) {
      this.currentUserId = userId;
      this.hasDraftedAssignedPrinters = false
      this.allPrinters = [];
    }
    this.allPrintersSubject.next({ isLoading: true, printers: this.allPrinters });
    
    let response = await lastValueFrom(this.getPrinters(searchText, page_size, offset)) // .toPromise();
    let endOfList = !response.next;
    if(response.printers.length == 0) {
      this.allPrintersSubject.next({isLoading: false, printers: this.allPrinters, endOfList: endOfList });
      return;
    }
    
    this.allPrinters = response.printers;
    this.allPrintersSubject.next({isLoading: false, printers: this.allPrinters, endOfList: endOfList });
  }

  getPrinters(searchText: string, pageSize: number, pageIndex: number) : Observable<any> {
    return this.printerService.list(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "connector", "false", searchText, "name", pageSize, pageIndex)
    .pipe(map(response => {
      return { printers: response.result.results, next: response.result.next };
    }));
  }

  getMyPrintersList(user_id: string, page_size: number, page_index: number) : Observable<any> {
    let offset = page_index * page_size
    return this.printerService.list(undefined, undefined, undefined, undefined, undefined, user_id, undefined, undefined, "connector", "true", undefined, "name", page_size, offset)
    .pipe(map(async response => {
      let printers = []
        if(response.result.next) {
          let nextIndex = page_index + 1
          printers = await lastValueFrom(this.getMyPrintersList(user_id, page_size, nextIndex)) // .toPromise()
        }
        return [...response.result.results, ...printers];
    }));
  }

  getAssignedPrintersGroupsToUser(user_id:string) : Observable<any> {
    return this.userPrintingService.read(user_id).pipe(map(async response => {
      let assignedPrinters = []
      if(response.result.groups && response.result.groups.length > 0) {
        for (const g of response.result.groups) {
          let tempAssignedPrinters = await lastValueFrom(this.getAssignedPrintersToGroup(g.group_id, 100, 0)) // .toPromise()
          let resp_group = await lastValueFrom(this.groupsService.read(g.group_id)).catch(error => { // .toPromise()
            // group doesn't exist
          })
          tempAssignedPrinters.map(p => p.source = "Group "+resp_group['result'].name)
          assignedPrinters = [...assignedPrinters, ...tempAssignedPrinters]
        }
      }
      return assignedPrinters
    }))
  }

  getAssignedPrintersToGroup(group_id:string, page_size:number, page_index:number) : Observable<any> {
    let offset = page_index * page_size
    return this.groupAssignmentService.list(undefined, group_id, undefined, undefined, page_size, offset)
      .pipe(map(async response => {
        let printers = []
        if(response.result.next) {
          let nextIndex = page_index + 1
          printers = await lastValueFrom(this.getAssignedPrintersToGroup(group_id, page_size, nextIndex)) // .toPromise()
        }
        return [...response.result.results, ...printers];
      }));
  }

  getAssignedPrintersToUser(user_id:string, page_size:number, page_index:number) : Observable<any> {
    let offset = page_index * page_size
    return this.assignmentService.list(undefined, user_id, undefined, undefined, page_size, offset)
      .pipe(map(async response => {
        let printers = []
        if(response.result.next) {
          let nextIndex = page_index + 1
          printers = await lastValueFrom(this.getAssignedPrintersToUser(user_id, page_size, nextIndex)) // .toPromise()
        }
        return [...response.result.results, ...printers];
      }));
  }

  async draftAssignedPrinters(currentAssignedPrinters: any[], newAssignments: any) {
    let removedPrinters = currentAssignedPrinters.filter(g => newAssignments.filter(n => n.id == g.id).length == 0)
    let currentPrinters = await lastValueFrom(this.getAssignedPrintersToUser(this.currentUserId, 100, 0)) // .toPromise()
    newAssignments = newAssignments.filter(nu => currentPrinters.filter(cp => cp.printer_id == nu.id).length == 0)
    
    this.draftedAssignedPrinters = currentPrinters.filter(cp => removedPrinters.filter(rp => rp.id == cp.printer_id).length == 0)
      .map(cp => { return {id:cp.printer_id}})
    this.draftedAssignedPrinters = [...this.draftedAssignedPrinters, ...newAssignments]
    this.hasDraftedAssignedPrinters = true
  }

  async saveDraftedAssignedPrinters() {
    if(!this.draftedAssignedPrinters) return
    this.hasDraftedAssignedPrinters = false
    let userToEdit = this.usersService.getUserToEdit()
    let responseUser = [{ user_id: userToEdit.id}]
    
    let currentPrinters = await lastValueFrom(this.getAssignedPrintersToUser(this.currentUserId, 100, 0)) // .toPromise()
    let removedPrinters = currentPrinters.filter(cp => this.draftedAssignedPrinters.filter(dp => cp.printer_id == dp.id).length == 0)

    if (removedPrinters && removedPrinters.length > 0) {
      from(removedPrinters)
        .pipe(mergeMap(async (p: PrinterUserAssignment) => {
          let user = responseUser //save it before requesting the assignments
          let response: any = await lastValueFrom(this.getAssignments(p.printer_id)) // .toPromise();
          if (response.users !== undefined
            && response.users.length > 0
            && user[0] != undefined) {
            let usersFiltered = response.users.filter(au => au.user_id !== user[0].user_id)
            await lastValueFrom(this.update(usersFiltered, p.printer_id, undefined)) // .toPromise();
          }
        }))
        .pipe(last())
        .subscribe(async () => {
          return;
        });
    }
    if (this.draftedAssignedPrinters.length > 0) {
      //update users
      from(this.draftedAssignedPrinters)
        .pipe(mergeMap(async (p: Printer) => {
          let user = responseUser //save it before requesting the assignments
          let response: any = await lastValueFrom(this.getAssignments(p.id)) // .toPromise();
          user = user.filter(u => {
            if (response.users == undefined
              || response.users.length == 0
              || (u != undefined && response.users.filter(au => au.user_id == u.user_id).length == 0))
              return u;
          });
          if (user[0] == undefined)
            return;
          await lastValueFrom(this.update([...response.users, ...user], p.id, undefined)) // .toPromise();
        }))
        .pipe(last())
        .subscribe(async () => {
          return;
        });
      }
  }

  clearDraftedAssignedPrinters() {
    this.draftedAssignedPrinters = []
  }

  update(users: any[], printerId: string, printerName: string): Observable<any> {
    let assignments: any = [{ users: users }];
    let assignment = new PrinterUserAssignment({ printer_id: printerId, printer_name: printerName, assignment: assignments });
    return this.assignmentService.update(assignment, printerId).pipe(map(response => {
      return response.result;
    }));
  }

  public getAssignments(printerId: string): Observable<any> {
    return this.assignmentService.read(printerId).pipe(map(response => {
      let tempUsers = [];

      if (!response.result || !response.result.assignments || response.result.assignments.length == 0) {
        return { users: tempUsers };
      }

      let printerUsers = response.result.assignments[0].users;
      if (printerUsers && printerUsers.length > 0) {
        tempUsers = printerUsers;
      }

      return { users: tempUsers };
    }));
  }
}
