import { NgModule } from '@angular/core'
import { SettingsComponent } from './settings.component'
import { MaterialModule } from '../../modules/material/material.module'
import { CoreModule } from '../../modules/core/core.module'
import { SharedModule } from '../../modules/shared/shared.module'
import { SessionPlaceholderComponent } from './session-placeholder/session-placeholder.component';
import { SessionComponent } from './session/session.component';
import { WebhookComponent } from './webhooks/webhook/webhook.component';
import { WebhookEditorDialogueComponent } from './webhooks/webhook-editor-dialogue/webhook-editor-dialogue.component';
import { FailedEventsComponent } from './webhooks/webhook-editor-dialogue/failed-events/failed-events.component';
import { PropertiesComponent } from './webhooks/webhook-editor-dialogue/properties/properties.component';
import { TestRunDialogueComponent } from './webhooks/webhook-editor-dialogue/test-run-dialogue/test-run-dialogue.component'

@NgModule({
  declarations: [SettingsComponent, SessionPlaceholderComponent, SessionComponent, WebhookComponent, WebhookEditorDialogueComponent, FailedEventsComponent, PropertiesComponent, TestRunDialogueComponent],
  imports: [MaterialModule, CoreModule, SharedModule],
})
export class SettingsModule {}
