import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private orgNameSubject$ = new BehaviorSubject<any>('')
  getUpdatedOrgName$ = this.orgNameSubject$.asObservable()

  setOrgName(data: any) {
    this.orgNameSubject$.next(data)
  }
}
