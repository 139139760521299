import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { RequesterApiService, ApiResponse, ApiResult, ListResult, subscriptionApiUrl } from './requester-api.service';

export interface ISubscriptionApiService {
  read(ordering: string | null | undefined): Observable<ApiResponse<SubscriptionStatus>>;
}

@Injectable({
  providedIn: 'root'
})
export class SubscriptionApiService extends RequesterApiService implements ISubscriptionApiService {

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(subscriptionApiUrl) baseUrl?: string) {
    super(http, baseUrl ? baseUrl : "/management/subscribing");
  }

  read(ordering: string | null | undefined): Observable<ApiResponse<SubscriptionStatus>> {
    let url_ = this.baseUrl + "/v1/subscriptions/?";
    if (ordering !== undefined)
      url_ += "ordering=" + encodeURIComponent("" + ordering) + "&";

    url_ = url_.replace(/[?&]$/, "");
    
    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };
    return this.processRequest("get", url_, options_, SubscriptionStatus);
  }
}

export class SubscriptionStatus extends ApiResult implements ISubscriptionStatus {
  result: { 
    subscription_id: string; 
    created: string; 
    updated?: string; 
    source_type?: string; 
    source? : { subscription_id: string | undefined, ezeep_link: string | undefined, plan_id: string | undefined, subscription: { id:string | undefined, plan_quantity: number | undefined, currency_code: string | undefined, next_billing_at: number | undefined }, customer: { id:string | undefined, cf_billing_admin_id: string | undefined }} | undefined;
    status?: string; 
    expires?: string; 
    deletion?: string; 
    features? : { max_users: number | undefined, my_printers: boolean | undefined, print_now: boolean | undefined, pull_printing: boolean | undefined, azure_ad_connect: boolean | undefined, print_later: boolean | undefined, hub_claiming: boolean | undefined, domain_assignments: boolean | undefined, dashboard: boolean | undefined, report_download: boolean | undefined, groups_printer_assignments : boolean | undefined, webhooks: boolean | undefined} | undefined ;
  }[];

  constructor(data?: ISubscriptionStatus) {
    super();
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    } else {
      this.result = [];
    }
  }
  

  init(data?: any) {
    if (data) {
      if (Array.isArray(data)) {
        for (let item of data)
          this.result.push({
            subscription_id: item["subscription_id"],
            created: item["created"],
            updated: item["updated"],
            source_type: item["source_type"],
            source: {
              subscription_id: item["source"] ? item["source"]["subscription_id"] : undefined,
              ezeep_link: item["source"] ? item["source"]["ezeep_link"] : undefined,
              plan_id: item["source"] ? item["source"]["plan_id"] : undefined,
              subscription: {
                id: item["source"] && item["source"]["subscription"] ? item["source"]["subscription"]["id"] : undefined,
                plan_quantity: item["source"] && item["source"]["subscription"] ? item["source"]["subscription"]["plan_quantity"] : undefined,
                currency_code: item["source"] && item["source"]["subscription"] ? item["source"]["subscription"]["currency_code"] : undefined,
                next_billing_at: item["source"] && item["source"]["subscription"] ? item["source"]["subscription"]["next_billing_at"] : undefined
              },
              customer: {
                id: item["source"] && item["source"]["customer"] ? item["source"]["customer"]["id"] : undefined,
                cf_billing_admin_id: item["source"] && item["source"]["customer"] ? item["source"]["customer"]["cf_billing_admin_id"] : undefined
              }
            },
            status: item["status"],
            expires: item["expires"],
            deletion: item["deletion"],
            features: {
              max_users: item["features"] ? item["features"]["max_users"] : undefined,
              my_printers: item["features"] ? item["features"]["my_printers"] : undefined,
              print_now: item["features"] ? item["features"]["print_now"] : undefined,
              pull_printing: item["features"] ? item["features"]["pull_printing"] : undefined,
              azure_ad_connect: item["features"] ? item["features"]["azure_ad_connect"] : undefined,
              print_later: item["features"] ? item["features"]["print_later"] : undefined,
              hub_claiming: item["features"] ? item["features"]["hub_claiming"] : undefined,
              domain_assignments: item["features"] ? item["features"]["domain_assignments"] : undefined,
              dashboard: item["features"] ? item["features"]["dashboard"] : undefined,
              report_download: item["features"] ? item["features"]["report_download"] : undefined,
              groups_printer_assignments : item["features"] ? item["features"]["groups_printer_assignments"] : undefined,
              webhooks : item["features"] ? item["features"]["webhooks"] : undefined
            }
          });
      }
    }
  }

  fromJS(data: any): SubscriptionStatus {
    data = typeof data === 'object' ? data : {};
    let result = new SubscriptionStatus();
    result.init(data);
    return result;
  }
}

export interface ISubscriptionStatus {
  result: { 
    subscription_id: string | undefined
    created: string | undefined
    updated?: string | undefined
    source_type?: string | undefined
    source? : { subscription_id: string | undefined, ezeep_link: string | undefined, plan_id: string | undefined, subscription: { id:string | undefined, plan_quantity: number | undefined, currency_code: string | undefined, next_billing_at: number | undefined }, customer: { id:string | undefined, cf_billing_admin_id: string | undefined }} | undefined
    status?: string | undefined
    expires?: string | undefined
    deletion?: string | undefined
    features? : { max_users: number | undefined, my_printers: boolean | undefined, print_now: boolean | undefined, pull_printing: boolean | undefined, azure_ad_connect: boolean | undefined, print_later: boolean | undefined, hub_claiming: boolean | undefined , domain_assignments: boolean | undefined, dashboard: boolean | undefined, report_download: boolean | undefined, webhooks: boolean | undefined} | undefined
  }[]
}