<div class="ezp-layout__page">
  <div class="ezp-layout__content">
    <h1 class="mat-headline" id="printers-title-text">{{ 'PRINTERS.TITLE' | translate }}</h1>
    <mat-tab-group
      #tabs
      class="ezp-tabs"
      [selectedIndex]="currentTabIndex"
      animationDuration="0ms"
      dynamicHeight
    >
      <mat-tab label="myprinters">
        <ng-template mat-tab-label>
          <span id="my-printers-view-label-text">
            <mat-icon id="my_printers-view-label-icon">print</mat-icon>
            {{ 'PRINTERS.TABS.MY_PRINTERS' | translate }}
          </span>
        </ng-template>
        <div class="ezp-layout__part">
          <ezd--printers-myprinters (showDialog)="showDialog = $event"></ezd--printers-myprinters>
        </div>
      </mat-tab>
      <mat-tab label="networkprinters" *ngIf="isAdmin">
        <ng-template mat-tab-label>
          <span id="printers-view-label-text">
            <mat-icon id="network_printer-view-label-icon" svgIcon="network_printer"></mat-icon>
            {{ 'PRINTERS.TABS.NETWORK_PRINTERS' | translate }}
          </span>
        </ng-template>
        <div class="ezp-layout__part">
          <ezd-networknetworkprinters-printers
            (showDialog)="showDialog = $event"
            (tabChanged)="currentTabIndex = $event"
          ></ezd-networknetworkprinters-printers>
        </div>
      </mat-tab>
      <mat-tab label="printerprofile" *ngIf="isAdmin">
        <ng-template mat-tab-label>
          <span id="profiles-view-label-text">
            <mat-icon id="profiles-view-label-icon">layers</mat-icon>
            {{ 'PRINTERS.TABS.PRINTER_PROFILE' | translate }}
          </span>
        </ng-template>
        <div class="ezp-layout__part">
          <ezd-printer-profile></ezd-printer-profile>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
