<h2 mat-dialog-title id="new-password-text">New Password</h2>
<div mat-dialog-content id="new-password">
  {{password}}
</div>
<div mat-dialog-actions>
    <button mat-button id="copy-password" (click)="copy()" color="primary" class="ezp-button">
      Copy
    </button>
    <button mat-button id="cancel-password" (click)="close()" color="primary" class="ezp-button">
      Close
    </button>
</div>