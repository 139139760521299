

import { Injectable }                                       from '@angular/core';
import { ActivatedRouteSnapshot, Router }      from '@angular/router';
import { Observable ,  of }                                       from 'rxjs';

@Injectable()
export class CanActivatePrinterTabGuard  {

  constructor(
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (route.data['availableForMultiplePrinters']) {
      return true;
    } else {
      return false;
    }
  }
}
