import { AbstractControl, ValidatorFn } from '@angular/forms';



export function urlValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any; } | null => {
    const urlPattern: RegExp = /^(https):\/\/[^ "]+$/;

    if (!control.value || urlPattern.test(control.value)) {
      return null; // URL is valid
    } else {
      return { invalidUrl: true }; // URL is invalid
    }
  };
}
