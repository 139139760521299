<div *ngIf="!showMessageNoPrinters" class="ezp-layout__head grid-container">
  <div class="center">
    <ezd-printers-search></ezd-printers-search>
  </div>
  <ng-container *ngFor="let filter of networkPrintersPrintersFilters">
    <mat-form-field
      *ngIf="filter.field_name.toLowerCase() != 'connector'"
      class="ezp-form-field ezp-form-field--sidebar select-field custom-mat-select"
      appearance="outline"
      floatLabel="always"
      [id]="filter.field_name.toLowerCase() + '-filter-panel'"
    >
      <mat-label>
        {{
          'PRINTERS.NETWORK_PRINTERS.PRINTERS_FILTERS.' +
            filter.field_name.split(' ').join('_').toUpperCase() | translate
        }}
      </mat-label>
      <mat-select
        [formControl]="getFormControl(filter)"
        multiple
        placeholder="{{ 'PRINTERS.NETWORK_PRINTERS.PRINTERS_FILTERS.NONE' | translate }}"
      >
        <mat-select-trigger>
          {{
            (getSelectedFilterCountForFilterCategory(filter.field_name) > 0
              ? 'PRINTERS.NETWORK_PRINTERS.PRINTERS_FILTERS.SELECTED'
              : 'PRINTERS.NETWORK_PRINTERS.PRINTERS_FILTERS.NONE'
            ) | translate : { value: getSelectedFilterCountForFilterCategory(filter.field_name) }
          }}
        </mat-select-trigger>
        <mat-option
          [id]="
            filter.field_name.toLowerCase() +
            '-filter-' +
            filterName.toLowerCase().replaceAll(' ', '-')
          "
          *ngFor="let filterName of filter.values"
          [value]="filterName"
          >{{ filterName | translate }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </ng-container>
</div>
<div class="selection" *ngIf="selectedPrintersFilters.length">
  <span class="selected-filters">{{
    'PRINTERS.NETWORK_PRINTERS.SELECTED_FILTERS' | translate
  }}</span>
  <div class="panel">
    <mat-chip-list>
      <ng-container *ngFor="let filterItem of selectedPrintersFilters">
        <mat-chip
          *ngIf="filterItem.field_name.toLowerCase() == 'location'"
          [removable]="removable"
          class="location-filter"
        >
          <img
            alt="location-filter"
            matPrefix
            class="mat-icon"
            src="/assets/images/location-filter.png"
            class="filter-icon"
          />
          <span class="filters__text" [id]="filterItem.name">
            {{ filterItem.name | translate }}
          </span>
          <img
            matPrefix
            alt="remove-location-filter"
            class="mat-icon remove-filter"
            src="/assets/images/remove-location-filter.png"
            (click)="selectFilter(false, filterItem, filterItem.name)"
          />
        </mat-chip>
        <mat-chip
          *ngIf="filterItem.field_name.toLowerCase() == 'model_name'"
          [removable]="removable"
          class="model-filter"
        >
          <img
            alt="model-filter"
            matPrefix
            class="mat-icon"
            src="/assets/images/model-filter.png"
            class="filter-icon"
          />
          <span class="filters__text" [id]="filterItem.name">
            {{ filterItem.name | translate }}
          </span>
          <img
            matPrefix
            alt="remove-model-filter"
            class="mat-icon remove-filter"
            src="/assets/images/remove-model-filter.png"
            (click)="selectFilter(false, filterItem, filterItem.name)"
          />
        </mat-chip>
        <mat-chip
          *ngIf="filterItem.field_name.toLowerCase() == 'connector'"
          [removable]="removable"
          class="connector-filter"
        >
          <img
            alt="connector-filter"
            matPrefix
            class="mat-icon"
            src="/assets/images/connector-filter.png"
            class="filter-icon"
          />
          <span class="filters__text" [id]="filterItem.name">
            {{ filterItem.name | translate }}
          </span>
          <img
            matPrefix
            alt="remove-connector-filter"
            class="mat-icon remove-filter"
            src="/assets/images/remove-connector-filter.png"
            (click)="selectFilter(false, filterItem, filterItem.name)"
          />
        </mat-chip>
      </ng-container>
      <mat-chip
        *ngIf="selectedPrintersFilters.length > 1"
        [removable]="removable"
        class="clear-all"
      >
        <span class="filters__text">
          {{ 'CLEAR_ALL' | translate }}
        </span>
        <img
          matPrefix
          alt="remove-filters"
          class="mat-icon remove-filter"
          src="/assets/images/remove-filters.png"
          (click)="clearFilters()"
        />
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
<div class="ezp-layout__part" *ngIf="showMessageNotFound && !isLoading">
  <div class="ezp-note">
    <div class="ezp-note__icon">
      <mat-icon matPrefix>warning</mat-icon>
    </div>
    <div class="ezp-note__text">
      <p class="ezp-text ezp-text--high-emphasis mat-subheading-2" id="no-printers-found">
        <strong>
          {{ 'PRINTERS.NETWORK_PRINTERS.NO_RESULTS_FOUND' | translate }}
        </strong>
      </p>
    </div>
  </div>
</div>

<ezd-table-placeholder
  *ngIf="showMessageNoPrinters && !isLoading"
  [columns]="['Name', 'Model']"
  [rows]="8"
  [action]="{ title: 'MAIN_NAVIGATION.DOWNLOADS' | translate, path: 'downloads' }"
  [heading]="'PRINTERS.NETWORK_PRINTERS.NO_PRINTERS_TEXT1' | translate"
  [description]="'PRINTERS.NETWORK_PRINTERS.NO_PRINTERS_TEXT2' | translate"
></ezd-table-placeholder>
<div class="ezp-layout__part" *ngIf="!showMessageNoPrinters && !showMessageNotFound">
  <div class="ezp-table">
    <table
      class="ezp-table__data"
      mat-table
      [dataSource]="networkPrintersPrintersTableDataSource"
      matSort
      (matSortChange)="sortChange($event)"
      matSortDisableClear="true"
      *ngIf="!isLoading && !showMessageNoPrinters"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--select">
          <mat-checkbox
            id="select-all-printers"
            [indeterminate]="
              selectedNetworkPrintersPrintersCount > 0 &&
              selectedNetworkPrintersPrintersCount <
                networkPrintersPrintersTableDataSource.data.length
            "
            [checked]="
              selectedNetworkPrintersPrintersCount ===
              networkPrintersPrintersTableDataSource.data.length
            "
            [disabled]="printersPropertiesHasChanges"
            (change)="selectAllVisiblePrinters($event)"
            color="primary"
          ></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let printer" class="ezp-table__cell ezp-table__cell--select">
          <mat-checkbox
            [id]="printer.id"
            (change)="selectPrinter($event, printer)"
            [checked]="printer.isSelected"
            [(ngModel)]="printer.isSelected"
            color="primary"
          ></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="ezp-table__cell ezp-table__cell--text"
          id="header-printer-name"
        >
          {{ 'PRINTERS.NETWORK_PRINTERS.PRINTERS_TABLE_HEADERS.NAME' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let printer"
          class="ezp-table__cell ezp-table__cell--text"
          id="{{ printer.id + '-name' }}"
        >
          {{ printer.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="model">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="ezp-table__cell ezp-table__cell--text"
          id="header-printer-model"
        >
          {{ 'PRINTERS.NETWORK_PRINTERS.PRINTERS_TABLE_HEADERS.MODEL' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let printer"
          class="ezp-table__cell ezp-table__cell--text"
          id="{{ printer.id + '-model' }}"
        >
          {{ printer.model_name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="connector">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="ezp-table__cell ezp-table__cell--text"
          id="header-printer-connector"
        >
          {{ 'PRINTERS.NETWORK_PRINTERS.PRINTERS_TABLE_HEADERS.CONNECTOR' | translate }}
        </th>
        <td
          mat-cell
          *matCellDef="let printer"
          class="ezp-table__cell ezp-table__cell--text"
          id="{{ printer.id + '-connector' }}"
        >
          {{ printer.connectorName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--select">
          <button
            mat-icon-button
            id="printer-editor-menu"
            class="ezp-dialog__controls__action"
            [matMenuTriggerFor]="printerEditorMenu"
            [disabled]="selectedNetworkPrintersPrintersCount <= 1"
            (click)="openPrinterEditorDialog(selectedPrinter)"
          >
            <img src="../../../../assets/images/icons/edit_multiple.svg" />
          </button>
        </th>
        <td
          mat-cell
          *matCellDef="let printer"
          class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon"
        >
          <button
            mat-icon-button
            id="open-printer-editor"
            (click)="openPrinterEditorDialog(printer)"
            [disabled]="selectedNetworkPrintersPrintersCount >= 2"
          >
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnDefs"></tr>
      <tr mat-row *matRowDef="let printer; columns: columnDefs"></tr>
    </table>
    <mat-spinner
      *ngIf="isLoading"
      class="ezp-spinner ezp-spinner--large ezp-spinner--table"
    ></mat-spinner>
    <mat-paginator
      *ngIf="!isLoading && !showMessageNoPrinters"
      class="efa-paginator"
      id="printers-paginator"
      #paginator
      [length]="maxPageLength"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="pageSizeOptions"
      (page)="onPaginationChange($event)"
    >
    </mat-paginator>
  </div>
</div>

<ezd-alert-changing-properties (dialogResult)="processDialogResult($event)" *ngIf="showDialogAlert">
</ezd-alert-changing-properties>
