import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Component, HostListener, Inject, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar'
import { ConnectorService } from '../../../modules/shared/services/connector.service'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { printerApiUrl } from '../../../modules/shared/services/requester-api.service'

@Component({
  selector: 'ezd-claim-hub-dialog',
  templateUrl: './claim-hub-dialog.component.html',
  styleUrls: ['./claim-hub-dialog.component.scss'],
})
export class ClaimHubDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ClaimHubDialogComponent>,
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private connectorService: ConnectorService,
    @Inject(printerApiUrl) private baseUrl: string
  ) {}

  @HostListener('window:keyup.Enter', ['$event'])
  onDialogClick(event: KeyboardEvent): void {
    this.onClick()
  }

  serialString: string
  hubPassword: string
  errorState: { [key: string]: boolean } = {}
  isLoading: boolean
  success: boolean
  auth_url: string
  polling_url: string

  ngOnInit(): void {}

  close() {
    this.dialogRef.close()
  }

  onClick() {
    this.resetErrorState()
    this.isLoading = true
    this.claimHub()
  }

  claimHub() {
    this.connectorService.hubClaimAndConnect(this.serialString, this.hubPassword).subscribe(
      (response) => {
        this.isLoading = false
        if (Object.keys(response).length === 0) {
          this.dialogRef.close()
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        } else {
          this.success = true
          this.dialogRef.close(this.success)
          // this.snackbar.open('Hub claimed successfully.', '', { duration: 2000 })

          // Simply navigate to the auth_url in the current window
          this.auth_url = response.auth_url
          if (this.auth_url) {
            window.location.href = this.auth_url
          }
        }
      },
      (error) => {
        this.isLoading = false
        this.handleError(error)
      }
    )
  }

  private resetErrorState() {
    this.errorState = {
      showError: false,
      show400Error: false,
      show402Error: false,
      show403Error: false,
      show404Error: false,
      show409Error: false,
      show412Error: false,
    }
  }

  private handleError = (error: HttpErrorResponse): Observable<never> => {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message)
    } else {
      switch (error.status) {
        case 400:
          this.errorState.show400Error = true
          break
        case 402:
          this.errorState.show402Error = true
          break
        case 403:
          this.errorState.show403Error = true
          break
        case 404:
          this.errorState.show404Error = true
          break
        case 409:
          this.errorState.show409Error = true
          break
        case 412:
          this.errorState.show412Error = true
          break
        default:
          this.errorState.showError = true
      }
    }
    return throwError('Something bad happened; please try again later.')
  }
}
