import { Component, OnInit } from '@angular/core'
import { map } from 'rxjs/operators';
import { CHARGEBEE_SITE_NAME } from '../../../app.config';
import { EzpChargebeeApiService, PortalResult } from '../../../modules/shared/services/ezp-chargebee-api.service';
import { ApiResponse } from '../../../modules/shared/services/requester-api.service';
import { SubscriptionService } from '../../../modules/shared/services/subscription.service';
import { SubscriptionApiService } from '../../../modules/shared/services/subscription-api.service';

@Component({
  selector: 'ezd-subscription-active',
  templateUrl: './subscription-active.component.html',
  styleUrls: ['./subscription-active.component.scss'],
})
export class SubscriptionActiveComponent implements OnInit {
  cbInstance: any;
  customerId: any;
  subscriptionStatus: any;
  nextBillingDate: any;
  isNextBillingDatePresent: any;
  sourceType: string;
  constructor(
    private chargebeeApiService: EzpChargebeeApiService, 
    private subscriptionService: SubscriptionService,
    private subscriptionApiService: SubscriptionApiService) { 
  }

  ngOnInit(): void {
    this.subscriptionApiService.read("-created").subscribe(response => {
      //WARNING! last created subscription is the one to be verified
      this.subscriptionStatus = response.result.result[0];
      this.isNextBillingDatePresent = this.subscriptionStatus.source.subscription.next_billing_at;
      this.sourceType = this.subscriptionStatus.source_type;
      this.nextBillingDate = new Date(this.subscriptionStatus.source.subscription.next_billing_at * 1000).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
      if (!this.subscriptionStatus) return;
    });
    this.cbInstance = window['Chargebee'].init({
      site: CHARGEBEE_SITE_NAME
    });
    this.cbInstance = window['Chargebee'].getInstance()
    this.subscriptionService.getSubscription().subscribe(async response => {
      this.customerId = response.source.customer.id
      // this.chargebeeApiService.portal(customerId).pipe(map(response => response.result)) // .toPromise(); 
      
    })
  }

  openChargebeePortal() {
    this.cbInstance.setPortalSession(() => this.chargebeePortal());
    let cbPortal = this.cbInstance.createChargebeePortal();
    cbPortal.open({
      loaded: function () {
        // Optional
        // called when chargebee portal is loaded
      },
      close: this.cbInstanceLogout(),
      visit: function(sectionName) {
        // Optional
        // called whenever the customer navigates across different sections in portal
      },
      paymentSourceAdd: function() {
        // Optional
        // called whenever a new payment source is added in portal
      },
      paymentSourceUpdate: function() {
        // Optional
        // called whenever a payment source is updated in portal
      },
      paymentSourceRemove: function() {
        // Optional
        // called whenever a payment source is removed in portal.
      },
      subscriptionChanged: function(data) {
        // Optional
        // called whenever a subscription is changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionCustomFieldsChanged: function(data) {
        // Optional
        // called whenever a subscription custom fields are changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionCancelled: function(data) {
        // Optional
        // called when a subscription is cancelled
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionPaused: function(data) {
        // Optional
        // called when a subscription is Paused.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionResumed: function(data) {
        // Optional
        // called when a paused subscription is resumed.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      scheduledPauseRemoved: function(data) {
        // Optional
        // called when the schedule to pause a subscription is removed for that subscription.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionReactivated: function(data) {
        // Optional
        // called when a cancelled subscription is reactivated.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      }
    });
  }

  chargebeePortal() {
    return this.chargebeeApiService.portal(this.customerId).pipe(map(response => {
      return response.result
    })).toPromise();
  }

  cbInstanceLogout() {
    this.cbInstance.logout()
  }
}
