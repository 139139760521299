import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'ezd-subscription-unavailable',
  templateUrl: './subscription-unavailable.component.html',
  styleUrls: ['./subscription-unavailable.component.scss'],
})
export class SubscriptionUnavailableComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
