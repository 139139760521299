import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { LocalPrinterSupportService } from '../../../modules/shared/services/local-printer-support.service';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '../../../app.config';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { Subscriber, Subscription, lastValueFrom } from 'rxjs';

@Component({
  selector: 'ezd-localprintersupport-add-groups',
  templateUrl: './add-groups.component.html',
  styleUrls: ['./add-groups.component.scss']
})
export class LocalPrinterSupportAddGroupsComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  isGroupSelected = false;
  printersGroups = [];
  selectedPrintersGroups = [];
  groups: any = [];
  adconGroupsSubject = [];
  selectedGroups = [];
  maxPageLength: number;
  defaultPageSize = DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] =  DEFAULT_PAGE_SIZE_OPTIONS;
  pageIndex: number;
  pageSize: number;
  isLoading = true;

  @Output() public hideAddGroupsView: EventEmitter<void> = new EventEmitter<void>();
  groupsFiltered: any;
  localPrinterSupportServiceSubscription: Subscription;

  constructor(private localPrinterSupportService: LocalPrinterSupportService) {}

  ngOnInit() {
    this.isLoading = true;
    this.localPrinterSupportServiceSubscription = this.localPrinterSupportService.getAllEzeepGroups(100, 0)
      .subscribe(async result => {
        // if(result.isLoading) return
        // this.maxPageLength = result.count;
        // let adconGroups = result.groups;
        // if (adconGroups && adconGroups.length > 0) {
        //   adconGroups.forEach(element => {
        //     this.groups.push({ id: element.id, name: element.displayName, source: 'AZURE' });
        //   });
        // }
        let ezeepGroups = await result
        if (ezeepGroups && ezeepGroups.length > 0) {
          ezeepGroups.forEach(element => {
            element.source = 'EZEEP'
            this.groups.push(element);
          });
        }

        this.groupsFiltered = this.groups;
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.localPrinterSupportServiceSubscription.unsubscribe()
  }

  hideAddGroups() {
    this.hideAddGroupsView.emit();
  }

  selectGroups(feature, group) {
    if (feature.checked) {
      this.selectedGroups.push(group);
    } else {
      this.selectedGroups.forEach( item => {
        const index: number = this.selectedGroups.indexOf(item);
        if (item.name === group.name) {
          this.selectedGroups.splice(index, 1);
        }
      });
    }
    this.isGroupSelected = this.selectedGroups.length > 0;
  }

  addGroups() {
    this.localPrinterSupportService.addPrintersServiceGroups(this.selectedGroups)
      .subscribe(result => {
        if (result.isLoading === true) {
          this.hideAddGroups();
        } else {
          this.isLoading = true;
        }
    });
  }

  public onPaginationChange(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
  }

  searchText($event) {
    this.groupsFiltered = this.groups.filter(m => m.name.toLowerCase().includes($event.target.value.toLowerCase()));
  }
}
