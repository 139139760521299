import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of as observableOf, from as observableFrom, Observable, BehaviorSubject, Subscription, merge, EMPTY, forkJoin, from, lastValueFrom } from 'rxjs';
import { map, last, mergeMap, concatMap, toArray, catchError, flatMap } from 'rxjs/operators';
import { PrinterUserAssignment, UserAssignmentsService, UserPrintingService } from './printing-api.service';
import { NetworkPrintersAssignmentsService } from './network-printers-assignments.service';
import { GeneralUtil } from '../utils/general-util';
import { AuthUserApiService, AuthOrganizationApiService } from './auth-api.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkPrintersUsersService {
  users = [];
  assignedUsers = [];
  allUsersSubject = new BehaviorSubject<any>([]);
  allUsers: any[] = [];
  PAGE_SIZE = 100;
  PAGE_INDEX = 0;
  draftedAssignedGroups = undefined;
  printingUsers = new BehaviorSubject<any>([]);
  assignmentAdded = new BehaviorSubject<boolean>(false);
  assignmentRemoved = new BehaviorSubject<boolean>(false);
  userAssignmentRemoved = new BehaviorSubject<boolean>(false);

  constructor(
    private networkPrintersAssignmentsService: NetworkPrintersAssignmentsService,
    private usersService: UserPrintingService,
    private authUserService: AuthUserApiService,
    private authOrganizationService: AuthOrganizationApiService,
    private assignmentService: UserAssignmentsService
  ) { }

  public getAllUpdatedUsers(page_size:number, page_index:number) : Observable<any> {
    let offset = page_index * page_size
    return this.usersService.list(undefined, undefined, undefined, undefined, undefined, page_size, offset)
      .pipe(map(async response => {
        let users = []
        if(response.result.next) {
          let nextIndex = page_index + 1
          users = await lastValueFrom(this.getAllUpdatedUsers(page_size, nextIndex)) // .toPromise()
        }
        return [...response.result.results, ...users];
      }));
  }

  public getAllUsers(searchText: string): Observable<any> {
    this.setAllUsers(searchText, this.PAGE_SIZE, this.PAGE_INDEX).subscribe();
    return this.allUsersSubject.asObservable();
  }

  setAllUsers(searchText: string, page_size: number, offset: number) {
    this.allUsersSubject.next({ isLoading: true, users: this.allUsers });

    return this.getUsers(searchText, page_size, offset).pipe(map(response => {
      let endOfList = !response.next;
      this.allUsers = response.users;
      this.allUsersSubject.next({isLoading: false, users: this.allUsers, endOfList: endOfList });
    }))

  }
  getUsers(searchText: string, pageSize: number, pageIndex: number): Observable<any> {
    return this.authUserService.list(searchText, 'display_name', pageSize, pageIndex)
    .pipe(map(response => {
      return { users: response.result.results, next: response.result.next };
    }));
  }

  public getAssignedUsersByPrintersId(printersId: string[]) : Observable<any> {
    return this.networkPrintersAssignmentsService.getPrintersUserAssignments(printersId).pipe(map(result => {
      let users = result[0] 
        && result[0].assignments[0] 
        && result[0].assignments[0].users
        && result[0].assignments[0].users.length > 0 
          ? result[0].assignments[0].users 
          : [];

      result.forEach(r => {
        let usersToRemove = [];

        users.forEach(u => {
          if (r.assignments == undefined
            || r.assignments.length == 0
            || r.assignments[0].users == undefined
            || r.assignments[0].users.length == 0
            || r.assignments[0].users.filter(x => x.user_id == u.user_id).length == 0) {
              usersToRemove.push(u);
          }
        });

        usersToRemove.forEach(u => {
          let index = users.indexOf(u);

          if (index >= 0) {
            users.splice(index, 1);
          }
        })
      });

      return users;
    }));
  }

  private updateUserAssignments(addedUsers: any[], removedUsers: any[], printersSelected: any[]): void {
    from(printersSelected).pipe(
      mergeMap(p => {
        return this.networkPrintersAssignmentsService.getUserAssignments(p).pipe(
          map((assignments) => {
            const finalUsers = this.getFinalUsers(assignments, addedUsers, removedUsers)
            return this.networkPrintersAssignmentsService.updatePrinterUserAssignment(finalUsers, p, undefined);
          })
        );
      }),
      concatMap(response => response),
      toArray()
    ).subscribe({
      next: (responses) => {
        GeneralUtil.sleep(1000);
        this.assignmentAdded.next(true);
      },
      error: error => console.error('Error updating group assignments:', error),
    });
  }


  addremoveUsers(addedUsers: any[], removedUsers: string[], printersSelected: any[]): Observable<boolean> {
    this.assignmentAdded.next(false);
    this.updateUserAssignments(addedUsers, removedUsers, printersSelected);
    return this.assignmentAdded.asObservable();
  }

 

  getFinalUsers(assignments, addedUsers, removedUsers) {
    const tempCombinedaddedUsers = [...assignments.users, ...addedUsers].reduce((acc, curr) => {
      if (!acc[curr.user_id]) {
        acc[curr.user_id] = curr;
      }
      return acc;
    }, {});
    const combinedaddedUsers: any[] = Object.values(tempCombinedaddedUsers);
    return combinedaddedUsers.filter((obj1) => !removedUsers.some((obj2) => obj2.user_id === obj1.user_id));
  }
  
  updatePrinterUserAssignment(users: any[], printerId: string): Observable<any> {
    let ezeepUsers = users.map(u => {
      return { user_id: u.id, force_chosen: false };
    });
    let assignments: any = [{ users: ezeepUsers }];
    let assignment = new PrinterUserAssignment({assignment: assignments, printer_id: printerId });
    return this.assignmentService.update(assignment, printerId).pipe(map(response => {
      return response.result;
    }));
  }

  removeUser(printersSelected: any[], user: any) {
    this.userAssignmentRemoved.next(false);
    observableFrom(printersSelected)
      .pipe(mergeMap(async p => {
        let assigment = await lastValueFrom(this.networkPrintersAssignmentsService.getUserAssignments(p.id)) // .toPromise();
        assigment.users = assigment.users.filter(u => u.user_id != user.user_id);
        return await lastValueFrom(this.networkPrintersAssignmentsService.updatePrinterUserAssignment(assigment.users, p.id, p.name)) // .toPromise();
      }))
      .pipe(last())
      .subscribe(() => {
        this.userAssignmentRemoved.next(true);
      });

    return this.userAssignmentRemoved.asObservable();
  }
}
