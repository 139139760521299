import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, of, Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { DEFAULT_PAGE_SIZE } from '../../../app.config';
import { PrintersService, UserPrintingService, UserSettings } from './printing-api.service';

@Injectable({
  providedIn: 'root'
})
export class MyprintersService {
  printers = [];
  printersSubject = new BehaviorSubject<any>({isLoading: true, count: 0, printers: this.printers});
  printersCount = 0;
  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  sorting: any;
  searchText: string;

  constructor(
    private printersService: PrintersService,
    private userPrintingService: UserPrintingService) { }
  
  public getFilteredMyPrinters(): Observable<any> {
    return this.printersSubject.asObservable();
  }

  applyFilters(showLoading = true) {
    const searchText = this.searchText
    this.printersSubject.next({isLoading: (searchText == undefined || searchText == '') && showLoading, count: this.printersCount, printers: this.printers, pageSize: this.pageSize, pageIndex: this.pageIndex});
    
    this.printersService.list(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, "false", searchText, this.sorting, this.pageSize, this.pageIndex * this.pageSize)
      .subscribe({
        next: response => {
          if (searchText !== response.result.searchText) return //block older searches to show up
          this.printers = response.result.results;
          this.printersCount = response.result.count;
          this.printersSubject.next({
            isLoading: false,
            count: response.result.count,
            printers: response.result.results,
            pageSize: this.pageSize,
            pageIndex: this.pageIndex
          });
        }, error: error => {
          this.printersSubject.next({ isLoading: false, count: this.printersCount, printers: this.printers, pageSize: this.pageSize, pageIndex: this.pageIndex });
          //console.log(error);
        }
      });    
  }

  setSearchText(result: string) {
    this.searchText = result
    this.pageIndex = 0
  }

  setPage(pageIndex: number, pageSize: number, isInitial = false) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize ? pageSize : this.pageSize;
    if (!isInitial) {
      this.applyFilters();
    }
  }

  setSorting(sorting) {
    this.sorting = sorting;
    this.applyFilters(false);
  }

  changeFavoriteValue(checked: any, printer: any) {
    printer.is_favorite = checked
    this.printersService.update(printer, printer.id, false)
      .pipe(catchError(error => {
        if (error.status && (error.status == 401 || error.status == 404)) {
          window.location.href = "/"
          return of(null)
        } else {
          throw error
        }
      })).subscribe()
    // .toPromise()
  }

  getuserSettings() : Observable<any> {
    return this.userPrintingService.getSettings(null)
      .pipe(
        map(response => response.result),
        catchError(error => {
          if (error.status && error.status == 404)
            return of(null)
          else
            throw error
        }))
  }

  turnOffShowWelcomeOverlay(dontShowAgain: boolean | null) {
    this.userPrintingService.getSettings(null)
      .subscribe({
        next: response => {
          if (!response) return

          let userSettings = response.result
          userSettings.show_welcome_overlay = !dontShowAgain
          this.userPrintingService.updateSettings(userSettings, null).subscribe() // .toPromise()
        }, error: error => {
          console.log(error)
        }
      })
  }

  turnOffShowQuickstartOverlay(dontShowAgain: boolean | null) {
    this.userPrintingService.getSettings(null)
      .subscribe({
        next: response => {
          if (!response) return

          let userSettings = response.result
          userSettings.show_quickstart_dialog = !dontShowAgain
          this.userPrintingService.updateSettings(userSettings, null).subscribe() // .toPromise()
        }, error: error => {
          console.log(error)
        }
      })
  }
}
