import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequesterApiService, ApiResponse, ApiResult, ListResult, authApiUrl } from './requester-api.service';

export interface ITokenApiService {
  list(limit: number | null | undefined, offset: number | null | undefined): Observable<ApiResponse<ListResult<Token>>>;
  delete(id: string): Observable<ApiResponse<void>>;
}

@Injectable({
  providedIn: 'root'
})
export class TokenApiService extends RequesterApiService implements ITokenApiService {
  httpClient: any;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(authApiUrl) baseUrl?: string) {
    super(http, baseUrl);
  }

  list(limit: number | null | undefined, offset: number | null | undefined): Observable<ApiResponse<ListResult<Token>>> {
    let url_ = this.baseUrl + "/v1/tokens/?";
    if (limit !== undefined)
      url_ += "limit=" + encodeURIComponent("" + limit) + "&";
    if (offset !== undefined)
      url_ += "offset=" + encodeURIComponent("" + offset) + "&";
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };
    return this.processListRequest("get", url_, options_, Token)
  }

  delete(id: string): Observable<ApiResponse<void>> {
    let url_ = this.baseUrl + "/v1/tokens/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
      })
    };

    return this.processRequest<null>("delete", url_, options_, null);
  }

  search(data: any): Observable<ApiResponse<ApiResult>> {
    let url_ = this.baseUrl + "/v1/tokens/search/";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(data);
    console.log(content_)
    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.processRequest("post", url_, options_, null);
  }

  batchDelete(data:any): Observable<ApiResponse<ApiResult>> {
    let url_ = this.baseUrl + "/v1/tokens/search/";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(data);
    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.processRequest("delete", url_, options_, null);
  }
}


class Token extends ApiResult implements IToken {
  id: string;
  created: string;
  user_agent: string;
  last_refresh: string | null;
  // device: string | null
  client: {
    name: string;
    description: string;
  }

  constructor(data?: IToken) {
    super();
    this.id = data?.id ?? '';
    this.created = data?.created ?? '';
    this.user_agent = data?.user_agent ?? '';
    // this.device = data?.user_agent ? data.user_agent.match(/\((.*?)\)/)[1].split(';')[0] : ''
    this.last_refresh = data?.last_refresh ?? null;
    this.client = data?.client ?? { name: '', description: '' };
  }

  fromJS(data: any): Token {
    data = typeof data === 'object' ? data : {};
    let result = new Token();
    result.init(data);
    return result;
  }

  init(data?: any) {
    if (data) {
      (<any>this).id = data["id"];
      (<any>this).created = data["created"];
      (<any>this).user_agent = data["user_agent"];
      (<any>this).last_refresh = data["last_refresh"];
      (<any>this).client = data["client"];
      // (<any>this).device = data['user_agent'] ? data.user_agent.match(/\((.*?)\)/)[1].split(';')[0] : '';
    }
  }
}

export interface IToken {
  id: string;
  created: string;
  user_agent: string;
  last_refresh: string | null;
  client: {
    name: string;
    description: string;
  }
}