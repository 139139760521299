import { Injectable } from '@angular/core';
import { GroupsService, UserPrintingService, UserPrinting } from './printing-api.service';
import { AdconGroupsService, AdconGroup, AdconTenantGroups } from './adcon-api.service';
import { Observable, BehaviorSubject, from as observableFrom, lastValueFrom} from 'rxjs';
import { GeneralUtil } from '../utils/general-util';
import { mergeMap, concatMap, map, toArray, last } from 'rxjs/operators';
import { ApiResponse } from './requester-api.service';

@Injectable({
  providedIn: 'root'
})
export class UsersgroupsUsersGroupsService {
  allGroupsSubject = new BehaviorSubject<any>([]);
  allGroups: any[] = [];
  currentUserId = undefined;
  PAGE_SIZE = 25;
  PAGE_INDEX = 0;
  draftedAssignedGroups = undefined;

  constructor(
    private groupsService: GroupsService,
    private userPrintService: UserPrintingService) { }

  public getAllGroups(searchText:string, userId:string): Observable<any> {
    this.setAllGroups(searchText, userId,this.PAGE_SIZE, this.PAGE_INDEX).subscribe();
    return this.allGroupsSubject.asObservable();
  }

  

  setAllGroups(searchText: string, userId: string, page_size: number, offset: number) {

    if (!this.currentUserId) {
      this.currentUserId = userId;
    }
    if (this.currentUserId != userId) {
      this.currentUserId = userId;
      this.allGroups = [];
    }

    this.allGroupsSubject.next({ isLoading: true, groups: this.allGroups });

    return this.getGroups(searchText, page_size, offset).pipe(map(response => {
      let endOfList = !response.next;
      this.allGroups = response.groups;
      this.allGroupsSubject.next({ isLoading: false, groups: this.allGroups, endOfList: endOfList });
    }))
  }

  getGroups(searchText:string, pageSize: number, pageIndex: number) : Observable<any> {
    return this.groupsService.list(undefined, undefined, undefined, undefined, searchText, undefined, pageSize, pageIndex)
    .pipe(map(response => {
      return {groups: response.result.results, next: response.result.next };
    }));
  }

  async draftAssignedGroups(currentAssignedGroups: any[], newAssignments: any) {
    let removedGroups = currentAssignedGroups.filter(g => newAssignments.filter(n => n.id == g.id).length == 0)
    let currentUser = await lastValueFrom(this.userPrintService.read(this.currentUserId)) // .toPromise()
    newAssignments = newAssignments.filter(ng => currentUser.result.groups.filter(cg => cg.group_id == ng.id).length == 0)
    
    this.draftedAssignedGroups = currentUser.result.groups.filter(caag => removedGroups.filter(rg => rg.id == caag.group_id).length == 0)
    this.draftedAssignedGroups = [...this.draftedAssignedGroups, ...newAssignments.map(g => {return {group_id: g.id}})]
  }

  async saveDraftedAssignedGroups() {
    if(!this.draftedAssignedGroups) return;
    let currentUser = (await lastValueFrom(this.userPrintService.read(this.currentUserId))).result // .toPromise()).result
    currentUser.groups = this.draftedAssignedGroups
    await lastValueFrom(this.userPrintService.update(currentUser, currentUser.id)) // .toPromise()
  }

  clearDraftedAssignedGroups() {
    this.draftedAssignedGroups = undefined
  }
}
