import { Component, Input } from '@angular/core';

@Component({
  selector: 'ezd-throbber-svg',
  template: `
		<div [style.height.px]="size" [style.width.px]="size">
			<svg viewBox="0 0 38 38" stroke="#129CC2">
					<g fill="none" fill-rule="evenodd">
						<g transform="translate(1 1)" stroke-width="2">
							<circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
							<path d="M36 18c0-9.94-8.06-18-18-18">
									<animateTransform
										attributeName="transform"
										type="rotate"
										from="0 18 18"
										to="360 18 18"
										dur="1s"
										repeatCount="indefinite"/>
							</path>
						</g>
					</g>
			</svg>
		</div>
  `
})
export class ThrobberSvgComponent {

  @Input() size: number  = 30;

}

