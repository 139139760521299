import { Component, OnInit, ViewChild } from '@angular/core';
import { NetworkPrintersPrintersService } from '../../modules/shared/services/network-printers-printers.service';
import { MatLegacyTabChangeEvent as MatTabChangeEvent, MatLegacyTabGroup as MatTabGroup } from '@angular/material/legacy-tabs';
import { StorageUtils } from '../../modules/storage/utils/storage.utils';
import { Subscription } from 'rxjs';
import { DIALOG_RESPONSE } from '../../modules/shared/classes/dialog-result';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { ADMIN_USER, COMMON_USER } from '../../modules/storage/constants/storage.constants';
import { ConnectorService } from '../../modules/shared/services/connector.service';
import { CanComponentDeactivate } from './guards/can-deactivate-printer.guard';
import { MatTab, MatTabHeader } from '@angular/material/tabs';
@Component({
  selector: 'ezd-printers',
  templateUrl: './printers.component.html',
  styleUrls: ['./printers.component.scss']
})
export class PrintersComponent implements OnInit, CanComponentDeactivate {
  selectedNetworkPrintersPrintersCount = 0;
  selectedNetworkPrintersConnectorsCount = 0;
  currentTab = 'myprinters';
  isForceShowFilterView = false;
  showPrintersFilters: boolean = false;
  showPrintersProperties: boolean;
  showToolbar: boolean = false;
  showDrivers = false;
  showGroups = false;
  showDialog = false;
  selectedPrinter: any;
  selectedPrintersId: number[] = [];
  connectorIdToShow = '';
  currentTabIndex: number = 0;
  connectorId: any = '';
  isFirstLoad: boolean = true;
  printersPropertiesHasChanges: boolean = false;
  filteredPrintersSubject: Subscription;
  printerWatcherPropertiesSubscription: Subscription;
  connecotrWatcherPropertiesSubscription: Subscription;
  connectorIdToDelete: any;
  showUsers: boolean;
  @ViewChild('tabs', { static: false }) tabs: MatTabGroup;
  isAdmin: boolean = false;
  routerNextState: RouterStateSnapshot;
  nextTablabel = null;

  tabsEnum = {
    myprinters: 0,
    networkprinters: 1,
    printerprofile: 2
  };

  constructor(
    private networkPrintersPrintersService: NetworkPrintersPrintersService,
    public activatedRoute: ActivatedRoute, 
    private router: Router,
    private connectorsService: ConnectorService) {
    StorageUtils.set(localStorage, 'printerUnsaved', null);

    if (StorageUtils.get(sessionStorage, ADMIN_USER)) {
      this.isAdmin = true;
    }
    if (StorageUtils.get(sessionStorage, COMMON_USER)) {
      this.isAdmin = false;
    }
  }

  ngOnInit() {
    this.filteredPrintersSubject = this.networkPrintersPrintersService.getFilteredNetworkPrintersPrinters()
      .subscribe(() => {
        if (this.isFirstLoad) {
          this.isFirstLoad = false;
          this.checkView();
        }
        this.isForceShowFilterView = false;
        this.selectedNetworkPrintersPrintersCount = this.networkPrintersPrintersService.getSelectedNetworkPrintersCount();
        this.selectedPrinter = this.networkPrintersPrintersService.getSelectedNetworkPrinters()[0];
        this.selectedPrintersId = [];
        this.networkPrintersPrintersService.getSelectedNetworkPrinters().map(printer => {
          this.selectedPrintersId.push(printer.id);
        });
        if (this.currentTab == 'networkprinters') {
        this.showPrintersFilters = this.selectedNetworkPrintersPrintersCount === 0;
        this.showPrintersProperties = this.selectedNetworkPrintersPrintersCount > 0;
        }
      });

    this.printerWatcherPropertiesSubscription = this.networkPrintersPrintersService.watchPrinterPropertiesChanges()
      .subscribe(result => {
        this.printersPropertiesHasChanges = result.changed;
        if (result.changed && result.status == DIALOG_RESPONSE.CANCEL) {
          this.setCurrentTab('networkprinters');
          this.showDialog = false;
        }
      });
  }

  canDeactivate(nextState: RouterStateSnapshot) {
    this.routerNextState = nextState;
    if (this.printersPropertiesHasChanges) {
this.showDialog = true;
}
    return !this.printersPropertiesHasChanges;
  };

  ngAfterViewInit() {
    this.tabs._handleClick = this.tabClick.bind(this);
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['tab'] == 'networkprinters' || params['tab'] == 'networkprinter') {
        // this.currentTab = "networkprinters"
        this.setCurrentTab(params['tab']);
        // this.tabs.selectedIndex = 1
        if (params['connectorId']) {
          this.connectorId = params['connectorId'];
          this.connectorsService.applyFilters(this.connectorId);
        }
       
        this.checkView();
      } else {
        this.setCurrentTab('myprinters');
        // this.currentTab = "myprinters"
        // this.tabs.selectedIndex = 0
      }
    });
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.filteredPrintersSubject.unsubscribe();
    this.printerWatcherPropertiesSubscription.unsubscribe();
    // this.networkPrintersPrintersService.printers = [];
  }

  tabClick(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
    this.nextTablabel = tab.textLabel;
    if (this.printersPropertiesHasChanges) {
      this.showDialog = tab.textLabel == 'networkprinters' ? false : true;
      return false;
    }
    this.setCurrentTab(tab.textLabel);
  }

  setCurrentTab(feature: string) {
    if (this.currentTab == feature) {
 return; 
}
    this.currentTab = feature;
    this.tabs.selectedIndex = this.tabsEnum[this.currentTab]; // this.currentTab == 'myprinters' ? 0 : 1;
    this.checkView();
  }

  forceShowFilter() {
    if (this.printersPropertiesHasChanges) {
      this.showDialog = true;
      return;
    }
    this.isForceShowFilterView = true;
    this.checkView();
  }

  showConnector(connectorId) {
    if (this.printersPropertiesHasChanges) {
      this.showDialog = true;
      return;
    }
    this.connectorId = connectorId;
    this.router.navigate(['connectors'], { queryParams: { connectorId: this.connectorId }, skipLocationChange: true });
  }

  showAddGroups() {
    this.showGroups = true;
  }

  showAddUsers() {
    this.showUsers = true;
  }

  processDialogResult(result: DIALOG_RESPONSE) {
    if (this.printersPropertiesHasChanges) {
      if (result === DIALOG_RESPONSE.DISCARD) {
        if (this.routerNextState) {
this.router.navigate([this.routerNextState.url]);
} else if (this.nextTablabel){
          this.setCurrentTab(this.nextTablabel);
        }  
      }
      this.networkPrintersPrintersService.changePrinterPropertiesWatcherStatus(result == DIALOG_RESPONSE.CANCEL ? true : false, result);
    }
    this.routerNextState = null;
    this.nextTablabel = null;
    this.showDialog = false;
  }

  checkView() {
    this.showPrintersFilters = false;
    this.showPrintersProperties = false;
    switch (this.currentTab) {
      case 'networkprinters':
        this.selectedNetworkPrintersPrintersCount = this.networkPrintersPrintersService.getSelectedNetworkPrintersCount();
        this.showPrintersFilters = this.selectedNetworkPrintersPrintersCount === 0;
        this.showPrintersProperties = this.selectedNetworkPrintersPrintersCount > 0;
        if (this.isForceShowFilterView) {
          this.networkPrintersPrintersService.unselectPrinters(null);
          this.showPrintersFilters = true;
          this.showPrintersProperties = false;
        }
        this.networkPrintersPrintersService.setPage(0, undefined, true);
        if (this.currentTabIndex != 0) {
          this.currentTabIndex = 0;
          if (this.connectorId != '') {
setTimeout(() => {
              this.networkPrintersPrintersService.applyFilters(this.connectorId);
              this.connectorId = '';
            }, 2000);
} else {
            this.networkPrintersPrintersService.applyFilters(undefined);
            this.connectorId = '';
          }
        } else {
          if (this.connectorId) {
            setTimeout(() => {
              this.networkPrintersPrintersService.applyFilters(this.connectorId);
              this.connectorId = '';
            }, 2000);
          }

        }
        return;
    }
  }

  printerGroupsChanged() {
    this.showGroups = false;
    this.networkPrintersPrintersService.refreshSelectedPrintersProperties();
  }

  printerUsersChanged() {
    this.showUsers = false;
    this.networkPrintersPrintersService.refreshSelectedPrintersProperties();
  }
}
