import { Component, OnInit, Inject } from '@angular/core'
// import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { PrinterProfileService } from '../../../../modules/shared/services/printer-profile.service'
import { Observable, catchError, debounceTime, map, of, startWith, switchMap, tap } from 'rxjs'
import { RemoveProfileDialogComponent } from '../remove-profile-dialog/remove-profile-dialog.component'
import { ErrorService } from '../../../../modules/shared/services/error.service'
import { NetworkPrintersPrintersService } from '../../../../modules/shared/services/network-printers-printers.service'
import { Printer } from '../../../../modules/shared/services/printing-api.service'
import {
  PrinterCapability,
  PrinterProfile,
} from '../../../../modules/shared/services/printer-profile-api.service'
import { NetworkPrintersPrintersFiltersService } from '../../../../modules/shared/services/network-printers-printers-filters.service'

@Component({
  selector: 'ezd-profile-details-dialog',
  templateUrl: './profile-details-dialog.component.html',
  styleUrls: ['./profile-details-dialog.component.scss'],
})
export class ProfileDetailsDialogComponent implements OnInit {
  color_default_option = { name: 'DEFAULT' }
  duplex_default_option = { name: 'DEFAULT' }
  paper_default_option = { id: '0', name: 'Printer Default' }
  tray_default_option = { id: '0', name: 'Printer Default' }
  resolution_default_option = { name: 'Printer Default' }

  colors = [this.color_default_option]
  duplex = [this.duplex_default_option]
  papers = [this.paper_default_option]
  trays = [this.tray_default_option]
  resolutions = [this.resolution_default_option]

  orientations = []

  profile: PrinterProfile
  isLoading = false
  profileForm: FormGroup
  printers: Printer[] = []

  isEdit: boolean = false
  filteredPrinters: Observable<any>
  isLoadingPrinters: boolean = true
  noPrinters: boolean = false
  selectionMade = false

  constructor(
    private fb: FormBuilder,
    private profilesService: PrinterProfileService,
    public dialogRef: MatDialogRef<ProfileDetailsDialogComponent>,
    public dialog: MatDialog,
    private networkPrinterService: NetworkPrintersPrintersService,
    private networkPrintersPrintersFiltersService: NetworkPrintersPrintersFiltersService,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public profile_to_edit: any
  ) {
    this.isLoading = false
    this.profileForm = this.fb.group({
      name: [null, Validators.required],
      description: [null, [Validators.maxLength(255)]],
      orientation: [{ value: null, disabled: true }],
      printers: [null, Validators.required],
      autoCompleteInput: [null, Validators.required],

      paper_size: [{ value: this.paper_default_option.name, disabled: true }],
      tray: [{ value: this.tray_default_option.name, disabled: true }],
      resolution: [{ value: this.resolution_default_option.name, disabled: true }],
      color: [{ value: this.color_default_option.name, disabled: true }],
      duplex: [{ value: this.duplex_default_option.name, disabled: true }],
    })

    this.profile = profile_to_edit ? { ...profile_to_edit } : new PrinterProfile()

    this.isEdit = !!profile_to_edit

    if (this.isEdit) {
      // Ensure profile_to_edit.printers is defined and has at least one printer
      if (this.profile_to_edit.printers && this.profile_to_edit.printers.length > 0) {
        // Select the first printer
        this.printerSelected(this.profile_to_edit.printers[0])
      }

      this.profile.color = this.profile_to_edit.color || this.color_default_option.name
      this.profile.duplex = this.profile_to_edit.duplex || this.duplex_default_option.name
      this.profile.tray = this.profile_to_edit.tray || this.tray_default_option.name
      this.profile.paper_size = this.profile_to_edit.paper_name || this.paper_default_option.name
      this.profile.resolution =
        this.profile_to_edit.resolution || this.resolution_default_option.name

      this.profileForm.patchValue(this.profile)
      if (this.profile.printers && this.profile.printers.length > 0) {
        this.profileForm
          .get('autoCompleteInput')
          .setValue(this.profile.printers[0].name, { emitEvent: false })
      }
    }
  }

  ngOnInit() {
    this.profileForm.valueChanges.pipe(debounceTime(500)).subscribe((form_data: PrinterProfile) => {
      this.profile = { ...this.profile, ...form_data } as PrinterProfile
      const paper_obj = this.papers.find((element) => element.name === this.profile.paper_size)
      this.profile.paper_id = paper_obj.id || null
      this.profile.paper_name = paper_obj.name || null
    })

    this.filteredPrinters = this.profileForm.get('autoCompleteInput').valueChanges.pipe(
      debounceTime(500),
      startWith(''),
      tap(() => {
        if (!this.selectionMade) {
          this.isLoadingPrinters = true // Only set loading to true if the change is not due to selection
        }
      }),
      switchMap((value) => {
        if (this.selectionMade) {
          this.selectionMade = false // Reset the flag
          return of([]) // Do not proceed with filtering if the change is due to selection
        } else {
          return this._filter(value || '')
        }
      }),
      map((response) => {
        this.isLoadingPrinters = false // Set loading to false on success
        return response
      }),
      catchError((error) => {
        this.isLoadingPrinters = false // Ensure loading is set to false on error
        return of([]) // Handle errors gracefully
      })
    )

    this.profileForm.setValidators(this.validatePrintersArray.bind(this))
  }

  validatePrintersArray(formGroup: FormGroup): { [key: string]: any } | null {
    const printers = formGroup.get('printers').value
    return printers && printers.length > 0 ? null : { printersRequired: true }
  }

  printerDropDownClosed() {
    const printer = this.profileForm.get('printers').value[0]
    this.profileForm.get('autoCompleteInput').setValue(printer.name, { emitEvent: false })
    this.networkPrintersPrintersFiltersService.setSearchText(printer.name)
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase()
    this.networkPrintersPrintersFiltersService.setSearchText(filterValue)
    return this.networkPrinterService.getFilteredNetworkPrintersPrinters().pipe(
      map((response) => {
        this.isLoadingPrinters = false // Set loading to false on success
        if (response['printers'].length === 0) this.noPrinters = true
        else this.noPrinters = false
        return response['printers']
      })
    )
  }

  ngOnDestroy(): void {
    this.networkPrintersPrintersFiltersService.setSearchText('')
  }

  printerSelected(printer: Printer) {
    this.toggleDropdowns(false)
    if (!printer) {
      return
    }

    this.profilesService
      .getPrinterCapabilities([printer.id])
      .subscribe((options_values: PrinterCapability) => {
        // Directly initialize each property with the default option followed by the options from the service
        this.colors = [this.color_default_option, ...options_values.colors]
        this.duplex = [this.duplex_default_option, ...options_values.duplex]
        this.papers = [this.paper_default_option, ...options_values.papers]
        this.trays = [this.tray_default_option, ...options_values.trays]
        this.resolutions = [this.resolution_default_option, ...options_values.resolutions]

        this.colors = this.colors.filter((color) => color.name !== undefined && color.name !== null)
        this.toggleDropdowns(true)

        this.validateFormField('color', this.colors)
        this.validateFormField('duplex', this.duplex)
        this.validateFormField('paper_size', this.papers)
        this.validateFormField('tray', this.trays)
        this.validateFormField('resolution', this.resolutions)
      })

    this.selectionMade = true
    this.profileForm.get('printers').setValue([printer])
    if (!this.profile.printers.some((existingPrinter) => existingPrinter.id === printer.id)) {
      this.profile.printers = [printer]
    }
  }

  toggleDropdowns(enable: boolean) {
    const controls = ['color', 'duplex', 'paper_size', 'tray', 'resolution']
    controls.forEach((control) => {
      const formControl = this.profileForm.get(control)
      if (enable) {
        formControl.enable()
      } else {
        formControl.disable()
      }
    })
  }

  openRemoveProfileDialog() {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = false
    dialogConfig.width = '320px'
    dialogConfig.panelClass = 'ezp-dialog'
    dialogConfig.data = [this.profile]

    this.dialogRef.close()
    this.dialog.open(RemoveProfileDialogComponent, dialogConfig)
  }

  close() {
    this.isLoading = true
    this.dialogRef.close()
  }

  validateFormField(controlName: string, collection: any[]): void {
    const foundItem = collection.find(
      (item) => item.name == this.profileForm.get(controlName).value
    )
    if (!foundItem) {
      this.profileForm.get(controlName).setErrors({ customError: true })
      // this.profileForm.get(controlName).markAsTouched();
    } else {
      // Optionally clear errors if valid item is found
      this.profileForm.get(controlName).setErrors(null)
    }
  }

  done() {
    this.isLoading = true
    this.profilesService.saveProfile(this.isEdit, this.profile).subscribe({
      next: () => {
        this.dialogRef.close()
        this.profilesService.applyFilters(true)
      },
      error: (error) => {
        this.isLoading = false
        // this.errorService.openTranslate(error, 'ERRORS.GENERIC')
      },
    })
  }
}
