import { Component, ElementRef, ViewChild } from '@angular/core'
import {
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
  MatLegacyDialog as MatDialog,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog'
import { ActivatedRoute, Router } from '@angular/router'
import { GenerateQRCodeService } from '../../../../modules/shared/services/generate-qrcode.service'
import { NetworkPrintersPrintersService } from '../../../../modules/shared/services/network-printers-printers.service'
import { ANYPRINTER_BY_EZEEP } from '../../../../modules/core/constants/core.constants'
import { PrinterEditorDialogComponent } from '../printer-editor-dialog/printer-editor-dialog.component'
import { PullprintingService } from '../../../../modules/shared/services/pullprinting.service'
import { LangaugeService } from '../../../../modules/shared/services/langauge.service'
import { SubscriptionService } from '../../../../modules/shared/services/subscription.service'

@Component({
  selector: 'ezd-printer-editor-pullprinting',
  templateUrl: './printer-editor-pullprinting.component.html',
  styleUrls: ['./printer-editor-pullprinting.component.scss'],
})
export class PrinterEditorPullprintingComponent {
  format: string = 'pdf'
  printers: any
  printerName: boolean = false
  pageSize: string
  codeSize: string
  defaultPageSize: string = 'A4'
  defaultCodeSize: string = '5cm'
  selectedPrinter: any
  selectedPrinters = []
  ANYPRINTER_BY_EZEEP = ANYPRINTER_BY_EZEEP
  @ViewChild('pairCode') input_pairCode: ElementRef
  pairPrinterCode: string
  successMessage: boolean
  errorMessage404: boolean
  errorMessage409: boolean
  errorMessage400: boolean
  errorMessageUnknown: boolean
  preferredLanguage: any
  tooltipText: string
  isAvailable: boolean
  private _dataLoaded = false

  constructor(
    public dialogRef: MatDialogRef<PrinterEditorDialogComponent>,
    private dialog: MatDialog,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private networkPrintersPrintersService: NetworkPrintersPrintersService,
    private generateQRCodeService: GenerateQRCodeService,
    private pullPrintingService: PullprintingService,
    private subscriptionService: SubscriptionService,
    private languageService: LangaugeService
  ) {
    this.printers = this.networkPrintersPrintersService.getSelectedNetworkPrinters()
    this.selectedPrinters = this.networkPrintersPrintersService.getSelectedNetworkPrinters()
    if (this.selectedPrinters.length > 0) {
      this.selectedPrinter = this.selectedPrinters[0]
    }
  }

  ngOnInit(): void {
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value
    })
    this.subscriptionService.getSubscription().subscribe((result) => {
      this.isAvailable = result.features && result.features.pull_printing == true ? true : false
    })
  }

  get dataLoaded(): boolean {
    return this._dataLoaded
  }

  loadData() {
    if (!this._dataLoaded) {
      console.log('loadData')
      this._dataLoaded = true
    }
  }

  selectPageSize(event: any) {
    this.pageSize = event.value
  }

  selectCodeSize(event: any) {
    this.codeSize = event.value
  }

  enablePrinterName() {
    this.printerName
  }

  generateQRCode() {
    let pageSize = this.defaultPageSize ? this.defaultPageSize : this.pageSize
    let codeSize = this.defaultCodeSize ? this.defaultCodeSize : this.codeSize
    this.generateQRCodeService.downloadQRCode(
      this.printers.filter((x) => x.name != ANYPRINTER_BY_EZEEP).map((x) => x.id),
      this.format,
      this.printerName ? 'true' : 'false',
      pageSize,
      codeSize,
      null
    )
  }

  handleCopyQRCode() {
    this.copyQRcode(this.selectedPrinter.id)
  }

  copyQRcode(printerId) {
    this.generateQRCodeService.copyQRCode(
      [printerId],
      'png',
      this.printerName ? 'true' : 'false',
      undefined,
      undefined
    )
  }

  handleDownloadQRCode() {
    this.downloadQRcode(this.selectedPrinter.id, this.selectedPrinter.name)
  }

  handleMultiplePrintersDownloadQRCode() {
    this.selectedPrinters.forEach((selectedPrinter) =>
      this.downloadQRcode(selectedPrinter.id, selectedPrinter.name)
    )
  }

  downloadQRcode(printerId, printerName) {
    this.generateQRCodeService.downloadQRCode(
      [printerId],
      'png',
      this.printerName ? 'true' : 'false',
      undefined,
      undefined,
      printerName
    )
  }

  pairPrinterPullPrintingCode() {
    this.pullPrintingService
      .pairPrinterPullPrintingCode(this.pairPrinterCode, this.selectedPrinter.id)
      .subscribe({
        next: () => {
          setTimeout(() => {
            this.successMessage = false
          }, 6000)
          this.successMessage = true
        },
        error: (error) => {
          if (error.status == 404) {
            setTimeout(() => {
              this.errorMessage404 = false
            }, 6000)
            this.errorMessage404 = true
          } else if (error.status == 409) {
            setTimeout(() => {
              this.errorMessage409 = false
            }, 6000)
            this.errorMessage409 = true
          } else if (error.status == 400) {
            setTimeout(() => {
              this.errorMessage400 = false
            }, 6000)
            this.errorMessage400 = true
          } else {
            setTimeout(() => {
              this.errorMessageUnknown = false
            }, 6000)
            this.errorMessageUnknown = true
          }
        },
      })
  }

  onPairingCodeInput(event) {
    if (this.input_pairCode.nativeElement.value.length == 0)
      this.input_pairCode.nativeElement.focus()
    if (event.code === 'Backspace' && this.input_pairCode.nativeElement.value.length == 0)
      this.input_pairCode.nativeElement.focus()
  }

  close() {
    this.dialogRef.close()
  }

  done() {
    this.dialogRef.close()
  }

  showTooltip() {
    if (
      this.selectedPrinters &&
      this.selectedPrinters.length > 1 &&
      this.preferredLanguage == 'en'
    ) {
      this.tooltipText = 'Pull Printing Pairing is unavailable when selecting multiple printers.'
    }
    if (
      this.selectedPrinters &&
      this.selectedPrinters.length > 1 &&
      this.preferredLanguage == 'de'
    ) {
      this.tooltipText =
        'Pull-Printing-Pairing ist nicht verfügbar, wenn mehrere Drucker ausgewählt werden.'
    }
  }

  handleNavigation() {
    this.dialogRef.close()
    this.router.navigate(['subscription'])
  }
}
