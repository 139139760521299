import { Injectable } from '@angular/core';
import { PrintersService, PrinterAssignment, AssignmentsService, Printer } from './printing-api.service';
import { Observable, from, BehaviorSubject, lastValueFrom } from 'rxjs';
import { map, mergeMap, last } from 'rxjs/operators';
import { UsersgroupsGroupsService } from './usersgroups-groups.service';

@Injectable({
  providedIn: 'root',
})
export class UsersgroupsGroupsPrintersService {
  allPrintersSubject = new BehaviorSubject<any>([])
  currentGroupId: any
  allPrinters: any[] = []
  PAGE_SIZE: number = 100
  PAGE_INDEX: any = 0
  draftedAssignedPrinters: any[]
  hasDraftedAssignedPrinters: boolean = false

  constructor(
    private printerService: PrintersService,
    private assignmentService: AssignmentsService,
    private groupService: UsersgroupsGroupsService
  ) {}

  public getAllPrinters(searchText: string, groupId: string): Observable<any> {
    this.setAllPrinters(searchText, groupId, this.PAGE_SIZE, this.PAGE_INDEX)
    return this.allPrintersSubject.asObservable()
  }

  async setAllPrinters(searchText: string, groupId: string, page_size: number, offset: number) {
    if (!this.currentGroupId) {
      this.currentGroupId = groupId
    }
    if (this.currentGroupId != groupId) {
      this.currentGroupId = groupId
      this.hasDraftedAssignedPrinters = false
      this.allPrinters = []
    }
    this.allPrintersSubject.next({ isLoading: true, printers: this.allPrinters })
    // if(next)
    //   this.PAGE_SIZE += 25;

    let response = await lastValueFrom(this.getPrinters(searchText, page_size, offset)) // .toPromise();
    let endOfList = !response.next
    // if (endOfList) {
    //   this.PAGE_SIZE = 25;
    // }
    if (response.printers.length == 0) {
      this.allPrintersSubject.next({
        isLoading: false,
        printers: this.allPrinters,
        endOfList: endOfList,
      })
      return
    }

    this.allPrinters = response.printers
    this.allPrintersSubject.next({
      isLoading: false,
      printers: this.allPrinters,
      endOfList: endOfList,
    })
  }

  getPrinters(searchText: string, pageSize: number, pageIndex: number): Observable<any> {
    return this.printerService
      .list(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'connector',
        'false',
        searchText,
        'name',
        pageSize,
        pageIndex
      )
      .pipe(
        map((response) => {
          return { printers: response.result.results, next: response.result.next }
        })
      )
  }

  getAssignedPrintersToGroup(
    group_id: string,
    page_size: number,
    page_index: number
  ): Observable<any> {
    let offset = page_index * page_size
    return this.assignmentService
      .list(undefined, group_id, undefined, undefined, page_size, offset)
      .pipe(
        map(async (response) => {
          let printers = []
          if (response.result.next) {
            let nextIndex = page_index + 1
            printers = await lastValueFrom(
              this.getAssignedPrintersToGroup(group_id, page_size, nextIndex)
            ) // .toPromise()
          }
          return [...response.result.results, ...printers]
        })
      )
  }

  async draftAssignedPrinters(currentAssignedPrinters: any[], newAssignments: any) {
    let removedPrinters = currentAssignedPrinters.filter(
      (g) => newAssignments.filter((n) => n.id == g.id).length == 0
    )
    let currentPrinters = await lastValueFrom(
      this.getAssignedPrintersToGroup(this.currentGroupId, 100, 0)
    ) // .toPromise()
    newAssignments = newAssignments.filter(
      (nu) => currentPrinters.filter((cp) => cp.printer_id == nu.id).length == 0
    )

    this.draftedAssignedPrinters = currentPrinters
      .filter((cp) => removedPrinters.filter((rp) => rp.id == cp.printer_id).length == 0)
      .map((cp) => {
        return { id: cp.printer_id }
      })
    this.draftedAssignedPrinters = [...this.draftedAssignedPrinters, ...newAssignments]
    this.hasDraftedAssignedPrinters = true
  }

  async saveDraftedAssignedPrinters() {
    if (!this.draftedAssignedPrinters) return

    this.hasDraftedAssignedPrinters = false
    let groupToEdit = this.groupService.getGroupToEdit()
    let responseGroup = [
      {
        group_id: groupToEdit.id,
        group_name: groupToEdit.name,
        group_foreign_id: groupToEdit.origin ? groupToEdit.origin.foreign_id : '',
      },
    ]

    let currentPrinters = await lastValueFrom(
      this.getAssignedPrintersToGroup(this.currentGroupId, 100, 0)
    ) // .toPromise()
    let removedPrinters = currentPrinters.filter(
      (cp) => this.draftedAssignedPrinters.filter((dp) => cp.printer_id == dp.id).length == 0
    )

    if (removedPrinters && removedPrinters.length > 0) {
      from(removedPrinters)
        .pipe(
          mergeMap(async (p: PrinterAssignment) => {
            let group = responseGroup //save it before requesting the assignments
            let response: any = await lastValueFrom(this.getAssignments(p.printer_id)) // .toPromise();
            if (
              response.groups !== undefined &&
              response.groups.length > 0 &&
              group[0] != undefined
            ) {
              let groupsFiltered = response.groups.filter((ag) => ag.group_id !== group[0].group_id)
              await lastValueFrom(
                this.update(response.ip_ranges, groupsFiltered, p.printer_id, undefined)
              ) // .toPromise();
            }
          })
        )
        .pipe(last())
        .subscribe(async () => {
          return
        })
    }
    if (this.draftedAssignedPrinters.length > 0) {
      //update groups
      from(this.draftedAssignedPrinters)
        .pipe(
          mergeMap(async (p: Printer) => {
            let group = responseGroup //save it before requesting the assignments
            let response: any = await lastValueFrom(this.getAssignments(p.id)) // .toPromise();
            group = group.filter((g) => {
              if (
                response.groups == undefined ||
                response.groups.length == 0 ||
                (g != undefined &&
                  response.groups.filter((ag) => ag.group_id == g.group_id).length == 0)
              )
                return g
            })
            if (group[0] == undefined) return
            await lastValueFrom(
              this.update(response.ip_ranges, [...response.groups, ...group], p.id, undefined)
            ) // .toPromise();
          })
        )
        .pipe(last())
        .subscribe(async () => {
          return
        })
    }
  }

  clearDraftedAssignedGroups() {
    this.draftedAssignedPrinters = []
  }

  update(ranges: any[], groups: any[], printerId: string, printerName: string): Observable<any> {
    let assignments: any = [
      {
        groups: groups,
        settings: {
          ip_ranges: ranges.map((x) => {
            return { start_ip: x.start_ip, stop_ip: x.stop_ip }
          }),
        },
      },
    ]
    let assignment = new PrinterAssignment({
      printer_id: printerId,
      printer_name: printerName,
      assignment: assignments,
    })
    return this.assignmentService.update(assignment, printerId).pipe(
      map((response) => {
        return response.result
      })
    )
  }

  public getAssignments(printerId: string): Observable<any> {
    return this.assignmentService.read(printerId).pipe(
      map((response) => {
        let tempGroups = []
        let tempRestrictedIpRanges = []

        if (
          !response.result ||
          !response.result.assignments ||
          response.result.assignments.length == 0
        ) {
          return { groups: tempGroups, ip_ranges: tempRestrictedIpRanges }
        }

        let printerGroups = response.result.assignments[0].groups
        if (printerGroups && printerGroups.length > 0) {
          tempGroups = printerGroups
        }
        if (
          response.result.assignments[0].settings &&
          response.result.assignments[0].settings.ip_ranges &&
          response.result.assignments[0].settings.ip_ranges.length > 0
        ) {
          response.result.assignments[0].settings.ip_ranges.forEach((item) => {
            let range = { start_ip: item.start_ip, stop_ip: item.stop_ip }
            tempRestrictedIpRanges.push(range)
          })
        }

        return { groups: tempGroups, ip_ranges: tempRestrictedIpRanges }
      })
    )
  }
}
