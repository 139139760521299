<mat-form-field
  class="ezp-form-field ezp-form-field--full ezp-form-field--densed"
  id="search-users"
  appearance="fill"
>
  <mat-icon matPrefix>search</mat-icon>
  <input
    type="text"
    matInput
    id="search-users-input"
    [placeholder]="'USERSGROUPS.USERS.SEARCH_USERS.LABEL' | translate" 
    (input)="onSearchChange($event.target.value)"
  />
  <mat-hint align="start" id="search-users-hint">{{
    'USERSGROUPS.USERS.SEARCH_USERS.HINT' | translate
  }}</mat-hint>
</mat-form-field>
