<svg class="icon">
  <use
    [attr.href]="'/assets/images/glyphs.svg#ezp-glyph-print-app-' + (matched ? matched.os : 'any')"
  />
</svg>
<div class="text">
  <div class="mat-headline title" [id]="installation.title">{{ installation.title }}</div>
  <div class="mat-subheading-2 description" [id]="matched ? installation.description[matched.os] : installation.description.any">
    {{ matched ? installation.description[matched.os] : installation.description.any }}
  </div>
</div>
<div class="downloads">
  <a
    matRipple
    class="download"
    id="os-download-link"
    [href]="platform.url"
    *ngFor="let platform of platforms"
    [ngClass]="[
      matched && matched.os === platform.os ? 'download--matched' : '',
      matched
        ? matched.os === platform.os
          ? 'download--primary'
          : 'download--secondary'
        : 'download--primary'
    ]"
  >
    <svg class="download__os">
      <use [attr.href]="'/assets/images/glyphs.svg#ezp-glyph-os-' + platform.os" />
    </svg>
    <div class="download__label mat-subheading-2" [id]="installation.action">{{ installation.action }}</div>
    <div class="download__meta mat-body" [id]="platform.title">{{ platform.title }}</div>
  </a>
</div>
