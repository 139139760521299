import { Component, OnInit, ViewChild } from '@angular/core';
import {LocalPrinterSupportService} from '../../modules/shared/services/local-printer-support.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '../../app.config';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'ezd-localprintersupport',
  templateUrl: './localprintersupport.component.html',
  styleUrls: ['./localprintersupport.component.scss']
})

export class LocalPrinterSupportComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  disableLocalPrinterSupport = false;
  printersServiceGroups = [];
  showAdconSeviceGrps = false;
  maxPageLength: number;
  defaultPageSize = DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  pageIndex: number;
  pageSize: number = DEFAULT_PAGE_SIZE;
  isLoading = false;
  columnDefs = ['name', 'remove'];

  // Mock
  // printersServiceGroups = [{name: 'acme\\Online Marketing'}, {name: 'acme\\Creative Department'}, {name: 'acme\\Consulting'}];

  constructor(private localPrinterSupportService: LocalPrinterSupportService) {
  }

  ngOnInit() {
    this.localPrinterSupportService.setPage(0, this.defaultPageSize, true);
    this.localPrinterSupportService.setPrintersServiceGroups()
    this.localPrinterSupportService.getPrintersServiceGroups()
      .subscribe(result => {
        this.isLoading = result.isLoading;
        this.printersServiceGroups = result.groups;
        this.maxPageLength = result.count;
      });
    this.localPrinterSupportService.getCurrentLocalPrintingEnableStatus()
      .subscribe(result => {
        this.disableLocalPrinterSupport = !result;
      });
  }

  onDisableLocalPrinterSupportChange(event: any) {
    lastValueFrom(this.localPrinterSupportService.toggleLocalPrinterSupport())
      // .toPromise()
      .then(result => {
        this.disableLocalPrinterSupport = !result;
      });
  }

  removeGroup(item: any) {
    this.isLoading = true;
    this.printersServiceGroups = [];
    this.localPrinterSupportService.removePrintersServiceGroup(item);
  }

  showAdconGroups() {
    this.showAdconSeviceGrps = true;
  }

  hideAdconGroups() {
    this.showAdconSeviceGrps = false;
  }

  public onPaginationChange(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.localPrinterSupportService.setPage(this.pageIndex, this.pageSize);
  }

}
