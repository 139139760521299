<mat-form-field
  class="ezp-grid ezp-grid--gutter-horizontal ezp-grid--spacing-vertical search-printers" data-grid-cols="1"
  appearance="fill"
>
  <mat-label>{{ 'PRINTERS.NETWORK_PRINTERS.PRINTERS_SEARCH.PLACEHOLDER' | translate }}</mat-label>
  <mat-icon matPrefix class="search-icon">search</mat-icon>
  <input
    id="search-printers"
    type="text"
    matInput
    [placeholder]="'PRINTERS.NETWORK_PRINTERS.PRINTERS_SEARCH.PLACEHOLDER' | translate"
    (input)="onSearchChange($event.target.value)"
  />
  <mat-hint align="start" id="printers-search-hint">{{ 'PRINTERS.NETWORK_PRINTERS.PRINTERS_SEARCH.HINT' | translate }}</mat-hint>
</mat-form-field>
