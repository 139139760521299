import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SvgModule } from './svg/svg.module';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { StringReplacePipe } from './pipes/string-replace.pipe';
import { OsVersionPipe } from './pipes/version.pipe';
import { LocaleDatePipe } from './pipes/locale-date.pipe';
import { IntlDatePipe } from './pipes/locale-date.pipe'
import { MaterialModule } from '../material/material.module'
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component'
import { LoadingIconComponent } from './components/loading-icon/loading-icon.component'
import { InfinityScrollComponent } from './components/infinity-scroll/infinity-scroll.component'
import { VerifyEmailReminderDialogComponent } from './components/verify-email-reminder-dialog/verify-email-reminder-dialog.component'
import { VerifyEmailConfirmedDialogComponent } from './components/verify-email-confirmed-dialog/verify-email-confirmed-dialog.component'
import { VerifyEmailFailedDialogComponent } from './components/verify-email-failed-dialog/verify-email-failed-dialog.component'
import { VerifyEmailSentDialogComponent } from './components/verify-email-sent-dialog/verify-email-sent-dialog.component'
import { InvitationReminderDialogComponent } from './components/invitation-reminder-dialog/invitation-reminder-dialog.component'
import { AlertChangingPropertiesComponent } from './components/alert-changing-properties/alert-changing-properties.component'
import { TablePlaceholderComponent } from './components/table-placeholder/table-placeholder.component'
import { BannerAdComponent } from './components/banner-ad/banner-ad.component'

@NgModule({
  declarations: [
    CapitalizePipe,
    StringReplacePipe,
    OsVersionPipe,
    LocaleDatePipe,
    IntlDatePipe,
    ExpansionPanelComponent,
    InfinityScrollComponent,
    VerifyEmailReminderDialogComponent,
    VerifyEmailConfirmedDialogComponent,
    VerifyEmailFailedDialogComponent,
    VerifyEmailSentDialogComponent,
    InvitationReminderDialogComponent,
    AlertChangingPropertiesComponent,
    TablePlaceholderComponent,
    BannerAdComponent,
    LoadingIconComponent,
  ],
  exports: [
    CapitalizePipe,
    StringReplacePipe,
    OsVersionPipe,
    LocaleDatePipe,
    IntlDatePipe,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SvgModule,
    TranslateModule,
    ExpansionPanelComponent,
    InfinityScrollComponent,
    VerifyEmailReminderDialogComponent,
    VerifyEmailConfirmedDialogComponent,
    VerifyEmailFailedDialogComponent,
    VerifyEmailSentDialogComponent,
    AlertChangingPropertiesComponent,
    TablePlaceholderComponent,
    BannerAdComponent,
    LoadingIconComponent,
  ],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, SvgModule, MaterialModule],
})
export class SharedModule {}
