import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { UsersgroupsUsersService } from '../../../../modules/shared/services/usersgroups-users.service';
import { Subscription, Subject, lastValueFrom } from 'rxjs';
import { AuthUser } from '../../../../modules/shared/services/auth-api.service';
import { debounceTime } from 'rxjs/operators';
import { UsersgroupsUserInvitationsService } from '../../../../modules/shared/services/usersgroups-user-invitations.service';
import { ResendInvitationDialogComponent } from '../resend-invitation-dialog/resend-invitation-dialog.component';
import { MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SubscriptionService } from '../../../../modules/shared/services/subscription.service';

@Component({
  selector: 'ezd-user-details-information',
  templateUrl: './user-details-information.component.html',
  styleUrls: ['./user-details-information.component.scss']
})
export class UserDetailsInformationComponent implements OnInit, AfterViewInit {
  @ViewChild('email') email: ElementRef;
  @ViewChild('firstName') firstName: ElementRef;
  @ViewChild('billingCode') billingCode: ElementRef;
  @ViewChild('role', {static: false}) role: MatSelect;

  authUserSubscription: Subscription;
  selectedUserSubscription: Subscription;
  isLoading: boolean = true;
  user: any;
  isAdmin: boolean = false;
  isAzureUser: boolean = false;
  displayName: string;
  azureUserFirstName: string;
  azureUserLastName: string;
  propertiesChanged = new Subject<any>();
  selectedField = '';
  roles = '';
  isSocialAuthUser: boolean;
  isWebhooksPresent: boolean;
  isReportDownloadPresent: boolean;

  constructor(
    private authUserService: UsersgroupsUsersService,
    private clipboard: Clipboard,
    private dialog: MatDialog,
    private subscriptionService: SubscriptionService,
    private userInvitationService: UsersgroupsUserInvitationsService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.authUserSubscription = this.authUserService.getFilteredUsers()
      .subscribe(async result => {
        this.user = Object.assign({}, this.authUserService.getUserToEdit());
        if (!this.user)
          return;
        this.user.roles = Object.assign([], this.authUserService.getUserToEdit().roles);
        this.user.role = this.user.roles && this.user.roles.includes("admin") ? "admin" : "user"
        
        this.isSocialAuthUser = this.user.id_profile && this.user.id_profile.connected_idp && this.user.id_profile.connected_idp.length == 1 && this.user.id_profile.connected_idp.includes("local") ? false : true
        let currentUser = await lastValueFrom(this.authUserService.getAuthMe()) // .toPromise();
        this.isAdmin = currentUser.user_id == this.user.id;
        this.isLoading = result.isLoading;
      })

    this.subscriptionService.getSubscription().subscribe((result) => {
      this.isWebhooksPresent = result.features && result.features.webhooks && result.features.webhooks == true ? true : false;
      this.isReportDownloadPresent = result.features && result.features.report_download && result.features.report_download == true ? true : false;
    })

    this.selectedUserSubscription = this.authUserService.getSelectedField().subscribe(
      result => this.selectedField = result
    )

    this.propertiesChanged
      .pipe(
        debounceTime(300))
      .subscribe(result => {
        let hasChanges = false;
        if (result.first_name != null) {
          hasChanges = true;
          this.user.first_name = result.first_name;
        }
        if (result.last_name != null) {
          hasChanges = true;
          this.user.last_name = result.last_name;
        }
        if (result.email != null) {
          hasChanges = true;
          this.user.email = result.email;
        }
        if (result.billing_code != null) {
          hasChanges = true;
          this.user.billing_code = result.billing_code;
          if (this.user.billing_code == "") {
            this.user.billing_code = null;
          }

        }
        if (result.role) {
          hasChanges = true;
          if(result.role == "admin")
            this.user.roles.push("admin");
          else
            this.user.roles = this.user.roles.filter(x => x !== "admin");
          if(result.role == "user")
            this.user.roles.push("user");
          else
            this.user.roles = this.user.roles.filter(x => x !== "user")
        }

        if (hasChanges) {
          this.authUserService.draftUserDetails(this.user);
        }
      });
  }

  ngAfterViewInit() {
    if (this.selectedField === 'email') {
      setTimeout(() => this.email.nativeElement.focus(), 0)
    }
    if (this.selectedField === 'firstName') {
      setTimeout(() => this.firstName.nativeElement.focus(), 0)
    }
    if (this.selectedField === 'billingCode') {
      setTimeout(() => this.billingCode.nativeElement.focus(), 0)
    }
    if (this.selectedField === 'role') {
      setTimeout(() => this.role.focus(), 0)
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.authUserSubscription.unsubscribe();
    this.selectedUserSubscription.unsubscribe();
  }

  copyEzeepId() {
    this.clipboard.copy(this.user.id);
  }

  copyAzureId() {
    this.clipboard.copy(this.user.azure_profile.oid);
  }

  // resendInvitation() {
  //   let startDate = new Date()
  //   startDate.setDate(startDate.getDate() + 30)
  //   let expirationDate = startDate
  //   this.userInvitationService.resendInvitation([this.user.user_invitations[0].id], expirationDate) // .toPromise()
  // }

  openResendInvitationDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.width = '350px';
    dialogConfig.panelClass = 'ezp-dialog';
    dialogConfig.data = { resendInvite: this.user };

    this.dialog.open(ResendInvitationDialogComponent, dialogConfig);
  }

  onFirstNameChange(value: string) {
    this.propertiesChanged.next({ first_name: value });
  }

  onLastNameChange(value: string) {
    this.propertiesChanged.next({ last_name: value });
  }

  onEmailChange(value: string) {
    this.propertiesChanged.next({ email: value });
  }

  onRoleChange(value: string) {
    this.propertiesChanged.next({ role: value });
  }

  onBillingCodeChange(value: string) {
    this.propertiesChanged.next({ billing_code: value });
  }
}
