import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { UsersgroupsGroupsPrintersService } from '../../../../modules/shared/services/usersgroups-groups-printers.service'
import { UsersgroupsGroupsService } from '../../../../modules/shared/services/usersgroups-groups.service'
import { Subject, Subscription, Observable, lastValueFrom } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { PrinterProfileService } from '../../../../modules/shared/services/printer-profile.service';
import { PRINTERS } from '../../../pages.constants';
import { GeneralUtil } from '../../../../modules/shared/utils/general-util';
import { ErrorService } from '../../../../modules/shared/services/error.service';

@Component({
  selector: 'ezd-group-details-printers',
  templateUrl: './group-details-printers.component.html',
  styleUrls: ['./group-details-printers.component.scss'],
})
export class GroupDetailsPrintersComponent implements OnInit {
  @ViewChild('search') search: ElementRef;

  assignedPrinters: Array<any> = []
  isLoading: boolean = true;
  endOfList: boolean = false;
  group: any;
  isAzureGroup: boolean;
  searchTextChanged = new Subject<string>();
  AllPrintersSubscription: Subscription;
  searchText: string;
  allPrinters: any = [];
  currentPageIndex: number = 0;
  // profiles: any
  // newAssignments = []
  constructor(private groupPrintersService: UsersgroupsGroupsPrintersService,
    private groupsService: UsersgroupsGroupsService, private profileService: PrinterProfileService,
    private errorService: ErrorService) {}

  async ngOnInit(): Promise<void> {
    this.group = this.groupsService.getGroupToEdit();
    this.isAzureGroup = this.group.origin && this.group.origin.provider == 'Azure';
    this.AllPrintersSubscription = this.groupPrintersService.getAllPrinters(this.searchText, this.group.id).subscribe(async result => {
      if (!result.isLoading && result.printers) {
        this.allPrinters = [...this.allPrinters, ...result.printers]

        if (!result.endOfList) {
          this.currentPageIndex += 1
          this.groupPrintersService.setAllPrinters(this.searchText, this.group.id, 100, 100 * this.currentPageIndex)
          return
        }

        const [
          assignedPrintersGroup,
          // groupPrinterPresets,
        ] = await Promise.all([
          lastValueFrom(this.groupPrintersService.getAssignedPrintersToGroup(this.group.id, 100, 0)),
          // lastValueFrom(this.profileService.getGroupedPrinterPresets(null, PRINTERS, 1000, 0)),
        ]);
        this.allPrinters.map(p => {
          p.connector = p.connector.readable_name

          if (assignedPrintersGroup.filter(ap => ap.printer_id == p.id).length > 0) {
            p.isSelected = true;
          } else {
            p.isSelected = false;
          }
        })

        this.assignedPrinters = [...this.allPrinters.filter(x => x.isSelected), ...this.allPrinters.filter(x => !x.isSelected)]
        // this.assignedPrinters = GeneralUtil.findAndAssignPrinterPresets(this.assignedPrinters, groupPrinterPresets)
        // this.assignedPrinters = GeneralUtil.addselectDisabledToArray(this.assignedPrinters, false)
        // this.newAssignments = [...this.newAssignments, ...this.assignedPrinters.filter(g => g.isSelected)]
      }

      this.isLoading = result.isLoading;
      this.endOfList = result.endOfList;
      if (this.endOfList) {
        this.currentPageIndex = 0
      }
    })

    this.searchTextChanged
      .pipe(
        debounceTime(400))
      .subscribe(result => {
        this.searchText = result
        this.isLoading = true
        this.assignedPrinters = []
        this.allPrinters = []
        this.groupPrintersService.setAllPrinters(result, this.group.id, 100, 0);
      })

    // getProfiles
    // this.profiles = await lastValueFrom(this.profileService.getProfiles(1000, 0))
  }

  ngAfterContentChecked() {
    if (this.search) {
      this.search.nativeElement.focus();
    }
  }
  
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.AllPrintersSubscription.unsubscribe()
    this.assignedPrinters = [];
    this.endOfList = false;
    this.isLoading = true;
    this.group = undefined;
  }

  onScroll() {
    if(this.endOfList == undefined || this.endOfList || this.isLoading) return;
    this.isLoading = true;
    this.groupPrintersService.setAllPrinters(this.searchText, this.group.id, 100, 100 * this.currentPageIndex);
  }

  onSelection(e, v) {
    let currentAssignedPrinters = this.assignedPrinters.filter(g => g.isSelected)
    let newAssignments = v.selected.map(u => {
      return u.value
    })

    this.groupPrintersService.draftAssignedPrinters(currentAssignedPrinters, newAssignments)
  }

  // code for profile selection
  // onSelection(e) {
  //   let currentAssignedPrinters = this.assignedPrinters.filter(g => g.isSelected)

  //   if (e.checked) {
  //     this.newAssignments.push(e.source.value);
  //   } else {
  //     this.newAssignments = this.newAssignments.filter(obj => obj.id !== e.source.value.id);
  //   }
  //   this.groupPrintersService.draftAssignedPrinters(currentAssignedPrinters, this.newAssignments)
  // }

  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue);
  }

  // profileSelection(isChecked, printer, type, newprinterId, profile_id) {
  //   this.assignedPrinters = GeneralUtil.addselectDisabledToArray(this.assignedPrinters, true)
  //   if (isChecked) {
  //     this.profileService.updateAssignments(PRINTERS, profile_id, newprinterId).subscribe(
  //       {
  //         next: () => {
  //           this.updatePresetsView()
  //         }, error: error => {
  //           this.errorService.openTranslate(error, "USERSGROUPS.ERROR")
  //         }
  //       });
  //   } else {
  //     this.profileService.deleteAssignments(profile_id, newprinterId, type).subscribe( {
  //       next: () => {
  //         this.updatePresetsView()
  //       }, error: error => {
  //         this.errorService.openTranslate(error, "USERSGROUPS.ERROR")
  //       }
  //     });
  //   }
  // }

  // async updatePresetsView() {
  //   let groupPrinterPresets = await lastValueFrom(this.profileService.getGroupedPrinterPresets(null, PRINTERS, 1000, 0))
  //   this.assignedPrinters = GeneralUtil.findAndAssignPrinterPresets(this.assignedPrinters, groupPrinterPresets)
  //   this.assignedPrinters = GeneralUtil.addselectDisabledToArray(this.assignedPrinters, false)
  // }

}
