<aside class="layout__dialog">
  <div class="layout__dialog__body">
    <header class="layout__dialog__head layout__dialog__head--split">
      <h3 class="mat-title efa-text efa-text--no-spacing efa-text--he" id="unsaved-changes-text">
        {{ 'ALERT_TITLE' | translate }}
      </h3>
    </header>
    <section class="layout__dialog__content">
      <section class="layout__dialog__part">
        <h3 class="mat-body efa-text efa-text--no-spacing efa-text--he" id="alert-dialog-confirm">
          {{ 'ALERT_TEXT' | translate }}
        </h3>
      </section>
    </section>
    <footer class="layout__dialog__foot">
      <button
        mat-button
        color="accent"
        class="efa-button"
        id="alert-dialog-discard"
        (click)="discard()"
      >
        <mat-icon>remove_circle</mat-icon>
        {{ 'DISCARD' | translate }}
      </button>
      <button
        style="margin-left: 5px"
        mat-raised-button
        color="accent"
        id="alert-dialog-save"
        class="efa-button"
        (click)="save()"
      >
        <mat-icon>checked</mat-icon>
        {{ 'SAVE' | translate }}
      </button>
    </footer>
  </div>
</aside>
