import { NgModule } from '@angular/core';
import { LocalPrinterSupportComponent } from './localprintersupport.component';
import { MaterialModule } from '../../modules/material/material.module';
import { CoreModule } from '../../modules/core/core.module';
import { SharedModule } from '../../modules/shared/shared.module';
import { LocalPrinterSupportAddGroupsComponent } from './add-groups/add-groups.component';
import { SearchComponent } from './add-groups/search/search.component';

@NgModule({
  declarations: [
    LocalPrinterSupportComponent,
    LocalPrinterSupportAddGroupsComponent,
    SearchComponent
  ],
  imports: [MaterialModule, CoreModule, SharedModule]
})
export class LocalPrinterSupportModule {}
