import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FILTER_CATEGORIES, ConnectorsFiltersService } from './connectors-filters.service';
import { ConnectorsService, Connector, HubPassword, HubClaimConnect } from './printing-api.service';
import { DEFAULT_PAGE_SIZE } from '../../../app.config';
import { map } from 'rxjs/operators';
import { DIALOG_RESPONSE } from '../classes/dialog-result';

@Injectable()
export class ConnectorService {
  selectedFilters = [];
  connectors = [];
  printerConnectors = [];
  connectorsCount = 0;
  connectorsSubject = new BehaviorSubject<any>({isLoading: true, count: 0, connectors: this.connectors});
  printersConnectorsSubject = new BehaviorSubject<Connector[]>([]);
  pageIndex = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  sorting: string;
  initialSubscription = true;
  connectorPropertiesWatcher = new BehaviorSubject<any>({changed: false, status: DIALOG_RESPONSE.CANCEL});

  constructor(
    private httpClient: HttpClient, 
    private connectorsFiltersService: ConnectorsFiltersService,
    private connectorsService: ConnectorsService) {
    this.connectorsFiltersService.getSelectedFilters().subscribe(result => {
      this.selectedFilters = result;
      const connectorIdFilters = this.selectedFilters.filter(filter => filter.field_name === FILTER_CATEGORIES.CONNECTOR_ID);
      if (this.initialSubscription) {
        this.initialSubscription = false;
      } else if (connectorIdFilters.length == 0) {
        this.applyFilters(undefined);
      }
    });
  }

  public getFilteredConnectors(): Observable<any> {
    return this.connectorsSubject.asObservable();
  }

  public getConnector(id: any) : Observable<Connector> {
    return this.connectorsService.read(id).pipe(map(response => {
      return response.result;
    }));
  }

  applyFilters(connectorId: any, showLoading = true) {
    const searchText = this.connectorsFiltersService.getSearchText();
    const statusFilters = this.selectedFilters.filter(filter => filter.field_name === FILTER_CATEGORIES.STATUS);
    const status = statusFilters.length ? statusFilters.map(filter => filter.name).join('|') : undefined;
    const typeFilters = this.selectedFilters.filter(filter => filter.field_name === FILTER_CATEGORIES.TYPE);
    const type = typeFilters.length ? typeFilters.map(filter => filter.name == 'ezeep Hub' ? 'ezeepHub' : (filter.name == 'ThinPrint Hub' ? 'TPHub' : 'Printserver')).join('|') : undefined;
    const connectorIdFilters = this.selectedFilters.filter(filter => filter.field_name === FILTER_CATEGORIES.CONNECTOR_ID);
    const connectorIdFilter = connectorIdFilters.length ? connectorIdFilters.map(filter => filter.value)[0] : undefined;
    this.connectorsSubject.next({isLoading: (searchText == undefined || searchText == '') && showLoading, count: this.connectorsCount, connectors: [...this.connectors]});
    if (connectorId || connectorIdFilter) {
      const connectorIdTemp = connectorId ? connectorId : connectorIdFilter;
      this.connectorsService.read(connectorIdTemp)
        .subscribe({
          next: response => {
            this.connectorsFiltersService.addFilter('Connector', response.result.readable_name, response.result.id);
            this.connectors = [response.result];
            this.connectorsCount = 1;
            this.connectorsSubject.next({
              isLoading: false,
              count: 1,
              connectors: [response.result]
            });
          }, error: error => {
            this.connectorsSubject.next({ isLoading: false, count: this.connectorsCount, connectors: [...this.connectors] });
            //console.log(error);
          }
        });
    } else {
      this.connectorsService.list(status, undefined, type, searchText, this.sorting, this.pageSize, this.pageIndex * this.pageSize)
        .subscribe({
          next: response => {
            if (searchText !== response.result.searchText) return //block older searches to show up
            this.connectors = [...response.result.results];
            this.connectorsCount = response.result.count;
            this.connectorsSubject.next({
              isLoading: false,
              count: response.result.count,
              noFilters: this.selectedFilters.length == 0,
              connectors: [...response.result.results]
            });
          }, error: error => {
            this.connectorsSubject.next({ isLoading: false, count: this.connectorsCount, connectors: [...this.connectors] });
            //console.log(error);
          }
        });
    }
  }

  selectConnectors(connectors: any[]) {
    this.connectors.forEach(item => (
      item.isSelected = connectors.includes(item) ? true : item.isSelected)
    );
    this.connectorsSubject.next({isLoading: false, count: this.connectorsCount, connectors: [...this.connectors]});
  }

  unselectConnectors(connectors: any[]) {
    this.connectors.forEach(item => {
      if(connectors){
        item.isSelected = connectors.includes(item) ? false : item.isSelected;
      } else {
        item.isSelected = false;
      }
    });
    if(connectors) {this.connectorsSubject.next({isLoading: false, count: this.connectorsCount, connectors: [...this.connectors]});}
  }

  setPage(pageIndex: number, pageSize: number, isInitial = false) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    if (!isInitial) {
      this.applyFilters(undefined);
    }
  }

  setSorting(sorting) {
    this.sorting = sorting;
    this.applyFilters(undefined, false);
  }

  public getSelectedConnectors(): any[] {
    return this.connectors.filter(connector => connector.isSelected);
  }

  public getSelectedConnectorsCount(): number {
    return this.getSelectedConnectors().length;
  }

  saveConnector(selectedConnector: any) {
    this.connectors.map((old, i) => {
      if (old.id === selectedConnector.id) {
        this.connectors[i] = selectedConnector;
      }
    });
    this.connectorsService.update(selectedConnector, selectedConnector.id).subscribe(() => {
      this.applyFilters(undefined);
    });
  }
  
  deleteConnector(id: any): Observable<any> {
    return this.connectorsService.delete(id);
  }

  getPassword(id: string): Observable<HubPassword> {
    return this.connectorsService.getpassword(id)
      .pipe(map(result => {
        return result.result
      }))
  }

  hubClaimAndConnect(serialString: string, password: string): Observable<HubClaimConnect> {
    return this.connectorsService.claimHubConnect(serialString, password)
      .pipe(map(result => {
        return result.result
      }))
  }

  watchConnectorPropertiesChanges() {
    return this.connectorPropertiesWatcher.asObservable();
  }
  changeConnectorPropertiesWatcherStatus(changed: boolean, status: DIALOG_RESPONSE) {
    this.connectorPropertiesWatcher.next({changed: changed, status: status});
  }
}
