import { Component, OnInit, ViewChild, ElementRef, AfterContentChecked } from '@angular/core'
import { UsersgroupsUsersGroupsService } from '../../../../modules/shared/services/usersgroups-users-groups.service'
import { UsersgroupsUsersService } from '../../../../modules/shared/services/usersgroups-users.service';
import { AuthUser } from '../../../../modules/shared/services/auth-api.service';
import { UserPrintingService, GroupsService } from '../../../../modules/shared/services/printing-api.service';
import { Subscription, Subject, lastValueFrom } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { GeneralUtil } from '../../../../modules/shared/utils/general-util';

@Component({
  selector: 'ezd-user-details-groups',
  templateUrl: './user-details-groups.component.html',
  styleUrls: ['./user-details-groups.component.scss'],
})
export class UserDetailsGroupsComponent implements OnInit, AfterContentChecked {
  @ViewChild('searchGroups') search: ElementRef
  assignedGroups: any[] = []
  isLoading: boolean = true
  user: AuthUser
  endOfList: boolean = false
  groupsServiceSubscription: Subscription
  searchTextChanged = new Subject<string>()
  searchText: string
  currentPageIndex: number = 0
  isAzureUser: boolean

  constructor(
    private groupsService: UsersgroupsUsersGroupsService,
    private usersService: UsersgroupsUsersService,
    private userPrintService: UserPrintingService,
    private groupService: GroupsService
  ) {}
  private filterGroups(groups: any[]): any[] {
    return groups.filter(
      (group) => group.origin.provider === 'Ezeep' || group.origin.provider === 'OfficeRnD' || group.origin.provider === 'Cobot'
    )
  }

  async ngOnInit() {
    this.user = this.usersService.getUserToEdit()
    this.isAzureUser = this.user.azure_profile !== null

    let assignedGroups = (await lastValueFrom(this.userPrintService.read(this.user.id))).result.groups
    // adding isSelected and soruce to the assigned groups as they are not returned in the api call.
    const chunkedGroups = GeneralUtil.chunck(assignedGroups, 100) // TODO: Store it in a constant

    Promise.all(
      chunkedGroups.map(async (groups) => {
        let g = await lastValueFrom(
          this.groupService.listMany(groups.map((group) => group.group_id))
        )
        return g.result.results.map((u) => ({ ...u, ['isSelected']: true }))
      })
    ).then((allAssignments) => {
      allAssignments = [].concat(...allAssignments)
      this.groupsServiceSubscription = this.groupsService
        .getAllGroups(this.searchText, this.user.id)
        .subscribe(async (result) => {
          assignedGroups = GeneralUtil.searchArray(allAssignments, this.searchText, 'name') //TODO: save this key in constants
          if (!result.isLoading && result.groups) {
            let orderedGroups = result.groups
            orderedGroups = !this.isAzureUser ? this.filterGroups(orderedGroups) : orderedGroups
            this.assignedGroups = [...this.assignedGroups, ...assignedGroups, ...orderedGroups]
            // removing duplicate groups
            this.assignedGroups = GeneralUtil.removeDuplicates(this.assignedGroups)
            this.assignedGroups = this.assignedGroups.filter(
              (obj) => !allAssignments.some((item) => item['id'] === obj.id && !obj.isSelected)
            )
            if (this.assignedGroups.length < 25) {
              if (!result.endOfList) {
                this.currentPageIndex += 1
                this.groupsService
                  .setAllGroups(this.searchText, this.user.id, 25, 25 * this.currentPageIndex)
                  .subscribe()
                return
              }
            }
          }

          this.isLoading = result.isLoading
          this.endOfList = result.endOfList
          if (this.endOfList) {
            this.currentPageIndex = 0
          }
        })
    })

    this.searchTextChanged
      .pipe(
        debounceTime(400),
        switchMap((result) => {
          this.searchText = result
          this.isLoading = true
          this.assignedGroups = []
          return this.groupsService.setAllGroups(result, this.user.id, 25, 0)
        })
      )
      .subscribe()
  }

  ngAfterContentChecked() {
    if (this.search) {
      this.search.nativeElement.focus()
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.groupsServiceSubscription.unsubscribe()
    this.assignedGroups = []
    this.endOfList = false
    this.isLoading = true
  }

  onScroll() {
    if (this.endOfList == undefined || this.endOfList || this.isLoading) return
    this.isLoading = true
    this.currentPageIndex += 1
    this.groupsService
      .setAllGroups(this.searchText, this.user.id, 25, 25 * this.currentPageIndex)
      .subscribe()
  }

  onSelection(e, v) {
    let currentAssignedGroups = this.assignedGroups.filter((g) => g.isSelected)
    let newAssignments = v.selected.map((g) => {
      return g.value
    })
    this.groupsService.draftAssignedGroups(currentAssignedGroups, newAssignments)
    this.usersService.saveUserDetails().subscribe(async () => {
      await this.groupsService.saveDraftedAssignedGroups()
    })
  }

  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue)
  }
}
