import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSort } from '@angular/material/sort'
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table'
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator'
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog'
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '../../../app.config'
import { Subscription } from 'rxjs'
import { PrinterProfileService } from '../../../modules/shared/services/printer-profile.service'
import { LangaugeService } from '../../../modules/shared/services/langauge.service'
import { ProfileDetailsDialogComponent } from './profile-details-dialog/profile-details-dialog.component'
import { RemoveProfileDialogComponent } from './remove-profile-dialog/remove-profile-dialog.component'
import { ErrorService } from '../../../modules/shared/services/error.service'
import { Printer, PrintersService } from '../../../modules/shared/services/printing-api.service'
import { GeneralUtil } from '../../../modules/shared/utils/general-util'
import { NetworkPrintersPrintersService } from '../../../modules/shared/services/network-printers-printers.service'

@Component({
  selector: 'ezd-printer-profile',
  templateUrl: './printer-profiles.component.html',
  styleUrls: ['./printer-profiles.component.scss'],
})
export class PrinterProfilesComponent implements OnInit {
  columnDefs = ['select', 'name', 'description', 'properties', 'printers', 'edit', 'delete']
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  maxPageLength = 100
  defaultPageSize = DEFAULT_PAGE_SIZE
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS
  pageIndex: number
  pageSize: number = DEFAULT_PAGE_SIZE
  isLoading = true
  sorting: string
  profilesServiceSubscription: Subscription
  profilesDataSource = new MatTableDataSource([])
  preferredLanguage: any
  printersCount: any
  selectedProfileCount = 0
  showMessageNoPrinters: boolean
  tooltipText: string
  GeneralUtil = GeneralUtil

  constructor(
    public profilesService: PrinterProfileService,
    public printersService: PrintersService,
    public dialog: MatDialog,
    public languageService: LangaugeService,
    public errorService: ErrorService,
    public networkPrintersPrintersService: NetworkPrintersPrintersService
  ) {}

  ngOnInit() {
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value
    })
    this.networkPrintersPrintersService.getFilteredNetworkPrintersPrinters().subscribe((result) => {
      this.showMessageNoPrinters = result.count == 0
    })
    this.profilesDataSource.sort = this.sort
    this.profilesService.setPage(0, this.defaultPageSize, true)
    this.profilesServiceSubscription = this.profilesService.getFilteredProfiles().subscribe(
      (result) => {
        if (!result.profiles) return
        this.isLoading = result.isLoading
        this.profilesDataSource.data = result.profiles
        this.updatedSelectedProfileCount()
        this.maxPageLength = result.count
      },
      (error) => {
        this.errorService.openTranslate(error, 'ERRORS.GENERIC')
      }
    )

    this.profilesService.applyFilters()
  }

  ngOnDestroy(): void {
    if (this.profilesServiceSubscription) this.profilesServiceSubscription.unsubscribe()
  }

  openProfileDetailsDialog(profile: any | null = null) {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = false
    dialogConfig.width = '600px'
    dialogConfig.panelClass = 'ezp-dialog'
    dialogConfig.data = profile

    this.dialog.open(ProfileDetailsDialogComponent, dialogConfig)
  }

  public onPaginationChange(e) {
    this.pageIndex = e.pageIndex
    this.pageSize = e.pageSize
    this.profilesService.setPage(this.pageIndex, this.pageSize)
  }

  sortChange(e) {
    const direction = e.direction === 'desc' ? '-name' : e.direction === 'asc' ? 'name' : undefined
    if (direction) {
      this.sorting = direction
    }

    this.profilesService.setSorting(this.sorting)
  }

  selectProfile() {
    this.updatedSelectedProfileCount()
  }

  selectAllVisiblePrinters($event) {
    this.profilesDataSource.data.forEach((item) => (item.isSelected = $event.checked))
    this.updatedSelectedProfileCount()
  }

  openProfileWarning(profile?) {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = false
    dialogConfig.width = '400px'
    dialogConfig.panelClass = 'ezp-dialog'
    if (profile) dialogConfig.data = [profile]
    else dialogConfig.data = this.profilesDataSource.data.filter((item) => item.isSelected === true)
    this.dialog.open(RemoveProfileDialogComponent, dialogConfig)
  }

  updatedSelectedProfileCount() {
    this.selectedProfileCount = this.profilesDataSource.data.filter(
      (item) => item.isSelected === true
    ).length
  }

  showTooltip() {
    if (this.preferredLanguage == 'en') {
      this.tooltipText = 'Assigned printer was removed.'
    }
    if (this.preferredLanguage == 'de') {
      this.tooltipText = 'Zugewiesener Drucker wurde entfernt.'
    }
  }
}
