import { Component, OnInit } from '@angular/core';
import { ConnectorsFiltersService } from '../../../modules/shared/services/connectors-filters.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ezd-connectors-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class ConnectorsSearchComponent implements OnInit {
  searchTextChanged = new Subject<string>();

  constructor(private networkPrintersConnectorsFiltersService: ConnectorsFiltersService) { 
    this.networkPrintersConnectorsFiltersService.setSearchText(undefined);
    this.searchTextChanged
      .pipe(
        debounceTime(400))
      .subscribe(result => {
        this.networkPrintersConnectorsFiltersService.setSearchText(result);
      })
  }

  ngOnInit() {
  }

  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue);
    // this.networkPrintersConnectorsFiltersService.setSearchText(searchValue);
  }
}
