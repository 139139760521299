<div
  class="ezp-grid ezp-grid--gutter-horizontal ezp-grid--spacing-vertical"
  data-grid-cols="1"
>
  <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
    <mat-label id="user-search-groups-text">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_GROUPS.SEARCH_GROUPS' | translate }}</mat-label>
    <input matInput id="user-group-name" #searchGroups [placeholder]="'USERSGROUPS.USERS.USER_DETAILS.USER_GROUPS.GROUP_NAME' | translate" (input)="onSearchChange($event.target.value)" />
  </mat-form-field>
</div>
<ezd-infinity-scroll (scrolled)="onScroll()" style="height: 300px; overflow-y: auto">
  <mat-selection-list #groups class="ezp-list ezp-list--filterable" (selectionChange)="onSelection($event, groups.selectedOptions)">
    <mat-list-option
      *ngFor="let group of assignedGroups"
      checkboxPosition="before"
      color="primary"
      [selected]="group.isSelected"
      [disabled]="group.origin.provider !== 'Ezeep'"
      [value]="group"
      class="ezp-list--disabled"
      [id]="group.name"
    >
    <div class="ezp-list__item">
      <div [id]="group.name">{{ group.name }}</div>
      <div class="ezp-list__item__right-content" id="users-azure-group-text" *ngIf="group.origin.provider === 'Azure'">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_GROUPS.AZURE_GROUP' | translate }}</div>
      <div class="ezp-list__item__right-content" id="users-azure-group-text" *ngIf="group.origin.provider === 'OfficeRnD'">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_GROUPS.OFFICERND_GROUP' | translate }}</div>
      <div class="ezp-list__item__right-content" id="users-azure-group-text" *ngIf="group.origin.provider === 'Cobot'">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_GROUPS.COBOT_GROUP' | translate }}</div>
    </div>
    </mat-list-option>
  </mat-selection-list>
  <div class="ezp-list__fetching" *ngIf="isLoading">
    <mat-spinner
      class="ezp-list__fetching__spinner ezp-list__fetching__spinner--selection"
    ></mat-spinner>
    <span class="ezp-list__fetching__title" id="users-fetching-groups-text">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_GROUPS.FETCHING_GROUPS' | translate }}</span>
  </div>
</ezd-infinity-scroll>
