import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { FiltersService } from './printing-api.service';
import { map } from 'rxjs/operators';

export enum FILTER_CATEGORIES {
  TYPE = 'type',
  STATUS = 'status',
  CONNECTOR_ID = 'Connector'
}

@Injectable()
export class ConnectorsFiltersService {
  selectedFilters = [];
  selectedFilterSubject = new BehaviorSubject<any[]>(this.selectedFilters);
  searchText: string;

  private filterNameSource = new BehaviorSubject<string>('');
  currentFilterName = this.filterNameSource.asObservable();

  constructor(private router: Router,
    private filtersService: FiltersService) {
  }

  public getFilters(): Observable<any> {
    return this.filtersService.list("connector").pipe(map(response => {
      let result = response.result.result.filter(r => {
        // if (r.field_name == "status") {
        //   r.values = r.values.map(v => {
        //     if (v == "ON") { return "Online"; }
        //     if (v == "OFF") { return "Offline"; }
        //     else { v; }
        //   });
        // }
        if (r.field_name == "type") {
          r.values = r.values.map(v => {
            if (v == "Printserver") { return "Print server"; }
            if (v == "TPHub") { return "ThinPrint Hub"; }
            if (v == "ezeepHub") { return "ezeep Hub"; }
            else { return v; }
          });
        }
        //EFA-1077 removed status filter temporary
        if (r.field_name != "status")
          return r;
      });
      return result;
    }));
  }

  public getSelectedFilters(): Observable<any> {
    return this.selectedFilterSubject.asObservable();
  }

  public getSearchText(): string {
    return this.searchText;
  }

  public setSearchText(searchText: string) {
    this.searchText = searchText != null ? searchText.toLowerCase() : '';
    this.selectedFilterSubject.next(this.selectedFilters);
  }

  addFilter(fieldName, filterName, value) {
    const filterToAdd = {field_name: fieldName, name: filterName, value: value};
    const sameCategoryAndNameFilters = this.selectedFilters.filter(filter => filter.field_name === filterToAdd.field_name && filter.name === filterToAdd.name);
    if (!sameCategoryAndNameFilters.length) {
      this.selectedFilters.push(filterToAdd);
    }
    this.selectedFilterSubject.next(this.selectedFilters);
  }

  removeFilter(fieldName, filterName) {
    this.selectedFilters = this.selectedFilters.filter(filter => !(filter.field_name === fieldName && filter.name === filterName));
    this.selectedFilterSubject.next(this.selectedFilters);
  }

  removeAllFilters() {
    this.selectedFilters = [];
    this.selectedFilterSubject.next(this.selectedFilters);
  }

  updateFilterName(filterName: string): void {
    this.filterNameSource.next(filterName);
  }
}
