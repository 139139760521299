import { NgModule, APP_INITIALIZER } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationComponent } from './modules/authentication/authentication.component';
import { ErrorPageComponent } from './pages/error/error.component'
import { LocalPrinterSupportComponent } from './pages/localprintersupport/localprintersupport.component'
import { QuickStartComponent } from './pages/quickstart/quickstart.component'
import { PrintersComponent } from './pages/printers/printers.component'
import { AuthenticationEzeepAdminGuard } from './modules/authentication/guards/authenticationEzeepAdmin.guard'
import { UsersgroupsComponent } from './pages/usersgroups/usersgroups.component'
import { SubscriptionComponent } from './pages/subscription/subscription.component'
import { AuthenticationEzeepCommonUserGuard } from './modules/authentication/guards/authenticationEzeepCommonUser.guard'
import { MyPrintersComponent } from './pages/printers/myprinters/myprinters.component'
import { DownloadsComponent } from './pages/downloads/downloads.component'
import { PoliciesComponent } from './pages/policies/policies.component'
import { LoginSuccessfulComponent } from './pages/loginsuccessful/loginsuccessful.component'
import { PullprintingComponent } from './pages/pullprinting/pullprinting.component'
import { PrintjobInstructionsComponent } from './pages/printjob-instructions/printjob-instructions.component'
import { ReportingComponent } from './pages/reporting/reporting.component'
import { ConnectorsComponent } from './pages/connectors/connectors.component'
import { PrinterProfilesComponent } from './pages/printers/printer-profiles/printer-profiles.component'
import { CanDeactivateGuard } from './pages/printers/guards/can-deactivate-printer.guard'
import { SettingsComponent } from './pages/settings/settings.component'
import { AutomationsComponent } from './pages/automations/automations.component'
import { DocsComponent } from './pages/documents/documents.component'

const ROUTES: Routes = <Routes>[
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'printers',
    preserveFragment: true,
    queryParamsHandling: 'preserve',
  },
  {
    component: AuthenticationComponent,
    path: 'authentication',
  },
  { 
    path: 'welcome', 
    redirectTo: 'loginsuccessful' 
  },
  {
    path: 'localprintersupport',
    canActivate: [AuthenticationEzeepAdminGuard],
    component: LocalPrinterSupportComponent,
  },
  {
    path: 'quickstart',
    canActivate: [AuthenticationEzeepAdminGuard],
    component: QuickStartComponent,
  },
  {
    path: 'printers',
    canActivate: [AuthenticationEzeepAdminGuard],
    component: PrintersComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'usersgroups',
    canActivate: [AuthenticationEzeepAdminGuard],
    component: UsersgroupsComponent,
  },
  {
    path: 'subscription',
    canActivate: [AuthenticationEzeepAdminGuard],
    component: SubscriptionComponent,
  },
  {
    path: 'myprinters',
    canActivate: [AuthenticationEzeepAdminGuard, AuthenticationEzeepCommonUserGuard],
    component: MyPrintersComponent,
  },
  {
    path: 'connectors',
    canActivate: [AuthenticationEzeepAdminGuard],
    component: ConnectorsComponent,
  },
  {
    path: 'downloads',
    component: DownloadsComponent,
  },
  {
    path: 'policies',
    canActivate: [AuthenticationEzeepAdminGuard],
    component: PoliciesComponent,
  },
  {
    path: 'profiles',
    canActivate: [AuthenticationEzeepAdminGuard],
    component: PrinterProfilesComponent,
  },
  {
    path: 'settings',
    canActivate: [AuthenticationEzeepAdminGuard],
    component: SettingsComponent,
  },
  {
    path: 'documents',
    canActivate: [AuthenticationEzeepAdminGuard],
    component: DocsComponent,
  },
  {
    path: 'automations',
    canActivate: [AuthenticationEzeepAdminGuard],
    component: AutomationsComponent,
  },
  {
    path: 'reporting',
    canActivate: [AuthenticationEzeepAdminGuard],
    component: ReportingComponent,
  },
  {
    path: 'mycard',
    canActivate: [AuthenticationEzeepAdminGuard, AuthenticationEzeepCommonUserGuard],
    component: PullprintingComponent,
  },
  {
    path: 'pullprinting/releasejobs/',
    component: PrintjobInstructionsComponent,
  },
  {
    path: 'loginsuccessful',
    canActivate: [AuthenticationEzeepAdminGuard, AuthenticationEzeepCommonUserGuard],
    component: LoginSuccessfulComponent,
  },
  {
    path: 'error',
    component: ErrorPageComponent,
  },
]

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { paramsInheritanceStrategy: 'always' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
