import { Component, OnInit, Optional, Inject } from '@angular/core'
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog'
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips'
import { COMMA, ENTER, SPACE, SEMICOLON } from '@angular/cdk/keycodes'
import {
  UntypedFormGroup,
  AbstractControl,
  Validators,
  FormControl,
  FormArray,
  UntypedFormBuilder,
} from '@angular/forms'
import { UsersgroupsUserInvitationsService } from '../../../../modules/shared/services/usersgroups-user-invitations.service'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { UsersgroupsUsersService } from '../../../../modules/shared/services/usersgroups-users.service'
import { GeneralUtil } from '../../../../modules/shared/utils/general-util'
import { ErrorService } from '../../../../modules/shared/services/error.service'
import { QuickstartInviteUsersComponent } from '../quickstart-invite-users/quickstart-invite-users.component'
import { SubscriptionApiService } from '../../../../modules/shared/services/subscription-api.service'
import { AuthUserApiService } from '../../../../modules/shared/services/auth-api.service'
import { LangaugeService } from '../../../../modules/shared/services/langauge.service'
import { DateAdapter } from '@angular/material/core'

export interface Invitation {
  email: string
  invalid?: boolean
}

@Component({
  selector: 'ezd-invite-users-dialog',
  templateUrl: './invite-users-dialog.component.html',
  styleUrls: ['./invite-users-dialog.component.scss'],
})
export class InviteUsersDialogComponent implements OnInit {
  startDate: Date
  minDate: Date
  expirationDateLabel: any = 30
  selectable = true
  removable = true
  addOnBlur = true
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE, SEMICOLON]
  invitations: Invitation[] = []
  expirationDate: Date
  isLoading: boolean = false
  invitationsForm: UntypedFormGroup
  fb: UntypedFormBuilder = new UntypedFormBuilder()
  inviteUserQuery: any
  subscriptionStatus: any
  maxUsers: number
  usersCount: number
  planQuantity: number
  status: string
  defaultPreferredLanguage: string
  preferredLanguage: string
  sourceType: string
  ezeepLink: string

  constructor(
    public dialogRef: MatDialogRef<InviteUsersDialogComponent>,
    private userInvitationService: UsersgroupsUserInvitationsService,
    private usersService: UsersgroupsUsersService,
    private subscriptionApiService: SubscriptionApiService,
    private authApiService: AuthUserApiService,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private languageService: LangaugeService,
    private dateAdapter: DateAdapter<any>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.inviteUserQuery = this.data.data
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.defaultPreferredLanguage = value
    })
    this.dateAdapter.setLocale(this.defaultPreferredLanguage)
  }

  ngOnInit() {
    this.startDate = new Date()
    this.startDate.setDate(this.startDate.getDate() + 30)
    this.expirationDate = this.startDate
    let minDate = new Date()
    minDate.setDate(minDate.getDate() + 1)
    this.minDate = minDate
    this.invitationsForm = this.fb.group({
      emails: this.fb.array([]),
    })
    this.subscriptionApiService.read('-created').subscribe((response) => {
      this.subscriptionStatus = response.result.result[0]
      this.maxUsers = this.subscriptionStatus.features.max_users
      this.planQuantity = this.subscriptionStatus.source.subscription.plan_quantity
      this.status = this.subscriptionStatus.status
      this.sourceType = this.subscriptionStatus.source_type
      this.ezeepLink = this.subscriptionStatus.source.ezeep_link

      if (!this.subscriptionStatus) return
    })
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value
    })
    this.authApiService.list(undefined, undefined, 1, undefined).subscribe((response) => {
      this.usersCount = response.result.count
    })
  }

  addInvitation(event: MatChipInputEvent): void {
    const input = event.input
    const value = event.value

    if ((value || '').trim()) {
      if (this.validateEmail((value || '').trim())) {
        this.invitations.push({ email: value.trim(), invalid: false })
      } else {
        this.invitations.push({ email: value.trim(), invalid: true })
        this.invitationsForm.controls['emails'].setErrors({ incorrectEmail: true })
      }
    }
    if (input) {
      input.value = ''
    }
  }

  removeInvitation(invitation: Invitation): void {
    let checkInvalid = false
    const index = this.invitations.indexOf(invitation)

    if (index >= 0) {
      this.invitations.splice(index, 1)
    }

    this.invitations.forEach((invite) => {
      if (invite.invalid) {
        checkInvalid = true
      }
    })

    if (!checkInvalid) {
      this.invitationsForm.controls['emails'].clearValidators()
      this.invitationsForm.controls['emails'].updateValueAndValidity()
    }
  }

  expirationDateChange(event: MatDatepickerInputEvent<Date>) {
    this.expirationDate = event.value
    let dateNow = new Date()
    dateNow.setHours(0, 0, 0, 0)
    let totalDays = Math.round(
      Math.abs(event.value.getTime() - dateNow.getTime()) / (1000 * 60 * 60 * 24)
    )
    this.expirationDateLabel = totalDays
  }

  onLanguageChange(event: any) {
    this.preferredLanguage = event.value
  }

  invite() {
    this.isLoading = true
    this.userInvitationService
      .sendInvitation(
        this.invitations.map((x) => x.email),
        this.expirationDate,
        this.preferredLanguage
      )
      .subscribe({
        next: async () => {
          await GeneralUtil.sleep(600)
          this.usersService.applyFilters(true)
          this.dialogRef.close()
          this.isLoading = false
          if (this.inviteUserQuery) {
            this.openQuickstartMyPrintersDialog()
          }
        },
        error: (error) => {
          if (error.status == 400) {
            this.errorService.openTranslate(undefined, 'ERRORS.INVALID_EMAIL')
          } else {
            // this.errorService.openTranslate(error, 'ERRORS.GENERIC')
          }
          this.isLoading = false
        },
      })
  }

  close() {
    this.dialogRef.close()
  }

  isToday(someDate: Date) {
    const today = new Date()
    return (
      someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
    )
  }

  paste(event: ClipboardEvent): void {
    event.preventDefault() //Prevents the default action of addInvitation
    event.clipboardData
      .getData('Text')
      .split(/;|,|\n|\s/)
      .forEach((value) => {
        if (
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            String(value).toLowerCase()
          )
        ) {
          this.invitations.push({ email: value.trim(), invalid: false })
        } else {
          this.invitations.push({ email: value.trim(), invalid: true })
          this.invitationsForm.controls['emails'].setErrors({ incorrectEmail: true })
        }
      })
  }

  validateEmail(email) {
    let regExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regExp.test(String(email).toLowerCase())
  }

  openQuickstartMyPrintersDialog() {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = false
    dialogConfig.width = '600px'
    dialogConfig.panelClass = 'ezp-dialog'

    this.dialog.open(QuickstartInviteUsersComponent, dialogConfig)
  }
}
