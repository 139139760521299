<div class="ezp-layout__page">
  <div class="ezp-layout__content">
    <div class="header">
      <h1 class="title mat-headline">{{ 'AUTOMATIONS_PAGE.TITLE' | translate }}</h1>
    </div>
    <div class="embed">
      <zapier-full-experience
        [attr.client-id]="zapierClientId"
        theme="light"
        intro-copy-display="show"
        app-search-bar-display="show"
      ></zapier-full-experience>
    </div>
  </div>
</div>
