<aside class="layout__dialog">
    <div class="layout__dialog__body" style="width: 600px;">
      <header class="layout__dialog__head layout__dialog__head--split">
        <h3 class="mat-title efa-text efa-text--no-spacing efa-text--he" id="delete-connector-title">
            {{ 'CONNECTORS.CONNECTORS_PROPERTIES.DELETE_CONNECTOR.TITLE' | translate }}
        </h3>
        <button mat-icon-button class="layout__dialog__close" id="delete-connector-close" (click)="cancel()">
          <mat-icon class="efa-icon efa-icon--me">close</mat-icon>
        </button>
      </header>
      <section class="layout__dialog__content">
        <section class="layout__dialog__part">
          <h3 class="mat-body efa-text efa-text--no-spacing efa-text--he" id="delete-connector-desc-one">
            {{ 'CONNECTORS.CONNECTORS_PROPERTIES.DELETE_CONNECTOR.DESCRIPTION_1' | translate }}
          </h3>
          <h3 class="mat-body efa-text efa-text--no-spacing efa-text--he" id="delete-connector-desc-two">
              {{ 'CONNECTORS.CONNECTORS_PROPERTIES.DELETE_CONNECTOR.DESCRIPTION_2' | translate }}
          </h3>
          <h3 class="mat-body efa-text efa-text--no-spacing efa-text--he" id="delete-connector-desc-three">
              {{ 'CONNECTORS.CONNECTORS_PROPERTIES.DELETE_CONNECTOR.DESCRIPTION_3' | translate }}
          </h3>
        </section>
      </section>
      <footer class="layout__dialog__foot">
        <button mat-button color="accent" class="efa-button" id="cancel-alert-delete-connector" (click)="cancel()">
          <mat-icon>cancel</mat-icon>
          {{ 'CANCEL' | translate }}
        </button>
        <button mat-raised-button color="accent" class="efa-button" id="continue-alert-delete-connector" (click)="delete()" [disabled]="isLoading">
          <mat-icon [ngClass]="{ 'fa-spin': isLoading }">{{ !isLoading ? 'delete' : 'cached' }}</mat-icon>
          {{ 'CONTINUE' | translate }}
        </button>
      </footer>
    </div>
  </aside>