import { Component, EventEmitter, Inject, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '../../../../../app.config';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EndpointEventService } from '../../../../../modules/shared/services/endpoint-events.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DATE_LOCALIZATION, ERROR_CODES, TRIGGERS_LOCAL } from '../../constants';
import { LangaugeService } from '../../../../../modules/shared/services/langauge.service';
import { GeneralUtil } from '../../../../../modules/shared/utils/general-util';
@Component({
  selector: 'ezd-failed-events',
  templateUrl: './failed-events.component.html',
  styleUrls: ['./failed-events.component.scss']
})
export class FailedEventsComponent implements OnInit {

  columnDefs = ['date', 'name', 'triggers', 'retry'];
  failedEventsTableDataSource = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  maxPageLength = 100;
  isLoading = false;
  defaultPageSize = DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  pageIndex: number
  endPointServiceSubscription: Subscription
  defaultPreferredLanguage: string;
  triggers_localization = TRIGGERS_LOCAL;
  error_codes = ERROR_CODES;
  isRetryAllInProgress = false
  getRetryInterval;
  sorting: string;
  @Output() eventCount: EventEmitter<number> = new EventEmitter<number>();

  constructor(private endpointEventService: EndpointEventService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogueData: any, private languageService: LangaugeService) { }

  async ngOnInit(): Promise<void> {

    this.endpointEventService.endpoint_id = this.dialogueData.webhook.endpoint_id;
    this.failedEventsTableDataSource.sort = this.sort;
    this.endpointEventService.setPage(0, this.defaultPageSize, true);
    this.getRetryInterval = setInterval(async () => {
      try {
        await lastValueFrom(this.endpointEventService.getRetryall());
        this.isRetryAllInProgress = true;
      } catch (error) {
        clearInterval(this.getRetryInterval);
        this.handleRetryAllCompletion();
      }
    }, 2000);


    try {
      await lastValueFrom(this.endpointEventService.getRetryall());
      this.isRetryAllInProgress = true;
    } catch (error) {
      this.isRetryAllInProgress = false;
    }
    this.fetchEvents();

  }

  fetchEvents() {
    this.endPointServiceSubscription = this.endpointEventService.getEndPointEvents()
      .subscribe(result => {
        if (!result.events) return;
        this.isLoading = result.isLoading;
        this.addDisabledPropertyToEvents(result.events)
        this.failedEventsTableDataSource.data = result.events.map(event => this.isRetryAllInProgress ? { ...event, isDisabled: true } : { ...event, isDisabled: false });
        this.maxPageLength = result.count;
        // emitting event back to webhook editor to dislay the count of events. 
        this.eventCount.emit(result.count)

      });
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.defaultPreferredLanguage = value;
    })
    this.endpointEventService.applyFilters();
  }

  public onPaginationChange(e) {
    this.pageIndex = e.pageIndex;
    this.defaultPageSize = e.pageSize;
    this.endpointEventService.setPage(this.pageIndex, this.defaultPageSize);
  }

  sortChange(e) {
    const direction = e.direction === 'desc' ? '-created' : e.direction === 'asc' ? 'created' : undefined;
    if (direction) {
      this.sorting = direction
    }
    this.endpointEventService.setSorting(this.sorting);
  }

  formatDate(value: Date) {
    return GeneralUtil.formatDateHoursAndMinutes(value, DATE_LOCALIZATION[this.defaultPreferredLanguage])
  }

  retry(event_id: string) {
    const updatedData = this.failedEventsTableDataSource.data.map(ev => {
      if (ev.event_id === event_id) {
        return { ...ev, isRetrying: true };
      }
      return ev;
    });
    this.failedEventsTableDataSource.data = updatedData;

    this.endpointEventService.retryEvent(event_id).subscribe({
      next: result => {
        this.endpointEventService.applyFilters();
      }, error: error => {
        console.error(error)
        this.endpointEventService.applyFilters();
      }
    })
  }

  ngOnDestroy() {
    if (this.endPointServiceSubscription)
      this.endPointServiceSubscription.unsubscribe()

    if (this.getRetryInterval)
      clearInterval(this.getRetryInterval);
    // added here to make the events 0 when the service is done.
    this.endpointEventService.endPointEventsSubject.next({ isLoading: false, count: 0, events: [] })
  }

  retryAll() {
    if (this.getRetryInterval)
      clearInterval(this.getRetryInterval);

    this.isRetryAllInProgress = true;
    this.addDisabledPropertyToEvents(this.failedEventsTableDataSource.data);
    this.endpointEventService.retryAll().subscribe({
      next: data => {
        // this.endpointEventService.applyFilters();
        this.getRetryInterval = setInterval(async () => {
          try {
            await lastValueFrom(this.endpointEventService.getRetryall());
          } catch (error) {
            clearInterval(this.getRetryInterval);
            this.handleRetryAllCompletion();
          }
        }, 2000);
      },
      error: error => {
        this.handleError(error);
      }
    });
  }


  private addDisabledPropertyToEvents(events) {
    this.failedEventsTableDataSource.data = events.map(event => this.isRetryAllInProgress ? { ...event, isDisabled: true } : { ...event, isDisabled: false });
  }

  // Handle successful completion of retry all
  private handleRetryAllCompletion() {
    clearInterval(this.getRetryInterval);
    this.getRetryInterval = undefined;
    this.isRetryAllInProgress = false;
    this.endpointEventService.applyFilters();
  }

  // Handle retry all error
  private handleError(error: any) {
    this.isRetryAllInProgress = false;
    this.endpointEventService.applyFilters();
  }
}
