import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ezd-incorrect-admin',
  templateUrl: './incorrect-admin.component.html',
  styleUrls: ['./incorrect-admin.component.scss']
})
export class IncorrectAdminComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
