import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthOrganization, AuthOrganizationApiService } from './auth-api.service';

@Injectable({
  providedIn: 'root'
})
export class AppComponentService {

  constructor(private authOrganizationService: AuthOrganizationApiService) { }

  getOrganizations(page_size:number, page_index:number) : Observable<any> {
    let offset = page_index * page_size
    return this.authOrganizationService.list( page_size, offset)
      .pipe(map(async response => {
        let orgs = []
        if(response.result.next) {
          let nextIndex = page_index + 1
          orgs = await lastValueFrom(this.getOrganizations(page_size, nextIndex)) // // .toPromise()
        }
        return [...response.result.results, ...orgs];
      }));
  }
}
