<mat-icon class="icon">{{ type }}</mat-icon>
<div class="body">
  <div class="title">
    <div class="title__bar bar"></div>
  </div>
  <div class="info">
    <div class="meta">
      <div class="meta__bar meta__bar--leading bar"></div>
      <div class="meta__bar meta__bar--trailing bar"></div>
    </div>
    <div class="meta">
      <div class="meta__bar meta__bar--leading bar"></div>
      <div class="meta__bar meta__bar--trailing bar"></div>
    </div>
  </div>
</div>
