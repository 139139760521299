<h2 mat-dialog-title class="title">{{ 'PRINTERS.MY_PRINTERS.WELCOME.TITLE' | translate }}</h2>
<div class="steps">
  <div class="step">
    <div class="step__title mat-subheading-2">
      {{ 'PRINTERS.MY_PRINTERS.WELCOME.ENABLE_PRINTER' | translate }}
    </div>
    <ctd-animation
      url="/assets/animations/enable-printer.json"
      width="224px"
      height="224px"
    ></ctd-animation>
  </div>
  <div class="step">
    <div class="step__title mat-subheading-2">
      {{ 'PRINTERS.MY_PRINTERS.WELCOME.GET_PRINT_APP' | translate }}
    </div>
    <ctd-animation
      url="/assets/animations/get-print-app.json"
      width="224px"
      height="224px"
    ></ctd-animation>
    <button mat-flat-button color="primary" class="action" (click)="goToDownloads()">{{ 'PRINTERS.MY_PRINTERS.WELCOME.DOWNLOAD_PRINT_APP' | translate }}</button>
  </div>
</div>

<div mat-dialog-actions class="ezp-splash__actions ezp-splash__actions--spread">
  <mat-checkbox
    class="ezp-checkbox ezp-checkbox--invert ezp-checkbox--strong"
    [checked]="dontShowAgain"
    (change)="toggleShowAgain()"
    >{{ 'PRINTERS.MY_PRINTERS.WELCOME.DO_NOT_SHOW_AGAIN' | translate }}</mat-checkbox
  >
  <button (click)="close()" class="ezp-button ezp-button--custom ezp-button--custom--translucent">
    {{ 'GOT_IT' | translate }}
  </button>
</div>
