import { Observable } from 'rxjs'
import {
  ApiResponse,
  ApiResult,
  ListResult,
  RequesterApiService,
  reportingApiUrl,
} from './requester-api.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Inject, Injectable, Optional } from '@angular/core'
import { EZEEP_REPORTING_API } from '../../../app.config'

export interface IEventsApiService {
  list(
    endpointId: string,
    order: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<EventData>>>
  read(endpointId: string, event_id: string): Observable<ApiResponse<EventData>>
  retry(endpoint_id: string, event_id: string): Observable<ApiResponse<Retry>>
  retry_all(endpoint_id: string): Observable<ApiResponse<Retry_All>>
  read_retry_all(endpoint_id: string): Observable<ApiResponse<Retry_All>>
  delete_retry_all(endpoint_id: string): Observable<ApiResponse<void>>
}

@Injectable({
  providedIn: 'root',
})
export class EventDataApiService extends RequesterApiService implements IEventsApiService {
  httpClient: any

  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(reportingApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  list(
    endpointId: string,
    order: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<EventData>>> {
    let url_ = EZEEP_REPORTING_API + '/webhooks/endpoints/{endpointId}/events?'

    if (order !== undefined) url_ += 'order=' + encodeURIComponent('' + order) + '&'
    if (limit !== undefined) url_ += 'limit=' + encodeURIComponent('' + limit) + '&'
    if (offset !== undefined) url_ += 'offset=' + encodeURIComponent('' + offset) + '&'
    if (endpointId === undefined || endpointId === null)
      throw new Error("The parameter 'endpointId' must be defined.")
    url_ = url_.replace('{endpointId}', encodeURIComponent('' + endpointId))
    url_ = url_.replace(/[?&]$/, '')

    let options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    }

    return this.processListRequest('get', url_, options_, EventData)
  }

  read(endpoint_id: string, event_id: string): Observable<ApiResponse<EventData>> {
    let url_ = EZEEP_REPORTING_API + '/webhooks/endpoints/{endpoint_id}/events/{event_id}/'
    if (
      endpoint_id === undefined ||
      endpoint_id === null ||
      event_id === undefined ||
      event_id === null
    )
      throw new Error("The parameter 'endpoint_id' and 'event_id' must be defined.")
    url_ = url_.replace('{endpoint_id}', encodeURIComponent('' + endpoint_id))
    url_ = url_.replace('{event_id}', encodeURIComponent('' + event_id))
    url_ = url_.replace(/[?&]$/, '')

    let options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    }

    return this.processRequest('get', url_, options_, EventData)
  }

  retry(endpoint_id: string, event_id: string): Observable<ApiResponse<Retry>> {
    let url_ = EZEEP_REPORTING_API + '/webhooks/endpoints/{endpoint_id}/events/{event_id}/retry'
    if (
      endpoint_id === undefined ||
      endpoint_id === null ||
      event_id === undefined ||
      event_id === null
    )
      throw new Error("The parameter 'endpoint_id' and 'event_id' must be defined.")
    url_ = url_.replace('{endpoint_id}', encodeURIComponent('' + endpoint_id))
    url_ = url_.replace('{event_id}', encodeURIComponent('' + event_id))
    url_ = url_.replace(/[?&]$/, '')

    let options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    }

    return this.processRequest('put', url_, options_, Retry)
  }

  retry_all(endpoint_id: string): Observable<ApiResponse<Retry_All>> {
    let url_ = EZEEP_REPORTING_API + '/webhooks/endpoints/{endpoint_id}/events/retry/'
    if (endpoint_id === undefined || endpoint_id === null)
      throw new Error("The parameter 'endpoint_id' must be defined.")
    url_ = url_.replace('{endpoint_id}', encodeURIComponent('' + endpoint_id))
    url_ = url_.replace(/[?&]$/, '')

    let options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    }

    return this.processRequest('put', url_, options_, Retry_All)
  }

  read_retry_all(endpoint_id: string): Observable<ApiResponse<Retry_All>> {
    let url_ = EZEEP_REPORTING_API + '/webhooks/endpoints/{endpoint_id}/events/retry/'
    if (endpoint_id === undefined || endpoint_id === null)
      throw new Error("The parameter 'endpoint_id' must be defined.")
    url_ = url_.replace('{endpoint_id}', encodeURIComponent('' + endpoint_id))

    url_ = url_.replace(/[?&]$/, '')

    let options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    }

    return this.processRequest('get', url_, options_, Retry_All)
  }

  delete_retry_all(endpoint_id: string): Observable<ApiResponse<void>> {
    let url_ = EZEEP_REPORTING_API + '/webhooks/endpoints/{endpoint_id}/events/retry/'
    if (endpoint_id === undefined || endpoint_id === null)
      throw new Error("The parameter 'endpoint_id' must be defined.")
    url_ = url_.replace('{endpoint_id}', encodeURIComponent('' + endpoint_id))
    url_ = url_.replace(/[?&]$/, '')

    let options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({}),
    }

    return this.processRequest<null>('delete', url_, options_, null)
  }
}

export class EventData extends ApiResult implements IEventData {
  created: string | undefined
  endpoint:
    | {
        error: string | undefined
        last_attempt: string | undefined
        response_status_code: number | undefined
        status: string | undefined
      }
    | undefined
  event_id: string | undefined
  topic: string | undefined

  constructor(data?: IEventData) {
    super()
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).created = data['created']
      ;(<any>this).endpoint = data['endpoint']
      ;(<any>this).event_id = data['event_id']
      ;(<any>this).topic = data['topic']
    }
  }

  fromJS(data: any): EventData {
    data = typeof data === 'object' ? data : {}
    let result = new EventData()
    result.init(data)
    return result
  }
}

export interface IEventData {
  created: string
  endpoint: {
    error: string
    last_attempt: string
    response_status_code: number
    status: string
  }
  event_id: string
  topic: string
}

export class Retry extends ApiResult implements IRetry {
  error: string
  response_status_code: number
  status: string

  constructor(data?: IEventData) {
    super()
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).error = data['error']
      ;(<any>this).response_status_code = data['response_status_code']
      ;(<any>this).status = data['status']
    }
  }

  fromJS(data: any): EventData {
    data = typeof data === 'object' ? data : {}
    let result = new EventData()
    result.init(data)
    return result
  }
}

export interface IRetry {
  error: string
  response_status_code: number
  status: string
}

export class Retry_All extends ApiResult implements IRetryAll {
  created: Date
  constructor(data?: IRetryAll) {
    super()
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).created = data['created']
    }
  }

  fromJS(data: any): Retry_All {
    data = typeof data === 'object' ? data : {}
    let result = new Retry_All()
    result.init(data)
    return result
  }
}

export interface IRetryAll {
  created: Date
}
