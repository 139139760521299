import { Observable } from 'rxjs';
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequesterApiService, ApiResponse, printerApiUrl, ListResult, ApiResult } from './requester-api.service';

export interface IPrinterProfileAssignmentApiService {
  list(printer_id: string, type: string, limit: number, offset: number): Observable<ApiResponse<ListResult<GroupedPrinterPreset>>>;
  update(type: string, id: string, group_id: string): Observable<ApiResponse<any>>;
  delete(preset_id: string, id: string, type: string): Observable<ApiResponse<void>>;
}
@Injectable({
  providedIn: 'root'
})

export class PrinterProfileAssignmentApiService extends RequesterApiService implements IPrinterProfileAssignmentApiService {

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(printerApiUrl) baseUrl?: string) {
    super(http, baseUrl);
  }

  list(printer_id: string, type: string, limit: number | null | undefined, offset: number | null | undefined): Observable<ApiResponse<ListResult<GroupedPrinterPreset>>> {
    let url_ = this.baseUrl + `/v1/printers/{id}/printer_profiles/{type}/?limit=${limit}&offset=0`;

    if (printer_id === undefined || printer_id === null) {
      if (type == 'printers') {
        url_ = url_.replace("/{id}", "");
      } else
        throw new Error("The parameter 'printer_id' must be defined.");
    }

    url_ = url_.replace("{id}", encodeURIComponent("" + printer_id));
    url_ = url_.replace(/[?&]$/, "");
    if (type === undefined || type === null)
      throw new Error("The parameter 'type' must be defined.");
    url_ = url_.replace("{type}", encodeURIComponent("" + type));
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };

    return this.processListRequest("get", url_, options_, GroupedPrinterPreset)
  }

  update(type: string, printer_id: string, payload: any): Observable<ApiResponse<any>> {
    let url_ = this.baseUrl + "/v1/printers/{printer_id}/printer_profiles/" + type + "/";
    if (printer_id === undefined || printer_id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{printer_id}", encodeURIComponent("" + printer_id));
    url_ = url_.replace(/[?&]$/, "");

    const content_ = JSON.stringify(payload);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Cache-Control": "no-cache"
      })
    };

    return this.processRequest("post", url_, options_, undefined);
  }

  delete(printer_id: string, id: string, type: string): Observable<ApiResponse<void>> {
    let url_ = this.baseUrl + "/v1/printers/{printer_id}/printer_profiles/" + type + "/{id}";
    if (printer_id === undefined || printer_id === null)
      throw new Error("The parameter 'printer_id' must be defined.");
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{printer_id}", encodeURIComponent("" + printer_id));
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Cache-Control": "no-cache"
      })
    };

    return this.processRequest<null>("delete", url_, options_, null);
  }

}


interface IPrinterPreset {
  id: string;
  name: string;
  description: string;
}

export interface IGroupedPrinterPreset {
  user_id?: string;
  group_id?: string;
  printer_profile: IPrinterPreset;
}


export class GroupedPrinterPreset extends ApiResult implements IGroupedPrinterPreset {
  group_id?: string;
  user_id?: string;
  printer_profile: IPrinterPreset;

  constructor(data?: IGroupedPrinterPreset) {
    super();
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      (<any>this).user_id = data["user_id"];
      (<any>this).group_id = data["group_id"];
      (<any>this).printer_profile = data["printer_profile"];
    }
  }

  fromJS(data: any): GroupedPrinterPreset {
    data = typeof data === 'object' ? data : {};
    let result = new GroupedPrinterPreset();
    result.init(data);
    return result;
  }
}
