<aside class="layout__drawer add-groups">
  <div class="layout__drawer__body">
    <header class="layout__drawer__head layout__drawer__head--split">
      <h3 class="mat-title efa-text efa-text--no-spacing efa-text--he">
          {{
            'PRINTERS.NETWORK_PRINTERS.PRINTERS_PROPERTIES.DETAILS.ADDGROUPS'
              | translate
          }}
      </h3>
      <button mat-icon-button class="layout__drawer__close"
              (click)="hideAddGroups()">
        <mat-icon class="efa-icon efa-icon--me">close</mat-icon>
      </button>
    </header>
    <section class="layout__drawer__content">
      <section class="layout__drawer__part">
        <mat-form-field class="efa-text-field efa-text-field--prefixed">
          <mat-icon matPrefix class="efa-icon efa-icon--me">search</mat-icon>
          <input matInput [placeholder]="'PRINTERS.NETWORK_PRINTERS.PRINTERS_PROPERTIES.DETAILS.SEARCH_GROUP' | translate" (input)="searchText($event)"/>
        </mat-form-field>
        <ezd-loading-icon [isLoading]="isLoading"></ezd-loading-icon>
        <mat-list *ngIf="!isLoading">
          <mat-list-item *ngFor="let group of groupsFiltered" [value]="group">
            <span class="add-groups__select">
              <mat-checkbox
                class="efa-checkbox"
                (change)="selectGroups($event, group)">{{ group.name }}</mat-checkbox>
            </span>
          </mat-list-item>
        </mat-list>
      </section>
    </section>
    <footer class="layout__drawer__foot">
      <button mat-button color="accent" class="efa-button" (click)="hideAddGroups()">
        <mat-icon>arrow_back</mat-icon>
        {{
          'PRINTERS.NETWORK_PRINTERS.PRINTERS_PROPERTIES.DETAILS.BACK'
            | translate
        }}
      </button>
      <button mat-raised-button color="accent" class="efa-button" (click)="addGroups()" [disabled]="!isGroupSelected || isLoading">
        <mat-icon [ngClass]="{ 'fa-spin': isLoading }">{{ !isLoading ? 'checked' : 'cached' }}</mat-icon>
        {{
          'PRINTERS.NETWORK_PRINTERS.PRINTERS_PROPERTIES.DETAILS.ADDGROUPS'
            | translate
        }}
      </button>
    </footer>
  </div>
</aside>
