<div>
  <div class="ezp-layout__head ezp-layout__head--split">
    <ezd-search-users></ezd-search-users>
    <div class="ezp-layout__head__actions">
      <button
        mat-raised-button
        id="add-user"
        color="primary"
        class="ezp-button ezp-button--icon ezp-button--icon--leading"
        (click)="openInviteUsersDialog()"
      >
        <mat-icon>add</mat-icon>{{ 'USERSGROUPS.USERS.ADD_USER' | translate }}</button>
    </div>
  </div>


  <div class="ezp-layout__part">
    <div class="ezp-table">
      <table
        mat-table
        [dataSource]="usersDataSource"
        matSort
        (matSortChange)="sortChange($event)"
        matSortDisableClear="true"
        class="ezp-table__data"
      >
        <ng-container matColumnDef="display_name">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="ezp-table__cell ezp-table__cell--text"
            id="users-list-name-text"
          >
          {{ 'USERSGROUPS.USERS.USERS_LIST.NAME' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let user"
            class="ezp-table__cell ezp-table__cell--text"
            [id]="user.id + '-name'"
          >
            <span [id]="user.display_name">{{ user.display_name ? user.display_name : '-' }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="ezp-table__cell ezp-table__cell--text"
            id="users-list-email-text"
          >
            {{ 'USERSGROUPS.USERS.USERS_LIST.EMAIL_ADDRESS' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let user"
            class="ezp-table__cell ezp-table__cell--text"
            [id]="user.id + '-email-address'"
          >
          {{ user.email ? user.email : '-' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="roles">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="ezp-table__cell ezp-table__cell--text"
            id="users-list-role-text"
          >
          {{ 'USERSGROUPS.USERS.USERS_LIST.ROLE' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let user"
            class="ezp-table__cell ezp-table__cell--text"
            [id]="user.id + '-role'"
          >
          <span *ngIf="preferredLanguage == 'en'">{{ user.roles.includes('admin') ? 'Admin' : 'User' }}</span>
          <span *ngIf="preferredLanguage == 'de'">{{ user.roles.includes('admin') ? 'Verwaltung' : 'Nutzer' }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="invitation_status">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="ezp-table__cell ezp-table__cell--text"
            id="users-list-status-text"
          >
          {{ 'USERSGROUPS.USERS.USERS_LIST.STATUS' | translate }}
          </th>
          <td
            mat-cell
            *matCellDef="let user"
            class="ezp-table__cell ezp-table__cell--text"
            [id]="user.id + '-invitation-status'"
          >
            <span *ngIf="preferredLanguage == 'en'">{{ user.status === 'Accepted' ? '' : user.status }}</span>
            <span *ngIf="preferredLanguage == 'de'">{{ user.status === 'Angenommen' ? '' : user.status }}</span>
          </td>
        </ng-container>
        <ng-container matColumnDef="edit">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon"
          >
            <mat-menu #usersMenu="matMenu" xPosition="before">
            <button mat-menu-item id="manage-membership">{{ 'USERSGROUPS.USERS.EDIT_USER.MANAGE_MEMBERSHIP' | translate }}</button>
            <button mat-menu-item id="manage-printers">{{ 'USERSGROUPS.USERS.EDIT_USER.MANAGE_PRINTERS' | translate }}</button>
            <button mat-menu-item id="resend-invitation" (click)="openResendInvitationDialog()"
              [disabled]="selectedUsersCount == 0 || isVisible">{{ 'USERSGROUPS.USERS.EDIT_USER.RESEND_INVITATION' | translate }}</button>
            <mat-divider></mat-divider>
            <button mat-menu-item id="change-email-address" disabled="selectedUsersCount == 0 || selectedUsersCount >= 2"
              (click)="openUserDetailsDialog(undefined, 'email')">{{ 'USERSGROUPS.USERS.EDIT_USER.CHANGE_EMAIL_ADDRESS' | translate }}</button>
            <button mat-menu-item id="change-role" [disabled]="selectedUsersCount == 0 || selectedUsersCount >= 2"
              (click)="openUserDetailsDialog(undefined, 'role')">{{ 'USERSGROUPS.USERS.EDIT_USER.CHANGE_ROLE' | translate }}</button>
            <button mat-menu-item id="edit-billing-code" [disabled]="selectedUsersCount == 0 || selectedUsersCount >= 2"
              (click)="openUserDetailsDialog(undefined, 'billingCode')">{{ 'USERSGROUPS.USERS.EDIT_USER.EDIT_BILLING_CODE' | translate }}</button>
            <button mat-menu-item id="rename-user" [disabled]="selectedUsersCount == 0 || selectedUsersCount >= 2"
              (click)="openUserDetailsDialog(undefined, 'firstName')">{{ 'USERSGROUPS.USERS.EDIT_USER.RENAME_USER' | translate }}</button>
            <mat-divider></mat-divider>
            <button mat-menu-item id="open-removeuser-dialog" class="ezp-menu__item ezp-menu__item--destructive" (click)="openRemoveUserDialog()"
              [disabled]="selectedUsersCount == 0">
              {{ 'USERSGROUPS.USERS.EDIT_USER.REMOVE_USERS' | translate }}
            </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let user" class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon">
            <button mat-icon-button id="open-userdetails-dialog" (click)="openUserDetailsDialog(user.id)">
              <mat-icon>edit</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnDefs"></tr>
        <tr mat-row *matRowDef="let user; columns: columnDefs"></tr>
      </table>
      <mat-spinner
        *ngIf="isLoading"
        class="ezp-spinner ezp-spinner--large ezp-spinner--table"
        id="loading-spinner"
      ></mat-spinner>
      <mat-paginator
        class="efa-paginator"
        id="users-paginator"
        #paginator
        [length]="maxPageLength"
        [pageSize]="pageSize"
        [pageIndex]="pageIndex"
        [pageSizeOptions]="pageSizeOptions"
        (page)="onPaginationChange($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
