<h2 mat-dialog-title id="hub-dialog-title-text">
  {{ 'CONNECTORS.CONNECTORS_ADD.ADD_HUB_DIALOG.TITLE' | translate }}
</h2>
<div mat-dialog-content>
  <mat-form-field class="ezp-form-field ezp-form-field--full" appearance="fill">
    <mat-icon matPrefix>vpn_key</mat-icon>
    <mat-label id="hub-dialog-label-text">{{
      'CONNECTORS.CONNECTORS_ADD.ADD_HUB_DIALOG.LABEL' | translate
    }}</mat-label>
    <input type="text" id="hub-dialog-input" matInput [(ngModel)]="serialString" />
  </mat-form-field>

  <mat-form-field class="ezp-form-field ezp-form-field--full" appearance="fill">
    <mat-icon matPrefix>vpn_key</mat-icon>
    <mat-label id="hub-dialog-label-text">{{
      'CONNECTORS.CONNECTORS_ADD.ADD_HUB_DIALOG.HUB_PASSWORD' | translate
    }}</mat-label>
    <input type="password" id="hub-dialog-input" matInput [(ngModel)]="hubPassword" />
  </mat-form-field>
  <mat-hint *ngIf="errorState.showError" class="warn" align="start" id="hub-dialog-error-generic">{{
    'CONNECTORS.CONNECTORS_ADD.HUB_ERROR.GENERIC' | translate
  }}</mat-hint>
  <mat-hint *ngIf="errorState.show400Error" class="warn" align="start">
    <p
      style="margin: 0px"
      id="hub-dialog-error-notfound"
      [innerHtml]="'CONNECTORS.CONNECTORS_ADD.HUB_ERROR.400' | translate"
    ></p>
  </mat-hint>
  <mat-hint *ngIf="errorState.show403Error" class="warn" align="start">
    <p
      style="margin: 0px"
      id="hub-dialog-error-notfound"
      [innerHtml]="'CONNECTORS.CONNECTORS_ADD.HUB_ERROR.403' | translate"
    ></p>
  </mat-hint>
  <mat-hint *ngIf="errorState.show404Error" class="warn" align="start">
    <p
      style="margin: 0px"
      id="hub-dialog-error-notfound"
      [innerHtml]="'CONNECTORS.CONNECTORS_ADD.HUB_ERROR.404' | translate"
    ></p>
  </mat-hint>
  <mat-hint *ngIf="errorState.show402Error" class="warn" align="start">
    <p
      style="margin: 0px"
      id="hub-dialog-error402"
      [innerHTML]="'CONNECTORS.CONNECTORS_ADD.HUB_ERROR.402' | translate"
    ></p>
  </mat-hint>
  <mat-hint *ngIf="errorState.show409Error" class="warn" align="start" id="hub-dialog-error409">{{
    'CONNECTORS.CONNECTORS_ADD.HUB_ERROR.409' | translate
  }}</mat-hint>
  <mat-hint *ngIf="errorState.show412Error" class="warn" align="start" id="hub-dialog-error412">{{
    'CONNECTORS.CONNECTORS_ADD.HUB_ERROR.412' | translate
  }}</mat-hint>
</div>
<div mat-dialog-actions>
  <button mat-button id="cancel-hub-dialog" (click)="close()" color="primary" class="ezp-button">
    {{ 'CANCEL' | translate }}
  </button>
  <button
    *ngIf="!isLoading"
    mat-button
    id="add-hub-dialog"
    (click)="onClick()"
    color="primary"
    class="ezp-button"
  >
    {{ 'CONNECTORS.CONNECTORS_ADD.ADD_HUB_DIALOG.ACTION' | translate }}
  </button>
  <button
    *ngIf="isLoading"
    [disabled]="true"
    id="ezd-throbberr-svg"
    mat-button
    color="primary"
    class="ezp-button"
  >
    <ezd-throbber-svg [size]="30"></ezd-throbber-svg>
  </button>
</div>
