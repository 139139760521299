import { StorageUtils } from "../utils/storage.utils";

export class StorageService {

  constructor(
      public storageType: Storage
  ) {}

  public get(key: string): any {
    StorageUtils.get(this.storageType, key);
  }

  public set(key: string, value: any): void {
    StorageUtils.set(this.storageType, key, value);
  }

  public remove(key: string): void {
    StorageUtils.remove(this.storageType, key);
  }

}
