import { Component, OnInit, ViewChild, ElementRef, AfterContentChecked, ChangeDetectorRef} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UsersgroupsDomainsService } from '../../../../modules/shared/services/usersgroups-domains.service';

@Component({
  selector: 'ezd-search-domain',
  templateUrl: './search-domains.component.html',
  styleUrls: ['./search-domains.component.scss']
})

export class SearchDomainsComponent implements OnInit {

  @ViewChild('searchGroupsList') search: ElementRef;
  searchTextChanged = new Subject<string>();

  constructor(private domainsService: UsersgroupsDomainsService) { 
    this.domainsService.setSearchText(undefined);
    this.searchTextChanged
      .pipe(
        debounceTime(400),
        distinctUntilChanged())
      .subscribe(result => {
        this.domainsService.setSearchText(result);
        this.domainsService.applyFilters();
      })
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.search) {
        this.search.nativeElement.focus();
      }
    }, 500);
  }

  ngOnDestroy(): void {
    this.domainsService.setSearchText(undefined);
  }
  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue);
  }

}
