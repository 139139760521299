<mat-icon class="icon">warning</mat-icon>
<div class="text">
  <div class="mat-subheading-2 title">
    {{ 'SUBSCRIPTION.SUBSCRIPTION_UNAVAILABLE.STATUS' | translate }}
  </div>
  <div
    class="mat-body ezp-has-text-link subtitle"
    [innerHTML]="'SUBSCRIPTION.SUBSCRIPTION_UNAVAILABLE.DESCRIPTION' | translate"
  ></div>
</div>
