import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'ezd-welcome-connector-dialog',
  templateUrl: './welcome-connector-dialog.component.html',
  styleUrls: ['./welcome-connector-dialog.component.scss']
})
export class WelcomeConnectorDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<WelcomeConnectorDialogComponent>) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close()
  }
}
