import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DIALOG_RESPONSE } from '../../classes/dialog-result';

@Component({
  selector: 'ezd-alert-changing-properties',
  templateUrl: './alert-changing-properties.component.html',
  styleUrls: ['./alert-changing-properties.component.scss']
})
export class AlertChangingPropertiesComponent implements OnInit {

  @Output() dialogResult: EventEmitter<DIALOG_RESPONSE> = new EventEmitter<DIALOG_RESPONSE>();
  constructor() { }

  ngOnInit() {
  }

  save() {
    this.dialogResult.emit(DIALOG_RESPONSE.SAVE);
  }

  discard() {
    this.dialogResult.emit(DIALOG_RESPONSE.DISCARD);
  }

  cancel() {
    this.dialogResult.emit(DIALOG_RESPONSE.CANCEL);
  }
}
