import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../modules/material/material.module';
import { CoreModule } from '../../modules/core/core.module';
import { SharedModule } from '../../modules/shared/shared.module';
import { RequesterApiModule } from '../../modules/shared/services/requester-api.module';
import { UsersgroupsGroupsService } from '../../modules/shared/services/usersgroups-groups.service';
import { UsersgroupsUsersService } from '../../modules/shared/services/usersgroups-users.service';
import { UsersgroupsDomainsService } from '../../modules/shared/services/usersgroups-domains.service';
import { SearchComponent } from './groups/search/search.component';
import { GroupsComponent } from './groups/groups.component';
import { UsersComponent } from './users/users.component';
import { DomainsComponent } from './domains/domains.component';
import { UsersgroupsComponent } from './usersgroups.component';
import { InviteUsersDialogComponent } from './users/invite-users-dialog/invite-users-dialog.component';
import { UserDetailsDialogComponent } from './users/user-details-dialog/user-details-dialog.component';
import { UserDetailsInformationComponent } from './users/user-details-information/user-details-information.component';
import { UserDetailsGroupsComponent } from './users/user-details-groups/user-details-groups.component';
import { UserDetailsPrintersComponent } from './users/user-details-printers/user-details-printers.component';
import { GroupDetailsDialogComponent } from './groups/group-details-dialog/group-details-dialog.component';
import { GroupDetailsInformationComponent } from './groups/group-details-information/group-details-information.component';
import { GroupDetailsUsersComponent } from './groups/group-details-users/group-details-users.component';
import { GroupDetailsPrintersComponent } from './groups/group-details-printers/group-details-printers.component';
import { ResendInvitationDialogComponent } from './users/resend-invitation-dialog/resend-invitation-dialog.component';
import { RemoveUserDialogComponent } from './users/remove-user-dialog/remove-user-dialog.component';
import { SearchUsersComponent } from './users/search-users/search-users.component';
import { SearchGroupsComponent } from './groups/search-groups/search-groups.component';
import { RemoveGroupDialogComponent } from './groups/remove-group-dialog/remove-group-dialog.component';
import { ImportAzureAdGroupDialogComponent } from './groups/import-azure-ad-group-dialog/import-azure-ad-group-dialog.component';
import { QuickstartInviteUsersComponent } from './users/quickstart-invite-users/quickstart-invite-users.component';
import { SearchDomainsComponent } from './domains/search-domains/search-domains.component';
import { DomainInformationComponent } from './domains/domain-information/domain-information.component';
import { RemoveDomainDialogComponent } from './domains/remove-domain-dialog/remove-domain-dialog.component';

@NgModule({
    declarations: [
        UsersgroupsComponent,
        GroupsComponent,
        UsersComponent,
        DomainsComponent,
        SearchComponent,
        InviteUsersDialogComponent,
        UserDetailsDialogComponent,
        UserDetailsInformationComponent,
        UserDetailsGroupsComponent,
        UserDetailsPrintersComponent,
        GroupDetailsDialogComponent,
        GroupDetailsInformationComponent,
        GroupDetailsUsersComponent,
        GroupDetailsPrintersComponent,
        ResendInvitationDialogComponent,
        RemoveUserDialogComponent,
        SearchUsersComponent,
        SearchGroupsComponent,
        RemoveGroupDialogComponent,
        ImportAzureAdGroupDialogComponent,
        QuickstartInviteUsersComponent,
        SearchDomainsComponent,
        DomainInformationComponent,
        RemoveDomainDialogComponent,
    ],
    providers: [
        UsersgroupsGroupsService,
        UsersgroupsUsersService,
        UsersgroupsDomainsService
    ],
    imports: [MaterialModule, CoreModule, SharedModule, RequesterApiModule,
        CommonModule
    ]
})
export class UsersgroupsModule { }
