import { mergeMap as _observableMergeMap, catchError as _observableCatch, map, switchMap, catchError } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf, combineLatest, of } from 'rxjs';
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { RequesterApiService, ApiResponse, ApiResult, ListResult, reportingApiUrl } from './requester-api.service';
import { EZEEP_REPORTING_API } from '../../../app.config';

export interface IReportingApiService {
  list(order: string | null | undefined, limit: number | null | undefined, offset: number | null | undefined): Observable<ApiResponse<ListResult<Report>>>;
  read(id: string): Observable<ApiResponse<Report>>;
  post(data: Report): Observable<ApiResponse<Report>>;
  delete(id: string): Observable<ApiResponse<void>>;
  downloadReport(id: string): Observable<ApiResponse<null>>;
  getDashboards(year: string, month: string, offset: number): Observable<any>;
}

export interface IReportingSettingsApiService {
  read(): Observable<ApiResponse<ReportingSettings>>;
  update(data: ReportingSettings): Observable<ApiResponse<ReportingSettings>>;
}

@Injectable({
  providedIn: 'root'
})

export class ReportingApiService extends RequesterApiService implements IReportingApiService {
  httpClient: any;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(reportingApiUrl) baseUrl?: string) {
    super(http, baseUrl);
  }

  list(order: string | null | undefined, limit: number | null | undefined, offset: number | null | undefined): Observable<ApiResponse<ListResult<Report>>> {
    let url_ = EZEEP_REPORTING_API + "/reporting/reports/?";

    if (order !== undefined)
      url_ += "order=" + encodeURIComponent("" + order) + "&";
    if (limit !== undefined)
      url_ += "limit=" + encodeURIComponent("" + limit) + "&";
    if (offset !== undefined)
      url_ += "offset=" + encodeURIComponent("" + offset) + "&";
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };

    return this.processListRequest("get", url_, options_, Report)
  }

  read(id: string): Observable<ApiResponse<Report>> {
    let url_ = EZEEP_REPORTING_API + "/reporting/reports/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };

    return this.processRequest("get", url_, options_, Report);
  }

  post(data: Report): Observable<ApiResponse<Report>> {
    let url_ = EZEEP_REPORTING_API + "/reporting/reports/";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(data);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };

    return this.processRequest("post", url_, options_, Report);
  }

  delete(id: string): Observable<ApiResponse<void>> {
    let url_ = EZEEP_REPORTING_API + "/reporting/reports/{id}";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
      })
    };

    return this.processRequest<null>("delete", url_, options_, null);
  }

  downloadReport(id: string): Observable<ApiResponse<null>> {
    let url_ = EZEEP_REPORTING_API + "/reporting/reports/{id}/download";
    if (id === undefined || id === null)
      throw new Error("The parameter 'id' must be defined.");
    url_ = url_.replace("{id}", encodeURIComponent("" + id));
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };

    return this.processRequest("get", url_, options_, null);
  }

  getDashboards(year: string, month: string, offset: number): Observable<any> {

    let url_ = `${EZEEP_REPORTING_API}/reporting/dashboards/{year}/{month}/?`
    if (!year || !month )
      throw new Error("The parameters 'year' and 'month' must be defined.");
    url_ = url_.replace("{year}", encodeURIComponent("" + year));
    url_ = url_.replace("{month}", encodeURIComponent("" + month));
    if (offset !== undefined)
      url_ += "offset=" + encodeURIComponent("" + offset) + "&";
    url_ = url_.replace(/[?&]$/, "");
   
    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };
    return this.processRequest("get", url_, options_, DashBoard)

  }
}

@Injectable({
  providedIn: 'root'
})

export class ReportingSettingsApiService extends RequesterApiService implements IReportingSettingsApiService {
  httpClient: any;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(reportingApiUrl) baseUrl?: string) {
    super(http, baseUrl);
  }

  read(): Observable<ApiResponse<ReportingSettings>> {
    let url_ = EZEEP_REPORTING_API + "/reporting/settings";
    url_ = url_.replace(/[?&]$/, "");

    let options_: any = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };

    return this.processRequest("get", url_, options_, ReportingSettings);
  }

  update(data: any): Observable<ApiResponse<ReportingSettings>> {
    let url_ = EZEEP_REPORTING_API + "/reporting/settings";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(data);

    let options_: any = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/merge-patch+json",
        "Accept": "application/json",
        "Cache-Control": "no-cache"
      })
    };

    return this.processRequest("patch", url_, options_, ReportingSettings);
  }
}

export class DashBoard extends ApiResult implements IDashBoard {
  days: Array<{
    day: number;
    job_count: number;
    hours: Array<{
      hour: number;
      job_count: number;
    }>
  }>

  constructor(data?: IDashBoard) {
    super();
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.days = data
    }
  }

  fromJS(data: any): DashBoard {
    data = typeof data === 'object' ? data : {};
    let result = new DashBoard();
    result.init(data);
    return result;
  }
}

export interface IDashBoard {
  days: Array<{
    day: number;
    job_count: number;
    hours: Array<{
      hour: number;
      job_count: number;
    }>
  }>
}



export class Report extends ApiResult implements IReport {
  report_id?: string | undefined;
  created?: string | undefined;
  csv_delimiter?: string | undefined;
  end?: string | Date | undefined;
  file_name_obfuscated?: boolean | undefined;
  format?: string | undefined;
  start?: string | Date | undefined;
  status?: string | undefined;
  user_id?: string | undefined;
  user_name_blank?: boolean | undefined;
  user_email_blank?: boolean | undefined;
  timestamp_offset?: number | undefined

  constructor(data?: IReport) {
    super();
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      (<any>this).report_id = data["report_id"];
      (<any>this).created = data["created"];
      (<any>this).csv_delimiter = data["csv_delimiter"];
      (<any>this).end = data["end"];
      (<any>this).file_name_obfuscated = data["file_name_obfuscated"];
      (<any>this).format = data["format"];
      (<any>this).start = data["start"];
      (<any>this).status = data["status"];
      (<any>this).user_id = data["user_id"];
      (<any>this).user_name_blank = data["user_name_blank"];
      (<any>this).user_email_blank = data["user_email_blank"];
      (<any>this).timestamp_offset = data["timestamp_offset"];
    }
  }

  fromJS(data: any): Report {
    data = typeof data === 'object' ? data : {};
    let result = new Report();
    result.init(data);
    return result;
  }
}

export interface IReport {
  report_id?: string | undefined;
  created?: string | undefined;
  csv_delimiter?: string | undefined;
  end?: string | Date | undefined;
  file_name_obfuscated?: boolean | undefined;
  format?: string | undefined;
  start?: string | Date | undefined;
  status?: string | undefined;
  user_id?: string | undefined;
  user_name_blank?: boolean | undefined;
  user_email_blank?: boolean | undefined;
  timestamp_offset?: number | undefined
}

export class ReportingSettings extends ApiResult implements IReportingSettings {
  csv_delimiter?: string | undefined = ",";
  file_name_obfuscated?: boolean | undefined;
  user_name_blank?: boolean | undefined;
  user_email_blank?: boolean | undefined;

  constructor(data?: IReportingSettings) {
    super();
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(data?: any) {
    if (data) {
      this.csv_delimiter = data["csv_delimiter"];
      this.file_name_obfuscated = data["file_name_obfuscated"];
      this.user_name_blank = data["user_name_blank"];
      this.user_email_blank = data["user_email_blank"];
    }
  }

  fromJS(data: any): ReportingSettings {
    data = typeof data === 'object' ? data : {};
    let result = new ReportingSettings();
    result.init(data);
    return result;
  }
}

export interface IReportingSettings {
  csv_delimiter?: string | undefined;
  file_name_obfuscated?: boolean | undefined;
  user_name_blank?: boolean | undefined;
  user_email_blank?: boolean | undefined;
}