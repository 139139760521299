<div mat-dialog-content>
  <p *ngIf="!isMultipeProfilesSelected" [id]="profiles[0].name"
    [innerHTML]="'PRINTERS.PROFILES.REMOVE_PROFILE_TEXT' | translate: { value: profiles[0].name }"></p>

  <ng-container *ngIf="isMultipeProfilesSelected">
    <p>{{'PRINTERS.PROFILES.REMOVE_PROFILE_TEXT_MULTIPLE' | translate}}</p>
    <ul>
      <li *ngFor="let profile of profiles; trackBy: trackByProfileId">
        <b>{{profile.name}}</b>
      </li>
    </ul>
  </ng-container>

  <br>
  <p>{{'PRINTERS.PROFILES.REMOVE_PROFILE_TEXT_1' | translate}}</p>
</div>
<div mat-dialog-actions>
  <button mat-button id="cancel-remove-profile" [mat-dialog-close]="true" (click)="close()" color="primary"
    class="ezp-button" [disabled]="isLoading">
    {{ 'CANCEL' | translate }}
  </button>
  <button mat-button id="remove-profile" (click)="removeProfile()" color="primary" class="ezp-button"
    [disabled]="isLoading">
    <mat-icon [ngClass]="{ 'fa-spin': isLoading }" *ngIf="isLoading">cached</mat-icon>
    {{ 'DELETE' | translate }}
  </button>
</div>