import { Injectable } from "@angular/core";
import { TokenApiService, IToken } from './token-api.service';
import { BehaviorSubject, Observable, of } from "rxjs";
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    tokens: Array<IToken> = []
    tokensSubject = new BehaviorSubject<any>({ isLoading: true, count: 0, tokens: this.tokens });
    tokenCount = 0
    pageSize: any = 0;
    pageIndex: number = 0;

    constructor(private tokenApiService: TokenApiService
    ) { }

    public getTokens(): Observable<any> {
        return this.tokensSubject.asObservable();
    }

    applyFilters(showLoading: boolean = true) {
        this.tokensSubject.next({ isLoading: showLoading, count: 0, users: [] });
        this.tokenApiService.list(this.pageSize, this.pageIndex * this.pageSize).subscribe({
            next: async response => {
                this.tokens = response.result.results
                this.tokenCount = response.result.count
                this.tokensSubject.next({
                    isLoading: false,
                    count: this.tokenCount,
                    tokens: this.tokens,
                });
            }, error: error => {
                this.tokensSubject.next({ isLoading: false, count: this.tokenCount, tokens: this.tokens });
            }
        });
    }

    setPage(pageIndex: number, pageSize: number, isInitial = false) {
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
        if (!isInitial) {
            this.applyFilters(true);
        }
    }

    revokeToken(id: string): Observable<boolean> {
        return this.tokenApiService.delete(id).pipe(map(data => {
            if (data.status && (data.status == 204 || data.status == 200)) return true
            else false
        }), catchError((err, caught) => {
            return of(false)
        })
        )
    }

    searchTokensToDelete() {
        return this.tokenApiService.batchDelete({
            last_refresh_lt_or_null: this.getDateForDeletion(),
            created_lt: this.getDateForDeletion()
        })
    }

    getDateForDeletion() {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 30);
        return currentDate.toISOString()

    }
}