<mat-icon class="icon">timer_off</mat-icon>
<div class="text">
  <div class="mat-subheading-2 title" [innerHTML]="'SUBSCRIPTION.TRIAL_EXPIRED.STATUS' | translate"></div>
  <div
    class="mat-body ezp-has-text-link subtitle"
    [innerHTML]="'SUBSCRIPTION.TRIAL_EXPIRED.DESCRIPTION' | translate"
  ></div>
</div>
<button class="ezp-button" mat-flat-button color="primary" (click)="openNewSubscription()">
  {{ 'SUBSCRIPTION.ACTIONS.SUBSCRIBE_NOW' | translate }}
</button>
