import { Observable } from 'rxjs'
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core'
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http'
import {
  RequesterApiService,
  ApiResponse,
  ApiResult,
  ListResult,
  authApiUrl,
  printerApiUrl,
} from './requester-api.service'

export interface IPrinterEditorCapabilitiesApiService {
  /**
   * @param reset (optional)
   */
  listCapabilities(printer_id: string): Observable<ApiResponse<PrinterEditorCapabilities>>
  listDefaultCapabilities(printer_id: string): Observable<ApiResponse<PrinterEditorCapabilities>>
  updateCapabilities(data: PrinterEditorCapabilities, printer_id: string): Observable<ApiResponse<any>>
}

@Injectable({
  providedIn: 'root',
})
export class PrinterEditorCapabilitiesApiService
  extends RequesterApiService
  implements IPrinterEditorCapabilitiesApiService
{
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  private getUrl(printer_id: string, action: string = ''): string {
    if (printer_id === undefined || printer_id === null) {
      throw new Error("The parameter 'printer_id' must be defined.")
    }
    let url = this.baseUrl + `/v1/printers/${printer_id}/capabilities`
    if (action === 'reset=true') {
      return url + '/?reset=true'
    }
    return url + '/'
  }
  /**
   * @param reset (optional)
   */
  listCapabilities(printer_id: string): Observable<ApiResponse<PrinterEditorCapabilities>> {
    const url = this.getUrl(printer_id)
    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest<PrinterEditorCapabilities>(
      'get',
      url,
      options_,
      PrinterEditorCapabilities
    )
  }

  listDefaultCapabilities(printer_id: string): Observable<ApiResponse<PrinterEditorCapabilities>> {
    const url = this.getUrl(printer_id)
    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest<PrinterEditorCapabilities>(
      'get',
      url,
      options_,
      PrinterEditorCapabilities
    )
  }

  updateCapabilities(
    data: PrinterEditorCapabilities,
    printer_id: string
  ): Observable<ApiResponse<PrinterEditorCapabilities>> {
    const url = this.getUrl(printer_id)
    const content_ = JSON.stringify(data)

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('patch', url, options_, PrinterEditorCapabilities)
  }
}

export class PrinterEditorCapabilities extends ApiResult implements IPrinterEditorCapabilities {
  color_supported: boolean
  duplex_supported: boolean
  trays: {
    index?: number
    name: string
    original_name: string
    enabled: boolean
  }[]

  constructor(data?: IPrinterEditorCapabilities) {
    super()
    this.color_supported = false
    this.duplex_supported = false
    this.trays = []
    if (data) {
      this.init(data)
    }
  }

  init(data?: any) {
    if (data) {
      this.color_supported = data['color_supported'] || false
      this.duplex_supported = data['duplex_supported'] || false
      this.trays = data['trays'] || []
    }
  }

  static fromJS(data: any): PrinterEditorCapabilities {
    data = typeof data === 'object' ? data : {}
    const result = new PrinterEditorCapabilities()
    result.init(data)
    return result
  }
}

export interface IPrinterEditorCapabilities {
  color_supported: boolean
  duplex_supported: boolean
  trays: {
    index?: number
    name: string
    original_name: string
    enabled: boolean
  }[]
}
