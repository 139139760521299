  <div class="divHeight">
  <div class="placeholder" *ngIf="!isAvailable">
    <h1 class="heading mat-subheading-2">{{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.NOT_AVAILABLE' | translate }}</h1>
    <p class="description mat-subheading-2">{{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.DESCRIPTION' | translate }}</p>
    <button mat-button color="primary" class="action ezp-button mat-flat-button" (click)="handleNavigation()">{{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.BUTTON_TEXT' | translate }}</button>
  </div>
  
  <div class="container" *ngIf="isAvailable">
    <div class="left-column">
      <fieldset class="pdf-fieldset">
        <legend>
          <div class="legend-content">
            <img
              alt="pull-printing-pairing"
              matPrefix
              class="mat-icon"
              src="/assets/images/pull-printing-pairing.svg"
            />
            <span class="legend-text">{{
              'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.PULL_PRINTING_PAIRING'
                | translate
            }}</span>
          </div>
        </legend>
        <div
          class="ezp-download__description mat-subheading-2"
          style="color: #0000008a; font-size: 14px"
        >
          {{
            'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.PULL_PRINTING_PAIRING_DESCRIPTION'
              | translate
          }}
        </div>
        <div [matTooltip]="tooltipText" (mouseenter)="showTooltip()">
          <mat-form-field
            class="ezp-form-field ezp-form-field--no-helper pull-printing-pairing-code"
            appearance="fill"
            style="margin-top: 0.7em"
          >
            <mat-label id="register-code-text">{{
              'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.ENTER_PAIRING_CODE'
                | translate
            }}</mat-label>
            <input
              #pairCode
              matInput
              type="text"
              id="pairing-code"
              [(ngModel)]="pairPrinterCode"
              (keyup)="onPairingCodeInput($event)"
              [disabled]="selectedPrinters.length > 1"
            />
          </mat-form-field>

          <button
            mat-raised-button
            class="ezp-button"
            id="send-pairing-code"
            color="primary"
            (click)="pairPrinterPullPrintingCode()"
            style="margin-top: 1em"
            class="ezp-button ezp-button--icon ezp-button--icon--leading"
            [disabled]="selectedPrinters.length > 1"
          >
            {{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.PAIR_PRINTER' | translate }}
          </button>
        </div>
        <div class="error-message">
          <mat-error *ngIf="errorMessage404">{{
            'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.VALIDATION_MESSAGE.INVALID_PRINTER_ID'
              | translate
          }}</mat-error>
          <mat-error *ngIf="errorMessage409">{{
            'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.VALIDATION_MESSAGE.EXISTING_CODE'
              | translate
          }}</mat-error>
          <mat-error *ngIf="errorMessage400">{{
            'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.VALIDATION_MESSAGE.EXPIRED_CODE'
              | translate
          }}</mat-error>
          <mat-error *ngIf="errorMessageUnknown">{{
            'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.VALIDATION_MESSAGE.UNKNOWN'
              | translate
          }}</mat-error>
        </div>
        <div *ngIf="successMessage" class="success-message">
          {{
            'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.VALIDATION_MESSAGE.OK'
              | translate
          }}
        </div>
      </fieldset>
    </div>
    <div class="right-column">
      <fieldset class="images-fieldset">
        <legend>
          <div class="legend-content">
            <img
              alt="generate-qr-code"
              matPrefix
              class="mat-icon"
              src="/assets/images/generate-qr-code.svg"
            />
            <span class="legend-text">{{
              'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.GENERATE_QR_CODE' | translate
            }}</span>
          </div>
        </legend>
        <mat-slide-toggle
          color="primary"
          id="include-printer-name"
          [(ngModel)]="printerName"
          (change)="enablePrinterName()"
        >
          {{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.PRINTER_NAME' | translate }}
        </mat-slide-toggle>
        <div class="export-pdf">
          {{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.DOWNLOAD_PDF' | translate }}
        </div>
        <div class="select-container">
          <mat-form-field
            class="ezp-form-field ezp-form-field--no-helper page-size"
            appearance="fill"
          >
            <mat-label>{{
              'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.PAGE_SIZE' | translate
            }}</mat-label>
            <mat-select
              [(value)]="defaultPageSize"
              id="select-page-size"
              (selectionChange)="selectPageSize($event)"
            >
              <mat-option value="A4" id="A4">A4</mat-option>
              <mat-option value="letter" id="letter">letter</mat-option>
              <mat-option value="legal" id="legal">legal</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            class="ezp-form-field ezp-form-field--no-helper code-size"
            appearance="fill"
          >
            <mat-label id="code-size-text">{{
              'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.CODE_SIZE' | translate
            }}</mat-label>
            <mat-select
              [(value)]="defaultCodeSize"
              id="select-code-size"
              (selectionChange)="selectCodeSize($event)"
            >
              <mat-option value="5cm" id="5cm">{{
                'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.SMALL' | translate
              }}</mat-option>
              <mat-option value="7.5cm" id="7.5cm">{{
                'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.MEDIUM' | translate
              }}</mat-option>
              <mat-option value="10cm" id="10cm">{{
                'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.LARGE' | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <button
          mat-raised-button
          id="download-pdf"
          (click)="generateQRCode()"
          color="primary"
          class="ezp-button"
        >
          {{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.DOWNLOAD_PDF' | translate }}
        </button>
        <mat-divider></mat-divider>
        <div class="download-image">
          {{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.DOWNLOAD_IMAGE' | translate }}
        </div>
        <div class="ezp-download__items">
          <div
            class="ezp-download__item"
            *ngFor="let printer of selectedPrinters"
            style="display: flex"
          >
            <ng-container *ngIf="printer.name != ANYPRINTER_BY_EZEEP">
              <mat-icon mat-list-icon class="ezp-list__icon">qr_code</mat-icon>
              <div
                class="ezp-download__item__title ezp-text mat-body-1 printer-name-text"
                style="margin-top: 2px"
                matTooltip="{{ printer.name }}"
                matTooltipPosition="above"
              >
                {{ printer.name }}
              </div>
              <div class="action-icons" style="margin-left: auto">
                <button
                  mat-icon-button
                  class="ezp-download__item__link"
                  (click)="copyQRcode(printer.id)"
                >
                  <mat-icon>file_copy</mat-icon>
                </button>
                <button
                  mat-icon-button
                  class="ezp-download__item__link"
                  (click)="downloadQRcode(printer.id, printer.name)"
                >
                  <mat-icon>file_download</mat-icon>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
        <button
          mat-raised-button
          *ngIf="selectedPrinters && selectedPrinters.length > 1"
          (click)="handleMultiplePrintersDownloadQRCode()"
          color="primary"
          class="ezp-button"
          style="margin-top: 16px; margin-bottom: 8px"
        >
          {{
            'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PULL_PRINTING.DOWNLOAD_ALL_IMAGES' | translate
          }}
        </button>
      </fieldset>
    </div>
  </div>
</div>
