import { NgModule } from '@angular/core'
import { QuickStartComponent } from './quickstart.component'
import { QuickStartOverviewComponent } from './overview/overview.component'
import { QuickStartVirtualDesktopsComponent } from './virtual-desktops/virtual-desktops.component'
import { QuickStartLocalPrinterSupportComponent } from './local-printer-support/local-printer-support.component'
import { QuickStartNetworkPrintersComponent } from './network-printers/network-printers.component'
import { MaterialModule } from '../../modules/material/material.module'
import { CoreModule } from '../../modules/core/core.module'
import { SharedModule } from '../../modules/shared/shared.module'
import { QuickStartGetStartedComponent } from './get-started/get-started.component'
import { HttpClientModule } from '@angular/common/http'

@NgModule({
  declarations: [
    QuickStartComponent,
    QuickStartOverviewComponent,
    QuickStartVirtualDesktopsComponent,
    QuickStartLocalPrinterSupportComponent,
    QuickStartNetworkPrintersComponent,
    QuickStartGetStartedComponent,
  ],
  imports: [MaterialModule, CoreModule, SharedModule, HttpClientModule],
})
export class QuickStartModule {}
