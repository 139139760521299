import { Component, OnInit } from '@angular/core';
import { UsersgroupsUsersService } from '../../../../modules/shared/services/usersgroups-users.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ezd-search-users',
  templateUrl: './search-users.component.html',
  styleUrls: ['./search-users.component.scss']
})
export class SearchUsersComponent implements OnInit {
  searchTextChanged = new Subject<string>();

  constructor(private usersService: UsersgroupsUsersService) { 
    this.searchTextChanged
      .pipe(
        debounceTime(400),
        distinctUntilChanged()) 
      .subscribe(result => {
        this.usersService.setSearchText(result);
        this.usersService.applyFilters();
      })
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.usersService.setSearchText(undefined);
  }
  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue);
  }
}
