import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DriversService, PrinterDriver } from './printing-api.service';
import { of as observableOf, Observable, EMPTY, observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NetworkPrintersDriversService {

  constructor(
    private httpClient: HttpClient,
    private driversService: DriversService) { }

  public getDrivers(vendor: string, name_like: string, q: string = undefined, o: string = undefined, limit: number = 0, offset: number = 0): Observable<PrinterDriver[]> {
    return this.driversService.list(vendor, name_like, q, o, limit, offset).pipe(map(response => response.result.results, error => 
      // console.log(error);
       null
    ));
  }

  public getDriver(id: string): Observable<PrinterDriver> {
    return this.driversService.read(id).pipe(map(response => response.result),
    catchError((err: HttpErrorResponse) => {
      if (err.status === 404) {
return observableOf(null); 
} else {
throw err; 
}
    }));
  }
}
