import { Injectable } from '@angular/core';
import { ReportingApiService, Report, ReportingSettings, ReportingSettingsApiService } from './reporting-api.service';
import { DATE_LOCALIZATION, DropDownSelection } from '../../../pages/reporting/reporting.constans';
import { BehaviorSubject, Observable } from 'rxjs';
import { DEFAULT_PAGE_SIZE } from '../../../app.config';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  reportsSubject = new BehaviorSubject<any>({ isLoading: false, count: 0, reports: [] });
  pageIndex: number = 0;
  pageSize: number = DEFAULT_PAGE_SIZE;
  reportsCount: any;
  reports: any;
  sorting: any;

  constructor(private reportingApiService: ReportingApiService,
    private reportingSettingsApiService: ReportingSettingsApiService
    ) { }

  getFilteredReports(): Observable<any> {
    return this.reportsSubject.asObservable();
  }

  applyFilters(showLoading: boolean = true) {
    this.reportsSubject.next({ isLoading: showLoading, count: 0, reports: [] });
    this.reportingApiService.list(this.sorting ? this.sorting : "-created", this.pageSize, this.pageIndex * this.pageSize).subscribe(async response => {
      this.reports = response.result.results;
      this.reportsCount = response.result.count;
      this.reportsSubject.next({
        isLoading: false,
        count: response.result.count,
        reports: this.reports
      });
    }, error => {
      this.reportsSubject.next({ isLoading: false, count: this.reportsCount, reports: this.reports });
    });
  }

  setPage(pageIndex: number, pageSize: number, isInitial = false) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    if (!isInitial) {
      this.applyFilters(true);
    }
  }

  setSorting(sorting) {
    this.sorting = sorting;
    this.applyFilters(false);
  }

  generateReport(csvDelimiter: string | undefined, start: string| Date | undefined, end: string | Date | undefined): Observable<any> {

    let report = new Report();
    report.csv_delimiter = csvDelimiter
    report.start = start
    report.end = end
    report.timestamp_offset = this.getOffset(end)

    return this.reportingApiService.post(report);
  }

  getOffset(date: Date | string) {
    return Math.round(((new Date(date)).getTimezoneOffset()) / (-60))
  }

  getNextDayInUTC(date) {
    const nextDay = new Date(date.getTime() + 24 * 60 * 60 * 1000);
    nextDay.setHours(0, 0, 0, 0);
    return new Date(nextDay.toUTCString());
  }

  getDashboardData(year: string, month: string, offset: number) {
    return this.reportingApiService.getDashboards(year, month, offset).pipe(
      map(response => {
        const daysInMonth = this.getDaysInMonth(+month, +year);
        const { days } = response.result.days;
        return daysInMonth.map(monthDay => {
          let day = days.find(d => d.day === monthDay);
          if (!day) {
            day = { day: monthDay, job_count: 0, hours: [] };
          }
          return day
        })
      })
    );
  }

  getDaysInMonth(month, year) {
    return Array.from({ length: new Date(year, month, 0).getDate() }, (_, i) => i + 1);
  }

  getYearsAndMonthsInDateRange(startDate: Date, endDate: Date) {
    const yearsAndMonths = []
    let currentDate = startDate;

    while (currentDate <= endDate) {
      yearsAndMonths.push({
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear()
      })
      currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    }

    return yearsAndMonths;
  }

  // this will be called once each api call. 
  groupDataBasedOnSelection(selection: string, yearAndMonths: Array<any>, days: Array<any>, month: number, year: number, startDate: Date, endDate: Date,daysList?:Array<Array<any>>) {
    switch (selection) {
      case DropDownSelection.lastWeek: {
        return this.groupByDay(days, year, month, startDate, endDate, selection);
      }
      case DropDownSelection.lastMonth: {
        return this.groupByWeeks(days, startDate, endDate);
      }
      case DropDownSelection.lastQuarter: {
        return this.groupByQuater(days, month);
      }
      case DropDownSelection.lastYear: {
        return this.groupByMonth(days, month, year, startDate, endDate)
      }
      case DropDownSelection.custom: {
        if (yearAndMonths.length < 2) {
          // group by dates
          return this.groupByDay(days, year, month, startDate, endDate, selection);
        } else if (yearAndMonths.length >= 2 && yearAndMonths.length < 4) {
          // if we have number of months as 2 then we also need to check for days in between and then group them based on that
          if (yearAndMonths.length == 2) {
            const totalFilteredData = daysList.reduce((total, days, i) => {
              const filteredDays = days.filter(({ day }) => {
                const dayTimestamp = new Date(yearAndMonths[i].year, yearAndMonths[i].month - 1, day).getTime();
                return dayTimestamp >= startDate.getTime() && dayTimestamp <= endDate.getTime();
              });
              return total + filteredDays.length;
            }, 0);
            if (totalFilteredData <= 30) {
              return this.groupByDay(days, year, month, startDate, endDate, selection);
            }
          }
          return this.groupByWeeksCustom(days, month, year, startDate, endDate);
        } else {
          // group by months
          return this.groupByMonth(days, month, year, startDate, endDate)
        }

      }
    }
  }

  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate()
  }

  // gets the days in the date range from the parsed monthly response
  groupByDay(days: Array<any>, year: number, month: number, startDate: Date, endDate: Date, selection: string) {
    const groupedDays = {
      days: [],
      catagories: [],
    };
    for (const day of days) {
      const jobDate = new Date(year, month - 1, day.day);
      if (jobDate >= startDate && jobDate <= endDate) {
        groupedDays.days.push(day.job_count);
        groupedDays.catagories.push(jobDate);
      }
    }
    return groupedDays;
  }

  // gets the days in the date range from the parsed monthly response
  groupByMonth(days: Array<any>, month: number, year: number, startDate: Date, endDate: Date) {
    const groupedMonth = {
      days: [],
      catagories: []
    };
    const filteredDays = days.filter(
      day =>
        new Date(year, month - 1, day.day) >= startDate &&
        new Date(year, month - 1, day.day) <= endDate
    );
    groupedMonth.days.push(
      filteredDays.reduce((total, day) => total + day.job_count, 0)
    );
    groupedMonth.catagories.push(month);

    return groupedMonth;
  }

  groupByQuater(days: Array<any>, month: number) {
    const quaterData = {
      days: [days.reduce((n, { job_count }) => n + job_count, 0)],
      catagories: [month] // here month number will be pushed
    }
    return quaterData
  }

  groupByWeeks(days: Array<any>, startDate: Date, endDate: Date) {
    const weekdays = {
      days: [],
      catagories: []
    }
    let endWeekDate = startDate
    let endWeekInclusiveFlag = false
    for (let i = 0; i < days.length; i = i + 7) {
      let startWeekDate = endWeekDate
      endWeekDate = new Date(endWeekDate.getFullYear(), endWeekDate.getMonth(), endWeekDate.getDate() + 7);
      // end date should be inclusive for last month (In all other is exclusive)
      if (endWeekDate >= endDate) {
        endWeekDate = endDate
        endWeekInclusiveFlag = true // adds last day of month to the list
        // break the loop when the flag is true
      }
      weekdays.catagories.push(startWeekDate)
      weekdays.days.push(days.filter(({ day }) => (day >= startWeekDate.getDate() && (day < endWeekDate.getDate() || endWeekInclusiveFlag))).reduce((n, { job_count }) => n + job_count, 0))

      if (endWeekInclusiveFlag)
        break;
    }

    // adds 5th week printjobs to the fourth week
    if (weekdays.days.length > 4) {
      const lastWeekPrintJobs = weekdays.days.pop()
      weekdays.catagories.pop()
      weekdays.days[weekdays.days.length - 1] = weekdays.days[weekdays.days.length - 1] + lastWeekPrintJobs
    }
    return weekdays
  }

  groupByWeeksCustom(days: Array<any>, month: number, year: number, startDate: Date, endDate: Date) {
    const weekdays = {
      days: [],
      catagories: []
    }

    let endWeekDate = startDate // if start date is from previous month then assign the first if this month
    const startDateOfmonth = new Date(year, month - 1, 1)
    if (startDate <= startDateOfmonth)
      endWeekDate = startDateOfmonth
    let endWeekInclusiveFlag = false;

    for (let i = 0; i < days.length; i = i + 7) {
      let startWeekDate = endWeekDate;
      endWeekDate = new Date(endWeekDate.getFullYear(), endWeekDate.getMonth(), endWeekDate.getDate() + 7);

      // if endWeekDate is greater than endDate, set it to endDate and set endWeekInclusiveFlag to true
      if (endWeekDate > endDate) {
        endWeekDate = endDate;
        endWeekInclusiveFlag = true;
      }

      // if endWeekDate is greater than or equal to the last day of the month, set it to the last day of the month and set endWeekInclusiveFlag to true
      const endMonthDate = new Date(year, month - 1, this.daysInMonth(month, year));
      if (endWeekDate >= endMonthDate) {
        endWeekDate = endMonthDate;
        endWeekInclusiveFlag = true;
      }

      // filter the days that fall within the start and end date of the week
      const filteredDays = days.filter(({ day }) => {
        const dayTimestamp = new Date(year, month - 1, day).getTime();
        return dayTimestamp >= startWeekDate.getTime() && (dayTimestamp < endWeekDate.getTime() || endWeekInclusiveFlag);
      });
      // add the job counts of the filtered days to the days array
      weekdays.days.push(filteredDays.reduce((n, { job_count }) => n + job_count, 0));
       
      
      if (!endWeekInclusiveFlag) {
        let updatedDate = new Date(endWeekDate.getTime());
        updatedDate.setDate(updatedDate.getDate() - 1);
        weekdays.catagories.push({ startWeekDate, endWeekDate: updatedDate });
      } else
        weekdays.catagories.push({ startWeekDate, endWeekDate });
      // break the loop if the endWeekInclusiveFlag is true
      if (endWeekInclusiveFlag) {
        break;
      }
    }
    return weekdays;
  }

  getMonthName(monthNumber, defaultPreferredLanguage) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString(DATE_LOCALIZATION[defaultPreferredLanguage], { month: 'long' });
  }

  public toggleCSVUserNameSetting(): Observable<any> {
    return this.reportingSettingsApiService.read().pipe(mergeMap(response => {
      let reportingSettings = response.result;
      reportingSettings.user_name_blank = !reportingSettings.user_name_blank;
      let requestPayload = { user_name_blank: reportingSettings.user_name_blank }
      return this.reportingSettingsApiService.update(requestPayload).pipe(map(responseUpdate => {
        return responseUpdate.result.user_name_blank;
      }));
    }));
  }

  public getCurrentCSVUserNameSettingStatus(): Observable<boolean> {
    return this.reportingSettingsApiService.read().pipe(map(response => {
      let reportingSettings = response.result;
      return reportingSettings.user_name_blank;
    }));
  }

  public toggleCSVFileNameSetting(): Observable<any> {
    return this.reportingSettingsApiService.read().pipe(mergeMap(response => {
      let reportingSettings = response.result;
      reportingSettings.file_name_obfuscated = !reportingSettings.file_name_obfuscated;
      let requestPayload = { file_name_obfuscated: reportingSettings.file_name_obfuscated }
      return this.reportingSettingsApiService.update(requestPayload).pipe(map(responseUpdate => {
        return responseUpdate.result.file_name_obfuscated;
      }));
    }));
  }

  public getCurrentCSVFileNameSettingStatus(): Observable<boolean> {
    return this.reportingSettingsApiService.read().pipe(map(response => {
      let reportingSettings = response.result;
      return reportingSettings.file_name_obfuscated;
    }));
  }

  public toggleCSVEmailSetting(): Observable<any> {
    return this.reportingSettingsApiService.read().pipe(mergeMap(response => {
      let reportingSettings = response.result;
      reportingSettings.user_email_blank = !reportingSettings.user_email_blank;
      let requestPayload = { user_email_blank: reportingSettings.user_email_blank }
      return this.reportingSettingsApiService.update(requestPayload).pipe(map(responseUpdate => {
        return responseUpdate.result.user_email_blank;
      }));
    }));
  }

  public getCurrentCSVEmailSettingStatus(): Observable<boolean> {
    return this.reportingSettingsApiService.read().pipe(map(response => {
      let reportingSettings = response.result;
      return reportingSettings.user_email_blank;
    }));
  }
}
