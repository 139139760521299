<div class="header">
  <div class="mat-headline subscription__title" id="subscription-title-text">{{ 'SUBSCRIPTION.TITLE' | translate }}</div>
</div>

<div class="status" [ngSwitch]="subscriptionStatus">
  <ezd-incorrect-admin *ngSwitchCase="'INCORRECT_ADMIN'"></ezd-incorrect-admin>
  <ezd-subscription-active *ngSwitchCase="'ACTIVE_SUBSCRIPTION'"></ezd-subscription-active>
  <ezd-subscription-azure *ngSwitchCase="'AZURE_SUBSCRIPTION'"></ezd-subscription-azure>
  <ezd-subscription-cancelled *ngSwitchCase="'CANCELLED_SUBSCRIPTION'"></ezd-subscription-cancelled>
  <ezd-subscription-custom *ngSwitchCase="'CUSTOM_SUBSCRIPTION'"></ezd-subscription-custom>
  <ezd-subscription-unavailable *ngSwitchCase="'NOT_FOUND_SUBSCRIPTION'"></ezd-subscription-unavailable>
  <ezd-trial-active *ngSwitchCase="'TRIAL_SUBSCRIPTION'"></ezd-trial-active>
  <ezd-trial-expired *ngSwitchCase="'EXPIRED_TRIAL_SUBSCRIPTION'"></ezd-trial-expired>
</div>

<div class="periods" *ngIf="sourceType === 'chargebee' && currencyCode && !customSubscription">
  <div class="periods__header">
    <div class="mat-title periods__title" id="plans-title-text">{{ 'SUBSCRIPTION.PLAN_SELECTION.PLANS_TITLE' | translate }}</div>
    <div class="periods__actions">
      <button
        class="mat-body periods__action"
        (click)="handlePeriod('annually')"
        [ngClass]="{ 'periods__action--active': period === 'annually' }"
        id="annually-label"
      >
        {{ 'SUBSCRIPTION.PLAN_SELECTION.ANNUALLY_LABEL' | translate }}
      </button>
      <button
        class="mat-body periods__action"
        (click)="handlePeriod('monthly')"
        [ngClass]="{ 'periods__action--active': period === 'monthly' }"
        id="monthly-label"
      >
        {{ 'SUBSCRIPTION.PLAN_SELECTION.MONTHLY_LABEL' | translate }}
      </button>
    </div>
  </div>
</div>

<ezd-plan-selection
  *ngIf="sourceType === 'chargebee' && currencyCode && !customSubscription"
  [period]="period"
  [planId]="planId"
  [subscriptionId]="subscriptionId"
  [currencyCode]="currencyCode.toLowerCase()"
  [translations]="translations"
></ezd-plan-selection>

<ezd-banner-ad *ngIf="showBannerAd" imageSrc="assets/images/banner-ad-coworking-pricing.svg">
  <div header class="mat-title" [innerHTML]="'BANNER_ADS.COWORKING_PRICING.TITLE' | translate"></div>
  <div body class="mat-subheading-1 ezp-has-text-link" [innerHTML]="'BANNER_ADS.COWORKING_PRICING.DESCRIPTION' | translate"></div>
  <a footer mat-flat-button color="primary" [href]="'BANNER_ADS.COWORKING_PRICING.ACTION_LINK' | translate" target="_blank" rel="noopener">{{ 'BANNER_ADS.COWORKING_PRICING.ACTION_TITLE' | translate }}</a>
  <button footer mat-stroked-button color="primary" (click)="dismissBanner()">{{ 'BANNER_ADS.DISMISS' | translate }}</button>
</ezd-banner-ad>
