import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subject, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthUserApiService, AuthOrganizationApiService } from '../../modules/shared/services/auth-api.service';
import { UsersgroupsUsersService } from '../../modules/shared/services/usersgroups-users.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { env, changePasswordUrl } from '../../app.config';
import { SubscriptionService } from '../../modules/shared/services/subscription.service';
import { ReportingService } from '../../modules/shared/services/reporting.service';
import { SettingsService } from '../../modules/shared/services/settings.service';
import { WebhooksService } from '../../modules/shared/services/webhooks.service';
import { AppConfig } from '../../modules/shared/services/appconfig.service';
import { LangaugeService } from '../../modules/shared/services/langauge.service'

@Component({
  selector: 'ezd-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {
  isLoading: boolean = false
  user: any
  userId: string
  isAdmin: boolean
  isAzureUser: boolean = false
  displayName: string
  azureUserFirstName: string
  azureUserLastName: string
  preferredLanguage: string
  propertiesChanged = new Subject<any>()
  selectedField = ''
  accountForm: FormGroup
  organizationId: string
  currentOrgName: string
  subscriptionStatus: any
  azureADConnect: boolean
  azureProfilePresent: boolean
  hasOwner: boolean
  connectAzureAD: boolean
  sourceType: any
  displayHint: boolean
  changePassword = changePasswordUrl
  isSocialAuthUser: boolean
  userNameCSV: boolean
  fileNameCSV: boolean
  emailCSV: boolean
  userNameWebhooks: boolean
  emailWebhooks: boolean
  fileNameWebhooks: boolean

  private date: Date = new Date()
  private dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }
  private dateLocale: string = 'en-US'

  public languages: { value: string; display: string }[] = [
    { value: 'en', display: 'English' },
    { value: 'de', display: 'Deutsch' },
  ]

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    public appConfig: AppConfig,
    private languageService: LangaugeService,
    private authUserService: UsersgroupsUsersService,
    private authApiService: AuthUserApiService,
    private authOrganizationService: AuthOrganizationApiService,
    private subscriptionService: SubscriptionService,
    private reportingService: ReportingService,
    private settingsService: SettingsService,
    private webhooksService: WebhooksService
  ) {
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value
    })
    this.accountForm = this.fb.group({
      organizationName: '',
      firstName: '',
      lastName: '',
      email: '',
      preferredLanguage: '',
    })
  }

  ngOnInit(): void {
    this.authUserService.getAuthMe().subscribe(async (data) => {
      this.userId = data.user_id
      this.organizationId = data.organization_id
      let userDetails = await lastValueFrom(this.authApiService.read(this.userId)) //.toPromise();
      this.user = Object.assign({}, userDetails.result)
      this.isAdmin = this.user.role =
        this.user.roles && this.user.roles.includes('admin') ? true : false
      this.isSocialAuthUser =
        this.user.id_profile &&
        this.user.id_profile.connected_idp &&
        this.user.id_profile.connected_idp.length == 1 &&
        this.user.id_profile.connected_idp.includes('local')
          ? false
          : true

      this.authOrganizationService.get(this.organizationId).subscribe((data) => {
        this.azureProfilePresent =
          data.result.azure_profile != null || data.result.azure_profile != undefined ? true : false
        this.hasOwner = data.result.owner ? true : false
        this.currentOrgName = data.result.name

        this.accountForm.controls['organizationName'].setValue(this.currentOrgName)
        if (this.isAdmin == false) this.accountForm.controls['organizationName'].disable()
        this.accountForm.controls['firstName'].setValue(this.user.first_name)
        if (this.isSocialAuthUser == true) this.accountForm.controls['firstName'].disable()
        this.accountForm.controls['lastName'].setValue(this.user.last_name)
        if (this.isSocialAuthUser == true) this.accountForm.controls['lastName'].disable()
        this.accountForm.controls['email'].setValue(this.user.email)
        this.accountForm.controls['email'].disable()
        this.accountForm.controls['preferredLanguage'].setValue(this.user.preferred_language)
      })

      this.subscriptionService.getSubscription().subscribe(async (result) => {
        this.sourceType = result.source_type
        this.azureADConnect = result.features && result.features.azure_ad_connect
      })
    })

    this.propertiesChanged
      // .pipe(
      //   debounceTime(300))
      .subscribe((result) => {
        let hasChanges = false
        if (result.organization_name != null) {
          hasChanges = true
          this.currentOrgName = result.organization_name
        }
        if (result.first_name != null) {
          hasChanges = true
          this.user.first_name = result.first_name
        }
        if (result.last_name != null) {
          hasChanges = true
          this.user.last_name = result.last_name
        }
        if (result.email != null) {
          hasChanges = true
          this.user.email = result.email
        }
        if (result.preferred_language) {
          hasChanges = true
          if (result.preferred_language == 'de') this.user.preferred_language = 'de'
          else this.user.preferred_language = 'en'
        }
        if (hasChanges) {
          this.authUserService.draftUserDetails(this.user)
        }
      })

    this.reportingService.getCurrentCSVUserNameSettingStatus().subscribe((result) => {
      this.userNameCSV = result
    })

    this.reportingService.getCurrentCSVFileNameSettingStatus().subscribe((result) => {
      this.fileNameCSV = result
    })

    this.reportingService.getCurrentCSVEmailSettingStatus().subscribe((result) => {
      this.emailCSV = result
    })

    this.webhooksService.getCurrentUserNameSettingStatus().subscribe((result) => {
      this.userNameWebhooks = result
    })

    this.webhooksService.getCurrentFileNameSettingStatus().subscribe((result) => {
      this.fileNameWebhooks = result
    })

    this.webhooksService.getCurrentUserEmailSettingStatus().subscribe((result) => {
      this.emailWebhooks = result
    })
  }

  ngOnDestroy(): void {}

  onOrganizationNameChange($event) {
    this.propertiesChanged.next({ organization_name: $event.target.value })
    this.authOrganizationService
      .patch(this.organizationId, { name: this.currentOrgName })
      .subscribe(() => {
        this.settingsService.setOrgName(this.currentOrgName)
      })
  }

  onFirstNameChange($event) {
    this.propertiesChanged.next({ first_name: $event.target.value })
    this.authApiService.patch(this.user.id, this.user).subscribe(() => {})
  }

  onLastNameChange($event) {
    this.propertiesChanged.next({ last_name: $event.target.value })
    this.authApiService.patch(this.user.id, this.user).subscribe(() => {})
  }

  onEmailChange($event) {
    this.propertiesChanged.next({ email: $event.target.value })
    this.authApiService.patch(this.user.id, this.user).subscribe(() => {})
  }

  onPreferredLanguageChange($event) {
    this.propertiesChanged.next({ preferred_language: $event.value })
    this.authApiService.patch(this.user.id, this.user).subscribe(() => {})
    setTimeout(() => window.location.reload(), 2000)
  }

  onToggleAzureAD($event: MatSlideToggleChange) {
    this.connectAzureAD = $event.checked

    if (this.connectAzureAD == true) {
      setTimeout(() => {
        if (this.azureADConnect == true && env == 'prod' && !this.azureProfilePresent) {
          window.open(`https://api2.ezeep.com/federated/azure/connect/`, '_blank')
        }
        if (this.azureADConnect == true && env != 'prod' && !this.azureProfilePresent) {
          window.open(`https://api2.${env}.azdev.ezeep.com/federated/azure/connect/`, '_blank')
        }
      }, 2000)
    }

    if (this.connectAzureAD == false) {
      if (this.azureProfilePresent && env == 'prod' && this.hasOwner == false) {
        return this.http
          .delete(`https://api2.ezeep.com/federated/azure/api/sync/`)
          .subscribe(() => console.log('deleted'))
      }
      if (this.azureProfilePresent && env != 'prod' && this.hasOwner == false) {
        return this.http
          .delete(`https://api2.${env}.azdev.ezeep.com/federated/azure/api/sync/`)
          .subscribe(() => console.log('deleted'))
      }
    }
  }

  async onChangeUserNameCSVSetting(checked: boolean) {
    this.userNameCSV = await lastValueFrom(this.reportingService.toggleCSVUserNameSetting())
  }

  async onChangeFileNameCSVSetting(checked: boolean) {
    this.fileNameCSV = await lastValueFrom(this.reportingService.toggleCSVFileNameSetting())
  }

  async onChangeEmailCSVSetting(checked: boolean) {
    this.emailCSV = await lastValueFrom(this.reportingService.toggleCSVEmailSetting())
  }

  async onChangeUserNameWebhooksSetting(checked: boolean) {
    this.userNameWebhooks = await lastValueFrom(this.webhooksService.toggleUserNameSetting())
  }

  async onChangeFileNameWebhooksSetting(checked: boolean) {
    this.fileNameWebhooks = await lastValueFrom(this.webhooksService.toggleFileNameSetting())
  }

  async onChangeEmailWebhooksSetting(checked: boolean) {
    this.emailWebhooks = await lastValueFrom(this.webhooksService.toggleUserEmailSetting())
  }
}
