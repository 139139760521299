import { Component, OnInit, Input, Optional, Inject } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes'
import { UsersgroupsUserInvitationsService } from '../../../../modules/shared/services/usersgroups-user-invitations.service'
import { UsersgroupsUsersService } from '../../../../modules/shared/services/usersgroups-users.service';
import { LangaugeService } from '../../../../modules/shared/services/langauge.service';
import { lastValueFrom } from 'rxjs';

export interface Invitation {
  id: string
}

export interface userEmails {
  email: string
}
@Component({
  selector: 'ezd-resend-invitation-dialog',
  templateUrl: './resend-invitation-dialog.component.html',
  styleUrls: ['./resend-invitation-dialog.component.scss'],
})

export class ResendInvitationDialogComponent implements OnInit {
  startDate: Date
  minDate: Date
  expirationDateLabel: string = "30 days"
  selectable = true
  removable = true
  addOnBlur = true
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE]
  invitations: Invitation[] = []
  userEmails: userEmails[] = []
  expirationDate: Date
  usersList: any[]
  user: any
  isLoading: boolean = false
  preferredLanguage: string

  constructor(public dialogRef: MatDialogRef<ResendInvitationDialogComponent>,
    private userInvitationService: UsersgroupsUserInvitationsService,
    private authUserService: UsersgroupsUsersService,
    private languageService: LangaugeService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.user = data?.resendInvite;
   }

  ngOnInit() {
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value;
    })
  }

  // reinvite() {
  //   this.userInvitationService.resendInvitation(this.invitations.map(x => x.id), this.expirationDate) // .toPromise()
  //   this.dialogRef.close()
  // }

  resendInvitation() {
    //this.isLoading = true
    let startDate = new Date()
    startDate.setDate(startDate.getDate() + 30)
    let expirationDate = startDate
    this.isLoading = true
    lastValueFrom(this.userInvitationService.resendInvitation([this.user.user_invitations[0].id], expirationDate, this.preferredLanguage)) // .toPromise()
    this.dialogRef.close()
  }

  close() {
    this.dialogRef.close()
  }

}

