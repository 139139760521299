<h2 mat-dialog-title id="group-details-groupinfo-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_INFORMATION' | translate }}</h2>
<div class="ezp-dialog__controls">
  <button 
    *ngIf="toEdit"
    mat-icon-button
    id="group-details-menu"
    class="ezp-dialog__controls__action"
    [matMenuTriggerFor]="groupDetailsMenu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #groupDetailsMenu="matMenu" xPosition="before" yPosition="below">
    <button mat-menu-item id="group-details-remove-group" class="ezp-menu__item ezp-menu__item--destructive" (click)="openRemoveGroupDialog()" [disabled]="isOfficeRnDGroup  || isCobotGroup">
      {{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.REMOVE_GROUP' | translate }}
    </button>
  </mat-menu>
</div>
<div mat-dialog-content>
  <mat-tab-group class="ezp-tabs" animationDuration="0ms">
    <mat-tab label="Information">
      <ng-template mat-tab-label>
        <mat-icon>info</mat-icon>
        <span id="group-details-info-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.INFORMATION' | translate }}</span>
      </ng-template>
      <div class="tab-content">
        <ezd-group-details-information #information (canCreate)="changeCreateButtonStatus(information.hasName)"></ezd-group-details-information>
      </div>
    </mat-tab>
    <mat-tab label="Groups" *ngIf="toEdit">
      <ng-template mat-tab-label>
        <mat-icon>group</mat-icon>
        <span id="group-details-members-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.MEMBERS' | translate }}</span>
      </ng-template>
      <div class="tab-content">
        <ezd-group-details-users></ezd-group-details-users>
      </div>
    </mat-tab>
    <mat-tab label="Printers" *ngIf="toEdit">
      <ng-template mat-tab-label>
        <mat-icon>print</mat-icon>
        <span id="group-details-printers-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.PRINTERS' | translate }}</span>
      </ng-template>
      <div class="tab-content">
        <ezd-group-details-printers></ezd-group-details-printers>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions>
  <button mat-button id="group-details-cancel" (click)="close()" color="primary" class="ezp-button" [disabled]="isLoading">
   {{ 'CANCEL' | translate }} 
  </button>
  <button mat-button id="group-details-done" (click)="done()" color="primary" class="ezp-button" [disabled]="isLoading || !canCreate">
    <mat-icon [ngClass]="{ 'fa-spin': isLoading }" *ngIf="isLoading">cached</mat-icon>
    {{toEdit ? ('DONE' | translate) : ('CREATE' | translate)}}
  </button>
</div>
