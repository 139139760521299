import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { EZEEP_AZURE_ACCOUNT_URL } from '../../../../app.config'
import { ErrorService } from '../../services/error.service'

@Component({
  selector: 'ezd-verify-email-failed-dialog',
  templateUrl: './verify-email-failed-dialog.component.html',
  styleUrls: ['./verify-email-failed-dialog.component.scss'],
})
export class VerifyEmailFailedDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<VerifyEmailFailedDialogComponent>, private http: HttpClient, private errorService: ErrorService) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close()
  }

  resendEmail() {
    this.http
      .get(
        EZEEP_AZURE_ACCOUNT_URL + '/v1/resend_verification_email/'
      )
      .subscribe({
        next: data => {
          this.errorService.open('Verification email sent.')
          this.dialogRef.close()
        },
        error: error => {
          this.errorService.open(error.error)
          this.dialogRef.close()
        }
      })
  }
}
