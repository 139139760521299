import { BrowserModule } from '@angular/platform-browser'
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core'
import { Router } from '@angular/router'

// JG, 8-dec-2017
// This is a fix for TP-3397 where some characters are not properly encoded in url's
// Using ngx-http-client is a temporary until the fix is integrated into the main HttpClient module
// Follow issue here: https://github.com/angular/angular/pull/19710
// TO DO: revert code when Angular issue is resolved
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import * as Sentry from '@sentry/angular'
import { AppConfig } from './modules/shared/services/appconfig.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { OverlayModule } from '@angular/cdk/overlay'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { ErrorPageComponent } from './pages/error/error.component'
import { LangaugeService } from './modules/shared/services/langauge.service'
import { AuthenticationService } from './modules/authentication/services/authentication.service'
import { AuthenticationEzeepAdminGuard } from './modules/authentication/guards/authenticationEzeepAdmin.guard'
import { ErrorService } from './modules/shared/services/error.service'
import { ErrorSnackComponent } from './modules/shared/services/error/errorsnack.component'
import { Injector } from '@angular/core'
import { ClickOutsideDirective } from './modules/shared/classes/click-outside.directive'

import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar'
import { StorageModule } from './modules/storage/storage.module'
import { CustomHttpInterceptor } from './modules/core/interceptors/custom-http.interceptor'
import { GlobalErrorHandler } from './modules/core/interceptors/global-error.handler'
import { CoreModule } from './modules/core/core.module'
import { AuthenticationInterceptor } from './modules/core/interceptors/authentication.interceptor'
import { SharedModule } from './modules/shared/shared.module'
import { LocalPrinterSupportModule } from './pages/localprintersupport/localprintersupport.module'
import { QuickStartModule } from './pages/quickstart/quickstart.module'
import { PrintersModule } from './pages/printers/printers.module'
import { MaterialModule } from './modules/material/material.module'
import { UsersgroupsModule } from './pages/usersgroups/usersgroups.module'
import { SubscriptionApiService } from './modules/shared/services/subscription-api.service'
import { SubscriptionComponent } from './pages/subscription/subscription.component'
import { SubscriptionActiveComponent } from './pages/subscription/subscription-active/subscription-active.component'
import { SubscriptionCancelledComponent } from './pages/subscription/subscription-cancelled/subscription-cancelled.component'
import { SubscriptionCustomComponent } from './pages/subscription/subscription-custom/subscription-custom.component'
import { SubscriptionUnavailableComponent } from './pages/subscription/subscription-unavailable/subscription-unavailable.component'
import { TrialActiveComponent } from './pages/subscription/trial-active/trial-active.component'
import { TrialExpiredComponent } from './pages/subscription/trial-expired/trial-expired.component'
import { SubscriptionAzureComponent } from './pages/subscription/subscription-azure/subscription-azure.component'
import { AuthenticationEzeepCommonUserGuard } from './modules/authentication/guards/authenticationEzeepCommonUser.guard'
import { DownloadsModule } from './pages/downloads/downloads.module'
import { IncorrectAdminComponent } from './pages/subscription/incorrect-admin/incorrect-admin.component'
import { PoliciesModule } from './pages/policies/policies.module'
import { LoginSuccessfulComponent } from './pages/loginsuccessful/loginsuccessful.component'
import { PullprintingModule } from './pages/pullprinting/pullprinting.module'
import { GenerateQRCodeService } from './modules/shared/services/generate-qrcode.service'
import { RenameOrganizationDialogComponent } from './modules/shared/components/rename-organization/rename-organization.component'
import { PrintjobInstructionsComponent } from './pages/printjob-instructions/printjob-instructions.component'
import { PlanSelectionComponent } from './pages/subscription/plan-selection/plan-selection.component'
import { ReportingComponent } from './pages/reporting/reporting.component'
import { UserSettingsDialogComponent } from './modules/shared/components/user-settings-dialog/user-settings-dialog.component'
import { ConnectorModule } from './pages/connectors/connectors.module'
import { CustomValidatorDirective } from './modules/shared/directives/custom-validator.directive'
import { SettingsModule } from './pages/settings/settings.module'
import { AutomationsModule } from './pages/automations/automations.module'
import { EzpDocumentsService } from './modules/shared/services/ezp-documents.service'
import { DocumentsComponent } from './pages/documents/documents.component'
import { DocsComponent } from './pages/documents/documents.component'
import { AuthenticationComponent } from './modules/authentication/authentication.component'

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function loadAppConfig(appConfig: AppConfig, http: HttpClient) {
  // loads the config file from the assets folder
  return () => appConfig.load(http);
}
@NgModule({
  bootstrap: [AppComponent],
  declarations: [
    AppComponent,
    ErrorPageComponent,
    ErrorSnackComponent,
    AuthenticationComponent,
    SubscriptionComponent,
    SubscriptionActiveComponent,
    SubscriptionCancelledComponent,
    SubscriptionCustomComponent,
    SubscriptionUnavailableComponent,
    TrialActiveComponent,
    TrialExpiredComponent,
    SubscriptionAzureComponent,
    IncorrectAdminComponent,
    LoginSuccessfulComponent,
    RenameOrganizationDialogComponent,
    ClickOutsideDirective,
    PrintjobInstructionsComponent,
    PlanSelectionComponent,
    ReportingComponent,
    UserSettingsDialogComponent,
    CustomValidatorDirective,
    DocumentsComponent,
    DocsComponent,
    // MyPrintComponent,
    // DragDirective
  ],
  exports: [ErrorPageComponent, ErrorSnackComponent, SharedModule],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    OverlayModule,
    SharedModule,
    LocalPrinterSupportModule,
    QuickStartModule,
    PrintersModule,
    UsersgroupsModule,
    MaterialModule,
    DownloadsModule,
    PoliciesModule,
    ConnectorModule,
    PullprintingModule,
    SettingsModule,
    AutomationsModule,
    StorageModule.forRoot(),
    CoreModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MatSnackBarModule,
  ],
  providers: [
    AppConfig,
    ErrorService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadAppConfig,
      deps: [AppConfig, HttpClient, Sentry.TraceService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler, deps: [Injector] },
    LangaugeService,
    AuthenticationComponent,
    AuthenticationService,
    AuthenticationEzeepAdminGuard,
    AuthenticationEzeepCommonUserGuard,
    SubscriptionApiService,
    GenerateQRCodeService,
    EzpDocumentsService,
  ],
})
export class AppModule {}
