import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { InviteUsersDialogComponent } from '../../usersgroups/users/invite-users-dialog/invite-users-dialog.component';

@Component({
  selector: 'ezd-status-message-dialog',
  templateUrl: './status-message-dialog.component.html',
  styleUrls: ['./status-message-dialog.component.scss']
})
export class StatusMessageDialogComponent implements OnInit {
  title: string;
  message: string;

  constructor(public dialogRef: MatDialogRef<InviteUsersDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.title = 'Status';
      this.message = data;
    }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close()
  }
}
