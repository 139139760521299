import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ProfileService } from './printing-api.service';

@Injectable({
  providedIn: 'root'
})

export class PoliciesPrintlaterService {

  constructor(
    private profileService: ProfileService
    ) { }

  public togglePrintLater(): Observable<boolean> {
    return this.profileService.read().pipe(mergeMap(response => {
      let profile = response.result;
      profile.print_later_enabled = !profile.print_later_enabled;
      return this.profileService.update(profile).pipe(map(responseUpdate => {
        return responseUpdate.result.print_later_enabled;
      }));
    }));
  }

  public getCurrentPrintLaterEnableStatus(): Observable<boolean> {
    return this.profileService.read().pipe(map(response => {
      let profile = response.result;
      return profile.print_later_enabled;
    }));
  }
}
