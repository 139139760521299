import { Component, OnInit } from '@angular/core'
import { SubscriptionApiService, SubscriptionStatus } from '../../../modules/shared/services/subscription-api.service';
import { EzpChargebeeApiService } from '../../../modules/shared/services/ezp-chargebee-api.service';
import { map } from 'rxjs/operators';
import { SubscriptionService } from '../../../modules/shared/services/subscription.service';
import { CHARGEBEE_SITE_NAME } from '../../../app.config';

@Component({
  selector: 'ezd-subscription-cancelled',
  templateUrl: './subscription-cancelled.component.html',
  styleUrls: ['./subscription-cancelled.component.scss'],
})
export class SubscriptionCancelledComponent implements OnInit {
  cbInstance: any;
  customerId: any;
  subscriptionStatus: any;
  subscriptionId: any;
  constructor(private chargebeeApiService: EzpChargebeeApiService, 
              private subscriptionService: SubscriptionService,
              private subscriptionApiService: SubscriptionApiService) {};

  ngOnInit(): void {
    this.subscriptionApiService.read("-created").subscribe(response => {
      //WARNING! last created subscription is the one to be verified
      this.subscriptionStatus = response.result.result[0];
      this.subscriptionId = this.subscriptionStatus.source.subscription_id;
      if (!this.subscriptionStatus) return;
      if (this.subscriptionStatus.expires)
        this.subscriptionStatus.daysLeft = (new Date(this.subscriptionStatus.expires)).getTime() - new Date().getTime();
      this.subscriptionStatus.daysLeft = Math.ceil(this.subscriptionStatus.daysLeft / (1000 * 3600 * 24));
    });


    this.cbInstance = window['Chargebee'].init({
      site: CHARGEBEE_SITE_NAME
    });
    this.cbInstance = window['Chargebee'].getInstance()
    this.subscriptionService.getSubscription().subscribe(async response => {
      this.customerId = response.source.customer.id
      // this.chargebeeApiService.portal(customerId).pipe(map(response => response.result)) // .toPromise(); 
      
    })
  }

  openChargebeePortal() {
    this.cbInstance.setPortalSession(() => this.chargebeePortal());
    let cbPortal = this.cbInstance.createChargebeePortal();
    cbPortal.open({
      loaded: function () {
        // Optional
        // called when chargebee portal is loaded
      },
      close: this.cbInstanceLogout(),
      visit: function(sectionName) {
        // Optional
        // called whenever the customer navigates across different sections in portal
      },
      paymentSourceAdd: function() {
        // Optional
        // called whenever a new payment source is added in portal
      },
      paymentSourceUpdate: function() {
        // Optional
        // called whenever a payment source is updated in portal
      },
      paymentSourceRemove: function() {
        // Optional
        // called whenever a payment source is removed in portal.
      },
      subscriptionChanged: function(data) {
        // Optional
        // called whenever a subscription is changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionCustomFieldsChanged: function(data) {
        // Optional
        // called whenever a subscription custom fields are changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionCancelled: function(data) {
        // Optional
        // called when a subscription is cancelled
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionPaused: function(data) {
        // Optional
        // called when a subscription is Paused.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionResumed: function(data) {
        // Optional
        // called when a paused subscription is resumed.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      scheduledPauseRemoved: function(data) {
        // Optional
        // called when the schedule to pause a subscription is removed for that subscription.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionReactivated: function(data) {
        // Optional
        // called when a cancelled subscription is reactivated.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      }
    });
  }

  chargebeePortal() {
    return this.chargebeeApiService.portal(this.customerId).pipe(map(response => {
      return response.result
    })).toPromise();
  }

  cbInstanceLogout() {
    this.cbInstance.logout()
  }
}
