<div class="plans">
  <div
    class="plan"
    *ngFor="let plan of plans"
    [ngClass]="{ 'plan--active': planId === plan['item_price_id_' + currencyCode][period] }"
  >
    <div class="plan__header">
      <div class="plan__image">
        <img [src]="plan.image" />
      </div>
      <div class="mat-title plan__title" [id]="plan.title">{{ plan.title }}</div>
    </div>
    <div class="plan__details">
      <div class="mat-headline plan__price" *ngIf="plan.pricing === 'managed'" [id]="currencyCode + '-' + plan.price[period]">
        {{ currencyCode === 'eur' ? '&#8364;' : '&#36;' }} {{ plan.price[period] }}
      </div>
      <a
        class="mat-headline plan__price plan__price--link"
        *ngIf="plan.pricing === 'unmanaged'"
        [href]="plan.contact.url"
        target="_blank"
        id="price-contact"
        >{{ plan.contact.title }} &#8594;</a
      >
      <ng-container *ngIf="plan.pricing === 'managed' && plan.price[period] !== '0.00'">
        <div class="mat-caption plan__meta" id="per-user-month">
          {{ 'SUBSCRIPTION.PER_USER' | translate }} / {{ 'SUBSCRIPTION.MONTH' | translate }}.
          <ng-container *ngIf="period === 'annually'" 
            >{{ 'SUBSCRIPTION.BILLING.YEARLY' | translate }}.</ng-container
          >
          <ng-container *ngIf="period === 'monthly'"
            >{{ 'SUBSCRIPTION.BILLING.MONTHLY' | translate }}.</ng-container
          >
        </div>
      </ng-container>
      <div class="mat-caption plan__meta" [id]="plan.price_meta">
        {{ plan.price_meta }}
      </div>
    </div>
    <div class="mat-body plan__description" [id]="plan.description">{{ plan.description }}</div>
    <div class="plan__actions">
      <button #activePlan
        class="ezp-button plan__action"
        id="select-plan-button"
        mat-flat-button
        color="primary"
        *ngIf="plan.pricing === 'managed' && planId !== plan['item_price_id_' + currencyCode][period] && plan.title !== 'Enterprise' && subscriptionStatus !== 'active' && subscriptionStatus !== 'canceled'"
        (click)="
          checkoutExistingSubscription(
            subscriptionId,
            plan['item_price_id_' + currencyCode][period]
          )
        "
      >
        {{ 'SUBSCRIPTION.SELECT_PLAN' | translate }}
      </button>
      <a
        class="ezp-button plan__action"
        [id]="plan.contact.title"
        mat-flat-button
        color="primary"
        *ngIf="plan.pricing === 'unmanaged'"
        [href]="plan.contact.url"
        target="_blank"
      >
        {{ plan.contact.title }}
      </a>
      <div
        class="plan__action plan__action--status"
        id="sub-active-button"
        *ngIf="(planId === plan['item_price_id_' + currencyCode][period]) && subscriptionStatus != 'canceled'"
      >
        <mat-icon>check</mat-icon>
        {{ 'SUBSCRIPTION.ACTIVE' | translate }}
      </div>
    </div>
  </div>
</div>

<div class="categories">
  <div class="categories__header">
    <div class="mat-title categories__title" id="categories-title-text">{{ 'SUBSCRIPTION.PLAN_SELECTION.CATEGORIES_TITLE' | translate }}</div>
  </div>
  <div class="categories__body">
    <div class="categories__plans">
      <div class="mat-body categories__plans__title"></div>
      <div class="mat-body categories__plans__item" *ngFor="let plan of plans" [id]="plan.title">
        {{ plan.title }}
      </div>
    </div>
    <div class="category" *ngFor="let category of categories">
      <div class="mat-body category__title" [id]="category.title">{{ category.title }}</div>
      <div class="category__items">
        <div class="category__item" *ngFor="let item of category.items">
          <div class="mat-body category__item__title" [id]="item.title">{{ item.title }}</div>
          <div
            class="category__item__mark"
            *ngFor="let plan of plans"
            [ngClass]="
              item.plans.includes(plan.name) || item.plans.includes('all')
                ? 'category__item__mark--available'
                : ''
            "
          >
            <mat-icon>{{
              item.plans.includes(plan.name) || item.plans.includes('all')
                ? 'check_circle'
                : 'check_circle_outline'
            }}</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
