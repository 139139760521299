import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom, from as observableFrom, of } from 'rxjs';
import { concatMap, last, mergeMap, toArray } from 'rxjs/operators';
import { map } from 'rxjs/operators';
import { GeneralUtil } from '../utils/general-util';
import { AdconGroup, AdconGroupsService, AdconTenantGroups } from './adcon-api.service';
import { AuthOrganizationApiService, AuthUserApiService } from './auth-api.service';
import { GroupsService, ProfileService } from './printing-api.service';

@Injectable({
  providedIn: 'root'
})
export class PoliciesMyprintersService {
  myprintersGroupsSubject = new BehaviorSubject<any>([]);
  adconServiceGroupsSubject = new BehaviorSubject<any>([]);
  adconServiceGroups = [];
  adconServiceTenantId: string;
  adconServiceGroupsCount = 0;
  myprintersGroupsSearchText: string;
  printersServiceGroupsSearchText = undefined;
  adconServiceGroupsSearchText = undefined;
  ezeepGroupSearchText: string;
  PAGE_SIZE = 25;
  PAGE_INDEX = 0;
  allGroupsSubject = new BehaviorSubject<any>([]);
  allGroups: any[] = [];

  constructor(
    private profileService: ProfileService,
    private groupsService: GroupsService,
    private adconGroupsService: AdconGroupsService,
    private authUserService: AuthUserApiService,
    private authOrganizationService: AuthOrganizationApiService) { }

  public toggleMyPrinters(): Observable<boolean> {
    return this.profileService.read().pipe(mergeMap(response => {
      let profile = response.result;
      profile.myprinters_enabled = !profile.myprinters_enabled;
      return this.profileService.update(profile).pipe(map(responseUpdate => {
        return responseUpdate.result.myprinters_enabled;
      }));
    }));
  }

  public getCurrentMyPrintersEnableStatus(): Observable<boolean> {
    return this.profileService.read().pipe(map(response => {
      let profile = response.result;
      return profile.myprinters_enabled;
    }));
  }

  public getAllMyPrintersGroupsSubject(): Observable<any> {
    return this.myprintersGroupsSubject.asObservable();
  }

  setAllMyPrintersGroups() {
    this.myprintersGroupsSubject.next({isLoading: true, groups: []});
    this.getAllMyPrintersGroups().subscribe(response => {
      response.then(x => this.myprintersGroupsSubject.next({isLoading: false, groups: x}))
    })
  }

  getAllMyPrintersGroups(page_size:number = 100, page_index:number = 0) : Observable<Promise<any>> {
    let offset = page_index * page_size
    return this.groupsService.list(undefined, undefined, undefined, undefined, this.myprintersGroupsSearchText, undefined, page_size, offset)
      .pipe(map(async response => {
        let groups = []
        if(response.result.next) {
          let nextIndex = page_index + 1
          groups = await lastValueFrom(this.getAllMyPrintersGroups(page_size, nextIndex)) // .toPromise()
        }
        return [...response.result.results.filter(x => x.myprinters_enabled), ...groups];
      }));
  }

  public getAdconServiceGroups(): Observable<any> {
    this.setAdconServiceGroups();
    return this.adconServiceGroupsSubject.asObservable();
  }

  setAdconServiceGroups() {
    this.adconServiceGroupsSubject.next({ isLoading: true, groups: [] });
    this.authUserService.me().subscribe(async me => {
      let orgResult = await lastValueFrom(this.authOrganizationService.get(me.result.organization_id)) // .toPromise()
      if (orgResult.result.azure_profile) {
        this.adconGroupsService.get()
          .subscribe(async response => {
            this.adconServiceGroups = [...response.result.value];
            this.adconServiceTenantId = response.result.tenantId;
            if (response.result.next) {
              let allGroups = await lastValueFrom(this.getAllAzureGroups(response.result.next)) // .toPromise()
              this.adconServiceGroups = [...this.adconServiceGroups, ...allGroups]
            }
            this.filterAdconServiceGroups()
              .subscribe(async () => {
                this.adconServiceGroupsSubject.next({ isLoading: false, count: this.adconServiceGroups.length, groups: [...this.adconServiceGroups] });
              });
          }, error => {
            this.adconServiceGroupsSubject.next({ isLoading: false, count: this.adconServiceGroups.length, groups: [...this.adconServiceGroups] });
          });
      } else {
        this.adconServiceGroupsSubject.next({ isLoading: false, count: this.adconServiceGroups.length, groups: [...this.adconServiceGroups] });
      }
    });
  }

  getAllAzureGroups(next:string) : Observable<Promise<any>> {
    return this.adconGroupsService.get()
      .pipe(map(async response => {
        let groups = []
        if(response.result.next) {
          groups = await lastValueFrom(this.getAllAzureGroups(response.result.next)) // .toPromise()
        }
        return [...response.result.value, ...groups];
      }));
  }

  filterAdconServiceGroups() {
    if(this.adconServiceGroups.length == 0) return of(null)
    let foreign_ids = GeneralUtil.chunck(this.adconServiceGroups.map(x => x.id), 80)
    return observableFrom(foreign_ids)
      .pipe(mergeMap(async (ids: any) => {
        let response = await lastValueFrom(this.getCurrentMyPrintersEnabledGroups(ids)) // .toPromise();
        if (response.length > 0) {
          response.forEach(group => {
            this.adconServiceGroups = this.adconServiceGroups.filter(adconServiceGroup => adconServiceGroup.id !== group.origin.foreign_id);
          });
        }
        this.adconServiceGroups = this.adconServiceGroups.filter(adconServiceGroup => this.adconServiceGroupsSearchText == undefined || adconServiceGroup.displayName.toLowerCase().includes(this.adconServiceGroupsSearchText.toLowerCase()));
        this.adconServiceGroupsSearchText = null;
        this.adconServiceGroupsCount = this.adconServiceGroups.length;
      }))
      .pipe(last());
  }

  getCurrentMyPrintersEnabledGroups(foreign_ids:string[]) : Observable<any> {
    return this.groupsService.list(undefined, undefined, undefined, foreign_ids.join('|'), undefined, undefined, foreign_ids.length, 0)
      .pipe(map(response => {
        return response.result.results.filter(x => x.myprinters_enabled);
      }));
  }
  
  public getAllNonMyPrintersGroups(page_size:number, page_index:number) : Observable<any> {
    let offset = page_index * page_size
    return this.groupsService.list(undefined, undefined, undefined, undefined, this.ezeepGroupSearchText, undefined, page_size, offset)
      .pipe(map(async response => {
        let groups = []
        if(response.result.next) {
          let nextIndex = page_index + 1
          groups = await lastValueFrom(this.getAllNonMyPrintersGroups(page_size, nextIndex)) // .toPromise()
        }
        this.ezeepGroupSearchText = undefined
        return [...response.result.results.filter(x => !x.myprinters_enabled), ...groups];
      }));
  }

  setSearchText(searchText: string) {
    this.adconServiceGroupsSearchText = searchText.toLocaleLowerCase();
    this.ezeepGroupSearchText = searchText.toLocaleLowerCase();
    this.setAdconServiceGroups();
  }

  public getNonMyPrintersGroups(searchText:string): Observable<any> {
    this.setNonMyPrintersGroups(searchText, 100, this.PAGE_INDEX).subscribe();
    return this.allGroupsSubject.asObservable();
  }

  setNonMyPrintersGroups(searchText: string, page_size: number, offset: number) {
    this.allGroups = [];
    this.allGroupsSubject.next({ isLoading: true, groups: this.allGroups });
    return this.getGroups(searchText, page_size, offset).pipe(map(response => {
      let endOfList = !response.next;
      this.allGroups = response.groups;
      this.allGroupsSubject.next({ isLoading: false, groups: this.allGroups, endOfList: endOfList });
    }))
  }

  getGroups(searchText:string, pageSize: number, pageIndex: number) : Observable<any> {
    return this.groupsService.list(undefined, undefined, undefined, undefined, searchText, undefined, pageSize, pageIndex)
    .pipe(map(response => {
      return { groups: response.result.results, next: response.result.next };
    }));
  }

  public removePrintersServiceGroup(selectedGroup) {
    selectedGroup.myprinters_enabled = false;
    this.groupsService.update(selectedGroup, selectedGroup.id)
      .subscribe(() => {
        this.setAllMyPrintersGroups()
      });
  }
  
  public addPrintersServiceGroups(selectedGroups: any): Observable<any> {
    const currentGroups: AdconTenantGroups = new AdconTenantGroups({
      value: [],
      tenantId: this.adconServiceTenantId
    });
    selectedGroups.forEach( group => {
      if (group.source == 'EZEEP') return

      let adconGroup = new AdconGroup({id: group.id, displayName: group.name})
      currentGroups.value.push(adconGroup);
    });
    
    if (currentGroups.value.length > 0) {
      this.adconGroupsService.post(currentGroups)
        .subscribe(async response => {
          if (response.status === 201) {
            if (response.result.value.length > 0) {
              await GeneralUtil.sleep(600);
              const azureIds = response.result.value.map(v => v.id);
              this.updateLocalPrintingSupportProperty(azureIds, selectedGroups);
            }
          }
        });
    }
    else {
      this.updateLocalPrintingSupportProperty([], selectedGroups);
    }
    return this.getAllMyPrintersGroupsSubject();
  }

  private async updateLocalPrintingSupportProperty(azureIds: string[], selectedGroups: any) {
    let ezeepGroups = selectedGroups.filter(g => g.source == "EZEEP").map(g => {
      g.myprinters_enabled = true
      return g;
    });
    
    if (azureIds && azureIds.length > 0) {
      let foreign_ids = GeneralUtil.chunck(azureIds, 80);
      observableFrom(foreign_ids)
        .pipe(concatMap(async (ids: any) => {
          let responseGroup = await lastValueFrom(this.getGroupsByForeignId(ids)) // .toPromise();
          return responseGroup
            .filter(g => selectedGroups.filter(s => s.id == g.origin.foreign_id).length > 0)
            .map(g => { g.myprinters_enabled = true; return g; });
        }))
        .pipe(toArray())
        .subscribe((result: any[]) => {
          let groups = [...[].concat(...result), ...ezeepGroups];

          if (!groups || groups.length == 0) {
            this.setAllMyPrintersGroups();
          }
          else {
            observableFrom(groups)
              .pipe(mergeMap(async (g: any) => {
                await lastValueFrom(this.groupsService.update(g, g.id)) // .toPromise();
              }))
              .pipe(last())
              .subscribe(async () => {
                await GeneralUtil.sleep(1000);
                this.setAllMyPrintersGroups();
              });
          }
        });
    } else {
      if (!ezeepGroups || ezeepGroups.length == 0) {
        this.setAllMyPrintersGroups();
      }
      else {
        observableFrom(ezeepGroups)
          .pipe(mergeMap(async (g: any) => {
            await lastValueFrom(this.groupsService.update(g, g.id)) // .toPromise();
          }))
          .pipe(last())
          .subscribe(async () => {
            await GeneralUtil.sleep(1000);
            this.setAllMyPrintersGroups();
          });
      }
    }
  }
  
  getGroupsByForeignId(foreign_id:string[]) : Observable<any> {
    let foreign_ids = GeneralUtil.chunck(foreign_id, 80);
    return observableFrom(foreign_ids)
      .pipe(concatMap(async (ids: any) => {
        let result = []
        let tries = 0
        do {
          result = await lastValueFrom(this.groupsService.list(undefined, undefined, undefined, ids.join('|'), undefined, undefined, foreign_id.length, 0)
          .pipe(map(response => {
            return response.result.results;
          }))) // .toPromise();
          tries++
        } while(result.length == 0 && tries < 3)

        return result;
      }))
      .pipe(toArray())
      .pipe(map(r => [].concat(...r)))
  }
}
