import { Injectable }                           from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarConfig as MatSnackBarConfig,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar'
import { ErrorSnackComponent } from './error/errorsnack.component'
import { TranslateService } from '@ngx-translate/core'
import { NgZone } from '@angular/core'

@Injectable()
export class ErrorService {

  constructor(
    public snackBar: MatSnackBar,
    private translateService: TranslateService,
    private zone: NgZone) {
  }

  public open(message: string) {
    this.zone.run(() => {
      const config = new MatSnackBarConfig();
      config.panelClass = ['snack-error'];
      config.horizontalPosition = 'center';
      config.verticalPosition = 'bottom';

      const snackBarRef = this.snackBar.openFromComponent(ErrorSnackComponent, config);
      snackBarRef.instance.snackBarRef = snackBarRef;
      snackBarRef.instance.message = message;
    });
  }

  // Write the error to the console but show the snackbar using the translation key
  public openTranslate(error: any, translationKey: string, ) {
    if (error) {
      console.log(error.message ? error.message : error.toString());
      if (error.stack) console.log(error.stack);
    }
    this.translateService.get(translationKey).subscribe(text => this.open(text));
  }

}
