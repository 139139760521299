import { Component, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PrinterProfileService } from '../../../../modules/shared/services/printer-profile.service';

@Component({
  selector: 'ezd-search-profiles',
  templateUrl: './search-profiles.component.html',
  styleUrls: ['./search-profiles.component.scss']
})
export class SearchProfilesComponent implements OnInit {
  searchTextChanged = new Subject<string>();

  constructor(private profilesService: PrinterProfileService) { 
    this.profilesService.setSearchText(undefined);
    this.searchTextChanged
      .pipe(
        debounceTime(400))
      .subscribe(result => {
        this.profilesService.setSearchText(result);
      })
  }

  ngOnInit() {
  }

  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue);
  }
}
