<h2 mat-dialog-title id="import-azure-group-text">{{ 'USERSGROUPS.GROUPS.IMPORT_AZURE_GROUP_HEADER' | translate }}</h2>
<div mat-dialog-content>
  <div class="ezp-grid ezp-grid--spacing-bottom" data-grid-cols="1">
    <mat-form-field
      class="ezp-form-field ezp-form-field--no-helper"
      id="group-name"
      appearance="fill"
    >
      <mat-label>{{ 'USERSGROUPS.GROUPS.SEARCH_GROUPS.LABEL' | translate }}</mat-label>
      <input #searchImportGroups matInput id="group-name-input" placeholder="Group Name" (input)="searchText($event)"/>
    </mat-form-field>
  </div>
  <ezd-loading-icon [isLoading]="isLoading"></ezd-loading-icon>
  <mat-selection-list #groups class="ezp-list ezp-list--filterable" id="azure-groups-list" (selectionChange)="selectGroups($event, groups.selectedOptions.selected)"
  *ngIf="!isLoading && filteredAzureAdGroups && filteredAzureAdGroups.length > 0">
    <mat-list-option
      *ngFor="let group of filteredAzureAdGroups"
      checkboxPosition="before"
      color="primary"
      [value]="group"
      [id]="group.displayName"
    >
      {{ group.displayName }}
    </mat-list-option>
  </mat-selection-list>
  <span *ngIf="!isLoading && (!filteredAzureAdGroups || filteredAzureAdGroups.length  == 0)">{{ 'USERSGROUPS.GROUPS.NO_GROUPS_TEXT' | translate }}</span>
</div>
<div mat-dialog-actions>
  <button mat-button id="cancel-import-azure-group" (click)="close()" color="primary" class="ezp-button">
    {{ 'CANCEL' | translate }}
  </button>
  <button mat-button id="import-azure-group" (click)="addGroups()" color="primary" class="ezp-button" [disabled]="!canAddGroups || isLoading || (!filteredAzureAdGroups || filteredAzureAdGroups.length  == 0)">
    <mat-icon [ngClass]="{ 'fa-spin': isSync }" *ngIf="isSync">cached</mat-icon>
    {{ 'USERSGROUPS.GROUPS.IMPORT' | translate }}
  </button>
</div>
