import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'ezd-subscription-custom',
  templateUrl: './subscription-custom.component.html',
  styleUrls: ['./subscription-custom.component.scss'],
})
export class SubscriptionCustomComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
