import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MaterialModule} from '../../modules/material/material.module';
import {CoreModule} from '../../modules/core/core.module';
import {SharedModule} from '../../modules/shared/shared.module';
import { HttpClientModule } from '@angular/common/http'
import { PullprintingComponent } from './pullprinting.component';
import { StatusMessageDialogComponent } from './status-message-dialog/status-message-dialog.component';



@NgModule({
  declarations: [PullprintingComponent, StatusMessageDialogComponent],
  imports: [MaterialModule, CoreModule, SharedModule, HttpClientModule],
})
export class PullprintingModule { }
