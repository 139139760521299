import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { RemoveDomainDialogComponent } from '../remove-domain-dialog/remove-domain-dialog.component';
import { UsersgroupsDomainsService } from '../../../../modules/shared/services/usersgroups-domains.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Subject, Subscription, lastValueFrom } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { DefaultGroupAssignment, GroupsService } from '../../../../modules/shared/services/printing-api.service';
import { UsersgroupsDomainsAssignmentService } from '../../../../modules/shared/services/usersgroups-domains-assignment.service';
import { GeneralUtil } from '../../../../modules/shared/utils/general-util';

@Component({
    selector: 'ezd-domain-information',
    templateUrl: './domain-information.component.html',
    styleUrls: ['./domain-information.component.scss']
})
export class DomainInformationComponent implements OnInit {
    @ViewChild('searchGroups') search: ElementRef;
    assignedGroups: any[] = [];
    isLoading: boolean = true;
    endOfList: boolean = false;
    groupsServiceSubscription: Subscription;
    searchTextChanged = new Subject<string>();
    searchText: string;
    currentPageIndex: number = 0;
    domain: any;
    isLoadingSave: boolean = false;
    toEdit: boolean;
    domainsServiceSubscription: Subscription;
    canCreate = true;
    canRemove: boolean;

    constructor(public dialogRef: MatDialogRef<DomainInformationComponent>,
        private assignmentService: UsersgroupsDomainsAssignmentService,
        private domainService: UsersgroupsDomainsService,
        private groupService: GroupsService,
        private dialog: MatDialog) {
        this.domain = this.domainService.getDomainToEdit() ?
            Object.assign({}, this.domainService.getDomainToEdit()) :
            Object.assign({}, this.domainService.getDomainToCreate());
        this.canRemove = this.domain.id !== 'NEWDOMAIN';
        let assignedGroups = this.domain.groups || [];        // adding isSelected and soruce to the assigned groups as they are not returned in the api call.
        const chunkedGroups = GeneralUtil.chunck(assignedGroups, 100) // TODO: Store it in a constant
        Promise.all(chunkedGroups.map(async groups => {
            let g = await lastValueFrom(this.groupService.listMany(groups.map(group => group.id)))
            return g.result.results.map(u => ({ ...u, ["isSelected"]: true }))
        })).then(allAssignments => {
            allAssignments = [].concat(...allAssignments)
            this.groupsServiceSubscription = this.assignmentService.getAllGroups(this.searchText, this.domain.id).subscribe(async result => {
                assignedGroups = GeneralUtil.searchArray(allAssignments, this.searchText, 'name'); //TODO: save this key in constants
                if (!result.isLoading && result.groups) {
                    let orderedGroups = result.groups;
                    this.assignedGroups = [...this.assignedGroups, ...assignedGroups, ...orderedGroups];

                    // removing duplicate groups
                    this.assignedGroups = GeneralUtil.removeDuplicates(this.assignedGroups)
                    this.assignedGroups = this.assignedGroups.filter(obj => !allAssignments.some(item => (item['id'] === obj.id && !obj.isSelected)));
                    if (this.assignedGroups.length < 25) {
                        if (!result.endOfList) {
                            this.currentPageIndex += 1;
                            this.assignmentService.setAllGroups(this.searchText, this.domain.id, 25, 25 * this.currentPageIndex).subscribe();
                            return;
                        }
                    }
                }

                this.isLoading = result.isLoading;
                this.endOfList = result.endOfList;
                if (this.endOfList) {
                    this.currentPageIndex = 0;
                }
            })
        })


        this.searchTextChanged
            .pipe(debounceTime(400), switchMap(result => {
                this.searchText = result;
                this.isLoading = true;
                this.assignedGroups = [];
                return this.assignmentService.setAllGroups(result, this.domain.id, 25, 0);
            })).subscribe();
    }

    ngOnInit(): void {
        this.domainsServiceSubscription = this.domainService.getFilteredDomains()
            .subscribe(() => {
                this.domain = Object.assign({}, this.domainService.getDomainToEdit());
                if (!this.domain) {
                    return;
                }
            });
    }

    openRemoveDomainDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = false;
        dialogConfig.width = '320px';
        dialogConfig.panelClass = 'ezp-dialog';
        dialogConfig.data = { domainDetails: this.domain };

        this.dialogRef.close();
        this.dialog.open(RemoveDomainDialogComponent, dialogConfig);
    }

    ngAfterContentChecked() {
        if (this.search) {
            // this.search.nativeElement.focus();
        }
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        // Add 'implements OnDestroy' to the class.
        this.groupsServiceSubscription.unsubscribe();
        this.assignedGroups = [];
        this.endOfList = false;
        this.isLoading = true;
    }

    onScroll() {
        if (this.endOfList == undefined || this.endOfList || this.isLoading) return;
        this.isLoading = true;
        this.currentPageIndex += 1;
        this.assignmentService.setAllGroups(this.searchText, this.domain.id, 25, 25 * this.currentPageIndex).subscribe();
    }

    onSelection(e, v) {
        const currentAssignedGroups = this.assignedGroups.filter(g => g.isSelected);
        const newAssignments = v.selected.map(g => g.value);
        this.assignmentService.draftAssignedGroups(currentAssignedGroups, newAssignments);
    }

    onAdd() {
        this.isLoadingSave = true;
        this.domainService.saveDomain(this.domain)
            .subscribe({
                next: async result => {
                    await this.assignmentService.saveDraftedAssignedGroups(result.id);
                    this.domainService.cancelDomainToEdit();
                    this.assignmentService.clearDraftedAssignedGroups();
                    this.isLoadingSave = false;
                    this.domainService.applyFilters(true);
                    this.dialogRef.close();
                },
                error: err => {
                    this.domainService.cancelDomainToEdit();
                    this.assignmentService.clearDraftedAssignedGroups();
                    this.isLoadingSave = false;
                    this.dialogRef.close();
                }
            });
    }

    onClose() {
        this.assignmentService.clearDraftedAssignedGroups();
        this.dialogRef.close();
    }

    onSearchChange(searchValue: string) {
        this.searchTextChanged.next(searchValue);
    }

    onDomainChange(value: string) {
        this.domain.domain = value;
        // this.domain.expression = value
    }
}
