import { Component, OnInit } from '@angular/core'
import { env } from '../../app.config';
import { LocalPrinterSupportService } from '../../modules/shared/services/local-printer-support.service';
import { PoliciesMyprintersService } from '../../modules/shared/services/policies-myprinters.service';
import { PoliciesPullprintingService } from '../../modules/shared/services/policies-pullprinting.service';
import { PoliciesPrintlaterService } from '../../modules/shared/services/policies-printlater.service';
import { ProfileService } from '../../modules/shared/services/printing-api.service';
import { SubscriptionService } from '../../modules/shared/services/subscription.service';

@Component({
  selector: 'ezd-policies',
  templateUrl: './policies.component.html',
  styleUrls: ['./policies.component.scss'],
})
export class PoliciesComponent implements OnInit {
  env = env
  printerSelection = {
    isLoading: true,
    disabled: false,
    groups: [],
  }
  localPrinterSupport = {
    isLoading: true,
    disabled: false,
    groups: [],
  }
  securePullPrinting = {
    isLoading: true,
    disabled: false,
    groups: [],
  }
  printLater = {
    isLoading: true,
    disabled: false,
    groups: [],
  }
  isLoadingMyPrintersGroups: boolean;
  isValidSubscription: boolean = false;
  featurePullPrinting: boolean = false;
  featurePrintLater: boolean = false;
  enableGroups: boolean = false;

  constructor(private localPrinterSupportService: LocalPrinterSupportService, 
    private policiesMyPrintersService: PoliciesMyprintersService,
    private policiesPullPrintingService: PoliciesPullprintingService,
    private policiesPrintlaterService: PoliciesPrintlaterService,
    private profileService: ProfileService,
    private subscriptionService: SubscriptionService
    ) {}

  ngOnInit(): void {
    this.getCurrentLocalPrinterSupportGroups();
    this.getCurrentMyPrintersGroups();
    this.subscriptionService.getSubscription().subscribe((result) => {
      this.enableGroups = result.features && result.features.groups_printer_assignments ? true : false;
    })
    this.localPrinterSupportService.getCurrentLocalPrintingEnableStatus()
      .subscribe(result => {
        this.localPrinterSupport.disabled = result;
      });
    this.policiesMyPrintersService.getCurrentMyPrintersEnableStatus()
      .subscribe(result => {
        this.printerSelection.disabled = result;
      });
    this.policiesPullPrintingService.getCurrentPullPrintingEnableStatus()
      .subscribe(result => {
        this.securePullPrinting.disabled = result;
      });
      this.policiesPrintlaterService.getCurrentPrintLaterEnableStatus()
      .subscribe(result => {
        this.printLater.disabled = result;
      });

      this.profileService.read().subscribe(profileResponse => {
        this.isValidSubscription = profileResponse.result.subscription && profileResponse.result.subscription.is_valid == true ? true : false;
        this.featurePullPrinting = profileResponse.result.subscription && profileResponse.result.subscription.feature_pull_printing == true ? true : false;
        this.featurePrintLater = profileResponse.result.subscription && profileResponse.result.subscription.feature_print_later == true ? true : false;
      })
  }


  private getCurrentLocalPrinterSupportGroups() {
    this.localPrinterSupport.isLoading = true;
    this.localPrinterSupportService.getAllLocalSupportGroupsSubject()
      .subscribe(result => {
        this.localPrinterSupport.isLoading = result === undefined;
        this.localPrinterSupport.groups = result;
      });
    this.localPrinterSupportService.setAllLocalSupportGroups();
  }
  
  private getCurrentMyPrintersGroups() {
    this.printerSelection.isLoading = true;
    this.policiesMyPrintersService.getAllMyPrintersGroupsSubject()
      .subscribe(result => {
        this.printerSelection.isLoading = result.isLoading;
        this.printerSelection.groups = result.groups;
      });
    this.policiesMyPrintersService.setAllMyPrintersGroups();
  }
}
