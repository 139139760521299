import { NgModule } from '@angular/core'
import { MaterialModule } from '../../modules/material/material.module'
import { CoreModule } from '../../modules/core/core.module'
import { SharedModule } from '../../modules/shared/shared.module'
import { PoliciesComponent } from './policies.component'
import { PolicyPanelComponent } from './policy-panel/policy-panel.component'
import { AddGroupDialogComponent } from './add-group-dialog/add-group-dialog.component'

@NgModule({
    declarations: [PoliciesComponent, PolicyPanelComponent, AddGroupDialogComponent],
    imports: [MaterialModule, CoreModule, SharedModule]
})
export class PoliciesModule {}
