import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, HostBinding, OnDestroy } from '@angular/core'
import { MatDatepickerInputEvent } from '@angular/material/datepicker'
import { FormControl, Validators } from '@angular/forms'
import ApexCharts from 'apexcharts'
import { ReportingService } from '../../modules/shared/services/reporting.service'
import { ReportingApiService } from '../../modules/shared/services/reporting-api.service'
import { SubscriptionService } from '../../modules/shared/services/subscription.service'
import { GeneralUtil } from '../../modules/shared/utils/general-util'
import { Renderer2 } from '@angular/core'
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, EZEEP_REPORTING_API } from '../../app.config'
import { MatTableDataSource } from '@angular/material/table'
import { DATE_LOCALIZATION, DropDownSelection, MAX_ALLOWED_MONTH } from './reporting.constans'
import { Subscription, lastValueFrom } from 'rxjs'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { LangaugeService } from '../../modules/shared/services/langauge.service'
import { DateAdapter } from '@angular/material/core'
import * as moment from 'moment';
import { StorageUtils } from '../../modules/storage/utils/storage.utils'
import { REPORTING_BANNER } from '../../modules/storage/constants/storage.constants'

@Component({
  selector: 'ezd-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
})
export class ReportingComponent implements OnInit, OnDestroy {
  @ViewChild('printoutsChart') printoutsChart: ElementRef<HTMLCanvasElement>
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @HostBinding('class.has-banner-ad') showBannerAd: boolean = true;

  maxPageLength = 25;
  defaultPageSize = DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  pageIndex: number;
  pageSize: number = DEFAULT_PAGE_SIZE;
  reportingServiceSubscription: Subscription;
  sorting: string;
  chart
  today = new Date()
  reportingRange = new FormControl(DropDownSelection.lastWeek)
  startDate = new FormControl(this.today)
  endDate = new FormControl(this.today, [endDateValidator(this.startDate)])
  minEndDate
  maxDate = new Date()
  maxEndDate = this.maxDate
  generatedReports = new MatTableDataSource([])
  isLoading: boolean = true;
  minStartDate
  defaultPreferredLanguage: string;
  isDownloadReportPresent: boolean;
  isDashboardPresent: boolean;
  showGenerateReport: boolean;

  /**
   * Chart properties
   */
  private printoutsChartOptions = {
    noData: {
      text: "Loading...",
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#000000",
        fontSize: '14px',
        fontFamily: "Helvetica"
      }
    },
    series: [
      {
        name: 'Printouts',
        data: [],
      },
    ],
    chart: {
      height: 320,
      type: 'area',
      animations: {
        enabled: false,
      },
      fontFamily: 'inherit',
      foreColor: 'var(--ezp-core-foreground-secondary)',
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      type: 'categories',
      categories: [],
      tickPlacement: 'on',
      tooltip: {
        enabled: false,
      },
      labels: {
        style: {
          fontWeight: 500,
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      min: 0,
      forceNiceScale: true,
      labels: {
        style: {
          fontWeight: 500,
        },
      },
    },
    colors: ['#0091b4'],
    markers: {
      size: 4,
      strokeWidth: 0,
      hover: {
        size: 8,
      },
    },
    grid: {
      show: true,
      borderColor: 'var(--ezp-core-outline)',
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }

  /**
   * Reports properties
   */
  reportsColumns = ['range', 'generated', 'actions']

  constructor(private reportingService: ReportingService,
    private reportingApiService: ReportingApiService,
    private renderer: Renderer2,
    private languageService: LangaugeService,
    private subscriptionService: SubscriptionService,
    private dateAdapter: DateAdapter<any>) {
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.defaultPreferredLanguage = value;
    })
    if (this.defaultPreferredLanguage) {
      this.dateAdapter.setLocale(this.defaultPreferredLanguage);
    }

    this.checkAdBanner()
  }
  ngOnInit(): void {
    this.subscriptionService.getSubscription().subscribe((result) => {
      this.isDashboardPresent = (result.features && result.features.dashboard) && (result.status == 'trial' || result.status == 'active') ? true : false
      this.isDownloadReportPresent = (result.features && result.features.report_download) && (result.status == 'trial' || result.status == 'active') ? true : false
      this.showGenerateReport = result.features && result.features.report_download ? true : false

      if (this.isDashboardPresent) this.renderCharts();

      if (this.isDownloadReportPresent) {
        this.generatedReports.sort = this.sort;
        this.reportingService.setPage(0, this.defaultPageSize, true);
        this.reportingServiceSubscription = this.reportingService.getFilteredReports()
          .subscribe(result => {
            if (!result.reports) return;
            this.isLoading = result.isLoading;
            this.generatedReports.data = result.reports;
            this.maxPageLength = result.count;
            if (this.generatedReports.data && this.generatedReports.data.map(report => report.status == 'pending').includes(true)) {
              setTimeout(() => {
                this.reportingService.applyFilters();
              }, 4000)
            }
          });

        this.reportingService.applyFilters();
      }
    })
  }

  renderCharts(){
    this.chart = new ApexCharts(this.printoutsChart.nativeElement, this.printoutsChartOptions)
    this.chart.render()
    this.setDateRange()
  }

  ngOnDestroy(): void {
    if (this.reportingServiceSubscription) this.reportingServiceSubscription.unsubscribe();
  }

  startDateChange(event: MatDatepickerInputEvent<Date>) {
    this.reportingRange.patchValue(DropDownSelection.custom)
    this.startDate.setValue(event.value)
    this.setDateRange()
    this.setMinMaxEndDate();
  }

  endDateChange(event: MatDatepickerInputEvent<Date>) {
    this.reportingRange.patchValue(DropDownSelection.custom)
    this.endDate.setValue(event.value)
    this.setDateRange();
  }

  disableAllControls() {
    this.reportingRange.disable();
    this.startDate.disable();
    this.endDate.disable();
  }

  enableAllControls() {
    this.reportingRange.enable();
    this.startDate.enable();
    this.endDate.enable();
  }


  setMinMaxEndDate() {
    this.minEndDate = this.startDate.value
    let tempMinDate = new Date(this.startDate.value)
    this.maxEndDate = new Date(tempMinDate.getFullYear() + 2, tempMinDate.getMonth(), tempMinDate.getDate())
  }


  resetMinMaxDateRange() {
    this.minEndDate = undefined
    this.minStartDate = undefined
    this.maxEndDate = this.maxDate
  }

  getLastQuarterRange() {
    const today = new Date();
    const currentMonth = today.getMonth();
    let start, end;
    switch (currentMonth) {
      case 0:
      case 1:
      case 2:
        start = new Date(today.getFullYear() - 1, 9, 1);
        end = new Date(today.getFullYear() - 1, 11, 31);
        break;
      case 3:
      case 4:
      case 5:
        start = new Date(today.getFullYear(), 0, 1);
        end = new Date(today.getFullYear(), 2, 31);
        break;
      case 6:
      case 7:
      case 8:
        start = new Date(today.getFullYear(), 3, 1);
        end = new Date(today.getFullYear(), 5, 30);
        break;
      case 9:
      case 10:
      case 11:
        start = new Date(today.getFullYear(), 6, 1);
        end = new Date(today.getFullYear(), 8, 30);
        break;
    }
    return { start, end };
  }

  getLastWeekRange() {
    let start = new Date(
      this.today.getFullYear(),
      this.today.getMonth(),
      this.today.getDate() - this.today.getDay() + 1 - 7
    )
    let end = new Date(
      this.today.getFullYear(),
      this.today.getMonth(),
      this.today.getDate() - this.today.getDay()
    )
    return { start, end }
  }

  getLastMonthRange() {
    const today = new Date();
    const currentMonth = today.getMonth();
    const start = new Date(today.getFullYear(), currentMonth - 1, 1);
    const end = new Date(today.getFullYear(), currentMonth, 0);
    return { start, end };
  }

  getLastYearRange() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const start = new Date(currentYear - 1, 0, 1);
    const end = new Date(currentYear - 1, 12, 0);
    return { start, end };
  }

  updateGraphDataAndLabels(yearAndMonths = [], graphData = [], catagories = []) {
    this.chart.updateSeries([{ data: graphData }]);
    this.chart.updateOptions({
      xaxis: {
        categories: catagories,
        labels: {
          formatter: (value) => {
            return this.yAxisFormatter(value, yearAndMonths)
          },
        },
      },
      yaxis: [
        {
          labels: {
            formatter: (val) => {
              return val.toFixed(0);
            }
          }
        }
      ]
    });
  }

  yAxisFormatter(value, yearsAndMonths) {
    switch (this.reportingRange.value) {
      case DropDownSelection.lastWeek:
        return new Date(value).toLocaleDateString(DATE_LOCALIZATION[this.defaultPreferredLanguage], { weekday: "long" })
      case DropDownSelection.lastMonth:
        return new Date(value).toLocaleDateString(DATE_LOCALIZATION[this.defaultPreferredLanguage])
      case DropDownSelection.lastQuarter:
      case DropDownSelection.lastYear:
        return this.reportingService.getMonthName(value, this.defaultPreferredLanguage);
      case DropDownSelection.custom:
        if (value)
          return yearsAndMonths.length < 4 ? typeof value.getMonth === 'function' ? new Date(value).toLocaleDateString(DATE_LOCALIZATION[this.defaultPreferredLanguage]) : this.formatLabelForCustom(value) : this.reportingService.getMonthName(value, this.defaultPreferredLanguage);

    }
  }

  formatLabelForCustom(date){
    return new Date(date.startWeekDate).toLocaleDateString(DATE_LOCALIZATION[this.defaultPreferredLanguage],{ month: 'numeric', day: 'numeric' }) + " - " + new Date(date.endWeekDate).toLocaleDateString(DATE_LOCALIZATION[this.defaultPreferredLanguage],{ month: 'numeric', day: 'numeric' }) 
  }

  patchValuesToform(startDate: Date, endDate: Date) {
    this.startDate.patchValue(startDate)
    this.endDate.patchValue(endDate)
  }

  setDatePickerValidations() {
    if (this.reportingRange.value != DropDownSelection.custom)
      this.resetMinMaxDateRange();
    else {
      this.setMinMaxEndDate();
    }
  }


  async getGraphData(selection: string, startDate: Date, endDate: Date, yearAndMonths: Array<any>) {
    const requests = yearAndMonths.map(({ year, month }) => lastValueFrom(this.reportingService.getDashboardData(year, month, this.reportingService.getOffset(endDate))));
    let daysList = await Promise.all(requests);
    const result = daysList.reduce((acc, days, i) => {
      const { days: daysData, catagories } = this.reportingService.groupDataBasedOnSelection(
        selection, yearAndMonths, days, yearAndMonths[i].month, yearAndMonths[i].year, startDate, endDate, daysList);
      return {
        graphData: [...acc.graphData, ...daysData],
        catagories: [...acc.catagories, ...catagories]
      };
    }, { graphData: [], catagories: [] });
    return result;
  }

  async setDateRange() {
    let startDate;
    let endDate;
    ({ startDate, endDate } = this.setDatesBasedOnSelection());

    this.patchValuesToform(startDate, endDate);
    const yearAndMonths = this.reportingService.getYearsAndMonthsInDateRange(startDate, endDate)
    if (yearAndMonths.length && yearAndMonths.length < MAX_ALLOWED_MONTH) {
      this.setDatePickerValidations()
      this.updateGraphDataAndLabels();
      this.disableAllControls();
      const formattedData = await this.getGraphData(this.reportingRange.value, startDate, endDate, yearAndMonths);
      this.enableAllControls();
      this.updateGraphDataAndLabels(yearAndMonths, formattedData.graphData, formattedData.catagories);
    } else {
      // Add validation for invalid date range
      this.endDate.markAsTouched();
      this.setDatePickerValidations();
    }
  }

  setDatesBasedOnSelection() {
    let startDate;
    let endDate;
    let range;
    switch (this.reportingRange.value) {
      case DropDownSelection.lastWeek:
        range = this.getLastWeekRange();
        startDate = new Date(range.start);
        endDate = new Date(range.end);
        break;
      case DropDownSelection.lastMonth:
        range = this.getLastMonthRange();
        startDate = new Date(range.start);
        endDate = new Date(range.end);
        break;
      case DropDownSelection.lastYear:
        range = this.getLastYearRange();
        startDate = new Date(range.start);
        endDate = new Date(range.end);
        break;
      case DropDownSelection.lastQuarter:
        range = this.getLastQuarterRange();
        startDate = new Date(range.start);
        endDate = new Date(range.end);
        break;
      case DropDownSelection.custom:
        startDate = this.startDate.value;
        endDate = this.endDate.value;
        break;
    }
    return { startDate, endDate };
  }

  generateReport() {
    let csvDelimiter = ','
    let startDate = this.startDate.value
    let endDate = this.reportingService.getNextDayInUTC(this.endDate.value)
    this.reportingService.generateReport(csvDelimiter, startDate, endDate)
      .subscribe({
        next: async () => {
          await GeneralUtil.sleep(600)
          this.isLoading = true;
          this.reportingService.applyFilters()
        }, error: error => {
          this.isLoading = true;
          this.reportingService.applyFilters()
        }
      })
  }

  regenerateReport(reportId: string) {
    this.reportingApiService.read(reportId).subscribe(result => {
      let startDate = result.result.start
      let endDate = result.result.end
      let csvDelimiter = ','
      this.reportingService.generateReport(csvDelimiter, startDate, endDate)
        .subscribe({
          next: async () => {
            await GeneralUtil.sleep(600)
          }, error: error => {
            this.isLoading = true;
          }
        })
      this.reportingApiService.delete(reportId).subscribe(result => {
        this.reportingService.applyFilters()
      })
    })
  }


  downloadReport(report_id) {
    const link = this.renderer.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', `${EZEEP_REPORTING_API}/reporting/reports/${report_id}/download`);
    link.click();
    link.remove();
  }

  deleteReport(report_id) {
    this.reportingApiService.delete(report_id).subscribe(result => {
      this.isLoading = true;
      this.reportingService.applyFilters();
    })
  }

  public onPaginationChange(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.reportingService.setPage(this.pageIndex, this.pageSize);
  }

  sortChange(e) {
    const direction = e.direction === 'desc' ? '-created' : e.direction === 'asc' ? 'created' : undefined;
    if (direction) {
      this.sorting = direction
    }
    this.reportingService.setSorting(this.sorting);
  }

  dismissBanner() {
    StorageUtils.set(localStorage, REPORTING_BANNER, true)
    this.checkAdBanner()
  }

  checkAdBanner() {
    this.showBannerAd = StorageUtils.get(localStorage, REPORTING_BANNER) ? false : true
  }
}

export function endDateValidator(startDate: FormControl) {
  return (control: FormControl) => {
    if (startDate.value && control.value && startDate.value > control.value) {
      return { 'endDateInvalid': true };
    }
    return null;
  }
}
