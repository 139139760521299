import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '../../../app.config';
import { Subscription } from 'rxjs';
import { UsersgroupsUsersService } from '../../../modules/shared/services/usersgroups-users.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { InviteUsersDialogComponent } from './invite-users-dialog/invite-users-dialog.component';
import { UserDetailsDialogComponent } from './user-details-dialog/user-details-dialog.component';
import { ResendInvitationDialogComponent } from './resend-invitation-dialog/resend-invitation-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { LangaugeService } from '../../../modules/shared/services/langauge.service';

@Component({
  selector: 'ezd-usersgroups-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  columnDefs = ['display_name', 'email', 'roles', 'invitation_status', 'edit'];//['select', 'name', 'email', 'role', 'status', 'edit']
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  maxPageLength = 100;
  defaultPageSize = DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  pageIndex: number;
  pageSize: number = DEFAULT_PAGE_SIZE;
  isLoading = true;
  sorting: string;
  selectedUsers: any[] = [];
  usersServiceSubscription: Subscription;
  selectedUsersCount: number = 0;
  disableRemoveUser = true;
  disableAddGroup = true;
  disableRemoveGroup = true;
  isVisible: boolean;
  usersDataSource = new MatTableDataSource([]);
  inviteUsersQueryParam: string
  isQueryParamPresent: boolean
  preferredLanguage: any
  @Input() groupsEnabled: boolean = false;

  constructor(private usersService: UsersgroupsUsersService, private dialog: MatDialog, private route: ActivatedRoute, private languageService: LangaugeService) { }

  ngOnInit() {
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value 
    })
    this.usersDataSource.sort = this.sort;
    this.usersService.setPage(0, this.defaultPageSize, true);
    this.usersServiceSubscription = this.usersService.getFilteredUsers()
      .subscribe(result => {
        if (!result.users) return;
        this.isLoading = result.isLoading;

        result.users.forEach(user => {
          user.status = this.setUserStatus(user);
        });
        this.usersDataSource.data = result.users;

        this.selectedUsersCount = this.usersService.getSelectedUsersCount();
        this.selectedUsers = this.usersService.getSelectedUsers();
        this.pageIndex = result.pageIndex;
        this.pageSize = this.defaultPageSize;
        this.maxPageLength = result.count;
      });

    this.usersService.applyFilters();
    this.route.queryParamMap.subscribe((params) => {
      this.inviteUsersQueryParam = params.get('lang')
      
      if (this.inviteUsersQueryParam) {
        this.isQueryParamPresent = true
        this.openInviteUsersDialog()
      }
    })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.usersServiceSubscription) this.usersServiceSubscription.unsubscribe();
  }

  openInviteUsersDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = true;
    dialogConfig.width = '600px';
    dialogConfig.panelClass = 'ezp-dialog';
    dialogConfig.data = {
      data: this.isQueryParamPresent,
      languages: [{name: 'English', slug: 'en'}, {name: 'Deutsch', slug: 'de'}],
      language: 'en'
    }

    this.dialog.open(InviteUsersDialogComponent, dialogConfig);
  }

  openUserDetailsDialog(userId: string, field?: string) {
    this.usersService.editUser(userId);
    if (field) {
      this.usersService.selectedUserField(field);
    } else {
      this.usersService.selectedUserField('');
    }

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.width = '780px';
    dialogConfig.panelClass = 'ezp-dialog';
    dialogConfig.data = { userId: userId, enableGroups: this.groupsEnabled };
    this.dialog.open(UserDetailsDialogComponent, dialogConfig);
  }

  openResendInvitationDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.width = '320px';
    dialogConfig.panelClass = 'ezp-dialog';
    dialogConfig.data = { resendInvite: this.selectedUsers };

    this.dialog.open(ResendInvitationDialogComponent, dialogConfig);
  }

  // openRemoveUserDialog() {
  //   const dialogConfig = new MatDialogConfig();

  //   dialogConfig.autoFocus = false;
  //   dialogConfig.width = '320px';
  //   dialogConfig.panelClass = 'ezp-dialog';

  //   this.dialog.open(RemoveUserDialogComponent, dialogConfig);
  // }

  selectAllVisibleUsers(event) {
    if (event.checked) {
      this.usersService.selectUsers(this.usersDataSource.data);
    } else {
      this.usersService.unselectUsers(this.usersDataSource.data);
    }
    this.statusCheck();
  }

  selectUser(event, users) {
    if (event.checked) {
      this.usersService.selectUsers([users]);
    } else {
      this.usersService.unselectUsers([users]);
    }
    this.statusCheck();
  }

  public onPaginationChange(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.usersService.setPage(this.pageIndex, this.pageSize);
  }

  sortChange(e) {
    let colName = '';
    colName = e.active;

    //if (colName == 'name')
    //  colName = 'first_name,last_name';

    const direction = e.direction === 'desc' ? '-' : e.direction === 'asc' ? '' : undefined;
    if (direction === undefined) {
      this.sorting = colName;
    } else {
      this.sorting = direction + colName;
    }

    this.usersService.setSorting(this.sorting);
  }

  text_truncate(str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  setUserStatus(user): String {
    if (!user.user_invitations || !user.user_invitations[0] || !user.user_invitations[0].delivery_status || !user.user_invitations[0].invitation_status)
      return "";

    if (this.preferredLanguage == 'en') {
      if (user.user_invitations[0].invitation_status == "ACCEPTED")
        return "Accepted";

      if (user.user_invitations[0].invitation_status == "EXPIRED") {
        return "Expired";
      }

      if (user.user_invitations[0].invitation_status == "OPEN") {
        if (user.user_invitations[0].delivery_status == "NOTSENT")
          return "Not Sent";

        if (user.user_invitations[0].delivery_status == "SENT")
          return "Sent";

        if (user.user_invitations[0].delivery_status == "EMAILFAILED")
          return "Failed";
      }
    }

    if (this.preferredLanguage == 'de') {
      if (user.user_invitations[0].invitation_status == "ACCEPTED")
        return "Angenommen";

      if (user.user_invitations[0].invitation_status == "EXPIRED") {
        return "Abgelaufen";
      }

      if (user.user_invitations[0].invitation_status == "OPEN") {
        if (user.user_invitations[0].delivery_status == "NOTSENT")
          return "Nicht gesendet";

        if (user.user_invitations[0].delivery_status == "SENT")
          return "Gesendet";

        if (user.user_invitations[0].delivery_status == "EMAILFAILED")
          return "Fehlgeschlagen";
      }
    }
  }

  statusCheck() {
    const userStatus = this.selectedUsers.filter(user => user.status != 'Accepted');
    if (userStatus.length >= 1) {
      this.isVisible = false;
    } else {
      this.isVisible = true;
    }
  }
}
