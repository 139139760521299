<div class="section">
    <div class="section__box">
        <div class="section__leading">
            <mat-icon class="section__icon">devices</mat-icon>
            <div class="section__intro">
                <div class="section__title mat-subheading-2">{{ 'SETTINGS_PAGE.ACTIVE_SESSIONS.HEADER' | translate }}
                </div>
                <div *ngIf="hasSessions" class="section__description mat-body">
                    {{ 'SETTINGS_PAGE.ACTIVE_SESSIONS.DESCRIPTION_SESSIONS' | translate }}
                </div>
                <button *ngIf="hasSessions" (click)="terminateSession()" mat-stroked-button color="primary"
                    class="section__button ezp-button">
                    {{ 'SETTINGS_PAGE.ACTIVE_SESSIONS.TERMINATE_INACTIVE_SESSIONS' | translate }}
                </button>
                <div *ngIf="!hasSessions" class="section__description mat-body">
                    {{ 'SETTINGS_PAGE.ACTIVE_SESSIONS.DESCRIPTION_NO_SESSIONS' | translate }}
                </div>
                <span *ngIf="!hasSessions" class="mat-body-2 efa-text efa-text--link efa-text--prm"
                    (click)="openAppsAndDownload()" id="open-network-printers">
                    <span>{{ 'SETTINGS_PAGE.ACTIVE_SESSIONS.DOWNLOADS' | translate }}</span>
                </span>
            </div>
        </div>
        <div class="section__trailing">
            <div class="section__sessions">
                <ng-container *ngIf="hasSessions">
                    <div class="session" *ngFor="let session of sessions">
                        <mat-icon class="session__icon">devices</mat-icon>
                        <div class="session__body">
                            <div class="session__title mat-subheading-2">{{ session.client.name }}</div>
                            <div class="session__info">
                                <div class="session__meta mat-body">
                                    <strong>{{ 'SETTINGS_PAGE.ACTIVE_SESSIONS.LAST_LOGIN' | translate
                                        }}:</strong>{{formatDate(session.last_refresh ? session.last_refresh :
                                    session.created )}}
                                </div>
                            </div>
                        </div>
                        <button mat-button color="primary" class="session__button ezp-button"
                            (click)="removeToken(session.id)">{{
                            'SETTINGS_PAGE.ACTIVE_SESSIONS.LOGOUT' | translate }}</button>
                    </div>


                </ng-container>
                <ng-container *ngIf="!hasSessions">
                    <ezd-session-placeholder type="smartphone"></ezd-session-placeholder>
                    <ezd-session-placeholder type="tablet"></ezd-session-placeholder>
                </ng-container>

            </div>
            <mat-paginator class="efa-paginator" id="users-paginator" #paginator [length]="maxPageLength"
                [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
                (page)="onPaginationChange($event)">
            </mat-paginator>
        </div>

    </div>
</div>