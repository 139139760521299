<div class="ezp-layout__page">
    <div class="ezp-layout__content">
        <h1 class="mat-headline" id="pullprinting-text">Pull Printing</h1>
        <div class="ezp-layout__head ezp-layout__head--split" style="width: 275px;grid-template-columns: 1fr 6px 1fr auto;gap: 0 3px;">
            <mat-form-field class="ezp-form-field ezp-form-field--full ezp-form-field--densed" appearance="fill" style="width: 100px;">
                <mat-icon matPrefix>switch_account</mat-icon>
                <input #regCode1 id="register-code1" type="text" matInput [placeholder]="codePlaceHolder" [(ngModel)]="registCode1" pattern="^\w{4}" maxlength="4" (keyup)="onRegistCodeInput($event)"/>
                
            </mat-form-field>
            <span style="padding-top: 14px;">-</span>
            <mat-form-field class="ezp-form-field ezp-form-field--full ezp-form-field--densed" appearance="fill" style="width: 70px;">
                <input #regCode2 id="register-code2" type="text" matInput [placeholder]="codePlaceHolder" [(ngModel)]="registCode2" pattern="^\w{4}" maxlength="4" (keyup)="onRegistCodeInput($event)"/>
            </mat-form-field>
            <div class="ezp-layout__head__actions">
                <button mat-raised-button id="send-registration-code" color="primary" (click)="sendRegistrationCode()"
                    class="ezp-button ezp-button--icon ezp-button--icon--leading">Submit</button>
            </div>
            <mat-hint align="start" id="enter-code-hint" style="grid-column: 1 / 4;">Enter the Code number</mat-hint>
        </div>
    </div>
</div>
  