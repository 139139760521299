import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core'
import { Clipboard } from '@angular/cdk/clipboard';
import { UsersgroupsGroupsService } from '../../../../modules/shared/services/usersgroups-groups.service';
import { Subscription, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ezd-group-details-information',
  templateUrl: './group-details-information.component.html',
  styleUrls: ['./group-details-information.component.scss'],
})
export class GroupDetailsInformationComponent implements OnInit, AfterViewInit {
  @ViewChild('nameFocus') nameFieldFocus: ElementRef;
  groupSubscription: Subscription;
  selectedUserSubscription: Subscription;
  isLoading: boolean = true;
  group: any;
  isGroup: boolean = false;
  isEzeepGroup: boolean = false;
  propertiesChanged = new Subject<any>();
  selectedField = '';
  hasName = false;
  createGroup: boolean;
  @Output() public canCreate: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private groupsService: UsersgroupsGroupsService,
    private clipboard: Clipboard) {
    this.groupSubscription = this.groupsService.getFilteredGroups()
      .subscribe(async result => {
        this.group = this.groupsService.getGroupToEdit() ? 
          Object.assign({}, this.groupsService.getGroupToEdit()) : 
          Object.assign({}, this.groupsService.getGroupToCreate());

        if (!this.group) return;

        this.isEzeepGroup = this.group.origin && this.group.origin.provider == 'Ezeep';
        this.isLoading = result.isLoading;
      })
  }

  ngOnInit(): void {
    this.groupSubscription = this.groupsService.getFilteredGroups()
      .subscribe(async result => {
        this.createGroup = this.groupsService.getGroupToCreate() ? true : false;
        this.group = this.groupsService.getGroupToEdit() ? 
          Object.assign({}, this.groupsService.getGroupToEdit()) : 
          Object.assign({}, this.groupsService.getGroupToCreate());

        if (!this.group) return;

        this.isEzeepGroup = this.group.origin && this.group.origin.provider === 'Ezeep';
        this.isLoading = result.isLoading;
      })
      
    this.propertiesChanged
      .pipe(
        debounceTime(300))
      .subscribe(result => {
        let hasChanges = false;
        if (result.name !== undefined && result.name != this.group.name) {
          hasChanges = true;
          this.group.name = result.name;
        }
        if ((result.description || result.description == '') && result.description != this.group.description) {
          hasChanges = true;
          this.group.description = result.description;
        }

        if (result.name !== undefined) {
          if (result.name !== "" && /\S/.test(result.name)) {
            this.hasName = true
          } else {
            this.hasName = false;
          }
          this.canCreate.emit()
        }

        if (hasChanges) {
          this.groupsService.draftGroupDetails(this.group, this.groupsService.getGroupToCreate());        
        }
      });
  }

  ngAfterViewInit() {
    if (this.nameFieldFocus) {
      setTimeout(() => this.nameFieldFocus.nativeElement.focus(), 500);
    }
  }

  onNameChange(value: string) {
    this.propertiesChanged.next({ name: value });
  }

  onDescriptionChange(value: string) {
    this.propertiesChanged.next({ description: value });
  }

  copyEzeepId() {
    this.clipboard.copy(this.group.id);
  }

  copyAzureId() {
    this.clipboard.copy(this.group.origin.foreign_id);
  }

}
