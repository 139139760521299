import { Component, OnInit, ViewChild, ElementRef, AfterContentChecked, ChangeDetectorRef} from '@angular/core';
import { Subject } from 'rxjs';
import { UsersgroupsGroupsService } from '../../../../modules/shared/services/usersgroups-groups.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'ezd-search-groups',
  templateUrl: './search-groups.component.html',
  styleUrls: ['./search-groups.component.scss']
})
export class SearchGroupsComponent implements OnInit {
  @ViewChild('searchGroupsList') search: ElementRef;
  searchTextChanged = new Subject<string>();

  constructor(private groupsService: UsersgroupsGroupsService) { 
    this.groupsService.setSearchText(undefined);
    this.searchTextChanged
      .pipe(
        debounceTime(400),
        distinctUntilChanged())
      .subscribe(result => {
        this.groupsService.setSearchText(result);
        this.groupsService.applyFilters();
      })
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    setTimeout(() => {
      if (this.search) {
        this.search.nativeElement.focus();
      }
    }, 500);
  }

  ngOnDestroy(): void {
    this.groupsService.setSearchText(undefined);
  }
  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue);
  }
}
