<h1 mat-dialog-title class="title">{{ 'USER_SETTINGS_DIALOG.TITLE' | translate }}</h1>

<div mat-dialog-content>
  <mat-tab-group class="ezp-tabs" animationDuration="0ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>account_circle</mat-icon>
        <span>{{ 'USER_SETTINGS_DIALOG.TABS.ACCOUNT' | translate }}</span>
      </ng-template>
      <form [formGroup]="accountForm">
      <div class="account">
        <div class="account__fields">
          <mat-form-field
            appearance="fill"
            class="ezp-form-field ezp-form-field--no-helper account__field--organization-name"
          >
            <mat-label>{{ 'USER_SETTINGS_DIALOG.FIELDS.ORGANIZATION_NAME' | translate }}</mat-label>
            <input matInput formControlName="organizationName" (input)="onOrganizationNameChange($event.target.value)"/>
          </mat-form-field>
          <mat-form-field
            appearance="fill"
            class="ezp-form-field ezp-form-field--no-helper account__field--first-name"
          >
            <mat-label>{{ 'USER_SETTINGS_DIALOG.FIELDS.FIRST_NAME' | translate }}</mat-label>
            <input matInput formControlName="firstName" (input)="onFirstNameChange($event.target.value)" />
          </mat-form-field>
          <mat-form-field
            appearance="fill"
            class="ezp-form-field ezp-form-field--no-helper account__field--last-name"
          >
            <mat-label>{{ 'USER_SETTINGS_DIALOG.FIELDS.LAST_NAME' | translate }}</mat-label>
            <input matInput formControlName="lastName"  (input)="onLastNameChange($event.target.value)" />
          </mat-form-field>
          <mat-form-field
            appearance="fill"
            class="ezp-form-field ezp-form-field--no-helper account__field--email-address"
            (input)="onEmailChange($event.target.value)">
            <mat-label>{{ 'USER_SETTINGS_DIALOG.FIELDS.EMAIL_ADDRESS' | translate }}</mat-label>
            <input matInput formControlName="email" (input)="onEmailChange($event.target.value)" />
          </mat-form-field>
          <mat-form-field
            appearance="fill"
            class="ezp-form-field ezp-form-field--no-helper account__field--language"
          >
            <mat-label>{{ 'USER_SETTINGS_DIALOG.FIELDS.LANGUAGE' | translate }}</mat-label>
            <mat-select id="preferred_language" formControlName="preferredLanguage" (selectionChange)="onPreferredLanguageChange($event.value)">
              <mat-option id="english" value="en">{{ 'EN' | translate }}</mat-option>
              <mat-option id="german" value="de">{{ 'DE' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="account__password">
          <div class="mat-subheading-2 account__password__title">
            {{ 'USER_SETTINGS_DIALOG.CHANGE_PASSWORD.TITLE' | translate }}
          </div>
          <p
            class="mat-body-1 account__password__instruction"
            [innerHTML]="'USER_SETTINGS_DIALOG.CHANGE_PASSWORD.INSTRUCTION' | translate: { value: changePassword }"
          ></p>
        </div>
      </div>
      </form>
    </mat-tab>
    <mat-tab *ngIf="isAdmin == true">
      <ng-template mat-tab-label>
        <mat-icon>tune</mat-icon>
        <span>{{ 'USER_SETTINGS_DIALOG.TABS.ADVANCED' | translate }}</span>
      </ng-template>
      <div class="advanced">
        <div class="advanced__azure-active-directory">
          <mat-slide-toggle 
          color="primary" 
          class="toggle mat-subheading-2"
          [checked]="azureProfilePresent"
          [disabled]="azureADConnect == false || (!azureProfilePresent && hasOwner)"
          (change)="onToggleAzureAD($event)">{{
            'USER_SETTINGS_DIALOG.AZURE_ACTIVE_DIRECTORY.TOGGLE' | translate
          }}</mat-slide-toggle>
          <div
            *ngIf="sourceType == 'azuremarketplace' && azureProfilePresent"
            class="advanced__azure-active-directory__hint"
            [innerHTML]="'USER_SETTINGS_DIALOG.AZURE_ACTIVE_DIRECTORY.HINT_AZURE_MARKETPLACE' | translate"
          ></div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div mat-dialog-actions>
  <button class="ezp-button" mat-button mat-dialog-close color="primary" (click)="resetForm()">
    {{ 'CANCEL' | translate }}
  </button>
  <button class="ezp-button" mat-button mat-dialog-close color="primary" (click)="done()">
    <mat-icon [ngClass]="{ 'fa-spin': isLoading }" *ngIf="isLoading">cached</mat-icon>
    {{ 'DONE' | translate }}
  </button>
</div>
