import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, AfterViewChecked } from '@angular/core'
import { map } from 'rxjs/operators'
import { HttpClient } from '@angular/common/http'
import { EzpChargebeeApiService } from '../../../modules/shared/services/ezp-chargebee-api.service'
import { CHARGEBEE_SITE_NAME } from '../../../app.config'
import { SubscriptionApiService } from '../../../modules/shared/services/subscription-api.service'
import { LangaugeService } from '../../../modules/shared/services/langauge.service'
import { SubscriptionService } from '../../../modules/shared/services/subscription.service'

@Component({
  selector: 'ezd-plan-selection',
  templateUrl: './plan-selection.component.html',
  styleUrls: ['./plan-selection.component.scss'],
})
export class PlanSelectionComponent implements OnInit {
  @Input() currencyCode: string
  @Input() period: string
  @Input() planId: string
  @Input() subscriptionId: string
  @Input() translations: any

  baseUrl = 'https://ezeepassetsstg.wpengine.com/wp-json'
  route = 'ezp/v2'
  endpoint = 'pricing'
  preferredLanguage: string
  plans = []
  categories = []
  cbInstance: any
  subscriptionStatus: string
  subscription: any
  activePlan = []

  constructor(
    private chargebeeApiService: EzpChargebeeApiService, 
    private http: HttpClient,
    private languageService: LangaugeService,
    private subscriptionApiService: SubscriptionApiService,
    private subscriptionService: SubscriptionService) {}

  ngOnInit(): void {
    this.cbInstance = window['Chargebee'].init({
      site: CHARGEBEE_SITE_NAME,
    })

    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value
      if (this.preferredLanguage) {
        this.getPricing(this.currencyCode)
      }
    })

    this.subscriptionApiService.read('-created').subscribe((response) => {
      //WARNING! last created subscription is the one to be verified
      this.subscription = response.result.result[0]
      this.subscriptionStatus = this.subscription.status
      if (!this.subscriptionStatus) return
    })
  }


  // ngAfterViewChecked(): void {
  //   if (this.activePlan)
  //     console.log(this.activePlan)
  // }

  /**
   * Gets the pricing data.
   *
   * @param {string}    currency    The currency
   */

  getPricing(currency: string): void {
    this.http
      .get(`${this.baseUrl}/${this.route}/${this.endpoint}/${this.preferredLanguage}/${currency}`)
      .subscribe((data: any) => {
        this.plans = data.plans
        this.categories = data.categories
        this.subscriptionService.setPlans(this.plans)
        this.subscriptionService.plansArray$.subscribe(value => {
          this.activePlan = value;
        })
      })
  }

  checkoutExistingSubscription(subscriptionId: any, itemPriceId: any): void {
    this.cbInstance.createChargebeePortal()
    this.cbInstance.openCheckout({
      hostedPage: () => {
        //   // Hit your end point that returns hosted page object as response
        //   // This sample end point will call checkout new api
        //   // https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_new_subscription
        //   // If you want to use paypal, go cardless and plaid, pass embed parameter as false
        return this.chargebeeApiService
          .checkout(subscriptionId, itemPriceId)
          .pipe(
            map((response) => {
              return response.result
            })
          )
          .toPromise()
        //   //return this.http.post("http://localhost:8000/api/generate_checkout_existing_url", {}, {headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})}) // .toPromise();
      },

      loaded: () => {},
      close: () => {},
      success: (hostedPageId) => {
        // Hosted page id will be unique token for the checkout that happened
        // You can pass this hosted page id to your backend
        // and then call our retrieve hosted page api to get subscription details
        // https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page
      },
      step: (value) => {
        // value -> which step in checkout
      },
    })
  }
}
