<div class="ezp-layout__page">
  <div class="ezp-layout__content">
    <h1 class="mat-headline">
      {{ 'LOCAL_PRINTER_SUPPORT.TITLE' | translate }}
    </h1>
    <div class="ezp-paragraph">
      <p class="ezp-text ezp-text--high-emphasis mat-subheading-2">
        {{ 'LOCAL_PRINTER_SUPPORT.DESCRIPTION' | translate }}
      </p>
    </div>
    <mat-divider></mat-divider>
    <div class="ezp-layout__part">
      <mat-checkbox
        [ngModel]="disableLocalPrinterSupport"
        (ngModelChange)="onDisableLocalPrinterSupportChange($event)"
        color="primary"
        >{{
          'LOCAL_PRINTER_SUPPORT.CONTENT.DISABLE_EVERYONE' | translate
        }}</mat-checkbox
      >
    </div>
    <div class="ezp-layout__part">
      <h2 class="mat-title">
        {{ 'LOCAL_PRINTER_SUPPORT.CONTENT.GROUPS' | translate }}
      </h2>
      <button
        mat-raised-button
        color="primary"
        class="ezp-button ezp-button--icon ezp-button--icon--leading"
        (click)="showAdconGroups()"
      >
        <mat-icon>add</mat-icon>Add Group
      </button>
    </div>
    <div class="ezp-layout__part">
      <div
        class="groups"
        [ngClass]="{ 'groups--disabled': disableLocalPrinterSupport }"
      >
        <div class="ezp-table">
          <table
            mat-table
            [dataSource]="printersServiceGroups"
            class="ezp-table__data"
          >
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let group">
                {{ group.name }}
              </td>
            </ng-container>
            <ng-container matColumnDef="remove">
              <th mat-header-cell *matHeaderCellDef></th>
              <td
                mat-cell
                *matCellDef="let group"
                class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon"
              >
                <button mat-icon-button (click)="removeGroup(group)">
                  <mat-icon>remove_circle</mat-icon>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columnDefs"></tr>
            <tr mat-row *matRowDef="let group; columns: columnDefs"></tr>
          </table>
          <mat-spinner *ngIf="isLoading" class="ezp-spinner ezp-spinner--large ezp-spinner--table"></mat-spinner>
          <mat-paginator
            class="efa-paginator"
            [length]="maxPageLength"
            [pageSize]="pageSize"
            [pageIndex]="pageIndex"
            [pageSizeOptions]="pageSizeOptions"
            (page)="onPaginationChange($event)"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
  <ezd-localprintersupport-add-groups
    *ngIf="showAdconSeviceGrps"
    (hideAddGroupsView)="hideAdconGroups()"
  >
  </ezd-localprintersupport-add-groups>
</div>
