<h2 mat-dialog-title class="title">{{ 'INVITATION_REMINDER_DIALOG.TITLE' | translate }}</h2>
<img src="/assets/images/pictograms/island.svg" />
<div class="description mat-subheading-1">
  {{ 'INVITATION_REMINDER_DIALOG.DESCRIPTION' | translate }}
</div>
<div mat-dialog-actions class="ezp-splash__actions ezp-splash__actions--stacked">
  <button class="ezp-button ezp-button--custom ezp-button--custom--invert" (click)="inviteUsers()">
    <mat-icon>group</mat-icon>
    {{ 'INVITATION_REMINDER_DIALOG.INVITE' | translate }}
  </button>
  <button (click)="close()" class="ezp-button ezp-button--custom ezp-button--custom--translucent">
    {{ 'GOT_IT' | translate }}
  </button>
</div>
