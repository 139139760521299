import { Injectable } from '@angular/core';
import { AuthUserInvitationApiService, AuthUserInvitation } from './auth-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersgroupsUserInvitationsService {

  constructor(private userInvitationService: AuthUserInvitationApiService) { }

  sendInvitation(emails:string[], expiration: Date | undefined, preferredLanguage: string | undefined) :  Observable<any> {
    let defaultDays = new Date();
    defaultDays.setDate(new Date().getDate() + 30);

    let userInviationObj = emails.map(x => {
      let invitation = new AuthUserInvitation();
      invitation.email = x;
      invitation.expire_date = expiration ? //fix toJSON() to return the selected Date and not the UTC time of selected Date
        new Date(expiration.getTime() - (expiration.getTimezoneOffset() * 60000)).toJSON() : 
        new Date(defaultDays.getTime() - (defaultDays.getTimezoneOffset() * 60000)).toJSON();
      invitation.user = {
          preferred_language: preferredLanguage
      }
      return invitation;
    })
    return this.userInvitationService.send(userInviationObj);
  }

  resendInvitation(ids:string[], expiration: Date | undefined, preferredLanguage: string | undefined) :  Observable<any> {
    let defaultDays = new Date();
    defaultDays.setDate(new Date().getDate() + 30);

    let userInviationObj = ids.map(x => {
      let invitation = new AuthUserInvitation();
      invitation.id = x;
      invitation.expire_date = expiration ? //fix toJSON() to return the selected Date and not the UTC time of selected Date
        new Date(expiration.getTime() - (expiration.getTimezoneOffset() * 60000)).toJSON() : 
        new Date(defaultDays.getTime() - (defaultDays.getTimezoneOffset() * 60000)).toJSON();
      invitation.user = {
        preferred_language: preferredLanguage
      }
      
      return invitation;
    })
    
    return this.userInvitationService.resend(userInviationObj);
  }
}
