<h2 mat-dialog-title id="remove-group-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.REMOVE_GROUP' | translate }}</h2>
<div mat-dialog-content>
  <p [id]="groupName" *ngIf="preferredLanguage == 'en'">{{ 'USERSGROUPS.GROUPS.REMOVE_GROUP' | translate }} <strong>{{ groupName }}</strong> ?</p>
  <p [id]="groupName" *ngIf="preferredLanguage == 'de'" [innerHTML]="'USERSGROUPS.GROUPS.REMOVE_GROUP' | translate: { value: groupName }"></p>
</div>
<div mat-dialog-actions>
  <button mat-button id="cancel-remove-group" [mat-dialog-close]="true" (click)="close()" color="primary" class="ezp-button" [disabled]="isLoading">
    {{ 'CANCEL' | translate }}
  </button>
  <button mat-button id="remove-group" (click)="removeGroup()" color="primary" class="ezp-button" [disabled]="isLoading">
    <mat-icon [ngClass]="{ 'fa-spin': isLoading }" *ngIf="isLoading">cached</mat-icon>
    {{ 'REMOVE' | translate }}
  </button>
</div>