import { NgModule, ModuleWithProviders }    from '@angular/core';
import { LocalStorageService }              from './services/local-storage.service';
import { SessionStorageService }            from './services/session-storage.service';

@NgModule()
export class StorageModule {
  static forRoot(): ModuleWithProviders<StorageModule> {
    return {
      ngModule: StorageModule,
      // exports: [
      //   StorageUtils,
      //   LocalStorage,
      //   SessionStorageService,
      //   LocalStorageService,
      //   StorageModule
      // ],
      providers: [
        LocalStorageService,
        SessionStorageService
      ]
    };
  }
}