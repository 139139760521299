import { NgModule, ModuleWithProviders }                                     from '@angular/core';
import { MaterialModule }                               from '../material/material.module';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    LanguageSwitcherComponent
  ],
  exports: [
    LanguageSwitcherComponent
  ],
  imports: [
    SharedModule,
    MaterialModule
  ]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
        ngModule: CoreModule,
        providers: []
    };
}
}
