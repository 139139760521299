import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import posthog from 'posthog-js'
import { AppModule } from './app/app.module'
import * as Sentry from '@sentry/angular'
import { BrowserClient } from '@sentry/browser'
import {
  API_BASE_URL,
  SENTRY_DNS,
  POSTHOG_API_KEY,
  POSTHOG_HOST,
  env,
  isProd,
} from './app/app.config'
import {
  AuthUserApiService,
  AuthOrganizationApiService,
} from './app/modules/shared/services/auth-api.service'
import { StorageUtils } from './app/modules/storage/utils/storage.utils'

// Initialize Sentry
Sentry.init({
  dsn: SENTRY_DNS,
  environment: env,
  release: 'ezeep-frontend@0.0.0',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: false,
    }),
  ],

  // Performance Monitoring
  tracesSampleRate: isProd ? 0.05 : 1.0,

  // Session Replay
  replaysSessionSampleRate: isProd ? 0.05 : 1.0,
  replaysOnErrorSampleRate: 1.0,

  // General event sample rate
  sampleRate: isProd ? 0.2 : 1.0,

  // Enable debug mode only in development
  debug: false,

  // Transport configuration
  transportOptions: {
    headers: {
      Origin: 'https://o4507928884346880.ingest.de.sentry.io',
    },
  },

  // Configure allowed domains for tracing
  allowUrls: [
    'localhost',
    'develop.dev.azdev.ezeep.com',
    'https://app.dev.azdev.ezeep.com/',
    'https://app.tst.azdev.ezeep.com/',
    'https://app.ezeep.com/',
    'https://o4507928884346880.ingest.de.sentry.io',
  ],

  beforeSend(event) {
    // Add additional context
    event.tags = {
      ...event.tags,
      origin: window.location.origin,
      environment: env,
    }

    // Filter out ExpressionChangedAfterItHasBeenCheckedError in production
    if (event.exception?.values?.[0]?.type === 'ExpressionChangedAfterItHasBeenCheckedError') {
      return null
    }

    return event
  },
})

if (isProd) {
  enableProdMode()
  console.log('Production mode enabled')
}

// Initialize PostHog with environment-specific configuration
posthog.init(POSTHOG_API_KEY, {
  api_host: POSTHOG_HOST,
  person_profiles: 'identified_only',
  capture_pageview: true,
  capture_pageleave: true,
  disable_session_recording: true,
  autocapture: true,
  capture_heatmaps: true,
  loaded: (posthog) => {
    // Set environment based on current environment
    if (!isProd) {
      posthog.opt_in_capturing()
    }
  },
})

// Get user details and set up PostHog identification
const platformPromise = platformBrowserDynamic().bootstrapModule(AppModule)
platformPromise.then((appRef) => {
  const authService = appRef.injector.get(AuthUserApiService)
  const orgService = appRef.injector.get(AuthOrganizationApiService)

  authService.me().subscribe((response) => {
    const userId = response.result.user_id
    const orgId = response.result.organization_id

    // Save to session storage
    StorageUtils.set(sessionStorage, 'user_id', userId)
    StorageUtils.set(sessionStorage, 'organization_id', orgId)

    // Identify user in PostHog
    posthog.identify(userId)

    // Fetch organization details
    orgService.get(orgId).subscribe((orgResponse) => {
      posthog.group('organization', orgId, {
        name: orgResponse.result.name,
        value: orgResponse.result.id,
      })
    })
  })
})

platformPromise.catch((err) => Sentry.captureException(err))
