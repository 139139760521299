import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, observable, of } from 'rxjs';
import { AuthUserApiService, AuthUser, AuthMe } from './auth-api.service';
import { UserPrintingService } from './printing-api.service';
import { map } from 'rxjs/operators';
import { GeneralUtil } from '../utils/general-util';
import { DEFAULT_PAGE_SIZE } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class UsersgroupsUsersService {
  users: any = [];
  usersSubject = new BehaviorSubject<any>({ isLoading: true, count: 0, users: []});
  selectedFieldSubject = new BehaviorSubject<string>('');
  pageIndex: number = 0;
  pageSize: number = DEFAULT_PAGE_SIZE;
  sorting: any;
  usersCount: any = 0;
  searchText: string;
  draftedUser: AuthUser;

  constructor(private authUserService: AuthUserApiService, private userPrintingService: UserPrintingService) { }

  getFilteredUsers() : Observable<any> {
    return this.usersSubject.asObservable();
  }

  getSelectedField(): Observable<any> {
    return this.selectedFieldSubject.asObservable();
  }

  applyFilters(showLoading: boolean = true) {
    const searchText = this.searchText
    this.usersSubject.next({ isLoading: showLoading, count: this.usersCount, users: [], pageSize: this.pageSize, pageIndex: this.pageIndex });
    this.authUserService.list(searchText, this.sorting, this.pageSize, this.pageIndex * this.pageSize).subscribe({
      next: async response => {
        if (searchText !== response.result.searchText) return //block older searches to show up
        this.users = response.result.results;
        this.usersCount = response.result.count;
        this.usersSubject.next({
          isLoading: false,
          count: response.result.count,
          users: response.result.results,
          pageSize: this.pageSize,
          pageIndex: this.pageIndex
        });
      }, error: error => {
        this.usersSubject.next({ isLoading: false, count: this.usersCount, users: this.users, pageSize: this.pageSize, pageIndex: this.pageIndex });
      }
    });
  }
  
  unselectUsers(users: any[]) {
    this.users.forEach(item => {
      if(users){
        item.isSelected = users.includes(item) ? false : item.isSelected;
      } else {
        item.isSelected = false;
      }
    });
    this.usersSubject.next({isLoading: false, count: this.usersCount, users: this.users});
  }

  selectUsers(users: any[]) {
    this.users.forEach(item => (
      item.isSelected = users.includes(item) ? true : item.isSelected)
    );
    this.usersSubject.next({isLoading: false, count: this.usersCount, users: this.users});
  }

  editUser(userId: string) {
    if (userId)
      this.users.forEach(item => item.toEdit = item.id == userId ? true : false);
    else
      this.users.forEach(item => item.toEdit = item.isSelected ? true : false);

    this.usersSubject.next({isLoading: false, count: this.usersCount, users: this.users});
  }

  removeUser(id: any) {
    return this.authUserService.delete(id);
  }

  draftUserDetails(user: AuthUser) {
    this.draftedUser = user;
  }

  selectedUserField(field: string) {
    this.selectedFieldSubject.next(field);
  }

  saveUserDetails(): Observable<any> {
    if(!this.draftedUser)
      return of(true);

    if(!this.draftedUser.roles || this.draftedUser.roles.length == 0)
        this.draftedUser.roles = ['user']
    
    return this.authUserService.patch(this.draftedUser.id, this.draftedUser)
      .pipe(map(() => {
        this.draftedUser = undefined;
        return true;
      }));
  }

  setPage(pageIndex: number, pageSize: number, isInitial = false) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    if (!isInitial) {
      this.applyFilters(true);
    }
  }

  setSorting(sorting) {
    this.sorting = sorting;
    this.applyFilters(false);
  }
  
  setSearchText(result: string) {
    this.searchText = result
    this.pageIndex = 0
  }
  
  public getSelectedUsers(): any[] {
    if(!this.users) return [];
    return this.users.filter(user => user.isSelected);
  }

  public getUserToEdit(): AuthUser {
    if(!this.users || this.users.length == 0) return null;
    
    let user = this.users.filter(user => user.toEdit)[0];
    if(!user) return null;
    user.source = user.azure_profile ? "AZURE" : "EZEEP";

    return user;
  }

  public getSelectedUsersCount(): number {
    return this.getSelectedUsers().length;
  }

  public getAuthUser(id: any) : Observable<AuthUser> {
    return this.authUserService.read(id).pipe(map(response => {
      return response.result;
    }));
  }

  public getAuthMe() : Observable<AuthMe> {
    return this.authUserService.me().pipe(map(response => {
      return response.result;
    }));
  }
}
