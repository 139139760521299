<ezd-table-placeholder *ngIf="!failedEventsTableDataSource.data.length" [columns]="['Date','Name']" [rows]="6"
    [heading]="'SETTINGS_PAGE.WEBHOOKS.FAILED_EVENTS.MESSAGE' | translate"></ezd-table-placeholder>
<div class="ezp-table" *ngIf="failedEventsTableDataSource.data.length">
    <table class="ezp-table__data" mat-table [dataSource]="failedEventsTableDataSource" matSort
        (matSortChange)="sortChange($event)" matSortDisableClear="true">
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="ezp-table__cell ezp-table__cell--text"
                id="header-failedEvent-date">
                {{ 'SETTINGS_PAGE.WEBHOOKS.FAILED_EVENTS.DATE' | translate }}
            </th>
            <td mat-cell *matCellDef="let failedEvent" class="ezp-table__cell ezp-table__cell--text"
                id="{{failedEvent.event_id + '-created'}}">
                {{formatDate(failedEvent.created)}}
            </td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--text"
                id="header-failedEvent-name">
                {{ 'SETTINGS_PAGE.WEBHOOKS.FAILED_EVENTS.NAME' | translate }}
            </th>
            <td mat-cell *matCellDef="let failedEvent" class="ezp-table__cell ezp-table__cell--text"
                id="{{failedEvent.event_id + '-status'}}">
                {{error_codes[failedEvent.endpoint.error] ? error_codes[failedEvent.endpoint.error] :
                error_codes['unknown']}} {{failedEvent.endpoint.response_status_code }}
            </td>
        </ng-container>
        <ng-container matColumnDef="triggers">
            <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--text"
                id="header-failedEvent-triggers">
                {{
                'SETTINGS_PAGE.WEBHOOKS.FAILED_EVENTS.TRIGGERS' | translate
                }}
            </th>
            <td mat-cell *matCellDef="let failedEvent" class="ezp-table__cell ezp-table__cell--text"
                id="{{failedEvent.event_id + '-trigger'}}">
                {{ triggers_localization[failedEvent.topic] }}
            </td>
        </ng-container>
        <ng-container matColumnDef="retry">
            <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--text"
                id="header-failedEvent-retry">
                <button *ngIf="!isRetryAllInProgress" (click)="retryAll()" mat-button
                    id="header-failedEvent-retry-all-button" color="primary" class="ezp-button"> {{
                    'SETTINGS_PAGE.WEBHOOKS.FAILED_EVENTS.RETRY' | translate
                    }}</button>
                <div *ngIf="isRetryAllInProgress" class="ezp-table__processing">
                    <mat-spinner class="ezp-table__spinner"></mat-spinner>
                </div>
            </th>
            <td mat-cell *matCellDef="let failedEvent" class="ezp-table__cell ezp-table__cell--text"
                id="{{failedEvent.event_id + '-retry'}}">
                <button *ngIf="!failedEvent.isRetrying" [disabled]="failedEvent.isDisabled" mat-icon-button
                    color="primary" (click)="retry(failedEvent.event_id)">
                    <mat-icon>refresh</mat-icon>
                </button>
                <div class="ezp-table__processing" *ngIf="failedEvent.isRetrying">
                    <mat-spinner class="ezp-table__spinner"></mat-spinner>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnDefs"></tr>
        <tr mat-row *matRowDef="let failedEvent; columns: columnDefs"
            [ngClass]="{ 'disabled-row': failedEvent.isDisabled || failedEvent.isRetrying }"></tr>
    </table>
    <mat-spinner *ngIf="isLoading" class="ezp-spinner ezp-spinner--large ezp-spinner--table"></mat-spinner>
    <mat-paginator *ngIf="!isLoading" class="efa-paginator" id="printers-paginator" #paginator [length]="maxPageLength"
        [pageSize]="defaultPageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
        (page)="onPaginationChange($event)">
    </mat-paginator>
</div>