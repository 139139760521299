import { Component, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'

@Component({
  selector: 'ezd-verify-email-confirmed-dialog',
  templateUrl: './verify-email-confirmed-dialog.component.html',
  styleUrls: ['./verify-email-confirmed-dialog.component.scss'],
})
export class VerifyEmailConfirmedDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<VerifyEmailConfirmedDialogComponent>) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close()
  }
}
