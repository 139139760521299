import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MappingsService, AssignmentsService, ConnectorsService, DriversService, FiltersService, GroupsService, PrintersService, ProfileService, UserPrintingService } from './printing-api.service';
import { EZEEP_PRINTING_API, EZEEP_ADCON_API, EZEEP_SUBSCRIPTION_API, EZEEP_AZURE_ACCOUNT_URL, EZP_CHARGEBEE_URL, EZEEP_REPORTING_API } from '../../../app.config';
import { printerApiUrl, RequesterApiService, adconApiUrl, subscriptionApiUrl, authApiUrl, ezpChargebeeApiUrl, reportingApiUrl } from './requester-api.service';
import { AdconGroupsService } from './adcon-api.service';
import { AuthUserApiService } from './auth-api.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    RequesterApiService,
    MappingsService,
    AssignmentsService,
    ConnectorsService,
    DriversService,
    FiltersService,
    GroupsService,
    PrintersService,
    ProfileService,
    UserPrintingService,
    AdconGroupsService,
    AuthUserApiService,
    {provide: printerApiUrl, useValue: EZEEP_PRINTING_API},
    {provide: adconApiUrl, useValue: EZEEP_ADCON_API},
    {provide: subscriptionApiUrl, useValue: EZEEP_SUBSCRIPTION_API },
    {provide: authApiUrl, useValue: EZEEP_AZURE_ACCOUNT_URL },
    {provide: ezpChargebeeApiUrl, useValue: EZP_CHARGEBEE_URL },
    {provide: reportingApiUrl, useValue: EZEEP_REPORTING_API }
  ]
})
export class RequesterApiModule { }
