import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, AfterContentInit, AfterViewChecked, AfterContentChecked, HostBinding } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { map } from 'rxjs/operators'
import { AuthenticationService } from '../../modules/authentication/services/authentication.service'
import { AuthUserApiService } from '../../modules/shared/services/auth-api.service'
import { SubscriptionApiService } from '../../modules/shared/services/subscription-api.service'
import {
  CUSTOM_SUBSCRIPTION,
  SubscriptionService,
} from '../../modules/shared/services/subscription.service'
import { PlanSelectionComponent } from './plan-selection/plan-selection.component'
import { TranslateService } from '@ngx-translate/core';
import { StorageUtils } from '../../modules/storage/utils/storage.utils'
import { LangaugeService } from '../../modules/shared/services/langauge.service'
import { SUBSCRIPTION_BANNER } from '../../modules/storage/constants/storage.constants'

type PeriodType = 'monthly' | 'annually'

@Component({
  selector: 'ezd-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
  @HostBinding('class.has-banner-ad') showBannerAd: boolean = true;

  plans: any;
  subscriptionStatus: String = ''
  subscription: any
  baseUrl = 'https://assets.ezeep.com/wp-json'
  route = 'ezp/v1'
  endpoint = 'translations'
  language: string;
  currencyCode: string
  period: PeriodType = 'annually'
  translations: any = {}
  sourceType: string
  planId: string
  subscriptionId: any
  activePlanMonthly: any
  status: string
  customSubscription: boolean
  preferredLanguage: string;
  browserLanguage: string;
  activeFreePlan: boolean;
  activeProPlan: boolean;
  // pricingbaseUrl = 'https://ezeepassetsstg.wpengine.com/wp-json'
  // pricingRoute = 'ezp/v2'
  // pricingEndpoint = 'pricing'
  // pricingLanguage = 'en'
  // plans: any
  // categories: any
  // activeMonthlyPlan: any
  // status: any

  constructor(
    private subscriptionService: SubscriptionService,
    private authService: AuthUserApiService,
    private subscriptionApiService: SubscriptionApiService,
    private authApiService: AuthUserApiService,
    private translateService: TranslateService,
    private languageService: LangaugeService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.subscriptionService.getSubscription().subscribe((response) => {
      //WARNING! last created subscription is the one to be verified
      this.subscription = response
      this.currencyCode = this.subscription.source.subscription.currency_code
      this.sourceType = this.subscription.source_type
      this.planId = this.subscription.source.plan_id
      this.status = this.subscription.status
      this.subscriptionId = this.subscription.source.subscription_id
      if (!this.subscriptionStatus) return
      this.handlePeriod('annually')
      this.languageService.getPreferredLang$.subscribe((value) => {
        this.preferredLanguage = value
      })
      if (this.preferredLanguage) {
        this.getTranslations()
      }

      this.subscriptionService.plansArray$.subscribe(value => {

        this.plans = value;
        this.plans.map(plan => this.planId === plan['item_price_id_' + this.currencyCode.toLowerCase()]['monthly'] && plan.title !== 'Free').includes(true) ? this.handlePeriod('monthly') : this.handlePeriod('annually')
        this.subscriptionService.setActiveFreePlan((this.plans.map(plan => this.planId === plan['item_price_id_' + this.currencyCode.toLowerCase()]['monthly'] && plan.title === 'Free').includes(true)) || (this.plans.map(plan => this.planId === plan['item_price_id_' + this.currencyCode.toLowerCase()]['annually'] && plan.title === 'Free').includes(true)) ? true : false)
        this.subscriptionService.setActiveProPlan((this.plans.map(plan => this.planId === plan['item_price_id_' + this.currencyCode.toLowerCase()]['monthly'] && plan.title === 'Pro').includes(true)) || (this.plans.map(plan => this.planId === plan['item_price_id_' + this.currencyCode.toLowerCase()]['annually'] && plan.title === 'Pro').includes(true)) ? true : false)

        this.subscriptionService.activeFreePlan$.subscribe((value) => {
          this.activeFreePlan = value
        })
      })
    })
    this.checkAdBanner()
    this.subscriptionStatus = 'CUSTOM_SUBSCRIPTION'
    this.subscriptionService.getSubscription().subscribe(async (result) => {
      // EFA-3469
      // if (result.status_type == ACTIVE_SUBSCRIPTION && currentUser.result.user_id && currentUser.result.user_id !== result.source.customer.cf_billing_admin_id)
      //   this.subscriptionStatus = "INCORRECT_ADMIN"
      // else
      this.subscriptionStatus = result.status_type
      this.customSubscription = result.source_type == "chargebee" && result.source && result.source.ezeep_link == "Custom"
    })

  }

  /**
   * Get string translations.
   */
  getTranslations(): void {
    this.http
      .get(`${this.baseUrl}/${this.route}/${this.endpoint}/${this.preferredLanguage}`)
      .subscribe((data) => {
        this.translations = data['subscriptions']
      })
  }

  /**
   * Updates the current period.
   *
   * @param {PeriodType}    period    The period.
   */
  handlePeriod(period: PeriodType) {
    this.period = period
  }


  dismissBanner() {
    StorageUtils.set(localStorage, SUBSCRIPTION_BANNER, true)
    this.checkAdBanner()
  }

  checkAdBanner() {
    this.showBannerAd = StorageUtils.get(localStorage, SUBSCRIPTION_BANNER) ? false : true
  }
}
