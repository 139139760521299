<div class="ezp-grid ezp-grid--spacing-vertical" data-grid-cols="1">
  <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
    <mat-label id="users-search-printers-text">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_PRINTERS.SEARCH_PRINTERS' |
      translate }}</mat-label>
    <input matInput id="printer-name"
      [placeholder]="'USERSGROUPS.USERS.USER_DETAILS.USER_PRINTERS.PRINTER_NAME' | translate"
      (input)="onSearchChange($event.target.value)" />
  </mat-form-field>
</div>

<ezd-infinity-scroll (scrolled)="onScroll()" style="height: 300px; overflow-y: auto">
  <mat-selection-list #printers *ngIf="printerSelection == true" class="ezp-list ezp-list--filterable" id="group-printers-list" (selectionChange)="onSelection($event, printers.selectedOptions)">
    <mat-list-option
      *ngFor="let printer of assignedPrinters"
      checkboxPosition="before"
      color="primary"
      [selected]="printer.isSelected"
      [disabled]="printer.isDisabled"
      [value]="printer"
      [id]="printer.name"
    >
      <span mat-line>{{ printer.name }}</span>
      <div matLine>
        <div class="ezp-list__meta">
          <span mat-line id="group-printers-location-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_PRINTERS.LOCATION' | translate }}: {{ printer.location }}</span>
          <span mat-line id="group-printers-connector-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_PRINTERS.CONNECTOR' | translate }}: {{ printer.connector.readable_name }}</span>
        </div>
      </div>

      <mat-divider [inset]="true"></mat-divider>
    </mat-list-option>
  </mat-selection-list>
  <mat-selection-list #printers *ngIf="printerSelection == false" class="ezp-list ezp-list--filterable" id="group-printers-list" (selectionChange)="onSelection($event, printers.selectedOptions)">
    <mat-list-option
      *ngFor="let printer of assignedPrinters"
      checkboxPosition="before"
      color="primary"
      [selected]="printer.isSelected && printer.source != 'My Printers' && printerSelection == false"
      [disabled]="printer.isDisabled && printer.source != 'My Printers' && printerSelection == false"
      [value]="printer"
      [id]="printer.name"
    >
      <span mat-line>{{ printer.name }}</span>
      <div matLine>
        <div class="ezp-list__meta">
          <span mat-line id="group-printers-location-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_PRINTERS.LOCATION' | translate }}: {{ printer.location }}</span>
          <span mat-line id="group-printers-connector-text"> dscsdc{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_PRINTERS.CONNECTOR' | translate }}: {{ printer.connector.readable_name}}</span>
        </div>
      </div>

      <mat-divider [inset]="true"></mat-divider>
    </mat-list-option>
  </mat-selection-list>
  <div class="ezp-list__fetching" *ngIf="isLoading">
    <mat-spinner class="ezp-list__fetching__spinner ezp-list__fetching__spinner--selection"></mat-spinner>
    <span class="ezp-list__fetching__title" id="group-printers-fetchingprinters-text">{{
      'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_PRINTERS.FETCHING_PRINTERS' | translate }}</span>
  </div>
</ezd-infinity-scroll>