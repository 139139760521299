import { Component, Input } from '@angular/core'

@Component({
  selector: 'ezd-banner-ad',
  templateUrl: './banner-ad.component.html',
  styleUrls: ['./banner-ad.component.scss'],
})
export class BannerAdComponent {
  @Input() imageSrc: string
}
