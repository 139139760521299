import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { ApiResult, ezpChargebeeApiUrl, RequesterApiService } from './requester-api.service';

export interface IEzpChargebeeApiService {
  checkout(chargebeeSubscriptionId: string, planId: string)
  portal(customerId: string)
}

@Injectable({
  providedIn: 'root'
})
export class EzpChargebeeApiService extends RequesterApiService implements IEzpChargebeeApiService{

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(ezpChargebeeApiUrl) baseUrl?: string) {
    super(http, baseUrl ? baseUrl : "/integrations/chargebee");
  }

  checkout(chargebeeSubscriptionId: string, planId: string) {
    let url_ = this.baseUrl + "/hosted_pages/checkout_existing";
    url_ = url_.replace(/[?&]$/, "");

    //const content_ = JSON.stringify({embed: true, iframe_messaging: true, subscription[id]: chargebeeSubscriptionId});

    const content_ = new HttpParams()
    .set('embed', 'false')
    .set('subscription[id]', chargebeeSubscriptionId)
    .set('subscription[plan_id]', planId)
    // content_.append('embed', 'false');
    // //content_.append('iframe_messaging', 'true');
    // content_.append('subscription[id]', chargebeeSubscriptionId.toString());

    let options_: any = {
      body: content_.toString(),
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    return this.processRequest("post", url_, options_, CheckoutResult);
  }
  
  portal(customerId: string) {
    let url_ = this.baseUrl + "/portal_sessions";
    url_ = url_.replace(/[?&]$/, "");


    const content_ = new HttpParams()
    .set('customer[id]', customerId)

    let options_: any = {
      body: content_.toString(),
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    };

    return this.processRequest("post", url_, options_, PortalResult);
  }
}

export class CheckoutResult extends ApiResult implements ICheckoutResult {
  id: string;
  type: string;
  url: string;
  state: string;
  embed: boolean;
  created_at: number;
  expires_at: number;
  object: string;
  updated_at: number;
  resource_version: number;

  constructor(data?: ICheckoutResult) {
    super();
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
  

  init(data?: any) {
    if (data) {
      (<any>this).id = data["id"];
      (<any>this).type = data["type"];
      (<any>this).url = data["url"];
      (<any>this).state = data["state"];
      (<any>this).embed = data["embed"];
      (<any>this).created_at = data["created_at"];
      (<any>this).expires_at = data["expires_at"];
      (<any>this).object = data["object"];
      (<any>this).updated_at = data["updated_at"];
      (<any>this).resource_version = data["resource_version"];
    }
  }

  fromJS(data: any): CheckoutResult {
    data = typeof data === 'object' ? data : {};
    let result = new CheckoutResult();
    result.init(data);
    return result;
  }
}

export interface ICheckoutResult {
  id: string
  type: string
  url: string
  state: string
  embed: boolean
  created_at: number
  expires_at: number
  object: string
  updated_at: number
  resource_version: number
}

export class PortalResult extends ApiResult implements IPortalResult {
  id: string
  token: string
  access_url: string
  status: string
  created_at: string
  expires_at: string
  object: string
  customer_id: string
  redirect_url: string

  constructor(data?: IPortalResult) {
    super();
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }
  

  init(data?: any) {
    if (data) {
      (<any>this).id = data["id"];
      (<any>this).token = data["token"];
      (<any>this).access_url = data["access_url"];
      (<any>this).state = data["status"];
      (<any>this).created_at = data["created_at"];
      (<any>this).expires_at = data["expires_at"];
      (<any>this).object = data["object"];
      (<any>this).customer_id = data["customer_id"];
      (<any>this).redirect_url = data["redirect_url"];
    }
  }

  fromJS(data: any): PortalResult {
    data = typeof data === 'object' ? data : {};
    let result = new PortalResult();
    result.init(data);
    return result;
  }
}

export interface IPortalResult {
  id: string
  token: string
  access_url: string
  status: string
  created_at: string
  expires_at: string
  object: string
  customer_id: string
  redirect_url: string
}

