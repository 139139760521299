import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, env } from '../../../app.config';
import { UsersgroupsDomainsService } from '../../../modules/shared/services/usersgroups-domains.service';
import { DomainInformationComponent } from './domain-information/domain-information.component'
import { SubscriptionService } from '../../../modules/shared/services/subscription.service';

@Component({
  selector: 'ezd-usersgroups-domains',
  templateUrl: './domains.component.html',
  styleUrls: ['./domains.component.scss']
})
export class DomainsComponent implements OnInit {
  columnDefs = ['domains', 'groups', 'edit'];//['select', 'name', 'description', 'source', 'edit']
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('searchDomainsList') search: ElementRef;
  maxPageLength = 100;
  defaultPageSize = DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  pageIndex: number;
  pageSize: number = DEFAULT_PAGE_SIZE;
  isLoading: boolean;
  sorting: string;
  disableAddUsers = true;
  disableRemoveUsers = true;
  disableRenameGroup = true;
  showDialog: boolean;
  operationType: string;
  domainsSubscription: Subscription;
  @Output() showGroupDialog: EventEmitter<any> = new EventEmitter<any>();
  domainsDataSource = new MatTableDataSource([]);
  env: string;
  isAzureProfile: boolean;
  isEzeepOrg: any;
  searchTextChanged = new Subject<string>();
  isAvailable: boolean;

  constructor(private domainsService: UsersgroupsDomainsService,
    private subscriptionService: SubscriptionService,
    private dialog: MatDialog,) {
    this.env = env
  }

  ngOnInit() {
    this.domainsDataSource.sort = this.sort;
    this.domainsService.setPage(0, this.defaultPageSize, true);
    this.domainsSubscription = this.domainsService.getFilteredDomains()
      .subscribe(result => {
        if (!result.domains) return;
        this.isLoading = result.isLoading;
        this.domainsDataSource.data = result.domains;
        this.pageIndex = result.pageIndex;
        this.pageSize = this.defaultPageSize;
        this.maxPageLength = result.count;
        setTimeout(() => {
          if (this.search) {
            this.search.nativeElement.focus();
          }
        }, 500);
        if (result.domains.length > 0) {
          this.processGroupsNames(result.domains);
        }
      });
    this.domainsService.applyFilters()
    this.domainsService.setSearchText(null);
    this.searchTextChanged
      .pipe(
        debounceTime(400))
      .subscribe(result => {
        this.domainsService.setSearchText(result);
      })
      this.subscriptionService.getSubscription().subscribe((result) => {
        this.isAvailable = result.features && result.features.domain_assignments == true ? true : false;
      })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.domainsSubscription) this.domainsSubscription.unsubscribe();
  }

  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue);
  }

  private processGroupsNames(domains: [any]) {
    domains.forEach((p: any) => {
      p.groupsNames = p.groups.length == 0 ? "-" : p.groups.map(obj => obj.name).join(', ');
    })
  }

  openDomainDetailsDialog(id: string | undefined, create: boolean) {
    this.domainsService.cancelDomainToEdit();
    if (!create)
      this.domainsService.editDomain(id);
    else
      this.domainsService.createDomain();

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.width = '600px';
    dialogConfig.panelClass = 'ezp-dialog';

    this.dialog.open(DomainInformationComponent, dialogConfig);
  }

  addDomainDialog(id: string | undefined, create: boolean) {
    this.domainsService.cancelDomainToEdit();
    if (!create)
      this.domainsService.editDomain(id);
    else
      this.domainsService.createDomain();

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.width = '600px';
    dialogConfig.panelClass = 'ezp-dialog';
    dialogConfig.data = create;

    this.dialog.open(DomainInformationComponent, dialogConfig);
  }

  public onPaginationChange(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.domainsService.setPage(this.pageIndex, this.pageSize);
  }

  sortChange(e) {
    let colName = '';
    if (e.active === 'domains') {
      colName = 'expression';
    } else {
      colName = e.active;
    }

    const direction = e.direction === 'desc' ? '-' : e.direction === 'asc' ? '' : undefined;
    if (direction === undefined) {
      this.sorting = colName;
    } else {
      this.sorting = direction + colName;
    }

    this.domainsService.setSorting(this.sorting);
  }

}
