<div class="ezp-layout__page">
    <div class="ezp-layout__content">
        <h1 class="mat-headline" id="edocs-title-text">{{ 'DOCUMENTS_PAGE.DOCUMENTS' | translate }}</h1>
        <div #flutterTarget>
            <div class="spinner">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
</div>
