import { Component, OnInit } from '@angular/core'
import { UsersgroupsUsersPrintersService } from '../../../../modules/shared/services/usersgroups-users-printers.service'
import { UsersgroupsUsersService } from '../../../../modules/shared/services/usersgroups-users.service'
import { Subject, Subscription, lastValueFrom } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { PoliciesMyprintersService } from '../../../../modules/shared/services/policies-myprinters.service';
import { PrinterProfileService } from '../../../../modules/shared/services/printer-profile.service';
import { PRINTERS } from '../../../pages.constants';
import { GeneralUtil } from '../../../../modules/shared/utils/general-util';
import { ErrorService } from '../../../../modules/shared/services/error.service';

@Component({
  selector: 'ezd-user-details-printers',
  templateUrl: './user-details-printers.component.html',
  styleUrls: ['./user-details-printers.component.scss'],
})
export class UserDetailsPrintersComponent implements OnInit {
  public get policiesMyPrintersService(): PoliciesMyprintersService {
    return this._policiesMyPrintersService;
  }
  public set policiesMyPrintersService(value: PoliciesMyprintersService) {
    this._policiesMyPrintersService = value;
  }
  assignedPrinters: Array<any> = []
  isLoading: boolean = true;
  endOfList: boolean = false;
  user: any;
  // isAzureGroup: boolean;
  searchTextChanged = new Subject<string>();
  searchText: string;
  AllPrintersSubscription: Subscription;
  allPrinters: any = [];
  currentPageIndex: number = 0;
  printerSelection: any;
  // profiles: any;
  // newAssignments = []; 
  
  constructor(private userPrintersService: UsersgroupsUsersPrintersService,
    private usersService: UsersgroupsUsersService,
    private _policiesMyPrintersService: PoliciesMyprintersService) {}

  async ngOnInit(): Promise<void> {
    this.user = this.usersService.getUserToEdit();
    this.policiesMyPrintersService.getCurrentMyPrintersEnableStatus()
      .subscribe(result => {
        this.printerSelection = result;
      });
    this.AllPrintersSubscription = this.userPrintersService.getAllPrinters(this.searchText, this.user.id).subscribe(async result => {
      if (!result.isLoading && result.printers) {
        this.allPrinters = [...this.allPrinters, ...result.printers]
        this.allPrinters = GeneralUtil.removeDuplicates(this.allPrinters)
        const [
          assignedGroupPrinterList,
          assignedMyPrinterList,
          // groupPrinterPresets,
          assignedPrintersUser,
        ] = await Promise.all([
          lastValueFrom(this.userPrintersService.getAssignedPrintersGroupsToUser(this.user.id)),
          lastValueFrom(this.userPrintersService.getMyPrintersList(this.user.id, 100, 0)),
          // lastValueFrom(this.profileService.getGroupedPrinterPresets(null, PRINTERS, 1000, 0)),
          lastValueFrom(this.userPrintersService.getAssignedPrintersToUser(this.user.id, 100, 0)),
        ]);
        this.allPrinters.map(p => {
          p.isSelected = false
          p.isDisabled = false

          if (assignedPrintersUser.filter(ap => ap.printer_id == p.id).length > 0) {
            p.isSelected = true
            // p.isDisabled = false
            p.source = undefined
          }
          if (assignedGroupPrinterList.filter(agp => agp.printer_id == p.id).length > 0) {
            p.isDisabled = true
            p.isSelected = true
            p.source = assignedGroupPrinterList.filter(agp => agp.printer_id == p.id)[0].source
          } else if (assignedMyPrinterList.filter(amyp => amyp.id == p.id).length > 0 &&
            assignedPrintersUser.filter(ap => ap.printer_id == p.id).length == 0) {
            p.isDisabled = true
            p.isSelected = true
            p.source = 'My Printers'
          }
        })
        this.assignedPrinters = [
          ...this.allPrinters.filter(x => x.isDisabled && x.source != "My Printers"),
          ...this.allPrinters.filter(x => x.isDisabled && x.source == "My Printers"),
          ...this.allPrinters.filter(x => x.isSelected && !x.isDisabled && !x.source), 
          ...this.allPrinters.filter(x => !x.isSelected && !x.isDisabled && !x.source)]
        
        // this.assignedPrinters = GeneralUtil.findAndAssignPrinterPresets(this.assignedPrinters, groupPrinterPresets)
        // this.assignedPrinters = GeneralUtil.addselectDisabledToArray(this.assignedPrinters, false)
      }
      // we are maintaining new assignments
      // this.newAssignments = [...this.newAssignments, ...this.assignedPrinters.filter(g => g.isSelected && !g.isDisabled)]
      this.isLoading = result.isLoading;
      this.endOfList = result.endOfList;
      if (this.endOfList) {
        this.currentPageIndex = 0
      }
    })

    this.searchTextChanged
      .pipe(
        debounceTime(400))
      .subscribe(result => {
        this.searchText = result
        this.isLoading = true
        this.assignedPrinters = []
        this.allPrinters = []
        this.userPrintersService.setAllPrinters(result, this.user.id, 100, 0);
      })

    // getProfiles
    // this.profiles = await lastValueFrom(this.profileService.getProfiles(1000, 0))
   
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.AllPrintersSubscription.unsubscribe()
    this.assignedPrinters = [];
    this.endOfList = false;
    this.isLoading = true;
    this.user = undefined;
  }

  onScroll() {
    if(this.endOfList == undefined || this.endOfList || this.isLoading) return;
    this.isLoading = true;
    this.currentPageIndex += 1
    this.userPrintersService.setAllPrinters(this.searchText, this.user.id, 100, 100 * this.currentPageIndex);
  }

  onSelection(e, v) {
    let currentAssignedPrinters = this.assignedPrinters.filter(g => g.isSelected && !g.isDisabled)
    // let newAssignments = v.selected.map(u => {
    //   return u.value
    // })
    let newAssignments = v.selected.map(u => {
      if(!u.value.isDisabled)
        return u.value
    }).filter(x => x != undefined)
    this.userPrintersService.draftAssignedPrinters(currentAssignedPrinters, newAssignments)
  }


  // onSelection(e) {
  //   let currentAssignedPrinters = this.assignedPrinters.filter(g => g.isSelected && !g.isDisabled)

  //   if (e.checked) {
  //     this.newAssignments.push(e.source.value)
  //   } else {
  //     let indexToRemove = this.newAssignments.findIndex(obj => obj.id === e.source.value.id);
  //     if (indexToRemove !== -1)
  //       this.newAssignments.splice(indexToRemove, 1);

  //   }
  //   this.userPrintersService.draftAssignedPrinters(currentAssignedPrinters, this.newAssignments)
  // }

  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue);
  }

  // profileSelection(isChecked, printer, type, newprinterId, profile_id) {
  //   this.assignedPrinters = GeneralUtil.addselectDisabledToArray(this.assignedPrinters, true)
  //   if (isChecked) {
  //     this.profileService.updateAssignments(PRINTERS, profile_id, newprinterId).subscribe(
  //       {
  //         next: () => {
  //           this.updatePresetsView()
  //         }, error: error => {
  //           this.errorService.openTranslate(error, "USERSGROUPS.ERROR")
  //         }
  //       }

  //     );
  //   } else {
  //     this.profileService.deleteAssignments(profile_id, newprinterId, type).subscribe({
  //       next: () => {
  //         this.updatePresetsView()
  //       }, error: error => {
  //         this.errorService.openTranslate(error, "USERSGROUPS.ERROR")
  //       }
  //     });
  //   }
  // }

  // async updatePresetsView() {
  //   let groupPrinterPresets = await lastValueFrom(this.profileService.getGroupedPrinterPresets(null, PRINTERS, 1000, 0))
  //   this.assignedPrinters = GeneralUtil.findAndAssignPrinterPresets(this.assignedPrinters, groupPrinterPresets)
  //   this.assignedPrinters = GeneralUtil.addselectDisabledToArray(this.assignedPrinters, false)
  // }
}
