import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusMessageDialogComponent } from './status-message-dialog/status-message-dialog.component';
import { PullprintingService } from '../../modules/shared/services/pullprinting.service';

export enum STATUS_REGISTRATION_CODE {
  OK = 'Linking successful',
  EXPIRED_CODE = 'Code has expired',
  EXISTING_CODE = 'Token already exists',
  INVALID_CODE = 'Invalid Code',
  UNKNOWN = 'Linking failed for unknown reason'
}

@Component({
  selector: 'ezd-pullprinting',
  templateUrl: './pullprinting.component.html',
  styleUrls: ['./pullprinting.component.scss']
})
export class PullprintingComponent implements OnInit {
  @ViewChild("regCode1") input_regCode1: ElementRef;
  @ViewChild("regCode2") input_regCode2: ElementRef;
  registrationCode:string
  registCode1:string
  registCode2:string
  codePlaceHolder="XXXX"

  constructor(
    private dialog: MatDialog,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private pullPrintingService: PullprintingService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params["registration_code"]) {
        if(params["registration_code"].length != 8) {
          this.openStatusMessageDialog(STATUS_REGISTRATION_CODE.INVALID_CODE)
        } else {
          this.registrationCode = params["registration_code"]
          this.registCode1 = this.registrationCode.substring(0,4)
          this.registCode2 = this.registrationCode.substring(4)
        }
      }
    })
  }

  sendRegistrationCode() {
    //get api response
    //status: ok/error/unknown
    if(!this.registrationCode) {
      this.registrationCode = this.registCode1 + this.registCode2
    }
    this.pullPrintingService.registerPullPrintingCode(this.registrationCode).subscribe(
      {
        next: () => {
          this.openStatusMessageDialog(STATUS_REGISTRATION_CODE.OK)
        }, error: error => {
          if (error.status == 404) {
            this.openStatusMessageDialog(STATUS_REGISTRATION_CODE.INVALID_CODE)
          } else if (error.status == 410) {
            this.openStatusMessageDialog(STATUS_REGISTRATION_CODE.EXPIRED_CODE)
          } else if (error.status == 409) {
            this.openStatusMessageDialog(STATUS_REGISTRATION_CODE.EXISTING_CODE)
          } else {
            this.openStatusMessageDialog(STATUS_REGISTRATION_CODE.UNKNOWN)
          }
        }
      }
    )
  }

  onRegistCodeInput(event) {
    if(this.input_regCode1.nativeElement.value.length == 4) this.input_regCode2.nativeElement.focus()
    if (event.code === 'Backspace' && this.input_regCode2.nativeElement.value.length == 0) this.input_regCode1.nativeElement.focus()
  }

  openStatusMessageDialog(status:STATUS_REGISTRATION_CODE) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.width = '600px';
    dialogConfig.panelClass = 'ezp-dialog';
    dialogConfig.data = status;

    const dialogRef = this.dialog.open(StatusMessageDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(
      () => {
        this.router.navigate(['/']);
      }
    );
  }

}
