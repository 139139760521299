<div class="search-driver-manufacturer arrangeDiv" *ngIf="showManufactures">
  <h2 mat-dialog-title id="select-driver-title" class="mb-0">
    {{
      'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.PRINTERS_DRIVERS_MANUFACTURERS.TITLE'
        | translate
    }}
  </h2>
  <h3 class="printerName">{{selectedPrinters[0].name}}</h3>

  <mat-form-field
    appearance="fill"
    class="ezp-form-field ezp-form-field--full ezp-form-field--densed mb-0"
    id="search-driver-manufacturer"
  >
    <mat-icon matPrefix class="efa-icon efa-icon--me">search</mat-icon>
    <input
      #searchFieldManufacturer
      matInput
      type="text"
      [placeholder]="
        'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.PRINTERS_DRIVERS_MANUFACTURERS.PLACEHOLDER'
          | translate
      "
      (input)="onSearchChangeManufacturer($event.target.value)"
    />
  </mat-form-field>

  <mat-nav-list
    class="efa-list customBorder"
    id="manufacturers-nav"
    *ngIf="manufacturersFiltered.length"
  >
    <a
      *ngFor="let manufacturer of manufacturersFiltered"
      mat-list-item
      class="efa-list__item efa-list__item--dense"
      (click)="goToDrivers(manufacturer)"
    >
      <span mat-line>{{ manufacturer }}</span>
      <mat-icon class="efa-icon efa-icon--me">chevron_right</mat-icon>
      <mat-divider></mat-divider>
    </a>

    <a
      mat-list-item
      id="request-driver"
      class="efa-list__item efa-list__item--primary"
      (click)="requestDriver()"
      *ngIf="!isLoading"
    >
      <span mat-line id="printer-request-newdriver-text">
        {{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.PRINTERS_DRIVERS_MANUFACTURERS.REQUEST_NEW_DRIVERS'
            | translate
        }}
      </span>
      <mat-icon class="efa-icon efa-icon--me">mail_outline</mat-icon>
      <mat-divider></mat-divider>
    </a>
  </mat-nav-list>

  <div *ngIf="isLoading">
    <ezd-loading-icon [isLoading]="isLoading"></ezd-loading-icon>
  </div>

  <div class="mat-dialog-actions mt-0">
    <button mat-button color="primary" class="ezp-button" (click)="goBack()">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>

<!-- DRIVERS-->
<div class="search-driver-model arrangeDiv" *ngIf="showDrivers">
  <h2 mat-dialog-title id="select-driver-title" class="mb-0">
    {{
      'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.PRINTERS_DRIVERS_MODELS.TITLE' | translate
    }}
  </h2>
  <h3 class="printerName">{{selectedPrinters[0].name}}</h3>
  <mat-form-field
    appearance="fill"
    class="ezp-form-field ezp-form-field--full ezp-form-field--densed mb-0"
    id="search-driver-models"
  >
    <mat-icon matPrefix class="efa-icon efa-icon--me">search</mat-icon>
    <input
      #searchFieldModel
      matInput
      type="text"
      [placeholder]="
        'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.PRINTERS_DRIVERS_MODELS.PLACEHOLDER'
          | translate
      "
      (input)="onSearchChangeDriver($event.target.value)"
    />
  </mat-form-field>

  <ezd-loading-icon [isLoading]="isLoading"></ezd-loading-icon>

  <div *ngIf="filteredDrivers.length === 0 && !isLoading" class="customHeight">
    <div class="noModels">
      <ezd-table-placeholder
        [columns]="['', '', '']"
        [rows]="6"
        [description]="
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.PRINTERS_DRIVERS_MODELS_NOT_AVAILABLE.DESCRIPTION'
            | translate
        "
      ></ezd-table-placeholder>
    </div>
  </div>

  <div *ngIf="filteredDrivers.length > 0" class="customHeight">
    <mat-radio-group
      class="efa-radio__group efa-radio__group--block"
      *ngIf="!isLoading"
      aria-label="Select printer driver"
      [(ngModel)]="selectedPrinter"
    >
      <div class="borders">
       
        <mat-radio-button
          color="primary"
          class="efa-radio__button customRadioButton"
          *ngFor="let driver of filteredDrivers"
          [value]="driver"
          (change)="modelChanged($event)"
        >
          {{ driver.name }}
        </mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
  <div class="mat-dialog-actions mt-0-custom">
    <div class="space-between">
      <div class="left">
        <button mat-button color="primary" class="ezp-button" (click)="goToManufacturer()">
          <mat-icon>arrow_back</mat-icon>
          {{
            'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.PRINTERS_DRIVERS_MANUFACTURERS.MANUFACTURER'
              | translate
          }}
        </button>
      </div>
      <div class="right">
        <button mat-button color="primary" class="ezp-button" (click)="goBack()">
          {{ 'CANCEL' | translate }}
        </button>
        <button
          mat-button
          color="primary"
          class="ezp-button"
          (click)="selectDriver($event)"
          [disabled]="!canSelectDriver"
        >
          {{ 'SAVE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
