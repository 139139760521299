import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Subscription } from 'rxjs'
import { UsersgroupsGroupsService } from '../../../../modules/shared/services/usersgroups-groups.service'

@Component({
  selector: 'ezd-import-azure-ad-group-dialog',
  templateUrl: './import-azure-ad-group-dialog.component.html',
  styleUrls: ['./import-azure-ad-group-dialog.component.scss'],
})
export class ImportAzureAdGroupDialogComponent implements OnInit {
  @ViewChild('searchImportGroups') search: ElementRef;
  filteredAzureAdGroups: Array<any> = []
  getGroupsSubscription: Subscription
  selectedGroups: any
  isGroupSelected: boolean
  isLoading: boolean
  azureAdGroups: any
  isSync: boolean
  canAddGroups: boolean

  constructor(
    public dialogRef: MatDialogRef<ImportAzureAdGroupDialogComponent>,
    private usergroupGroupService: UsersgroupsGroupsService,
    private groupsService: UsersgroupsGroupsService
  ) {}

  ngOnInit(): void {
    this.isLoading = true
    this.isSync = false
    this.getGroupsSubscription = this.usergroupGroupService.getAdconServiceGroups().subscribe(result => {
      if(result.isLoading) return;
      this.isLoading = false
      this.filteredAzureAdGroups = result.groups
      this.azureAdGroups = result.groups
      if(this.search) {
        this.search.nativeElement.focus();
        }
    })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.getGroupsSubscription.unsubscribe()
  }

  close() {
    this.dialogRef.close()
  }
  
  selectGroups(feature, groups) {
    this.selectedGroups = groups.map(g => g.value);
    
    if(this.selectedGroups.length > 0) {
      this.canAddGroups = true;
    } else {
      this.canAddGroups = false;
    }
    this.isGroupSelected = this.selectedGroups.length > 0;
  }

  addGroups() {
    this.isSync = true
    this.usergroupGroupService.addPrintersServiceGroups(this.selectedGroups)
      .subscribe(result => {
        if (result.isLoading === false) {
          this.isSync = false
          this.groupsService.applyFilters(true)
          this.dialogRef.close()
        } else {
          this.isSync = true;
        }
    });
  }

  searchText($event) {
    this.filteredAzureAdGroups = this.azureAdGroups.filter(m => m.displayName.toLowerCase().includes($event.target.value.toLowerCase()));
  }
}
