import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintersComponent } from './printers.component';
import { NetworkprintersComponent } from './networkprinters/networkprinters.component';
import { NetworkPrintersPrintersFiltersComponent } from './networkprinters/filters/filters.component';
import { NetworkPrintersPrintersPropertiesComponent } from './networkprinters/properties/properties.component'
import { NetworkPrintersSettingsComponent } from './settings/settings.component'
import { MaterialModule } from '../../modules/material/material.module'
import { CoreModule } from '../../modules/core/core.module'
import { SharedModule } from '../../modules/shared/shared.module'
import { NetworkPrintersPrintersService } from '../../modules/shared/services/network-printers-printers.service'
import { NetworkPrintersPrintersFiltersService } from '../../modules/shared/services/network-printers-printers-filters.service'
import { NetworkPrintersPrintersSearchComponent } from './networkprinters/search/search.component'
import { AddGroupsComponent } from './networkprinters/add-groups/add-groups.component'
import { SearchDriversManufacturersComponent } from './networkprinters/printer-editor-details/printer-editor-drivers/manufacturers/search-drivers-manufacturers/search-drivers-manufacturers.component';
import { SearchDriversModelsComponent } from './networkprinters/printer-editor-details/printer-editor-drivers/models/search-drivers-models/search-drivers-models.component';
import { NetworkPrintersModelsComponent } from './networkprinters/printer-editor-details/printer-editor-drivers/models/networkprinters-models.component';
import { RequesterApiModule } from '../../modules/shared/services/requester-api.module';
import { LocalPrinterSupportService } from '../../modules/shared/services/local-printer-support.service';
import { ClaimHubDialogComponent } from '../connectors/claim-hub-dialog/claim-hub-dialog.component';
import { NewpasswordDialogComponent } from '../connectors/properties/newpassword-dialog/newpassword-dialog.component';
import { PrinterQrcodeDialogComponent } from './networkprinters/properties/printer-qrcode-dialog/printer-qrcode-dialog.component';
import { PrinterQrcodePdfComponent } from './networkprinters/properties/printer-qrcode-pdf/printer-qrcode-pdf.component';
import { PrinterQrcodeImageComponent } from './networkprinters/properties/printer-qrcode-image/printer-qrcode-image.component';
import { AssignmentsEditorDialogComponent } from './networkprinters/assignments-editor-dialog/assignments-editor-dialog.component';
import { AssignmentsEditorUsersComponent } from './networkprinters/assignments-editor-users/assignments-editor-users.component';
import { AssignmentsEditorGroupsComponent } from './networkprinters/assignments-editor-groups/assignments-editor-groups.component';
import { MyPrintersComponent } from './myprinters/myprinters.component';
import { WelcomeDialogComponent } from './myprinters/welcome-dialog/welcome-dialog.component';
import { QuickstartMyprintersDialogComponent } from './myprinters/quickstart-myprinters-dialog/quickstart-myprinters-dialog.component';
import { PrinterProfilesComponent } from './printer-profiles/printer-profiles.component';
import { SearchProfilesComponent } from './printer-profiles/search-profiles/search-profiles.component';
import { RemoveProfileDialogComponent } from './printer-profiles/remove-profile-dialog/remove-profile-dialog.component';
import { ProfileDetailsDialogComponent } from './printer-profiles/profile-details-dialog/profile-details-dialog.component';
import { PrinterEditorDialogComponent } from './networkprinters/printer-editor-dialog/printer-editor-dialog.component';
import { PrinterEditorDetailsComponent } from './networkprinters/printer-editor-details/printer-editor-details.component';
import { PrinterEditorAssignmentsComponent } from './networkprinters/printer-editor-assignments/printer-editor-assignments.component';
import { PrinterEditorCapabilitiesComponent } from './networkprinters/printer-editor-capabilities/printer-editor-capabilities.component';
import { PrinterEditorPullprintingComponent } from './networkprinters/printer-editor-pullprinting/printer-editor-pullprinting.component';
import { PrinterEditorDriversComponent } from './networkprinters/printer-editor-details/printer-editor-drivers/printer-editor-drivers.component';
import { NetworkPrintersManufacturersComponent } from './networkprinters/printer-editor-details/printer-editor-drivers/manufacturers/networkprinters-manufacturers.component';
import { CanActivatePrinterTabGuard } from './guards/can-activate-printer-tab.guard'
import { CanDeactivateGuard } from './guards/can-deactivate-printer.guard'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    PrintersComponent,
    NetworkprintersComponent,
    NetworkPrintersSettingsComponent,
    NetworkPrintersPrintersFiltersComponent,
    NetworkPrintersPrintersPropertiesComponent,
    NetworkPrintersPrintersSearchComponent,
    AddGroupsComponent,
    SearchDriversManufacturersComponent,
    SearchDriversModelsComponent,
    NetworkPrintersModelsComponent,
    NetworkPrintersManufacturersComponent,
    ClaimHubDialogComponent,
    NewpasswordDialogComponent,
    PrinterQrcodeDialogComponent,
    PrinterQrcodePdfComponent,
    PrinterQrcodeImageComponent,
    AssignmentsEditorDialogComponent,
    AssignmentsEditorUsersComponent,
    AssignmentsEditorGroupsComponent,
    MyPrintersComponent,
    WelcomeDialogComponent,
    QuickstartMyprintersDialogComponent,
    PrinterProfilesComponent,
    SearchProfilesComponent,
    RemoveProfileDialogComponent,
    ProfileDetailsDialogComponent,
    PrinterEditorDialogComponent,
    PrinterEditorDetailsComponent,
    PrinterEditorAssignmentsComponent,
    PrinterEditorCapabilitiesComponent,
    PrinterEditorPullprintingComponent,
    PrinterEditorDriversComponent,
    NetworkPrintersManufacturersComponent,
  ],
  providers: [
    NetworkPrintersPrintersService,
    NetworkPrintersPrintersFiltersService,
    LocalPrinterSupportService,
    CanActivatePrinterTabGuard,
    CanDeactivateGuard,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  imports: [
    MaterialModule,
    CoreModule,
    SharedModule,
    RequesterApiModule,
    MatProgressSpinnerModule,
    CommonModule,
  ],
})
export class PrintersModule {}
