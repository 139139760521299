import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable()
export class EzpDocumentsService {

  private firebaseLoginFunction$ = new Subject<void>();
  private printDocumentFunction$ = new BehaviorSubject<any>({});

  firebaseLogin$ = this.firebaseLoginFunction$.asObservable();
  printDocument$ = this.printDocumentFunction$.asObservable();

  firebaseLogin() {
    this.firebaseLoginFunction$.next();
  }

  printDocument(document) {
    this.printDocumentFunction$.next(document);
  }
}
