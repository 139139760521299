<h2 mat-dialog-title>{{ 'PRINTERS.MY_PRINTERS.QUICKSTART_MYPRINTERS_DIALOG.HEADER' | translate }}</h2>
<div mat-dialog-content>
  <p>{{ 'PRINTERS.MY_PRINTERS.QUICKSTART_MYPRINTERS_DIALOG.BODY' | translate }}
</p>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" (click)="close()" color="primary" class="ezp-button">
    {{ 'PRINTERS.MY_PRINTERS.QUICKSTART_MYPRINTERS_DIALOG.ACTION_CLOSE' | translate }}
  </button>
  <button mat-button (click)="routeToQuickstart()" color="primary" class="ezp-button">
    {{ 'PRINTERS.MY_PRINTERS.QUICKSTART_MYPRINTERS_DIALOG.ACTION_CONTINUE' | translate }}
  </button>
</div>
