import { map } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { AuthenticationService } from '../services/authentication.service'
import { EZEEP_AZURE_API_URL_LOGIN } from '../../../app.config'

@Injectable()
export class AuthenticationEzeepAdminGuard {
  constructor(private authenticationService: AuthenticationService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.authenticationService.isEzeepUser().pipe(
      map((response) => {
        if (response) {
          return true
        } else {
          window.location.href = EZEEP_AZURE_API_URL_LOGIN + state.url
          return false
        }
      })
    )
  }
}
