import {
  Component,
  ViewChild,
  TemplateRef,
  Input,
  OnInit,
  EventEmitter,
  Output,
} from '@angular/core'
import { CapabilitiesService } from '../../../../modules/shared/services/capabilities.service'
import { PrinterEditorDialogComponent } from '../printer-editor-dialog/printer-editor-dialog.component'
import {
  PrinterEditorCapabilities,
  PrinterEditorCapabilitiesApiService,
} from '../../../../modules/shared/services/printer-editor-capabilities-api.service'
import { ApiResponse } from '../../../../modules/shared/services/requester-api.service'
import { FormBuilder, FormGroup, FormArray } from '@angular/forms'
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs'
import { StorageUtils } from '../../../../modules/storage/utils/storage.utils'
import { DIALOG_RESPONSE } from '../../../../modules/shared/classes/dialog-result'

@Component({
  selector: 'ezd-printer-editor-capabilities',
  templateUrl: './printer-editor-capabilities.component.html',
  styleUrls: ['./printer-editor-capabilities.component.scss'],
})
export class PrinterEditorCapabilitiesComponent implements OnInit {
  @ViewChild(PrinterEditorDialogComponent) printerEditorDialog: TemplateRef<any>

  @Output() capabilitiesChanged = new EventEmitter<any>()
  @Output() hasErrors = new EventEmitter<boolean>()
  @Output() duplicateNameDetected = new EventEmitter<boolean>()
  @Output() capabilityChanges = new EventEmitter<boolean>()
  @Output() hasEmptyName = new EventEmitter<boolean>()

  @Input() selectedPrinters: any[] = []

  capabilities: PrinterEditorCapabilities = new PrinterEditorCapabilities()
  selectedPaperSize: string[] = []
  selectedTray: any
  printOption = 'both-sides'
  printerId: string
  capabilitiesForm: FormGroup
  _dataLoaded = false
  propertiesChanged = new Subject<any>()
  hasUnsavedChanges = false
  storedFormData: any
  colorSupported: boolean
  duplexSupported: boolean
  traysAvailable: any
  changedCapabilities: Partial<PrinterEditorCapabilities> = {}
  defaultCapabilities: any
  loadedCapabilities: Partial<PrinterEditorCapabilities> = {}
  editable: boolean
  initialCapabilities: Partial<PrinterEditorCapabilities> = {}
  private originalTrays: any[] = []

  constructor(
    private capabilitiesService: CapabilitiesService,
    private printerEditorCapabilitiesApiService: PrinterEditorCapabilitiesApiService,
    private fb: FormBuilder
  ) {
    this.initForm()
  }

  ngOnInit(): void {
    // this.loadCapabilitiesFromLocalStorage()
    this.loadCapabilities()
    this.subscribeToFormChanges()
  }

  initForm(): void {
    this.capabilitiesForm = this.fb.group({
      color_supported: [false],
      duplex_supported: [false],
      trays: this.fb.array([]),
    })
    this.subscribeToFormChanges() // Subscribe to form changes after initializing the form
  }

  get dataLoaded(): boolean {
    return this._dataLoaded
  }

  updateCapabilities(data: any) {
    this.capabilities = data.capabilities || data
    this.defaultCapabilities = data.defaultCapabilities || this.defaultCapabilities

    this.colorSupported = this.defaultCapabilities?.color_supported !== false
    this.duplexSupported = this.defaultCapabilities?.duplex_supported !== false
    this.traysAvailable =
      this.defaultCapabilities?.trays && this.defaultCapabilities.trays.length > 0
    this.updateFormWithStoredData(this.capabilities)
  }

  updateFormWithStoredData(storedData: any): void {
    if (!storedData) return

    this.capabilitiesForm.patchValue(
      {
        color_supported: storedData.color_supported,
        duplex_supported: storedData.duplex_supported,
      },
      { emitEvent: false }
    )
    // Update the disabled state of the form controls
    this.capabilitiesForm.get('color_supported').enable({ emitEvent: false })
    this.capabilitiesForm.get('duplex_supported').enable({ emitEvent: false })

    if (!this.colorSupported || !this.traysAvailable) {
      this.capabilitiesForm.get('color_supported').disable({ emitEvent: false })
    }

    if (!this.duplexSupported || !this.traysAvailable) {
      this.capabilitiesForm.get('duplex_supported').disable({ emitEvent: false })
    }

    this.changedCapabilities = { ...storedData }
    this.hasUnsavedChanges = true
    this.capabilityChanges.emit(true)
  }

  loadCapabilities(): void {
    if (!this._dataLoaded) {
      if (this.selectedPrinters.length > 0) {
        const printerId = this.selectedPrinters[0].id
        this.capabilitiesService.getPrinterDefaultCapabilities(printerId).subscribe(
          (data: PrinterEditorCapabilities) => {
            this.colorSupported = data.color_supported
            this.duplexSupported = data.duplex_supported
            this.traysAvailable = data.trays.length > 0
          },
          (error) => {
            console.error('Error retrieving printer capabilities:', error)
          }
        )
        this.capabilitiesService.getPrinterCapabilities(printerId).subscribe(
          (data: PrinterEditorCapabilities) => {
            this.loadedCapabilities = JSON.parse(JSON.stringify(data))
            this.updateFormWithResetData(data)
            // Clear any existing data in localStorage
            StorageUtils.set(localStorage, 'printerCapabilities', null)
            this.changedCapabilities = {}
            this.hasUnsavedChanges = false
            this.capabilityChanges.emit(false)
          },
          (error) => {
            console.error('Error retrieving printer capabilities:', error)
          }
        )
      }
      this._dataLoaded = true
    }
  }

  getCapabilities(printerId: string): void {
    this.printerEditorCapabilitiesApiService.listCapabilities(printerId).subscribe(
      (response: ApiResponse<PrinterEditorCapabilities>) => {
        if (response && response.result) {
          this.updateFormWithResetData(response.result)
        }
      },
      (error) => {
        console.error('Error retrieving printer capabilities: ', error)
      }
    )
  }

  resetCapabilities(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.selectedPrinters && this.selectedPrinters.length > 0) {
        const printerId = this.selectedPrinters[0].id
        this.capabilitiesService.getPrinterDefaultCapabilities(printerId).subscribe(
          (resetData) => {
            this.updateFormWithResetData(resetData)
            this.saveCapabilities()
            StorageUtils.set(localStorage, 'printerCapabilities', null)
          },
          (error) => {
            console.error('Error resetting capabilities:', error)
            reject(error)
          }
        )
      } else {
        reject('No printer selected')
      }
    })
  }

  updateFormWithResetData(resetData: PrinterEditorCapabilities): void {
    if (!resetData) return

    this.capabilitiesForm.patchValue({
      color_supported: resetData.color_supported ?? false,
      duplex_supported: resetData.duplex_supported ?? false,
    })

    const traysFormArray = this.capabilitiesForm.get('trays') as FormArray
    traysFormArray.clear()
    if (resetData.trays && resetData.trays.length > 0) {
      resetData.trays.forEach((tray) => {
        traysFormArray.push(this.createTrayFormGroup(tray))
      })
      this.selectedPaperSize = resetData.trays
        .filter((tray) => tray.enabled)
        .map((tray) => tray.name)
    }

    this.capabilities = PrinterEditorCapabilities.fromJS(resetData)

    this.subscribeToFormChanges()

    // Don't save to localStorage here, as it might overwrite user changes
    // Instead, consider saving only when the user explicitly saves changes
  }

  createTrayFormGroup(tray: any): FormGroup {
    return this.fb.group({
      index: [tray.index],
      name: [tray.name],
      original_name: [tray.original_name],
      enabled: [tray.enabled],
    })
  }

  renameTray(dialogRef: any): void {
    const updatedTray = {
      index: this.selectedTray.index,
      name: this.selectedTray.name,
      original_name: this.selectedTray.original_name,
      enabled: this.selectedTray.enabled,
    }

    const payload: Partial<PrinterEditorCapabilities> = {
      trays: [updatedTray],
    }

    this.capabilitiesService.updatePrinterCapabilities(this.printerId, payload).subscribe(
      (response) => {
        dialogRef.close()
        this.getCapabilities(this.printerId)
      },
      (error) => {
        console.error('Error updating tray: ', error)
      }
    )
  }

  onToggleChange(trayControl: FormGroup, event: any): void {
    trayControl.patchValue({ enabled: event.checked })
  }

  onTrayNameChange(trayControl: FormGroup, newName: string): void {
    if (!this.isDuplicateName(newName, trayControl)) {
      trayControl.patchValue({ name: newName })
    }
  }

  clearInput(trayControl: FormGroup): void {
    trayControl.patchValue({ name: '' })
  }

  isDuplicateName(name: string, currentTrayControl: FormGroup): boolean {
    const trimmedName = name.trim()
    return (this.capabilitiesForm.get('trays') as FormArray).controls.some(
      (trayControl) =>
        trayControl.get('name').value.trim() === trimmedName && trayControl !== currentTrayControl
    )
  }

  hasDuplicateName(name: string): boolean {
    const trimmedName = name.trim()
    return (
      (this.capabilitiesForm.get('trays') as FormArray).controls.filter(
        (trayControl) => trayControl.get('name').value.trim() === trimmedName
      ).length > 1
    )
  }

  isEmptyName(name: string): boolean {
    const isEmpty = !name || name.trim().length === 0
    this.hasEmptyName.emit(isEmpty)
    return isEmpty
  }

  storeFormData() {
    this.storedFormData = this.capabilitiesForm.value
  }

  restoreFormData() {
    if (this.storedFormData) {
      this.capabilitiesForm.patchValue(this.storedFormData)
    }
  }

  saveCapabilities(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.logCurrentState()

      if (this.selectedPrinters.length === 0) {
        console.error('No printer selected')
        this.hasErrors.emit(true)
        reject('No printer selected')
        return
      }

      const printerId = this.selectedPrinters[0].id
      const formValue = this.capabilitiesForm.value

      const capabilitiesToSave = PrinterEditorCapabilities.fromJS({
        color_supported: formValue.color_supported,
        duplex_supported: formValue.duplex_supported,
        trays: formValue.trays,
      })

      console.log('Saving capabilities:', JSON.stringify(capabilitiesToSave))

      this.capabilitiesService.updatePrinterCapabilities(printerId, capabilitiesToSave).subscribe(
        (response) => {
          console.log('Server response:', JSON.stringify(response))
          this.handleSaveSuccess(response)
          resolve(response)
        },
        (error) => {
          console.error('Error saving capabilities:', error)
          this.hasErrors.emit(true)
          reject(error)
        }
      )
    })
  }

  subscribeToFormChanges() {
    if (this.capabilitiesForm) {
      this.capabilitiesForm.get('color_supported').valueChanges.subscribe((value) => {
        this.trackChanges('color_supported', value)
      })

      this.capabilitiesForm.get('duplex_supported').valueChanges.subscribe((value) => {
        this.trackChanges('duplex_supported', value)
      })

      const traysFormArray = this.capabilitiesForm.get('trays') as FormArray
      traysFormArray.controls.forEach((trayGroup: FormGroup, index: number) => {
        ;['name', 'original_name', 'enabled'].forEach((field) => {
          trayGroup.get(field).valueChanges.subscribe((value) => {
            this.trackTrayChanges(index, field, value)
            if (field === 'name') {
              this.checkForDuplicateNames()
            }
          })
        })
      })
    }
  }

  checkForDuplicateNames() {
    const traysFormArray = this.capabilitiesForm.get('trays') as FormArray
    const names = traysFormArray.controls.map((trayGroup) => trayGroup.get('name').value.trim())
    const hasDuplicateName = names.some((name, index) => names.indexOf(name) !== index)
    this.duplicateNameDetected.emit(hasDuplicateName)
  }

  handleCapabilitiesChanges(result: any) {
    let hasChanges = false

    if (
      result.color_supported !== undefined &&
      result.color_supported !== this.capabilities.color_supported
    ) {
      hasChanges = true
      this.capabilities.color_supported = result.color_supported
    }
    if (
      result.duplex_supported !== undefined &&
      result.duplex_supported !== this.capabilities.duplex_supported
    ) {
      hasChanges = true
      this.capabilities.duplex_supported = result.duplex_supported
    }
    if (result.tray_name !== undefined) {
      const traysFormArray = this.capabilitiesForm.get('trays') as FormArray
      traysFormArray.controls.forEach((trayGroup: FormGroup) => {
        if (trayGroup.get('name').value === result.tray_name) {
          hasChanges = true
          trayGroup.get('name').setValue(result.tray_name, { emitEvent: false })
        }
      })
    }
    if (result.tray_enabled !== undefined) {
      const traysFormArray = this.capabilitiesForm.get('trays') as FormArray
      traysFormArray.controls.forEach((trayGroup: FormGroup) => {
        if (trayGroup.get('enabled').value === result.tray_enabled) {
          hasChanges = true
          trayGroup.get('enabled').setValue(result.tray_enabled, { emitEvent: false })
        }
      })
    }

    if (hasChanges) {
      this.hasUnsavedChanges = false
      this.saveFormToLocalStorage()
      this.capabilityChanges.emit(true)
    }
  }

  // saveFormToLocalStorage() {
  //   StorageUtils.set(localStorage, 'printerCapabilities', this.capabilitiesForm.value)
  //   this.capabilitiesService.changePrinterPropertiesWatcherStatus(true, DIALOG_RESPONSE.NONE)
  // }

  saveFormToLocalStorage() {
    if (Object.keys(this.changedCapabilities).length > 0) {
      const currentData = StorageUtils.get(localStorage, 'printerCapabilities') || {}
      const updatedData = { ...currentData, ...this.changedCapabilities }
      StorageUtils.set(localStorage, 'printerCapabilities', updatedData)
      this.capabilitiesService.changePrinterPropertiesWatcherStatus(true, DIALOG_RESPONSE.NONE)
    }
  }

  trackChanges(key: string, value: any) {
    if (this.loadedCapabilities[key] !== value) {
      this.changedCapabilities[key] = value
    } else {
      delete this.changedCapabilities[key]
    }
    this.compareAndUpdateLocalStorage()
  }

  trackTrayChanges(index: number, key: string, value: any) {
    if (!this.changedCapabilities.trays) {
      this.changedCapabilities.trays = []
    }

    const loadedTray = this.loadedCapabilities.trays[index]
    if (loadedTray[key] !== value) {
      if (!this.changedCapabilities.trays[index]) {
        // Initialize with all properties, but only set the changed one
        this.changedCapabilities.trays[index] = {
          index,
          name: loadedTray.name,
          original_name: loadedTray.original_name,
          enabled: loadedTray.enabled,
          [key]: value,
        }
      } else {
        this.changedCapabilities.trays[index][key] = value
      }
    } else {
      if (this.changedCapabilities.trays[index]) {
        // If reverting to original value, set it back to the loaded value
        this.changedCapabilities.trays[index][key] = loadedTray[key]

        // Check if all values are now equal to the loaded values
        const isUnchanged = ['name', 'original_name', 'enabled'].every(
          (k) => this.changedCapabilities.trays[index][k] === loadedTray[k]
        )

        if (isUnchanged) {
          this.changedCapabilities.trays[index] = undefined
        }
      }
    }

    // Remove undefined entries and clean up if empty
    this.changedCapabilities.trays = this.changedCapabilities.trays.filter(
      (tray) => tray !== undefined
    )
    if (this.changedCapabilities.trays.length === 0) {
      delete this.changedCapabilities.trays
    }

    this.compareAndUpdateLocalStorage()
  }

  private compareAndUpdateLocalStorage(): void {
    const isEqual = this.isEqualToLoadedCapabilities(this.changedCapabilities)

    if (isEqual) {
      StorageUtils.set(localStorage, 'printerCapabilities', null)
      this.capabilitiesService.changePrinterPropertiesWatcherStatus(false, DIALOG_RESPONSE.NONE)
      this.hasUnsavedChanges = false
    } else {
      StorageUtils.set(localStorage, 'printerCapabilities', this.changedCapabilities)
      this.capabilitiesService.changePrinterPropertiesWatcherStatus(true, DIALOG_RESPONSE.NONE)
      this.hasUnsavedChanges = true
    }

    this.capabilityChanges.emit(this.hasUnsavedChanges)
  }

  private isEqualToLoadedCapabilities(
    changedCapabilities: Partial<PrinterEditorCapabilities>
  ): boolean {
    if (
      changedCapabilities.color_supported !== undefined &&
      changedCapabilities.color_supported !== this.loadedCapabilities.color_supported
    ) {
      return false
    }
    if (
      changedCapabilities.duplex_supported !== undefined &&
      changedCapabilities.duplex_supported !== this.loadedCapabilities.duplex_supported
    ) {
      return false
    }
    if (changedCapabilities.trays) {
      for (let i = 0; i < changedCapabilities.trays.length; i++) {
        if (
          changedCapabilities.trays[i] &&
          (changedCapabilities.trays[i].name !== this.loadedCapabilities.trays[i].name ||
            changedCapabilities.trays[i].enabled !== this.loadedCapabilities.trays[i].enabled)
        ) {
          return false
        }
      }
    }
    return true
  }

  private getChangedCapabilities(): Partial<PrinterEditorCapabilities> {
    const currentFormValue = this.capabilitiesForm.value
    const changedCapabilities: Partial<PrinterEditorCapabilities> = {}

    if (currentFormValue.color_supported !== this.loadedCapabilities.color_supported) {
      changedCapabilities.color_supported = currentFormValue.color_supported
    }

    if (currentFormValue.duplex_supported !== this.loadedCapabilities.duplex_supported) {
      changedCapabilities.duplex_supported = currentFormValue.duplex_supported
    }

    if (currentFormValue.trays) {
      const changedTrays = currentFormValue.trays.filter((tray: any, index: number) => {
        const loadedTray = this.loadedCapabilities.trays.find((t) => t.index === tray.index)
        return loadedTray && (tray.name !== loadedTray.name || tray.enabled !== loadedTray.enabled)
      })

      if (changedTrays.length > 0) {
        changedCapabilities.trays = changedTrays.map((tray) => ({
          index: tray.index,
          name: tray.name,
          original_name: tray.original_name,
          enabled: tray.enabled,
        }))
      }
    }

    return changedCapabilities
  }

  private handleSaveSuccess(response: any) {
    this.capabilitiesChanged.emit(response)
    this.hasErrors.emit(false)
    this.hasUnsavedChanges = false
    this.capabilityChanges.emit(false)

    // Update loadedCapabilities with the server response
    if (response.trays) {
      this.loadedCapabilities.trays = this.loadedCapabilities.trays.map((tray: any) => {
        const updatedTray = response.trays.find((t: any) => t.index === tray.index)
        return updatedTray ? { ...tray, ...updatedTray } : tray
      })
    }
    this.loadedCapabilities = { ...this.loadedCapabilities, ...response }

    // Clear changedCapabilities and localStorage
    this.changedCapabilities = {}
    StorageUtils.set(localStorage, 'printerCapabilities', null)

    // Update the form with the new loadedCapabilities
    this.updateFormWithResetData(this.loadedCapabilities as PrinterEditorCapabilities)
  }

  private onFormValueChanges(): void {
    this.capabilitiesForm.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
      )
      .subscribe((value) => {
        this.updateChangedCapabilities(value)
      })
  }

  private updateChangedCapabilities(formValue: any): void {
    const changes: any = {}

    if (formValue.color_supported !== this.initialCapabilities.color_supported) {
      changes.color_supported = formValue.color_supported
    }
    if (formValue.duplex_supported !== this.initialCapabilities.duplex_supported) {
      changes.duplex_supported = formValue.duplex_supported
    }

    if (formValue.trays) {
      const changedTrays = formValue.trays.filter((tray: any, index: number) => {
        const initialTray = this.initialCapabilities.trays.find((t: any) => t.index === tray.index)
        return (
          !initialTray || tray.name !== initialTray.name || tray.enabled !== initialTray.enabled
        )
      })

      if (changedTrays.length > 0) {
        changes.trays = changedTrays
      }
    }

    this.changedCapabilities = changes
    this.hasUnsavedChanges = Object.keys(changes).length > 0
    this.capabilityChanges.emit(this.hasUnsavedChanges)
  }

  onTrayChange(index: number) {
    const formValue = this.capabilitiesForm.value
    this.changedCapabilities = {
      color_supported: formValue.color_supported,
      duplex_supported: formValue.duplex_supported,
      trays: formValue.trays,
    }

    this.hasUnsavedChanges = true
    this.capabilityChanges.emit(this.hasUnsavedChanges)

    // Store the entire object in localStorage
    StorageUtils.set(localStorage, 'printerCapabilities', this.changedCapabilities)
  }

  undoTrayChanges() {
    const traysFormArray = this.capabilitiesForm.get('trays') as FormArray
    this.originalTrays.forEach((originalTray, index) => {
      const trayControl = traysFormArray.at(index)
      trayControl.patchValue({
        name: originalTray.name,
        enabled: originalTray.enabled,
      })
    })

    delete this.changedCapabilities.trays
    this.hasUnsavedChanges = Object.keys(this.changedCapabilities).length > 0
    this.capabilityChanges.emit(this.hasUnsavedChanges)
  }

  private logCurrentState(): void {
    console.log(
      'Current state:',
      JSON.stringify({
        color_supported: this.capabilities.color_supported,
        duplex_supported: this.capabilities.duplex_supported,
        trays: this.capabilities.trays,
      })
    )
  }
}
