<div class="divHeight">
  <ezd-table-placeholder
    *ngIf="selectedPrinters.length > 1"
    [columns]="['', '', '']"
    [rows]="8"
    [heading]="
      'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PROPERTIES.PROPERTIES_NOT_AVAILABLE.NOT_AVAILABLE'
        | translate
    "
  ></ezd-table-placeholder>

  <form [formGroup]="capabilitiesForm" *ngIf="selectedPrinters.length == 1">
    <mat-card class="card-0">
      <mat-list>
        <mat-list-item>
          <mat-checkbox
            color="primary"
            class="checkbox"
            formControlName="color_supported"
            [disabled]="colorSupported === false || traysAvailable === false"
          >
            <span class="fontSize">{{
              'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PROPERTIES.CAN_PRINT_IN_COLOR' | translate
            }}</span>
          </mat-checkbox>
        </mat-list-item>
        <mat-list-item>
          <mat-checkbox
            color="primary"
            class="checkbox"
            formControlName="duplex_supported"
            [disabled]="duplexSupported === false || traysAvailable === false"
          >
            <span class="fontSize">{{
              'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PROPERTIES.CAN_PRINT_ON_BOTH_SIDES'
                | translate
            }}</span>
          </mat-checkbox>
        </mat-list-item>
      </mat-list>
    </mat-card>

    <mat-card class="card">
      <mat-list>
        <mat-list-item>
          <span class="fontSize fontWeight">{{
            'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PROPERTIES.PAPER_TRAYS' | translate
          }}</span>
        </mat-list-item>
        <div *ngIf="!traysAvailable" class="not-available">
          <p>
            {{
              'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PROPERTIES.PAPER_TRAYS_NOT_AVAILABLE'
                | translate
            }}
          </p>
          <p>
            {{
              'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PROPERTIES.MODEL_SELECTION_TEXT'
                | translate
            }}
          </p>
        </div>
        <div *ngIf="traysAvailable">
          <mat-list-item
            *ngFor="let trayControl of capabilitiesForm.get('trays')['controls']; let i = index"
            [formGroup]="trayControl"
          >
            <mat-slide-toggle
              color="primary"
              formControlName="enabled"
              (change)="onToggleChange(trayControl, $event)"
            ></mat-slide-toggle>
            <div class="tray-info">
              <div class="tray-original-name">
                <span class="original-name">{{ trayControl.get('original_name').value }}</span>
              </div>
              <div class="tray-name">
                <input
                  matInput
                  formControlName="name"
                  (blur)="onTrayNameChange(trayControl, $event.target.value)"
                  (keydown.enter)="onTrayNameChange(trayControl, $event.target.value)"
                  class="editable-input"
                  [class.error]="
                    hasDuplicateName(trayControl.get('name').value) ||
                    isEmptyName(trayControl.get('name').value)
                  "
                  placeholder="{{
                    'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PROPERTIES.TRAY_NAME_PLACEHOLDER'
                      | translate
                  }}"
                  matTooltip="{{
                    'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PROPERTIES.TRAY_NAME_TOOLTIP'
                      | translate
                  }}"
                  matTooltipClass="error-tooltip"
                  matTooltipPosition="right"
                  [matTooltipDisabled]="
                    !hasDuplicateName(trayControl.get('name').value) &&
                    !isEmptyName(trayControl.get('name').value)
                  "
                />
                <div class="clear-icon">
                  <img
                    matPrefix
                    alt="clear-tray-name"
                    class="mat-icon clear-tray-name"
                    src="/assets/images/clear-tray-name.png"
                    (click)="clearInput(trayControl)"
                  />
                </div>
              </div>
            </div>
          </mat-list-item>
        </div>
      </mat-list>
    </mat-card>
  </form>
</div>
