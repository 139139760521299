import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { ConnectorService } from '../../../../modules/shared/services/connector.service';

@Component({
  selector: 'ezd-newpassword-dialog',
  templateUrl: './newpassword-dialog.component.html',
  styleUrls: ['./newpassword-dialog.component.scss']
})
export class NewpasswordDialogComponent implements OnInit {
  password: string;

  constructor(private connectorService: ConnectorService,
    public dialogRef: MatDialogRef<NewpasswordDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private clipboard: Clipboard) {
      this.password = 'Loading...';

    this.connectorService.getPassword(data.id).subscribe(x => {
      this.password = x.password;
    });
  }

  ngOnInit(): void {
  }

  copy() {
    this.clipboard.copy(this.password);
  }

  close() {
    this.dialogRef.close();
  }
}
