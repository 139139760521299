<h2 mat-dialog-title id="remove-domain-text">{{ 'USERSGROUPS.DOMAINS.DOMAIN_INFORMATION.REMOVE_DOMAIN' | translate }} </h2> 
<div mat-dialog-content>
  <p id="domain_name_en" *ngIf="preferredLanguage == 'en'">{{ 'USERSGROUPS.DOMAINS.REMOVE_DOMAIN' | translate }} <strong>{{ domainName }}</strong> ?</p>
  <p id="domain_name_de" *ngIf="preferredLanguage == 'de'" [innerHTML]="'USERSGROUPS.DOMAINS.REMOVE_DOMAIN' | translate: { value: domainName }"></p>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="true" (click)="close()" color="primary" class="ezp-button" id="cancel_remove_domain" [disabled]="isLoading">
    {{ 'CANCEL' | translate }}
  </button>
  <button mat-button (click)="removeDomain()" color="primary" class="ezp-button" id="remove-domain" [disabled]="isLoading">
    <mat-icon [ngClass]="{ 'fa-spin': isLoading }" *ngIf="isLoading">cached</mat-icon>
    {{ 'REMOVE' | translate }}
  </button>
</div>