import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { DefaultGroupAssignmentsService, GroupsService, UserPrintingService } from './printing-api.service';

@Injectable({
  providedIn: 'root'
})
export class UsersgroupsDomainsAssignmentService {
  allGroupsSubject = new BehaviorSubject<any>([]);
  allGroups: any[] = [];
  currentDomainId = undefined;
  PAGE_SIZE = 25;
  PAGE_INDEX = 0;
  draftedAssignedGroups = undefined;
  
  constructor(
    private groupsService: GroupsService,
    private domainPrintService: DefaultGroupAssignmentsService) { }

  public getAllGroups(searchText:string, domainId:string): Observable<any> {
    this.setAllGroups(searchText, domainId, this.PAGE_SIZE, this.PAGE_INDEX).subscribe();
    return this.allGroupsSubject.asObservable();
  }
  

  setAllGroups(searchText: string, domainId: string, page_size: number, offset: number) {
    if (!this.currentDomainId) {
      this.currentDomainId = domainId;
    }
    if (this.currentDomainId != domainId) {
      this.currentDomainId = domainId;
      this.allGroups = [];
    }
    this.allGroupsSubject.next({ isLoading: true, groups: this.allGroups });

    return this.getGroups(searchText, page_size, offset).pipe(map(response => {
      let endOfList = !response.next;
      this.allGroups = response.groups;
      this.allGroupsSubject.next({ isLoading: false, groups: this.allGroups, endOfList: endOfList });
    }))
  }

  getGroups(searchText:string, pageSize: number, pageIndex: number) : Observable<any> {
    return this.groupsService.list(undefined, undefined, undefined, undefined, searchText, "name", pageSize, pageIndex)
    .pipe(map(response => {
      return {groups: response.result.results, next: response.result.next };
    }));
  }
  
  async draftAssignedGroups(currentAssignedGroups: any[], newAssignments: any) {
    if(!this.draftedAssignedGroups) this.draftedAssignedGroups = []
    let removedGroups = currentAssignedGroups.filter(g => newAssignments.filter(n => n.id == g.id).length == 0)
    if (this.currentDomainId && this.currentDomainId !== 'NEWDOMAIN') {
      let currentDomain = await lastValueFrom(this.domainPrintService.read(this.currentDomainId))
      newAssignments = newAssignments.filter(ng => currentDomain.result.groups.filter(cg => cg.id == ng.id).length == 0)

      this.draftedAssignedGroups = currentDomain.result.groups.filter(caag => removedGroups.filter(rg => rg.id == caag.id).length == 0)
    }
    this.draftedAssignedGroups = [...this.draftedAssignedGroups, ...newAssignments.map(g => {return {id: g.id}})]
  }

  async saveDraftedAssignedGroups(domainId:string) {
    if(!this.draftedAssignedGroups) return;

    let currentDomain = (await lastValueFrom(this.domainPrintService.read(domainId))).result // .toPromise()).result
    currentDomain.groups = this.draftedAssignedGroups
    delete currentDomain.enabled
    delete currentDomain.expression
    await lastValueFrom(this.domainPrintService.update(currentDomain, currentDomain.id)) // .toPromise()
  }

  clearDraftedAssignedGroups() {
    this.draftedAssignedGroups = undefined
  }
}
