import { Component, OnInit, Optional, Inject } from '@angular/core'
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { LangaugeService } from '../../../../modules/shared/services/langauge.service'
import { PrinterProfileService } from '../../../../modules/shared/services/printer-profile.service'
import { ErrorService } from '../../../../modules/shared/services/error.service'
import { forkJoin } from 'rxjs'

@Component({
  selector: 'ezd-remove-profile-dialog',
  templateUrl: './remove-profile-dialog.component.html',
  styleUrls: ['./remove-profile-dialog.component.scss'],
})
export class RemoveProfileDialogComponent implements OnInit {
  isLoading = false
  preferredLanguage: any
  profiles: any // Add type declaration
  isMultipeProfilesSelected: boolean = false

  constructor(
    private profilesService: PrinterProfileService,
    public dialogRef: MatDialogRef<RemoveProfileDialogComponent>,
    private languageService: LangaugeService,
    private errorService: ErrorService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isLoading = false
    this.profiles = data || [] // Ensure data is initialized
    if (this.profiles.length > 1) {
      this.isMultipeProfilesSelected = true
    }
  }

  ngOnInit(): void {
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value
    })
  }

  removeProfile() {
    this.isLoading = true

    const removalRequests = this.profiles.map((profile) =>
      this.profilesService.removeProfile(profile.id)
    )

    forkJoin(removalRequests)
      .subscribe(
        async (results) => {
          await this.profilesService.applyFilters(true)
          this.dialogRef.close()
        },
        (error) => {
          // this.errorService.openTranslate(error, 'ERRORS.GENERIC');
        }
      )
      .add(() => {
        this.isLoading = false
      })
  }

  trackByProfileId(index: number, profile: any): any {
    return profile.id
  }

  close() {
    this.dialogRef.close()
  }
}
