import { Component, OnInit, Optional, Inject } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { LangaugeService } from '../../../../modules/shared/services/langauge.service';
import { UsersgroupsUsersService } from '../../../../modules/shared/services/usersgroups-users.service'

@Component({
  selector: 'ezd-remove-user-dialog',
  templateUrl: './remove-user-dialog.component.html',
  styleUrls: ['./remove-user-dialog.component.scss'],
})
export class RemoveUserDialogComponent implements OnInit {
  isLoading = false;
  userId: any;
  userEmail: any;
  preferredLanguage: any;
  displayName: string;

  constructor(
    public dialogRef: MatDialogRef<RemoveUserDialogComponent>,
    //public userDialogRef: MatDialogRef<UserDetailsDialogComponent>,
    private userService: UsersgroupsUsersService,
    private languageService: LangaugeService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.isLoading = false;
      if (this.data && this.data.userDetails) { 
        this.userId = this.data.userDetails.id;
        this.userEmail = this.data.userDetails.email;
        this.displayName = this.data.userDetails.display_name;
      }
    }


  ngOnInit(): void {
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value;
    })
  }

  removeUser() {
    this.isLoading = true;
    this.userService.removeUser(this.userId).subscribe(
      async () => {
        await this.userService.applyFilters(true)
        this.dialogRef.close()
        //this.userDialogRef.close()
      });
  }

  close() {
    this.dialogRef.close();
    //this.userDialogRef.close()
  }

}
