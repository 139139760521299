<div class="ezp-panels__item">
  <div class="ezp-panels__item__actions">
    <span class="ezp-panels__item__title mat-title" [id]="title.replaceAll(' ', '-').toLowerCase() + '-title'">{{ title
      }}</span>
   
      <span *ngIf="type === 'PULLPRINTING' && isPullPrintingPresent == false" (click)="openSubscriptionPage()"
        style="display: flex;">
        <span class="mat-body-2 efa-text efa-text--link efa-text--prm star-icon" style="cursor: pointer;">
          <mat-icon class="icon-star">star</mat-icon>
        </span>
        <span class="mat-body-2 efa-text efa-text--link efa-text--prm active-plan"
          style="cursor: pointer; margin-bottom: 6px;"
          *ngIf="type === 'PULLPRINTING' && isPullPrintingPresent == false">Business</span>
      </span>
      
      <span *ngIf="type === 'PRINTLATER' && isPrintLaterPresent == false" style="display: flex;">
        <span class="mat-body-2 efa-text efa-text--link efa-text--prm star-icon">
          <mat-icon class="icon-star">star</mat-icon>
        </span>
        <span class="mat-body-2 efa-text efa-text--link efa-text--prm active-plan" style="margin-bottom: 6px;"
          *ngIf="type === 'PRINTLATER' && isPrintLaterPresent == false">Pro</span>
      </span>
  </div>
  <div class="ezp-panels__item__description ezp-text ezp-text--medium-emphasis mat-body-1"
    [id]="title.replaceAll(' ', '-').toLowerCase() + '-description'">
    {{ description }}
  </div>
  <div class="ezp-panels__item__actions">
    <mat-slide-toggle color="primary" [id]="title.replaceAll(' ', '-').toLowerCase() + '-toggle'" [checked]="disabled"
      [disabled]="grayOut" (change)="onChangeDisabled($event.checked)">
      <span [id]="title.replaceAll(' ', '-').toLowerCase() + '-toggle-text'">{{ disabledTitle }}</span>
    </mat-slide-toggle>
    <button mat-raised-button [id]="title.replaceAll(' ', '-').toLowerCase() + '-add-group-button'" color="primary"
      class="ezp-button ezp-button--icon ezp-button--icon--leading" [disabled]="!disabled || !groupsEnabled" *ngIf="!disabledGroups"
      (click)="openAddGroupDialog()">
      <mat-icon>add</mat-icon>{{ 'POLICIES.ADD_GROUP' | translate }}
    </button>
    <span class="mat-body-2 efa-text efa-text--link efa-text--prm" (click)="openNetworkPrinters()"
      id="open-network-printers" *ngIf="type === 'PULLPRINTING'">
      <span>{{ 'POLICIES.NETWORK_PRINTERS_ROUTE' | translate }}</span>
    </span>
  </div>
  <div class="ezp-panels__list" *ngIf="!disabledGroups" [ngClass]="!disabled ? 'ezp-panels__list--disabled' : ''">
    <div class="ezp-panels__list__item ezp-panels__list__item--action-leading" *ngFor="let group of groups">
      <button mat-icon-button id="policy-remove-group" class="ezp-panels__list__item__action"
        (click)="removeGroup(group)">
        <mat-icon class="ezp-icon ezp-icon--medium-emphasis">remove_circle</mat-icon>
      </button>
      <div class="ezp-panels__list__item__title ezp-text mat-body-1" [id]="group.name">
        {{ group.name }}
      </div>
    </div>
  </div>
</div>