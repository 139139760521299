import { Component, OnInit, Inject } from '@angular/core'
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog'
import { UsersgroupsGroupsService } from '../../../../modules/shared/services/usersgroups-groups.service'
import { UsersgroupsGroupsUsersService } from '../../../../modules/shared/services/usersgroups-groups-users.service'
import { UsersgroupsGroupsPrintersService } from '../../../../modules/shared/services/usersgroups-groups-printers.service'
import { Subscription } from 'rxjs'
import { RemoveGroupDialogComponent } from '../remove-group-dialog/remove-group-dialog.component'
import { ErrorService } from '../../../../modules/shared/services/error.service'

@Component({
  selector: 'ezd-group-details-dialog',
  templateUrl: './group-details-dialog.component.html',
  styleUrls: ['./group-details-dialog.component.scss'],
})
export class GroupDetailsDialogComponent implements OnInit {
  toEdit: boolean
  groupServiceSubscription: Subscription
  group: any
  isAzureGroup: boolean
  isOfficeRnDGroup: boolean
  isCobotGroup: boolean
  isLoading = false
  canCreate = true

  constructor(
    private groupService: UsersgroupsGroupsService,
    private groupUserService: UsersgroupsGroupsUsersService,
    private groupPrinterService: UsersgroupsGroupsPrintersService,
    public dialogRef: MatDialogRef<GroupDetailsDialogComponent>,
    public dialog: MatDialog,
    private errorService: ErrorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.isLoading = false
    this.toEdit = !this.data
    if (!this.toEdit) this.canCreate = false
  }

  ngOnInit() {
    this.groupServiceSubscription = this.groupService.getFilteredGroups().subscribe(() => {
      this.group = Object.assign({}, this.groupService.getGroupToEdit())
      if (!this.group) return

      this.isAzureGroup = this.group.origin && this.group.origin.provider == 'Azure'
      this.isOfficeRnDGroup = this.group.origin && this.group.origin.provider == 'OfficeRnD'
      this.isCobotGroup = this.group.origin && this.group.origin.provider == 'Cobot'
    })
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.groupServiceSubscription.unsubscribe()
  }

  openRemoveGroupDialog() {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = false
    dialogConfig.width = '320px'
    dialogConfig.panelClass = 'ezp-dialog'
    dialogConfig.data = { groupDetails: this.group }

    this.dialogRef.close()
    this.dialog.open(RemoveGroupDialogComponent, dialogConfig)
  }

  changeCreateButtonStatus(hasName) {
    this.canCreate = hasName
  }

  close() {
    this.isLoading = true
    this.groupService.cancelGroupToEdit()
    this.groupUserService.clearDraftedAssignedGroups()
    this.dialogRef.close()
  }

  done() {
    this.isLoading = true
    this.groupService.saveGroup().subscribe({
      next: async (result) => {
        await this.groupUserService.saveDraftedAssignedUsers(result.id)
        if (this.groupPrinterService.hasDraftedAssignedPrinters) {
          await this.groupPrinterService.saveDraftedAssignedPrinters()
        }
        this.groupService.cancelGroupToEdit()
        this.groupUserService.clearDraftedAssignedGroups()
        this.groupPrinterService.clearDraftedAssignedGroups()
        this.groupService.applyFilters(true)
        this.dialogRef.close()
      },
      error: (error) => {
        const errorMessage = JSON.parse(error.response)
        if (
          error.status == 400 &&
          errorMessage.errors[0] &&
          errorMessage.errors[0].field == 'name'
        ) {
          this.errorService.open(errorMessage.errors[0].message)
          this.isLoading = false
          this.canCreate = true
        } else {
          // this.errorService.openTranslate(error, 'ERRORS.GENERIC')
        }
      },
    })
  }
}
