<div class="ezp-grid ezp-grid--gutter-horizontal ezp-grid--spacing-vertical" data-grid-cols="1">
  <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill" id="group-search-users">
    <mat-label id="group-search-users-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_USERS.SEARCH_USERS' | translate
      }}</mat-label>
    <input matInput id="group-user-name" #search
      [placeholder]="'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_USERS.USER_NAME' | translate"
      (input)="onSearchChange($event.target.value)" />
  </mat-form-field>
</div>
<p *ngIf="!assignedUsers.length && !isLoading" class="ezp-text ezp-text--high-emphasis mat-subheading-2">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_USERS.USER_NOT_FOUND' | translate }}</p>
<ezd-infinity-scroll (scrolled)="onScroll()" style="height: 300px; overflow-y: auto">
  <mat-selection-list #users id="groups-users-list" class="ezp-list ezp-list--filterable"
    (selectionChange)="onSelection($event, users.selectedOptions)">
    <mat-list-option *ngFor="let user of assignedUsers" checkboxPosition="before" color="primary"
      [selected]="user.isSelected"
      [value]="user" 
      [id]="user.name" 
      [disabled]="!isEzeepGroup">
      <div class="ezp-list__item">
        <div>{{ user.display_name ? user.display_name : user.email }}</div>
        <div class="ezp-list__item__right-content" id="groups-users-azureuser-text" *ngIf="user.azure_profile !== null">
          {{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_USERS.AZURE_USER' | translate }}</div>
      </div>
    </mat-list-option>
  </mat-selection-list>
  <div class="ezp-list__fetching" *ngIf="isLoading">
    <mat-spinner class="ezp-list__fetching__spinner ezp-list__fetching__spinner--selection"></mat-spinner>
    <span class="ezp-list__fetching__title" id="group-users-fetchingusers-text">{{
      'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_USERS.FETCHING_USERS' | translate }}</span>
  </div>
</ezd-infinity-scroll>