import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { concatMap, last, map, mergeMap, toArray } from 'rxjs/operators';
import { GeneralUtil } from '../utils/general-util';
import { AdconGroup, AdconGroupsService, AdconTenantGroups } from './adcon-api.service';
import { AuthOrganizationApiService, AuthUserApiService } from './auth-api.service';
import { GroupsService, ProfileService } from './printing-api.service';

@Injectable({
  providedIn: 'root'
})
export class PoliciesPullprintingService {
  myprintersGroupsSubject = new BehaviorSubject<any>([]);
  adconServiceGroupsSubject = new BehaviorSubject<any>([]);
  adconServiceGroups = [];
  adconServiceTenantId: string;
  adconServiceGroupsCount = 0;
  myprintersGroupsSearchText: string;
  printersServiceGroupsSearchText = undefined;
  adconServiceGroupsSearchText = undefined;
  ezeepGroupSearchText: string;

  constructor(
    private profileService: ProfileService,
    private groupsService: GroupsService,
    private adconGroupsService: AdconGroupsService,
    private authUserService: AuthUserApiService,
    private authOrganizationService: AuthOrganizationApiService) { }

  public togglePullPrinting(): Observable<boolean> {
    return this.profileService.read().pipe(mergeMap(response => {
      let profile = response.result;
      profile.pull_printing_enabled = !profile.pull_printing_enabled;
      return this.profileService.update(profile).pipe(map(responseUpdate => {
        return responseUpdate.result.pull_printing_enabled;
      }));
    }));
  }

  public getCurrentPullPrintingEnableStatus(): Observable<boolean> {
    return this.profileService.read().pipe(map(response => {
      let profile = response.result;
      return profile.pull_printing_enabled;
    }));
  }
}
