import { Component, OnInit } from '@angular/core'
import { Observable, lastValueFrom } from 'rxjs'
import { map } from 'rxjs/operators'
import { AuthUserApiService } from '../../modules/shared/services/auth-api.service'
import { GroupsService, ProfileService, UserPrintingService } from '../../modules/shared/services/printing-api.service'
import { ADMIN_USER } from '../../modules/storage/constants/storage.constants'
import { StorageUtils } from '../../modules/storage/utils/storage.utils'

@Component({
  selector: 'ezd-loginsuccessful',
  templateUrl: './loginsuccessful.component.html',
  styleUrls: ['./loginsuccessful.component.scss'],
})
export class LoginSuccessfulComponent implements OnInit {
  showMyPrinters: boolean
  isAdmin: boolean
  
  constructor(
    private authApiService: AuthUserApiService,
    private profileService: ProfileService,
    private groupService: GroupsService, 
    private userPrintService: UserPrintingService) {}

  ngOnInit(): void {
    if(StorageUtils.get(sessionStorage, ADMIN_USER))
      this.isAdmin = true

    this.authApiService.me().subscribe(async response => {
      this.profileService.read().subscribe(async profileResponse => {
        let currentUser = await lastValueFrom(this.userPrintService.read(response.result.user_id)) // .toPromise()
        let allowedGroups = await lastValueFrom(this.getAllMyPrintersGroups()) // .toPromise()

        if (this.isAdmin || 
          ((profileResponse.result.myprinters_enabled === undefined || profileResponse.result.myprinters_enabled )
            && (allowedGroups.length == 0 || allowedGroups.filter(x => currentUser.result.groups.filter(y => x.id === y.group_id).length > 0).length > 0)))
          this.showMyPrinters = true
        else
          this.showMyPrinters = false
      })
    })
  }
  
  private getAllMyPrintersGroups(page_size:number = 100, page_index:number = 0) : Observable<Promise<any>> {
    let offset = page_index * page_size
    return this.groupService.list(undefined, undefined, undefined, undefined, undefined, undefined, page_size, offset)
      .pipe(map(async response => {
        let groups = []
        if(response.result.next) {
          let nextIndex = page_index + 1
          groups = await lastValueFrom(this.getAllMyPrintersGroups(page_size, nextIndex)) // .toPromise()
        }
        return [...response.result.results.filter(x => x.myprinters_enabled), ...groups];
      }));
  }
}
