import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core'
import { Subscription } from 'rxjs'
import { EzpDocumentsService } from '../../modules/shared/services/ezp-documents.service'
import { Router } from '@angular/router'
import {
  EZEEP_DOCMAN_BASE_URL,
  EZEEP_DOCMAN_MAIN_DART,
  EZEEP_DOCMAN_SERVICE_WORKER,
} from '../../app.config'

declare var _flutter: any

@Component({
  selector: 'ezd-edocs',
  template: '',
})
export class DocsComponent {}

@Component({
  selector: 'ezd-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements AfterViewInit {
  private subscription: Subscription
  @ViewChild('flutterTarget') flutterTarget!: ElementRef
  flutterLogIn?: any
  flutterPrintDocument?: any

  constructor(private ezpDocumentsService: EzpDocumentsService, public router: Router) {}

  ngOnInit() {
    this.subscription = this.ezpDocumentsService.firebaseLogin$.subscribe(() => {
      this.flutterLogIn.logIn()
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  ngAfterViewInit(): void {
    const target: HTMLElement = this.flutterTarget.nativeElement
    this.loadDocumentManagment(target)
    this.addEventListener(target)
  }

  loadDocumentManagment(target: HTMLElement) {
    _flutter.loader.loadEntrypoint({
      entrypointUrl: EZEEP_DOCMAN_MAIN_DART,
      serviceWorker: {
        serviceWorkerUrl: EZEEP_DOCMAN_SERVICE_WORKER,
      },
      onEntrypointLoaded: async (engineInitializer: any) => {
        let appRunner = await engineInitializer.initializeEngine({
          hostElement: target,
          assetBase: `${EZEEP_DOCMAN_BASE_URL}/`,
        })
        await appRunner.runApp()
      },
    })
  }

  addEventListener(target: HTMLElement) {
    target.addEventListener('login', (event: Event) => {
      let logInState = (event as CustomEvent).detail
      this.flutterLogIn = logInState
    })

    target.addEventListener('printDocument', (event: Event) => {
      let printState = (event as CustomEvent).detail
      this.flutterPrintDocument = printState
      this.ezpDocumentsService.printDocument(this.flutterPrintDocument.getDocument())
    })
  }
}
