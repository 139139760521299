<h2 mat-dialog-title id="add-group-text">{{ 'POLICIES.ADD_GROUP_DIALOG.ADD_GROUP' | translate }}</h2>
<div mat-dialog-content>
  <div class="ezp-grid ezp-grid--spacing-bottom" data-grid-cols="1">
    <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
      <mat-label>{{ 'POLICIES.ADD_GROUP_DIALOG.SEARCH_GROUPS' | translate }}</mat-label>
      <input matInput id="policy-search-groups" placeholder="Group Name" (input)="onSearchChange($event.target.value)"/>
    </mat-form-field>
  </div>
  <ezd-infinity-scroll (scrolled)="onScroll()">
  <mat-selection-list class="ezp-list ezp-list--filterable" *ngIf="!isLoading" #groups1 (selectionChange)="groupChanged($event, groups1.selectedOptions.selected)">
    <mat-list-option
      *ngFor="let group of groups"
      checkboxPosition="before"
      color="primary"
      [value]="group"
      [id]="group.name"
    >
      {{ group.name }}
    </mat-list-option>
  </mat-selection-list>
  <div class="ezp-list__fetching" *ngIf="isLoading">
    <mat-spinner
      class="ezp-list__fetching__spinner ezp-list__fetching__spinner--selection"
    ></mat-spinner>
    <span class="ezp-list__fetching__title" id="users-fetching-groups-text">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_GROUPS.FETCHING_GROUPS' | translate }}</span>
  </div>
</ezd-infinity-scroll>
  <!-- <ezd-loading-icon [isLoading]="isLoading"></ezd-loading-icon> -->
</div>
<div mat-dialog-actions>
  <button mat-button id="policy-groups-close" (click)="close()" color="primary" class="ezp-button">{{ 'CANCEL' | translate }}</button>
  <button mat-button id="policy-groups-add" (click)="add()" color="primary" class="ezp-button" [disabled]="!canAddGroups || isLoading">{{ 'ADD' | translate }}</button>
</div>
