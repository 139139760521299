<mat-form-field
  class="ezp-form-field ezp-form-field--full ezp-form-field--densed input-field"
  id="search-connectors"
  appearance="fill"
>
  <mat-icon matPrefix>search</mat-icon>
  <input
    type="text"
    matInput
    id="search-connectors-input"
    [placeholder]="'CONNECTORS.CONNECTORS_SEARCH.PLACEHOLDER' | translate"
    (input)="onSearchChange($event.target.value)"
  />
  <mat-hint align="start" id="search-connectors-hint">{{ 'CONNECTORS.CONNECTORS_SEARCH.HINT' | translate }}</mat-hint>
</mat-form-field>
