import { Injectable } from '@angular/core';
import { PrinterProfileApiService, PrinterProfile, PrinterCapabilityApiService } from './printer-profile-api.service';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { DEFAULT_PAGE_SIZE } from '../../../app.config';
import { PrinterProfileAssignmentApiService } from './printer-profile-assignment-api.service';
import { GROUPS, USERS } from '../../../pages/pages.constants';

@Injectable({
  providedIn: 'root'
})

export class PrinterProfileService {

  printerProfilesSubject = new BehaviorSubject<any>({ isLoading: false, count: 0, profiles: [] });
  pageIndex: number = 0;
  pageSize: number = DEFAULT_PAGE_SIZE;
  profilesCount: any;
  profiles: any;
  sorting: any;
  searchText: string = undefined;

  constructor(private printerProfileApiService: PrinterProfileApiService, private printerProfileAssignment: PrinterProfileAssignmentApiService, private printerCapabilityApiservice : PrinterCapabilityApiService) { }

  getFilteredProfiles(): Observable<any> {
    return this.printerProfilesSubject.asObservable();
  }

  applyFilters(showLoading: boolean = true) {
    this.printerProfilesSubject.next({ isLoading: showLoading, count: 0, profiles: [] });
    this.printerProfileApiService.list(undefined, this.searchText ? this.searchText : undefined, this.sorting ? this.sorting : "-name", this.pageSize, this.pageIndex * this.pageSize).subscribe(async response => {
      this.profiles = response.result.results;
      this.profilesCount = response.result.count;
      this.printerProfilesSubject.next({
        isLoading: false,
        count: response.result.count,
        profiles: this.profiles
      });
    }, error => {
      this.printerProfilesSubject.next({ isLoading: false, count: this.profilesCount, profiles: this.profiles });
    });
  }

  setPage(pageIndex: number, pageSize: number, isInitial = false) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    if (!isInitial) {
      this.applyFilters(true);
    }
  }

  setSorting(sorting) {
    this.sorting = sorting;
    this.applyFilters(false);
  }

  setSearchText(result: string) {
    this.searchText = !result ? undefined : result;
    this.applyFilters(false);
  }

  saveProfile(isEdit: boolean, profile) {
    if (isEdit) {
      return this.printerProfileApiService.update(this.formatProfilePayload(profile), profile.id)
        .pipe(map(response => {
          return response.result;
        }));
    }

    if (!isEdit) {
      return this.printerProfileApiService.create(this.formatProfilePayload(profile))
        .pipe(map(response => {
          return response.result;
        }));
    }
  }

  formatProfilePayload(profile) {

    profile.resolution = profile.resolution == "Printer Default" ? null : profile.resolution;
    profile.tray = profile.tray == "Printer Default" ? null : profile.tray;
    profile.duplex = profile.duplex == "DEFAULT" ? null : profile.duplex;
    profile.color = profile.color == "DEFAULT" ? null : profile.color;

    profile.selected_printers = profile.printers?.map(printer => printer.id) || [];

    profile.paper_id = profile.paper_id == '0' ? null : profile.paper_id;
    profile.paper_name = profile.paper_name == 'Printer Default' ? null : profile.paper_name;

    // Clean-up
    ['printers', 'paper_size', 'autoCompleteInput'].forEach(key => delete profile[key]);

    return profile;
  }

  removeProfile(id: string): Observable<any> {
    return this.printerProfileApiService.delete(id);
  }

  getProfiles(pageSize: number, pageIndex: number) {
    return this.printerProfileApiService.list(undefined, undefined, undefined, pageSize, pageIndex * pageSize).pipe(
      map((response: any) => {
        if (response.error) throw response.error;
        return response.result.results
      }));
  }

  getProfilesByPrinterId(printer_id: string) {
    return this.printerProfileApiService.list(printer_id, undefined, undefined, undefined, undefined).pipe(
      map((response: any) => {
        if (response.error) throw response.error;
        return response.result.results
      }));
  }
  
  updateAssignments(type: string, profile_id: string, printer_id : string ,user_or_grou_id: string) {
    let payload: Record<string, string>;

    switch (type) {
      case USERS:
        payload = {
          printer_profile_id: profile_id,
          user_id: user_or_grou_id
        };
        break;
      case GROUPS:
        payload = {
          printer_profile_id: profile_id,
          group_id: user_or_grou_id
        };
        break;
    }

    return this.printerProfileAssignment.update(type, printer_id, payload).pipe(
      map((response: any) => {
        if (response.error) {
          throw response.error;
        }
        return response.result;
      })
    );
  }

  deleteAssignments(printer_id: string, user_or_group_id: string, type: string) {
    return this.printerProfileAssignment.delete(printer_id, user_or_group_id, type)
  }

  getGroupedPrinterPresets(printer_id: string, type: string, pageSize: number, pageIndex: number) {
    return this.printerProfileAssignment.list(printer_id, type, pageSize, pageIndex * pageSize).pipe(
      map((response: any) => {
        if (response.error) throw response.error;
        return response.result.results
      }));
  }

  getPrinterCapabilities(printer_ids: Array<string>) {
    const printerIds = printer_ids.join(',');
    return this.printerCapabilityApiservice.get(printerIds).pipe(
      map((response: any) => {
        if (response.error) throw response.error;
        return response.result
      }));
  }  
}
