
import {tap} from 'rxjs/operators';
import { Injectable }                                                           from '@angular/core';
import { HttpEvent, HttpEventType, HttpHeaders, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse }  from '@angular/common/http';
import { Observable }                                                           from 'rxjs';
import { EZEEP_AZURE_API_URL_LOGIN } from '../../../app.config'
import { StorageUtils } from '../../storage/utils/storage.utils'
import { JWT_TOKEN } from '../../storage/constants/storage.constants'

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<HttpEventType.Response>> {
    if (!req.url.includes('accounts') && !req.url.includes('account')) {
      const authRequest = req.clone({
        headers: this.getHeaders(req.headers),
      })
      return next.handle(authRequest).pipe(
        tap(null, (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              if (
                window.location.hostname.includes('.azdev.') ||
                window.location.hostname.includes('app.ezeep.')
              ) {
                window.location.href = EZEEP_AZURE_API_URL_LOGIN
              }
            }
          }
        })
      )
    }

    return next.handle(req)
  }

  // -------------------------------------------
  // Private methods
  // -------------------------------------------

  private getHeaders(req: HttpHeaders): HttpHeaders {
    const TOKEN: string = StorageUtils.get(sessionStorage, JWT_TOKEN)

    if (TOKEN) {
      req = req.set('Authorization', `Bearer ${TOKEN}`)
    }

    return req
  }
}
