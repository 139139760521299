import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthUserApiService } from './auth-api.service';
import { GroupsService, ProfileService, UserPrintingService } from './printing-api.service';

@Injectable({
  providedIn: 'root'
})
export class AppDownloadsViewService {

  constructor(
    private profileService: ProfileService,
    private groupsService: GroupsService,
    private userPrintService: UserPrintingService,
    private authApiService: AuthUserApiService) { }

  public getCurrentLocalPrintingEnableStatus(): Observable<boolean> {
    return this.profileService.read().pipe(map(response => {
      let profile = response.result;
      return profile.local_printing_enabled;
    }));
  }

  public isAllowedGroupsLocalPrinting(): Observable<any> {
    return this.authApiService.me().pipe(map(async response => {
      let currentUser = await lastValueFrom(this.userPrintService.read(response.result.user_id)) // // .toPromise()
      let allowedGroups = await lastValueFrom(this.getAllLocalSupportGroups()) // // .toPromise()
      return allowedGroups.filter(x => currentUser.result.groups.filter(y => x.id === y.group_id).length > 0).length > 0 || allowedGroups.length == 0
    }))
  }

  getAllLocalSupportGroups(page_size:number = 100, page_index:number = 0) : Observable<Promise<any>> {
    let offset = page_index * page_size
    return this.groupsService.list(undefined, 'true', undefined, undefined, undefined, undefined, page_size, offset)
      .pipe(map(async response => {
        let groups = []
        if(response.result.next) {
          let nextIndex = page_index + 1
          groups = await lastValueFrom(this.getAllLocalSupportGroups(page_size, nextIndex)) // // .toPromise()
        }
        return [...response.result.results, ...groups];
      }));
  }
}
