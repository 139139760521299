<div>
  <div class="ezp-layout__part">
    <mat-form-field class="ezp-form-field ezp-form-field--full ezp-form-field--densed" id="search-my-printers"
      appearance="fill">
      <mat-icon matPrefix>search</mat-icon>
      <input type="text" matInput id="search-my-printers-input"
        placeholder="{{ 'PRINTERS.MY_PRINTERS.WELCOME.MY_PRINTERS.PRINTERS_LIST.PLACEHOLDER' | translate }}"
        (input)="onSearchChange($event.target.value)" />
      <mat-hint align="start" id="search-my-printers-hint">{{ 'PRINTERS.MY_PRINTERS.WELCOME.MY_PRINTERS.PRINTERS_LIST.HINT' |
        translate }}</mat-hint>
    </mat-form-field>
  </div>

  <div class="ezp-layout__part">
    <div class="ezp-table">
      <table mat-table [dataSource]="printersDataSource" matSort (matSortChange)="sortChange($event)"
        class="ezp-table__data">
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--toggle"></th>
          <td mat-cell *matCellDef="let printer" class="ezp-table__cell ezp-table__cell--toggle"
            [id]="printer.id + '-toggle'">
            <mat-slide-toggle #favoritePrinter color="primary"
              [checked]="printer.is_favorite || !printer.can_modify_favorite" [disabled]="!printer.can_modify_favorite"
              (change)="onFavoriteChange(favoritePrinter, printer)"></mat-slide-toggle>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="ezp-table__cell ezp-table__cell--text"
            id="myprinter-name">
            {{ 'PRINTERS.MY_PRINTERS.WELCOME.MY_PRINTERS.PRINTERS_LIST.NAME' | translate }}
          </th>
          <td mat-cell *matCellDef="let printer" class="ezp-table__cell ezp-table__cell--text"
            [id]="printer.id + '-name'">
            {{ printer.name }}
          </td>
        </ng-container>
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="ezp-table__cell ezp-table__cell--text"
            id="myprinter-location">
            {{ 'PRINTERS.MY_PRINTERS.WELCOME.MY_PRINTERS.PRINTERS_LIST.LOCATION' | translate }}
          </th>
          <td mat-cell *matCellDef="let printer" class="ezp-table__cell ezp-table__cell--text ezp-table__cell--soft"
            [id]="printer.id + '-location'">
            {{ printer.location }}
          </td>
        </ng-container>
        <ng-container matColumnDef="model">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="ezp-table__cell ezp-table__cell--text"
            id="myprinter-model">
            {{ 'PRINTERS.MY_PRINTERS.WELCOME.MY_PRINTERS.PRINTERS_LIST.MODEL' | translate }}
          </th>
          <td mat-cell *matCellDef="let printer" class="ezp-table__cell ezp-table__cell--text ezp-table__cell--soft"
            [id]="printer.id + '-model'">
            {{ printer.model_name }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnDefs"></tr>
        <tr mat-row *matRowDef="let printer; columns: columnDefs"></tr>
      </table>
      <mat-spinner *ngIf="isLoading" class="ezp-spinner ezp-spinner--large ezp-spinner--table"
        id="myprinters-loading-spinner"></mat-spinner>
      <mat-paginator class="efa-paginator" id="myprinters-paginator" #paginator [length]="maxPageLength"
        [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
        (page)="onPaginationChange($event)">
      </mat-paginator>
    </div>
  </div>
</div>