import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'
import { UsersgroupsGroupsUsersService } from '../../../../modules/shared/services/usersgroups-groups-users.service'
import { UsersgroupsGroupsService } from '../../../../modules/shared/services/usersgroups-groups.service';
import { UserPrintingService } from '../../../../modules/shared/services/printing-api.service';
import { Subject, Subscription, lastValueFrom } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { AuthUserApiService } from '../../../../modules/shared/services/auth-api.service';
import { GeneralUtil } from '../../../../modules/shared/utils/general-util';

@Component({
  selector: 'ezd-group-details-users',
  templateUrl: './group-details-users.component.html',
  styleUrls: ['./group-details-users.component.scss'],
})
export class GroupDetailsUsersComponent implements OnInit {
  @ViewChild('search') search: ElementRef;
  assignedUsers: any[] = []
  isLoading = true;
  endOfList = false;
  group: any;
  isEzeepGroup: boolean;
  searchTextChanged = new Subject<string>();
  userServiceGetAllUsersSubscription: Subscription;
  searchText: string;
  currentPageIndex = 0;

  constructor(
    private usersService: UsersgroupsGroupsUsersService,
    private groupService: UsersgroupsGroupsService,
    private userPrintService: UserPrintingService,
    private authUserService : AuthUserApiService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.group = this.groupService.getGroupToEdit();
    this.isEzeepGroup = this.group.origin && this.group.origin.provider === 'Ezeep';

    let assignedUsersIds = await lastValueFrom(this.userPrintService.getUsersInGroup(this.group.id))
    // chunking here as api has a limit of 100
    const chunkedUsersIds = GeneralUtil.chunck(assignedUsersIds, 100) // TODO: Store it in a constant

    Promise.all(chunkedUsersIds.map(async user_ids => {
      const g = await lastValueFrom(this.authUserService.listMany(user_ids))
      return g.result.results.map(u => ({ ...u, ["isSelected"]: true }))
    })).then(allAssignments => {
      allAssignments = [].concat(...allAssignments)
      this.userServiceGetAllUsersSubscription = this.usersService.getAllUsers(this.searchText, this.group.id)
        .subscribe(async result => {
          assignedUsersIds = GeneralUtil.searchArray(allAssignments, this.searchText, 'display_name','email'); //TODO: save this key in constants
          if (!result.isLoading && result.users) {

            const orderedUsers = result.users;
            this.assignedUsers = [...this.assignedUsers, ...assignedUsersIds, ...orderedUsers];

            this.assignedUsers = GeneralUtil.removeDuplicates(this.assignedUsers)
            // So here we will check the main array if it has some of the assigned users which appeared in search from api but should be there
            this.assignedUsers = this.assignedUsers.filter(obj => !allAssignments.some(item => (item["id"] === obj.id && !obj.isSelected)));
            // if array is less than 25 then fetch more
            if (this.assignedUsers.length < 25) {
              if (!result.endOfList) {
                this.currentPageIndex += 1;
                this.usersService.setAllUsers(this.searchText, this.group.id, 25, 25 * this.currentPageIndex).subscribe();
                return;
              }
            }
          }

          this.isLoading = result.isLoading;
          this.endOfList = result.endOfList;
          if (this.endOfList) {
            this.currentPageIndex = 0
          }

        });
    })


    this.searchTextChanged
      .pipe(debounceTime(400), switchMap(result => {
        this.searchText = result
        this.isLoading = true
        this.assignedUsers = []
        return this.usersService.setAllUsers(result, this.group.id, 25, 0);
      })).subscribe();
  }

  ngAfterContentChecked() {
    if (this.search) {
      this.search.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.userServiceGetAllUsersSubscription.unsubscribe()
    this.assignedUsers = [];
    this.endOfList = false;
    this.isLoading = true;
    //this.group = undefined;
  }

  onScroll() {
    if (this.endOfList == undefined || this.endOfList || this.isLoading) return;
    this.isLoading = true;
    this.currentPageIndex += 1
    this.usersService.setAllUsers(this.searchText, this.group.id, 100, 100 * this.currentPageIndex).subscribe();
  }

  onSelection(e, v) {
    const currentAssignedUsers = this.assignedUsers.filter(g => g.isSelected)
    const newAssignments = v.selected.map(u => {
      return u.value
    })

    this.usersService.draftAssignedUsers(currentAssignedUsers, newAssignments)
  }

  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue);
  }
}
