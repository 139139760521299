<div class="ezp-layout__page">
  <div class="ezp-layout__content">
    <h1 class="mat-headline" id="usergroups-title-text">{{ 'USERSGROUPS.TITLE' | translate }}</h1>
    <mat-tab-group
      class="ezp-tabs"
      (selectedTabChange)="tabClick($event)"
      [selectedIndex]="currentTabIndex"
      animationDuration="0ms"
    >
      <mat-tab label="users">
        <ng-template mat-tab-label>
          <mat-icon>person</mat-icon>
          <span>{{ 'USERSGROUPS.TABS.USERS' | translate }}</span>
        </ng-template>
        <div class="ezp-layout__part">
          <ezd-usersgroups-users [groupsEnabled]="enableGroups"></ezd-usersgroups-users>
        </div>
      </mat-tab>
      <mat-tab label="groups">
        <ng-template mat-tab-label>
          <mat-icon>group</mat-icon>
          <span>{{ 'USERSGROUPS.TABS.GROUPS' | translate }}</span>
        </ng-template>
        <div class="ezp-layout__part">
          <ezd-usersgroups-groups
            (showGroupDialog)="showGroupDialog($event)"
          ></ezd-usersgroups-groups>
        </div>
      </mat-tab>
      <mat-tab label="domains">
        <ng-template mat-tab-label>
          <mat-icon>link</mat-icon>
          <span>{{ 'USERSGROUPS.TABS.DOMAINS' | translate }}</span>
        </ng-template>
        <div class="ezp-layout__part">
          <ezd-usersgroups-domains></ezd-usersgroups-domains>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
  <ezd-add-rename-groups
    (dialogResult)="processDialogResult($event)"
    [operationType]="operationType"
    *ngIf="showDialog"
  ></ezd-add-rename-groups>
</div>
