import { IfStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubscriptionApiService } from './subscription-api.service';

export const NOT_FOUND_SUBSCRIPTION = "NOT_FOUND_SUBSCRIPTION"
export const AZURE_SUBSCRIPTION = "AZURE_SUBSCRIPTION"
export const ACTIVE_SUBSCRIPTION = "ACTIVE_SUBSCRIPTION"
export const TRIAL_SUBSCRIPTION = "TRIAL_SUBSCRIPTION"
export const EXPIRED_TRIAL_SUBSCRIPTION = "EXPIRED_TRIAL_SUBSCRIPTION"
export const CANCELLED_SUBSCRIPTION = "CANCELLED_SUBSCRIPTION"
export const CUSTOM_SUBSCRIPTION = "CUSTOM_SUBSCRIPTION"

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private plans$ = new BehaviorSubject([]);
  plansArray$ = this.plans$.asObservable();

  private freePlan$ = new BehaviorSubject<boolean>(false)
  activeFreePlan$ = this.freePlan$.asObservable();

  private proPlan$ = new BehaviorSubject<boolean>(false)
  activeProPlan$ = this.proPlan$.asObservable();

  constructor(private subscriptionApi: SubscriptionApiService) { }

  getSubscription() : Observable<any> {
    return this.getLastSubscription().pipe(map(response => {
      if(!response) return {status_type: NOT_FOUND_SUBSCRIPTION}

      if(response.source_type == "azuremarketplace"){
        response.status_type = AZURE_SUBSCRIPTION
        return response
      }

      if(response.source_type == "chargebee" && response.source && response.source.ezeep_link == "Custom") {
        response.status_type = CUSTOM_SUBSCRIPTION
        return response
      }

      switch (response.status) {
        case "active":
          response.status_type = ACTIVE_SUBSCRIPTION
          break
        case "trial":
          if ((new Date(response.expires)).getTime() - new Date().getTime() > 0)
            response.status_type = TRIAL_SUBSCRIPTION
          else
            response.status_type = EXPIRED_TRIAL_SUBSCRIPTION
          break
        case "suspended":
            response.status_type = CANCELLED_SUBSCRIPTION
          break
        case "canceled":
          response.status_type = CANCELLED_SUBSCRIPTION
          break
        default:
          response.status_type = NOT_FOUND_SUBSCRIPTION
      }
      
      return response
    }))
  }

  private getLastSubscription() : Observable<any> {
    return this.subscriptionApi.read("-created").pipe(map(response => {
      let subscriptions = response.result.result
      if (subscriptions && subscriptions[0])
        return subscriptions[0]
      else
        return undefined
    }))
  }

  setPlans(plans: any[]) {
    this.plans$.next(Object.assign([], plans));
  }

  setActiveFreePlan(plan: boolean) {
    this.freePlan$.next(plan);
  }

  setActiveProPlan(plan: boolean) {
    this.proPlan$.next(plan);
  }
}
