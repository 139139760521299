import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AuthOrganizationApiService, URL_REGEX } from '../../services/auth-api.service';
import { ErrorService } from '../../services/error.service'
import { ValidatorFn } from '@angular/forms';

@Component({
  selector: 'ezd-rename-organization',
  templateUrl: './rename-organization.component.html',
  styleUrls: ['./rename-organization.component.scss']
})
export class RenameOrganizationDialogComponent implements OnInit {
  organizationId: string;
  currentOrgName: string;
  isLoading: boolean = false
  isDisabled: boolean = false
  constructor(public dialogRef: MatDialogRef<RenameOrganizationDialogComponent>,
    private authOrganizationService: AuthOrganizationApiService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private errorService: ErrorService) {
    this.organizationId = data.id;
    this.currentOrgName = data.name;
  }

  ngOnInit(): void {
  }

  renameOrganization() {
    this.isLoading = true
    this.authOrganizationService.patch(this.organizationId, { "name": this.currentOrgName }).subscribe(
      () => {
        this.dialogRef.close();
        setTimeout(() =>
          document.location.reload(),
          1000);
      }
    )
  }

  nameValidator: ValidatorFn = (c) => {
    if (URL_REGEX.test(c.value) || c.value == '') {
      this.isDisabled = true
      return { ['isURL']: true };
    } else {
      this.isDisabled = false
    }
  };

  close() {
    this.dialogRef.close()
  }

}

