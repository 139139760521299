<mat-icon class="icon">check_circle</mat-icon>
<div class="text">
  <div class="mat-subheading-2 title"  [innerHtml]="'SUBSCRIPTION.SUBSCRIPTION_ACTIVE.STATUS' | translate">
  </div>
  <div *ngIf="isNextBillingDatePresent" class="mat-body ezp-has-text-link subtitle">
    {{ 'SUBSCRIPTION.TRIAL_ACTIVE.NEXT_BILLING_DATE' | translate }} {{ nextBillingDate }}.</div>
</div>
<div class="mat-body ezp-has-text-link subtitle">
  {{ 'SUBSCRIPTION.SUBSCRIPTION_ACTIVE.CONTINUE' | translate }}
</div>
<button *ngIf="sourceType === 'chargebee'" class="ezp-button" mat-flat-button color="primary" (click)="openChargebeePortal()">
  {{ 'SUBSCRIPTION.ACTIONS.MANAGE_SUBSCRIPTION' | translate }}
</button>
