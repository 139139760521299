import { NgModule } from '@angular/core'
import { MaterialModule } from '../../modules/material/material.module'
import { CoreModule } from '../../modules/core/core.module'
import { SharedModule } from '../../modules/shared/shared.module'
import { DownloadsComponent } from './downloads.component'
import { HttpClientModule } from '@angular/common/http'

@NgModule({
  declarations: [DownloadsComponent],
  imports: [MaterialModule, CoreModule, SharedModule, HttpClientModule],
})
export class DownloadsModule {}
