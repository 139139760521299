export const STORAGE_PREFIX: String = 'ezd';

export const LANGUAGE_KEY = 'language';
export const JWT_TOKEN = 'jwt';
export const JWT_REFRESH_TOKEN = 'jwtRefresh';
export const JWT_SCOPE = 'jwtScope';
export const USER_ID = 'userId'
export const ADMIN_USER = 'azureUser';
export const COMMON_USER = 'commonAzureUser';
export const HIDE_OVERLAY_MYPRINTERS = 'hideOverlayMyprinters';
export const HIDE_OVERLAY_QUICKSTART = 'hideOverlayQuickstart';
export const REPORTING_BANNER = 'reporting-banner';
export const SUBSCRIPTION_BANNER = 'subscription-banner';
