<div class="ezp-layout__page ezp-layout__page--toolbar">
  <div class="ezp-layout__content">
    <h1 class="mat-headline" id="myprinters-heading">{{ 'CONNECTORS.TITLE' | translate }}</h1>

    <div class="ezp-layout__head ezp-layout__head--split">
      <ezd-connectors-search></ezd-connectors-search>
      <div class="ezp-layout__head__actions">
        <button mat-raised-button id="connector-add-menu" color="primary"
          class="ezp-button ezp-button--icon ezp-button--icon--leading" (click)="openClaimHubDialog()">
          <mat-icon>add</mat-icon>{{ 'CONNECTORS.CONNECTORS_ADD.MENU.ADD_HUB' | translate }}
          <!-- <span *ngIf="isHubClaimingPresent == false"><mat-icon class="icon-star">star</mat-icon></span> -->
        </button>
        <!-- <mat-menu #addConnectorMenu="matMenu" xPosition="before">
          <button mat-menu-item id="open-claim-hub-dialog" (click)="openClaimHubDialog()">{{
            'CONNECTORS.CONNECTORS_ADD.MENU.ADD_HUB' | translate }}
            <span *ngIf="isHubClaimingPresent == false"><mat-icon class="icon-star">star</mat-icon></span>
          </button>
          <a href="https://ezeep.io/3xtWNhi" target="_blank" mat-menu-item id="add-connector-text">{{
            'CONNECTORS.CONNECTORS_ADD.MENU.ADD_CONNECTOR' | translate }}</a>
        </mat-menu> -->
      </div>
    </div>
    <div class="ezp-layout__part" *ngIf="showMessageNoConnectors && !isLoading">
      <div class="ezp-note">
        <div class="ezp-note__icon">
          <mat-icon matPrefix>warning</mat-icon>
        </div>
        <div class="ezp-note__text">
          <p class="ezp-text ezp-text--high-emphasis mat-subheading-2" id="no-connector-text1">
            <strong>{{
              'CONNECTORS.NO_CONNECTORS_TEXT1' | translate
              }}</strong>
          </p>
          <p class="ezp-text ezp-text--high-emphasis mat-body-1" id="no-connector-text2"
            [innerHtml]="'CONNECTORS.NO_CONNECTORS_TEXT2' | translate"></p>
        </div>
      </div>
    </div>
    <div class="ezp-layout__part" *ngIf="!showMessageNoConnectors">
      <div class="ezp-table">
        <table mat-table [dataSource]="networkPrintersConnectorsTableDataSource" matSort
          (matSortChange)="sortChange($event)" matSortDisableClear="true" class="ezp-table__data" id="connectors-table"
          *ngIf="!isLoading && !showMessageNoConnectors">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--select">
            </th>
            <td mat-cell *matCellDef="let connector" class="ezp-table__cell ezp-table__cell--select">
              <mat-checkbox (change)="selectConnector($event, connector)" [checked]="connector.isSelected"
                [(ngModel)]="connector.isSelected" color="primary" [id]="connector.id">
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="ezp-table__cell ezp-table__cell--text"
              id="connectors-header-name-text">
              {{ 'CONNECTORS.CONNECTORS_TABLE_HEADERS.NAME' | translate }}
            </th>
            <td mat-cell *matCellDef="let connector" class="ezp-table__cell ezp-table__cell--text"
              id="{{connector.id + '-name'}}">
              {{ connector.readable_name }}
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="ezp-table__cell ezp-table__cell--text"
              id="connectors-header-desc-text">
              {{
              'CONNECTORS.CONNECTORS_TABLE_HEADERS.DESCRIPTION'
              | translate
              }}
            </th>
            <td mat-cell *matCellDef="let connector" class="ezp-table__cell ezp-table__cell--text"
              id="{{connector.id + '-description'}}">
              {{ connector.description }}
            </td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="ezp-table__cell ezp-table__cell--text"
              id="connectors-header-type-text">
              {{ 'CONNECTORS.CONNECTORS_TABLE_HEADERS.TYPE' | translate }}
            </th>
            <td mat-cell *matCellDef="let connector" class="ezp-table__cell ezp-table__cell--text"
              id="{{connector.id + '-type'}}">
              {{
              connector.type == 'TPHub'
              ? 'ThinPrint Hub'
              : connector.type == 'ezeepHub'
              ? 'ezeep Hub'
              : connector.type == 'Printserver'
              ? 'Print server'
              : ''
              }}
            </td>
          </ng-container>
          <ng-container matColumnDef="manage">
            <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon">
            </th>
            <td mat-cell *matCellDef="let connector"
              class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon">
              <a [href]="connector.management_url" [id]="connector.id" target="_blank" rel="noreferrer" mat-icon-button
                *ngIf="connector.type == 'TPHub' || connector.type == 'ezeepHub'">
                <mat-icon>launch</mat-icon>
              </a>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnDefs"></tr>
          <tr mat-row *matRowDef="let connector; columns: columnDefs"></tr>
        </table>
        <mat-spinner *ngIf="isLoading" class="ezp-spinner ezp-spinner--large ezp-spinner--table"></mat-spinner>
        <mat-paginator *ngIf="!isLoading && !showMessageNoConnectors" class="efa-paginator" id="connectors-paginator"
          [length]="maxPageLength" [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
          (page)="onPaginationChange($event)">
        </mat-paginator>
      </div>
    </div>
  </div>

  <connectors-filters *ngIf="showConnectorsFilters"></connectors-filters>
  <ezd-connectors-properties *ngIf="showConnectorsProperties" (forceShowFilterView)="forceShowFilter()"
    (showPrinters)="showPrinters($event)" (showDeleteConnectorMessage)="showDeleteConnectorMessage()">
</ezd-connectors-properties>

  <ezd-alert-changing-properties (dialogResult)="processDialogResult($event)" *ngIf="showDialog">
  </ezd-alert-changing-properties>
  <ezd-alert-delete-connector (dialogResult)="proccessDialogDeleteConnectorResult($event)"
    *ngIf="showDialogDeleteConnector"></ezd-alert-delete-connector>
</div>