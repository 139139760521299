import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EZEEP_PRINTING_API } from '../../../app.config';
import { saveAs } from '../../../../../node_modules/file-saver/dist/FileSaver.min.js';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable({
  providedIn: 'root'
})

export class GenerateQRCodeService {
  fileName = 'printer-qrcode.pdf';

  constructor(private httpClient: HttpClient) {}
  
  public downloadQRCode(
    printerIds: string[], 
    format: string, 
    showPrinterName: string, 
    pageSize: string,
    codeSize: string,
    defaultFilename: string | null) {
    let fileName = defaultFilename ? defaultFilename : this.fileName
    this.getQRCode(printerIds, format, showPrinterName, pageSize, codeSize)
      .subscribe(resp => {
        saveAs(resp.body, fileName);
      });
  }

  public copyQRCode(
    printerIds: string[], 
    format: string, 
    showPrinterName: string, 
    pageSize: string, 
    codeSize: string) {
    this.getQRCode(printerIds, format, showPrinterName, pageSize, codeSize)
      .subscribe(async resp => {
        await navigator.clipboard.write([
          new ClipboardItem({[resp.body.type]:resp.body})
        ])
      });
  }

  public getQRCode(
      printerIds: string[], 
      format: string, 
      showPrinterName: string, 
      pageSize: string, 
      codeSize: string
      ) {
    const body = { 
        "printers": printerIds,
        "format": format,
        "show_printer_name": showPrinterName,
        "page_size": pageSize,
        "code_size": codeSize
     };
    return this.httpClient.post(`${EZEEP_PRINTING_API}/v1/actions/generate_qr/`, body, { observe:'response', responseType: "blob" })
  }
}
