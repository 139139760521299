import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  Renderer2,
  ElementRef,
  OnDestroy,
  AfterViewInit,
} from '@angular/core'
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  NavigationError,
  Event as NavigationEvent,
} from '@angular/router'
import { DomSanitizer } from '@angular/platform-browser'
import { TranslateService } from '@ngx-translate/core'
import { Observable, Subscription, lastValueFrom } from 'rxjs'
import { ANIMATIONS } from './app.animations'
import {
  isProd,
  EZEEP_AZURE_API_URL_LOGOFF,
  env,
  azureAccountHostUrl,
  printApiHostUrl,
  redirectUri,
  EZEEP_AZURE_ACCOUNT_URL,
  ANDROID_BUNDLE_NAME,
  ANDROID_STORE_LINK,
  IPHONE_STORE_LINK,
  IPHONE_BUNDLE_NAME,
  EZEEP_CLIENT_ID,
} from './app.config'
import { AuthenticationService } from './modules/authentication/services/authentication.service'
import { PrintNowSharedService } from './modules/shared/services/print-now-shared.service'
import { AppConfig } from './modules/shared/services/appconfig.service'
import { LangaugeService } from './modules/shared/services/langauge.service'
import { Location } from '@angular/common'
import { ErrorService } from './modules/shared/services/error.service'
import { StorageUtils } from './modules/storage/utils/storage.utils'
import { ADMIN_USER, COMMON_USER } from './modules/storage/constants/storage.constants'
import { SubscriptionApiService } from './modules/shared/services/subscription-api.service'
import { MatIconRegistry } from '@angular/material/icon'
import { MediaMatcher } from '@angular/cdk/layout'
import { AppComponentService } from './modules/shared/services/app-component.service'
import { AuthUserApiService } from './modules/shared/services/auth-api.service'
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog'
import { VerifyEmailReminderDialogComponent } from './modules/shared/components/verify-email-reminder-dialog/verify-email-reminder-dialog.component'
import { VerifyEmailConfirmedDialogComponent } from './modules/shared/components/verify-email-confirmed-dialog/verify-email-confirmed-dialog.component'
import { VerifyEmailFailedDialogComponent } from './modules/shared/components/verify-email-failed-dialog/verify-email-failed-dialog.component'
import { RenameOrganizationDialogComponent } from './modules/shared/components/rename-organization/rename-organization.component'
import {
  GroupsService,
  ProfileService,
  UserPrintingService,
} from './modules/shared/services/printing-api.service'
import { filter, map } from 'rxjs/operators'
import { UserSettingsDialogComponent } from './modules/shared/components/user-settings-dialog/user-settings-dialog.component'
import { SettingsService } from './modules/shared/services/settings.service'
import posthog from 'posthog-js'
import { EzpDocumentsService } from './modules/shared/services/ezp-documents.service'

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'ezd-app',
  animations: ANIMATIONS,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('printNow', { read: ElementRef }) printNow: ElementRef<HTMLDivElement>
  @ViewChild('ezpPrinting', { read: ElementRef }) ezpPrinting: ElementRef<any>
  @ViewChild('printNowArea', { read: ElementRef }) printNowArea: ElementRef<any>
  @ViewChild('signOut', { read: ElementRef }) signOut: ElementRef<HTMLAnchorElement>
  @ViewChild('languageSwitch') languageSwitch: ElementRef<HTMLDivElement>
  public isProd: boolean = isProd
  public env: string = env
  public azureAccountHostUrl: string = azureAccountHostUrl
  public printApiHostUrl: string = printApiHostUrl
  public envClientId: string = EZEEP_CLIENT_ID
  public redirectUri: string = redirectUri
  isOpen = false
  public version
  public loggedIn = false // used to hide view if user not logged in
  public isEzeepUser = true
  @ViewChild('ezpDocuments', { read: ElementRef }) ezpDocuments: ElementRef<HTMLDivElement>
  @ViewChild('ezpPrintingDocuments', { read: ElementRef }) ezpPrintingDocuments: ElementRef<any>
  public isAdmin = false
  public isCommonUser = false
  public isVerified = true
  subscriptionStatus: any
  mobileQuery: MediaQueryList
  private _mobileQueryListener: () => void

  firstName: string
  lastName: string
  email: string
  password: string
  companyName: string

  currentOrgName: string
  organizationId: string
  userDisplayName: string = 'Account'
  userEmail: string
  accountOrganizations = []
  showNavigation: boolean = true
  email_confirmed: any
  lang: any
  dialogIsOpen: boolean = false
  showMyPrinters: boolean
  @ViewChild('appDrawer') appDrawer: any
  surveyUrl: string
  tooltip: any
  clickEventsubscription: Subscription
  languages = [
    { slug: 'en', name: 'English' },
    { slug: 'de', name: 'Deutsch' },
  ]
  ezeepDocsSubscription: Subscription
  currentLanguage = 'en'
  preferredLanguage: string
  userId: string
  updatedOrgName: string

  visitedDocuments: boolean = false
  documentFileName: String = ''
  documentFileUrl: String = ''
  documentFileType: String = ''

  showDocuments: boolean = true
  isOpenPrintArea: boolean= false;

  constructor(
    private location: Location,
    private router: Router,
    private translateService: TranslateService,
    public activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    public appConfig: AppConfig,
    private langaugeService: LangaugeService,
    private errorService: ErrorService,
    private subscriptionApiService: SubscriptionApiService,
    private appComponentService: AppComponentService,
    private authApiService: AuthUserApiService,
    private profileService: ProfileService,
    private dialog: MatDialog,
    private userPrintService: UserPrintingService,
    private groupService: GroupsService,
    private renderer: Renderer2,
    private sharedService: PrintNowSharedService,
    private settingsService: SettingsService,
    private ezpDocumentsService: EzpDocumentsService,
    private cdr: ChangeDetectorRef,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher
  ) {
    this.translateService.use(this.translateService.getBrowserLang())
    this.authApiService.me().subscribe(async (response) => {
      this.authApiService.read(response.result.user_id).subscribe((user_result) => {
        this.preferredLanguage = user_result.result.preferred_language
        this.langaugeService.setLanguage(this.preferredLanguage)
        this.translateService.use(this.preferredLanguage)
      })
    })

    this.renderer.setAttribute(
      document.querySelector('html'),
      'lang',
      this.translateService.getBrowserLang()
    )
    this.isEzeepUser =
      window.location.hostname.indexOf('.azdev.', 0) !== -1 ||
      window.location.hostname.indexOf('app.ezeep.', 0) !== -1
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationError) {
        this.errorService.openTranslate(event, 'ERRORS.NAVIGATION')
      }
    })

    router.events.subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        this.showNavigation = event.url !== '/loginsuccessful' && event.url !== '/welcome'
        this.loginDocuments(event.url)
        activatedRoute.queryParams.subscribe((params) => {
          this.email_confirmed = params['email_confirmed']
          this.authApiService.me().subscribe(async (response) => {
            this.authApiService.read(response.result.user_id).subscribe((user_result) => {
              this.isVerified = user_result.result.is_verified
              this.openEmailVerificationDialog()
            })
          })
        })
      }
    })

    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event) => {
        posthog.capture('$pageview', {
          path: event.urlAfterRedirects,
        })
      })

    this.version = appConfig.version
    this.isProd = isProd
    this.env = env

    // The session storage is not immediately available, so we loop
    // until we get a positive result. If the user has previously
    // logged out, we will navigate away from here to the login screen.
    const timer = setInterval(() => {
      if (authenticationService.loggedIn()) {
        this.loggedIn = true
        clearInterval(timer)
      }
    }, 100)

    if (!this.loggedIn && this.isEzeepUser) {
      this.authenticationService.isEzeepUser().subscribe((response) => {
        this.isEzeepUser = response

        if (StorageUtils.get(sessionStorage, ADMIN_USER)) {
          this.isAdmin = true
          //thermostat io
          this.loadThermostat()
        }

        if (StorageUtils.get(sessionStorage, COMMON_USER)) {
          this.isAdmin = false
          //thermostat io
          this.loadThermostat()
        }

        if (StorageUtils.get(sessionStorage, ADMIN_USER) && window.location.pathname == '/') {
          this.router.navigate(['quickstart'])
        }

        if (StorageUtils.get(sessionStorage, COMMON_USER) && window.location.pathname == '/') {
          this.router.navigate(['printers'])
        }

        if (response) {
          this.subscriptionApiService.read('-created').subscribe((response) => {
            //WARNING! last created subscription is the one to be verified
            this.subscriptionStatus = response.result.result[0]
            if (!this.subscriptionStatus) return
            if (this.subscriptionStatus.expires)
              this.subscriptionStatus.daysLeft =
                new Date(this.subscriptionStatus.expires).getTime() - new Date().getTime()
            this.subscriptionStatus.daysLeft = Math.ceil(
              this.subscriptionStatus.daysLeft / (1000 * 3600 * 24)
            )
            if (this.subscriptionStatus.deletion)
              this.subscriptionStatus.daysDeletion =
                new Date(this.subscriptionStatus.deletion).getTime() - new Date().getTime()
            this.subscriptionStatus.daysDeletion = Math.ceil(
              this.subscriptionStatus.daysDeletion / (1000 * 3600 * 24)
            )
          })

          this.authApiService.me().subscribe(async (response) => {
            this.authApiService.read(response.result.user_id).subscribe((user_result) => {
              this.userDisplayName = user_result.result.display_name
              this.userEmail = user_result.result.email
              this.userId = user_result.result.id
              this.preferredLanguage = user_result.result.preferred_language
              // this.langaugeService.setLanguage(this.preferredLanguage);
              // this.translateService.use(this.preferredLanguage);
            })

            this.profileService.read().subscribe({
              next: async (profileResponse) => {
                let currentUser = await lastValueFrom(
                  this.userPrintService.read(response.result.user_id)
                ) // // .toPromise()
                let allowedGroups = await lastValueFrom(this.getAllMyPrintersGroups()) // // .toPromise()

                if (
                  this.isAdmin ||
                  ((profileResponse.result.myprinters_enabled === undefined ||
                    profileResponse.result.myprinters_enabled) &&
                    (allowedGroups.length == 0 ||
                      allowedGroups.filter(
                        (x) =>
                          currentUser.result.groups.filter((y) => x.id === y.group_id).length > 0
                      ).length > 0))
                )
                  this.showMyPrinters = true
                else this.showMyPrinters = false
              },
              error: (error) => {
                const errorMessage = JSON.parse(error.response)
                if (error.status == 403) {
                  this.errorService.open(errorMessage.detail)
                }
              },
            })

            let orgs = await lastValueFrom(this.appComponentService.getOrganizations(100, 0)) // // .toPromise()
            this.accountOrganizations = []
            this.organizationId = response.result.organization_id
            if (!orgs || orgs.length == 0) return
            orgs.forEach((org) => {
              this.accountOrganizations.push({
                id: org.id,
                name: org.name,
                url: EZEEP_AZURE_ACCOUNT_URL + '/auth/signin/organization/' + org.id,
                selected: org.id == response.result.organization_id,
              })
              if (org.id == response.result.organization_id) this.currentOrgName = org.name
            })
          })
        }
      })
    }

    iconRegistry.addSvgIcon(
      'twitter',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/twitter.svg')
    )
    iconRegistry.addSvgIcon(
      'facebook',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/facebook.svg')
    )
    iconRegistry.addSvgIcon(
      'network_printer',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/network_printer.svg')
    )
    iconRegistry.addSvgIcon(
      'document',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/document.svg')
    )

    this.mobileQuery = media.matchMedia('(max-width: 768px)')
    this._mobileQueryListener = () => changeDetectorRef.detectChanges()
    this.mobileQuery.addListener(this._mobileQueryListener)
  }

  ngOnInit() {
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {
      this.togglePrintNowArea()
    })

    this.ezeepDocsSubscription = this.ezpDocumentsService.printDocument$.subscribe(
      (printDocument) => {
        this.printDocument(printDocument)
      }
    )

    // Check for document_management feature flag
    posthog.onFeatureFlags(() => {
      this.showDocuments = posthog.isFeatureEnabled('document_management')
    })
  }

  ngAfterViewInit() {
    this.settingsService.getUpdatedOrgName$.subscribe((value) => {
      Promise.resolve().then(() => {
        this.currentOrgName = value
        this.cdr.detectChanges()
      })
    })
  }

  ngOnDestroy() {
    this.clickEventsubscription.unsubscribe()
    this.ezeepDocsSubscription.unsubscribe()
  }
  // -------------------------------------------
  // Public methods
  // -------------------------------------------

  openEmailVerificationDialog() {
    //block popup to show once a day
    let expirationEmailVerifPopUp = StorageUtils.get(localStorage, 'emailVerificationPopUpDate')
    if (
      expirationEmailVerifPopUp != null &&
      new Date(expirationEmailVerifPopUp).getTime() > new Date().getTime()
    )
      return
    if (this.dialogIsOpen) return
    this.dialogIsOpen = true
    let minDate = new Date()
    minDate.setDate(minDate.getDate() + 1)
    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = false
    dialogConfig.width = '528px'
    dialogConfig.panelClass = 'ezp-dialog'

    if (this.email_confirmed !== undefined && this.email_confirmed === 'false') {
      const dialogRef = this.dialog.open(VerifyEmailFailedDialogComponent, dialogConfig)
      dialogRef.afterClosed().subscribe(() => {
        StorageUtils.set(localStorage, 'emailVerificationPopUpDate', minDate)
        this.dialogIsOpen = false
      })
    } else if (this.email_confirmed !== undefined && this.email_confirmed === 'true') {
      //check if it is mobile (true: open ezeep app)
      if (this.isMobile()) {
        this.dialogIsOpen = false
        this.deeplinkMobile() //redirect user to android/iphone app
      } else {
        const dialogRef = this.dialog.open(VerifyEmailConfirmedDialogComponent, dialogConfig)
        dialogRef.afterClosed().subscribe(() => {
          StorageUtils.set(localStorage, 'emailVerificationPopUpDate', minDate)
          this.dialogIsOpen = false
        })
      }
    } else if (this.isVerified !== undefined && this.isVerified === false) {
      const dialogRef = this.dialog.open(VerifyEmailReminderDialogComponent, dialogConfig)
      dialogRef.afterClosed().subscribe(() => {
        StorageUtils.set(localStorage, 'emailVerificationPopUpDate', minDate)
        this.dialogIsOpen = false
      })
    } else {
      // this.dialog.open(VerifyEmailSentDialogComponent, dialogConfig)
      this.dialogIsOpen = false
    }
  }

  // Used to prevent navigation happenng when already selected
  public get isPrinters(): boolean {
    return this.location.path().indexOf('printers', 0) !== -1
  }

  // Used to prevent navigation happenng when already selected
  public get isDrivers(): boolean {
    return this.location.path().indexOf('drivers', 0) !== -1
  }

  public prepRouteState(outlet: any): any {
    return outlet.activatedRouteData.animation
  }

  public logOff() {
    this.ezpPrinting.nativeElement.logOut()
    setTimeout(() => {
      this.authenticationService.flushUser()
      localStorage.removeItem('properties')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('access_token')
      localStorage.removeItem('printer')
      localStorage.removeItem('isAuthorized')
      if (this.isEzeepUser) {
        window.location.href = EZEEP_AZURE_API_URL_LOGOFF + '?next=https://ezeep.com'
      }
    }, 1000)
  }
  // -------------------------------------------
  // Private methods
  // -------------------------------------------
  private getAllMyPrintersGroups(
    page_size: number = 100,
    page_index: number = 0
  ): Observable<Promise<any>> {
    let offset = page_index * page_size
    return this.groupService
      .list(undefined, undefined, undefined, undefined, undefined, undefined, page_size, offset)
      .pipe(
        map(async (response) => {
          let groups = []
          if (response.result.next) {
            let nextIndex = page_index + 1
            groups = await lastValueFrom(this.getAllMyPrintersGroups(page_size, nextIndex)) // // .toPromise()
          }
          return [...response.result.results.filter((x) => x.myprinters_enabled), ...groups]
        })
      )
  }

  loadScript(): Promise<any> {
    return new Promise((resolve, reject) => {
      const script = this.renderer.createElement('script')
      script.type = 'text/javascript'
      script.src = this.surveyUrl
      script.text = ``
      script.async = true
      script.defer = true
      script.onload = resolve
      script.onerror = reject
      this.renderer.appendChild(document.body, script)
    })
  }

  switchLanguage(lang: string) {
    this.translateService.use(lang)
  }

  openRenameOrganizationDialog() {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = false
    dialogConfig.width = '600px'
    dialogConfig.panelClass = 'ezp-dialog'
    dialogConfig.data = { id: this.organizationId, name: this.currentOrgName }

    this.dialog.open(RenameOrganizationDialogComponent, dialogConfig)
  }

  openCreateOrganization() {
    window.location.href = EZEEP_AZURE_ACCOUNT_URL + '/auth/signup/?confirmed'
  }

  openUserSettingsDialog() {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = false
    dialogConfig.width = '512px'
    dialogConfig.panelClass = 'ezp-dialog'
    dialogConfig.data = {
      id: this.organizationId,
      name: this.currentOrgName,
    }

    this.dialog.open(UserSettingsDialogComponent, dialogConfig)
  }

  loadThermostat(): void {
    this.authApiService.me().subscribe((response) => {
      this.authApiService.read(response.result.user_id).subscribe((user_result) => {
        this.userDisplayName = user_result.result.display_name
        this.userEmail = user_result.result.email

        const thermostatObject = {
          email: this.userEmail,
          name: this.userDisplayName,
          fields: {
            usertype: this.isAdmin ? 'admin' : 'user',
          },
        }
        if (env === 'dev') {
          thermostatObject.fields['env'] = 'dev'
        } else if (env === 'tst') {
          thermostatObject.fields['env'] = 'tst'
        } else if (env === 'stg') {
          thermostatObject.fields['env'] = 'stg'
        } else {
          thermostatObject.fields['env'] = 'prod'
        }
        const language = this.translateService.getBrowserLang()
        this.surveyUrl = 'https://thermostat.io/s/d6c65d92/survey.js'
        if (language.includes('de')) {
          this.surveyUrl = 'https://thermostat.io/s/49d7d375/survey.js'
        }
        this.loadScript().then(() => {
          if (window['thermostatio']) {
            window['thermostatio'].start(thermostatObject)
          }
        })
      })
    })
  }

  // ... existing code ...

togglePrintNowArea() {
  this.isOpenPrintArea = !this.isOpenPrintArea;
  const printNowElement = this.printNow.nativeElement;
  
  if (this.isOpenPrintArea) {
    printNowElement.classList.add('ezp-print-now--area-open');
  } else {
    printNowElement.classList.remove('ezp-print-now--area-open');
  }
}

// ... existing code ...

  organizationChange(orgId) {
    localStorage.removeItem('properties')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('access_token')
    localStorage.removeItem('printer')
    localStorage.removeItem('isAuthorized')
    window.location.href = EZEEP_AZURE_ACCOUNT_URL + '/auth/signin/organization/' + orgId
  }

  isMobile(): boolean {
    let userAgent = window.navigator.userAgent
    if (!userAgent) return false

    let userAgentTest = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    let screenSizeTest = window.matchMedia('only screen and (max-width: 760px)').matches
    let onTouchTest =
      'ontouchstart' in document.documentElement && navigator.userAgent.match(/Mobi/)

    if (userAgentTest && screenSizeTest && onTouchTest) return true

    return false
  }

  deeplinkMobile() {
    let isAndroid = navigator.userAgent.toLocaleUpperCase().indexOf('ANDROID') > -1
    let isIphone = navigator.userAgent.toLocaleUpperCase().indexOf('IPHONE') > -1

    if (isAndroid) {
      window.location.replace(ANDROID_BUNDLE_NAME)
      setTimeout(() => {
        window.location.href = ANDROID_STORE_LINK
      }, 500)
    }
    if (isIphone) {
      setTimeout(() => {
        window.location.href = IPHONE_STORE_LINK
      }, 25)
      window.location.href = IPHONE_BUNDLE_NAME
    }
  }

  handleCurrentLanguage(language: string): void {
    this.preferredLanguage = language
    this.authApiService.patchPreferredLanguage(this.userId, language).subscribe((result) => {
      let preferredlanguage = result.result.preferred_language
      document.location.reload()
      this.langaugeService.setLanguage(preferredlanguage)
      this.translateService.use(preferredlanguage)
    })
    this.location.go(this.router.url.split('?')[0])
    this.languageSwitch.nativeElement.classList.remove('ezp-drawer__language-switch--list-open')
  }

  handleLanguageSwitch(): void {
    this.languageSwitch.nativeElement.classList.toggle('ezp-drawer__language-switch--list-open')
  }

  loginDocuments(url: string) {
    if (!this.visitedDocuments && url === '/documents') {
      this.visitedDocuments = true
    }
  }

  displayDocuments(url: string) {
    return url.includes('/documents') ? 'ezp-documents__open' : 'ezp-documents__close'
  }

  printDocument(printDocument: string[]) {
    if (!printDocument?.length) {
      return
    }

    const documentName = printDocument[0]
    if (!documentName) {
      return
    }

    const parts = documentName.split('.')
    this.documentFileName = parts[0] || ''
    // Extract file type from the URL before ?alt=media
    const url = printDocument[1] || ''
    const fileTypeMatch = url.match(/\.([^.?]+)\?alt=media/)
    this.documentFileType = fileTypeMatch ? fileTypeMatch[1] : ''
    this.documentFileUrl = url.trim()

    setTimeout(() => {
      if (this.ezpPrintingDocuments?.nativeElement) {
        this.ezpPrintingDocuments.nativeElement.open()
      }
    }, 300)
  }

  emitUserActionLoginDocuments() {
    this.ezpDocumentsService.firebaseLogin();
  }
}
