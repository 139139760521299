import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConnectorsFiltersService } from '../../../modules/shared/services/connectors-filters.service';
import {ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'connectors-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class ConnectorsFiltersComponent implements OnInit {
  filterName: string;
  selectedFilters = [];
  connectorsFilters = [];
  getFiltersSubscription: Subscription;
  getSelectecdFiltersSubscription: Subscription;

  constructor(private connectorsFiltersService: ConnectorsFiltersService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.connectorsFiltersService.currentFilterName.subscribe(filterName => {
      this.filterName = filterName;
    });
    this.getFiltersSubscription = this.connectorsFiltersService.getFilters()
      .subscribe(result => {
        this.connectorsFilters = result;
      });
      this.getSelectecdFiltersSubscription = this.connectorsFiltersService.getSelectedFilters()
      .subscribe(result => {
        this.selectedFilters = result;
      });
  }

  ngOnDestroy(): void {
    this.getFiltersSubscription.unsubscribe();
    this.getSelectecdFiltersSubscription.unsubscribe();
  }

  selectFilter(isAdd, filter, name) {
    if (isAdd) {
      this.connectorsFiltersService.addFilter(filter.field_name, name, undefined);
    } else {
      this.connectorsFiltersService.removeFilter(filter.field_name, name);
    }
  }

  getSelectedFilterCountForFilterCategory(fieldName: string) {
    return this.selectedFilters.filter(filter => filter.field_name === fieldName).length;
  }

  isFilterSelected(filter: any, filterName: string) {
    return this.selectedFilters.find(selectedFilter => selectedFilter.field_name === filter.field_name && selectedFilter.name === filterName);
  }

  clearFilters() {
    this.connectorsFiltersService.removeAllFilters();
  }
}