import {
  mergeMap as _observableMergeMap,
  catchError as _observableCatch,
  delay,
  startWith,
  switchMap,
  map,
} from 'rxjs/operators'
import { Observable, throwError as _observableThrow, of as _observableOf, of } from 'rxjs'
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core'
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http'
import {
  RequesterApiService,
  ApiResponse,
  printerApiUrl,
  ApiResult,
  ListResult,
} from './requester-api.service'
import { Printer } from './printing-api.service'

export interface IPrinterProfileApiService {
  /**
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    printer: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<PrinterProfile>>>
  create(data: PrinterProfile): Observable<ApiResponse<PrinterProfile>>
  read(id: string): Observable<ApiResponse<PrinterProfile>>
  update(data: PrinterProfile, id: string): Observable<ApiResponse<PrinterProfile>>
  delete(id: string): Observable<ApiResponse<void>>
}

@Injectable({
  providedIn: 'root',
})
export class PrinterProfileApiService
  extends RequesterApiService
  implements IPrinterProfileApiService
{
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  /**
   * @param q (optional) A search term.
   * @param o (optional) Which field to use when ordering the results.
   * @param limit (optional) Number of results to return per page.
   * @param offset (optional) The initial index from which to return the results.
   */
  list(
    printer: string | null | undefined,
    q: string | null | undefined,
    o: string | null | undefined,
    limit: number | null | undefined,
    offset: number | null | undefined
  ): Observable<ApiResponse<ListResult<PrinterProfile>>> {
    let url_ = this.baseUrl + '/v1/printer_profiles/?'
    if (printer !== undefined) url_ += 'printer=' + encodeURIComponent('' + printer) + '&'
    if (q !== undefined) url_ += 'q=' + encodeURIComponent('' + q) + '&'
    if (o !== undefined) url_ += 'o=' + encodeURIComponent('' + o) + '&'
    if (limit !== undefined) url_ += 'limit=' + encodeURIComponent('' + limit) + '&'
    if (offset !== undefined) url_ += 'offset=' + encodeURIComponent('' + offset) + '&'
    url_ = url_.replace(/[?&]$/, '')

    let options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processListRequest('get', url_, options_, PrinterProfile).pipe(
      map((r) => {
        r.result.searchText = q
        return r
      })
    )
  }

  create(data: PrinterProfile): Observable<ApiResponse<PrinterProfile>> {
    let url_ = this.baseUrl + '/v1/printer_profiles/'
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    let options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('post', url_, options_, PrinterProfile)
  }

  read(id: string): Observable<ApiResponse<PrinterProfile>> {
    let url_ = this.baseUrl + '/v1/printer_profiles/{id}/'
    if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    let options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('get', url_, options_, PrinterProfile)
  }

  update(data: PrinterProfile, id: string): Observable<ApiResponse<PrinterProfile>> {
    let url_ = this.baseUrl + '/v1/printer_profiles/{id}/'
    if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(data)

    let options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('put', url_, options_, PrinterProfile)
  }

  delete(id: string): Observable<ApiResponse<void>> {
    let url_ = this.baseUrl + '/v1/printer_profiles/{id}/'
    if (id === undefined || id === null) throw new Error("The parameter 'id' must be defined.")
    url_ = url_.replace('{id}', encodeURIComponent('' + id))
    url_ = url_.replace(/[?&]$/, '')

    let options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest<null>('delete', url_, options_, null)
  }
}
export class PrinterProfile extends ApiResult implements IPrinterProfile {
  id?: string | undefined
  name?: string | undefined
  description?: string | undefined
  duplex?: string | DuplexOption | undefined
  color?: string | ColorOption | undefined
  resolution?: number | undefined
  paper_size?: string | PaperOption | undefined
  paper_id?: string | undefined
  paper_name?: string | undefined
  orientation?: string | undefined
  tray?: string | TrayOption | undefined
  printers: Printer[] = []
  selected_printers?: string[] = []

  constructor(data?: IPrinterProfile) {
    super()
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).id = data['id']
      ;(<any>this).name = data['name']
      ;(<any>this).description = data['description']
      ;(<any>this).duplex = data['duplex']
      ;(<any>this).color = data['color']
      ;(<any>this).resolution = data['resolution']
      ;(<any>this).paper_size = data['paper_size']
      ;(<any>this).paper_id = data['paper_id']
      ;(<any>this).paper_name = data['paper_name']
      ;(<any>this).orientation = data['orientation']
      ;(<any>this).tray = data['tray']
      ;(<any>this).printers = data['printers']
      ;(<any>this).selected_printers = data['selected_printers']
    }
  }

  fromJS(data: any): PrinterProfile {
    data = typeof data === 'object' ? data : {}
    let result = new PrinterProfile()
    result.init(data)
    return result
  }
}

export interface IPrinterProfile {
  id?: string | undefined
  name?: string | undefined
  description?: string | undefined
  duplex?: string | DuplexOption | undefined
  color?: string | ColorOption | undefined
  resolution?: number | undefined
  paper_size?: string | PaperOption | undefined
  paper_id?: string | undefined
  paper_name?: string | undefined
  orientation?: string | undefined
  tray?: string | TrayOption | undefined
  printers?: Printer[]
  selected_printers?: string[]
}

export interface IPrinterCapabilityApiService {
  get(ids: string): Observable<ApiResponse<ApiResult>>
}

@Injectable({
  providedIn: 'root',
})
export class PrinterCapabilityApiService
  extends RequesterApiService
  implements IPrinterCapabilityApiService
{
  constructor(
    @Inject(HttpClient) http: HttpClient,
    @Optional() @Inject(printerApiUrl) baseUrl?: string
  ) {
    super(http, baseUrl)
  }

  get(ids: string): Observable<ApiResponse<PrinterCapability>> {
    let url_ = this.baseUrl + '/v1/printer_profiles/capabilities/?printers={ids}'
    url_ = url_.replace(/[?&]$/, '')
    if (ids === undefined || ids === null) throw new Error("The parameter 'ids' must be defined.")
    url_ = url_.replace('{ids}', encodeURIComponent('' + ids))

    let options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      }),
    }

    return this.processRequest('get', url_, options_, PrinterCapability)
  }
}

export class PrinterCapability extends ApiResult implements IPrinterCapability {
  colors: Array<ColorOption>
  duplex: Array<DuplexOption>
  papers: Array<PaperOption>
  trays: Array<TrayOption>
  resolutions: Array<ResolutionOption>

  constructor(data?: IPrinterCapability) {
    super()
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(data?: any) {
    if (data) {
      ;(<any>this).colors = data['colors']
      ;(<any>this).duplex = data['duplex']
      ;(<any>this).papers = data['papers']
      ;(<any>this).trays = data['trays']
      ;(<any>this).resolutions = data['resolutions']
    }
  }

  fromJS(data: any): PrinterCapability {
    data = typeof data === 'object' ? data : {}
    let result = new PrinterCapability()
    result.init(data)
    return result
  }
}

interface IPrinterCapability {
  colors: Array<ColorOption>
  duplex: Array<DuplexOption>
  papers: Array<PaperOption>
  trays: Array<TrayOption>
  resolutions: Array<ResolutionOption>
}

interface ColorOption {
  id?: string
  name: string
  common?: boolean
}

interface ResolutionOption {
  id?: string
  name: any
  common?: boolean
}

interface DuplexOption {
  id?: string
  name: string
  common: boolean
}

interface PaperOption {
  id: string
  label?: string
  name: string
  common: boolean
}

interface TrayOption {
  id: any
  name: string
  common: boolean
}
