import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'localeDate' })
export class LocaleDatePipe implements PipeTransform {
    transform(date: Date | string, preferredLang: string): string {
        let formatDate = new Date(date);
        // const  langCode = document.querySelector('html').getAttribute('lang');
        return formatDate.toLocaleDateString(preferredLang);
    }
}

@Pipe({ name: 'intlDate' })
export class IntlDatePipe implements PipeTransform {
    transform(date: Date | string, preferredLang: string): any {
        let formatDate = new Date(date);
        return new Intl.DateTimeFormat(preferredLang, { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }).format(formatDate);
    }
}