
import {of as observableOf,  Observable } from 'rxjs';

import { map, catchError } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { JwtHelperService } from '@auth0/angular-jwt'
import { StorageUtils } from '../../storage/utils/storage.utils'
import {
  JWT_TOKEN,
  JWT_SCOPE,
  JWT_REFRESH_TOKEN,
  USER_ID,
  ADMIN_USER,
  COMMON_USER,
} from '../../storage/constants/storage.constants'
import { EZEEP_AZURE_API_URL } from '../../../app.config'

@Injectable()
export class AuthenticationService {
  constructor(private httpClient: HttpClient) {}

  // -----------------------------------
  // Public methods
  // -----------------------------------

  public loggedIn(): boolean {
    return (
      StorageUtils.get(sessionStorage, ADMIN_USER) || StorageUtils.get(sessionStorage, COMMON_USER)
    )
  }

  public isEzeepUser(): Observable<boolean> {
    return this.httpClient.get<any>(`${EZEEP_AZURE_API_URL}`, { withCredentials: true }).pipe(
      map((response) => {
        StorageUtils.set(sessionStorage, ADMIN_USER, false)
        StorageUtils.set(sessionStorage, COMMON_USER, false)
        if (!response || response.roles == undefined || !Array.isArray(response.roles)) {
          return false
        }

        if (response.roles.includes('admin')) StorageUtils.set(sessionStorage, ADMIN_USER, true)
        else StorageUtils.set(sessionStorage, COMMON_USER, true)

        return true
      }),
      catchError((error) => {
        StorageUtils.set(sessionStorage, ADMIN_USER, false)
        return observableOf(false)
      })
    )
  }

  public decodeToken(response: any): any {
    return new JwtHelperService().decodeToken(response.access_token).sub
  }

  public flushUser() {
    StorageUtils.remove(sessionStorage, JWT_TOKEN)
    StorageUtils.remove(sessionStorage, JWT_REFRESH_TOKEN)
    StorageUtils.remove(sessionStorage, JWT_SCOPE)
    StorageUtils.remove(sessionStorage, USER_ID)
    StorageUtils.remove(sessionStorage, ADMIN_USER)
    StorageUtils.remove(sessionStorage, COMMON_USER)
  }
}
