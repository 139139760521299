import { Component, OnInit } from '@angular/core';
import { TokenService } from '../../../modules/shared/services/token.service';
import { IToken } from '../../../modules/shared/services/token-api.service';
import { DATE_LOCALIZATION } from '../../reporting/reporting.constans';
import { LangaugeService } from '../../../modules/shared/services/langauge.service';
import { Router } from '@angular/router';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '../../../app.config';

@Component({
  selector: 'ezd-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss',]
})
export class SessionComponent implements OnInit {

  hasSessions: boolean = false
  sessions: Array<IToken> = []
  defaultPreferredLanguage: string;
  maxPageLength = 100;
  defaultPageSize = DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  pageIndex: number;
  pageSize: number = DEFAULT_PAGE_SIZE;

  constructor(private tokenService: TokenService, private languageService: LangaugeService, private router: Router) { }

  ngOnInit(): void {


    this.tokenService.setPage(0, this.defaultPageSize);
    this.tokenService.getTokens().subscribe(tResponse => {
      this.hasSessions = !tResponse.isLoading
      if (tResponse.isLoading) return;
      this.sessions = tResponse.tokens;
      this.hasSessions = !(!this.sessions.length)

      this.maxPageLength = tResponse.count;
    })
    this.tokenService.applyFilters()

    this.languageService.getPreferredLang$.subscribe((value) => {
      this.defaultPreferredLanguage = value;
    })
  }

  public onPaginationChange(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.tokenService.setPage(this.pageIndex, this.pageSize);
  }

  removeToken(id: string) {
    this.tokenService.revokeToken(id).subscribe({
      next: response => {
        this.tokenService.applyFilters()
      }, error: error => {
        this.tokenService.applyFilters()
      }
    })
  }

  formatDate(value: Date) {
    return new Date(value).toLocaleDateString(DATE_LOCALIZATION[this.defaultPreferredLanguage])
  }

  terminateSession() {
    this.tokenService.searchTokensToDelete().subscribe({
      next: async response => {
        this.tokenService.applyFilters()
      }, error: error => {
        this.tokenService.applyFilters()
      }
    })
  }

  openAppsAndDownload() {
    this.router.navigate(['downloads'])
  }

}
