import { CommonModule } from '@angular/common'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CoreModule } from '../../modules/core/core.module'
import { MaterialModule } from '../../modules/material/material.module'
import { SharedModule } from '../../modules/shared/shared.module'
import { AutomationsComponent } from './automations.component'

@NgModule({
  declarations: [AutomationsComponent],
  imports: [CommonModule, MaterialModule, CoreModule, SharedModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AutomationsModule {}
