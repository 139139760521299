<mat-icon class="icon">cancel</mat-icon>
<div class="text">
  <div
    class="mat-subheading-2 title"
    *ngIf="
      subscriptionStatus && subscriptionStatus.status && subscriptionStatus.status == 'canceled'
    "
    [innerHTML]="'SUBSCRIPTION.SUBSCRIPTION_CANCELLED.STATUS' | translate"
  >  
  </div>
  <div
    class="mat-subheading-2 title"
    *ngIf="
      subscriptionStatus && subscriptionStatus.status && subscriptionStatus.status == 'suspended'
    "
    [innerHTML]="'SUBSCRIPTION.SUBSCRIPTION_SUSPENDED.STATUS' | translate"
  >
  </div>
  <div
    class="mat-body ezp-has-text-link subtitle"
    [innerHTML]="'SUBSCRIPTION.SUBSCRIPTION_CANCELLED.DESCRIPTION' | translate"
  ></div>
</div>
<button class="ezp-button" mat-flat-button color="primary" (click)="openChargebeePortal()">
  {{ 'SUBSCRIPTION.ACTIONS.SUBSCRIBE_NOW' | translate }}
</button>
