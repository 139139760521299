import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable, Subscription, lastValueFrom, map } from 'rxjs';
import { Router } from '@angular/router';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '../../../../app.config';
import { Webhook, WebhooksApiService } from '../../../../modules/shared/services/webhooks-api.service';
import { WebhooksService } from '../../../../modules/shared/services/webhooks.service';
import { LangaugeService } from '../../../../modules/shared/services/langauge.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { WebhookEditorDialogueComponent } from '../webhook-editor-dialogue/webhook-editor-dialogue.component';
import { SubscriptionService } from '../../../../modules/shared/services/subscription.service';
import { GeneralUtil } from '../../../../modules/shared/utils/general-util';
import { DATE_LOCALIZATION } from '../constants';

@Component({
  selector: 'ezd-webhook',
  templateUrl: './webhook.component.html',
  styleUrls: ['./webhook.component.scss']
})
export class WebhookComponent implements OnInit {

  webhooksData = new MatTableDataSource([])
  userNameWebhooks: boolean;
  emailWebhooks: boolean;
  fileNameWebhooks: boolean;
  isLoading: boolean = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  maxPageLength = 25;
  defaultPageSize = DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  pageIndex: number;
  pageSize: number = DEFAULT_PAGE_SIZE;
  webhooksServiceSubscription: Subscription;
  sorting: string;
  webhooksColumns = ['name', 'last-run' ,'actions']
  defaultPreferredLanguage: string;
  webhooks: boolean;

  constructor(private webhooksService: WebhooksService,
    private webhooksApiService: WebhooksApiService,
    private dialogue: MatDialog,
    private subscriptionService: SubscriptionService,
    private languageService: LangaugeService,
    private router: Router) {
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.defaultPreferredLanguage = value;
    })
  }

  ngOnInit(): void {

    this.webhooksData.sort = this.sort;
    this.webhooksService.setPage(0, this.defaultPageSize, true);
    this.webhooksServiceSubscription = this.webhooksService.getFilteredWebhooks()
      .subscribe(result => {
        if (!result.webhooks) return;
        this.isLoading = result.isLoading;
        this.webhooksData.data = result.webhooks;
        this.maxPageLength = result.count;

      });

    this.webhooksService.applyFilters();

    this.subscriptionService.getSubscription().subscribe((response) => {
      this.webhooks = response.features && response.features.webhooks ? true : false
    })
  }

  ngOnDestroy(): void {
    if (this.webhooksServiceSubscription) this.webhooksServiceSubscription.unsubscribe();
  }

  public onPaginationChange(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.webhooksService.setPage(this.pageIndex, this.pageSize);
  }

  sortChange(e) {
    const direction = e.direction === 'desc' ? '-name' : e.direction === 'asc' ? 'name' : undefined;
    if (direction) {
      this.sorting = direction
    }
    this.webhooksService.setSorting(this.sorting);
  }

  deleteWebhook(endpoint_id) {
    this.webhooksApiService.delete(endpoint_id).subscribe(result => {
      this.isLoading = true;
      this.webhooksService.applyFilters();
    })
  }

  openWebhookEditor(webhook?: Webhook) {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.width = '780px';
    dialogConfig.panelClass = 'ezp-dialog';
    dialogConfig.data = {
      webhook
    };
    const dialogRef = this.dialogue.open(WebhookEditorDialogueComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      // if (result && result.success)
      this.webhooksService.applyFilters();
      this.webhooksService.clearEditMode()
    });

  }

  async onChangeDisabledWebhookSetting(checked: boolean, endpointId: string) {
    const webhookDisabled = await lastValueFrom(this.webhooksService.toggleWebhookDisabledSetting(endpointId))
    this.updateWebhook(endpointId, webhookDisabled);
  }

  updateWebhook(endpointId: string, webhookResult: boolean) {
    const objectIndex = this.webhooksData.data.findIndex((obj) => obj.endpoint_id === endpointId);
    if (objectIndex !== -1) {
      this.webhooksData.data[objectIndex]['disabled'] = webhookResult;
    }
  }

  formatDate(value: Date) {
    return GeneralUtil.formatDateHoursAndMinutes(value, DATE_LOCALIZATION[this.defaultPreferredLanguage])
  }
  
  goToSubscription() {
    this.router.navigate(['subscription'])
  }
}
