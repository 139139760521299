import { environment as config } from '../environments/environment'

export const isProd = config.production
export const env = getEnvironment();
export const azureAccountHostUrl = safeGetHost(config.apiUrls.azure)
export const printApiHostUrl = safeGetHost(config.apiUrls.printApiHostUrl)
export const redirectUri = `https://${window.location.host}/`
export const changePasswordUrl = changePassword();

export const API_BASE_URL = config.apiUrls.base
export const API_ULP_URL = `${API_BASE_URL}/ulp`
export const API_ULP_PRINTER_URL = `${API_ULP_URL}/printers`
export const API_DS_URL = `${API_BASE_URL}/ds`
export const API_DS_DRIVER_URL = `${API_DS_URL}/Driver`
export const API_DS_DRIVER_BUNDLE_URL = `${API_DS_URL}/DriverBundle`
export const API_DRIVER_UPLOAD_URL = `${API_BASE_URL}/ds/DriverArchive/upload`

// Language properties
export const DEFAULT_LANGUAGE = 'en'
export const AVAILABLE_LANGUAGES: Array<string> = ['en', 'de']

// Forms
export const GLOBAL_FORM_DEBOUNCE_TIME = 250

// Authentication
export const EIS_EZEEP_API_URL = config.apiUrls.accounts
export const EIS_JWT_CLIENT_ID = ''
export const EIS_JWT_CLIENT_SECRET = ''
export const EZEEP_API_AUTHORIZE_URL = `${EIS_EZEEP_API_URL}/oauth/authorize/?client_id=${EIS_JWT_CLIENT_ID}&response_type=code&state=success&redirect_uri=http://${window.location.host}/authentication&scope=dash:read dash:write`
export const EZEEP_API_ACCESS_TOKEN_URL = `${EIS_EZEEP_API_URL}/oauth/access_token`
export const EZEEP_API_LOGOFF = `${EIS_EZEEP_API_URL}/auth/signout?redirect_uri=http://${window.location.host}/authentication&scope=dash:read dash:write`

export const EZEEP_CLIENT_ID = config.auth.ezeepClientId

export const EZEEP_AZURE_ACCOUNT_URL = config.apiUrls.azure
export const EZEEP_AZURE_API_URL = `${EZEEP_AZURE_ACCOUNT_URL}/auth/me/`
export const EZEEP_AZURE_API_URL_LOGIN = `${EZEEP_AZURE_ACCOUNT_URL}/auth/signin?next=https://${window.location.host}`
export const EZEEP_AZURE_API_URL_LOGOFF = `${EZEEP_AZURE_ACCOUNT_URL}/auth/signout`
export const EZEEP_PRINTING_API = `${config.apiUrls.api2Base}/printing`
export const EZEEP_ADCON_API = `${config.apiUrls.api2Base}/federated`
export const EZEEP_SUBSCRIPTION_API = `${config.apiUrls.api2Base}/management/subscribing`
export const EZEEP_REPORTING_API = config.apiUrls.api2Base
export const EZP_CHARGEBEE_URL = `${config.apiUrls.api2Base}/integrations/chargebee`
export const CHARGEBEE_SITE_NAME = config.apiUrls.chargebeeSiteName
export const EZEEP_DOCMAN_BASE_URL = config.apiUrls.documentManagement
export const EZEEP_DOCMAN_MAIN_DART = `${EZEEP_DOCMAN_BASE_URL}/main.dart.js?v=20250311t1644`
export const EZEEP_DOCMAN_SERVICE_WORKER = `${EZEEP_DOCMAN_BASE_URL}/flutter_service_worker.js`

// Header params
const HEADER_PARAM_PREFIX = 'x-dash'
export const HEADER_PARAM_ORGANIZATION_ID = `${HEADER_PARAM_PREFIX}-organization-id`

// Ezeep urls
export const EZEEP_PORTAL_URL = config.apiUrls.portal

// Url for the Dash Assistant console app
export const ASSISTANT_API_URL: string = 'http://127.0.0.1:4044/api'

// Tables
export const DEFAULT_PAGE_SIZE: number = 25
export const DEFAULT_PAGE_SIZE_OPTIONS: number[] = [10, 25, 50, 100]

// Ezeep app bundle names
export const ANDROID_BUNDLE_NAME = 'ezeep://'
export const ANDROID_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.thinprintcloud.android.ezeep.print'
export const IPHONE_BUNDLE_NAME = 'ezeep://'
export const IPHONE_STORE_LINK = 'https://apps.apple.com/app/ezeep/id493953434'
export const SENTRY_DNS = config.monitoring.sentryDsn
export const POSTHOG_HOST = config.monitoring.posthogHost
export const POSTHOG_API_KEY = config.monitoring.posthogKey

function safeGetHost(urlString: string): string {
  try {
    // First attempt: try direct URL construction
    return new URL(urlString).host
  } catch (e) {
    try {
      // Second attempt: try with https:// prefix
      return new URL(`https://${urlString}`).host
    } catch (e) {
      // Fallback: parse manually if URL construction fails
      return urlString.replace(/^https?:\/\//, '').split('/')[0]
    }
  }
}

function getEnvironment() {
  const host = window.location.hostname;

  if (host.indexOf('app.ezeep.', 0) !== -1) {
    return 'prod';
  }

  if (host.indexOf('.dev.', 0) !== -1) {
    return 'dev';
  }
  if (host.indexOf('.tst.', 0) !== -1) {
    return 'tst';
  }
  if (host.indexOf('.stg.', 0) !== -1) {
    return 'stg';
  }

  return 'dev';
}

function changePassword(): String {
  const env = getEnvironment();
  if (env === 'prod') {
    return `https://id.ezeep.com/Account/ForgotPassword`;
  } else if (env === 'dev') {
    return `https://id.dev.azdev.ezeep.com/Account/ForgotPassword`
  } else if (env === 'tst') {
    return `https://id.tst.azdev.ezeep.com/Account/ForgotPassword`
  } else if (env === 'stg') {
    return `https://id.stg.azdev.ezeep.com/Account/ForgotPassword`
  } else {
    return `https://id.dev.azdev.ezeep.com/Account/ForgotPassword`;
  }
}