<div
  class="ezp-grid ezp-grid--gutter-horizontal ezp-grid--spacing-vertical"
  data-grid-cols="1"
>
  <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill" id="group-printers-search">
    <mat-label id="group-printers-search-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_PRINTERS.SEARCH_PRINTERS' | translate }}</mat-label>
    <input matInput id="group-printer-name" #search [placeholder]="'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_PRINTERS.PRINTER_NAME' | translate" (input)="onSearchChange($event.target.value)"/>
  </mat-form-field>
</div>
<ezd-infinity-scroll (scrolled)="onScroll()" style="height: 295px; overflow-y: auto">

  <mat-selection-list #printers class="ezp-list ezp-list--filterable" id="group-printers-list"
    (selectionChange)="onSelection($event, printers.selectedOptions)">
    <mat-list-option *ngFor="let printer of assignedPrinters" checkboxPosition="before" color="primary"
      [selected]="printer.isSelected" [value]="printer" [id]="printer.name">
      <span mat-line>{{ printer.name }}</span>
      <div matLine>
        <div class="ezp-lisst__meta">
          <span mat-line id="group-printers-location-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_PRINTERS.LOCATION' |
            translate }}: {{ printer.location }}</span>
          <span mat-line id="group-printers-connector-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_PRINTERS.CONNECTOR'
            | translate }}: {{ printer.connector }}</span>
        </div>
      </div>
      <mat-divider [inset]="true"></mat-divider>
    </mat-list-option>
  </mat-selection-list>
 
  <div class="ezp-list__fetching" *ngIf="isLoading">
    <mat-spinner
      class="ezp-list__fetching__spinner ezp-list__fetching__spinner--selection"
    ></mat-spinner>
    <span class="ezp-list__fetching__title" id="group-printers-fetchingprinters-text">{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_PRINTERS.FETCHING_PRINTERS' | translate }}</span>
  </div>
</ezd-infinity-scroll>

