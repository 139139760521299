<div class="single-printer" *ngIf="selectedPrinters.length == 1">
  <div class="divHeight">
    <div class="ezp-grid ezp-grid--gutter ezp-grid--spacing-vertical mobileFix" data-grid-cols="2">
      <mat-form-field
        *ngIf="showPrinterDetail"
        class="ezp-form-field ezp-form-field--no-helper"
        appearance="fill"
      >
        <mat-label id="printer-details-name">{{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.NAME' | translate
        }}</mat-label>
        <input
          matInput
          [ngModel]="selectedPrinter.name"
          (input)="onNameChange($event.target.value)"
        />
      </mat-form-field>

      <mat-form-field
        *ngIf="showPrinterDetail"
        class="ezp-form-field ezp-form-field--no-helper"
        appearance="fill"
      >
        <mat-label>{{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.LOCATION' | translate
        }}</mat-label>
        <input
          matInput
          [ngModel]="selectedPrinter.location"
          (input)="onLocationChange($event.target.value)"
        />
      </mat-form-field>

      <div class="panel">
        <div class="panel__title mat-caption">
          {{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.MODEL' | translate }}
        </div>
        <div class="selectDriver" *ngIf="showPrinterDetail">
          <mat-select
            class="transparent"
            [ngModel]="selectedPrinter.driver_id"
            (selectionChange)="onDriverChange($event.value)"
            [placeholder]="
              'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.SELECT_MODEL' | translate
            "
            [disabled]="
              !(
                (selectedPrinter.driver_id != undefined || showPrinterDetail) &&
                selectedPrinter.connector_os_type != 'Windows' &&
                !selectedPrinter.is_queue
              ) || !showPrinterDetail
            "
          >
            <mat-option (click)="openPrinterEditorDriversDialog()" class="more-drivers">
              <mat-icon>add_circle</mat-icon>
              {{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.MORE_MODELS' | translate }}
            </mat-option>
            <mat-divider></mat-divider>
            <mat-option *ngFor="let driver of drivers" [value]="driver.id" [id]="driver.id">
              {{ driver.readable_name }}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div class="panel">
        <div class="panel__title mat-caption">
          {{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.ASSIGNMENTS' | translate }}
        </div>
        <div class="selectDriver" *ngIf="showPrinterDetail">
          <input
            class="transparentBg"
            matInput
            [value]="groups.length + ' Groups,' + ' ' + users.length + ' Users'"
            readonly
            disabled
          />
        </div>
      </div>

      <div class="panel">
        <div class="panel__title mat-caption">
          {{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.ID' | translate }}
        </div>
        <div class="printer-id" *ngFor="let printer of selectedPrinters">
          <div class="printer-id__content">
            <div class="printer-id__value mat-subheading-2">{{ printer.id }}</div>
            <button mat-icon-button class="printer-id__copy" (click)="copyPrinterId(printer.id)">
              <mat-icon class="icon-suffix">content_copy</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="panel" *ngIf="selectedPrinter.connectorName != undefined">
        <div class="panel__title mat-caption">
          {{ 'PRINTERS.NETWORK_PRINTERS.PRINTERS_PROPERTIES.DETAILS.CONNECTOR' | translate }}
        </div>
        <div
          class="connector mat-subheading-2"
          (click)="showConnector(selectedPrinter.connector_id)"
        >
          <mat-icon>wifi_tethering</mat-icon>
          <span class="connector__title">{{ selectedPrinter.connectorName }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Multiple Printers Selected -->
<div class="multiple-printer" *ngIf="selectedPrinters.length > 1">
  <div class="divHeight">
    <div class="ezp-grid ezp-grid--gutter ezp-grid--spacing-vertical mobileFix" data-grid-cols="2">
      <mat-form-field
        *ngIf="selectedPrinters.length > 1"
        class="ezp-form-field ezp-form-field--no-helper"
        appearance="fill"
      >
        <mat-label id="printer-details-name">{{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.NAME' | translate
        }}</mat-label>
        <input matInput value="Multiple Names" disabled />
      </mat-form-field>

      <mat-form-field
        *ngIf="selectedPrinters.length > 1"
        class="ezp-form-field ezp-form-field--no-helper"
        appearance="fill"
      >
        <mat-label>{{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.LOCATION' | translate
        }}</mat-label>
        <input
          matInput
          [ngModel]="selectedPrinter.location"
          (input)="onLocationChange($event.target.value)"
        />
      </mat-form-field>
    </div>

    <div class="ezp-grid ezp-grid--gutter ezp-grid--spacing-vertical mobileFix" data-grid-cols="2">
      <mat-form-field
        *ngIf="selectedPrinters.length > 1"
        class="ezp-form-field ezp-form-field--no-helper"
        appearance="fill"
      >
        <mat-label>{{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.MODEL' | translate
        }}</mat-label>
        <mat-select
          [ngModel]="selectedPrinter.driver_id"
          (selectionChange)="onDriverChange($event.value)"
          [placeholder]="
            'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.SELECT_MODEL' | translate
          "
          [disabled]="!showPrinterDetail"
        >
          <mat-option
            (click)="openPrinterEditorDriversDialog()"
            [disabled]="
              !(
                (selectedPrinter.driver_id != undefined || showPrinterDetail) &&
                selectedPrinter.connector_os_type != 'Windows' &&
                !selectedPrinter.is_queue
              ) || !showPrinterDetail
            "
            class="more-drivers"
          >
            <mat-icon>add_circle</mat-icon>
            {{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.MORE_MODELS' | translate }}
          </mat-option>
          <mat-divider></mat-divider>
          <mat-option *ngFor="let driver of drivers" [value]="driver.id" [id]="driver.id">
            {{ driver.readable_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        *ngIf="!showPrinterDetail"
        class="ezp-form-field ezp-form-field--no-helper"
        appearance="fill"
      >
        <mat-label>{{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.TABS.ASSIGNMENTS' | translate
        }}</mat-label>
        <input
          matInput
          [value]="groups.length + ' Groups,' + ' ' + users.length + ' Users'"
          readonly
          disabled
        />
      </mat-form-field>
    </div>

    <!-- Updated section for ID and Connector -->
    <div class="ezp-grid ezp-grid--gutter ezp-grid--spacing-vertical mobileFix" data-grid-cols="2" *ngIf="isDesktop">
      <div class="ezp-grid__item">
        <div class="panel panel-multi">
          <div class="panel__title mat-caption">
            {{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.ID' | translate }}
          </div>
          <div class="printer-id" *ngFor="let printer of selectedPrinters">
            <div class="printer-id__title mat-caption">{{ printer.name }}</div>
            <div class="printer-id__content">
              <div class="printer-id__value mat-subheading-2">{{ printer.id }}</div>
              <button mat-icon-button class="printer-id__copy" (click)="copyPrinterId(printer.id)">
                <mat-icon class="icon-suffix">content_copy</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="ezp-grid__item">
        <div class="panel panel-multi" *ngIf="selectedPrinter.connectorName != undefined">
          <div class="panel__title mat-caption">
            {{ 'PRINTERS.NETWORK_PRINTERS.PRINTERS_PROPERTIES.DETAILS.CONNECTOR' | translate }}
          </div>
          <div
            class="connector mat-subheading-2"
            (click)="showConnector.emit(selectedPrinter.connector_id)"
          >
            <mat-icon>wifi_tethering</mat-icon>
            <span class="connector__title">{{ selectedPrinter.connectorName }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- IS MOBILE -->
    <div class="ezp-grid ezp-grid--gutter ezp-grid--spacing-vertical mobileFix" data-grid-cols="1" *ngIf="isMobile">
      <div class="ezp-grid__item">
        <div class="panel panel-multi">
          <div class="panel__title mat-caption">
            {{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.DETAILS.ID' | translate }}
          </div>
          <div class="printer-id" *ngFor="let printer of selectedPrinters">
            <div class="printer-id__title mat-caption">{{ printer.name }}</div>
            <div class="printer-id__content">
              <div class="printer-id__value mat-subheading-2">{{ printer.id }}</div>
              <button mat-icon-button class="printer-id__copy" (click)="copyPrinterId(printer.id)">
                <mat-icon class="icon-suffix">content_copy</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="ezp-grid__item">
        <div class="panel panel-multi" *ngIf="selectedPrinter.connectorName != undefined">
          <div class="panel__title mat-caption">
            {{ 'PRINTERS.NETWORK_PRINTERS.PRINTERS_PROPERTIES.DETAILS.CONNECTOR' | translate }}
          </div>
          <div
            class="connector mat-subheading-2"
            (click)="showConnector.emit(selectedPrinter.connector_id)"
          >
            <mat-icon>wifi_tethering</mat-icon>
            <span class="connector__title">{{ selectedPrinter.connectorName }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
