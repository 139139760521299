import {
  Component,
  OnInit,
  AfterViewInit,
  ElementRef,
  ViewChildren,
  QueryList,
  HostBinding,
} from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { PrintNowSharedService } from '../../modules/shared/services/print-now-shared.service'
import { Router } from '@angular/router'
import lottie from 'lottie-web'
import { AuthUserApiService } from '../../modules/shared/services/auth-api.service'
import { TranslateService } from '@ngx-translate/core'
import { LangaugeService } from '../../modules/shared/services/langauge.service'
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog'
import { InvitationReminderDialogComponent } from './../../modules/shared/components/invitation-reminder-dialog/invitation-reminder-dialog.component';
import { StorageUtils } from '../../modules/storage/utils/storage.utils'
import { ADMIN_USER, HIDE_OVERLAY_QUICKSTART } from '../../modules/storage/constants/storage.constants'
import { MyprintersService } from '../../modules/shared/services/myprinters.service'

@Component({
  selector: 'ezd-quickstart',
  templateUrl: './quickstart.component.html',
  styleUrls: ['./quickstart.component.scss'],
})
export class QuickStartComponent implements OnInit, AfterViewInit {
  @ViewChildren('guideAnimations') guideAnimations: QueryList<ElementRef>
  @HostBinding('class.show-feed') showFeed: boolean = true // temporarily

  baseUrl = 'https://assets.ezeep.com/wp-json'
  routeDefault = 'wp/v2'
  routeCustom = 'ezp/v1'
  endpointGuide = 'guide'
  endpointScenario = 'scenario'
  endpointNews = 'news'
  endpointTranslations = 'translations'
  language: string;
  orderBy = 'menu_order'
  order = 'asc'
  guides = []
  scenarios = []
  newsItems = []
  currentGuideIndex: number = 0
  guidesCount: number = 0
  translations: any = {
    quick_start: {},
    global: {},
  }
  preferredLanguage: any;
  hideInviteUsersDialog: boolean;
  users: any;
  isAdmin: any;
  dateJoined: any;
  currentDate: any;
  daysDiff: any;

  constructor(
    private http: HttpClient,
    private sharedService: PrintNowSharedService,
    private authApiService: AuthUserApiService,
    private translateService: TranslateService,
    private languageService: LangaugeService,
    private myprintersService: MyprintersService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {

    if (StorageUtils.get(sessionStorage, ADMIN_USER))
      this.isAdmin = true;
    
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value;
      if (this.preferredLanguage) {

        /**
         * Invitation reminder
         */
        this.authApiService.list(undefined, undefined, undefined, undefined).subscribe(result => {
          this.users = result.result.results;

          let today = new Date();
          let year = today.getFullYear();
          let month = today.getMonth() + 1;
          let day = today.getDate();

          this.currentDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;

          this.authApiService.me().subscribe(async response => {
            this.authApiService.read(response.result.user_id).subscribe(user_result => {
              this.dateJoined = (user_result.result.date_joined).split('T')[0];
              
              const date1 = new Date(this.dateJoined);
              const date2 = new Date(this.currentDate);
    
              const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    
              this.daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

              if (!StorageUtils.get(sessionStorage, HIDE_OVERLAY_QUICKSTART) && this.users.length == 1 && this.isAdmin == true && this.daysDiff > 7) {
                this.myprintersService.getuserSettings().subscribe(response => {
                  if (response && !response.show_quickstart_dialog) return
                  const dialogConfig = new MatDialogConfig();
          
                    dialogConfig.autoFocus = false;
                    dialogConfig.panelClass = 'ezp-splash';
                    dialogConfig.maxWidth = '480px'
                  let dialogRef = this.dialog.open(InvitationReminderDialogComponent, dialogConfig)
                  dialogRef.afterClosed().subscribe(() => {
                    StorageUtils.set(sessionStorage, HIDE_OVERLAY_QUICKSTART, true);
                  })
                })
              }
            })
          })  
        })

        /**
         * Get guide posts.
         */
        //if (this.preferredLanguage) {
        this.http
          .get(
            `${this.baseUrl}/${this.routeDefault}/${this.endpointGuide}?lang=${this.preferredLanguage}&orderby=${this.orderBy}&order=${this.order}`
          )
          .subscribe((data) => {
            for (let key in data) {
              if (data.hasOwnProperty(key)) {
                let guide = {
                  title: data[key].title.rendered,
                  description: data[key].acf.description,
                  figure: data[key].acf.figure,
                  actions: data[key].acf.actions,
                }
                this.guides.push(guide)
              }
            }

            this.guidesCount = this.guides.length
          })

        /**
         * Get scenario posts.
         */
        this.http
          .get(
            `${this.baseUrl}/${this.routeDefault}/${this.endpointScenario}?lang=${this.preferredLanguage}&orderby=${this.orderBy}&order=${this.order}`
          )
          .subscribe((data) => {
            for (let key in data) {
              if (data.hasOwnProperty(key)) {
                let scenario = {
                  title: data[key].title.rendered,
                  description: data[key].acf.description,
                  figure: data[key].acf.figure,
                  link: data[key].acf.link,
                }
                this.scenarios.push(scenario)
              }
            }
          })

        /**
         * Get news posts.
         */
        if (this.showFeed) {
          // temporarily
          this.http
            .get(`${this.baseUrl}/${this.routeDefault}/${this.endpointNews}?lang=${this.preferredLanguage}`)
            .subscribe((data) => {
              for (let key in data) {
                if (data.hasOwnProperty(key)) {
                  let news = {
                    date: data[key].ezp.post_date,
                    title: data[key].title.rendered,
                    description: data[key].acf.description,
                    link: data[key].acf.link,
                    category: {
                      title: data[key].ezp.category_title,
                      icon: data[key].ezp.category_icon,
                      archive: data[key].ezp.category_archive,
                    },
                  }
                  this.newsItems.push(news)
                }
              }
            })
        }

        /**
         * Get string translations.
         */
        this.http
          .get(`${this.baseUrl}/${this.routeCustom}/${this.endpointTranslations}/${this.preferredLanguage}`)
          .subscribe((data) => {
            this.translations.quick_start = data['quick_start']
            this.translations.global = data['global']
          })
      }
    })

    if (localStorage.getItem('cardCount')) {
      this.currentGuideIndex = JSON.parse(localStorage.getItem('cardCount'))
      localStorage.removeItem('cardCount')
    } else {
      localStorage.setItem('cardCount', '0')
    }
  }

  ngAfterViewInit(): void {
    this.guideAnimations.changes.subscribe((animations) => {
      animations.map((animation: ElementRef) => {
        const animationElement = animation.nativeElement
        const animationData = JSON.parse(animationElement.dataset.animation)

        lottie.loadAnimation({
          container: animationElement,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: animationData,
          rendererSettings: {
            viewBoxOnly: true,
            className: 'ezp-guide__animation-player',
          },
        })
      })
    })
  }

  /**
   * Updates the current guide index.
   *
   * @param {number}    guideIndex    The guide index
   */
  handleCurrentGuideIndex(guideIndex: number): void {
    this.currentGuideIndex = guideIndex
    localStorage.setItem('cardCount', JSON.stringify(guideIndex))
  }

  /**
   * Programmatically navigate to the given path.
   *
   * @param {string}    path    The path
   */
  handleNavigation(path: string): void {
    if (path === '/usersgroups') {
      this.router.navigate(['usersgroups'], { queryParams: { lang: this.preferredLanguage }, queryParamsHandling: 'merge' })
    }
    if (path === '/myprinters') {
      this.router.navigate(['printers'], { queryParams: { lang: this.preferredLanguage }, queryParamsHandling: 'merge' })
    }
    if (path === '/downloads') {
     this.router.navigate(['downloads'])
    }
  }
  /**
   * Perform a custom action based on the elements id.
   *
   * @param {string}    id    The elements id
   */
  handleAction(id: string): void {
    if (id == 'guide-action-print-now') {
      this.sharedService.sendClickEvent()
    }
  }
}
