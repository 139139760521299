import { Component, OnInit } from '@angular/core'
import { SubscriptionApiService } from '../../../modules/shared/services/subscription-api.service';
import { EzpChargebeeApiService } from '../../../modules/shared/services/ezp-chargebee-api.service';
import { map } from 'rxjs/operators';
import { CHARGEBEE_SITE_NAME } from '../../../app.config';

@Component({
  selector: 'ezd-trial-expired',
  templateUrl: './trial-expired.component.html',
  styleUrls: ['./trial-expired.component.scss'],
})
export class TrialExpiredComponent implements OnInit {
  subscriptionStatus: any;
  subscriptionId: any;
  planId: any;
  cbInstance;

  constructor(private subscriptionApiService: SubscriptionApiService,
    private chargebeeApiService: EzpChargebeeApiService) { }

  ngOnInit(): void {
    this.subscriptionApiService.read("-created").subscribe(response => {
      //WARNING! last created subscription is the one to be verified
      this.subscriptionStatus = response.result.result[0];
      this.subscriptionId = this.subscriptionStatus.source.subscription_id;
      this.planId = this.subscriptionStatus.source.plan_id;
      if (!this.subscriptionStatus) return;
      if (this.subscriptionStatus.expires)
        this.subscriptionStatus.daysLeft = (new Date(this.subscriptionStatus.expires)).getTime() - new Date().getTime();
      this.subscriptionStatus.daysLeft = Math.ceil(this.subscriptionStatus.daysLeft / (1000 * 3600 * 24));

    });

    this.cbInstance = window['Chargebee'].init({
      site: CHARGEBEE_SITE_NAME
    });

  }

  openNewSubscription(): void {
    this.cbInstance.createChargebeePortal();
    this.cbInstance.openCheckout({
      hostedPage: () => {
        return this.chargebeeCheckout();
      },

      loaded: () => {
      },
      close: () => {
      },
      success: (hostedPageId) => {
      },
      step: (value) => {
        // value -> which step in checkout
      }
    });
  }


  chargebeeCheckout() {
    return this.chargebeeApiService.checkout(this.subscriptionId, this.planId).pipe(map(response => {
      return response.result
    })).toPromise();
  }
}
