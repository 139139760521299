import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs'
import {
  NetworkPrintersPrintersFiltersService,
  FILTER_CATEGORIES,
} from './network-printers-printers-filters.service'
import { PrintersService, ConnectorsService, Printer } from './printing-api.service'
import { DEFAULT_PAGE_SIZE } from '../../../app.config'
import { DIALOG_RESPONSE } from '../classes/dialog-result'
import { StorageUtils } from '../../storage/utils/storage.utils'

@Injectable()
export class NetworkPrintersPrintersService {
  selectedFilters = []
  _printers = []
  printersCount = 0
  printersSubject = new BehaviorSubject<any>({
    isLoading: true,
    count: 0,
    printers: this._printers,
  })
  pageIndex = 0
  pageSize = DEFAULT_PAGE_SIZE
  sorting: string
  printerPropertiesWatcher = new BehaviorSubject<any>({
    changed: false,
    status: DIALOG_RESPONSE.CANCEL,
  })
  getSelectedFilterSubscription: Subscription

  constructor(
    private httpClient: HttpClient,
    private networkPrintersPrintersFiltersService: NetworkPrintersPrintersFiltersService,
    private printersService: PrintersService,
    private connectorsService: ConnectorsService
  ) {
    this.getSelectedFilterSubscription = this.networkPrintersPrintersFiltersService
      .getSelectedFilters()
      .subscribe({
        next: (result) => {
          this.selectedFilters = result
          this.applyFilters(undefined)
        },
        error: (error) => {
          console.log(error)
        },
      })
  }

  set printers(printers) {
    this._printers = printers
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.getSelectedFilterSubscription.unsubscribe()
  }

  public getFilteredNetworkPrintersPrinters(): Observable<any> {
    return this.printersSubject.asObservable()
  }

  applyFilters(connectorId, showLoading = true) {
    const searchText = this.networkPrintersPrintersFiltersService.getSearchText()
    const modelFilters = this.selectedFilters.filter(
      (filter) => filter.field_name === FILTER_CATEGORIES.MODEL
    )
    const models = modelFilters.length
      ? modelFilters.map((filter) => filter.name).join('|')
      : undefined
    const locationFilters = this.selectedFilters.filter(
      (filter) => filter.field_name === FILTER_CATEGORIES.LOCATION
    )
    const locations = locationFilters.length
      ? locationFilters.map((filter) => filter.name).join('|')
      : undefined
    const statusFilters = this.selectedFilters.filter(
      (filter) => filter.field_name === FILTER_CATEGORIES.STATUS
    )
    const status = statusFilters.length
      ? statusFilters.map((filter) => filter.name).join('|')
      : undefined
    const driverFilters = this.selectedFilters.filter(
      (filter) => filter.field_name === FILTER_CATEGORIES.DRIVER
    )
    const drivers = driverFilters.length
      ? driverFilters.map((filter) => filter.value).join('|')
      : undefined
    const connectorIdFilters = this.selectedFilters.filter(
      (filter) => filter.field_name === FILTER_CATEGORIES.CONNECTOR
    )
    const connectorIdFilter = connectorIdFilters.length
      ? connectorIdFilters.map((filter) => filter.value)[0]
      : undefined
    const connectorIdTemp = connectorId ? connectorId : connectorIdFilter
    // this.printersSubject.next({ isLoading: (searchText == undefined || searchText == '') && showLoading, count: this.printersCount, printers: this._printers, pageSize: this.pageSize, pageIndex: this.pageIndex });

    if (connectorId) {
      this.connectorsService.read(connectorId).subscribe((response) => {
        this.networkPrintersPrintersFiltersService.removeAllFilters(false)
        this.networkPrintersPrintersFiltersService.addFilter(
          'Connector',
          response.result.readable_name,
          response.result.id,
          true
        )
      })
      return
    }

    this.printersService
      .list(
        status,
        undefined,
        models,
        locations,
        drivers,
        undefined,
        undefined,
        connectorIdTemp,
        'connector',
        'false',
        searchText,
        this.sorting,
        this.pageSize,
        this.pageIndex * this.pageSize
      )
      .subscribe(
        (response) => {
          if (searchText !== response.result.searchText) return //block older searches to show up
          this._printers = response.result.results
          this.printersCount = response.result.count
          this.printersSubject.next({
            isLoading: false,
            count: response.result.count,
            noFilters: this.selectedFilters.length == 0 && !searchText,
            printers: response.result.results,
            pageSize: this.pageSize,
            pageIndex: this.pageIndex,
          })
        },
        (error) => {
          this.printersSubject.next({
            isLoading: false,
            count: this.printersCount,
            printers: this._printers,
            pageSize: this.pageSize,
            pageIndex: this.pageIndex,
          })
          //console.log(error);
        }
      )
  }

  selectPrinters(printers: any[]) {
    this._printers.forEach(
      (item) => (item.isSelected = printers.includes(item) ? true : item.isSelected)
    )
    this.printersSubject.next({
      isLoading: false,
      count: this.printersCount,
      printers: this._printers,
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
    })
  }

  unselectPrinters(printers: any[]) {
    this._printers.forEach((item) => {
      if (printers) {
        item.isSelected = printers.includes(item) ? false : item.isSelected
      } else {
        item.isSelected = false
      }
    })
    this.printersSubject.next({
      isLoading: false,
      count: this.printersCount,
      printers: this._printers,
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
    })
  }

  refreshSelectedPrintersProperties() {
    //printer properties is subscribed to printersSubject
    this.printersSubject.next({
      isLoading: false,
      count: this.printersCount,
      printers: this._printers,
      pageSize: this.pageSize,
    })
  }

  public getSelectedNetworkPrinters(): any[] {
    return this._printers.filter((printer) => printer.isSelected)
  }

  public getSelectedNetworkPrintersCount(): number {
    return this.getSelectedNetworkPrinters().length
  }

  public savePrinter(newPrinter: any): Observable<any> {
    return this.printersService.update(newPrinter, newPrinter.id)
  }

  public updatePrinterDriver(driver: any) {
    this.getSelectedNetworkPrinters().map((printer, i) => {
      let index = this._printers.indexOf(printer)

      if (index >= 0) {
        if (StorageUtils.get(localStorage, 'printerUnsaved') == null) {
          this._printers[index].driver_id = driver.id
          StorageUtils.set(localStorage, 'printerUnsaved', this._printers[index])
        } else {
          let selectedPrinter = StorageUtils.get(localStorage, 'printerUnsaved')
          selectedPrinter.driver_id = driver.id
          StorageUtils.set(localStorage, 'printerUnsaved', selectedPrinter)
        }
        this.assignPrinterDriver(this._printers[index].id, driver.id)
        this.changePrinterPropertiesWatcherStatus(true, DIALOG_RESPONSE.NONE)
      }
    })
  }

  public assignPrinterDriver(printerId: string, driverId: string) {
    this.printersService.assignDriver(printerId, driverId).subscribe(
      (response) => {
        console.log('assignPrinterDriver', response)
        this.changePrinterPropertiesWatcherStatus(true, DIALOG_RESPONSE.NONE)
      },
      (error) => {
        console.log(error)
      }
    )
  }

  setPage(pageIndex: number, pageSize: number, isInitial = false) {
    this.pageIndex = pageIndex
    this.pageSize = pageSize ? pageSize : this.pageSize
    if (!isInitial) {
      this.applyFilters(undefined)
    }
  }

  setSorting(sorting) {
    this.sorting = sorting
    this.applyFilters(undefined, false)
  }

  public watchPrinterPropertiesChanges(): Observable<any> {
    return this.printerPropertiesWatcher.asObservable()
  }

  public changePrinterPropertiesWatcherStatus(changed: boolean, status: DIALOG_RESPONSE) {
    this.printerPropertiesWatcher.next({ changed: changed, status: status })
  }
}
