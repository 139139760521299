import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  Input,
} from '@angular/core'
import { NetworkPrintersPrintersService } from '../../../../../modules/shared/services/network-printers-printers.service'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Observable, Subject, Subscription, debounceTime } from 'rxjs'
import { MatPaginator } from '@angular/material/paginator'
import { NetworkPrintersManufacturersService } from '../../../../../modules/shared/services/network-printers-manufacturers.service'
import { PrinterDriver } from '../../../../../modules/shared/services/printing-api.service'
import { NetworkPrintersDriversService } from '../../../../../modules/shared/services/network-printers-drivers.service'
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio'

@Component({
  selector: 'ezd-printer-editor-drivers',
  templateUrl: './printer-editor-drivers.component.html',
  styleUrls: ['./printer-editor-drivers.component.scss'],
})
export class PrinterEditorDriversComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild('searchFieldManufacturer') searchField: ElementRef
  @ViewChild('searchFieldModel') searchFieldDriver: ElementRef

  @Output() public manufacturersSelected: EventEmitter<any> = new EventEmitter<any>()
  @Output() public driverSelected: EventEmitter<void> = new EventEmitter<void>()
  @Output() public modelSelected: EventEmitter<any> = new EventEmitter<any>()

  @Input() selectedPrinters: any[] = []
  @Input() selectedPrinter = null

  searchTextChanged = new Subject<string>()
  showManufactures = true
  showDrivers = false
  modelsFiltered = []
  drivers: PrinterDriver[] = []
  filteredDrivers: PrinterDriver[] = []
  manufacturerSelected: string = ''
  firstLoading: boolean
  manufactureSubscription: Subscription
  models = []
  pageIndex: number
  pageSize: number
  public canSelectDriver = false
  public manufacturers = new Subject<any>()
  public manufacturersFiltered = []
  public isLoading = false
  private manufacturerSubscription: Subscription
  public maxPageLength = 100
  public defaultPageSize = 15
  public pageSizeOptions: number[] = [5, 10, 15, 20, 50, 100]
  public drivers$: Observable<PrinterDriver[]>

  constructor(
    public dialogRef: MatDialogRef<PrinterEditorDriversComponent>,
    private networkPrintersService: NetworkPrintersPrintersService,
    private manufacturerService: NetworkPrintersManufacturersService,
    private networkPrintersDriversService: NetworkPrintersDriversService,
    private networkPrintersPrintersService: NetworkPrintersPrintersService
  ) {
    this.manufacturerService.setManufacturersModelsSearchText(null, true)
    this.searchTextChanged.pipe(debounceTime(400)).subscribe((result) => {
      this.manufacturerService.setManufacturersModelsSearchText(result)
    })
  }

  ngOnInit() {
    this.selectedPrinters = this.networkPrintersPrintersService.getSelectedNetworkPrinters()
    this.isLoading = true
    this.manufacturerService.setPage(0, this.defaultPageSize, true)
    this.manufacturersApi()
  }

  ngOnDestroy() {
    if (this.manufacturerSubscription) {
      this.manufacturerSubscription.unsubscribe()
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.searchField.nativeElement.focus()
    }, 500)
  }

  // NAVIGATION

  goToDrivers(manufacturer: any): void {
    this.loadDrivers(manufacturer)
    this.showManufactures = false
    this.showDrivers = true
  }

  public goToManufacturer() {
    this.showManufactures = true
    this.showDrivers = false
    this.onSearchChange('')
  }

  // API CALLS
  public manufacturersApi() {
    this.manufacturerSubscription = this.manufacturerService
      .getManufacturers()
      .subscribe((result) => {
        this.manufacturers.next(result)
        this.manufacturersFiltered = result
        this.isLoading = false
      })
  }

  loadDrivers(vendor: string): void {
    this.manufacturerService.setPage(0, this.defaultPageSize, true)
    if (this.manufacturerSelected) {
      this.isLoading = true
      this.firstLoading = true
      this.modelsFiltered = []
      this.manufactureSubscription = this.manufacturerService
        .setManufacturerModel(this.manufacturerSelected)
        .subscribe((result) => {
          if (!result.models || this.firstLoading) {
            this.firstLoading = false
            return
          }
          this.models = result.models
          this.modelsFiltered = result.models
          this.maxPageLength = result.count
          this.isLoading = result.isLoading
          this.pageIndex = this.paginator.pageIndex = result.pageIndex
        })
    }
    const limit = 1000
    const offset = 0
    this.isLoading = true
    this.networkPrintersDriversService.getDrivers(vendor, '', '', '', limit, offset).subscribe(
      (response) => {
        // Sort the drivers alphabetically by name
        response.sort((a, b) => a.name.localeCompare(b.name))
        this.drivers = response
        this.filteredDrivers = response // Initialize the filtered drivers
        this.isLoading = false
      },
      (error) => {
        console.error('Error loading drivers:', error)
        this.isLoading = false
      }
    )
  }

  public onPaginationChangeDriver(e) {
    this.pageIndex = e.pageIndex
    this.pageSize = e.pageSize
    this.manufacturerService.setPage(this.pageIndex, this.pageSize)
  }

  selectDriver(driver): void {
    this.networkPrintersService.updatePrinterDriver(this.selectedPrinter)
    this.driverSelected.emit()
    this.dialogRef.close(driver) // Close the dialog and pass the selected driver
  }

  // SEARCH COMPONENTS
  public onSearchChangeManufacturer(searchValue: string) {
    this.manufacturerService.setManufacturersSearchText(searchValue)
  }

  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue)
    this.manufacturerService.setManufacturersSearchText(searchValue)
  }

  onSearchChangeDriver(searchValue: string) {
    this.filteredDrivers = this.drivers.filter((driver) =>
      this.includesIgnoreCase(driver.name, searchValue)
    )
  }

  // Utility function for flexible, case-insensitive partial match
  includesIgnoreCase(source: string, target: string): boolean {
    const targetParts = target.toLowerCase().split(' ')
    const sourceLower = source.toLowerCase()

    // Check if each part of the target is included in the source
    return targetParts.every((part) => sourceLower.includes(part))
  }

  public goBack() {
    this.dialogRef.close()
  }

  public requestDriver() {
    window.location.href = 'mailto:helpdesk@ezeep.com?subject=Request a New Driver'
  }

  modelChanged($event: MatRadioChange) {
    this.canSelectDriver = true
    this.modelSelected.emit($event.value)
  }
}
