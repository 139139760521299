<h2 mat-dialog-title id="printer-editor-text">
  {{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.TITLE' | translate }}
</h2>
<div mat-dialog-content>
  <mat-tab-group class="ezp-tabs" [(selectedIndex)]="selectedTabIndex" [animationDuration]="'0ms'">
    <mat-tab label="Details">
      <ng-template mat-tab-label>
        <mat-icon>info</mat-icon>
        <span id="printer-details-tab-text">{{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.TABS.DETAILS' | translate
        }}</span>
      </ng-template>
      <div class="tab-content">
        <ezd-printer-editor-details
          (capabilitiesLoaded)="capabilitiesComponent?.updateCapabilities($event)"
          [selectedPrinters]="selectedPrinters"
        ></ezd-printer-editor-details>
      </div>
    </mat-tab>
    <mat-tab label="Properties">
      <ng-template mat-tab-label>
        <mat-icon>toggle_on_off</mat-icon>
        <span id="capabilities-tab-text">{{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.TABS.PROPERTIES' | translate
        }}</span>
      </ng-template>
      <div class="tab-content">
        <ezd-printer-editor-capabilities
          [selectedPrinters]="selectedPrinters"
          (duplicateNameDetected)="onDuplicateName($event)"
          (hasEmptyName)="onEmptyName($event)"
        ></ezd-printer-editor-capabilities>
      </div>
    </mat-tab>
    <mat-tab label="Assignments">
      <ng-template mat-tab-label>
        <mat-icon>group</mat-icon>
        <span id="assignments-tab-text">{{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.TABS.ASSIGNMENTS' | translate
        }}</span>
      </ng-template>
      <div class="tab-content">
        <ezd-printer-editor-assignments></ezd-printer-editor-assignments>
      </div>
    </mat-tab>
    <mat-tab label="Release Code">
      <ng-template mat-tab-label>
        <mat-icon>security</mat-icon>
        <span id="releasecode-tab-text">{{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.TABS.PULL_PRINTING' | translate
        }}</span>
      </ng-template>
      <div class="tab-content">
        <ezd-printer-editor-pullprinting></ezd-printer-editor-pullprinting>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div class="tab-footer">
    <div class="left">
      <button
        *ngIf="selectedTabIndex === 1 && selectedPrinters.length === 1"
        mat-button
        id="reset-capabilities"
        class=""
        (click)="openResetModal()"
        style="color: rgba(0, 0, 0, 0.54)"
      >
        {{ 'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PROPERTIES.RESET_TO_DEFAULTS' | translate }}
      </button>
    </div>
    <div class="right">
      <button
        mat-button
        id="cancel-printer-editor"
        color="primary"
        class="ezp-button"
        (click)="close()"
      >
        {{ 'CANCEL' | translate }}
      </button>
      <button
        mat-button
        id="done-printer-editor"
        color="primary"
        class="ezp-button"
        [disabled]="
          hasCapabilitiesErrors || hasDuplicateName || isSaveLoadingIndicator || hasEmptyName
        "
        (click)="saveAllData()"
      >
        <mat-icon [ngClass]="{ 'fa-spin': isSaveLoadingIndicator }" *ngIf="isSaveLoadingIndicator"
          >cached</mat-icon
        >
        {{ 'SAVE' | translate }}
      </button>
    </div>
  </div>
</div>

<!-- Reset Confirmation Modal -->
<div *ngIf="showResetModal" class="modal-overlay">
  <div class="modal">
    <div mat-dialog-content class="reset-content">
      <p
        [innerHTML]="
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.PROPERTIES.RESET_CONFIRMATION_MESSAGE'
            | translate : { value: selectedPrinter?.name }
        "
      ></p>
    </div>
    <div mat-dialog-actions class="reset-actions">
      <button mat-button color="primary" class="ezp-button" (click)="closeResetModal()">
        {{ 'CANCEL' | translate }}
      </button>
      <button
        mat-button
        color="primary"
        class="ezp-button"
        (click)="confirmReset()"
        [disabled]="isResetLoading"
      >
        <mat-icon [ngClass]="{ 'fa-spin': isResetLoading }" *ngIf="isResetLoading">cached</mat-icon>
        {{ 'RESET' | translate }}
      </button>
    </div>
  </div>
</div>
