import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { DEFAULT_PAGE_SIZE } from '../../../app.config';
import { EventDataApiService } from './endpoint-events-api.service';

@Injectable({
    providedIn: 'root'
})
export class EndpointEventService {

    endPointEventsSubject = new BehaviorSubject<any>({ isLoading: false, count: 0, events: [] });
    pageIndex: number = 0;
    pageSize: number = DEFAULT_PAGE_SIZE;
    eventsCount: any;
    endPointEvents: any;
    sorting: any;
    endpoint_id: string;

    constructor(private endpointEventsApiService: EventDataApiService
    ) { }

    getEndPointEvents(): Observable<any> {
        return this.endPointEventsSubject.asObservable();
    }


    applyFilters(showLoading: boolean = true) {
        // this.endPointEventsSubject.next({ isLoading: showLoading, count: 0, events: [] });
        this.endpointEventsApiService.list(this.endpoint_id, this.sorting ? this.sorting : "-created", this.pageSize, this.pageIndex * this.pageSize).subscribe(async response => {
            this.endPointEvents = response.result.results;
            this.eventsCount = response.result.count;
            this.endPointEventsSubject.next({
                isLoading: false,
                count: response.result.count,
                events: this.endPointEvents
            });
        }, error => {
            this.endPointEventsSubject.next({ isLoading: false, count: this.eventsCount, events: this.endPointEvents });
        });
    }

    setPage(pageIndex: number, pageSize: number, isInitial = false) {
        this.pageIndex = pageIndex;
        this.pageSize = pageSize;
        if (!isInitial) {
            this.applyFilters(true);
        }
    }

    setSorting(sorting) {
        this.sorting = sorting;
        this.applyFilters(false);
    }


    getEvent(event_id: string) {
        return this.endpointEventsApiService.read(this.endpoint_id, event_id).pipe(map(responseUpdate => {
            return responseUpdate.result;
        }));
    }

    retryEvent(event_id: string) {
        return this.endpointEventsApiService.retry(this.endpoint_id, event_id).pipe(map(responseUpdate => {
            return responseUpdate.result;
        }));
    }

    retryAll() {
        return this.endpointEventsApiService.retry_all(this.endpoint_id).pipe(map(responseUpdate => {
            return responseUpdate.result;
        }));
    }

    getRetryall() {
        return this.endpointEventsApiService.read_retry_all(this.endpoint_id).pipe(map(responseUpdate => {
            return responseUpdate.result;
        }));
    }

    deleteRetryAll() {
        return this.endpointEventsApiService.delete_retry_all(this.endpoint_id).pipe(map(responseUpdate => {
            return responseUpdate.result;
        }));
    }
}
