<div class="table">
  <div class="row row--header">
    <div class="column" *ngFor="let column of columns">
      <div class="mat-body-2">{{ column }}</div>
    </div>
  </div>
  <div class="row" *ngFor="let row of rows">
    <div class="column" *ngFor="let column of row" [style.--ezp-table-placeholder-bar-width.px]="column">
      <div class="bar"></div>
    </div>
  </div>
</div>
<div class="message">
  <mat-icon *ngIf="mainIcon">{{mainIcon}}</mat-icon>
  <div class="heading mat-subheading-2" *ngIf="heading !== ''">{{ heading }}</div>
  <div class="description mat-subheading-2" *ngIf="description !== ''">{{ description }}</div>
  <button *ngIf="action" class="action ezp-button mat-flat-button" mat-button color="primary"
    (click)="handleNavigation(action.path)">
    <mat-icon *ngIf="action.icon" style="color:white">{{ action.icon }}</mat-icon>
    {{ action.title }}
  </button>
</div>