<h2 *ngIf="!isEdit" mat-dialog-title id="profile-details-profileinfo-text">{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.PROFILE_INFORMATION' |
  translate }}</h2>
  <h2 *ngIf="isEdit" mat-dialog-title id="profile-details-profileinfo-text">{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.PROFILE_EDITOR' |
    translate }}</h2>
<div class="ezp-dialog__controls">
  <button *ngIf="isEdit" mat-icon-button id="profile-details-menu" class="ezp-dialog__controls__action"
    [matMenuTriggerFor]="profileDetailsMenu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #profileDetailsMenu="matMenu" xPosition="before" yPosition="below">
    <button mat-menu-item id="profile-details-remove-profile" class="ezp-menu__item ezp-menu__item--destructive"
      (click)="openRemoveProfileDialog()">
      {{ 'PRINTERS.PROFILES.REMOVE_PROFILE' | translate }}
    </button>
  </mat-menu>
</div>
<form [formGroup]="profileForm">
  <div mat-dialog-content>
    <div class="ezp-grid ezp-grid--gutter ezp-grid--spacing-vertical" data-grid-cols="2">
      <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
        <mat-label id="profile-name-text">{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.NAME' | translate }}</mat-label>
        <input matInput id="profile-name" formControlName="name" />
      </mat-form-field>

      <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
        <mat-label id="profile-description-text">{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.DESCRIPTION' | translate
          }}</mat-label>
        <input matInput id="profile-description" formControlName="description" />
      </mat-form-field>
    </div>
    <mat-divider></mat-divider>
    <div class="ezp-grid ezp-grid--gutter" data-grid-cols="1">
      <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
        <mat-icon matPrefix>print</mat-icon>
        <mat-label id="profile-printer-text">{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.SELECT_PRINTERS' | translate
          }}</mat-label>
        <input type="text" placeholder="{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.SELECT_PRINTERS_PLACEHOLDER' | translate }}" aria-label="printer" matInput formControlName="autoCompleteInput"
          [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete" (closed)="printerDropDownClosed()">
            <mat-option *ngIf="isLoadingPrinters" disabled>{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.LOADING_PRINTERS' | translate }}</mat-option>
            <mat-option *ngIf="!isLoadingPrinters && noPrinters" disabled>{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.NO_PRINTERS' | translate }}</mat-option>
            <mat-option *ngFor="let printer of filteredPrinters | async" (onSelectionChange)="printerSelected(printer)" [value]="printer.name">{{printer.name}}</mat-option>
          </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="ezp-grid ezp-grid--gutter ezp-grid--spacing-vertical" data-grid-cols="2">
      <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
        <img alt="color" matPrefix class="mat-icon" src="/assets/images/profiles-color.png">
        <mat-label id="color-output-text">{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.COLOR_OUTPUT' | translate }}</mat-label>
        <mat-select #color id="color-output" formControlName="color">
          <mat-option *ngFor="let color of colors" [value]="color.name">{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.VALUES.COLORS.'
            +
            color.name | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
        <img alt="duplex" matPrefix class="mat-icon" src="/assets/images/profiles-duplex.png">
        <mat-label id="profile-options-text">{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.DUPLEX_OPTION' | translate
          }}</mat-label>
        <mat-select #option id="profile-options" formControlName="duplex">
          <mat-option *ngFor="let option of duplex" [value]="option.name">{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.VALUES.DUPLEX.'
            +
            option.name | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
        <img alt="portrait" matPrefix class="mat-icon" src="/assets/images/profiles-portrait.png">
        <mat-label id="profile-orientation-text">{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.ORIENTATION' | translate
          }}</mat-label>
        <mat-select #orientation id="profile-orientation" formControlName="orientation">
          <mat-option *ngFor="let orientation of orientations" [value]="orientation">{{
            'PRINTERS.PROFILES.PROFILE_DETAILS.VALUES.ORIENTATION.' + orientation | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
        <img alt="resolution" matPrefix class="mat-icon" src="/assets/images/profiles-resolution.png">
        <mat-label id="profile-quality-text">{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.RESOLUTION' | translate
          }}</mat-label>
        <mat-select #resolution id="profile-paper-size" formControlName="resolution">
          <mat-option *ngFor="let resolution of resolutions" [value]="resolution.name">{{ resolution.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
        <img alt="paper" matPrefix class="mat-icon" src="/assets/images/profiles-papersize.png">
        <mat-label id="profile-paper-size-text">{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.PAPER_SIZE' | translate
          }}</mat-label>
        <mat-select #paperSize id="profile-paper-size" formControlName="paper_size">
          <mat-option *ngFor="let paper_size of papers" [value]="paper_size.name">{{ paper_size.name }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
        <img alt="tray" matPrefix class="mat-icon" src="/assets/images/profiles-tray.png">
        <mat-label id="profile-tray-text">{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.TRAY' | translate }}</mat-label>
        <mat-select #tray id="profile-tray" formControlName="tray">
          <mat-option *ngFor="let tray of trays" [value]="tray.name">{{ tray.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</form>
<div mat-dialog-actions>
  <button mat-button id="profile-details-cancel" (click)="close()" color="primary" class="ezp-button"
    [disabled]="isLoading">
    {{ 'CANCEL' | translate }}
  </button>
  <button [disabled]="!profileForm.valid || isLoading" mat-button id="profile-details-done" (click)="done()" color="primary">
    <mat-icon [ngClass]="{ 'fa-spin': isLoading }" *ngIf="isLoading">cached</mat-icon>
    {{isEdit ? ('DONE' | translate) : ('CREATE' | translate)}}
  </button>
</div>