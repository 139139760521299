import { Injectable } from '@angular/core';
import { PrinterAssignment, AssignmentsService, UserAssignmentsService, PrinterUserAssignment } from './printing-api.service';
import { Observable, from as observableFrom, Subscription } from 'rxjs';
import { map, mergeMap, last } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NetworkPrintersAssignmentsService {

  constructor(private assignmentsService: AssignmentsService,
    private userAssignmentsService: UserAssignmentsService) { }

  public getPrinterAssignments(printerId) : Observable<any> {
    return this.assignmentsService.read(printerId).pipe(map(response => {
      return response.result;
    }))
  }

  public getPrintersAssignments(printerId:string[]) : Observable<any> {
    return this.assignmentsService.list(printerId.join('|'), undefined, undefined, undefined, undefined, undefined).pipe(map(response => {
      return response.result.results;
    }))
  }

  public update(ranges: any[], groups: any[], printerId: string, printerName: string): Observable<any> {
    let assignments: any = [{ groups: groups, settings: { ip_ranges: ranges.map(x => { return { start_ip: x.start_ip, stop_ip: x.stop_ip } }) } }];
    let assignment = new PrinterAssignment({ printer_id: printerId, printer_name: printerName, assignment: assignments });
    return this.assignmentsService.update(assignment, printerId).pipe(map(response => {
      return response.result;
    }));
  }
  
  
  public getAssignments(printerId: string): Observable<any> {
    return this.getPrinterAssignments(printerId).pipe(map(result => {
      let tempGroups = [];
      let tempRestrictedIpRanges = [];

      if (!result || !result.assignments || result.assignments.length == 0) {
        return { groups: tempGroups, ip_ranges: tempRestrictedIpRanges };
      }

      let printerGroups = result.assignments[0].groups;
      if (printerGroups && printerGroups.length > 0) {
        tempGroups = printerGroups;
      }
      if (result.assignments[0].settings && result.assignments[0].settings.ip_ranges && result.assignments[0].settings.ip_ranges.length > 0) {
        result.assignments[0].settings.ip_ranges.forEach(item => {
          let range = { start_ip: item.start_ip, stop_ip: item.stop_ip, label: item.start_ip + " - " + item.stop_ip };
          tempRestrictedIpRanges.push(range);
        });
      }

      return { groups: tempGroups, ip_ranges: tempRestrictedIpRanges };
    }));
  }

  public getPrinterUserAssignments(printerId) : Observable<any> {
    return this.userAssignmentsService.read(printerId).pipe(map(response => {
      return response.result;
    }))
  }

  public getPrintersUserAssignments(printerId:string[]) : Observable<any> {
    return this.userAssignmentsService.list(printerId.join('|'), undefined, undefined, undefined, undefined, undefined).pipe(map(response => {
      return response.result.results;
    }))
  }

  public updatePrinterUserAssignment(users: any[], printerId: string, printerName: string): Observable<any> {
    let assignments: any = [{ users: users }];
    let assignment = new PrinterUserAssignment({ printer_id: printerId, printer_name: printerName, assignment: assignments });
    return this.userAssignmentsService.update(assignment, printerId).pipe(map(response => {
      return response.result;
    }));
  }

  public getUserAssignments(printerId: string): Observable<any> {
    return this.getPrinterUserAssignments(printerId).pipe(map(result => {
      let tempUsers = [];

      if (!result || !result.assignments || !result.assignments || result.assignments.length == 0) {
        return { users: tempUsers };
      }

      let printerUsers = result.assignments[0].users;
      if (printerUsers && printerUsers.length > 0) {
        tempUsers = printerUsers;
      }

      return { users: tempUsers };
    }));
  }
}