import { Component, OnInit, Input } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'ezd-table-placeholder',
  templateUrl: './table-placeholder.component.html',
  styleUrls: ['./table-placeholder.component.scss'],
})
export class TablePlaceholderComponent implements OnInit {
  @Input() columns: string[] = ['Column', 'Column']
  @Input('rows') rowsCount: number = 6
  @Input() heading: string = ''
  @Input() description: string = ''
  @Input() action: { title: String; action: string, icon: string }
  @Input() mainIcon: string = '';

  public rows: any

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.rows = Array.from(Array(this.rowsCount)).map(() => {
      return this.columns.map(() => this.generateBarWidth())
    })
  }

  private generateBarWidth(min: number = 60, max: number = 200): number {
    min = Math.ceil(min)
    max = Math.floor(max)

    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  public handleNavigation(path: string): void {
    this.router.navigate([path]);
  }
}
