<h2 mat-dialog-title id="domain-editor-title">{{ 'USERSGROUPS.DOMAINS.DOMAIN_INFORMATION.DOMAIN_EDITOR_TITLE' | translate }}</h2>
<div class="ezp-dialog__controls">
  <button
    mat-icon-button
    id="group-details-menu"
    class="ezp-dialog__controls__action"
    [matMenuTriggerFor]="domainDetailsMenu"
    *ngIf="canRemove" 
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #domainDetailsMenu="matMenu" xPosition="before" yPosition="below">
    <button mat-menu-item class="ezp-menu__item ezp-menu__item--destructive" id="remove-domain-button" (click)="openRemoveDomainDialog()">
        {{ 'USERSGROUPS.DOMAINS.DOMAIN_INFORMATION.REMOVE_DOMAIN' | translate }}
    </button>
  </mat-menu>
</div>
<div mat-dialog-content>
    <div class="ezp-grid ezp-grid--spacing-vertical" data-grid-cols="1" style="padding-top: 12px;padding-bottom: 12px;">
        <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
            <mat-label id="domain-label-text">{{ 'USERSGROUPS.DOMAINS.DOMAIN_INFORMATION.DOMAIN_LABEL' | translate }}</mat-label>
            <input matInput id="domain-label-input" [(ngModel)]="domain.domain" (input)="onDomainChange($event.target.value)"/>
        </mat-form-field>
    </div>
    <div class="ezp-grid ezp-grid--gutter-horizontal ezp-grid--spacing-top" data-grid-cols="1" style="padding-top: 0px">
    
        <span class="ezp-text ezp-text--medium-emphasis mat-caption" style="font-size: 13px;" id="domain-caption-text">
            {{ 'USERSGROUPS.DOMAINS.DOMAIN_INFORMATION.DOMAIN_CAPTION' | translate }} </span>
    
    </div>
    <div class="ezp-grid ezp-grid--spacing-vertical" data-grid-cols="1" style="padding-top: 12px;padding-bottom: 12px; grid-template: auto/repeat(1, 1fr);">

        <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
            <mat-label id="domain-search-groups-label">{{ 'USERSGROUPS.DOMAINS.DOMAIN_INFORMATION.SEARCH_GROUPS_LABEL' | translate }}</mat-label>
            <input matInput id="search-groups-input" #searchGroups (input)="onSearchChange($event.target.value)"/>
          </mat-form-field>
    </div>
    <ezd-infinity-scroll (scrolled)="onScroll()" style="height: 295px; overflow-y: auto">
        <mat-selection-list class="ezp-list ezp-list--filterable" #groups (selectionChange)="onSelection($event, groups.selectedOptions)">
          <mat-list-option checkboxPosition="before" color="primary" 
            *ngFor="let group of assignedGroups"
            [selected]="group.isSelected" 
            [disabled]="group.origin.provider !== 'Ezeep'"
            [value]="group"  
            [id]="group.name">
            <div class="ezp-list__item">
              <div [id]="group.name">{{ group.name }}</div>
              <div class="ezp-list__item__right-content" id="azure-group-text" *ngIf="group.origin.provider === 'Azure'">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_GROUPS.AZURE_GROUP' | translate }}</div>
              <div class="ezp-list__item__right-content" id="azure-group-text" *ngIf="group.origin.provider === 'OfficeRnD'">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_GROUPS.OFFICERND_GROUP' | translate }}</div>
              <div class="ezp-list__item__right-content" id="azure-group-text" *ngIf="group.origin.provider === 'Cobot'">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_GROUPS.COBOT_GROUP' | translate }}</div>
            </div>
          </mat-list-option>
        </mat-selection-list>
        <div class="ezp-list__fetching" *ngIf="isLoading">
          <mat-spinner class="ezp-list__fetching__spinner ezp-list__fetching__spinner--selection"></mat-spinner>
          <span
            class="ezp-list__fetching__title">Fetching Groups...</span>
        </div>
      </ezd-infinity-scroll>
</div>
<div mat-dialog-actions>
  <button mat-button color="primary" class="ezp-button" id="close-domain-dialog" (click)="onClose()">
   {{ 'CANCEL' | translate }} 
  </button>
  <button mat-button color="primary" class="ezp-button" (click)="onAdd()">
    <mat-icon *ngIf="isLoadingSave" [ngClass]="{ 'fa-spin': isLoadingSave }">cached</mat-icon>
    <span *ngIf="!domain.toEdit" id="add-domain-button">{{ 'ADD' | translate }}</span>
    <span *ngIf="domain.toEdit" id="save-domain-button">{{ 'SAVE' | translate }}</span>
  </button>
</div>
