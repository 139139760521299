import { Component, ViewEncapsulation } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBarRef as MatSnackBarRef } from '@angular/material/legacy-snack-bar';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'ezd-error-snack',
  templateUrl: './errorsnack.component.html',
  styleUrls: ['./errorsnack.component.scss'],
})
export class ErrorSnackComponent {
  public snackBarRef: MatSnackBarRef<ErrorSnackComponent>;
  public message: string;

  public close() {
    this.snackBarRef.dismiss();
  }
}
