export const DropDownSelection = {
    lastWeek : 'lastWeek',
    lastMonth : 'lastMonth',
    lastYear : 'lastYear',
    lastQuarter : 'lastQuarter',
    custom : 'custom'
}

export const MAX_ALLOWED_MONTH = 26

export const DATE_LOCALIZATION = {
    de : 'de-DE',
    en : 'en-US'
}