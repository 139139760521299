import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpEventType,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { Util } from '../utils/object.utils'

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<HttpEventType.Response>> {
    // Don't add credentials for Sentry requests
    if (req.url.includes('sentry.io')) {
      return next.handle(req)
    }

    if (!req.url.includes('accounts')) {
      req = req.clone({
        withCredentials: true,
      })
    }
    if (req.method === 'POST' && typeof req.body === 'object') {
      const newRequest = req.clone({
        body: Util.removeEmptyKeys(req.body),
        withCredentials: true,
      })

      return next.handle(newRequest)
    }

    return next.handle(req)
  }
}
