import { EnvironmentVariables } from './types'

export const environment: EnvironmentVariables = {
  production: true,
  apiUrls: {
    base: '${API_BASE_URL}',
    api2Base: '${API_BASE_URL}',
    accounts: '${ACCOUNTS_URL}',
    portal: '${PORTAL_URL}',
    azure: '${AZURE_ACCOUNT_URL}',
    printApiHostUrl: '${PRINT_API_HOST}',
    chargebeeSiteName: '${CHARGEBEE_SITE_NAME}',
    documentManagement: '${DOCUMENT_MANAGEMENT_URL}',
  },
  auth: {
    ezeepClientId: '${EZEEP_CLIENT_ID}',
  },
  monitoring: {
    posthogKey: '${POSTHOG_API_KEY}',
    posthogHost: '${POSTHOG_HOST}',
    sentryDsn: '${SENTRY_DSN}',
  },
  environment: '${STAGE}',
}
