<h2 mat-dialog-title>{{ 'PRINTERS.MY_PRINTERS.WELCOME.TITLE' | translate }}</h2>
<div mat-dialog-content>
  <p class="mat-body-1">{{ 'PRINTERS.MY_PRINTERS.WELCOME.BODY' | translate }}</p>
</div>

<div mat-dialog-actions class="ezp-dialog__actions ezp-dialog__actions--spread">
  <button mat-button (click)="close()" color="primary" class="ezp-button">
    {{ 'PRINTERS.MY_PRINTERS.WELCOME.CLOSE' | translate }}
  </button>
</div>
