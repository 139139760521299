import { map, mergeMap, toArray } from 'rxjs/operators'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { AuthenticationService } from '../services/authentication.service'
import { EZEEP_AZURE_API_URL_LOGIN } from '../../../app.config'
import { StorageUtils } from '../../storage/utils/storage.utils'
import { ADMIN_USER, COMMON_USER } from '../../storage/constants/storage.constants'
import {
  GroupsService,
  ProfileService,
  UserPrintingService,
} from '../../shared/services/printing-api.service'
import { AuthUserApiService } from '../../shared/services/auth-api.service'

@Injectable()
export class AuthenticationEzeepCommonUserGuard {
  constructor(
    private authenticationService: AuthenticationService,
    private profileService: ProfileService,
    private router: Router,
    private authApiService: AuthUserApiService,
    private groupService: GroupsService,
    private userPrintService: UserPrintingService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.canAccessMyPrinters(route, state).pipe(
      map((result) => {
        return result
      })
    )
  }

  private canAccessMyPrinters(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authenticationService.isEzeepUser().pipe(
      mergeMap((isEzeepUser) => {
        if (isEzeepUser) {
          return this.profileService.read().pipe(
            mergeMap((response) => {
              return this.authApiService.me().pipe(
                mergeMap((user) => {
                  return this.userPrintService.read(user.result.user_id).pipe(
                    mergeMap((currentUser) => {
                      return this.getAllMyPrintersGroups(100, 0, 'true')
                        .pipe(toArray())
                        .pipe(
                          map((allowedGroups) => {
                            if (isEzeepUser) {
                              if (
                                StorageUtils.get(sessionStorage, ADMIN_USER) ||
                                StorageUtils.get(sessionStorage, COMMON_USER)
                              )
                                return true
                              else {
                                if (
                                  state.url === '/myprinters' &&
                                  ((response.result.myprinters_enabled !== undefined &&
                                    !response.result.myprinters_enabled) ||
                                    (allowedGroups.length > 0 &&
                                      allowedGroups.filter(
                                        (x) =>
                                          currentUser.result.groups.filter(
                                            (y) => x.id === y.group_id
                                          ).length > 0
                                      ).length == 0))
                                ) {
                                  this.router.navigate(['downloads'], {
                                    queryParams: route.queryParams,
                                  })
                                  return false
                                } else {
                                  return true
                                }
                              }
                            } else {
                              return false
                            }
                          })
                        )
                    })
                  )
                })
              )
            })
          )
        } else {
          window.location.href = EZEEP_AZURE_API_URL_LOGIN + state.url
          return of(false)
        }
      })
    )
  }

  private getAllMyPrintersGroups(
    page_size: number = 100,
    page_index: number = 0,
    previousResponse?: string
  ): Observable<any> {
    let offset = page_index * page_size
    if (!previousResponse) {
      return of([])
    } else {
      return this.groupService
        .list(undefined, undefined, undefined, undefined, undefined, undefined, page_size, offset)
        .pipe(
          mergeMap((response) => {
            if (response.result.next) {
              let nextIndex = page_index + 1
              return this.getAllMyPrintersGroups(page_size, nextIndex, response.result.next).pipe(
                mergeMap((nextResponse) => {
                  return [
                    ...response.result.results.filter((x) => x.myprinters_enabled),
                    ...nextResponse,
                  ]
                })
              )
            } else {
              let groups = response.result.results.filter((x) => x.myprinters_enabled)
              return [...groups]
            }
          })
        )
    }
  }
}
