<div class="ezp-grid ezp-grid--gutter ezp-grid--spacing-vertical" data-grid-cols="2">
  <mat-form-field  class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
    <mat-label id="user-first-name-text">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_INFO.FIRST_NAME' | translate }}</mat-label>
    <span *ngIf="isSocialAuthUser == true">
      <input matInput id="user-first-name" [ngModel]="user.display_name && user.display_name.split(' ').length > 1 ?  user.display_name.split(' ').slice(0, -1).join(' ') : user.display_name" disabled />
    </span>
    <span *ngIf="isSocialAuthUser == false">
      <input matInput #firstName id="user-first-name" [ngModel]="user.first_name" (input)="onFirstNameChange($event.target.value)" />
    </span>
  </mat-form-field>
  <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
    <mat-label id="user-last-name-text">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_INFO.LAST_NAME' | translate }}</mat-label>
    <span *ngIf="isSocialAuthUser == true">
      <input matInput id="user-last-name" [ngModel]="user.display_name && user.display_name.split(' ').length > 1 ?  user.display_name.split(' ').slice(-1).join(' ') : ''" disabled />
    </span>
    <span *ngIf="isSocialAuthUser == false">
      <input matInput id="user-last-name" [ngModel]="user.last_name" (input)="onLastNameChange($event.target.value)" />
    </span>
  </mat-form-field>
  <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
    <mat-label id="user-email-text">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_INFO.EMAIL' | translate }}</mat-label>
    <input matInput #email id="user-email" [ngModel]="user.email" disabled
      (input)="onEmailChange($event.target.value)" />
  </mat-form-field>
  <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
    <mat-label id="user-role-text">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_INFO.ROLE' | translate }}</mat-label>
    <mat-select #role id="user-role" [ngModel]="user.role" [disabled]="isAdmin" (selectionChange)="onRoleChange($event.value)">
      <mat-option id="user-role-admin" value="admin">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_INFO.ADMIN' | translate }}</mat-option>
      <mat-option id="user-role-user" value="user">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_INFO.USER' | translate }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
    <mat-label id="user-billing-code-text">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_INFO.BILLING_CODE' | translate }}</mat-label>
    <input matInput id="user-billing-code"  #billingCode [ngModel]="user.billing_code" [disabled]="isWebhooksPresent == false && isReportDownloadPresent == false"
    (input)="onBillingCodeChange($event.target.value)"/>
  </mat-form-field>
  <mat-form-field class="ezp-form-field" appearance="fill" *ngIf="user.user_invitations">
    <mat-label>{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_INFO.STATUS' | translate }}</mat-label>
    <input disabled matInput id="user-status"  [ngModel]="user.status" />
    <button *ngIf="user.user_invitations[0] && user.user_invitations[0].invitation_status !== 'ACCEPTED'" matSuffix mat-icon-button id="open-resend-invite-dialog" (click)="openResendInvitationDialog()" 
      class="ezp-button ezp-button--icon ezp-button--icon--leading" matTooltip="{{'USERSGROUPS.USERS.INVITE_USER.RESEND_INVITATION' | translate }}">
      <span><mat-icon matSuffix color="primary">mail_outline</mat-icon></span>
    </button>
  </mat-form-field>
</div>
<mat-divider></mat-divider>
<div class="ezp-grid ezp-grid--gutter-horizontal ezp-grid--spacing-top" data-grid-cols="2">
  <div class="user-id">
    <div class="user-id__item">
      <span class="ezp-text ezp-text--medium-emphasis mat-caption" id="user-ezeepid-text"><strong>{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_INFO.EZEEP_ID' | translate }}</strong></span>
      <span class="ezp-text ezp-text--medium-emphasis mat-caption" [id]="user.id">{{user.id}}</span>
      <span class="user-id__copy ezp-text ezp-text--primary mat-caption" id="copy-ezeepid" (click)="copyEzeepId()">{{ 'COPY' | translate }}</span>
    </div>
    <div class="user-id__item" *ngIf="user.azure_profile">
      <span class="ezp-text ezp-text--medium-emphasis mat-caption" id="user-azureid-text"><strong>{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_INFO.AZURE_ID' | translate }}</strong></span>
      <span class="ezp-text ezp-text--medium-emphasis mat-caption" [id]="user.azure_profile.oid">{{user.azure_profile.oid}}</span>
      <span class="user-id__copy ezp-text ezp-text--primary mat-caption" id="copy-azureid" (click)="copyAzureId()">{{ 'COPY' | translate }}</span>
    </div>
  </div>
</div>