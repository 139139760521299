import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Subject, lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';
import { AuthUserApiService, AuthOrganizationApiService } from '../../services/auth-api.service';
import { UsersgroupsUsersService } from '../../services/usersgroups-users.service';
import { UntypedFormBuilder, FormsModule, UntypedFormGroup, FormControl } from '@angular/forms';
import { StorageUtils } from '../../../storage/utils/storage.utils';
import { ADMIN_USER, COMMON_USER } from '../../../storage/constants/storage.constants';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { SubscriptionApiService } from '../../services/subscription-api.service';
import { env, changePasswordUrl } from '../../../../app.config';
import { SubscriptionService } from '../../services/subscription.service';

@Component({
  selector: 'user-settings-dialog',
  templateUrl: './user-settings-dialog.component.html',
  styleUrls: ['./user-settings-dialog.component.scss'],
})
export class UserSettingsDialogComponent implements OnInit {
  isLoading: boolean = false;
  user: any;
  userId: string;
  isAdmin: boolean;
  isAzureUser: boolean = false;
  displayName: string;
  azureUserFirstName: string;
  azureUserLastName: string;
  preferredLanguage: string; 
  propertiesChanged = new Subject<any>();
  selectedField = '';
  accountForm: UntypedFormGroup;
  organizationId: string;
  currentOrgName: string;
  subscriptionStatus: any;
  azureADConnect: boolean;
  azureProfilePresent: boolean;
  hasOwner: boolean;
  connectAzureAD: boolean;
  sourceType: any; 
  displayHint: boolean;
  changePassword = changePasswordUrl;
  isSocialAuthUser: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private http: HttpClient,
    private authUserService: UsersgroupsUsersService,
    private authApiService: AuthUserApiService,
    private subscriptionApiService: SubscriptionApiService,
    private authOrganizationService: AuthOrganizationApiService,
    private subscriptionService: SubscriptionService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserSettingsDialogComponent>
  ) {
    if (data && data.id) {
      this.organizationId = data.id;
      this.currentOrgName = data.name;
    } 
    this.accountForm = this.fb.group({
      organizationName: '',
      firstName: '',
      lastName: '',
      email: '',
      preferredLanguage: ''
    });
  }
  
   ngOnInit(): void {
    this.authUserService.getAuthMe().subscribe(async data => {
      this.userId = data.user_id;
      this.organizationId = data.organization_id;
      const userDetails = await lastValueFrom(this.authApiService.read(this.userId)); // // .toPromise();
      this.user = Object.assign({}, userDetails.result);
      this.isAdmin = this.user.role = this.user.roles && this.user.roles.includes('admin') ? true : false;
      this.isSocialAuthUser = this.user.id_profile && this.user.id_profile.connected_idp && this.user.id_profile.connected_idp.length == 1 && this.user.id_profile.connected_idp.includes('local') ? false : true;
      
      this.accountForm.controls['organizationName'].setValue(this.currentOrgName);
      if (this.isAdmin == false) this.accountForm.controls['organizationName'].disable();
      this.accountForm.controls['firstName'].setValue(this.user.first_name);
      if (this.isSocialAuthUser == true) this.accountForm.controls['firstName'].disable();
      this.accountForm.controls['lastName'].setValue(this.user.last_name);
      if (this.isSocialAuthUser == true) this.accountForm.controls['lastName'].disable();
      this.accountForm.controls['email'].setValue(this.user.email);
      this.accountForm.controls['email'].disable();
      this.accountForm.controls['preferredLanguage'].setValue(this.user.preferred_language);
    });
    
    this.authOrganizationService.get(this.organizationId).subscribe(data => {
      this.azureProfilePresent = (data.result.azure_profile != null || data.result.azure_profile != undefined) ? true : false;
      this.hasOwner = data.result.owner ? true : false; 
    });
    
    this.subscriptionService.getSubscription().subscribe(async (result) => {
      this.sourceType = result.source_type;
      this.azureADConnect = result.features && result.features.azure_ad_connect;
    });
    
    
    this.propertiesChanged
      .pipe(
        debounceTime(300))
      .subscribe(result => {
        let hasChanges = false;
        if (result.organization_name != null) {
          hasChanges = true;
          this.currentOrgName = result.organization_name;
        }
        if (result.first_name != null) {
          hasChanges = true;
          this.user.first_name = result.first_name;
        }
        if (result.last_name != null) {
          hasChanges = true;
          this.user.last_name = result.last_name;
        }
        if (result.email != null) {
          hasChanges = true;
          this.user.email = result.email;
        }
        // if (result.role) {
        //   hasChanges = true;
        //   if (result.role == "admin")
        //     this.user.roles.push("admin");
        //   else
        //     this.user.roles = this.user.roles.filter(x => x !== "admin");
        //   if (result.role == "user")
        //     this.user.roles.push("user");
        //   else
        //     this.user.roles = this.user.roles.filter(x => x !== "user")
        // }
        if (result.preferred_language) {
          hasChanges = true;
          if (result.preferred_language == 'de') {
this.user.preferred_language = 'de'; 
} else {
this.user.preferred_language = 'en'; 
}
        }
        if (hasChanges) {
          this.authUserService.draftUserDetails(this.user);
        }
      });
  }


  onOrganizationNameChange(value: string) {
    this.propertiesChanged.next({ organization_name : value });
  }

  onFirstNameChange(value: string) {
    this.propertiesChanged.next({ first_name: value });
  }

  onLastNameChange(value: string) {
    this.propertiesChanged.next({ last_name: value });
  }

  onEmailChange(value: string) {
    this.propertiesChanged.next({ email: value });
  }

  onPreferredLanguageChange(value: string) {
    this.propertiesChanged.next({ preferred_language: value});
  }

  onToggleAzureAD($event: MatSlideToggleChange) {
    this.connectAzureAD = $event.checked;
  }

  resetForm() {
    this.accountForm.controls['organizationName'].setValue(this.currentOrgName);
    this.accountForm.controls['firstName'].setValue(this.user.first_name);
    this.accountForm.controls['lastName'].setValue(this.user.last_name);
    this.accountForm.controls['email'].setValue(this.user.email);
    this.accountForm.controls['preferredLanguage'].setValue(this.user.preferred_language);  
  }

  done() {
    if (this.connectAzureAD == true && this.azureADConnect == true && !this.azureProfilePresent) {
      if (env == 'prod') {
        window.open('https://api2.ezeep.com/federated/azure/connect/', '_blank');
        } else {
        window.open(`https://api2.${env}.azdev.ezeep.com/federated/azure/connect/`, '_blank');
        }
  
    } 
    
    if (this.connectAzureAD == false && this.azureProfilePresent && this.hasOwner == false)  {
      if (env == 'prod') {
        return this.http.delete('https://api2.ezeep.com/federated/azure/api/sync/').subscribe(() => console.log('deleted'));
      } else {
        return this.http.delete(`https://api2.${env}.azdev.ezeep.com/federated/azure/api/sync/`).subscribe(() => console.log('deleted'));
      }
    }

    
    if (this.accountForm.dirty || this.accountForm.touched) {
      this.isLoading = true;
      this.authOrganizationService.patch(this.organizationId, { 'name': this.currentOrgName }).subscribe(
        () => {
           console.log('success');
        }
      );  
      this.authApiService.patch(this.user.id, this.user).subscribe(data => {
        setTimeout(() => 
        this.dialogRef.close(),
        3000);

        setTimeout(() =>
          document.location.reload(),
          1000);
      });
    }
  }
}
