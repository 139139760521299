import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ezd-subscription-azure',
  templateUrl: './subscription-azure.component.html',
  styleUrls: ['./subscription-azure.component.scss']
})
export class SubscriptionAzureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
