import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ezd-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss']
})
export class ExpansionPanelComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;

  isExpanded: boolean = false;

  onSelect() {
    this.isExpanded = !this.isExpanded;
  }

  constructor() {}

  ngOnInit() {}
}
