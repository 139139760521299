import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DriversService } from './printing-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DEFAULT_PAGE_SIZE } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})
export class NetworkPrintersManufacturersService {

  manufacturers = new BehaviorSubject<any>(null);
  manufacturersObject = new BehaviorSubject<any>(null);
  manufacturersModel = new BehaviorSubject<any>(null);
  manufacturersModelObject = new BehaviorSubject<any>(null);
  searchText: string;
  vendor: string;
  pageSize: number = DEFAULT_PAGE_SIZE;
  pageIndex: number = 0;
  models: any;
  modelsCount: number;
  MODEL_ORDER = 'name';

  constructor(
    private httpClient: HttpClient,
    private driversService: DriversService) {

    }

  public getManufacturers(): Observable<any> {
    this.driversService.vendors(undefined, undefined, undefined, undefined, 0, 0)
      .subscribe(response => {
        this.manufacturers.next([...response.result.results]);
        this.manufacturersObject.next([...response.result.results]);
      });
    return this.manufacturersObject.asObservable();
  }

  public setManufacturerModel(vendorName: string, isSearchText = false): Observable<any> {
    if (vendorName) {
      this.manufacturers
        .subscribe(result => {
          this.vendor = result.filter(m => m === vendorName)[0];
        });
    }
    this.manufacturersModelObject.next({isLoading: true, count: this.modelsCount, pageIndex: this.pageIndex,models: this.models});
    if(isSearchText) this.pageIndex = 0;
    this.driversService.list(this.vendor, undefined, this.searchText, this.MODEL_ORDER, this.pageSize, this.pageIndex * this.pageSize)
      .subscribe(response => {
        this.models = response.result.results;
        this.modelsCount = response.result.count;
        this.manufacturersModel.next([...response.result.results]);
        this.manufacturersModelObject.next({isLoading: false, count: this.modelsCount, pageIndex: this.pageIndex, models: [...response.result.results]});
      });
    return this.manufacturersModelObject.asObservable();
  }

  setManufacturersSearchText(searchValue): Observable<any> {
    let vendors = '';
    this.manufacturers
      .subscribe(result => {
        vendors = result.filter(v => v.toLowerCase().includes(searchValue.toLowerCase()));
        this.manufacturersObject.next(vendors);
        });
    return this.manufacturersObject.asObservable();
  }

  setManufacturersModelsSearchText(searchValue, isInitial = false) {
    this.searchText = searchValue != null ? searchValue.toLowerCase() : '';
    if (!isInitial) {
      this.setManufacturerModel(undefined, true);
    }
  }

  setPage(pageIndex: number, pageSize: number, isInitial = false) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    if (!isInitial) {
      this.setManufacturerModel(undefined);
    }
  }
}
