import { Component, Inject, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog'
import { Subject, Subscription, lastValueFrom } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { LocalPrinterSupportService } from '../../../modules/shared/services/local-printer-support.service'
import { PoliciesMyprintersService } from '../../../modules/shared/services/policies-myprinters.service';

@Component({
  selector: 'ezd-add-group-dialog',
  templateUrl: './add-group-dialog.component.html',
  styleUrls: ['./add-group-dialog.component.scss'],
})
export class AddGroupDialogComponent implements OnInit {
  searchTextChanged = new Subject<string>();
  isLoading: boolean = true
  localPrinterSupportServiceSubscription: Subscription;
  policiesMyPrintersServiceSubscription: Subscription;
  groups: any[] = []
  groupsFiltered: any[];
  selectedGroups: any;
  canAddGroups: boolean;
  endOfList: boolean = false;

  searchText: string;
  currentPageIndex: number = 0;

  constructor(
    public dialogRef: MatDialogRef<AddGroupDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,
    private localPrinterSupportService: LocalPrinterSupportService,
    private policiesMyPrintersService: PoliciesMyprintersService ) {}

  ngOnInit(): void {  
    if (this.data.type === 'LOCALPRINTERSUPPORT') {
      this.policiesMyPrintersServiceSubscription = this.policiesMyPrintersService.getNonMyPrintersGroups(this.searchText).subscribe(async result => {
        if (!result.isLoading && result.groups) {

        let ezeepGroups = await result.groups.filter(x => !x.local_printing_enabled)
        if (ezeepGroups && ezeepGroups.length > 0) {
          ezeepGroups.forEach(element => {
            element.source = 'EZEEP';
            this.groups.push(element);
          });
        }

          if (this.groups.length < 100) {
            if (!result.endOfList) {
              this.currentPageIndex += 1;
              this.policiesMyPrintersService.setNonMyPrintersGroups(this.searchText, 100, 100 * this.currentPageIndex).subscribe()
              return;
            } 
          }
        }

        this.isLoading = result.isLoading;
        this.endOfList = result.endOfList;
        if (this.endOfList) {
          this.currentPageIndex = 0;
        }
      })
    }

    if (this.data.type === 'MYPRINTERS') {
      this.policiesMyPrintersServiceSubscription = this.policiesMyPrintersService.getNonMyPrintersGroups(this.searchText).subscribe(async result => {
        if (!result.isLoading && result.groups) {

        let ezeepGroups = await result.groups.filter(x => !x.myprinters_enabled)
        if (ezeepGroups && ezeepGroups.length > 0) {
          ezeepGroups.forEach(element => {
            element.source = 'EZEEP';
            this.groups.push(element);
          });
        }

          if (this.groups.length < 100) {
            if (!result.endOfList) {
              this.currentPageIndex += 1;
              this.policiesMyPrintersService.setNonMyPrintersGroups(this.searchText, 100, 100 * this.currentPageIndex).subscribe()
              return;
            } 
          }
        }

        this.isLoading = result.isLoading;
        this.endOfList = result.endOfList;
        if (this.endOfList) {
          this.currentPageIndex = 0;
        }
      })
    }
    this.searchTextChanged
      .pipe(
        debounceTime(400),
        switchMap((result) => {
          if (this.data.type === 'MYPRINTERS') {
            this.searchText = result
            this.isLoading = true
            this.groups = []
            return this.policiesMyPrintersService.setNonMyPrintersGroups(result, 100, 0)
          }
          if (this.data.type === 'LOCALPRINTERSUPPORT') {
            this.searchText = result
            this.isLoading = true
            this.groups = []
            return this.policiesMyPrintersService.setNonMyPrintersGroups(result, 100, 0)
          }
        })
      )
      .subscribe()
    
  }

  ngOnDestroy(): void {
    if(this.localPrinterSupportServiceSubscription) {
      this.localPrinterSupportServiceSubscription.unsubscribe()
      this.groups = [];
      this.endOfList = false;
      this.isLoading = true;
    }
    if(this.policiesMyPrintersServiceSubscription) {
      this.policiesMyPrintersServiceSubscription.unsubscribe()
      this.groups = [];
      this.endOfList = false;
      this.isLoading = true;
    }  
  }

  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue);
  }

  onScroll() {
    if(this.endOfList == undefined || this.endOfList || this.isLoading) return;
    this.isLoading = true;
    this.currentPageIndex += 1
    this.policiesMyPrintersService.setNonMyPrintersGroups(this.searchText, 100, 100 * this.currentPageIndex).subscribe();
  }

  close() {
    this.dialogRef.close()
  }

  groupChanged(event, groups) {
    this.selectedGroups = groups.map(g => g.value);
    
    if(this.selectedGroups.length > 0) {
      this.canAddGroups = true;
    } else {
      this.canAddGroups = false;
    }
  }
  
  add() {
    if (this.data.type === 'LOCALPRINTERSUPPORT') {
      this.localPrinterSupportService.addPrintersServiceGroups(this.selectedGroups)
        .subscribe(result => {
          if (result.isLoading === true) {
            this.localPrinterSupportService.setAllLocalSupportGroups()
            this.dialogRef.close()
          } else {
            this.isLoading = true;
          }
        });
    }
    if (this.data.type === 'MYPRINTERS') {
      this.policiesMyPrintersService.addPrintersServiceGroups(this.selectedGroups)
        .subscribe(result => {
          if (result.isLoading === false) {
            this.dialogRef.close()
          } else {
            this.isLoading = true;
          }
        });
    }
  }
}
