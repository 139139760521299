import { Injectable } from '@angular/core';
import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from '../../../app.config';
import { TranslateService } from '@ngx-translate/core';
import { StorageUtils } from '../../storage/utils/storage.utils';
import { LANGUAGE_KEY  } from '../../storage/constants/storage.constants';
import { AuthUserApiService } from './auth-api.service';
import { BehaviorSubject } from 'rxjs';


@Injectable()
export class LangaugeService {

  private stringSubject$ = new BehaviorSubject<any>('');
  getPreferredLang$ = this.stringSubject$.asObservable();

  private hideInviteUsersDialog$ = new BehaviorSubject<boolean>(false);
  getHideInviteUsersDialogValue$ = this.hideInviteUsersDialog$.asObservable();

  private hideMyPrintersDialog$ = new BehaviorSubject<boolean>(false);
  getHideMyPrintersDialogValue$ = this.hideMyPrintersDialog$.asObservable();

  constructor(private translateService: TranslateService,
    private authApiService: AuthUserApiService
    ) {}

  public get language(): string {
    let language = StorageUtils.get(localStorage, LANGUAGE_KEY);
    if (!language) {
      language = this.getBrowserLanguage();
      if (!this.availableLanguages.includes(language)) {
        language = this.defaultLanguage;
      }
    }
    return language;
  }
  public set language(value: string) {
    StorageUtils.set(localStorage, LANGUAGE_KEY, value);
    this.translateService.use(value);
  }

  public get availableLanguages(): Array<string> {
    return AVAILABLE_LANGUAGES;
  }

  public get defaultLanguage(): string {
    return DEFAULT_LANGUAGE;
  }

  public getBrowserLanguage(): string {
    const selectedLanguage: string = navigator.language.split('-')[0];
    return selectedLanguage;
  }

  setLanguage(data: any) {
    this.stringSubject$.next(data);
  }  

  setHideDialogValue(data: boolean) {
    this.hideInviteUsersDialog$.next(data);
  } 

  setHideMyPrintersDialogValue(data: boolean) {
    this.hideMyPrintersDialog$.next(data);
  }
}
