<h2 mat-dialog-title id="user-information-text">{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_INFORMATION' | translate }}</h2>
<div class="ezp-dialog__controls">
  <button 
    *ngIf="!isAdmin"
    mat-icon-button
    id="user-detail-menu"
    class="ezp-dialog__controls__action"
    [matMenuTriggerFor]="userDetailsMenu"
    >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #userDetailsMenu="matMenu" xPosition="before" yPosition="below">
    <button mat-menu-item id="open-removeuser-dialog" class="ezp-menu__item ezp-menu__item--destructive"
    (click)="openRemoveUserDialog()"> 
      {{ 'USERSGROUPS.USERS.USER_DETAILS.REMOVE_USER' | translate }}
    </button>
  </mat-menu>
</div>
<div mat-dialog-content>
  <mat-tab-group class="ezp-tabs" animationDuration="0ms">
    <mat-tab label="Information">
      <ng-template mat-tab-label>
        <mat-icon>info</mat-icon>
        <span id="user-information-tab-text">{{ 'USERSGROUPS.USERS.USER_DETAILS.INFORMATION' | translate }}</span>
      </ng-template>
      <div class="tab-content">
        <ezd-user-details-information></ezd-user-details-information>
      </div>
    </mat-tab>
    <mat-tab label="Groups" *ngIf="groupsEnabled">
      <ng-template mat-tab-label>
        <mat-icon>group</mat-icon>
        <span id="user-groups-tab-text">{{ 'USERSGROUPS.USERS.USER_DETAILS.GROUPS' | translate }}</span>
      </ng-template>
      <div class="tab-content">
        <ezd-user-details-groups></ezd-user-details-groups>
      </div>
    </mat-tab>
    <mat-tab label="Printers">
      <ng-template mat-tab-label>
        <mat-icon>print</mat-icon>
        <span id="user-printers-tab-text">{{ 'USERSGROUPS.USERS.USER_DETAILS.PRINTERS' | translate }}</span>
      </ng-template>
      <div class="tab-content">
        <ezd-user-details-printers></ezd-user-details-printers>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions>
  <button mat-button id="cancel-user-details" (click)="close()" color="primary" class="ezp-button" [disabled]="isLoading">
    {{ 'CANCEL' | translate }}
  </button>
  <button mat-button id="done-user-details" (click)="done()" color="primary" class="ezp-button" [disabled]="isLoading">
    <mat-icon [ngClass]="{ 'fa-spin': isLoading }" *ngIf="isLoading">cached</mat-icon>
    {{ 'DONE' | translate }}
  </button>
</div>
