import { mergeMap as _observableMergeMap, catchError as _observableCatch, map } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { RequesterApiService, ApiResponse, ApiResult, ListResult, authApiUrl } from './requester-api.service';

export interface IAuthUserApiService {
  list(q: string | null | undefined, o: string | null | undefined, limit: number | null | undefined, offset: number | null | undefined): Observable<ApiResponse<ListResult<AuthUser>>>;
  read(id: string): Observable<ApiResponse<AuthUser>>;
  patch(id: string, data: AuthUser): Observable<ApiResponse<AuthUser>>;
  delete(id: string): Observable<ApiResponse<void>>;
  me(): Observable<ApiResponse<AuthMe>>;
}

@Injectable({
  providedIn: 'root'
})
export class AuthUserApiService extends RequesterApiService implements IAuthUserApiService {
  httpClient: any;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(authApiUrl) baseUrl?: string) {
    super(http, baseUrl ? baseUrl : '');
  }

  list(q: string | null | undefined, o: string | null | undefined, limit: number | null | undefined, offset: number | null | undefined): Observable<ApiResponse<ListResult<AuthUser>>> {
    let url_ = this.baseUrl + '/v1/users/?';
    if (q !== undefined) {
url_ += 'q=' + encodeURIComponent('' + q) + '&'; 
}
    if (o !== undefined) {
url_ += 'o=' + encodeURIComponent('' + o) + '&'; 
}
    if (limit !== undefined) {
url_ += 'limit=' + encodeURIComponent('' + limit) + '&'; 
}
    if (offset !== undefined) {
url_ += 'offset=' + encodeURIComponent('' + offset) + '&'; 
}
    url_ = url_.replace(/[?&]$/, '');

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };

    return this.processListRequest('get', url_, options_, AuthUser)
      .pipe(map(r => {
        r.result.searchText = q;
        return r;
      }));
  }

  read(id: string): Observable<ApiResponse<AuthUser>> {
    let url_ = this.baseUrl + '/v1/users/{id}/';
    if (id === undefined || id === null) {
throw new Error('The parameter \'id\' must be defined.'); 
}
    url_ = url_.replace('{id}', encodeURIComponent('' + id));
    url_ = url_.replace(/[?&]$/, '');

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };

    return this.processRequest('get', url_, options_, AuthUser);
  }

  patch(id: string, data: AuthUser): Observable<ApiResponse<AuthUser>> {
    let url_ = this.baseUrl + '/v1/users/{id}/';
    if (id === undefined || id === null) {
throw new Error('The parameter \'id\' must be defined.'); 
}
    url_ = url_.replace('{id}', encodeURIComponent('' + id));
    url_ = url_.replace(/[?&]$/, '');

    const content_ = JSON.stringify(data);

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };

    return this.processRequest('patch', url_, options_, AuthUser);
  }


  listMany(user_ids: string[]): Observable<ApiResponse<ListResult<AuthUser>>> {
    let url_ = this.baseUrl + '/v1/users/search/';
    if (user_ids === null || user_ids.length == 0) {
throw new Error('The parameter \'user_id\' must be defined.'); 
}
    url_ = url_.replace(/[?&]$/, '');

    const content_ = JSON.stringify({ user_ids });

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };

    return this.processListRequest('post', url_, options_, AuthUser);
  }

  delete(id: string): Observable<ApiResponse<void>> {
    let url_ = this.baseUrl + '/v1/users/{id}/';
    if (id === undefined || id === null) {
throw new Error('The parameter \'id\' must be defined.'); 
}
    url_ = url_.replace('{id}', encodeURIComponent('' + id));
    url_ = url_.replace(/[?&]$/, '');

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
      })
    };

    return this.processRequest<null>('delete', url_, options_, null);
  }

  me(): Observable<ApiResponse<AuthMe>> {
    let url_ = this.baseUrl + '/auth/me/';
    url_ = url_.replace(/[?&]$/, '');

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };

    return this.processRequest('get', url_, options_, AuthMe);
  }

  patchPreferredLanguage(id: string, data: string): Observable<ApiResponse<AuthUser>> {
    let url_ = this.baseUrl + '/v1/users/{id}/';
    if (id === undefined || id === null) {
throw new Error('The parameter \'id\' must be defined.'); 
}
    url_ = url_.replace('{id}', encodeURIComponent('' + id));
    url_ = url_.replace(/[?&]$/, '');

    const content_ = JSON.stringify({ preferred_language: data });
    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };
    return this.processRequest('patch', url_, options_, AuthUser);
  }
}

export class AuthUser extends ApiResult implements IAuthUser {
  id?: string | undefined;
  username?: string | undefined;
  email?: string | undefined;
  first_name?: string | undefined;
  last_name?: string | undefined;
  display_name?: string | undefined;
  date_joined?: string | undefined;
  azure_profile?: {
    tid?: string | undefined;
    oid?: string | undefined;
    business_phones?: any;
    mobile_phone?: string | undefined;
    display_name?: string | undefined;
    job_title?: string | undefined;
    office_location?: string | undefined;
    preferred_language?: string | undefined;
    user_principal_name?: string | undefined;
  } | undefined;
  id_profile?: {
    oid?: string | undefined;
    preferred_username?: string | undefined;
    name?: string | undefined;
    connected_idp?: string[] | undefined;
  } | undefined;
  is_verified?: any;
  preferred_language?: string | undefined;
  user_invitations?: [{
    id?: string | undefined;
    updated?: string | undefined;
    email?: string | undefined;
    expire_date?: string | undefined;
    delivery_status?: string | undefined;
    invitation_status?: string | undefined;
  }]
    | undefined;
  roles?: any;
  billing_code?: any;
  status?: any;
  source?: string;

  constructor(data?: IAuthUser) {
    super();
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
(<any>this)[property] = (<any>data)[property]; 
}
      }
    }
  }

  init(data?: any) {
    if (data) {
      (<any>this).id = data['id'];
      (<any>this).username = data['username'];
      (<any>this).email = data['email'];
      (<any>this).first_name = data['first_name'];
      (<any>this).last_name = data['last_name'];
      (<any>this).display_name = data['display_name'];
      (<any>this).azure_profile = data['azure_profile'];
      (<any>this).id_profile = data['id_profile'];
      (<any>this).date_joined = data['date_joined'];
      (<any>this).roles = data['roles'];
      (<any>this).billing_code = data['billing_code'];
      (<any>this).is_verified = data['is_verified'];
      (<any>this).preferred_language = data['preferred_language'];
      if (data['user_invitations']) {
        // it is an array; an user can have many user invitations, but it can have only one user invitation by organization
        (<any>this).user_invitations = data['user_invitations'];
      }
      // if(data["roles"]){
      //   (<any>this).roles = data["roles"];
      // }
    }
  }

  fromJS(data: any): AuthUser {
    data = typeof data === 'object' ? data : {};
    const result = new AuthUser();
    result.init(data);
    return result;
  }
}

export interface IAuthUser {
  id?: string | undefined;
  username?: string | undefined;
  email?: string | undefined;
  first_name?: string | undefined;
  last_name?: string | undefined;
  display_name?: string | undefined;
  date_joined?: string | undefined;
  azure_profile?: {
    tid?: string | undefined;
    oid?: string | undefined;
    business_phones?: any;
    mobile_phone?: string | undefined;
    display_name?: string | undefined;
    job_title?: string | undefined;
    office_location?: string | undefined;
    preferred_language?: string | undefined;
    user_principal_name?: string | undefined;
  } | undefined;
  id_profile?: {
    oid?: string | undefined;
    preferred_username?: string | undefined;
    name?: string | undefined;
    connected_idp?: string[] | undefined;
  } | undefined;
  is_verified?: any;
  preferred_language?: string | undefined;
  user_invitations?: [{
    id?: string | undefined;
    updated?: string | undefined;
    email?: string | undefined;
    expire_date?: string | undefined;
    delivery_status?: string | undefined;
    invitation_status?: string | undefined;
  }] | undefined;
  roles?: any;
  billing_code?: any;
  status?: any;
}

export class AuthMe extends ApiResult implements IAuthMe {
  user_id?: string | undefined;
  username?: string | undefined;
  organization_id?: string | undefined;
  roles?: string[] | undefined;

  constructor(data?: IAuthMe) {
    super();
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
(<any>this)[property] = (<any>data)[property]; 
}
      }
    }
  }

  init(data?: any) {
    if (data) {
      (<any>this).user_id = data['user_id'];
      (<any>this).username = data['username'];
      (<any>this).organization_id = data['organization_id'];
      (<any>this).roles = data['roles'];
    }
  }

  fromJS(data: any): AuthMe {
    data = typeof data === 'object' ? data : {};
    const result = new AuthMe();
    result.init(data);
    return result;
  }
}

export interface IAuthMe {
  user_id?: string | undefined;
  username?: string | undefined;
  organization_id?: string | undefined;
  is_verified?: any;
  roles?: string[] | undefined;
}

export interface IAuthUserInvitationApiService {
  send(data: AuthUserInvitation[]): Observable<ApiResponse<void>>;
  resend(data: AuthUserInvitation[]): Observable<ApiResponse<void>>;
}

@Injectable({
  providedIn: 'root'
})
export class AuthUserInvitationApiService extends RequesterApiService implements IAuthUserInvitationApiService {

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(authApiUrl) baseUrl?: string) {
    super(http, baseUrl ? baseUrl : '');
  }

  send(data: AuthUserInvitation[]): Observable<ApiResponse<void>> {
    let url_ = this.baseUrl + '/v1/userinvitations/';
    url_ = url_.replace(/[?&]$/, '');

    const content_ = JSON.stringify(data);

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };

    return this.processRequest<null>('post', url_, options_, null);
  }

  resend(data: AuthUserInvitation[]): Observable<ApiResponse<void>> {
    const content_ = data.length == 1 ? JSON.stringify(data[0]) : JSON.stringify(data);
    // const content_ = JSON.stringify(data);

    let url_ = this.baseUrl + '/v1/userinvitations/';
    if (data.length == 1) {
url_ += data[0].id + '/';
}
    url_ = url_.replace(/[?&]$/, '');

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };

    return this.processRequest<null>('patch', url_, options_, null);
  }

}

export class AuthUserInvitation extends ApiResult implements IAuthUserInvitation {
  id?: string | undefined;
  email?: string | undefined;
  expire_date?: string | undefined;
  user?: { preferred_language: string | undefined } | undefined;

  constructor(data?: IAuthUserInvitation) {
    super();
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
(<any>this)[property] = (<any>data)[property]; 
}
      }
    }
  }

  init(data?: any) {
    if (data) {
      (<any>this).id = data['id'];
      (<any>this).email = data['email'];
      (<any>this).expire_date = data['expire_date'];
      (<any>this).user = data['user'];
    }
  }

  fromJS(data: any): AuthUserInvitation {
    data = typeof data === 'object' ? data : {};
    const result = new AuthUserInvitation();
    result.init(data);
    return result;
  }
}

export interface IAuthUserInvitation {
  id?: string | undefined;
  email?: string | undefined;
  expire_date?: string | undefined;
  user?: { preferred_language: string | undefined } | undefined;
}


export interface IAuthOrganizationApiService {
  get(id: string): Observable<ApiResponse<AuthOrganization>>;
  list(limit: number | null | undefined, offset: number | null | undefined): Observable<ApiResponse<ListResult<AuthOrganization>>>;
}

@Injectable({
  providedIn: 'root'
})
export class AuthOrganizationApiService extends RequesterApiService implements IAuthOrganizationApiService {

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(authApiUrl) baseUrl?: string) {
    super(http, baseUrl ? baseUrl : '');
  }

  list(limit: number | null | undefined, offset: number | null | undefined): Observable<ApiResponse<ListResult<AuthOrganization>>> {
    let url_ = this.baseUrl + '/v1/organizations/?';
    if (limit !== undefined) {
url_ += 'limit=' + encodeURIComponent('' + limit) + '&'; 
}
    if (offset !== undefined) {
url_ += 'offset=' + encodeURIComponent('' + offset) + '&'; 
}
    url_ = url_.replace(/[?&]$/, '');

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };

    return this.processListRequest('get', url_, options_, AuthOrganization);
  }

  get(id: string): Observable<ApiResponse<AuthOrganization>> {
    let url_ = this.baseUrl + '/v1/organizations/{id}/';
    if (id === undefined || id === null) {
throw new Error('The parameter \'id\' must be defined.'); 
}
    url_ = url_.replace('{id}', encodeURIComponent('' + id));
    url_ = url_.replace(/[?&]$/, '');

    const options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };

    return this.processRequest('get', url_, options_, AuthOrganization);
  }

  patch(id: string, name: any): Observable<ApiResponse<AuthOrganization>> {
    let url_ = this.baseUrl + '/v1/organizations/{id}/';
    if (id === undefined || id === null) {
throw new Error('The parameter \'id\' must be defined.'); 
}
    url_ = url_.replace('{id}', encodeURIComponent('' + id));
    url_ = url_.replace(/[?&]$/, '');

    const content_ = JSON.stringify(name);

    const options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };

    return this.processRequest('patch', url_, options_, null);
  }
}

export class AuthOrganization extends ApiResult implements IAuthOrganization {
  id?: string | undefined;
  name?: string | undefined;
  profile?: any | undefined;
  azure_profile?: { tid: string | undefined } | undefined;
  owner?: any | undefined;

  constructor(data?: IAuthOrganization) {
    super();
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
(<any>this)[property] = (<any>data)[property]; 
}
      }
    }
  }

  init(data?: any) {
    if (data) {
      (<any>this).id = data['id'];
      (<any>this).name = data['name'];
      (<any>this).profile = data['profile'];
      (<any>this).azure_profile = data['azure_profile'];
      (<any>this).owner = data['owner'];
    }
  }

  fromJS(data: any): AuthOrganization {
    data = typeof data === 'object' ? data : {};
    const result = new AuthOrganization();
    result.init(data);
    return result;
  }
}

export interface IAuthOrganization {
  id?: string | undefined;
  name?: string | undefined;
  profile?: any | undefined;
  azure_profile?: { tid: string | undefined } | undefined;
  owner?: any | undefined;
}
export const URL_REGEX = new RegExp('(^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?)|(<a\\s+href="([^"]+)?"><\\/a>)');
