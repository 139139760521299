import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, observable, from, of, lastValueFrom } from 'rxjs';
import {  GroupsService, Group } from './printing-api.service';
import { mergeMap, last, map, concatMap, toArray } from 'rxjs/operators';
import { ApiResponse } from './requester-api.service';
import { DEFAULT_PAGE_SIZE } from '../../../app.config';
import { GeneralUtil } from '../utils/general-util';
import { AuthenticationService } from '../../authentication/services/authentication.service';
import { AdconGroup, AdconGroupsService, AdconTenantGroups } from './adcon-api.service';
import { AuthOrganizationApiService, AuthUserApiService } from './auth-api.service';

@Injectable({
  providedIn: 'root'
})
export class UsersgroupsGroupsService {
  groupsSubject = new BehaviorSubject<any>({ isLoading: true, count: 0, groups: [] });
  adconServiceGroupsSubject = new BehaviorSubject<any>({ isLoading: false, count: 0, groups: []});
  adconServiceSubject = new BehaviorSubject<any>({ isLoading: true});
  pageIndex: number = 0;
  pageSize: number = DEFAULT_PAGE_SIZE;
  sorting: any;
  groupsCount: any = 0;
  groups: any = [];
  groupEdited: any;
  groupCreated: any;
  searchText: string;
  adconServiceGroups: AdconGroup[];
  adconServiceTenantId: string;
  adconServiceGroupsCount: number;
  
  constructor(private groupsService: GroupsService,
    private adconGroupsService: AdconGroupsService) { 
  }

  getFilteredGroups() : Observable<any> {
    return this.groupsSubject.asObservable();
  }

  applyFilters(showLoading:boolean = true) {
    const searchText = this.searchText
    this.groupsSubject.next({isLoading: showLoading, count: this.groupsCount, groups: [], pageSize: this.pageSize, pageIndex: this.pageIndex });
    //missing filters
    this.groupsService.list(undefined, undefined, undefined, undefined, searchText, this.sorting, this.pageSize, this.pageIndex * this.pageSize).subscribe({
      next: response => {
        if (searchText !== response.result.searchText) return //block older searches to show up
        this.groups = response.result.results;
        this.groupsCount = response.result.count;
        this.groupsSubject.next({
          isLoading: false,
          count: response.result.count,
          groups: response.result.results,
          pageSize: this.pageSize,
          pageIndex: this.pageIndex
        });
      }, error: error => {
        this.groupsSubject.next({ isLoading: false, count: this.groupsCount, groups: this.groups, pageSize: this.pageSize, pageIndex: this.pageIndex });
      }
    });    
  }
  
  unselectGroups(groups: any[]) {
    this.groups.forEach(item => {
      if(groups){
        item.isSelected = groups.includes(item) ? false : item.isSelected;
      } else {
        item.isSelected = false;
      }
    });
    this.groupsSubject.next({isLoading: false, count: this.groupsCount, groups: this.groups});
  }

  selectGroups(groups: any[]) {
    this.groups.forEach(item => {
      if (groups) {
        item.isSelected = groups.includes(item) ? true : item.isSelected
      }
    });
    this.groupsSubject.next({isLoading: false, count: this.groupsCount, groups: this.groups});
  }
  
  setPage(pageIndex: number, pageSize: number, isInitial = false) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    if (!isInitial) {
      this.applyFilters(true);
    }
  }

  setSorting(sorting) {
    this.sorting = sorting;
    this.applyFilters(false);
  }
  
  getSelectedGroups(): any[] {
    return this.groups.filter(group => group.isSelected);
  }

  getSelectedGroupsCount(): number {
    return this.getSelectedGroups().length;
  }

  draftGroupDetails(group: any, newGroup: boolean) {
    if (newGroup)
      this.groupCreated = group;
    else
      this.groupEdited = group;
  }

  editGroup(id: string) {
    if (id)
      this.groups.map(item => {
        item.toEdit = item.id == id ? true : false
        if(!this.groupEdited && item.toEdit)
          this.groupEdited = item;
      });
    else
      this.groups.map(item => {
        item.toEdit = item.isSelected ? true : false
        if(!this.groupEdited && item.toEdit)
          this.groupEdited = item;
      });

    this.groupsSubject.next({isLoading: false, count: this.groupsCount, groups: this.groups});
  }

  public getGroupToEdit(): any {
    if(!this.groups) return null;

    let group = this.groups.filter(group => group.toEdit)[0];

    return group;
  }

  createGroup() {
    this.groupCreated = {id: "NEWGROUP", name: "", description: "", local_printing_enabled: false, toCreate: true};
    this.groupsSubject.next({isLoading: false, count: this.groupsCount, groups: [...this.groups]});
  }

  public getGroupToCreate(): any {
    if(!this.groups) return null;

    return this.groupCreated;
  }

  cancelGroupToEdit() {
    this.groups.map(item => {
      item.toEdit = false
      item.toCreate = false
    });
    this.groupEdited = undefined
    this.groupCreated = undefined
  }

  saveGroup(): Observable<any> {
    if (this.groupEdited) {
      return this.updateGroup(this.groupEdited)
        .pipe(map(response => {
          return response.result;
        }));
    }

    if (this.groupCreated) {
      return this.groupsService.create(this.groupCreated)
        .pipe(map(response => {
          return response.result;
        }));
    }
  }

  removeGroup(id:string) : Observable<any> {
    return this.groupsService.delete(id);
  }
  
  removeGroups() {
    from(this.getSelectedGroups().map(x => x.id)).pipe(mergeMap(async (id: any) => {
      await lastValueFrom(this.groupsService.delete(id)) // .toPromise();
    }))
    .pipe(last())
    .subscribe(async () => {
      this.applyFilters();
    });
  }

  updateGroup(group:any) : Observable<ApiResponse<Group>> {
    return this.groupsService.update(group, group.id);
  }

  setSearchText(result: string) {
    this.searchText = result
    this.pageIndex = 0
  }
  
  public getAdconServiceGroups(): Observable<any> {
    this.setAdconServiceGroups();
    return this.adconServiceGroupsSubject.asObservable();
  }

  setAdconServiceGroups() {
    this.adconServiceGroupsSubject.next({ isLoading: true, groups: [] });
    this.adconGroupsService.get()
      .subscribe({
        next: async response => {
          this.adconServiceGroups = [...response.result.value];
          this.adconServiceTenantId = response.result.tenantId;
          this.adconServiceGroupsSubject.next({ isLoading: false, count: this.adconServiceGroups.length, groups: [...this.adconServiceGroups] });
        }, error: error => {
          this.adconServiceGroupsSubject.next({ isLoading: false, count: this.adconServiceGroups.length, groups: [...this.adconServiceGroups] });
        }
      });
  }

  getGroups(foreign_ids:string[]) : Observable<any> {
    return this.groupsService.list(undefined, undefined, undefined, foreign_ids.join('|'), undefined, undefined, foreign_ids.length, 0)
      .pipe(map(response => {
        return response.result.results;
      }));
  }
  
  addPrintersServiceGroups(selectedGroups: any) : Observable<any> {
    const currentGroups: AdconTenantGroups = new AdconTenantGroups({
      value: [],
      tenantId: this.adconServiceTenantId
    });
    selectedGroups.forEach( group => {
      if (group.source == 'EZEEP') return

      let adconGroup = new AdconGroup({id: group.id, displayName: group.displayName})
      currentGroups.value.push(adconGroup);
    });
    
    if (currentGroups.value.length > 0) {
      this.adconServiceSubject.next({isLoading: true})
      this.adconGroupsService.post(currentGroups)
        .subscribe(async response => {
          if (response.status === 201) {
            if (response.result.value.length > 0) {
              await GeneralUtil.sleep(600);
              const azureIds = response.result.value.map(v => v.id);
              await lastValueFrom(this.getGroupsByForeignId(azureIds)) // .toPromise()
              this.adconServiceSubject.next({isLoading: false})
            }
          }
        });
    }

    return this.adconServiceSubject.asObservable();
  }
  
  getGroupsByForeignId(foreign_id:string[]) : Observable<any> {
    let foreign_ids = GeneralUtil.chunck(foreign_id, 80);
    return from(foreign_ids)
      .pipe(concatMap(async (ids: any) => {
        let result = []
        let tries = 0
        do {
          result = await lastValueFrom(this.groupsService.list(undefined, 'false', undefined, ids.join('|'), undefined, undefined, foreign_id.length, 0)
          .pipe(map(response => {
            return response.result.results;
          }))) // .toPromise();
          tries++
        } while(result.length == 0 && tries < 3)

        return result;
      }))
      .pipe(toArray())
      .pipe(map(r => [].concat(...r)))
  }
}
