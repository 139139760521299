<mat-form-field
  class="ezp-form-field ezp-form-field--full ezp-form-field--densed"
  id="search-groups"
  appearance="fill"
>
  <mat-icon matPrefix>search</mat-icon>
  <input
    #searchGroupsList
    type="text"
    matInput
    id="search-groups-input"
    (input)="onSearchChange($event.target.value)"
  />
  <mat-hint align="start" id="search-groups-hint">{{
    'USERSGROUPS.GROUPS.SEARCH_GROUPS.HINT' | translate
  }}</mat-hint>
</mat-form-field>
