<h2 mat-dialog-title>{{ 'VERIFY_EMAIL.FAILED.TITLE' | translate }}</h2>
<div mat-dialog-content>
  <p class="mat-body-1">{{ 'VERIFY_EMAIL.FAILED.DESCRIPTION' | translate }}</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="close()" color="primary" class="ezp-button">
    {{ 'VERIFY_EMAIL.CLOSE' | translate }}
  </button>
  <button mat-button (click)="resendEmail()" color="primary" class="ezp-button">
    {{ 'VERIFY_EMAIL.RESEND' | translate }}
  </button>
</div>
