import { Component, EventEmitter, Inject, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Webhook } from '../../../../../modules/shared/services/webhooks-api.service';
import { WebhooksService } from '../../../../../modules/shared/services/webhooks.service';
import { debounceTime } from 'rxjs';
import { urlValidator } from './urlValidator';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { triggers } from '../../constants';
@Component({
  selector: 'ezd-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss']
})
export class PropertiesComponent implements OnInit {

  form: FormGroup;
  triggerOptions: string[] = triggers;
  webhook: Webhook
  @Output() isFormValidEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private formBuilder: FormBuilder, private webhookService: WebhooksService, @Optional() @Inject(MAT_DIALOG_DATA) public dialogueData: any,
  private clipboard: Clipboard) {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required, Validators.min(1)]],
      topics: [[]],
      url: [null, [Validators.required, urlValidator()]],
      authentication_scheme: [false],
      basic_username: [{ value: "", disabled: true }],
      basic_password: [{ value: "", disabled: true }]
    });

  }

  ngOnInit(): void {
    this.handleToggleChange();
    this.formChanges();
    if (this.dialogueData.webhook) {
      this.patchFormValues()
      this.webhookService.setEditMode();
    }
  }

  patchFormValues() {
    this.form.patchValue(this.dialogueData.webhook)
  }
  
  handleToggleChange() {
    const usernameControl = this.form.get('basic_username');
    const passwordControl = this.form.get('basic_password');
    const authenticationSchemeControl = this.form.get('authentication_scheme');
  
    const enableAuthenticationControls = () => {
      usernameControl.enable();
      passwordControl.enable();
      usernameControl.setValidators([Validators.required, Validators.pattern('^[^:]+$')]);
      passwordControl.setValidators(Validators.required);
      usernameControl.updateValueAndValidity();
      passwordControl.updateValueAndValidity();
    };
  
    const disableAuthenticationControls = () => {
      usernameControl.disable();
      passwordControl.disable();
      usernameControl.clearValidators();
      passwordControl.clearValidators();
      usernameControl.reset();
      passwordControl.reset();
      usernameControl.updateValueAndValidity();
      passwordControl.updateValueAndValidity();
    };
  
    authenticationSchemeControl.valueChanges.subscribe((value) => {
      if (value) {
        enableAuthenticationControls();
      } else {
        disableAuthenticationControls();
      }
    });
  
    usernameControl.valueChanges.subscribe((value) => {
      if (value) {
        passwordControl.clearValidators();
        passwordControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      } else {
        passwordControl.setValidators(Validators.required);
        passwordControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
    });
  
    passwordControl.valueChanges.subscribe((value) => {
      if (value) {
        usernameControl.clearValidators();
        usernameControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      } else {
        usernameControl.setValidators([Validators.required, Validators.pattern('^[^:]+$')]);
        usernameControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
      }
    });
  }
  

  formChanges() {
    this.form.valueChanges
      .pipe(debounceTime(500))
      .subscribe(formData => {
        this.isFormValidEvent.emit(this.form.valid)
        formData.authentication_scheme = formData.authentication_scheme ? 'basic' : null
        if (this.dialogueData.webhook)
          this.webhookService.draftWebhookDetails({
            ...formData,
            disabled: this.dialogueData.webhook.disabled,
            id: this.dialogueData.webhook.endpoint_id,
            last_success: this.dialogueData.webhook.last_success,
          });
        else
          this.webhookService.draftWebhookDetails(formData)
      })
  }

  copyWebhookId(){
    this.clipboard.copy(this.dialogueData.webhook.endpoint_id)
  }
}
