import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'

@Component({
  selector: 'ezd-quickstart-myprinters-dialog',
  templateUrl: './quickstart-myprinters-dialog.component.html',
  styleUrls: ['./quickstart-myprinters-dialog.component.scss']
})

export class QuickstartMyprintersDialogComponent implements OnInit {

  constructor(private router: Router,
    public dialogRef: MatDialogRef<QuickstartMyprintersDialogComponent>) {}

  ngOnInit(): void {
  }

  routeToQuickstart() {
    this.dialogRef.close();
    localStorage.setItem('cardCount', '2')
    this.router.navigate(['quickstart']);
  }

  close() {
    this.dialogRef.close();
    localStorage.setItem('cardCount', '1')
  }
}
