import { Component, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { MyprintersService } from '../../../../modules/shared/services/myprinters.service'
import { HIDE_OVERLAY_MYPRINTERS } from '../../../../modules/storage/constants/storage.constants'
import { StorageUtils } from '../../../../modules/storage/utils/storage.utils'
import { Router } from '@angular/router'

@Component({
  selector: 'ezd-welcome-dialog',
  templateUrl: './welcome-dialog.component.html',
  styleUrls: ['./welcome-dialog.component.scss'],
})
export class WelcomeDialogComponent implements OnInit {
  dontShowAgain = false
  static dialogIsOpen = false;

  constructor(
    public dialogRef: MatDialogRef<WelcomeDialogComponent>,
    private myprintersService: MyprintersService,
    private router: Router
  ) {}

  ngOnInit(): void {
    WelcomeDialogComponent.dialogIsOpen = true;
  }

  ngOnDestroy(): void {
    WelcomeDialogComponent.dialogIsOpen = false;
  }

  close() {
    StorageUtils.set(sessionStorage, HIDE_OVERLAY_MYPRINTERS, true)
    this.dialogRef.close()
  }

  toggleShowAgain() {
    this.dontShowAgain = !this.dontShowAgain
    this.myprintersService.turnOffShowWelcomeOverlay(this.dontShowAgain)
  }

  goToDownloads() {
    this.dialogRef.close()
    this.router.navigate(['downloads'])
  }
}
