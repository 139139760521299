<h2 mat-dialog-title id="test-run-text">{{ 'SETTINGS_PAGE.WEBHOOKS.TEST_RUN.TITLE' | translate }}</h2>

<div mat-dialog-content>
    <div class="ezp-grid ezp-grid--gutter ezp-grid--spacing-vertical" data-grid-cols="2">
        <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
            <mat-label id="test-run-triggers-text">{{ 'SETTINGS_PAGE.WEBHOOKS.TEST_RUN.CHOOSE_EVENT' | translate
                }}</mat-label>
            <mat-select id="utest-run-trigger" [(ngModel)]="topic">
                <mat-option id="test-run-trigger-value" [value]="triggerOptions[0]">{{
                    'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.TOPICS.PRINTJOB_SUCCEEDED' |
                    translate }}</mat-option>
                <mat-option id="test-run-trigger-value" [value]="triggerOptions[1]">{{
                    'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.TOPICS.PRINTJOB_FAILED' | translate
                    }}</mat-option>
            </mat-select>
        </mat-form-field>
        <button [disabled]="!topic || isLoading" mat-raised-button color="primary" class="section__button ezp-button"
            (click)="testURL()">
            <mat-icon [ngClass]="{ 'fa-spin': isLoading }">{{ !isLoading ? '' : 'cached' }}</mat-icon>
            {{'SETTINGS_PAGE.WEBHOOKS.TEST_RUN.TEST_BUTTON' | translate }}</button>
    </div>

    <div *ngIf="result">
        <h3 mat-dialog-title id="test-run-text-status">{{ 'SETTINGS_PAGE.WEBHOOKS.TEST_RUN.STATUS' | translate }} :
            {{result.status}}</h3>
        <h3 mat-dialog-title id="test-run-text-request">{{ 'SETTINGS_PAGE.WEBHOOKS.TEST_RUN.REQUEST' | translate}}
        </h3>

        <div class="result">
            <div class="result__space">
                <textarea rows="1" readonly>{{result.request.start_line}}</textarea>
                <h4 mat-dialog-title id="test-run-text-request">{{ 'SETTINGS_PAGE.WEBHOOKS.TEST_RUN.HEADERS' |
                    translate}}
                </h4>

                <textarea [rows]="countNewlines(result.request.headers)" readonly>{{result.request.headers}}</textarea>
                <h4 mat-dialog-title id="test-run-text-request">{{ 'SETTINGS_PAGE.WEBHOOKS.TEST_RUN.BODY' | translate }}
                </h4>
                <textarea rows="20" readonly>{{result.request.body}}</textarea>
            </div>
        </div>

        <h3 mat-dialog-title id="test-run-result">{{ 'SETTINGS_PAGE.WEBHOOKS.TEST_RUN.RESPONSE' | translate }}</h3>
        <div class="result" *ngIf="result.response">
            <div class="result__space">
                <textarea rows="1" readonly>{{result.response.start_line}}</textarea>
                <h4 mat-dialog-title id="test-run-text-response">{{ 'SETTINGS_PAGE.WEBHOOKS.TEST_RUN.HEADERS' |
                    translate}}
                </h4>
                <textarea [rows]="countNewlines(result.response.headers)"
                    readonly>{{result.response.headers}}</textarea>
                <h4 mat-dialog-title id="test-run-text-response">{{ 'SETTINGS_PAGE.WEBHOOKS.TEST_RUN.BODY' | translate
                    }}</h4>
                <textarea [rows]="result.response.body.length ? 20 : 1" readonly>{{result.response.body}}</textarea>
            </div>
        </div>
        <div class="result" *ngIf="result.error && !result.response">
            <div class="result__space">
                <textarea rows="1" readonly>{{result.error.toUpperCase()}}</textarea>
            </div>
        </div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-button id="cancel-webhook-dialogue-details" color="primary" class="ezp-button" (click)="close()">
        {{ 'SETTINGS_PAGE.WEBHOOKS.TEST_RUN.CLOSE' | translate }}
    </button>
</div>