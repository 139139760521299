<aside
  class="layout__aside layout__aside--right layout__aside--right--narrow filters"
>
  <header class="layout__aside__head layout__aside__head--split">
    <h3 class="mat-title efa-text efa-text--no-spacing efa-text--he" id="connector-filters-text">
      {{ 'FILTERS' | translate }}
    </h3>
    <button mat-button
            id="clear-connector-filters"
            color="accent"
            class="efa-button"
            *ngIf="selectedFilters.length"
            (click)="clearFilters()">
      {{ 'CLEAR_ALL' | translate }}
    </button>
  </header>
  <section class="filters__selected">
    <mat-chip-list>
      <ng-container *ngIf="selectedFilters">
        <mat-chip
          *ngIf="selectedFilters.length && selectedFilters[selectedFilters.length - 1].field_name.toLowerCase() == 'connector'"
          [removable]="removable" class="connector-filter">
          <img alt="connector-filter" matPrefix class="connector-filter-icon" src="/assets/images/connector-filter.png">
          <span [id]="selectedFilters[selectedFilters.length - 1].name">
            {{ selectedFilters[selectedFilters.length - 1].name }}
          </span>
          <img matPrefix alt="remove-connector-filter" class="mat-icon remove-filter"
            src="/assets/images/remove-connector-filter.png" (click)="clearFilters()">
        </mat-chip>
      </ng-container>
    </mat-chip-list>
    <mat-chip-list>
      <ng-container *ngFor="let filterItem of selectedFilters">
      <mat-chip
      *ngIf="filterItem.field_name.toLowerCase() != 'connector'"
        [removable]="removable">
        <span [id]="filterItem.name">
          <!-- {{('CONNECTORS.CONNECTORS_FILTERS.' + filterItem.field_name.split(' ').join('_').toUpperCase()) | translate}} -->
          {{filterItem.name | translate}}
        </span>
        <mat-icon matChipRemove
          id="cancel-connector-filter"
          (click)="selectFilter(false, filterItem, filterItem.name)">
          cancel
        </mat-icon>
      </mat-chip>
    </ng-container>
    </mat-chip-list>
  </section>
  <ezd-expansion-panel
    *ngFor="let filter of connectorsFilters"
    title="{{('CONNECTORS.CONNECTORS_FILTERS.' + filter.field_name.split(' ').join('_').toUpperCase()) | translate}}"
    subtitle="{{( getSelectedFilterCountForFilterCategory(filter.field_name) >0 ?
     'CONNECTORS.CONNECTORS_FILTERS.SELECTED': 'CONNECTORS.CONNECTORS_FILTERS.NONE')|
     translate:{value: getSelectedFilterCountForFilterCategory(filter.field_name)} }}">
    <ng-container>
      <mat-checkbox
        *ngFor="let filterName of filter.values"
        class="efa-checkbox filters__checkbox"
        (change)="selectFilter($event.checked, filter, filterName)"
        [checked]="isFilterSelected(filter, filterName)"
        [id]="filterName"
      >{{ filterName | translate }}</mat-checkbox>
    </ng-container>
  </ezd-expansion-panel>
</aside>
