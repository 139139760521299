import { Component, OnInit } from '@angular/core';
import {NetworkPrintersPrintersFiltersService} from '../../../../modules/shared/services/network-printers-printers-filters.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'ezd-printers-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class NetworkPrintersPrintersSearchComponent implements OnInit {
  searchTextChanged = new Subject<string>();
  searchTextSubscription: Subscription;

  constructor(private networkPrintersPrintersFiltersService: NetworkPrintersPrintersFiltersService) { 
    this.networkPrintersPrintersFiltersService.setSearchText(undefined);
    this.searchTextSubscription = this.searchTextChanged
      .pipe(
        debounceTime(400))
      .subscribe(result => {
        this.networkPrintersPrintersFiltersService.setSearchText(result);
      })
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.searchTextSubscription.unsubscribe()
  }

  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue);
    // this.networkPrintersPrintersFiltersService.setSearchText(searchValue);
  }
}
