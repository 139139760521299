<ezd-table-placeholder
  *ngIf="showMessageNoPrinters"
  [columns]="['', '', '']"
  [rows]="8"
  [heading]="'PRINTERS.PROFILES.PROFILES_NOT_AVAILABLE.NOT_AVAILABLE' | translate"
  [description]="'PRINTERS.PROFILES.PROFILES_NOT_AVAILABLE.DESCRIPTION' | translate"
  [action]="{title: 'PRINTERS.PROFILES.PROFILES_NOT_AVAILABLE.BUTTON_TEXT' | translate, path: 'downloads'}"
></ezd-table-placeholder>

<div class="ezp-layout__head ezp-layout__head--split" *ngIf="!showMessageNoPrinters">
  <ezd-search-profiles></ezd-search-profiles>
  <div class="ezp-layout__head__actions">
    <button mat-raised-button id="add-profile" color="primary"
      class="ezp-button ezp-button--icon ezp-button--icon--leading" (click)="openProfileDetailsDialog(null)">
      <mat-icon>add</mat-icon>{{ 'PRINTERS.PROFILES.ADD_PROFILE' | translate }}</button>
  </div>
</div>
<div class="ezp-layout__part" *ngIf="!showMessageNoPrinters">
  <div class="ezp-table">
    <table mat-table [dataSource]="profilesDataSource" matSort (matSortChange)="sortChange($event)"
      matSortDisableClear="true" class="ezp-table__data" aria-describedby="profile_list">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--select">
          <mat-checkbox id="select-all-profiles" [indeterminate]="
            selectedProfileCount > 0 &&
            selectedProfileCount <
            profilesDataSource.data.length
          " [checked]="
            selectedProfileCount ===
            profilesDataSource.data.length
          " [disabled]="printersPropertiesHasChanges" (change)="selectAllVisiblePrinters($event)"
            color="primary"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let profile" class="ezp-table__cell ezp-table__cell--select">
          <mat-checkbox (change)="selectProfile($event, profile)" [checked]="profile.isSelected"
            [(ngModel)]="profile.isSelected" color="primary" [id]="profile.id" color="primary"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ezp-table__cell ezp-table__cell--text"
          id="profiles-list-name-text">
          {{ 'PRINTERS.PROFILES.PROFILES_LIST.NAME' | translate }}
        </th>
        <td mat-cell *matCellDef="let profile" class="ezp-table__cell ezp-table__cell--text">
          <span [id]="profile.name">{{ profile.name ? profile.name : '-' }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--text"
          id="profiles-list-email-text">
          {{ 'PRINTERS.PROFILES.PROFILES_LIST.DESCRIPTION' | translate }}
        </th>
        <td mat-cell *matCellDef="let profile" class="ezp-table__cell ezp-table__cell--text">
          <span matTooltip="{{ profile.description ? profile.description : '-' }}" matTooltipPosition="above"> {{
            GeneralUtil.substringIfLongerThan(profile.description ? profile.description : '-' ) }} </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="properties">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="ezp-table__cell ezp-table__cell--text">
          {{ 'PRINTERS.PROFILES.PROFILES_LIST.PROPERTIES' | translate }}
        </th>
        <td mat-cell *matCellDef="let profile" class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon">
          <img alt="color" matPrefix *ngIf="profile.color" class="mat-icon" src="assets/images/profiles-color.png" matTooltip="{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.VALUES.COLORS.' +  profile.color | translate }}" matTooltipPosition="above">
          <img alt="duplex" matPrefix *ngIf="profile.duplex" class="mat-icon" src="assets/images/profiles-duplex.png" matTooltip="{{ 'PRINTERS.PROFILES.PROFILE_DETAILS.VALUES.DUPLEX.' + profile.duplex | translate }}" matTooltipPosition="above">
          <img alt="orientation" matPrefix *ngIf="profile.orientation" class="mat-icon" src="assets/images/profiles-portrait.png" matTooltip="{{ profile.orientation }}" matTooltipPosition="above">
          <img alt="resolution" matPrefix *ngIf="profile.resolution" class="mat-icon" src="assets/images/profiles-resolution.png" matTooltip="{{ profile.resolution }}" matTooltipPosition="above">
          <img alt="paper_name" matPrefix *ngIf="profile.paper_name" class="mat-icon" src="assets/images/profiles-papersize.png" matTooltip="{{ profile.paper_name }}" matTooltipPosition="above">
          <img alt="tray" matPrefix *ngIf="profile.tray" class="mat-icon" src="assets/images/profiles-tray.png" matTooltip="{{ profile.tray }}" matTooltipPosition="above">
        </td>

      </ng-container>
      <ng-container matColumnDef="printers">
        <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--text"
          id="profiles-list-role-text">
          {{ 'PRINTERS.PROFILES.PROFILES_LIST.PRINTERS' | translate }}
        </th>
        <td mat-cell *matCellDef="let profile" class="ezp-table__cell ezp-table__cell--text">
          <span [id]="profile.printers"
            matTooltip="{{ GeneralUtil.concatenateObjectProperty(profile.printers, 'name')   }}"
            matTooltipPosition="above">{{ GeneralUtil.concatenateObjectPropertyAndSubstring(profile.printers, 'name')
            }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon"
          id="profiles-list-actions">
        </th>
        <td mat-cell *matCellDef="let profile" class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon">
          <button mat-icon-button id="open-profile-details" (click)="openProfileDetailsDialog(profile)" [disabled]="profile.printers.length == 0" [matTooltip]="profile.printers.length == 0 ? tooltipText : ''" (mouseenter)="showTooltip()">
            <mat-icon>edit</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--select">
          <button disabled="{{selectedProfileCount <= 1}}" mat-icon-button id="profile-details-menu"
            class="ezp-dialog__controls__action" [matMenuTriggerFor]="profileDetailsMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #profileDetailsMenu="matMenu" xPosition="before" yPosition="below">
            <button mat-menu-item id="profile-details-remove-profile" class="ezp-menu__item ezp-menu__item--destructive"
              (click)="openProfileWarning()">
              <mat-icon color="accent">delete_forever</mat-icon>
              {{ 'DELETE' | translate }}
            </button>
          </mat-menu>

        </th>
        <td mat-cell *matCellDef="let profile" class="ezp-table__cell ezp-table__cell--select">
          <button mat-icon-button id="profile-details-menu" class="ezp-dialog__controls__action"
            [matMenuTriggerFor]="profileDetailsMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #profileDetailsMenu="matMenu" xPosition="before" yPosition="below">
            <button mat-menu-item id="profile-details-remove-profile" class="ezp-menu__item ezp-menu__item--destructive"
              (click)="openProfileWarning(profile)">
              <mat-icon color="accent">delete_forever</mat-icon>
              {{ 'DELETE' | translate }}
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnDefs"></tr>
      <tr mat-row *matRowDef="let profile; columns: columnDefs"></tr>
    </table>
    <mat-spinner *ngIf="isLoading" class="ezp-spinner ezp-spinner--large ezp-spinner--table"
      id="loading-spinner"></mat-spinner>
    <mat-paginator class="efa-paginator" id="profiles-paginator" #paginator [length]="maxPageLength"
      [pageSize]="pageSize" [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions"
      (page)="onPaginationChange($event)">
    </mat-paginator>
  </div>
