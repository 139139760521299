import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { ConnectorsFiltersService } from '../../modules/shared/services/connectors-filters.service'
import { ConnectorService } from '../../modules/shared/services/connector.service'
import { CommonModule } from '@angular/common'
import { CoreModule } from '../../modules/core/core.module'
import { MaterialModule } from '../../modules/material/material.module'
import { SharedModule } from '../../modules/shared/shared.module'
import '@cortado-holding/animation/dist/animation/animation.esm'
import { ConnectorsComponent } from './connectors.component'
import { ConnectorsFiltersComponent } from './filters/filters.component'
import { ConnectorsPropertiesComponent } from './properties/properties.component'
import { ConnectorsSearchComponent } from './search/search.component'
import { AlertDeleteConnectorComponent } from './alert-delete-connector/alert-delete-connector.component'
import { WelcomeConnectorDialogComponent } from './welcome-connector-dialog/welcome-connector-dialog.component'

@NgModule({
    declarations: [
        ConnectorsComponent,
        ConnectorsFiltersComponent,
        ConnectorsPropertiesComponent,
        ConnectorsSearchComponent,
        AlertDeleteConnectorComponent,
        WelcomeConnectorDialogComponent
    ],
    providers: [ConnectorService,
        ConnectorsFiltersService],
    imports: [MaterialModule, CoreModule, SharedModule, CommonModule],
})
export class ConnectorModule { }
