<div class="section">
  <div class="section__box">
    <div class="section__leading">
      <mat-icon class="section__icon">flag</mat-icon>
      <div class="section__intro">
        <div class="section__title mat-subheading-2">{{ 'SETTINGS_PAGE.WEBHOOKS.HEADER' | translate }}</div>
        <button mat-stroked-button color="primary" class="section__button ezp-button" (click)="openWebhookEditor()"
          [disabled]="webhooks == false">{{
          'SETTINGS_PAGE.WEBHOOKS.ADD_WEBHOOKS' | translate }}</button>

        <button mat-stroked-button color="primary" class="section__button ezp-button" *ngIf="webhooks == false"
          (click)="goToSubscription()">{{
          'SETTINGS_PAGE.WEBHOOKS.UPGRADE_PLAN' | translate }}</button>
      </div>
    </div>

    <div class="section__trailing" *ngIf="webhooks">
      <div class="section__fields email">
        <div class="ezp-table">
          <table mat-table [dataSource]="webhooksData" matSort (matSortChange)="sortChange($event)"
            matSortDisableClear="true" class="ezp-table__data">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="ezp-table__cell ezp-table__cell--text">
                {{ 'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.NAME' | translate }}
              </th>
              <td mat-cell *matCellDef="let webhook" class="ezp-table__cell ezp-table__cell--text">
                <div class="toggle">
                  <div class="toggle__control">
                    <mat-slide-toggle color="primary" class="toggle__slide-toggle"
                      (change)="onChangeDisabledWebhookSetting($event.checked, webhook.endpoint_id)"
                      [checked]="!webhook.disabled"></mat-slide-toggle>
                  </div>
                  <div class="toggle__body">
                    <div class="toggle__title">
                      <span>{{ webhook.name }}</span>
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="last-run">
              <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--text last-run">
                {{ 'SETTINGS_PAGE.WEBHOOKS.PROPERTIES.LAST_RUN' | translate }}
              </th>
              <td mat-cell *matCellDef="let webhook" class="ezp-table__cell ezp-table__cell--text">
                <span *ngIf="webhook.last_success">{{formatDate(webhook.last_success)}}</span>
              </td>

            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef class="ezp-table__cell ezp-table__cell--text">

              </th>
              <td mat-cell *matCellDef="let webhook"
                class="ezp-table__cell ezp-table__cell--soft ezp-table__cell--icon ezp-table__cell--icon--double">
                <div class="ezp-table__cell__actions">
                  <mat-icon color="accent" *ngIf="webhook.has_failed_events">error</mat-icon>
                  <button mat-icon-button (click)="openWebhookEditor(webhook)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-icon-button (click)="deleteWebhook(webhook.endpoint_id)">
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                  <!-- Loading Start... -->
                  <div class="ezp-table__processing" *ngIf="isLoading">
                    <mat-spinner class="ezp-table__spinner"></mat-spinner>
                  </div>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="webhooksColumns"></tr>
            <!-- Loading Start... -->
            <tr mat-row *matRowDef="let webhook; columns: webhooksColumns"
              [ngClass]="{'ezp-table__row ezp-table__row--loading': isLoading}"></tr>
            <!-- Loading End... -->
          </table>
          <mat-spinner *ngIf="isLoading && webhooks" class="ezp-spinner ezp-spinner--large ezp-spinner--table"></mat-spinner>
          <mat-paginator class="efa-paginator" #paginator [length]="maxPageLength" [pageSize]="pageSize"
            [pageIndex]="pageIndex" [pageSizeOptions]="pageSizeOptions" (page)="onPaginationChange($event)">
          </mat-paginator>
        </div>
        <div>
        </div>
      </div>
    </div>

    <ezd-table-placeholder
    class="placeholder"
    *ngIf="webhooks == false"
    [columns]="['', '', '']"
    [rows]="5"
    [heading]="'SETTINGS_PAGE.WEBHOOKS.WEBHOOKS_LIST_NOT_AVAILABLE.NOT_AVAILABLE' | translate"
    [action]="{title: 'SETTINGS_PAGE.WEBHOOKS.WEBHOOKS_LIST_NOT_AVAILABLE.BUTTON_TEXT' | translate, path: 'subscription'}"
    [description]="'SETTINGS_PAGE.WEBHOOKS.WEBHOOKS_LIST_NOT_AVAILABLE.DESCRIPTION' | translate"
  ></ezd-table-placeholder>
  </div>
</div>