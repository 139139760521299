
import { throwError } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OperatingSystem } from '../../../globals/enums/operating-system.enum';

// Gives access to the values in the config file in the assets folder
@Injectable()
export class AppConfig {

  private config: any;

  public firstTime = true;
  isAzureUser = window.location.hostname.includes('.azdev.') || window.location.hostname.includes('app.ezeep.');

  public load(http: HttpClient): Promise<any> {
    return new Promise((resolve, reject) => {
      http.get('/assets/app.config.json').pipe(catchError((error: any): any => {
        resolve(true);
        return throwError(() => new Error("Error reading config file")) // observableThrowError('Error reading config file');
      })).subscribe(response => {
        this.config = response;
        resolve(true);
      });
    });
  }

  public get version(): string {
    if (!this.config) {
      return 'Missing or unreadable app.config.json in assets folder';
    }
    if (!this.config.version) {
      return 'Version not found in app.config.json in assets folder';
    }
    return this.config.version;
  }

  public get operatingSystem() {
    const userAgent         = window.navigator.userAgent;
    const platform          = window.navigator.platform;
    const macosPlatforms    = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms  = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms      = ['iPhone', 'iPad', 'iPod'];
    let os                  = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = OperatingSystem.Mac;
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = OperatingSystem.Ios;
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = OperatingSystem.Win;
    } else if (/Android/.test(userAgent)) {
      os = OperatingSystem.Android;
    } else if (!os && /Linux/.test(platform)) {
      os = OperatingSystem.Linux;
    }

    return os;
  }

  public get allowMac(): boolean {
    return this.config.allowMac;
  }

  public get useWizard2(): boolean {
    return this.config.useWizard2;
  }

  public get minUploaderVersion() {
    return this.config.minUploaderVersion;
  }

  public get downloadExeAddress32() {
    return this.config.downloadExeAddress32;
  }
  public get downloadExeAddress64() {
    return this.config.downloadExeAddress64;
  }
  public get downloadZipAddress() {
    return this.config.downloadZipAddress;
  }

  public get downloadDashAddress(): string {
    return this.config.downloadDashAddress;
  }

  public get selfServiceAddress(): string {
    return this.config.selfServiceAddress;
  }

  public get zipHelpAddress(): string {
    return this.config.zipHelpAddress;
  }

  public get contactAddress(): string {
    return this.isAzureUser ? this.config.efaContactAddress : this.config.contactAddress;
  }

  public get generalHelpAddress(): string {
    return this.isAzureUser ? this.config.efaGeneralHelpAddress : this.config.generalHelpAddress;
  }

  public get generalHelpAddressGerman(): string {
    return this.isAzureUser ? this.config.efaGeneralHelpAddressGerman : this.config.generalHelpAddressGerman;
  }

  public get twitterAddress(): string {
    return this.isAzureUser ? this.config.efaTwitterAddress : this.config.twitterAddress;
  }

  public get facebookAddress(): string {
    return this.config.facebookAddress;
  }

  public get imprintGerman(): string {
    return this.config.imprintGerman;
  }

  public get imprintEnglish(): string {
    return this.config.imprintEnglish;
  }

  public get termsAndConditionsGerman(): string {
    return this.config.termsAndConditionsGerman;
  }

  public get termsAndConditionsEnglish(): string {
    return this.config.termsAndConditionsEnglish;
  }

  public get privacyPolicyGerman(): string {
    return this.config.privacyPolicyGerman;
  }

  public get privacyPolicyEnglish(): string {
    return this.config.privacyPolicyEnglish;
  }
}
