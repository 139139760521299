import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EZEEP_PRINTING_API } from '../../../app.config';

@Injectable({
  providedIn: 'root'
})

export class PullprintingService {

  constructor(private httpClient: HttpClient) {}
  
  public registerPullPrintingCode(code: string): Observable<any> {
    return this.httpClient.post<any>(`${EZEEP_PRINTING_API}/v1/pull_printing_registration_codes/?override=true`, { code: code });
  }

  public pairPrinterPullPrintingCode(code: string, printer_id: string): Observable<any> {
   return this.httpClient.post<any>(`${EZEEP_PRINTING_API}/v1/printers/${printer_id}/pull_printing_pairing/`, { code: code });
  }
}
