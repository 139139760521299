import { Component } from '@angular/core'

@Component({
  selector: 'ezd-automations',
  templateUrl: './automations.component.html',
  styleUrls: ['./automations.component.scss'],
})
export class AutomationsComponent {
  public zapierClientId: string = 'KxqYX7Kl7xzFZR9cb0ysY7LIobAcOGtC1f6dCf2x'
}
