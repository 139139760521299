export const triggers = ['printjob_succeeded', 'printjob_failed'];

export const DATE_LOCALIZATION = {
    de: 'de-DE',
    en: 'en-US'
}

export const TRIGGERS_LOCAL = {
    printjob_succeeded: 'PrintJob Succeeded',
    printjob_failed: 'PrintJob Failed'
}

export const ERROR_CODES = {
    timeout: 'Timeout',
    response_status_code: 'Error code',
    unknown: 'Unknown'
}

