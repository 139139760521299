import { Injectable } from '@angular/core';
import { WebhooksApiService, WebhooksSettingsApiService, Webhook, IWebhookTestResponse, WebhookTestResponse } from './webhooks-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DEFAULT_PAGE_SIZE } from '../../../app.config';
import { map, mergeMap } from 'rxjs/operators';
import { GeneralUtil } from '../utils/general-util';

@Injectable({
  providedIn: 'root'
})
export class WebhooksService {

  webhooksSubject = new BehaviorSubject<any>({ isLoading: false, count: 0, webhooks: [] });
  pageIndex: number = 0;
  pageSize: number = DEFAULT_PAGE_SIZE;
  webhooksCount: any;
  webhooks: any;
  sorting: any;
  draftWebhook: Webhook
  editMode: Boolean = false

  constructor(private webhooksApiService: WebhooksApiService,
    private webhooksSettingsApiService: WebhooksSettingsApiService
  ) { }

  getFilteredWebhooks(): Observable<any> {
    return this.webhooksSubject.asObservable();
  }

  applyFilters(showLoading: boolean = true) {
    this.webhooksSubject.next({ isLoading: showLoading, count: 0, webhooks: [] });
    this.webhooksApiService.list(this.sorting ? this.sorting : "-name", this.pageSize, this.pageIndex * this.pageSize).subscribe(async response => {
      this.webhooks = response.result.results;
      this.webhooksCount = response.result.count;
      this.webhooksSubject.next({
        isLoading: false,
        count: response.result.count,
        webhooks: this.webhooks
      });
    }, error => {
      this.webhooksSubject.next({ isLoading: false, count: this.webhooksCount, webhooks: this.webhooks });
    });
  }

  setPage(pageIndex: number, pageSize: number, isInitial = false) {
    this.pageIndex = pageIndex;
    this.pageSize = pageSize;
    if (!isInitial) {
      this.applyFilters(true);
    }
  }

  setSorting(sorting) {
    this.sorting = sorting;
    this.applyFilters(false);
  }

  public toggleUserNameSetting(): Observable<any> {
    return this.webhooksSettingsApiService.read().pipe(mergeMap(response => {
      let webhooksSettings = response.result;
      webhooksSettings.user_name_disabled = !webhooksSettings.user_name_disabled;
      let requestPayload = { user_name_disabled: webhooksSettings.user_name_disabled }
      return this.webhooksSettingsApiService.update(requestPayload).pipe(map(responseUpdate => {
        return responseUpdate.result.user_name_disabled;
      }));
    }));
  }

  public getCurrentUserNameSettingStatus(): Observable<boolean> {
    return this.webhooksSettingsApiService.read().pipe(map(response => {
      let webhooksSettings = response.result;
      return webhooksSettings.user_name_disabled;
    }));
  }

  public toggleUserEmailSetting(): Observable<any> {
    return this.webhooksSettingsApiService.read().pipe(mergeMap(response => {
      let webhooksSettings = response.result;
      webhooksSettings.user_email_disabled = !webhooksSettings.user_email_disabled;
      let requestPayload = { user_email_disabled: webhooksSettings.user_email_disabled }
      return this.webhooksSettingsApiService.update(requestPayload).pipe(map(responseUpdate => {
        return responseUpdate.result.user_email_disabled;
      }));
    }));
  }

  public getCurrentUserEmailSettingStatus(): Observable<boolean> {
    return this.webhooksSettingsApiService.read().pipe(map(response => {
      let webhooksSettings = response.result;
      return webhooksSettings.user_email_disabled;
    }));
  }

  public toggleFileNameSetting(): Observable<any> {
    return this.webhooksSettingsApiService.read().pipe(mergeMap(response => {
      let webhooksSettings = response.result;
      webhooksSettings.file_name_obfuscated = !webhooksSettings.file_name_obfuscated;
      let requestPayload = { file_name_obfuscated: webhooksSettings.file_name_obfuscated }
      return this.webhooksSettingsApiService.update(requestPayload).pipe(map(responseUpdate => {
        return responseUpdate.result.file_name_obfuscated;
      }));
    }));
  }

  public addWebhook() {
    return this.webhooksApiService.post(this.draftWebhook).pipe(map(responseUpdate => {
      return responseUpdate.result;
    }));
  }

  public updateWebhook() {
    const id = this.draftWebhook.id
    delete this.draftWebhook.id
    return this.webhooksApiService.update(this.draftWebhook, id).pipe(map(responseUpdate => {
      return responseUpdate.result;
    }));
  }

  public getCurrentFileNameSettingStatus(): Observable<boolean> {
    return this.webhooksSettingsApiService.read().pipe(map(response => {
      let webhooksSettings = response.result;
      return webhooksSettings.file_name_obfuscated;
    }));
  }

  draftWebhookDetails(webhook: Webhook) {
    this.draftWebhook = GeneralUtil.cleanObject(webhook);
  }

  getDraftWebhook() {
    return this.draftWebhook;
  }

  clearDraftWebhook() {
    this.draftWebhook = null;
  }

  setEditMode() {
    this.editMode = true
  }

  clearEditMode() {
    this.editMode = false
  }

  testURL(topic: string): Observable<WebhookTestResponse> {
    const payload = {
      authentication_scheme: this.draftWebhook.authentication_scheme,
      basic_password: this.draftWebhook.basic_password,
      basic_username: this.draftWebhook.basic_username,
      topic,
      url: this.draftWebhook.url,
    };

    return this.webhooksApiService.test(payload).pipe(
      map(response => response.result)
    );
  }
  
  public toggleWebhookDisabledSetting(endpointId): Observable<any> {
    return this.webhooksApiService.read(endpointId).pipe(mergeMap(response => {
      let webhooksSettings = response.result;
      webhooksSettings.disabled = !webhooksSettings.disabled;
      return this.webhooksApiService.update(webhooksSettings, endpointId).pipe(map(responseUpdate => {
        return responseUpdate.result.disabled;
      }));
    }));
  }
}
