import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DIALOG_RESPONSE } from '../../../modules/shared/classes/dialog-result';

@Component({
  selector: 'ezd-alert-delete-connector',
  templateUrl: './alert-delete-connector.component.html',
  styleUrls: ['./alert-delete-connector.component.scss']
})
export class AlertDeleteConnectorComponent implements OnInit {
  @Output() dialogResult: EventEmitter<DIALOG_RESPONSE> = new EventEmitter<DIALOG_RESPONSE>();
  isLoading: boolean;
  
  constructor() { }

  ngOnInit() {
    this.isLoading = false;
  }

  delete() {
    this.isLoading = true;
    this.dialogResult.emit(DIALOG_RESPONSE.DELETE);
  }

  cancel() {
    this.dialogResult.emit(DIALOG_RESPONSE.CANCEL);
  }
}
