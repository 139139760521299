<h2 mat-dialog-title id="remove-user-text">{{ 'USERSGROUPS.USERS.USER_DETAILS.REMOVE_USER' | translate }}</h2>
<div mat-dialog-content>
  <p *ngIf="preferredLanguage == 'en'" [id]="userEmail">{{ 'USERSGROUPS.USERS.REMOVE_USER' | translate }} <strong>{{ displayName ? displayName: userEmail }}</strong>?</p>
  <p *ngIf="preferredLanguage == 'de'" [id]="userEmail" [innerHTML]="'USERSGROUPS.USERS.REMOVE_USER' | translate: { value: userEmail }"></p>
</div>
<div mat-dialog-actions>
  <button mat-button id="cancel-remove-user" [mat-dialog-close]="true" (click)="close()" color="primary" class="ezp-button" [disabled]="isLoading">
    {{ 'CANCEL' | translate }}
  </button>
  <button mat-button id="remove-user" (click)="removeUser()" color="primary" class="ezp-button" [disabled]="isLoading">
    <mat-icon [ngClass]="{ 'fa-spin': isLoading }" *ngIf="isLoading">cached</mat-icon>
    {{ 'REMOVE' | translate }}
  </button>
</div>
