import { Component, OnInit } from '@angular/core'
import { SubscriptionApiService, SubscriptionStatus } from '../../../modules/shared/services/subscription-api.service'
import { EzpChargebeeApiService } from '../../../modules/shared/services/ezp-chargebee-api.service'
import { map } from 'rxjs/operators';
import { CHARGEBEE_SITE_NAME } from '../../../app.config';

@Component({
  selector: 'ezd-trial-active',
  templateUrl: './trial-active.component.html',
  styleUrls: ['./trial-active.component.scss'],
})
export class TrialActiveComponent implements OnInit {
  subscriptionStatus: any;
  subscriptionId: any;
  planId: any;
  cbInstance;
  nextBillingDate: any;
  isNextBillingDatePresent: any;

  constructor(private subscriptionApiService: SubscriptionApiService,
    private chargebeeApiService: EzpChargebeeApiService) {}

  ngOnInit(): void {
    this.subscriptionApiService.read("-created").subscribe(response => {
      //WARNING! last created subscription is the one to be verified
      this.subscriptionStatus = response.result.result[0];
      this.subscriptionId = this.subscriptionStatus.source.subscription_id;
      this.planId = this.subscriptionStatus.source.plan_id;
      this.isNextBillingDatePresent = this.subscriptionStatus.source.subscription.next_billing_at;
      this.nextBillingDate = new Date(this.subscriptionStatus.source.subscription.next_billing_at * 1000).toLocaleDateString('en-us', { year: 'numeric', month: 'short', day: 'numeric' });
      if (!this.subscriptionStatus) return;
      if (this.subscriptionStatus.expires)
        this.subscriptionStatus.daysLeft = (new Date(this.subscriptionStatus.expires)).getTime() - new Date().getTime();
      this.subscriptionStatus.daysLeft = Math.ceil(this.subscriptionStatus.daysLeft / (1000 * 3600 * 24));
      
    });

    this.cbInstance = window['Chargebee'].init({
      site: CHARGEBEE_SITE_NAME
    });
    
  }
  
  checkoutExistingSubscription(): void {
    this.cbInstance.createChargebeePortal();
    this.cbInstance.openCheckout({
      hostedPage: () => {
        
      //   // Hit your end point that returns hosted page object as response
      //   // This sample end point will call checkout new api
      //   // https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_new_subscription
      //   // If you want to use paypal, go cardless and plaid, pass embed parameter as false
      return this.chargebeeCheckout();
      //   //return this.http.post("http://localhost:8000/api/generate_checkout_existing_url", {}, {headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})}) // .toPromise();
      },
      
      loaded: () => {
      },
      close: () => {
      },
      success: (hostedPageId) => {
        // Hosted page id will be unique token for the checkout that happened
        // You can pass this hosted page id to your backend 
        // and then call our retrieve hosted page api to get subscription details
        // https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page
      },
      step: (value) => {
          // value -> which step in checkout
      }
    });
  }
  

  chargebeeCheckout() {
    return this.chargebeeApiService.checkout(this.subscriptionId, this.planId).pipe(map(response => {
      return response.result
    })).toPromise();
  }
}
