import { Component, OnInit } from '@angular/core'
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs';
import { HIDE_OVERLAY_QUICKSTART } from '../../../storage/constants/storage.constants';
import { StorageUtils } from '../../../storage/utils/storage.utils';
import { LangaugeService } from '../../services/langauge.service';
import { MyprintersService } from '../../services/myprinters.service';

@Component({
  selector: 'ezd-invitation-reminder-dialog',
  templateUrl: './invitation-reminder-dialog.component.html',
  styleUrls: ['./invitation-reminder-dialog.component.scss'],
})
export class InvitationReminderDialogComponent implements OnInit {
  preferredLanguage: string; 
  hideDialog: boolean;
  dontShowAgain: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<InvitationReminderDialogComponent>, 
    private router: Router,
    private languageService: LangaugeService,
    private myprintersService: MyprintersService) {}

  ngOnInit(): void {
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value;
  })
  }

  inviteUsers() {
    this.dialogRef.close()
    this.router.navigate(['usersgroups'], { queryParams: { lang: this.preferredLanguage }, queryParamsHandling: 'merge' })
  }

  close() {
    this.dontShowAgain = true
    this.myprintersService.turnOffShowQuickstartOverlay(this.dontShowAgain)
    StorageUtils.set(sessionStorage, HIDE_OVERLAY_QUICKSTART, true)
    this.dialogRef.close()
  }
}
