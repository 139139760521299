<div class="ezp-layout__page">
  <div class="ezp-layout__content">
    <h1 class="mat-headline" id="settings-title-text">{{ 'SETTINGS_PAGE.SETTINGS' | translate }}</h1>
    <mat-tab-group #tabs class="ezp-tabs" animationDuration="0ms" dynamicHeight *ngIf="isAdmin">
      <mat-tab label="general">
        <ng-template mat-tab-label>
          <span id="general-view-label-text">
            <mat-icon id="my_printers-view-label-icon">tune</mat-icon>
            {{ 'SETTINGS_PAGE.GENERAL' | translate }}
          </span>
        </ng-template>
        <div class="ezp-layout__part">
          <!-- Account -->
          <div class="section">
            <div class="section__box">
              <div class="section__leading">
                <mat-icon class="section__icon">account_circle</mat-icon>
                <div class="section__intro">
                  <div class="section__title mat-subheading-2">{{ 'SETTINGS_PAGE.ACCOUNT.HEADER' | translate }}</div>
                  <div class="section__description mat-body">
                    {{ 'SETTINGS_PAGE.ACCOUNT.DESCRIPTION' | translate }}
                    <a [href]="changePassword" class="link">{{ 'SETTINGS_PAGE.ACCOUNT.LINK' | translate }}</a>
                  </div>
                </div>
              </div>
              <form [formGroup]="accountForm">
                <div class="section__trailing">
                  <div class="section__fields">
                    <mat-form-field appearance="outline"
                      class="ezp-form-field ezp-form-field--grid ezp-form-field--grid--no-helper">
                      <mat-label>{{ 'SETTINGS_PAGE.ACCOUNT.INFORMATION.ORGANIZATION_NAME' | translate }}</mat-label>
                      <input matInput formControlName="organizationName" (keyup)="onOrganizationNameChange($event)" />
                    </mat-form-field>
                    <mat-form-field appearance="outline"
                      class="ezp-form-field ezp-form-field--grid ezp-form-field--grid--no-helper">
                      <mat-label>{{ 'SETTINGS_PAGE.ACCOUNT.INFORMATION.EMAIL_ADDRESS' | translate }}</mat-label>
                      <input matInput formControlName="email" (keyup)="onEmailChange($event)" />
                    </mat-form-field>
                    <mat-form-field appearance="outline"
                      class="ezp-form-field ezp-form-field--grid ezp-form-field--grid--no-helper">
                      <mat-label>{{ 'SETTINGS_PAGE.ACCOUNT.INFORMATION.FIRST_NAME' | translate }}</mat-label>
                      <input matInput formControlName="firstName" (keyup)="onFirstNameChange($event)" />
                    </mat-form-field>
                    <mat-form-field appearance="outline"
                      class="ezp-form-field ezp-form-field--grid ezp-form-field--grid--no-helper">
                      <mat-label>{{ 'SETTINGS_PAGE.ACCOUNT.INFORMATION.LAST_NAME' | translate }}</mat-label>
                      <input matInput formControlName="lastName" (keyup)="onLastNameChange($event)" />
                    </mat-form-field>
                    <mat-form-field appearance="outline"
                      class="ezp-form-field ezp-form-field--grid ezp-form-field--grid--no-helper">
                      <mat-label>{{ 'SETTINGS_PAGE.ACCOUNT.INFORMATION.LANGUAGE' | translate }}</mat-label>
                      <mat-select formControlName="preferredLanguage" (selectionChange)="onPreferredLanguageChange($event)">
                        <mat-option id="english" value="en">{{ 'EN' | translate }}</mat-option>
                        <mat-option id="german" value="de">{{ 'DE' | translate }}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </form>
            </div>
          </div>
      
          <!-- Data Protection -->
          <div class="section" *ngIf="isAdmin == true">
            <div class="section__box">
              <div class="section__leading">
                <mat-icon class="section__icon">lock</mat-icon>
                <div class="section__intro">
                  <div class="section__title mat-subheading-2">{{ 'SETTINGS_PAGE.DATA_PROTECTION.HEADER' | translate }}
                  </div>
                </div>
              </div>
              <div class="section__trailing">
                <div class="section__toggles">
      
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>{{ 'REPORTING.GENERATED_REPORTS' | translate }}</th>
                        <th>{{ 'SETTINGS_PAGE.WEBHOOKS.HEADER' | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th style="text-align: left;">{{ 'SETTINGS_PAGE.DATA_PROTECTION.USER_NAME_TOGGLE' | translate }}</th>
                        <td data-label="Reports">
                          <mat-slide-toggle color="primary" class="toggle__slide-toggle"
                            (change)="onChangeUserNameCSVSetting($event.checked)" [checked]="userNameCSV"></mat-slide-toggle>
                        </td>
                        <td data-label="Webhooks">
                          <mat-slide-toggle color="primary" class="toggle__slide-toggle"
                            (change)="onChangeUserNameWebhooksSetting($event.checked)"
                            [checked]="userNameWebhooks"></mat-slide-toggle>
                        </td>
                      </tr>
                      <tr>
                        <th style="text-align: left;">{{ 'SETTINGS_PAGE.DATA_PROTECTION.EMAIL_TOGGLE' | translate }}</th>
                        <td data-label="Reports">
                          <mat-slide-toggle color="primary" class="toggle__slide-toggle"
                            (change)="onChangeEmailCSVSetting($event.checked)" [checked]="emailCSV"></mat-slide-toggle>
                        </td>
                        <td data-label="Webhooks">
                          <mat-slide-toggle color="primary" class="toggle__slide-toggle"
                            (change)="onChangeEmailWebhooksSetting($event.checked)" [checked]="emailWebhooks">
                          </mat-slide-toggle>
                        </td>
                      </tr>
                      <tr>
                        <th style="text-align: left;">{{ 'SETTINGS_PAGE.DATA_PROTECTION.FILE_NAME_TOGGLE' | translate }}</th>
                        <td data-label="Reports">
                          <mat-slide-toggle color="primary" class="toggle__slide-toggle"
                            (change)="onChangeFileNameCSVSetting($event.checked)" [checked]="fileNameCSV"></mat-slide-toggle>
                        </td>
                        <td data-label="Webhooks">
                          <mat-slide-toggle color="primary" class="toggle__slide-toggle"
                            (change)="onChangeFileNameWebhooksSetting($event.checked)"
                            [checked]="fileNameWebhooks"></mat-slide-toggle>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
      
          <!-- Sessions -->
          <ezd-session></ezd-session>
      
          <!-- Quick Links -->
          <div class="section">
            <div class="section__box  section-quicklinks">
              <div class="section__leading">
                <mat-icon class="section__icon">public</mat-icon>
                <div class="section__intro">
                  <div class="section__title mat-subheading-2">
                    {{ 'SETTINGS_PAGE.QUICK_LINKS.HEADER' | translate }}
                  </div>
                  <div class="section__description mat-body">
                    {{ 'SETTINGS_PAGE.QUICK_LINKS.DESCRIPTION' | translate }}
                  </div>
                </div>
              </div>
              <div class="section__trailing">
                <div class="section__quick-links">
      
                  <div class="quick-links">
                    <div class="quick-links__control">
                      <mat-icon class="link-icon">import_contacts</mat-icon>
                    </div>
                    <div class="quick-links__body">
                      <div class="quick-links__title mat-subheading-2">
                        <a [href]="preferredLanguage == 'de' ? appConfig.imprintGerman : appConfig.imprintEnglish"
                          target="_blank" class="link-text">{{ 'SETTINGS_PAGE.QUICK_LINKS.IMPRINT' | translate }}</a>
                      </div>
                    </div>
                  </div>
      
                  <div class="quick-links">
                    <div class="quick-links__control">
                      <mat-icon class="link-icon">devices</mat-icon>
                    </div>
                    <div class="quick-links__body">
                      <div class="quick-links__title mat-subheading-2">
                        <a [href]="preferredLanguage == 'de' ? appConfig.termsAndConditionsGerman : appConfig.termsAndConditionsEnglish"
                          target="_blank" class="link-text">{{ 'SETTINGS_PAGE.QUICK_LINKS.TERMS_AND_CONDITIONS' | translate
                          }}</a>
                      </div>
                    </div>
                  </div>
      
                  <div class="quick-links">
                    <div class="quick-links__control">
                      <mat-icon class="link-icon">flag</mat-icon>
                    </div>
                    <div class="quick-links__body">
                      <div class="quick-links__title mat-subheading-2">
                        <a [href]="preferredLanguage == 'de' ? appConfig.privacyPolicyGerman : appConfig.privacyPolicyEnglish"
                          target="_blank" class="link-text">{{ 'SETTINGS_PAGE.QUICK_LINKS.PRIVACY_POLICY' | translate }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="integrations">
        <ng-template mat-tab-label>
          <span id="integrations-view-label-text">
            <mat-icon id="integrations-view-label-icon">link</mat-icon>
            {{ 'SETTINGS_PAGE.INTEGRATIONS' | translate }}
          </span>
        </ng-template>
        <div class="ezp-layout__part">
          <!-- Connectivity -->
          <div class="section" *ngIf="isAdmin == true">
            <div class="section__box">
              <div class="section__leading">
                <mat-icon class="section__icon">cloud</mat-icon>
                <div class="section__intro">
                  <div class="section__title mat-subheading-2">{{ 'SETTINGS_PAGE.CONNECTIVITY.HEADER' | translate }}
                  </div>
                </div>
              </div>
              <div class="section__trailing">
                <div class="section__toggles">
                  <div class="toggle">
                    <div class="toggle__control">
                      <mat-slide-toggle color="primary" class="toggle__slide-toggle" [checked]="azureProfilePresent"
                        [disabled]="azureADConnect == false || (!azureProfilePresent && hasOwner)"
                        (change)="onToggleAzureAD($event)">
                      </mat-slide-toggle>
                    </div>
                    <div class="toggle__body">
                      <div class="toggle__title mat-subheading-2">{{ 'SETTINGS_PAGE.CONNECTIVITY.TOGGLE_TEXT' |
                        translate }}</div>
                      <div class="toggle__meta mat-body" [innerHTML]="'SETTINGS_PAGE.CONNECTIVITY.HINT' | translate">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Webhooks -->
          <ezd-webhook></ezd-webhook>
        </div>
      </mat-tab>
    </mat-tab-group>

    <div class="ezp-layout__part" *ngIf="!isAdmin">
      <!-- Account -->
      <div class="section">
        <div class="section__box">
          <div class="section__leading">
            <mat-icon class="section__icon">account_circle</mat-icon>
            <div class="section__intro">
              <div class="section__title mat-subheading-2">{{ 'SETTINGS_PAGE.ACCOUNT.HEADER' | translate }}</div>
              <div class="section__description mat-body">
                {{ 'SETTINGS_PAGE.ACCOUNT.DESCRIPTION' | translate }}
                <a [href]="changePassword" class="link">{{ 'SETTINGS_PAGE.ACCOUNT.LINK' | translate }}</a>
              </div>
            </div>
          </div>
          <form [formGroup]="accountForm">
            <div class="section__trailing">
              <div class="section__fields">
                <mat-form-field appearance="outline"
                  class="ezp-form-field ezp-form-field--grid ezp-form-field--grid--no-helper">
                  <mat-label>{{ 'SETTINGS_PAGE.ACCOUNT.INFORMATION.ORGANIZATION_NAME' | translate }}</mat-label>
                  <input matInput formControlName="organizationName" (keyup)="onOrganizationNameChange($event)" />
                </mat-form-field>
                <mat-form-field appearance="outline"
                  class="ezp-form-field ezp-form-field--grid ezp-form-field--grid--no-helper">
                  <mat-label>{{ 'SETTINGS_PAGE.ACCOUNT.INFORMATION.EMAIL_ADDRESS' | translate }}</mat-label>
                  <input matInput formControlName="email" (keyup)="onEmailChange($event)" />
                </mat-form-field>
                <mat-form-field appearance="outline"
                  class="ezp-form-field ezp-form-field--grid ezp-form-field--grid--no-helper">
                  <mat-label>{{ 'SETTINGS_PAGE.ACCOUNT.INFORMATION.FIRST_NAME' | translate }}</mat-label>
                  <input matInput formControlName="firstName" (keyup)="onFirstNameChange($event)" />
                </mat-form-field>
                <mat-form-field appearance="outline"
                  class="ezp-form-field ezp-form-field--grid ezp-form-field--grid--no-helper">
                  <mat-label>{{ 'SETTINGS_PAGE.ACCOUNT.INFORMATION.LAST_NAME' | translate }}</mat-label>
                  <input matInput formControlName="lastName" (keyup)="onLastNameChange($event)" />
                </mat-form-field>
                <mat-form-field appearance="outline"
                  class="ezp-form-field ezp-form-field--grid ezp-form-field--grid--no-helper">
                  <mat-label>{{ 'SETTINGS_PAGE.ACCOUNT.INFORMATION.LANGUAGE' | translate }}</mat-label>
                  <mat-select formControlName="preferredLanguage"
                    (selectionChange)="onPreferredLanguageChange($event)">
                    <mat-option id="english" value="en">{{ 'EN' | translate }}</mat-option>
                    <mat-option id="german" value="de">{{ 'DE' | translate }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- Data Protection -->
      <div class="section" *ngIf="isAdmin == true">
        <div class="section__box">
          <div class="section__leading">
            <mat-icon class="section__icon">lock</mat-icon>
            <div class="section__intro">
              <div class="section__title mat-subheading-2">{{ 'SETTINGS_PAGE.DATA_PROTECTION.HEADER' | translate }}
              </div>
            </div>
          </div>
          <div class="section__trailing">
            <div class="section__toggles">
              <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>{{ 'REPORTING.GENERATED_REPORTS' | translate }}</th>
                        <th>{{ 'SETTINGS_PAGE.WEBHOOKS.HEADER' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th  style="text-align: left;">{{ 'SETTINGS_PAGE.DATA_PROTECTION.USER_NAME_TOGGLE' | translate }}</th>
                        <td data-label="Reports">
                          <mat-slide-toggle color="primary" class="toggle__slide-toggle" (change)="onChangeEmailCSVSetting($event.checked)"
                            [checked]="emailCSV"></mat-slide-toggle>
                        </td>
                        <td data-label="Webhooks">
                          <mat-slide-toggle color="primary" class="toggle__slide-toggle"
                            (change)="onChangeUserNameWebhooksSetting($event.checked)" [checked]="userNameWebhooks"></mat-slide-toggle>
                        </td>
                    </tr>
                    <tr>
                      <th style="text-align: left;">{{ 'SETTINGS_PAGE.DATA_PROTECTION.EMAIL_TOGGLE' | translate }}</th>
                        <td data-label="Reports">
                          <mat-slide-toggle color="primary" class="toggle__slide-toggle"
                    (change)="onChangeEmailCSVSetting($event.checked)" [checked]="emailCSV"></mat-slide-toggle>
                        </td>
                        <td data-label="Webhooks">
                          <mat-slide-toggle color="primary" class="toggle__slide-toggle"
                    (change)="onChangeEmailWebhooksSetting($event.checked)" [checked]="emailWebhooks">
                  </mat-slide-toggle>
                        </td>
                    </tr>
                    <tr>
                      <th  style="text-align: left;">{{ 'SETTINGS_PAGE.DATA_PROTECTION.FILE_NAME_TOGGLE' | translate }}</th>
                        <td data-label="Reports">
                          <mat-slide-toggle color="primary" class="toggle__slide-toggle"
                    (change)="onChangeFileNameCSVSetting($event.checked)"
                    [checked]="fileNameCSV"></mat-slide-toggle>
                        </td>
                        <td data-label="Webhooks">
                          <mat-slide-toggle color="primary" class="toggle__slide-toggle"
                    (change)="onChangeFileNameWebhooksSetting($event.checked)"
                    [checked]="fileNameWebhooks"></mat-slide-toggle>
                        </td>
                    </tr>
                </tbody>
            </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Sessions -->
      <ezd-session></ezd-session>

      <!-- Quick Links -->
      <div class="section">
        <div class="section__box  section-quicklinks">
          <div class="section__leading">
            <mat-icon class="section__icon">public</mat-icon>
            <div class="section__intro">
              <div class="section__title mat-subheading-2">
                {{ 'SETTINGS_PAGE.QUICK_LINKS.HEADER' | translate }}
              </div>
              <div class="section__description mat-body">
                {{ 'SETTINGS_PAGE.QUICK_LINKS.DESCRIPTION' | translate }}
              </div>
            </div>
          </div>
          <div class="section__trailing">
            <div class="section__quick-links">
  
              <div class="quick-links">
                <div class="quick-links__control">
                  <mat-icon class="link-icon">import_contacts</mat-icon>
                </div>
                <div class="quick-links__body">
                  <div class="quick-links__title mat-subheading-2">
                    <a [href]="preferredLanguage == 'de' ? appConfig.imprintGerman : appConfig.imprintEnglish"
                      target="_blank" class="link-text">{{ 'SETTINGS_PAGE.QUICK_LINKS.IMPRINT' | translate }}</a>
                  </div>
                </div>
              </div>
  
              <div class="quick-links">
                <div class="quick-links__control">
                  <mat-icon class="link-icon">devices</mat-icon>
                </div>
                <div class="quick-links__body">
                  <div class="quick-links__title mat-subheading-2">
                    <a [href]="preferredLanguage == 'de' ? appConfig.termsAndConditionsGerman : appConfig.termsAndConditionsEnglish"
                      target="_blank" class="link-text">{{ 'SETTINGS_PAGE.QUICK_LINKS.TERMS_AND_CONDITIONS' | translate
                      }}</a>
                  </div>
                </div>
              </div>
  
              <div class="quick-links">
                <div class="quick-links__control">
                  <mat-icon class="link-icon">flag</mat-icon>
                </div>
                <div class="quick-links__body">
                  <div class="quick-links__title mat-subheading-2">
                    <a [href]="preferredLanguage == 'de' ? appConfig.privacyPolicyGerman : appConfig.privacyPolicyEnglish"
                      target="_blank" class="link-text">{{ 'SETTINGS_PAGE.QUICK_LINKS.PRIVACY_POLICY' | translate }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>    
    </div>
  </div>
</div>