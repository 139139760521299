import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS } from '../../../app.config';
import { MyprintersService } from '../../../modules/shared/services/myprinters.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { WelcomeDialogComponent } from './welcome-dialog/welcome-dialog.component';
import { StorageUtils } from '../../../modules/storage/utils/storage.utils';
import { ADMIN_USER, HIDE_OVERLAY_MYPRINTERS } from '../../../modules/storage/constants/storage.constants';
import { ActivatedRoute } from '@angular/router';
import { QuickstartMyprintersDialogComponent } from './quickstart-myprinters-dialog/quickstart-myprinters-dialog.component';
import { ProfileService } from '../../../modules/shared/services/printing-api.service';

@Component({
  selector: 'ezd--printers-myprinters',
  templateUrl: './myprinters.component.html',
  styleUrls: ['./myprinters.component.scss'],
})
export class MyPrintersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public isFirstVisit = true;
  maxPageLength = 100;
  defaultPageSize = DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  pageIndex: number;
  pageSize: number;
  isLoading = true;
  canTogglePrinters = true;
  columnDefs = ['status', 'name', 'location', 'model'];
  printersDataSource = new MatTableDataSource([]);
  myprintersSubscription: Subscription;
  searchTextChanged = new Subject<string>();
  sorting: string;
  myPrintersQueryParam: string;
  isQueryParamPresent: boolean;
  isAdmin: boolean = false;
  myprinters_enabled: boolean;

  constructor(private myprintersService: MyprintersService, 
              private dialog: MatDialog,
              private profileService: ProfileService,
              private route: ActivatedRoute) {
    if (StorageUtils.get(sessionStorage, ADMIN_USER)) this.isAdmin = true;

    this.profileService.read().subscribe(async profileResponse => {
      this.myprinters_enabled = profileResponse.result.myprinters_enabled;
    });

    this.myprintersSubscription = myprintersService.getFilteredMyPrinters().subscribe(result => {
      this.isLoading = result.isLoading;
      if(this.isLoading) return;
      result.printers.map(p => {
        if(!p.can_modify_favorite)
          p.is_favorite = true;
      });
      this.printersDataSource.data = result.printers;
      this.pageIndex = result.pageIndex;
      this.pageSize = result.pageSize;
      this.maxPageLength = result.count;
    });

    //setSorting uses applyFilters
    this.myprintersService.setSorting('is_selected_or_assigned,location,name');

    this.searchTextChanged
      .pipe(
        debounceTime(400),
        distinctUntilChanged()) //blocks same search in a row
      .subscribe(result => {
        this.myprintersService.setSearchText(result);
        this.myprintersService.applyFilters();
      });
  }

  ngOnInit(): void { 
    if (!StorageUtils.get(sessionStorage, HIDE_OVERLAY_MYPRINTERS)) {
      this.myprintersService.getuserSettings().subscribe((response) => {
        if (response && !response.show_welcome_overlay) return
        const dialogConfig = new MatDialogConfig()

        dialogConfig.autoFocus = false
        dialogConfig.panelClass = 'ezp-splash'
        if (!WelcomeDialogComponent.dialogIsOpen) {
          let dialogRef = this.dialog.open(WelcomeDialogComponent, dialogConfig)
          dialogRef.afterClosed().subscribe(() => {
            StorageUtils.set(sessionStorage, HIDE_OVERLAY_MYPRINTERS, true)
          })
        }
      })
    }
    
    this.route.queryParamMap.subscribe(params => {
      this.myPrintersQueryParam = params.get('lang');
      if (this.myPrintersQueryParam) {
        this.isQueryParamPresent = true;
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.myprintersService.setSearchText(undefined);
    this.myprintersSubscription.unsubscribe();
  }

  onFavoriteChange(toggleObj, printer){
    this.myprintersService.changeFavoriteValue(toggleObj.checked, printer);
    if(this.isQueryParamPresent) {
       toggleObj.checked ? this.openQuickstartMyPrintersDialog() : '';
    }
  }

  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue);
    // this.networkPrintersPrintersFiltersService.setSearchText(searchValue);
  }

  onPaginationChange(e) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.myprintersService.setPage(this.pageIndex, this.pageSize);
  }

  openQuickstartMyPrintersDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.width = '600px';
    dialogConfig.panelClass = 'ezp-dialog';

    this.dialog.open(QuickstartMyprintersDialogComponent, dialogConfig);
  }
  
  sortChange(e) {
    let colName = '';
    if (e.active === 'model') {
      colName = 'model_name';
    } else {
      colName = e.active;
    }
    
    const direction = e.direction === 'desc' ? '-' : e.direction === 'asc' ? '' : undefined;
    if (direction === undefined) {
      this.sorting = colName;
    } else {
      this.sorting = direction + colName;
    }

    this.myprintersService.setSorting(this.sorting);
  }
}
