import { Component, OnInit, Optional, Inject } from '@angular/core';
import { UsersgroupsGroupsService } from '../../../../modules/shared/services/usersgroups-groups.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { LangaugeService } from '../../../../modules/shared/services/langauge.service';

@Component({
  selector: 'ezd-remove-group-dialog',
  templateUrl: './remove-group-dialog.component.html',
  styleUrls: ['./remove-group-dialog.component.scss']
})
export class RemoveGroupDialogComponent implements OnInit {
  isLoading = false;
  groupId: any;
  groupName: any;
  preferredLanguage: any; 
  
  constructor(
    private groupService: UsersgroupsGroupsService,
    public dialogRef: MatDialogRef<RemoveGroupDialogComponent>,
    private languageService: LangaugeService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.isLoading = false
      this.groupId = this.data.groupDetails.id;
      this.groupName = this.data.groupDetails.name;
      // this.activatedRoute.queryParams.subscribe(params => {
      //   this.lang = params["lang"]
      // })
  }

  ngOnInit(): void {
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value;
    })
  }

  removeGroup() {
    this.isLoading = true
    this.groupService.removeGroup(this.groupId).subscribe(
      async () => {
        await this.groupService.applyFilters(true)
        this.dialogRef.close()
      });
  }

  close() {
    this.dialogRef.close()
  }
}
