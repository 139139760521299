<div class="divHeight">
  <div *ngIf="totalGroups.length > 0 || enableGroups == true">
    <div class="ezp-grid ezp-grid--gutter ezp-grid--spacing-vertical" data-grid-cols="2">
      <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
        <mat-label>{{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.ASSIGNMENTS.SELECT_AN_OPTION' | translate
        }}</mat-label>
        <mat-select [(ngModel)]="selectedOption" (selectionChange)="onDropdownChange($event)">
          <mat-option value="groups">
            <span>{{
              'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.ASSIGNMENTS.GROUPS' | translate
            }}</span>
          </mat-option>
          <mat-option value="users">
            <span>{{
              'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.ASSIGNMENTS.USERS' | translate
            }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        *ngIf="selectedOption === 'groups'"
        class="ezp-form-field ezp-form-field--no-helper"
        appearance="fill"
      >
        <mat-icon matPrefix>search</mat-icon>
        <mat-label id="user-search-groups-text">{{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.ASSIGNMENTS.SEARCH_GROUPS' | translate
        }}</mat-label>
        <input
          matInput
          id="user-group-name"
          #searchGroups
          [placeholder]="
            'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.ASSIGNMENTS.GROUP_NAME' | translate
          "
          (input)="onSearchChangeGroups($event.target.value)"
        />
      </mat-form-field>

      <mat-form-field
        *ngIf="selectedOption === 'users'"
        class="ezp-form-field ezp-form-field--no-helper"
        appearance="fill"
      >
        <mat-icon matPrefix>search</mat-icon>
        <mat-label id="user-search-groups-text">{{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.ASSIGNMENTS.SEARCH_USERS' | translate
        }}</mat-label>
        <input
          matInput
          id="group-user-name"
          #search
          [placeholder]="
            'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.ASSIGNMENTS.SEARCH_USERS' | translate
          "
          (input)="onSearchChange($event.target.value)"
        />
      </mat-form-field>
    </div>

    <div *ngIf="selectedOption === 'groups'">
      <ezd-infinity-scroll
        (scrolled)="onScrollGroups()"
        style="height: 300px; overflow-y: auto"
        class="list"
      >
        <mat-list #groups class="ezp-list ezp-list--filterable">
          <mat-list-item *ngFor="let group of assignedGroups" color="primary" [id]="group.name">
            <div class="ezp-list__container">
              <div class="ezp-list__container__left-content">
                <mat-checkbox
                  (change)="groupChanged($event)"
                  [value]="group"
                  [(ngModel)]="group.isSelected"
                  color="primary"
                ></mat-checkbox>
                <span [id]="group.name">{{ group.name }} </span>
                <span
                  *ngIf="group.origin.provider != 'Ezeep'"
                  class="ezp-list__container__light-content"
                  id="users-azure-group-text"
                  >{{ 'USERSGROUPS.USERS.USER_DETAILS.USER_GROUPS.AZURE_GROUP' | translate }}</span
                >
              </div>
              <div class="ezp-list__container__select-content">
                <mat-select
                  [disabled]="isDisabled(group)"
                  [value]="group.printer_presets ? group.printer_presets[0] : group.printer_presets"
                  [id]="'mat-select-' + group.id"
                  placeholder="{{
                    'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.ASSIGNMENTS.SELECT_PROFILE'
                      | translate
                  }}"
                  color="primary"
                  [matTooltip]="tooltipText"
                  (mouseenter)="showTooltip()"
                  (selectionChange)="onProfileSelectionChange(group, $event.value, 'groups')"
                >
                  <mat-select-trigger>
                    {{ group.profile_name ? group.profile_name[0] : group.profile_name }}
                  </mat-select-trigger>
                  <mat-option (click)="removeProfile(group, 'groups')">
                    {{
                      'PRINTERS.NETWORK_PRINTERS.PRINTERS_PROPERTIES.DETAILS.REMOVE_PROFILE'
                        | translate
                    }}
                  </mat-option>
                  <mat-option
                    #matOption
                    [value]="profile.id"
                    *ngFor="let profile of profilesByPrinter"
                    class="multiline-mat-option"
                  >
                    <div class="option-name">{{ profile.name }}</div>
                    <div class="option-description">{{ profile.description }}</div>
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </mat-list-item>
        </mat-list>
        <div class="ezp-list__fetching" *ngIf="isLoadingGroups">
          <mat-spinner
            class="ezp-list__fetching__spinner ezp-list__fetching__spinner--selection"
          ></mat-spinner>
          <span class="ezp-list__fetching__title" id="users-fetching-groups-text">{{
            'USERSGROUPS.USERS.USER_DETAILS.USER_GROUPS.FETCHING_GROUPS' | translate
          }}</span>
        </div>
      </ezd-infinity-scroll>
    </div>

    <div *ngIf="selectedOption === 'users'">
      <ezd-infinity-scroll
        (scrolled)="onScroll()"
        style="height: 300px; overflow-y: auto"
        class="list"
      >
        <mat-list #users class="ezp-list ezp-list--filterable">
          <mat-list-item
            *ngFor="let user of assignedUsers"
            color="primary"
            [id]="user.display_name"
          >
            <div class="ezp-list__container">
              <div class="ezp-list__container__left-content">
                <mat-checkbox
                  (change)="userChanged($event)"
                  [value]="user"
                  [(ngModel)]="user.isSelected"
                  color="primary"
                ></mat-checkbox>
                <span [id]="user.display_name">{{
                  user.display_name ? user.display_name : user.email
                }}</span>
                <span
                  *ngIf="user.azure_profile !== null"
                  class="ezp-list__container__light-content"
                  id="users-azure-user-text"
                  >{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_USERS.AZURE_USER' | translate }}</span
                >
              </div>
              <div class="ezp-list__container__select-content">
                <mat-select
                  [disabled]="isDisabled(user)"
                  [value]="user.printer_presets ? user.printer_presets[0] : user.printer_presets"
                  [id]="'mat-select-' + user.id"
                  placeholder="{{
                    'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.ASSIGNMENTS.SELECT_PROFILE'
                      | translate
                  }}"
                  color="primary"
                  [matTooltip]="tooltipText"
                  (mouseenter)="showTooltip()"
                  (selectionChange)="onProfileSelectionChange(user, $event.value)"
                >
                  <mat-select-trigger>
                    {{ user.profile_name ? user.profile_name[0] : user.profile_name }}
                  </mat-select-trigger>
                  <mat-option (click)="removeProfile(user, 'users')">
                    {{
                      'PRINTERS.NETWORK_PRINTERS.PRINTERS_PROPERTIES.DETAILS.REMOVE_PROFILE'
                        | translate
                    }}
                  </mat-option>
                  <mat-option
                    #matOption
                    [value]="profile.id"
                    *ngFor="let profile of profilesByPrinter"
                    class="multiline-mat-option"
                  >
                    <div class="option-name">{{ profile.name }}</div>
                    <div class="option-description">{{ profile.description }}</div>
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </mat-list-item>
        </mat-list>
        <div class="ezp-list__fetching" *ngIf="isLoading">
          <mat-spinner
            class="ezp-list__fetching__spinner ezp-list__fetching__spinner--selection"
          ></mat-spinner>
          <span class="ezp-list__fetching__title" id="group-users-fetchingusers-text">{{
            'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_USERS.FETCHING_USERS' | translate
          }}</span>
        </div>
      </ezd-infinity-scroll>
    </div>
  </div>

  <div *ngIf="totalGroups.length == 0 || enableGroups == false">
    <div class="ezp-grid ezp-grid--gutter ezp-grid--spacing-vertical" data-grid-cols="1">
      <mat-form-field class="ezp-form-field ezp-form-field--no-helper" appearance="fill">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label id="user-search-groups-text">{{
          'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.ASSIGNMENTS.SEARCH_USERS' | translate
        }}</mat-label>
        <input
          matInput
          id="group-user-name"
          #search
          [placeholder]="
            'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.ASSIGNMENTS.SEARCH_USERS' | translate
          "
          (input)="onSearchChange($event.target.value)"
        />
      </mat-form-field>
    </div>

    <div>
      <ezd-infinity-scroll
        (scrolled)="onScroll()"
        style="height: 300px; overflow-y: auto"
        class="list"
      >
        <mat-list #users class="ezp-list ezp-list--filterable">
          <mat-list-item
            *ngFor="let user of assignedUsers"
            color="primary"
            [id]="user.display_name"
          >
            <div class="ezp-list__container">
              <div class="ezp-list__container__left-content">
                <mat-checkbox
                  (change)="userChanged($event)"
                  [value]="user"
                  [(ngModel)]="user.isSelected"
                  color="primary"
                ></mat-checkbox>
                <span [id]="user.display_name">{{
                  user.display_name ? user.display_name : user.email
                }}</span>
                <span
                  *ngIf="user.azure_profile !== null"
                  class="ezp-list__container__light-content"
                  id="users-azure-user-text"
                  >{{ 'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_USERS.AZURE_USER' | translate }}</span
                >
              </div>
              <div class="ezp-list__container__select-content">
                <mat-select
                  [disabled]="isDisabled(user)"
                  [value]="user.printer_presets ? user.printer_presets[0] : user.printer_presets"
                  [id]="'mat-select-' + user.id"
                  placeholder="{{
                    'PRINTERS.NETWORK_PRINTERS.PRINTER_EDITOR.ASSIGNMENTS.SELECT_PROFILE'
                      | translate
                  }}"
                  color="primary"
                  [matTooltip]="tooltipText"
                  (mouseenter)="showTooltip()"
                  (selectionChange)="onProfileSelectionChange(user, $event.value)"
                >
                  <mat-select-trigger>
                    {{ user.profile_name ? user.profile_name[0] : user.profile_name }}
                  </mat-select-trigger>
                  <mat-option (click)="removeProfile(user, 'users')">
                    {{
                      'PRINTERS.NETWORK_PRINTERS.PRINTERS_PROPERTIES.DETAILS.REMOVE_PROFILE'
                        | translate
                    }}
                  </mat-option>
                  <mat-option
                    #matOption
                    [value]="profile.id"
                    *ngFor="let profile of profilesByPrinter"
                    class="multiline-mat-option"
                  >
                    <div class="option-name">{{ profile.name }}</div>
                    <div class="option-description">{{ profile.description }}</div>
                  </mat-option>
                </mat-select>
              </div>
            </div>
          </mat-list-item>
        </mat-list>
        <div class="ezp-list__fetching" *ngIf="isLoading">
          <mat-spinner
            class="ezp-list__fetching__spinner ezp-list__fetching__spinner--selection"
          ></mat-spinner>
          <span class="ezp-list__fetching__title" id="group-users-fetchingusers-text">{{
            'USERSGROUPS.GROUPS.GROUP_DETAILS.GROUP_USERS.FETCHING_USERS' | translate
          }}</span>
        </div>
      </ezd-infinity-scroll>
    </div>
  </div>
</div>
