import { Injectable } from '@angular/core';
import { AuthUserApiService } from './auth-api.service';
import { Observable, BehaviorSubject, of, from, lastValueFrom } from 'rxjs';
import { map, mergeMap, last } from 'rxjs/operators';
import { UserPrintingService, UserPrinting } from './printing-api.service';

@Injectable({
  providedIn: 'root'
})
export class UsersgroupsGroupsUsersService {
  allUsersSubject = new BehaviorSubject<any>([]);
  currentGroupId: any = undefined;
  allUsers: any[] = [];
  PAGE_SIZE = 100;
  PAGE_INDEX = 0;
  draftedAssignedUsers= undefined;
  searchText: string;

  constructor(
    private authUserService: AuthUserApiService,
    private userPrintService: UserPrintingService
  ) { }

  
  public getAllUsers(searchText:string, groupId:string): Observable<any> {
    this.setAllUsers(searchText, groupId, this.PAGE_SIZE, this.PAGE_INDEX).subscribe();
    return this.allUsersSubject.asObservable();
  }

  setAllUsers(searchText: string, groupId: string, page_size: number, offset: number) {
    if (!this.currentGroupId) {
      this.currentGroupId = groupId;
    }
    if (this.currentGroupId != groupId) {
      this.currentGroupId = groupId;
      this.allUsers = [];
    }


    this.allUsersSubject.next({ isLoading: true, users: this.allUsers });

    return this.getUsers(searchText, page_size, offset).pipe(map(response => {
      let endOfList = !response.next;
      this.allUsers = response.users;
      this.allUsersSubject.next({ isLoading: false, users: this.allUsers, endOfList: endOfList });
    }))
  }

  getUsers(searchText: string, pageSize: number, pageIndex: number) : Observable<any> {
    return this.authUserService.list(searchText, 'display_name', pageSize, pageIndex)
    .pipe(map(response => {
      return { users: response.result.results, next: response.result.next };
    }));
  }

  getAllPrintingUsers(group_id:string, page_size:number, page_index:number) : Observable<any> {
    let offset = page_index * page_size
    return this.userPrintService.list(undefined,  undefined, "is_assigned", group_id, undefined, page_size, offset)
      .pipe(map(async response => {
        let users = []
        if(response.result.next) {
          let nextIndex = page_index + 1
          users = await lastValueFrom(this.getAllPrintingUsers(group_id, page_size, nextIndex)) // .toPromise()
        }
        return [...response.result.results, ...users];
      }));
  }

  getAssignedPrintingUsersToGroup(group_id:string, page_size:number, page_index:number) : Observable<any> {
    let offset = page_index * page_size
    return this.userPrintService.list(undefined,  undefined, undefined, undefined, group_id, page_size, offset)
      .pipe(map(async response => {
        let users = []
        if(response.result.next) {
          let nextIndex = page_index + 1
          users = await lastValueFrom(this.getAssignedPrintingUsersToGroup(group_id, page_size, nextIndex)) // .toPromise()
        }
        return [...response.result.results, ...users];
      }));
  }

  async draftAssignedUsers(currentAssignedUsers: any[], newAssignments: any) {
    let removedUsers = currentAssignedUsers.filter(g => newAssignments.filter(n => n.id == g.id).length == 0)
    if (this.currentGroupId) {
      let currentUsers = await lastValueFrom(this.getAssignedPrintingUsersToGroup(this.currentGroupId, 100, 0)) // .toPromise()
      newAssignments = newAssignments.filter(nu => currentUsers.filter(cu => cu.id == nu.id).length == 0)
      this.draftedAssignedUsers = currentUsers.filter(cu => removedUsers.filter(ru => ru.id == cu.id).length == 0)
    }
    this.draftedAssignedUsers = [...this.draftedAssignedUsers, ...newAssignments.map(u => {return {id: u.id, groups: [{group_id:this.currentGroupId}]}})]
  }

  async saveDraftedAssignedUsers(group_id:string) {
    if(!this.draftedAssignedUsers) return;

    let currentUsers = await lastValueFrom(this.getAssignedPrintingUsersToGroup(this.currentGroupId, 100, 0)) // .toPromise()
    let removedUsers = currentUsers.filter(cu => this.draftedAssignedUsers.filter(du => cu.id == du.id).length == 0)

    if (removedUsers && removedUsers.length > 0) {
      from(removedUsers)
        .pipe(mergeMap(async (u: UserPrinting) => {
          let currentUser = (await lastValueFrom(this.userPrintService.read(u.id))).result // .toPromise()).result
          currentUser.groups = currentUser.groups.filter(g => g.group_id !== group_id)
          await lastValueFrom(this.userPrintService.update(currentUser, currentUser.id)) // .toPromise()
        }))
        .pipe(last())
        .subscribe(async () => {
          return;
        });
    }
    
    if(this.draftedAssignedUsers.length > 0)
      from(this.draftedAssignedUsers)
        .pipe(mergeMap(async (u:UserPrinting) => {
          let currentUser = (await lastValueFrom(this.userPrintService.read(u.id))).result // .toPromise()).result
          if (!currentUser.groups[0] || !currentUser.groups[0].group_id){
            currentUser.groups = []
          }
          currentUser.groups.push({ group_id: group_id })
          await lastValueFrom(this.userPrintService.update(currentUser, currentUser.id)) // .toPromise()
        }))
        .pipe(last())
        .subscribe(async () => {
          return;
        });
  }

  clearDraftedAssignedGroups() {
    this.draftedAssignedUsers = undefined
  }
}
