import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZE_OPTIONS, env } from '../../app.config';
import { ConnectorService } from '../../modules/shared/services/connector.service';
import { ConnectorsFiltersService } from '../../modules/shared/services/connectors-filters.service';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { DIALOG_RESPONSE } from '../../modules/shared/classes/dialog-result';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ClaimHubDialogComponent } from './claim-hub-dialog/claim-hub-dialog.component';
import { SubscriptionService } from '../../modules/shared/services/subscription.service';
import { Subscription } from 'rxjs';
import { StorageUtils } from '../../modules/storage/utils/storage.utils';
import { WelcomeConnectorDialogComponent } from './welcome-connector-dialog/welcome-connector-dialog.component';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'ezd-connectors',
  templateUrl: './connectors.component.html',
  styleUrls: ['./connectors.component.scss']
})
export class ConnectorsComponent implements OnInit {
  env = env;
  columnDefs = ['select', 'name', 'description', 'type', 'manage'];
  networkPrintersConnectorsTableDataSource = new MatTableDataSource([]);
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  selectedNetworkPrintersConnectorsCount = 0;
  maxPageLength = 100;
  defaultPageSize = DEFAULT_PAGE_SIZE;
  pageSizeOptions: number[] = DEFAULT_PAGE_SIZE_OPTIONS;
  pageIndex: number;
  pageSize: number = DEFAULT_PAGE_SIZE;
  isLoading = false;
  showMessageNoConnectors = false;
  sorting: string;
  connectorPropertiesHasChanges: boolean = false;
  selectedConnector: any = undefined;
  isHubClaimingPresent: boolean;
  showConnectorsProperties: boolean = false;
  filteredConnectorsSubject: Subscription;
  // isFirstLoadConnectors: boolean = true;
  isForceShowFilterView = false;
  showConnectorsFilters: boolean = false;
  connecotrWatcherPropertiesSubscription: Subscription;
  showDialog = false;
  connectorId: any = "";
  showDialogDeleteConnector: boolean;
  filterName: string;
  
  constructor(private connectorsService: ConnectorService, 
    private filterService: ConnectorsFiltersService,
    private dialog: MatDialog,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
    StorageUtils.set(localStorage, "connectorUnsaved", null);
  }
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['connectorId']) {
        this.connectorId = params['connectorId'];
        setTimeout(() => {
          this.connectorsService.applyFilters(this.connectorId);
          this.connectorsService.getConnector(this.connectorId)
          .subscribe((connector) => {
            this.filterName = connector.readable_name;
            this.filterService.updateFilterName(this.filterName);
          });
        }, 2000);
      }
      if (params['popup'] == "welcome") {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.autoFocus = false;
        dialogConfig.width = '600px';
        dialogConfig.panelClass = 'ezp-dialog';

        this.dialog.open(WelcomeConnectorDialogComponent, dialogConfig);
      }
    }) 

    this.filteredConnectorsSubject = this.connectorsService.getFilteredConnectors()
      .subscribe((result) => {
        this.selectedNetworkPrintersConnectorsCount = this.connectorsService.getSelectedConnectorsCount();
        // if (!this.isFirstLoadConnectors) {
          this.isForceShowFilterView = false;
          this.showConnectorsFilters = this.selectedNetworkPrintersConnectorsCount !== 1 && StorageUtils.get(localStorage, "connectorUnsaved") == null;
          this.showConnectorsProperties = this.selectedNetworkPrintersConnectorsCount == 1 || StorageUtils.get(localStorage, "connectorUnsaved") != null;
        // }
        // this.isFirstLoadConnectors = false;

        this.isLoading = result.isLoading;
        this.networkPrintersConnectorsTableDataSource.data = result.connectors;
        this.maxPageLength = result.count;
        this.showMessageNoConnectors = result.noFilters && result.count == 0;
      
        if (this.selectedConnector != null)
          this.networkPrintersConnectorsTableDataSource.data.forEach(item => {
            item.isSelected = this.selectedConnector.id == item.id ? true : false;
          });
      });
      
    this.connecotrWatcherPropertiesSubscription = this.connectorsService.watchConnectorPropertiesChanges()
      .subscribe(result => {
        this.connectorPropertiesHasChanges = result.changed;
        if (result.changed && result.status == DIALOG_RESPONSE.CANCEL) {
          this.showDialog = false;
        }

        if ((result.status == DIALOG_RESPONSE.CANCEL || result.status == DIALOG_RESPONSE.NONE) && this.connectorPropertiesHasChanges) {
          let selectedItems = this.networkPrintersConnectorsTableDataSource.data.filter(x => x.isSelected);
          if (selectedItems.length > 0 && this.selectedConnector == undefined) {
            this.selectedConnector = selectedItems[0];
          } else {
            this.networkPrintersConnectorsTableDataSource.data.forEach(item => {
              item.isSelected = this.selectedConnector.id == item.id ? true : false;
            });
          }
        } else {
          this.selectedConnector = undefined;
        }
      });
    this.networkPrintersConnectorsTableDataSource.sort = this.sort;
    this.connectorsService.setPage(0, this.defaultPageSize, true);
    this.subscriptionService.getSubscription().subscribe(async (result) => {
      this.isHubClaimingPresent = result.features && result.features.hub_claiming ? true : false
    })
  }

  // selectAllVisibleConnectors(event) {
  //   if (event.checked) {
  //     this.networkPrintersConnectorsService.selectConnectors(this.networkPrintersConnectorsTableDataSource.data);
  //   } else {
  //     this.networkPrintersConnectorsService.unselectConnectors(this.networkPrintersConnectorsTableDataSource.data);
  //   }
  // }

  selectConnector(event, connector) {
    if (this.connectorPropertiesHasChanges) {
      this.showDialog = true;
      return;
    }
    this.connectorsService.unselectConnectors(this.networkPrintersConnectorsTableDataSource.data);
    if (event.checked) {
      this.connectorsService.selectConnectors([connector]);
    } else {
      this.connectorsService.unselectConnectors([connector]);
    }
  }

  public onPaginationChange(e) {
    if (this.connectorPropertiesHasChanges) {
      this.showDialog = true
      return;
    }
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.connectorsService.setPage(this.pageIndex, this.pageSize);
  }

  sortChange(e) {
    if (this.connectorPropertiesHasChanges) {
      this.showDialog = true
      return;
    }
    let colName = '';
    if (e.active === 'name') {
      colName = 'readable_name';
    } else if (e.active === 'type') {
      colName = 'connector_type';
    } else {
      colName = e.active;
    }
    const direction = e.direction === 'desc' ? '-' : e.direction === 'asc' ? '' : undefined;
    if (direction === undefined) {
      this.sorting = colName;
    } else {
      this.sorting = direction + colName;
    }

    this.connectorsService.setSorting(this.sorting);

  }

  openClaimHubDialog() {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.autoFocus = false;
    dialogConfig.width = '480px';
    dialogConfig.panelClass = 'ezp-dialog';

    const claimHubDialogRef = this.dialog.open(ClaimHubDialogComponent, dialogConfig);
    claimHubDialogRef.afterClosed().subscribe(success => {
      if (success) {
        this.connectorsService.applyFilters(undefined, true);
      }
    });
  }

  ngOnDestroy(): void {
    this.filteredConnectorsSubject.unsubscribe();
    this.connecotrWatcherPropertiesSubscription.unsubscribe();
  }

  showPrinters(connectorId) {
    if (this.connectorPropertiesHasChanges) {
      this.showDialog = true;
      return;
    }
    this.connectorId = connectorId;
    this.router.navigate(['printers'], { queryParams: { tab: 'networkprinters', connectorId: this.connectorId}, skipLocationChange: true })
  }

  showDeleteConnectorMessage() {
    this.showDialogDeleteConnector = true;
  }

  proccessDialogDeleteConnectorResult(result: DIALOG_RESPONSE) {
    if (this.connectorsService.getSelectedConnectors().length == 1 && result == DIALOG_RESPONSE.DELETE) {
      let connector = this.connectorsService.getSelectedConnectors()[0];
      this.connectorsService.deleteConnector(connector.id).subscribe(() => {
        this.showDialogDeleteConnector = false;
        this.connectorsService.applyFilters(undefined);
      });
    }
    else {
      this.showDialogDeleteConnector = false;
    }
  }

  forceShowFilter() {
    if (this.connectorPropertiesHasChanges) {
      this.showDialog = true;
      return;
    }
    this.isForceShowFilterView = true;
    this.selectedNetworkPrintersConnectorsCount = this.connectorsService.getSelectedConnectorsCount();
    this.showConnectorsFilters = this.selectedNetworkPrintersConnectorsCount !== 1;
    this.showConnectorsProperties = this.selectedNetworkPrintersConnectorsCount == 1;
    if (this.isForceShowFilterView) {
      this.connectorsService.unselectConnectors(null);
      this.showConnectorsFilters = true;
      this.showConnectorsProperties = false;
    }

    this.connectorsService.applyFilters(this.connectorId);
    this.connectorId = "";
    return;
  }

  processDialogResult(result: DIALOG_RESPONSE) {
    if (this.connectorPropertiesHasChanges)
      this.connectorsService.changeConnectorPropertiesWatcherStatus(result == DIALOG_RESPONSE.CANCEL ? true : false, result);

    this.showDialog = false;
  }
}
