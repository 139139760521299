import { Component, OnInit } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { AppDownloadsViewService } from '../../modules/shared/services/app-downloads-view.service'
import { StorageUtils } from '../../modules/storage/utils/storage.utils'
import { ADMIN_USER } from '../../modules/storage/constants/storage.constants'
import { LangaugeService } from '../../modules/shared/services/langauge.service'
import { lastValueFrom } from 'rxjs'

@Component({
  selector: 'ezd-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss'],
})
export class DownloadsComponent implements OnInit {
  baseUrl = 'https://assets.ezeep.com/wp-json'
  route = 'wp/v2'
  type = 'download'
  preferredLanguage: string;
  status = 'publish'
  orderBy = 'menu_order'
  order = 'asc'
  downloads = []
  hideConnectorsDownload: boolean
  isAdmin: boolean

  constructor(private http: HttpClient, private appDownloadService: AppDownloadsViewService, private languageService: LangaugeService) {
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value;

      if (this.preferredLanguage) {
        this.getDownloads()
      }
    })
  }

  ngOnInit(): void {
    if (StorageUtils.get(sessionStorage, ADMIN_USER)) this.isAdmin = true

    // this.appDownloadService
    //   .getCurrentLocalPrintingEnableStatus()
    //   .subscribe(async (local_printing_enabled) => {
    //     if (!local_printing_enabled) {
    //       this.hideConnectorsDownload = true
    //     } else {
    //       let isAllowedGroups = await lastValueFrom(this.appDownloadService
    //         .isAllowedGroupsLocalPrinting())
    //          // .toPromise()

    //       this.hideConnectorsDownload = !isAllowedGroups
    //     }
    //   })
  }

  private getDownloads(): void {
    this.http
      .get(
        `${this.baseUrl}/${this.route}/${this.type}?lang=${this.preferredLanguage}&status=${this.status}&orderby=${this.orderBy}&order=${this.order}`
      )
      .subscribe((data: any) => {
        data.map((item: any) => {
          let collections = []

          if (item.post_collections && item.post_collections.length > 0) {
            collections = item.post_collections.filter(
              (c) =>
                this.isAdmin ||
                !c.requirements ||
                !c.requirements.local_printer_support ||
                !this.hideConnectorsDownload
            )
          }

          let post = {
            title: item.title.rendered,
            description: item.post_description,
            icon: item.post_icon,
            collections: collections,
          }

          this.downloads.push(post)
        })
      })
  }
}
