import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { WebhooksService } from '../../../../modules/shared/services/webhooks.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { TestRunDialogueComponent } from './test-run-dialogue/test-run-dialogue.component';
import { ErrorService } from '../../../../modules/shared/services/error.service';

@Component({
  selector: 'ezd-webhook-editor-dialogue',
  templateUrl: './webhook-editor-dialogue.component.html',
  styleUrls: ['./webhook-editor-dialogue.component.scss']
})
export class WebhookEditorDialogueComponent implements OnInit {

  doneDisabled = false
  eventCount = 0;
  isLoading: boolean

  constructor(public dialogRef: MatDialogRef<WebhookEditorDialogueComponent>, private webhookService: WebhooksService, private dialogue: MatDialog,
    private errorService: ErrorService) { }

  ngOnInit(): void { }

  close() {
    this.dialogRef.close()
    this.webhookService.clearDraftWebhook();
  }

  done() {
    this.isLoading = true;
    this.addEditWebhook().subscribe({
      next: response => {
        this.webhookService.clearDraftWebhook()
        this.isLoading = false
        this.dialogRef.close({ success: true })
      }, error: error => {
        this.isLoading = false
        this.errorService.open(error.message)
      }
    });
  }

  addEditWebhook() {
    if (this.webhookService.editMode)
      return this.webhookService.updateWebhook()
    return this.webhookService.addWebhook()
  }

  // sent from properties tab
  propertiesFormValid(event) {
    this.doneDisabled = event;
  }

  // sent from failed event tab
  eventCountEvent(event){
    this.eventCount = event
  }

  openTestRunDialogue() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '780px';
    dialogConfig.panelClass = 'ezp-dialog';
    const dialogRef = this.dialogue.open(TestRunDialogueComponent, dialogConfig);
  }
}
