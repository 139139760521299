import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core'
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { Subject, Subscription, lastValueFrom, of, throwError } from 'rxjs'
import { catchError, debounceTime, switchMap } from 'rxjs/operators'
import { AuthUser, AuthUserApiService } from '../../../../modules/shared/services/auth-api.service'
import { NetworkPrintersGroupsService } from '../../../../modules/shared/services/network-printers-groups.service'
import { NetworkPrintersPrintersService } from '../../../../modules/shared/services/network-printers-printers.service'
import { NetworkPrintersUsersService } from '../../../../modules/shared/services/network-printers-users.service'
import { SubscriptionService } from '../../../../modules/shared/services/subscription.service'
import { GroupsService } from '../../../../modules/shared/services/printing-api.service'
import { GeneralUtil } from '../../../../modules/shared/utils/general-util'
import { PrinterProfileService } from '../../../../modules/shared/services/printer-profile.service'
import { ErrorService } from '../../../../modules/shared/services/error.service'
import { GROUPS, PRINTERS, USERS } from '../../../pages.constants'
import { LangaugeService } from '../../../../modules/shared/services/langauge.service'
import { PrinterEditorDialogComponent } from '../printer-editor-dialog/printer-editor-dialog.component'
import { FormBuilder, FormGroup } from '@angular/forms'
import { StorageUtils } from '../../../../modules/storage/utils/storage.utils'

const CHUNK_SIZE = 100
const DEBOUNCE_TIME_USERS = 400
const DEBOUNCE_TIME_GROUPS = 400
const USERS_KEY = 'display_name'
const GROUPS_KEY = 'name'

@Component({
  selector: 'ezd-printer-editor-assignments',
  templateUrl: './printer-editor-assignments.component.html',
  styleUrls: ['./printer-editor-assignments.component.scss'],
})
export class PrinterEditorAssignmentsComponent implements OnInit {
  @ViewChild('searchUsers') search: ElementRef
  assignedUsers: any[] = []
  isLoading: boolean = true
  endOfList: boolean = false
  user: any
  searchTextChanged = new Subject<string>()
  userServiceGetAllUsersSubscription: Subscription
  searchText: string
  currentPageIndex: number = 0
  removedUsers: any[] = []
  addedUsers: any[] = []
  canAddUsers: boolean
  printersSelected: any
  printerName: any
  @Output() usersSelected: EventEmitter<void> = new EventEmitter<void>()
  isProcessingUsers: boolean = true

  assignedGroups: any[] = []
  searchTextChangedGroup = new Subject<string>()
  searchTextGroup: string
  currentPageIndexGroups: number = 0
  removedGroups: any[] = []
  addedGroups: any[] = []
  canAddGroups: boolean
  @Output() groupsSelected: EventEmitter<void> = new EventEmitter<void>()
  isLoadingGroups: boolean = true
  groupServiceGetAllGroupsSubscription: Subscription
  isProcessingGroups: boolean = false
  endOfGroupsList: any
  enableGroups: boolean = false
  profilesByPrinter = []
  tooltipText: string
  preferredLanguage: any
  selectedOption: string = 'groups'
  showLoadingIndicator: boolean = false
  private _dataLoaded = false
  assignmentsForm: FormGroup
  selectedProfiles: { type: string; profileId: string; newGroupOrUserId: string }[] = []
  totalGroups: any

  constructor(
    public dialogRef: MatDialogRef<PrinterEditorDialogComponent>,
    private usersPrinterService: NetworkPrintersUsersService,
    private networkPrintersPrintersService: NetworkPrintersPrintersService,
    private NetworkPrinterGroupsService: NetworkPrintersGroupsService,
    private subscriptionService: SubscriptionService,
    private groupService: GroupsService,
    private authUserService: AuthUserApiService,
    private profileService: PrinterProfileService,
    private errorService: ErrorService,
    private languageService: LangaugeService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef
  ) {
    this.assignmentsForm = this.fb.group({
      // Define your form controls here
      dropdownField: [''],
      // Add other form controls as needed
    })
  }

  ngOnInit() {
    this.subscriptionService.getSubscription().subscribe((result) => {
      this.enableGroups =
        result.features && result.features.groups_printer_assignments == true ? true : false
    })
    this.NetworkPrinterGroupsService.getGroups(undefined, 25, 0).subscribe((result) => {
      this.totalGroups = result.groups
    })
    this.loadData()
  }

  get dataLoaded(): boolean {
    return this._dataLoaded
  }

  async loadData(): Promise<void> {
    if (!this._dataLoaded) {
      this.loadLanguagePreference()
      this.loadPrintersSelected()
      this.loadProfilesByPrinter()
      this.loadAssignedUsers()
      this.setupUserSearch()
      this.loadAssignedGroups()
      this.setupGroupSearch()
    }
    this._dataLoaded = true
  }

  private loadLanguagePreference() {
    this.languageService.getPreferredLang$.subscribe((value) => {
      this.preferredLanguage = value
    })
  }

  private loadPrintersSelected() {
    this.printersSelected = this.networkPrintersPrintersService
      .getSelectedNetworkPrinters()
      .map((x) => x.id)
    this.printerName = this.networkPrintersPrintersService
      .getSelectedNetworkPrinters()
      .map((x) => x.name)
  }

  private loadProfilesByPrinter() {
    if (this.printersSelected && this.printersSelected.length == 1) {
      this.profileService.getProfilesByPrinterId(this.printersSelected[0]).subscribe((response) => {
        this.profilesByPrinter = response
      })
    }
  }

  private async loadAssignedUsers() {
    this.canAddUsers = false
    if (this.printersSelected && this.printersSelected.length > 0) {
      const assignedUsers = await lastValueFrom(
        this.usersPrinterService.getAssignedUsersByPrintersId(this.printersSelected)
      )
      const chunkedUsers = GeneralUtil.chunck(assignedUsers, CHUNK_SIZE)
      Promise.all(
        chunkedUsers.map(async (users) => {
          const g = await lastValueFrom(
            this.authUserService.listMany(users.map((user) => user.user_id))
          )
          return g.result.results.map((u) => ({ ...u, ['isSelected']: true }))
        })
      ).then((allAssignments) => {
        this.processAssignedUsers(allAssignments)
      })
    }
  }

  private processAssignedUsers(allAssignments) {
    allAssignments = [].concat(...allAssignments)
    this.userServiceGetAllUsersSubscription = this.usersPrinterService
      .getAllUsers(this.searchText)
      .subscribe(async (result) => {
        const assignedUsers = GeneralUtil.searchArray(
          allAssignments,
          this.searchText,
          USERS_KEY,
          'email'
        )
        if (!result.isLoading && result.users) {
          const groupPrinterPresets = await lastValueFrom(
            this.profileService.getGroupedPrinterPresets(this.printersSelected[0], USERS, 100, 0)
          )
          const orderedUsers = result.users
          this.assignedUsers = [...this.assignedUsers, ...assignedUsers, ...orderedUsers]
          this.assignedUsers = GeneralUtil.removeDuplicates(this.assignedUsers)
          this.assignedUsers = this.assignedUsers.filter(
            (obj) => !allAssignments.some((item) => item['id'] === obj.id && !obj.isSelected)
          )
          this.assignedUsers = GeneralUtil.findAndAssignPrinterPresets(
            this.assignedUsers,
            groupPrinterPresets,
            USERS
          )
          this.enableDisableSelectedOptions(USERS, false)
          if (this.assignedUsers.length < 25 && !result.endOfList) {
            this.currentPageIndex += 1
            this.usersPrinterService
              .setAllUsers(this.searchText, 25, 25 * this.currentPageIndex)
              .subscribe()
          }
        }
        this.isLoading = result.isLoading
        this.endOfList = result.endOfList
        if (this.endOfList) {
          this.currentPageIndex = 0
        }
      })
  }

  private setupUserSearch() {
    this.searchTextChanged
      .pipe(
        debounceTime(DEBOUNCE_TIME_USERS),
        switchMap((result) => {
          this.searchText = result
          this.isLoading = true
          this.assignedUsers = []
          return this.usersPrinterService.setAllUsers(result, 25, 0)
        })
      )
      .subscribe()
  }

  private async loadAssignedGroups() {
    this.canAddGroups = false
    if (this.printersSelected && this.printersSelected.length > 0) {
      const assignedGroups = await lastValueFrom(
        this.NetworkPrinterGroupsService.getAssignedGroupsByPrintersId(this.printersSelected)
      )
      const chunkedGroups = GeneralUtil.chunck(assignedGroups, CHUNK_SIZE)
      Promise.all(
        chunkedGroups.map(async (groups) => {
          const g = await lastValueFrom(
            this.groupService.listMany(groups.map((group) => group.group_id))
          )
          return g.result.results.map((u) => ({ ...u, ['isSelected']: true }))
        })
      ).then((allAssignments) => {
        this.processAssignedGroups(allAssignments)
      })
    }
  }

  private processAssignedGroups(allAssignments) {
    allAssignments = [].concat(...allAssignments)
    this.groupServiceGetAllGroupsSubscription = this.NetworkPrinterGroupsService.getAllGroups(
      this.searchTextGroup
    ).subscribe(async (result) => {
      const assignedGroups = GeneralUtil.searchArray(
        allAssignments,
        this.searchTextGroup,
        GROUPS_KEY
      )
      if (!result.isLoading && result.groups) {
        const groupPrinterPresets = await lastValueFrom(
          this.profileService.getGroupedPrinterPresets(this.printersSelected[0], GROUPS, 100, 0)
        )
        const orderedGroups = result.groups
        this.assignedGroups = [...this.assignedGroups, ...assignedGroups, ...orderedGroups]
        this.assignedGroups = GeneralUtil.removeDuplicates(this.assignedGroups)
        this.assignedGroups = this.assignedGroups.filter(
          (obj) => !allAssignments.some((item) => item['id'] === obj.id && !obj.isSelected)
        )
        this.assignedGroups = GeneralUtil.findAndAssignPrinterPresets(
          this.assignedGroups,
          groupPrinterPresets,
          GROUPS
        )
        this.enableDisableSelectedOptions(GROUPS, false)
        if (this.assignedGroups.length < 25 && !result.endOfList) {
          this.currentPageIndexGroups += 1
          this.NetworkPrinterGroupsService.setAllGroups(
            this.searchTextGroup,
            25,
            25 * this.currentPageIndexGroups
          ).subscribe()
        }
      }
      this.isLoadingGroups = result.isLoading
      this.endOfGroupsList = result.endOfList
      if (this.endOfGroupsList) {
        this.currentPageIndexGroups = 0
      }
    })
  }

  private setupGroupSearch() {
    this.searchTextChangedGroup
      .pipe(
        debounceTime(DEBOUNCE_TIME_GROUPS),
        switchMap((result) => {
          this.searchTextGroup = result
          this.isLoadingGroups = true
          this.assignedGroups = []
          return this.NetworkPrinterGroupsService.setAllGroups(result, 25, 0)
        })
      )
      .subscribe()
  }

  ngAfterContentChecked() {
    if (this.search) {
      this.search.nativeElement.focus()
    }
  }

  ngOnDestroy(): void {
    if (this.userServiceGetAllUsersSubscription) {
      this.userServiceGetAllUsersSubscription.unsubscribe()
    }
    this.assignedUsers = []
    this.endOfList = false
    this.isLoading = true
    if (this.groupServiceGetAllGroupsSubscription) {
      this.groupServiceGetAllGroupsSubscription.unsubscribe()
    }
    this.endOfGroupsList = false
    this.isLoadingGroups = true
  }

  onSearchChange(searchValue: string) {
    this.searchTextChanged.next(searchValue)
  }

  onSearchChangeGroups(searchValue: string) {
    this.searchTextChangedGroup.next(searchValue)
  }

  userChanged(event) {
    console.log('userChanged called')
    const eventUser = event.source.value
    if (event.checked) {
      if (
        !this.addedUsers.find((user) => user.id === eventUser.id || user.user_id === eventUser.id)
      ) {
        this.addedUsers.push({ user_id: eventUser.id, force_chosen: false })
      }
      this.removedUsers = this.removedUsers.filter((user) => user.user_id !== eventUser.id)
    } else {
      if (
        !this.removedUsers.find((user) => user.id === eventUser.id || user.user_id === eventUser.id)
      ) {
        this.removedUsers.push({ user_id: eventUser.id, force_chosen: false })
      }
      this.addedUsers = this.addedUsers.filter((user) => user.user_id !== eventUser.id)
    }
    this.canAddUsers = true
    this.saveFormToLocalStorage() // Save changes to localStorage
  }

  groupChanged(event) {
    console.log('groupChanged called')
    const eventGroup = event.source.value
    if (event.checked) {
      if (
        !this.addedGroups.find(
          (group) => group.id === eventGroup.id || group.group_id === eventGroup.id
        )
      ) {
        this.addedGroups.push({ group_id: eventGroup.id, force_chosen: false })
      }
      this.removedGroups = this.removedGroups.filter((group) => group.group_id !== eventGroup.id)
    } else {
      if (
        !this.removedGroups.find(
          (group) => group.id === eventGroup.id || group.group_id === eventGroup.id
        )
      ) {
        this.removedGroups.push({ group_id: eventGroup.id, force_chosen: false })
      }
      this.addedGroups = this.addedGroups.filter((user) => user.group_id !== eventGroup.id)
    }
    this.canAddGroups = true
    this.saveFormToLocalStorage() // Save changes to localStorage
  }

  onScroll() {
    if (this.endOfList == undefined || this.endOfList || this.isLoading) return
    this.isLoading = true
    this.currentPageIndex += 1
    this.usersPrinterService
      .setAllUsers(this.searchText, 100, 100 * this.currentPageIndex)
      .subscribe()
  }

  onScrollGroups() {
    if (this.endOfGroupsList == undefined || this.endOfGroupsList || this.isLoadingGroups) return
    this.isLoadingGroups = true
    this.currentPageIndexGroups += 1
    this.NetworkPrinterGroupsService.setAllGroups(
      this.searchTextGroup,
      25,
      25 * this.currentPageIndexGroups
    ).subscribe()
  }

  close() {
    this.dialogRef.close()
    this.groupsSelected.emit(null)
    this.usersSelected.emit(null)
  }

  updateProfileAssignments(type, profile_id?, newGroupOrUserId?) {
    return this.profileService
      .updateAssignments(type, profile_id, this.printersSelected[0], newGroupOrUserId)
      .pipe(
        catchError((error) => {
          this.errorService.openTranslate(error, 'USERSGROUPS.ERROR')
          this.enableDisableSelectedOptions(type, false)
          return throwError(error)
        })
      )
  }

  onProfileSelectionChange(entity, profileId, type = 'users') {
    console.log('onProfileSelectionChange called')
    const existingSelection = this.selectedProfiles.find(
      (selection) => selection.newGroupOrUserId === entity.id && selection.type === type
    )
    if (existingSelection) {
      existingSelection.profileId = profileId
    } else {
      this.selectedProfiles.push({ type, profileId, newGroupOrUserId: entity.id })
    }

    // Update the entity's profile in the model to reflect the change in the UI
    entity.printer_presets = [profileId]
    entity.profile_name = [this.profilesByPrinter.find((profile) => profile.id === profileId).name]
    this.saveFormToLocalStorage() // Save changes to localStorage

    // Trigger change detection to update the UI
    this.cdr.detectChanges()
  }

  removeProfile(userOrGroup, type) {
    if (userOrGroup.printer_presets.length === 0) return
    this.profileService
      .deleteAssignments(this.printersSelected[0], userOrGroup.id, type)
      .subscribe({
        next: () => {
          this.updatePresetView(type)
        },
        error: (error) => {
          this.errorService.openTranslate(error, 'USERSGROUPS.ERROR')
          this.enableDisableSelectedOptions(type, false)
        },
      })
  }

  enableDisableSelectedOptions(type, disable) {
    const targetArray = type === USERS ? this.assignedUsers : this.assignedGroups
    const updatedArray = GeneralUtil.addselectDisabledToArray(targetArray, disable)
    if (type === USERS) {
      this.assignedUsers = updatedArray
    } else {
      this.assignedGroups = updatedArray
    }
  }

  async updatePresetView(type) {
    const groupPrinterPresets = await lastValueFrom(
      this.profileService.getGroupedPrinterPresets(this.printersSelected[0], type, 100, 0)
    )
    if (type === USERS) {
      this.assignedUsers = GeneralUtil.findAndAssignPrinterPresets(
        this.assignedUsers,
        groupPrinterPresets,
        USERS
      )
    } else {
      this.assignedGroups = GeneralUtil.findAndAssignPrinterPresets(
        this.assignedGroups,
        groupPrinterPresets,
        GROUPS
      )
    }
    this.enableDisableSelectedOptions(type, false)
  }

  showTooltip() {
    if (
      this.printersSelected &&
      this.printersSelected.length > 1 &&
      this.preferredLanguage == 'en'
    ) {
      this.tooltipText = 'Profile selection is unavailable when selecting multiple printers.'
    }
    if (
      this.printersSelected &&
      this.printersSelected.length > 1 &&
      this.preferredLanguage == 'de'
    ) {
      this.tooltipText =
        'Die Profilauswahl ist nicht verfügbar, wenn mehrere Drucker ausgewählt werden.'
    }
  }

  isDisabled(type): boolean {
    if (
      this.printersSelected &&
      this.printersSelected.length == 1 &&
      (type.selectDisabled || !type.isSelected)
    ) {
      return true
    }
    if (
      this.printersSelected &&
      this.printersSelected.length > 1 &&
      (type.selectDisabled || (this.printersSelected && this.printersSelected.length > 1))
    ) {
      return true
    }
  }

  done(): Promise<void> {
    console.log('done called')
    return new Promise((resolve, reject) => {
      this.showLoadingIndicator = true
      const requests = []

      // Process profile selections
      this.selectedProfiles.forEach((selection) => {
        const profileRequest = this.updateProfileAssignments(
          selection.type,
          selection.profileId,
          selection.newGroupOrUserId
        ).toPromise()
        requests.push(profileRequest)
      })

      if (this.canAddGroups) {
        this.isProcessingGroups = true
        const groupRequest = this.NetworkPrinterGroupsService.addRemoveGroups(
          this.addedGroups,
          this.removedGroups,
          this.printersSelected
        ).toPromise()
        requests.push(groupRequest)
        groupRequest
          .then((result) => {
            if (result) {
              this.canAddGroups = false
              this.networkPrintersPrintersService.getSelectedNetworkPrinters().map((x) => x.id)
              this.isProcessingGroups = false
              this.groupsSelected.emit()
            }
          })
          .catch((error) => {
            this.errorService.openTranslate(error, 'USERSGROUPS.ERROR')
            this.isProcessingGroups = false
            reject(error)
          })
      }

      if (this.canAddUsers) {
        this.isProcessingUsers = true
        const userRequest = this.usersPrinterService
          .addremoveUsers(this.addedUsers, this.removedUsers, this.printersSelected)
          .toPromise()
        requests.push(userRequest)
        userRequest
          .then((result) => {
            if (result) {
              this.canAddUsers = false
              this.networkPrintersPrintersService.getSelectedNetworkPrinters().map((x) => x.id)
              this.isProcessingUsers = false
              this.usersSelected.emit()
            }
          })
          .catch((error) => {
            this.errorService.openTranslate(error, 'USERSGROUPS.ERROR')
            this.isProcessingUsers = false
            reject(error)
          })
      }

      Promise.all(requests)
        .then(() => {
          this.showLoadingIndicator = false
          resolve()
        })
        .catch((error) => {
          this.showLoadingIndicator = false
          reject(error)
        })
    })
  }

  public saveAssignments() {
    this.done()
    StorageUtils.set(localStorage, 'printerAssignments', null)
    this.dialogRef.close()
  }

  private saveFormToLocalStorage() {
    StorageUtils.set(localStorage, 'printerAssignments', this.assignmentsForm.value)
  }

  onDropdownChange(event) {
    this.assignmentsForm.patchValue({
      dropdownField: event.value,
    })
    // this.saveFormToLocalStorage(); // Save changes to localStorage
  }
}
