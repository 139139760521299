<aside class="layout__aside layout__aside--right layout__aside--right--narrow properties">
  <header class="layout__aside__head">
    <button mat-button id="show-filter-view" color="accent" (click)="showFilterView()">
      <mat-icon>arrow_back</mat-icon>
      {{ 'FILTERS' | translate }}
    </button>
  </header>
  <div class="layout__aside__tab" *ngIf="showConnectorDetail">
    <div class="layout__aside__part">
      <mat-form-field class="efa-text-field">
        <input matInput id="selected-connector"
          [placeholder]="'CONNECTORS.CONNECTORS_PROPERTIES.DETAILS.NAME' | translate"
          [ngModel]="selectedConnector.readable_name" (input)="onNameChange($event.target.value)" />
      </mat-form-field>
      <mat-form-field class="efa-text-field">
        <textarea matInput id="selected-connector-desc"
          [placeholder]="'CONNECTORS.CONNECTORS_PROPERTIES.DETAILS.DESCRIPTION' | translate"
          [ngModel]="selectedConnector.description" (input)="onDescriptionChange($event.target.value)"></textarea>
      </mat-form-field>
    </div>
    <div class="layout__aside__part property-list">
      <div class="property">
        <span class="mat-body-2 efa-text--me" id="connector-hostname-text">
          {{ 'CONNECTORS.CONNECTORS_PROPERTIES.DETAILS.HOSTNAME' | translate }}:
        </span>
        <span class="mat-subheading-2 efa-text--he" [id]="selectedConnector.hostname">
          {{ selectedConnector.hostname }}
        </span>
      </div>
      <div class="property">
        <span class="mat-body-2 efa-text--me" id="connector-internalIp-text">
          {{ 'CONNECTORS.CONNECTORS_PROPERTIES.DETAILS.INTERNAL_IP' | translate }}:
        </span>
        <span class="mat-subheading-2 efa-text--he" [id]="selectedConnector.internal_ip">{{ selectedConnector.internal_ip
          }}</span>
      </div>
      <div class="property">
        <span class="mat-body-2 efa-text--me" id="connector-osversion-text">
          {{ 'CONNECTORS.CONNECTORS_PROPERTIES.DETAILS.OS_VERSION' | translate }}:
        </span>
        <span class="mat-subheading-2 efa-text--he" [id]="selectedConnector.os_type">{{ selectedConnector.os_type ==
          'Raspberry Pi' ? 'ezeep Hub' : selectedConnector.os_type }}</span>
      </div>
    </div>
    <div class="layout__aside__part layout__aside__part--separate">
      <span class="mat-body-2 efa-text efa-text--link efa-text--prm" (click)="showPrinters.emit(selectedConnector.id)"
        id="show-connected-printers">
        {{ 'CONNECTORS.CONNECTORS_PROPERTIES.DETAILS.SHOW_CONNECTED_PRINTERS' | translate }}
      </span>
      <span class="mat-body-2 efa-text efa-text--link efa-text--prm" id="get-hub-password" (click)="getPassword()">
        {{ 'CONNECTORS.CONNECTORS_PROPERTIES.DETAILS.PULL_PRINTING_PASSWORD' | translate }}
      </span>
    </div>
  </div>
  <footer class="footer">
    <button mat-stroked-button color="primary" (click)="saveChanges()">
      {{ 'SAVE' | translate }}
    </button>
    <button mat-stroked-button color="accent" (click)="discardChanges()">
      {{ 'DISCARD' | translate }}
    </button>
  </footer>
  <div class="footer">
    <button mat-stroked-button color="accent" class="ezp-button ezp-button--icon ezp-button--icon--leading" (click)="deleteConnector()">
      <mat-icon>delete</mat-icon>
      {{ 'CONNECTORS.CONNECTORS_PROPERTIES.DETAILS.DELETE_CONNECTOR' | translate }}
    </button>
  </div>
</aside>
