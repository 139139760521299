import { trigger, animate, state, stagger, style, group, transition, query, animateChild } from '@angular/animations';

export const fadeInOverlay = [
  group([
    query('.overlay__background', [
      style({ opacity: 0 }),
      animate('.4s ease', style({ opacity: 1 }))
    ], { optional: true }),
    query('.overlay-content', [
      style({ opacity: 0, transform: 'translateX(100%)' }),
      animate('.4s ease', style({ opacity: 1, transform: 'translateX(0)' }))
    ], { optional: true }),
    query('.driver-bundle-overlay', [
      style({ opacity: 0 }),
      animate('.4s 300ms ease', style({ opacity: 1 }))
    ], { optional: true })
  ])
];

export const fadeOutOverlay = [
  group([
    query('.overlay__background', [
      style({ opacity: 1 }),
      animate('.4s 300ms ease', style({ opacity: 0 }))
    ], { optional: true }),
    query('.overlay-content', [
      style({ transform: 'translateX(0)' }),
      animate('.3s 300ms ease', style({ transform: 'translateX(100%)' }))
    ], { optional: true }),
    query('.driver-bundle-overlay', [
      style({ opacity: 1 }),
      animate('.4s ease', style({ opacity: 0 }))
    ], { optional: true })
  ])
];

const fadeInOut = [
  query(':enter, :leave', style({ position: 'absolute', height: '100%', width: '100%' }), { optional: true }),
  group([
    query(':enter', [
      style({ opacity: 0 }),
      animate('.7s ease', style({ opacity: 1 }))
    ], { optional: true }),
    query(':leave', [
      style({ opacity: 1 }),
      animate('.7s ease', style({ opacity: 0 }))
    ], { optional: true })
  ])
];

export const ANIMATIONS: any = [
  trigger('routeAnimation', [
    transition('* <=> *', [
      query(':enter, :leave', style({ position: 'fixed', width: '100%' })
        , { optional: true }),
      group([
        query(':enter', [
          style({ opacity: 0, transform: 'translateY(30px)' }),
          animate('.4s ease', style({ opacity: 1, transform: 'translateY(0)' }))
        ], { optional: true }),
        query(':leave', [
          style({ opacity: 1, transform: 'translateY(0)' }),
          animate('.4s ease', style({ opacity: 0, transform: 'translateY(30px)' }))
        ], { optional: true })
      ])
    ])
  ]),
  trigger('importAnimation', [
    transition('* => importOverlay', fadeInOverlay),
    transition('importOverlay => importListOverlay', fadeInOut),
    transition('importOverlay => *', fadeOutOverlay),
    transition('importListOverlay => *', fadeOutOverlay)
  ]),
  trigger('overlayAnimation', [
    transition('* => addDriverBundleOverlay', fadeInOverlay),
    transition('* => editDriverBundleOverlay', fadeInOverlay),
    transition('* => addPrinterOverlay', fadeInOverlay),
    transition('* => editPrinterOverlay', fadeInOverlay),
    transition('* => addProfileOverlay', fadeInOverlay),
    transition('addDriverBundleOverlay => *', fadeOutOverlay),
    transition('editDriverBundleOverlay => *', fadeOutOverlay),
    transition('addPrinterOverlay => *', fadeOutOverlay),
    transition('editPrinterOverlay => *', fadeOutOverlay),
    transition('addProfileOverlay => *', fadeOutOverlay),
  ])
];
