import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdconGroupsService, AdconTenantGroups, AdconGroup } from '../services/adcon-api.service';
import { of as observableOf, from as observableFrom, Observable, BehaviorSubject, Subscription, merge, EMPTY, forkJoin, from, lastValueFrom } from 'rxjs';
import { map, last, mergeMap, concatMap, toArray, catchError, flatMap } from 'rxjs/operators';
import { AssignmentsService, GroupsService } from './printing-api.service';
import { NetworkPrintersAssignmentsService } from './network-printers-assignments.service';
import { GeneralUtil } from '../utils/general-util';
import { AuthUserApiService, AuthOrganizationApiService } from './auth-api.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkPrintersGroupsService {
  groups = [];
  assignedGroups = [];
  allGroupsSubject = new BehaviorSubject<any>([]);
  allGroups: any[] = [];
  PAGE_SIZE = 25;
  PAGE_INDEX = 0;
  printingGroups = new BehaviorSubject<any>([]);
  assignmentAdded = new BehaviorSubject<boolean>(false);
  assignmentRemoved = new BehaviorSubject<boolean>(false);
  userAssignmentRemoved = new BehaviorSubject<boolean>(false);

  constructor(
    private adconGroupsService: AdconGroupsService,
    private networkPrintersAssignmentsService: NetworkPrintersAssignmentsService,
    private groupsService: GroupsService,
    private authUserService: AuthUserApiService,
    private authOrganizationService: AuthOrganizationApiService
  ) { }

  public getAdconServiceGroups(): Observable<any> {
    return this.authUserService.me().pipe(map(async me => {
      let orgResult = await lastValueFrom(this.authOrganizationService.get(me.result.organization_id)) // .toPromise()
      if (orgResult.result.azure_profile) {
        return await lastValueFrom(this.adconGroupsService.get()) // .toPromise()
        .then(async r => {
          let adconResult = r
          let groups = r.result.value
          if(r.result.next) {
            let nextGroups = await lastValueFrom(this.getAllAzureGroups(r.result.next)) // .toPromise()
            groups = [...groups, ...nextGroups]
          }
          return { tenantId: adconResult.result.tenantId, groups: groups };
        })
        .catch(e => {
          console.log(e)
        })
      }
      else
        return undefined;
    }))
  }

  public getGroupsByForeignId(name, local_printer_support, foreign_id:string[]) : Observable<any> {
    let foreign_ids = GeneralUtil.chunck(foreign_id, 80);
    return observableFrom(foreign_ids)
      .pipe(concatMap(async (ids: any) => {
        let result = await lastValueFrom(this.groupsService.list(name, local_printer_support, undefined, ids.join('|'), undefined, undefined, foreign_id.length, 0)
          .pipe(map(response => {
            return response.result.results;
          }))) // .toPromise();
        return result;
      }))
      .pipe(toArray())
      .pipe(map(r => [].concat(...r)))
  }

  public getAllEzeepGroups(page_size:number, page_index:number) : Observable<any> {
    let offset = page_index * page_size
    return this.groupsService.list(undefined, undefined, undefined, undefined, undefined, undefined, page_size, offset)
      .pipe(map(async response => {
        let groups = []
        if(response.result.next) {
          let nextIndex = page_index + 1
          groups = await lastValueFrom(this.getAllEzeepGroups(page_size, nextIndex)) // .toPromise()
        }
        return [...response.result.results, ...groups];
      }));
  }

  getAllAzureGroups(next:string) : Observable<Promise<any>> {
    return this.adconGroupsService.get()
      .pipe(map(async response => {
        let groups = []
        if(response.result.next) {
          groups = await lastValueFrom(this.getAllAzureGroups(response.result.next)) // .toPromise()
        }
        return [...response.result.value, ...groups];
      }));
  }

  public getAllGroups(searchText:string): Observable<any> {
    this.setAllGroups(searchText, this.PAGE_SIZE, this.PAGE_INDEX).subscribe();
    return this.allGroupsSubject.asObservable();
  }

  setAllGroups(searchText: string, page_size: number, offset: number) {
    this.allGroupsSubject.next({ isLoading: true, groups: this.allGroups });

    return this.getGroups(searchText, page_size, offset).pipe(map(response => {
      let endOfList = !response.next;
      this.allGroups = response.groups;
      this.allGroupsSubject.next({ isLoading: false, groups: this.allGroups, endOfList: endOfList });
    }))

  }

  getGroups(searchText: string, pageSize: number, pageIndex: number): Observable<any> {
    return this.groupsService.list(undefined, undefined, undefined, undefined, searchText, undefined, pageSize, pageIndex)
    .pipe(map(response => {
      return { groups: response.result.results, next: response.result.next };
    }));
  }

  public getAssignedGroupsByPrintersId(printersId: string[]) : Observable<any> {
    return this.networkPrintersAssignmentsService.getPrintersAssignments(printersId).pipe(map(result => {
      let groups = result[0] 
        && result[0].assignments[0] 
        && result[0].assignments[0].groups
        && result[0].assignments[0].groups.length > 0 
          ? result[0].assignments[0].groups 
          : [];

      result.forEach(r => {
        let groupsToRemove = [];

        groups.forEach(g => {
          if (r.assignments == undefined
            || r.assignments.length == 0
            || r.assignments[0].groups == undefined
            || r.assignments[0].groups.length == 0
            || r.assignments[0].groups.filter(x => x.group_id == g.group_id).length == 0) {
              groupsToRemove.push(g);
          }
        });

        groupsToRemove.forEach(g => {
          let index = groups.indexOf(g);

          if (index >= 0) {
            groups.splice(index, 1);
          }
        })
      });

      return groups;
    }));
  }

  private updateGroupAssignments(addedGroups: any[], removedGroups: any[], printersSelected: any[]): void {
    from(printersSelected).pipe(
      mergeMap(p => {
        return this.networkPrintersAssignmentsService.getAssignments(p).pipe(
          map((assignments) => {
            const finalGroups = this.getFinalGroups(assignments, addedGroups, removedGroups)
            return this.networkPrintersAssignmentsService.update(assignments.ip_ranges, finalGroups, p, undefined);
          })
        );
      }),
      concatMap(response => response),
      toArray()
    ).subscribe({
      next: (responses) => {
        GeneralUtil.sleep(1000);
        this.assignmentAdded.next(true);
      },
      error: error => console.error('Error updating group assignments:', error),
    });
  }


  addRemoveGroups(addedGroups: any[], removedGroups: string[], printersSelected: any[]): Observable<boolean> {
    this.assignmentAdded.next(false);
    this.updateGroupAssignments(addedGroups, removedGroups, printersSelected);
    return this.assignmentAdded.asObservable();
  }

 

  getFinalGroups(assignments, addedUsers, removedUsers) {
    const tempCombinedaddedUsers = [...assignments.groups, ...addedUsers].reduce((acc, curr) => {
      if (!acc[curr.group_id]) {
        acc[curr.group_id] = curr;
      }
      return acc;
    }, {});
    const combinedaddedGroups: any[] = Object.values(tempCombinedaddedUsers);
    return combinedaddedGroups.filter((obj1) => !removedUsers.some((obj2) => obj2.group_id === obj1.group_id));
  }

  removeGroup(printersSelected: any[], group: any) {
    this.assignmentRemoved.next(false);
    observableFrom(printersSelected)
      .pipe(mergeMap(async p => {
        let assigment = await lastValueFrom(this.networkPrintersAssignmentsService.getAssignments(p.id)) // .toPromise();
        assigment.groups = assigment.groups.filter(g => g.group_id != group.group_id);
        return await this.networkPrintersAssignmentsService.update(assigment.ip_ranges, assigment.groups, p.id, p.name) // .toPromise();
      }))
      .pipe(last())
      .subscribe(() => {
        this.assignmentRemoved.next(true);
      });

    return this.assignmentRemoved.asObservable();
  }
}
